import { ResponseThread } from "../../../types"
import useLayout from "../../../hooks/layout.hook"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import usePreventScroll from "../../../hooks/preventScroll.hook"
import TopBar from "../../../components/TopBar"
import { useResizeDetector } from "react-resize-detector"
import ThreadScoreHeader from "./components/ThreadScoreHeader"
import ThreadTitle from "./components/ThreadTitle"
import { StyledThreadTopBar } from "./styles"
import { AnimatePresence, motion } from "framer-motion"

type ThreadTopBarProps = {
    thread?: ResponseThread
    showScoreSubHeader?: boolean
}

const ThreadTopBar = ({ thread, showScoreSubHeader = false }: ThreadTopBarProps) => {
    const { isMobile } = useLayout()
    const libraryBarOpen = useSelector((state: RootState) => state.layoutReducer.libraryBarOpen)
    const socialBarOpen = useSelector((state: RootState) => state.layoutReducer.socialBarOpen)

    const { width: threadTopBarWidth, ref: threadTopBarRef } = useResizeDetector()

    usePreventScroll(threadTopBarRef)

    return (
        <StyledThreadTopBar
            isMobile={isMobile}
            libraryBarOpen={libraryBarOpen}
            socialBarOpen={socialBarOpen}
            ref={threadTopBarRef}
        >
            <TopBar styles={{ borderBottom: "unset", justifyContent: "center", position: "unset" }}>
                {thread?.thread?.title && (
                    <ThreadTitle
                        title={thread?.thread?.title}
                        isMobile={isMobile}
                        width={threadTopBarWidth}
                    />
                )}
            </TopBar>
            <AnimatePresence initial={false}>
                {showScoreSubHeader && (
                    <motion.div
                        key={"score-header"}
                        style={{ display: "flex", flexDirection: "column" }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.3, delay: 0.5 }}
                    >
                        <ThreadScoreHeader />
                    </motion.div>
                )}
            </AnimatePresence>
        </StyledThreadTopBar>
    )
}

export default ThreadTopBar
