import { useParams } from "react-router"
import { ProgramContent } from "."
import LibraryBar from "../components/LibraryBar/LibraryBar"
import { StyledHomeMobileScreen } from "./styles"
import SidebarToggle, { SidebarTogglePosition } from "../components/SidebarToggle"
import Sidebar from "react-sidebar"
import { SidebarType } from "../redux/layout"
import { useSidebars } from "../hooks/sidebars.hook"
import { Close } from "@mui/icons-material"
import LeaderboardIcon from "../assets/icons/LeaderboardIcon"
import { useTheme } from "styled-components"
import SocialBar from "../components/SocialBar"
import { StyledArrowBackIcon } from "./styles"
import { useHistory } from "react-router"

const HomeMobileScreen = () => {
    const { programGuid, threadGuid } = useParams<any>()
    const history = useHistory()
    const { toggleSidebar, canUseSidebar, socialBarOpen, enableSidebarAnimation } = useSidebars()
    const theme = useTheme()

    return (
        <StyledHomeMobileScreen>
            {programGuid && threadGuid ? (
                <>
                    <StyledArrowBackIcon onClick={() => history.goBack()} />
                    {programGuid && canUseSidebar(SidebarType.social) && (
                        <SidebarToggle
                            onClick={() => toggleSidebar(SidebarType.social)}
                            position={SidebarTogglePosition.right}
                            sidebarWidth={`${theme.sidebarWidths.social}px`}
                            rightPadding={theme.variables.program.socialBarPadding.right}
                            icon={
                                socialBarOpen ? (
                                    <Close sx={{ color: theme.colors.base.uiBgIcon }} />
                                ) : (
                                    <LeaderboardIcon />
                                )
                            }
                            styles={{ zIndex: 1000 }}
                            enabledTransition={enableSidebarAnimation}
                        />
                    )}
                    <Sidebar
                        sidebar={<SocialBar />}
                        open={socialBarOpen}
                        onSetOpen={() => toggleSidebar(SidebarType.social)}
                        touch={true}
                        pullRight
                        styles={{
                            root: {
                                // note: this is to prevent unwanted non-clickable items (like submit button on choice selection)
                                pointerEvents: socialBarOpen ? "auto" : "none",
                            },
                            content: {
                                overflowY: "hidden",
                                display: "flex",
                                flexDirection: "column",
                            },
                            sidebar: { zIndex: "10", position: "fixed" },
                        }}
                        overlayClassName={"sidebar-overlay"}
                        shadow={false}
                        transitions={enableSidebarAnimation}
                    >
                        <ProgramContent isInsideSidebar />
                    </Sidebar>
                    <ProgramContent />
                </>
            ) : (
                <LibraryBar />
            )}
        </StyledHomeMobileScreen>
    )
}

export default HomeMobileScreen
