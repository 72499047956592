import textIcon from "../../../assets/images/chunk_icon_text.png"
import imageIcon from "../../../assets/images/chunk_icon_image.png"
import customIcon from "../../../assets/images/chunk_icon_custom.png"
import questionIcon from "../../../assets/images/chunk_icon_question.png"
import branchIcon from "../../../assets/images/chunk_icon_branch.png"
import brickIcon from "../../../assets/images/chunk_icon_brick.png"
import messageIcon from "../../../assets/images/chunk_icon_message.png"
import commentIcon from "../../../assets/images/chunk_icon_comment.png"
import setVariableIcon from "../../../assets/images/chunk_icon_set_variable.png"
import {
    BlockType as AiAssistMenuBlockKey,
    QuestionIntent as AiAssistMenuQuestionKey,
} from "../../../apollo/generated/graphql"
import { BlockType } from "../../../types"

export enum QuestionBlockMenuKey {
    CHOICE = "QUESTION_BLOCK_CHOICE",
    FREE_TEXT = "QUESTION_BLOCK_FREE_TEXT",
}

// note: we add the `CONTENT_BLOCK` prefix to avoid conflicts with the `AiAssistMenuKey`
export enum ContentBlockMenuKey {
    TEXT = "CONTENT_BLOCK_TEXT",
    IMAGE = "CONTENT_BLOCK_IMAGE",
    CUSTOM = "CONTENT_BLOCK_CUSTOM",
}

export enum AdvanceMenuKey {
    SET_VARIABLE = "ADVANCE_SET_VARIABLE",
    COMMENT = "ADVANCE_COMMENT",
    MESSAGE = "ADVANCE_MESSAGE",
    BRANCH = "ADVANCE_BRANCH",
    INCLUDE = "ADVANCE_INCLUDE",
}

export { AiAssistMenuBlockKey, AiAssistMenuQuestionKey }
export type AiAssistMenuKey = AiAssistMenuBlockKey

export type MenuItem = {
    key: ContentBlockMenuKey | AdvanceMenuKey | AiAssistMenuKey | QuestionBlockMenuKey
    label: string
    image?: string
    showDivider?: boolean
    options?: MenuItem[]
    // `mainBlockType` represents the block type that will be created when selecting the menu item.
    // Is useful to determine if a block can be pasted or not in certain `AddChunkMenu`.
    // Is like the `context` prop we use in other places.
    mainBlockType?: BlockType
}

export const aiAssistOptions: MenuItem[] = [
    {
        key: AiAssistMenuBlockKey.Question,
        label: "Ask a question",
    },
    {
        key: AiAssistMenuBlockKey.Writing,
        label: "Continue writing",
    },
]

export const questionBlockOptions: MenuItem[] = [
    {
        key: QuestionBlockMenuKey.CHOICE,
        label: "Multiple Choice",
        image: questionIcon,
        mainBlockType: BlockType.CHOICE_QUESTION,
    },
    {
        key: QuestionBlockMenuKey.FREE_TEXT,
        label: "Free Text",
        image: questionIcon,
        mainBlockType: BlockType.FREE_TEXT_QUESTION,
    },
]

export const contentBlockOptions: MenuItem[] = [
    {
        key: ContentBlockMenuKey.TEXT,
        label: "Text",
        image: textIcon,
        mainBlockType: BlockType.GROUP,
    },
    {
        key: ContentBlockMenuKey.IMAGE,
        label: "Image",
        image: imageIcon,
        mainBlockType: BlockType.GROUP,
    },
    {
        key: ContentBlockMenuKey.CUSTOM,
        label: "Custom",
        image: customIcon,
        mainBlockType: BlockType.GROUP,
    },
]

export const advanceOptions: MenuItem[] = [
    {
        key: AdvanceMenuKey.SET_VARIABLE,
        label: "Set Variable",
        image: setVariableIcon,
        mainBlockType: BlockType.SET_STRING_VARIABLE,
    },
    {
        key: AdvanceMenuKey.COMMENT,
        label: "Comment",
        image: commentIcon,
        mainBlockType: BlockType.COMMENT,
    },
    {
        key: AdvanceMenuKey.MESSAGE,
        label: "Message",
        image: messageIcon,
        mainBlockType: BlockType.NOTIFICATION,
    },
    {
        key: AdvanceMenuKey.INCLUDE,
        label: "Brick",
        image: brickIcon,
        mainBlockType: BlockType.INCLUDE,
    },
    {
        key: AdvanceMenuKey.BRANCH,
        label: "Branch",
        image: branchIcon,
        mainBlockType: BlockType.CONDITIONAL,
    },
]
