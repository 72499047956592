import styled from "styled-components"
import { ToastContainer } from "react-toastify"
import { StyledBox } from "../../styles/styledcomponents"

export const StyledToast = styled(StyledBox)`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
`

export const StyledSpanIcon = styled.span`
    margin-right: 10px;
    display: flex;
    align-items: center;
`

export const StyledMessage = styled.p`
    color: ${({ theme }) => theme.headlandsWhite};
    text-wrap: normal;
    text-align: center;
    margin-right: 5px;
`

export const StyledToastContainer = styled(ToastContainer)`
    .Toastify__close-button {
        color: ${({ theme }) => theme.headlandsWhite} !important;
        opacity: 1 !important;
        align-self: center !important;
    }

    .Toastify__toast {
        min-height: 0 !important;
        padding: 8px 15px !important;
    }

    .Toastify__toast-body {
        margin: 0 !important;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &.Toastify__toast-container--top-center {
        top: 6px;
    }
`
