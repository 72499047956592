import styled from "styled-components"
import { StyledBox, StyledRow } from "../../../styles/styledcomponents"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { StyledAddChunkButton } from "../AddChunkMenu/styles"

export const StyledSection = styled(StyledBox)``

export const StyledSectionHeader = styled(StyledRow)`
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`

export const StyledExpandLessIcon = styled(ExpandLessIcon)`
    width: 14px !important;
    color: ${({ theme }) => theme.colors.base.uiBgIcon};
`
export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
    width: 14px !important;
    color: ${({ theme }) => theme.colors.base.uiBgIcon};
`

export const StyledSectionContent = styled(StyledBox)<{ open?: boolean }>`
    height: ${({ open }) => (open ? "inherit" : 0)};
    overflow-y: ${({ open }) => (open ? "inherit" : "hidden")};
    /*
    * Sorry about this but on design there is no room for the blocks handlers, they should be on the
    * same level as the section. So we add a negative margin left of the same with tha icons-row
    */
    margin-left: -40px;
`

export const StyledSectionTitleContainer = styled(StyledBox)`
    max-width: calc(100% - 20px);

    input {
        border-style: none;
        max-width: inherit;
        background-color: transparent;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.base.uiLabelBase};

        &:hover,
        &:focus-visible {
            outline-color: ${({ theme }) => theme.utils.addOpacityToHex(theme.headlandsGray7, 0.2)};
            outline-width: 1px;
            background-color: ${({ theme }) => theme.colors.base.uiBgBase};
        }
    }
`

export const StyledAddChunkContainer = styled(StyledRow)`
    // the 40px is for the icons-row space
    margin: 6px 40px;

    ${StyledAddChunkButton} {
        padding: 8px;
    }
`
