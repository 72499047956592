export const ImageUpload = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_28251_94588)">
            <path
                d="M6.75 12H11.25V7.5H14.25L9 2.25L3.75 7.5H6.75V12ZM9 4.3725L10.6275 6H9.75V10.5H8.25V6H7.3725L9 4.3725ZM3.75 13.5H14.25V15H3.75V13.5Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_28251_94588">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
