import styled from "styled-components"
import { StyledBox } from "../../../styles/styledcomponents"

export const StyledBrick = styled(StyledBox)`
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 12px;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.headlandsWhite};

    .react-select {
        max-width: 360px;
    }
`

export const StyledIconContainer = styled(StyledBox)`
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.headlandsGray6};
    margin-right: 18px;
    border-radius: 50px;
`

export const StyledThreadContainer = styled(StyledBox)`
    h5 {
        font-size: 14px;
        line-height: 18px;
        margin: 0;
    }

    p {
        font-size: 12px;
        line-height: 18px;
    }
`
