import styled, { css } from "styled-components"
import { rgba } from "polished"
import {
    HorizontalScrollableContentWithHiddenScroll,
    StyledBox,
    StyledH4,
    StyledImage,
    StyledRow,
    StyledSpan,
    VerticalScrollableContentWithHiddenScroll,
} from "../../../styles/styledcomponents"
import {
    StyledSearchBar,
    StyledSearchInputContainer,
} from "../../../deliver/components/SearchBar/styles"

export const MediaModalBaseStyles = css`
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    box-shadow: ${({ theme }) => theme.boxShadow1};
    border-radius: ${({ theme }) => theme.borderRadius1};
    box-sizing: border-box;
    width: 642px;

    .react-tabs {
        .react-tabs__tab-list {
            display: block;
            border-bottom: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
            padding: 0;
            margin: 0;

            .react-tabs__tab {
                padding: 6px 8px 4px;

                ${StyledSpan} {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    color: ${({ theme }) => theme.colors.base.uiLabelBase};
                }

                img {
                    width: 12px;
                    height: 12px;
                    margin-right: 3px;
                }

                &.react-tabs__tab--selected {
                    border-bottom: 2px solid ${({ theme }) => theme.headlandsPrimaryColor};
                    position: relative;
                }
            }
        }

        .react-tabs__tab-panel--selected {
            display: flex;
            flex-direction: column;
            padding: 18px 12px 12px;

            .rich-text-editor {
                flex: 1;
                // this will prevent the input from overflowing it's parent
                min-width: 0;
                max-height: 32px;

                .quill {
                    word-break: unset;
                    height: inherit;
                    margin: 0;
                    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
                    border-radius: ${({ theme }) => theme.borderRadius1};
                    padding: 6px;

                    .ql-editor {
                        ${HorizontalScrollableContentWithHiddenScroll};
                        word-wrap: normal;
                        white-space: nowrap;
                        padding: unset;

                        .variable-blot {
                            padding: 0 6px;
                        }

                        // quill placeholder
                        &.ql-blank::before {
                            color: ${({ theme }) => theme.colors.base.uiLabelDisabled};
                            font-style: normal;
                            font-size: 14px;
                            line-height: 20px;
                            left: 0;
                        }
                    }

                    &.focused {
                        border-radius: ${({ theme }) => theme.borderRadius1}!important;
                        box-shadow: none !important;
                        border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder}!important;
                    }
                }
            }

            ${StyledSearchBar} {
                margin-top: unset;
                margin-bottom: 12px;
                ${StyledSearchInputContainer} {
                    border-radius: ${({ theme }) => theme.borderRadius1};
                }
            }

            ${StyledH4} {
                font-size: 14px;
                line-height: 18px;
                color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
                margin-bottom: 6px;
            }
        }
    }
`

export const StyledMediaModal = styled.div`
    position: absolute;
    top: calc(100% + 12px);
    left: -60px;
    z-index: 2;

    ${MediaModalBaseStyles};
`
export const StyledImagesContainer = styled(StyledBox)<{
    $height?: number
    columns?: number
    rows?: number
}>`
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    grid-template-rows: repeat(${({ rows }) => rows}, 1fr);
    gap: 6px;
    max-height: ${({ $height }) => $height}px;
    ${VerticalScrollableContentWithHiddenScroll};

    ${StyledImage} {
        width: 100%;
        height: 100%;
        border-radius: ${({ theme }) => theme.borderRadius1};
        object-fit: cover;
    }
`

StyledImagesContainer.defaultProps = { $height: 312, columns: 4, rows: 3 }

export const StyledTabTitle = styled(StyledRow)`
    align-items: center;
`
export const StyledDropzone = styled(StyledBox)<{ error?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.headlandsGray4};
    border-radius: ${({ theme, error }) =>
        error ? `${theme.borderRadius1} ${theme.borderRadius1} 0 0` : theme.borderRadius1};
    border: 1px dashed
        ${({ theme, error }) => (error ? theme.headlandsError : theme.headlandsGray3)};
    padding: 12px 28px;
    text-align: center;
    cursor: pointer;
    min-height: 38px;

    img {
        width: 24px;
        height: 24px;
    }
`

export const StyledDropzoneError = styled(StyledBox)`
    background-color: ${({ theme }) => rgba(theme.headlandsError, 0.2)};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border: 1px dashed ${({ theme }) => theme.headlandsError};
    border-radius: 0 0 ${({ theme }) => theme.borderRadius1} ${({ theme }) => theme.borderRadius1};
    border-top-style: none;
    p {
        margin-left: 5px;
        color: ${({ theme }) => theme.headlandsError} !important;
        font-size: 14px;
    }
    path {
        fill: ${({ theme }) => theme.headlandsError};
    }
`
