import { useHistory } from "react-router"
import useLayout from "../hooks/layout.hook"
import {
    StyledBackArrow,
    StyledMobileFormContainer,
    StyledMobileSettings,
    StyledProfileContent,
    StyledDesktopSettings,
    StyledVerticalScrollableContainer,
    StyledButtonLabelWithIcon,
} from "./styles"
import TopBar from "../components/TopBar"
import { StyledHeaderCard } from "./components/ProfileMainCard/styles"
import UserProfileImage from "./components/UserProfileImage"
import ProfileForm, { PROFILE_USER_FORM_ID } from "./components/ProfileForm"
import { StyledH1, StyledRow } from "../styles/styledcomponents"
import { Button } from "../components/Button/Button"
import { NavLink } from "react-router-dom"
import { useTheme } from "styled-components"
import { SaveOutlineIcon } from "../assets/icons/SaveOutlineIcon"

const SettingsEdit = () => {
    const theme = useTheme()
    const { isMobile } = useLayout()
    const history = useHistory()

    if (isMobile) {
        return (
            <StyledMobileSettings>
                <TopBar title={"Edit Profile"}>
                    <StyledBackArrow onClick={() => history.goBack()} />
                </TopBar>
                <StyledHeaderCard>
                    <UserProfileImage />
                </StyledHeaderCard>
                <StyledProfileContent>
                    <StyledMobileFormContainer>
                        <ProfileForm />
                    </StyledMobileFormContainer>
                </StyledProfileContent>
            </StyledMobileSettings>
        )
    }
    return (
        <StyledDesktopSettings>
            <TopBar styles={{ justifyContent: "space-between" }}>
                <StyledH1 useNewDesign>Profile</StyledH1>
                <StyledRow css={{ gap: "12px" }}>
                    <NavLink to={"/settings"}>
                        <Button label={"Cancel"} variant={"outline"} />
                    </NavLink>
                    <Button
                        form={PROFILE_USER_FORM_ID}
                        type={"submit"}
                        label={
                            <StyledButtonLabelWithIcon>
                                <SaveOutlineIcon color={theme.colors.base.uiBgBase} />
                                <span>Save</span>
                            </StyledButtonLabelWithIcon>
                        }
                    />
                </StyledRow>
            </TopBar>
            <StyledVerticalScrollableContainer css={{ padding: "24px", width: "100%" }}>
                <StyledMobileFormContainer
                    css={{
                        minWidth: "417px",
                        maxWidth: "417px",
                        alignItems: "center",
                        margin: "0 auto",
                    }}
                >
                    <UserProfileImage />
                    <ProfileForm hideSubmitButton />
                </StyledMobileFormContainer>
            </StyledVerticalScrollableContainer>
        </StyledDesktopSettings>
    )
}

export default SettingsEdit
