import styled, { css } from "styled-components"
import { StyledImage } from "../../../styles/styledcomponents"

export const StyledMessageProviderImage = styled(StyledImage)<{
    size: number
    disabled?: boolean
    clickable?: boolean
}>`
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    filter: none;

    ${({ disabled }) =>
        disabled &&
        css`
            filter: grayscale(1) opacity(0.5);
        `}

    ${({ clickable }) =>
        clickable &&
        css`
            cursor: pointer;
        `}
`
