/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    EmailAddress: any
    JWT: any
    Time: any
    Upload: any
    Void: any
}

export type AiContinueWritingInput = {
    index: Scalars["Int"]
    parentGUID?: InputMaybe<Scalars["String"]>
    threadId: Scalars["Int"]
    threadVersionId?: InputMaybe<Scalars["Int"]>
    topic: Scalars["String"]
    verbosity: WritingVerbosity
}

/**  AI Edit Menu Items  */
export type AiEditMenuItem = {
    __typename?: "AIEditMenuItem"
    /**  Short description of this menu item  */
    description?: Maybe<Scalars["String"]>
    /**  Emoji icon for this menu item  */
    icon?: Maybe<Scalars["String"]>
    /**  Title of this menu item  */
    title?: Maybe<Scalars["String"]>
    /**  the argument that should be passed to aiGenThreadContent  */
    type?: Maybe<EditType>
}

export type AiGenerateContinueWritingTopicInput = {
    index: Scalars["Int"]
    parentGUID?: InputMaybe<Scalars["String"]>
    threadId: Scalars["Int"]
    threadVersionId?: InputMaybe<Scalars["Int"]>
}

export type AiGenerateQuestionInput = {
    format: QuestionFormat
    index: Scalars["Int"]
    intent: QuestionIntent
    parentGUID?: InputMaybe<Scalars["String"]>
    responseStyle: QuestionResponseStyle
    threadId: Scalars["Int"]
    threadVersionId?: InputMaybe<Scalars["Int"]>
    topic: Scalars["String"]
}

export type AiGenerateQuestionTopicInput = {
    index: Scalars["Int"]
    intent: QuestionIntent
    parentGUID?: InputMaybe<Scalars["String"]>
    threadId: Scalars["Int"]
    threadVersionId?: InputMaybe<Scalars["Int"]>
}

export type AiprOutput = {
    __typename?: "AIPROutput"
    content?: Maybe<Scalars["String"]>
    context?: Maybe<Scalars["String"]>
    next: QaFlowStatus
}

export type AiParams = {
    __typename?: "AIParams"
    instructorProfile?: Maybe<Scalars["String"]>
    prompt?: Maybe<Scalars["String"]>
    referenceMaterial?: Maybe<Scalars["String"]>
}

export type AiThreadSpecification = {
    durationMinutes?: InputMaybe<Scalars["Int"]>
    generateEntireThread?: InputMaybe<Scalars["Boolean"]>
    instructorProfile: Scalars["String"]
    language: Language
    numberOfQuestions?: InputMaybe<Scalars["Int"]>
    prompt: Scalars["String"]
    referenceMaterial: Scalars["String"]
    threadTemplate: ThreadTemplate
}

export type AddEventInput = {
    clientTime: Scalars["Time"]
    data: Scalars["String"]
    type: Scalars["String"]
    url: Scalars["String"]
}

export type AddThreadEventInput = {
    actor: TelemetryActor
    data: Scalars["String"]
    msOffset: Scalars["Int"]
    telemetrySessionId: Scalars["Int"]
    type: Scalars["String"]
    url: Scalars["String"]
}

export type Attachment = {
    __typename?: "Attachment"
    changes?: Maybe<Scalars["String"]>
    guid: Scalars["String"]
    type: Scalars["String"]
    url: Scalars["String"]
}

export type AuthenticateInput = {
    code: Scalars["String"]
    email: Scalars["EmailAddress"]
    requireAdmin?: InputMaybe<Scalars["Boolean"]>
}

export type AuthenticateResponse = {
    __typename?: "AuthenticateResponse"
    jwt: Scalars["JWT"]
    user: User
}

export enum BlockType {
    Question = "QUESTION",
    Writing = "WRITING",
}

export enum Category {
    Content = "CONTENT",
    Logic = "LOGIC",
    Variable = "VARIABLE",
}

export type ChannelDetails = {
    __typename?: "ChannelDetails"
    id: Scalars["String"]
    name: Scalars["String"]
}

/** A user selectable choice */
export type Choice = {
    __typename?: "Choice"
    id: Scalars["String"]
    /** The image associated with the choice (if applicable) */
    imageURL?: Maybe<Scalars["String"]>
    /** The displayed text of the choice */
    text?: Maybe<Scalars["String"]>
}

export type ChoiceData = {
    __typename?: "ChoiceData"
    correct?: Maybe<Scalars["Boolean"]>
    count?: Maybe<Scalars["Int"]>
    id: Scalars["String"]
    percent?: Maybe<Scalars["Float"]>
    total?: Maybe<Scalars["Int"]>
}

export type ConnectionDetails = {
    __typename?: "ConnectionDetails"
    channels?: Maybe<Array<ChannelDetails>>
    emailDomain: Scalars["String"]
    icon34?: Maybe<Scalars["String"]>
    icon88?: Maybe<Scalars["String"]>
    icon132?: Maybe<Scalars["String"]>
    icon230?: Maybe<Scalars["String"]>
    id: Scalars["String"]
    name: Scalars["String"]
}

export type ContentIndexingProgress = {
    __typename?: "ContentIndexingProgress"
    /**  When non-nil, indexing hit an error  */
    error?: Maybe<Scalars["String"]>
    progress: Scalars["Float"]
    state: Scalars["String"]
}

export type ContentRange = {
    __typename?: "ContentRange"
    byteEnd: Scalars["Int"]
    byteStart: Scalars["Int"]
    codepointEnd: Scalars["Int"]
    codepointStart: Scalars["Int"]
}

export type ContentSourceInput = {
    url: Scalars["String"]
}

export enum ContentType {
    ExampleContent = "EXAMPLE_CONTENT",
    TextContent = "TEXT_CONTENT",
}

export type CreateThreadInput = {
    edit?: InputMaybe<EditThreadInput>
    specs?: InputMaybe<AiThreadSpecification>
}

export type CreatorToolThreadBrief = {
    __typename?: "CreatorToolThreadBrief"
    guid: Scalars["String"]
    id: Scalars["Int"]
    instructor?: Maybe<Person>
    instructorId: Scalars["Int"]
    public?: Maybe<Scalars["Boolean"]>
    threadVersion?: Maybe<ThreadVersion>
}

export type DeleteThreadResponse = {
    __typename?: "DeleteThreadResponse"
    threadId: Scalars["Int"]
}

export type DynamicVariableQuery = {
    names?: InputMaybe<Array<Scalars["String"]>>
    programID?: InputMaybe<Scalars["Int"]>
    telemetrySessionId?: InputMaybe<Scalars["Int"]>
    threadID?: InputMaybe<Scalars["Int"]>
}

export type EditThreadInput = {
    blocks?: InputMaybe<Scalars["String"]>
    displayDescription?: InputMaybe<Scalars["String"]>
    font?: InputMaybe<Scalars["String"]>
    imageGUID?: InputMaybe<Scalars["String"]>
    /**  if instructor is non-null, the instructor of the thread will be updated  */
    instructorId?: InputMaybe<Scalars["Int"]>
    preview?: InputMaybe<Scalars["String"]>
    synopsis?: InputMaybe<Scalars["String"]>
    theme?: InputMaybe<Scalars["String"]>
    title?: InputMaybe<Scalars["String"]>
}

export enum EditType {
    Bulletize = "BULLETIZE",
    Emojify = "EMOJIFY",
    FixGrammar = "FIX_GRAMMAR",
    FixSpelling = "FIX_SPELLING",
    MakeLonger = "MAKE_LONGER",
    MakeShorter = "MAKE_SHORTER",
    Summarize = "SUMMARIZE",
}

export type EmailMessage = {
    __typename?: "EmailMessage"
    /**  the guid of an attachment to display with the email message  */
    attachment?: Maybe<Attachment>
    body: Scalars["String"]
    clientData?: Maybe<Scalars["String"]>
    deliveryTime: Scalars["Time"]
    link?: Maybe<Scalars["String"]>
    title: Scalars["String"]
}

export type EmailMessageInput = {
    /**  the guid of an attachment to display with the email message  */
    attachmentGUID?: InputMaybe<Scalars["String"]>
    body: Scalars["String"]
    clientData?: InputMaybe<Scalars["String"]>
    deliveryTime: Scalars["Time"]
    link?: InputMaybe<Scalars["String"]>
    title: Scalars["String"]
}

/** Information available to a user that describes a program that they are enrolled in */
export type EnrolledProgram = {
    __typename?: "EnrolledProgram"
    description?: Maybe<Scalars["String"]>
    endTime?: Maybe<Scalars["Time"]>
    firstThreadGUID?: Maybe<Scalars["String"]>
    firstThreadId?: Maybe<Scalars["Int"]>
    guid: Scalars["String"]
    id: Scalars["Int"]
    imageURL?: Maybe<Scalars["String"]>
    isSingleShareThread: Scalars["Boolean"]
    leaderboard: Scalars["Boolean"]
    name: Scalars["String"]
    progress: Progress
    socialSidebar: Scalars["Boolean"]
    startTime?: Maybe<Scalars["Time"]>
}

export type EnrolledProgramParticipant = {
    __typename?: "EnrolledProgramParticipant"
    person: Person
    progress: EnrolledProgramParticipantProgress
}

export type EnrolledProgramParticipantProgress = {
    __typename?: "EnrolledProgramParticipantProgress"
    lastActivity?: Maybe<Scalars["Time"]>
    threadProgress?: Maybe<Array<ThreadProgress>>
    threadScore?: Maybe<Array<ThreadScore>>
    total: Scalars["Float"]
    totalScore: Scalars["Float"]
}

export type EnrolledProgramThread = {
    __typename?: "EnrolledProgramThread"
    /** A unique identifier for this thread inside this program, which is just the concatenation of program+thread guids */
    id: Scalars["String"]
    lockTime?: Maybe<Scalars["Time"]>
    progress?: Maybe<Scalars["Float"]>
    status: ThreadStatus
    thread: Thread
    unlockTime?: Maybe<Scalars["Time"]>
}

export type Event = {
    __typename?: "Event"
    clientTime?: Maybe<Scalars["Time"]>
    clientURL?: Maybe<Scalars["String"]>
    data?: Maybe<Scalars["String"]>
    ipAddress?: Maybe<Scalars["String"]>
    requestID?: Maybe<Scalars["String"]>
    serverTime: Scalars["Time"]
    type: Scalars["String"]
    userAgent?: Maybe<Scalars["String"]>
    userID?: Maybe<Scalars["Int"]>
}

export type FreeTextResponse = {
    __typename?: "FreeTextResponse"
    author: Person
    response: Scalars["String"]
    when: Scalars["Time"]
}

export type FrontEndError = {
    error: Scalars["String"]
    stack: Scalars["String"]
    ua: Scalars["String"]
    url: Scalars["String"]
}

export type GenerateThreadInput = {
    instructorId: Scalars["Int"]
    language: Language
    /**  Size is a template specific value that indicates how big a thread should be.  I.E. for trivia quizzes it should be the number of questions */
    size?: InputMaybe<Scalars["Int"]>
    sources: Array<Scalars["String"]>
    threadTemplate: ThreadTemplate
    topic: Scalars["String"]
}

export type ImageResult = {
    __typename?: "ImageResult"
    handle: Scalars["String"]
    imageURL: Scalars["String"]
    thumbnailURL: Scalars["String"]
}

export type ImageSearchInput = {
    count?: InputMaybe<Scalars["Int"]>
    offset?: InputMaybe<Scalars["Int"]>
    provider: ImageSearchProvider
    query: Scalars["String"]
}

export enum ImageSearchProvider {
    Bing = "BING",
    BingGif = "BING_GIF",
    Giphy = "GIPHY",
    Unsplash = "UNSPLASH",
}

export type ImageSearchResponse = {
    __typename?: "ImageSearchResponse"
    value?: Maybe<Array<ImageResult>>
}

export enum ImageStyle {
    Cartoon = "CARTOON",
    Default = "DEFAULT",
    LineDrawing = "LINE_DRAWING",
    PencilSketch = "PENCIL_SKETCH",
    PhotorealisticImage = "PHOTOREALISTIC_IMAGE",
    VectorIllustration = "VECTOR_ILLUSTRATION",
}

export type Issue = {
    __typename?: "Issue"
    /** Category describes the type of issue being reported */
    category: Category
    /** Human readable description of the issue */
    description?: Maybe<Scalars["String"]>
    /**
     * an identifier for the type of issue being reported.  This is stable over time and
     * // *may* be used as a means to support content specific "issue suppression"
     */
    id: Scalars["String"]
    /** the ObjectID of the containing object where the lint issue was discovered */
    objectID: Scalars["String"]
    /**
     * ObjectPath holds a JSONPath to the deepest containing object where the
     * issue occurs
     */
    objectPath: Scalars["String"]
    /**
     * Path may be nil, but when present identifies the precise json node within the
     * containing object where the issue is occuring
     */
    path: Scalars["String"]
    /**
     * When Path points to an object containing textual content, Range MAY be defined to
     * identify the specific ranges where the error occurs
     */
    ranges?: Maybe<Array<ContentRange>>
    /** how important the lint issue is */
    severity: Severity
}

export enum Language {
    Bulgarian = "BULGARIAN",
    English = "ENGLISH",
    French = "FRENCH",
    German = "GERMAN",
    Spanish = "SPANISH",
}

export type Link = {
    __typename?: "Link"
    id: Scalars["Int"]
    linkURL: Scalars["String"]
    n?: Maybe<Scalars["Int"]>
    path: Scalars["String"]
    programID?: Maybe<Scalars["Int"]>
    /** token is a unique identifier for a link */
    token: Scalars["String"]
}

export type LinkInput = {
    n?: InputMaybe<Scalars["Int"]>
    programID?: InputMaybe<Scalars["Int"]>
    threadID?: InputMaybe<Scalars["Int"]>
}

export type Mutation = {
    __typename?: "Mutation"
    addEvent?: Maybe<Scalars["Void"]>
    /**
     *  Add several participants to a program.  Duplicates not allowed, the set of added participants will be returned.
     * If a participant is a duplicate, a NULL entry will be returned in the return array in the correct place.
     * If a participant is a registered user, their Participant record will be populated and returned (and company will NOT be updated).
     * If a participant is NOT a registered user, their returned participant record will reflect that (registered: false)
     */
    addProgramParticipants?: Maybe<Array<Maybe<Participant>>>
    aiCancelEditing?: Maybe<Thread>
    aiGenerateContinueWritingTopic?: Maybe<Scalars["String"]>
    aiGenerateQuestionTopic?: Maybe<Scalars["String"]>
    authenticate: AuthenticateResponse
    cloneProgram?: Maybe<Program>
    /** Create a copy of a thread using the latest version or the specific thread version if specified */
    cloneThread: Thread
    createAttachment: Attachment
    createAttachmentFromHandle: Attachment
    createAttachmentFromUrl: Attachment
    createLink: Link
    createPlaylist?: Maybe<Playlist>
    createProgram?: Maybe<Program>
    createThread: Thread
    createUser?: Maybe<User>
    deleteLink: Link
    /** deleteProgram deletes the program with the specified guid and returns the integer id upon success, or null if the program could not be deleted */
    deleteProgram?: Maybe<Scalars["Int"]>
    deleteThread: DeleteThreadResponse
    editLink: Link
    editThread: Thread
    generateThread: Thread
    getPathFromLinkToken?: Maybe<Scalars["String"]>
    /** loginAs enables an administrator to login as a non-administrator user */
    loginAs: AuthenticateResponse
    markThreadAsPublished: ThreadVersionHistory
    modifyAttachment: Attachment
    removeProgramParticipants?: Maybe<Scalars["Boolean"]>
    reportFrontEndError?: Maybe<Scalars["Boolean"]>
    /** resetThread deletes all telemetry data with the specified program guid and thread guid and returns the thread integer id upon success, or null if the telemetry data could not be deleted */
    resetThread?: Maybe<Scalars["Int"]>
    sendCode?: Maybe<Scalars["Void"]>
    setProgramContents?: Maybe<Array<ProgramContent>>
    /**  setProgramNotificationConfig will update the slack connection and channel that "social" notifications should be sent to.  A ConnectionDetails structure with the single channel specified in the channels array will be returned */
    setProgramNotificationConfig?: Maybe<ConnectionDetails>
    /** Query participants of a program */
    setProgramParticipantVisibility: Participant
    setThreadInfo?: Maybe<ThreadInfo>
    signUp: AuthenticateResponse
    /** Get the state required to start/continue a thread within a programs */
    startThread?: Maybe<StartThreadResponse>
    updatePlaylist?: Maybe<Playlist>
    updateProgram?: Maybe<Program>
    updateUser?: Maybe<User>
    updateUserProfile: User
}

export type MutationAddEventArgs = {
    input: AddEventInput
}

export type MutationAddProgramParticipantsArgs = {
    guid: Scalars["String"]
    participants?: InputMaybe<Array<ParticipantInput>>
}

export type MutationAiCancelEditingArgs = {
    threadGUID: Scalars["String"]
}

export type MutationAiGenerateContinueWritingTopicArgs = {
    specs: AiGenerateContinueWritingTopicInput
}

export type MutationAiGenerateQuestionTopicArgs = {
    specs: AiGenerateQuestionTopicInput
}

export type MutationAuthenticateArgs = {
    input: AuthenticateInput
}

export type MutationCloneProgramArgs = {
    guid: Scalars["String"]
}

export type MutationCloneThreadArgs = {
    threadID: Scalars["Int"]
    threadVersionID?: InputMaybe<Scalars["Int"]>
}

export type MutationCreateAttachmentArgs = {
    changes?: InputMaybe<Scalars["String"]>
    file: Scalars["Upload"]
}

export type MutationCreateAttachmentFromHandleArgs = {
    handle: Scalars["String"]
}

export type MutationCreateAttachmentFromUrlArgs = {
    imageUrl: Scalars["String"]
}

export type MutationCreateLinkArgs = {
    input?: InputMaybe<LinkInput>
}

export type MutationCreateThreadArgs = {
    input?: InputMaybe<CreateThreadInput>
}

export type MutationDeleteLinkArgs = {
    id: Scalars["Int"]
}

export type MutationDeleteProgramArgs = {
    guid: Scalars["String"]
}

export type MutationDeleteThreadArgs = {
    threadId: Scalars["Int"]
}

export type MutationEditLinkArgs = {
    id: Scalars["Int"]
    input?: InputMaybe<LinkInput>
}

export type MutationEditThreadArgs = {
    edit: EditThreadInput
    threadId: Scalars["Int"]
    threadVersionId: Scalars["Int"]
}

export type MutationGenerateThreadArgs = {
    input?: InputMaybe<GenerateThreadInput>
}

export type MutationGetPathFromLinkTokenArgs = {
    token: Scalars["String"]
}

export type MutationLoginAsArgs = {
    id: Scalars["Int"]
}

export type MutationMarkThreadAsPublishedArgs = {
    threadId: Scalars["Int"]
    threadVersionId: Scalars["Int"]
}

export type MutationModifyAttachmentArgs = {
    changes?: InputMaybe<Scalars["String"]>
    guid: Scalars["String"]
}

export type MutationRemoveProgramParticipantsArgs = {
    emails?: InputMaybe<Array<Scalars["String"]>>
    guid: Scalars["String"]
}

export type MutationReportFrontEndErrorArgs = {
    error: FrontEndError
}

export type MutationResetThreadArgs = {
    programId: Scalars["Int"]
    threadId: Scalars["Int"]
}

export type MutationSendCodeArgs = {
    email: Scalars["EmailAddress"]
}

export type MutationSetProgramContentsArgs = {
    content?: InputMaybe<Array<ProgramContentInput>>
    guid: Scalars["String"]
}

export type MutationSetProgramNotificationConfigArgs = {
    channel: Scalars["String"]
    connection: Scalars["String"]
    guid: Scalars["String"]
}

export type MutationSetProgramParticipantVisibilityArgs = {
    hidden: Scalars["Boolean"]
    programGUID: Scalars["String"]
    userID: Scalars["Int"]
}

export type MutationSetThreadInfoArgs = {
    guid: Scalars["String"]
    thread: ThreadData
}

export type MutationSignUpArgs = {
    input: SignUpInput
}

export type MutationStartThreadArgs = {
    context?: InputMaybe<SessionContextInput>
    programGUID: Scalars["String"]
    threadGUID: Scalars["String"]
}

export type MutationUpdatePlaylistArgs = {
    guid: Scalars["String"]
    playlist: PlaylistData
}

export type MutationUpdateProgramArgs = {
    guid: Scalars["String"]
    updates: ProgramUpdate
}

export type MutationUpdateUserArgs = {
    id: Scalars["Int"]
    user: UserData
}

export type MutationUpdateUserProfileArgs = {
    input: UpdateUserProfileInput
}

export type NodeTiming = {
    __typename?: "NodeTiming"
    expectedTime: Scalars["Int"]
    id: Scalars["String"]
    percentComplete: Scalars["Float"]
    percentCompleteIncludingSelf: Scalars["Float"]
    remainingTime: Scalars["Int"]
}

/** The type of a object inside a thread */
export enum ObjectType {
    Conditional = "CONDITIONAL",
    EmbeddedVideo = "EMBEDDED_VIDEO",
    FreeTextInput = "FREE_TEXT_INPUT",
    Global = "GLOBAL",
    MsDelay = "MS_DELAY",
    MultipleChoiceChooseMultiple = "MULTIPLE_CHOICE_CHOOSE_MULTIPLE",
    MultipleChoiceChooseOne = "MULTIPLE_CHOICE_CHOOSE_ONE",
    StaticImage = "STATIC_IMAGE",
    Text = "TEXT",
}

export type ParseError = {
    __typename?: "ParseError"
    comment?: Maybe<Scalars["String"]>
    path: Scalars["String"]
    type: ParseErrorType
}

/** The type of a object inside a thread */
export enum ParseErrorType {
    DuplicateObjectId = "DUPLICATE_OBJECT_ID",
    MalformedJson = "MALFORMED_JSON",
    MalformedType = "MALFORMED_TYPE",
    MalformedValue = "MALFORMED_VALUE",
    MissingRequiredKey = "MISSING_REQUIRED_KEY",
    NotImplemented = "NOT_IMPLEMENTED",
    UnexpectedKey = "UNEXPECTED_KEY",
    UnknownType = "UNKNOWN_TYPE",
    UnsupportedConditional = "UNSUPPORTED_CONDITIONAL",
}

export type Participant = {
    __typename?: "Participant"
    company?: Maybe<Scalars["String"]>
    email: Scalars["String"]
    firstName?: Maybe<Scalars["String"]>
    hidden: Scalars["Boolean"]
    id?: Maybe<Scalars["Int"]>
    imageURL?: Maybe<Scalars["String"]>
    lastName?: Maybe<Scalars["String"]>
    registered: Scalars["Boolean"]
}

export type ParticipantInput = {
    company?: InputMaybe<Scalars["String"]>
    email: Scalars["String"]
}

/** An overview of a person that contains display information that is not sensitive */
export type Person = {
    __typename?: "Person"
    city: Scalars["String"]
    company: Scalars["String"]
    description: Scalars["String"]
    /**  SECURITY NOTE: should email really be discoverable?  */
    email: Scalars["String"]
    emoji: Scalars["String"]
    firstName: Scalars["String"]
    id: Scalars["Int"]
    lastName: Scalars["String"]
    profileImageURL?: Maybe<Scalars["String"]>
    pronouns: Scalars["String"]
    role: Scalars["String"]
    state: Scalars["String"]
}

export type Playlist = {
    __typename?: "Playlist"
    description: Scalars["String"]
    guid: Scalars["String"]
    id: Scalars["Int"]
    imageURL?: Maybe<Scalars["String"]>
    length: Scalars["Int"]
    threadIDs?: Maybe<Array<Scalars["Int"]>>
    title: Scalars["String"]
}

export type PlaylistData = {
    description: Scalars["String"]
    imageGUID?: InputMaybe<Scalars["String"]>
    threadIDs?: InputMaybe<Array<Scalars["Int"]>>
    title: Scalars["String"]
}

export type PlaylistSearchResult = {
    __typename?: "PlaylistSearchResult"
    playlist: Playlist
    /** A floating point representation of how good the search result is */
    score: Scalars["Float"]
}

export type PreviewThreadResponse = {
    __typename?: "PreviewThreadResponse"
    telemetrySessionId: Scalars["Int"]
    thread: Thread
    /** The values of global variables */
    variables?: Maybe<Array<VariableWithValue>>
}

export type Program = {
    __typename?: "Program"
    defaultSender?: Maybe<Scalars["Int"]>
    description?: Maybe<Scalars["String"]>
    displayName: Scalars["String"]
    endTime?: Maybe<Scalars["Time"]>
    guid: Scalars["String"]
    id: Scalars["Int"]
    imageURL?: Maybe<Scalars["String"]>
    isSingleShareThread: Scalars["Boolean"]
    leaderboard: Scalars["Boolean"]
    live: Scalars["Boolean"]
    name: Scalars["String"]
    socialFeatures: Scalars["Boolean"]
    socialSidebar: Scalars["Boolean"]
    startTime?: Maybe<Scalars["Time"]>
    threadsInOrder: Scalars["Boolean"]
    updatedAt: Scalars["Time"]
}

export type ProgramContent = EmailMessage | ProgramThread | SlackMessage

export type ProgramContentInput = {
    email?: InputMaybe<EmailMessageInput>
    slack?: InputMaybe<SlackMessageInput>
    thread?: InputMaybe<ProgramThreadInput>
}

export type ProgramMessage = EmailMessage | SlackMessage

export type ProgramMessageInput = {
    email?: InputMaybe<EmailMessageInput>
    slack?: InputMaybe<SlackMessageInput>
}

export type ProgramProgress = {
    __typename?: "ProgramProgress"
    lastActivity?: Maybe<Scalars["Time"]>
    percentComplete: Scalars["Float"]
    person: Person
    totalScore: Scalars["Float"]
}

export type ProgramProgressInput = {
    programGUID: Scalars["String"]
}

export type ProgramTelemetry = {
    __typename?: "ProgramTelemetry"
    id: Scalars["String"]
    threadAggregates?: Maybe<Array<ThreadAggregateTelemetry>>
    threadInfo?: Maybe<Array<ThreadRef>>
    userInfo?: Maybe<Array<Person>>
    userThreadData?: Maybe<Array<ThreadTelemetry>>
}

export type ProgramThread = {
    __typename?: "ProgramThread"
    clientData?: Maybe<Scalars["String"]>
    lockTime?: Maybe<Scalars["Time"]>
    messages?: Maybe<Array<ProgramMessage>>
    thread: Thread
    unlockTime?: Maybe<Scalars["Time"]>
}

export type ProgramThreadInput = {
    clientData?: InputMaybe<Scalars["String"]>
    guid: Scalars["String"]
    lockTime?: InputMaybe<Scalars["Time"]>
    messages?: InputMaybe<Array<ProgramMessageInput>>
    unlockTime?: InputMaybe<Scalars["Time"]>
}

export type ProgramUpdate = {
    defaultSender?: InputMaybe<Scalars["Int"]>
    description?: InputMaybe<Scalars["String"]>
    displayName?: InputMaybe<Scalars["String"]>
    imageGUID?: InputMaybe<Scalars["String"]>
    leaderboard?: InputMaybe<Scalars["Boolean"]>
    live?: InputMaybe<Scalars["Boolean"]>
    name?: InputMaybe<Scalars["String"]>
    socialFeatures?: InputMaybe<Scalars["Boolean"]>
    socialSidebar?: InputMaybe<Scalars["Boolean"]>
    threadsInOrder?: InputMaybe<Scalars["Boolean"]>
}

export type Progress = {
    __typename?: "Progress"
    percentComplete: Scalars["Float"]
    status: ThreadStatus
}

export enum QaFlowStatus {
    Complete = "COMPLETE",
    ResubscribeWithInput = "RESUBSCRIBE_WITH_INPUT",
    Streaming = "STREAMING",
}

export type Query = {
    __typename?: "Query"
    /** Return basic information about the currently logged in user */
    aboutMe: User
    /** Get the contents of the AI Edit menu */
    aiEditMenu?: Maybe<Array<AiEditMenuItem>>
    /** Generate an edit by performing the given edit type on the content */
    aiGenEditContent?: Maybe<Scalars["String"]>
    aiGenEditDebug?: Maybe<Scalars["String"]>
    allInstructors: Array<Person>
    /** Read an attachment */
    attachment?: Maybe<Attachment>
    checkEmail: Scalars["Boolean"]
    /** Return the dynamic variables for the given query */
    dynamicVariable?: Maybe<Array<Maybe<VariableWithValue>>>
    /** Return information about the set of users in a program that the user is enrolled in */
    enrolledProgramParticipants?: Maybe<Array<EnrolledProgramParticipant>>
    /** Return information about the set of threads in a program that the user is enrolled in */
    enrolledProgramThreads?: Maybe<Array<EnrolledProgramThread>>
    /** Return descriptions of the set of programs that a user is enrolled in */
    enrolledPrograms?: Maybe<Array<EnrolledProgram>>
    /** Return links for program */
    events?: Maybe<Array<Event>>
    /** Return thread content, timing data, and a description of the variables used in the thread */
    fullThreadContent?: Maybe<ThreadBundle>
    getAllCreatorToolThreads: Array<CreatorToolThreadBrief>
    /** Return catalog playlists */
    getAllPlaylists?: Maybe<Array<Playlist>>
    getThreadByGuid: Thread
    getThreadInfo?: Maybe<ThreadInfo>
    getThreadVersionHistoryByGuid: Array<Maybe<ThreadVersionHistory>>
    /** List the set of global variables in the platform */
    globalVariables?: Maybe<Array<VariableDefinition>>
    imageSearch: ImageSearchResponse
    /** Get the latest user activity across all threads */
    latestActivity?: Maybe<Array<UserActivity>>
    /** Get the latest free text responses for a specific question in a thread & program */
    latestFreetextResponses?: Maybe<Array<FreeTextResponse>>
    /** Return links for program */
    links?: Maybe<Array<Link>>
    /** Return machine-generated advice about how to make a thread better */
    lintThread?: Maybe<Array<Issue>>
    playlist?: Maybe<Playlist>
    /** Return expanded thread and variables for preview pane */
    previewThreadByGuid: PreviewThreadResponse
    /** Query programs */
    program?: Maybe<Array<Program>>
    /** Query threads in a program */
    programContents?: Maybe<Array<ProgramContent>>
    /** Query program notification configuration. A ConnectionDetails structure with the single channel specified in the channels array will be returned, or null if notifications are not configured */
    programNotificationConfig?: Maybe<ConnectionDetails>
    /** Query participants of a program */
    programParticipants?: Maybe<Array<Participant>>
    programProgress?: Maybe<Array<Maybe<ProgramProgress>>>
    /** Playlist search */
    searchPlaylists?: Maybe<Array<PlaylistSearchResult>>
    /** New (soon to be implemented) Thread search */
    searchThreads?: Maybe<Array<ThreadSearchResult>>
    /** List the configured slack connections available */
    slackConnections?: Maybe<Array<Maybe<ConnectionDetails>>>
    /** Return data from telemetry for a program */
    telemetryForProgram?: Maybe<ProgramTelemetry>
    thread: Thread
    threadProgressForProgram?: Maybe<Array<Maybe<ThreadProgressForProgram>>>
    /** Return full information about a user */
    user?: Maybe<User>
    /** Return a set of users */
    users?: Maybe<Array<Person>>
    /**
     * Enumerate the set of variables present in a set of threads.  When duplicate variables
     * are found in multiple threads, only the first instance will be listed
     */
    variablesFromThreads?: Maybe<Array<Maybe<VariableDescription>>>
    /** Return the commit hash of this build */
    version: Scalars["String"]
}

export type QueryAiGenEditContentArgs = {
    content: Scalars["String"]
    type?: InputMaybe<EditType>
}

export type QueryAiGenEditDebugArgs = {
    Content: Scalars["String"]
    Instruction: Scalars["String"]
}

export type QueryAttachmentArgs = {
    guid: Scalars["String"]
}

export type QueryCheckEmailArgs = {
    email: Scalars["String"]
}

export type QueryDynamicVariableArgs = {
    input: DynamicVariableQuery
}

export type QueryEnrolledProgramParticipantsArgs = {
    guid: Scalars["String"]
}

export type QueryEnrolledProgramThreadsArgs = {
    guid: Scalars["String"]
}

export type QueryEventsArgs = {
    notTypes?: InputMaybe<Array<Scalars["String"]>>
    onlyTypes?: InputMaybe<Array<Scalars["String"]>>
}

export type QueryFullThreadContentArgs = {
    input?: InputMaybe<ThreadSpec>
}

export type QueryGetThreadByGuidArgs = {
    threadGUID: Scalars["String"]
}

export type QueryGetThreadInfoArgs = {
    input?: InputMaybe<ThreadSpec>
}

export type QueryGetThreadVersionHistoryByGuidArgs = {
    threadGUID: Scalars["String"]
}

export type QueryImageSearchArgs = {
    input: ImageSearchInput
}

export type QueryLatestFreetextResponsesArgs = {
    objectID: Scalars["String"]
    programGUID: Scalars["String"]
    threadGUID: Scalars["String"]
}

export type QueryLinksArgs = {
    programID: Scalars["Int"]
}

export type QueryLintThreadArgs = {
    input: ThreadSpec
}

export type QueryPlaylistArgs = {
    guid: Scalars["String"]
}

export type QueryPreviewThreadByGuidArgs = {
    threadGUID: Scalars["String"]
}

export type QueryProgramArgs = {
    guid?: InputMaybe<Scalars["String"]>
    searchTerm?: InputMaybe<Scalars["String"]>
}

export type QueryProgramContentsArgs = {
    guid: Scalars["String"]
}

export type QueryProgramNotificationConfigArgs = {
    guid: Scalars["String"]
}

export type QueryProgramParticipantsArgs = {
    guid: Scalars["String"]
}

export type QueryProgramProgressArgs = {
    input?: InputMaybe<ProgramProgressInput>
}

export type QuerySearchPlaylistsArgs = {
    onlyPublished?: InputMaybe<Scalars["Boolean"]>
    searchTerm?: InputMaybe<Scalars["String"]>
}

export type QuerySearchThreadsArgs = {
    input: ThreadSearchQuery
}

export type QueryTelemetryForProgramArgs = {
    guid: Scalars["String"]
    user?: InputMaybe<Scalars["String"]>
}

export type QueryThreadArgs = {
    id: Scalars["Int"]
}

export type QueryThreadProgressForProgramArgs = {
    input?: InputMaybe<ThreadProgressForProgramInput>
}

export type QueryUserArgs = {
    id: Scalars["Int"]
}

export type QueryUsersArgs = {
    searchTerm?: InputMaybe<Scalars["String"]>
}

export type QueryVariablesFromThreadsArgs = {
    onlyUndefined?: InputMaybe<Scalars["Boolean"]>
    threads: Array<ThreadSpec>
}

export enum QuestionFormat {
    FillInTheBlanks = "FILL_IN_THE_BLANKS",
    FreeText = "FREE_TEXT",
    MultipleChoice = "MULTIPLE_CHOICE",
    TrueFalse = "TRUE_FALSE",
}

export enum QuestionIntent {
    EncourageReflection = "ENCOURAGE_REFLECTION",
    PlayfulTrivia = "PLAYFUL_TRIVIA",
    ProbeKnowledge = "PROBE_KNOWLEDGE",
    ReinforceIdeas = "REINFORCE_IDEAS",
    TestUnderstanding = "TEST_UNDERSTANDING",
}

export enum QuestionResponseStyle {
    None = "NONE",
    Terse = "TERSE",
    Verbose = "VERBOSE",
}

export enum Role {
    Admin = "ADMIN",
    Creator = "CREATOR",
    User = "USER",
}

export type RunQaFlowOutput = {
    __typename?: "RunQAFlowOutput"
    content?: Maybe<Scalars["String"]>
    context?: Maybe<Scalars["String"]>
    next: QaFlowStatus
}

export type Score = {
    __typename?: "Score"
    basePoints?: Maybe<Scalars["Float"]>
    bonusPoints?: Maybe<Scalars["Float"]>
    points?: Maybe<Scalars["Float"]>
}

export type SessionContextInput = {
    clientTime: Scalars["Time"]
    deviceId: Scalars["String"]
    userAgent: Scalars["String"]
    viewportHeight: Scalars["Int"]
    viewportWidth: Scalars["Int"]
}

export enum Severity {
    Advice = "ADVICE",
    Warning = "WARNING",
}

export type SignUpInput = {
    email: Scalars["EmailAddress"]
    firstName: Scalars["String"]
    lastName?: InputMaybe<Scalars["String"]>
}

export type SlackMessage = {
    __typename?: "SlackMessage"
    /**  the guid of an attachment to display with the slack message  */
    attachment?: Maybe<Attachment>
    body: Scalars["String"]
    clientData?: Maybe<Scalars["String"]>
    deliveryTime: Scalars["Time"]
    link?: Maybe<Scalars["String"]>
    title: Scalars["String"]
}

export type SlackMessageInput = {
    /**  the guid of an attachment to display with the slack message  */
    attachmentGUID?: InputMaybe<Scalars["String"]>
    body: Scalars["String"]
    clientData?: InputMaybe<Scalars["String"]>
    deliveryTime: Scalars["Time"]
    link?: InputMaybe<Scalars["String"]>
    title: Scalars["String"]
}

/** Everything required to begin playing a thread */
export type StartThreadResponse = {
    __typename?: "StartThreadResponse"
    /** Challenger */
    challenger?: Maybe<Person>
    /** When resuming a thread, the current object ID for this user.  Nil when starting a thread */
    currentObjectID?: Maybe<Scalars["String"]>
    /** Percentage completion of this thread. */
    percentComplete: Scalars["Float"]
    /** sessionID will be null when thread status is not UNLOCKED or IN_PROGRESS, otherwise it will be the identifier for a new session you can add telemetry events to */
    sessionID?: Maybe<Scalars["Int"]>
    /** Status of the thread */
    status?: Maybe<ThreadStatus>
    thread: Thread
    /** The set of user answers provided required to replay the thread to the current object */
    userAnswers?: Maybe<Array<UserAnswer>>
    /** The values of global variables */
    variables?: Maybe<Array<VariableWithValue>>
}

export type Subscription = {
    __typename?: "Subscription"
    /** Generate written content */
    aiContinueWriting?: Maybe<ThreadFragment>
    /** Generate a question block */
    aiGenerateQuestion?: Maybe<ThreadFragment>
    /** Generate a system response for a user input  */
    handleUserInput: SystemResponse
    indexContentSource: ContentIndexingProgress
}

export type SubscriptionAiContinueWritingArgs = {
    specs: AiContinueWritingInput
}

export type SubscriptionAiGenerateQuestionArgs = {
    specs: AiGenerateQuestionInput
}

export type SubscriptionHandleUserInputArgs = {
    input?: InputMaybe<UserInput>
}

export type SubscriptionIndexContentSourceArgs = {
    url: ContentSourceInput
}

export type SystemResponse = {
    __typename?: "SystemResponse"
    choiceData?: Maybe<Array<ChoiceData>>
    content?: Maybe<Scalars["String"]>
    correct?: Maybe<Scalars["Boolean"]>
    correctness?: Maybe<Scalars["Float"]>
    percentComplete: Scalars["Float"]
    score?: Maybe<Score>
}

export enum TelemetryActor {
    System = "SYSTEM",
    User = "USER",
}

export type TelemetryEvent = {
    __typename?: "TelemetryEvent"
    actor: Scalars["String"]
    aiChatDynamicContent?: Maybe<Scalars["String"]>
    aiChatDynamicContext?: Maybe<Scalars["String"]>
    clientTime: Scalars["Time"]
    elapsedTime: Scalars["Int"]
    inputChoices?: Maybe<Array<Maybe<Choice>>>
    /**  when type is input event, the type of input object that generated the event */
    inputType?: Maybe<Scalars["String"]>
    inputValue?: Maybe<Scalars["String"]>
    inputValueList?: Maybe<Array<Maybe<Scalars["String"]>>>
    learningTime: Scalars["Int"]
    msOffset: Scalars["Int"]
    notificationAnchorMessage?: Maybe<Scalars["String"]>
    notificationAnchorSender?: Maybe<Scalars["String"]>
    notificationDefaultAvatar?: Maybe<Scalars["Boolean"]>
    notificationGroupReplies?: Maybe<Scalars["Boolean"]>
    /**  notification events have additional data  */
    notificationNature?: Maybe<Scalars["String"]>
    notificationObject?: Maybe<Scalars["String"]>
    notificationSender?: Maybe<Scalars["String"]>
    notificationValue?: Maybe<Scalars["String"]>
    object: Scalars["String"]
    /**  render events have a percent completed property  */
    percentCompleted?: Maybe<Scalars["Float"]>
    /**  a text rendering of the value that was saved to the variable if relevant for this event type */
    saveToTextValue?: Maybe<Scalars["String"]>
    /**  when relevant, the name of the variable that this event populated */
    saveToVariable?: Maybe<Scalars["String"]>
    session: Scalars["Int"]
    tookBreak: Scalars["Boolean"]
    type: Scalars["String"]
    wasDistracted: Scalars["Boolean"]
}

export type TelemetrySession = {
    __typename?: "TelemetrySession"
    clientTimeWritten: Scalars["Time"]
    deviceID?: Maybe<Scalars["String"]>
    program: Scalars["Int"]
    serverTimeWritten: Scalars["Time"]
    session: Scalars["Int"]
    thread: Scalars["String"]
    threadVersion: Scalars["Time"]
    /** threadVersionID is ephemeral internal internal integer id for a thread, it is for information purposes only */
    threadVersionID: Scalars["Int"]
    user: Scalars["Int"]
    userAgent?: Maybe<Scalars["String"]>
    viewportHeight?: Maybe<Scalars["Int"]>
    viewportWidth?: Maybe<Scalars["Int"]>
}

export type Thread = {
    __typename?: "Thread"
    aiEditing?: Maybe<Scalars["Boolean"]>
    aiParams?: Maybe<AiParams>
    attachments?: Maybe<Array<Attachment>>
    blocks?: Maybe<Scalars["String"]>
    description?: Maybe<Scalars["String"]>
    font?: Maybe<Scalars["String"]>
    /** the string GUID of the thread */
    guid: Scalars["String"]
    hasCustomCoverImage?: Maybe<Scalars["Boolean"]>
    hasScore?: Maybe<Scalars["Boolean"]>
    id: Scalars["Int"]
    imageURL?: Maybe<Scalars["String"]>
    instructor?: Maybe<Person>
    lastUpdateTime: Scalars["Time"]
    preview?: Maybe<Scalars["String"]>
    synopsis?: Maybe<Scalars["String"]>
    theme?: Maybe<Scalars["String"]>
    threadVersionID: Scalars["Int"]
    title: Scalars["String"]
    /** thread version (RFC3339 formatted CreatedAt date of the thread) */
    version: Scalars["String"]
}

export type ThreadAggregateTelemetry = {
    __typename?: "ThreadAggregateTelemetry"
    breaks?: Maybe<Scalars["Float"]>
    distractions?: Maybe<Scalars["Float"]>
    elapsedTime: Scalars["Int"]
    learningTime: Scalars["Int"]
    percentComplete?: Maybe<Scalars["Float"]>
    sessions?: Maybe<Scalars["Float"]>
    threadGUID: Scalars["String"]
}

export type ThreadBrief = {
    __typename?: "ThreadBrief"
    guid: Scalars["String"]
    id: Scalars["Int"]
    title: Scalars["String"]
}

export type ThreadBundle = {
    __typename?: "ThreadBundle"
    parseErrors?: Maybe<Array<ParseError>>
    thread: Thread
    timingData: ThreadTiming
    variableDescription?: Maybe<Array<VariableDescription>>
}

export type ThreadData = {
    internalNotes?: InputMaybe<Scalars["String"]>
    mayUpdateProfile?: InputMaybe<Scalars["Boolean"]>
    public?: InputMaybe<Scalars["Boolean"]>
}

export type ThreadEvent = {
    __typename?: "ThreadEvent"
    actor: TelemetryActor
    data: Scalars["String"]
    id: Scalars["Int"]
    msOffset: Scalars["Int"]
    telemetrySessionId: Scalars["Int"]
    type: Scalars["String"]
}

export type ThreadFragment = {
    __typename?: "ThreadFragment"
    guid: Scalars["String"]
    index: Scalars["Int"]
    object: Scalars["String"]
    parentGUID?: Maybe<Scalars["String"]>
    threadGUID: Scalars["String"]
    threadId: Scalars["Int"]
    threadVersionId: Scalars["Int"]
}

export type ThreadInfo = {
    __typename?: "ThreadInfo"
    createdAt: Scalars["Time"]
    /** the string GUID of the thread */
    guid: Scalars["String"]
    id: Scalars["Int"]
    internalNotes?: Maybe<Scalars["String"]>
    mayUpdateProfile?: Maybe<Scalars["Boolean"]>
    public?: Maybe<Scalars["Boolean"]>
    updatedAt: Scalars["Time"]
}

/**  DEPRECATED: use Progress  */
export type ThreadProgress = {
    __typename?: "ThreadProgress"
    guid: Scalars["String"]
    progress: Scalars["Float"]
    status: ThreadStatus
}

export type ThreadProgressForProgram = {
    __typename?: "ThreadProgressForProgram"
    percentComplete: Scalars["Float"]
    person: Person
    programGuid: Scalars["String"]
    programId: Scalars["Int"]
    status: ThreadStatus
    threadGuid: Scalars["String"]
    threadId: Scalars["Int"]
    threadScore?: Maybe<ThreadScore>
}

export type ThreadProgressForProgramInput = {
    programGUID: Scalars["String"]
    threadGUID?: InputMaybe<Scalars["String"]>
    userIDs?: InputMaybe<Array<Scalars["Int"]>>
}

export type ThreadRef = {
    __typename?: "ThreadRef"
    guid: Scalars["String"]
    id: Scalars["Int"]
    title: Scalars["String"]
}

export type ThreadScore = {
    __typename?: "ThreadScore"
    guid: Scalars["String"]
    /**  The base points earned by the user on this thread (may still be in progress)  */
    totalBasePoints: Scalars["Int"]
    /**  The bonus points earned by the user on this thread (may still be in progress)  */
    totalBonusPoints: Scalars["Int"]
    /**  The total points earned by the user on this thread (may still be in progress)  */
    totalPoints: Scalars["Int"]
}

export type ThreadSearchQuery = {
    count?: InputMaybe<Scalars["Int"]>
    offset?: InputMaybe<Scalars["Int"]>
    onlyPublished?: InputMaybe<Scalars["Boolean"]>
    query: Scalars["String"]
    userID?: InputMaybe<Scalars["Int"]>
}

export type ThreadSearchResult = {
    __typename?: "ThreadSearchResult"
    /** Whether any version of this thread has been published */
    hasPublishedVersion: Scalars["Boolean"]
    /** Live threads have been used in programs */
    live: Scalars["Boolean"]
    /** A floating point representation of how good the search result is */
    score: Scalars["Float"]
    thread: Thread
}

/** A specification of a thread */
export type ThreadSpec = {
    /** the string GUID of the thread */
    guid?: InputMaybe<Scalars["String"]>
    /** the numeric ID of the thread */
    id?: InputMaybe<Scalars["Int"]>
    /** thread version (RFC3339 formatted CreatedAt date of the thread) */
    version?: InputMaybe<Scalars["String"]>
}

export enum ThreadStatus {
    Completed = "COMPLETED",
    Expired = "EXPIRED",
    InProgress = "IN_PROGRESS",
    Locked = "LOCKED",
    Unlocked = "UNLOCKED",
}

export type ThreadTelemetry = {
    __typename?: "ThreadTelemetry"
    breaks: Scalars["Int"]
    distractions: Scalars["Int"]
    elapsedTime: Scalars["Int"]
    endTime: Scalars["Time"]
    events?: Maybe<Array<TelemetryEvent>>
    learningTime: Scalars["Int"]
    percentComplete: Scalars["Float"]
    sessions?: Maybe<Array<TelemetrySession>>
    startTime: Scalars["Time"]
    threadGUID: Scalars["String"]
    threadVersion: Scalars["String"]
    userID: Scalars["Int"]
}

export enum ThreadTemplate {
    CoreContent = "CORE_CONTENT",
    Default = "DEFAULT",
    Exposition = "EXPOSITION",
    Test = "TEST",
    ThreeRings = "THREE_RINGS",
    TriviaQuiz = "TRIVIA_QUIZ",
    TriviaQuizV2 = "TRIVIA_QUIZ_V2",
    Tutor = "TUTOR",
}

export type ThreadTiming = {
    __typename?: "ThreadTiming"
    expectedDurationMS?: Maybe<Scalars["Int"]>
    perObjectTiming?: Maybe<Array<NodeTiming>>
}

export type ThreadVersion = {
    __typename?: "ThreadVersion"
    createdAt: Scalars["Time"]
    id: Scalars["Int"]
    published: Scalars["Boolean"]
    threadId: Scalars["Int"]
    title: Scalars["String"]
}

export type ThreadVersionHistory = {
    __typename?: "ThreadVersionHistory"
    /** the person who created the edit */
    editor?: Maybe<Person>
    /** an internal numerical id corresponding to the edit */
    id: Scalars["Int"]
    /** whether this version was published */
    published: Scalars["Boolean"]
    /** the time of the edit */
    time: Scalars["Time"]
}

/** Updates to user information */
export type UpdateUserProfileInput = {
    city?: InputMaybe<Scalars["String"]>
    company?: InputMaybe<Scalars["String"]>
    description?: InputMaybe<Scalars["String"]>
    emojis?: InputMaybe<Scalars["String"]>
    firstName?: InputMaybe<Scalars["String"]>
    lastName?: InputMaybe<Scalars["String"]>
    links?: InputMaybe<Scalars["String"]>
    profileImageGuid?: InputMaybe<Scalars["String"]>
    pronouns?: InputMaybe<Scalars["String"]>
    role?: InputMaybe<Scalars["String"]>
    state?: InputMaybe<Scalars["String"]>
}

/** Full user information */
export type User = {
    __typename?: "User"
    city?: Maybe<Scalars["String"]>
    company?: Maybe<Scalars["String"]>
    createdAt: Scalars["Time"]
    description?: Maybe<Scalars["String"]>
    distinguishedInstructor?: Maybe<Scalars["Boolean"]>
    email?: Maybe<Scalars["String"]>
    emojis?: Maybe<Scalars["String"]>
    firstName?: Maybe<Scalars["String"]>
    id: Scalars["Int"]
    isAdministrator?: Maybe<Scalars["Boolean"]>
    lastName?: Maybe<Scalars["String"]>
    links?: Maybe<Scalars["String"]>
    mayCreate?: Maybe<Scalars["Boolean"]>
    maySeeExperimentalFeatures?: Maybe<Scalars["Boolean"]>
    maySeeUnpublishedThreads?: Maybe<Scalars["Boolean"]>
    phoneNumber?: Maybe<Scalars["String"]>
    profileImageURL?: Maybe<Scalars["String"]>
    pronouns?: Maybe<Scalars["String"]>
    role?: Maybe<Scalars["String"]>
    state?: Maybe<Scalars["String"]>
    stats: UserStats
    status?: Maybe<Scalars["String"]>
    updatedAt: Scalars["Time"]
}

export type UserActivity = {
    __typename?: "UserActivity"
    durationMS: Scalars["Int"]
    program: Program
    thread: ThreadBrief
    when: Scalars["Time"]
    who: Person
}

export type UserAnswer = {
    __typename?: "UserAnswer"
    name: Scalars["String"]
    /**  The object ID within the thread where the answer was provided */
    object: Scalars["String"]
    systemResponse?: Maybe<SystemResponse>
    timeToAnswer?: Maybe<Scalars["Float"]>
    type: VariableType
    /** The value(s) saved. */
    value?: Maybe<VariableValue>
}

/** Updates to user information */
export type UserData = {
    city?: InputMaybe<Scalars["String"]>
    company?: InputMaybe<Scalars["String"]>
    description?: InputMaybe<Scalars["String"]>
    distinguishedInstructor?: InputMaybe<Scalars["Boolean"]>
    email?: InputMaybe<Scalars["String"]>
    emojis?: InputMaybe<Scalars["String"]>
    firstName?: InputMaybe<Scalars["String"]>
    isAdministrator?: InputMaybe<Scalars["Boolean"]>
    lastName?: InputMaybe<Scalars["String"]>
    links?: InputMaybe<Scalars["String"]>
    mayCreate?: InputMaybe<Scalars["Boolean"]>
    maySeeExperimentalFeatures?: InputMaybe<Scalars["Boolean"]>
    maySeeUnpublishedThreads?: InputMaybe<Scalars["Boolean"]>
    phoneNumber?: InputMaybe<Scalars["String"]>
    profileImageGuid?: InputMaybe<Scalars["String"]>
    pronouns?: InputMaybe<Scalars["String"]>
    role?: InputMaybe<Scalars["String"]>
    state?: InputMaybe<Scalars["String"]>
    status?: InputMaybe<Scalars["String"]>
}

export type UserInput = {
    data?: InputMaybe<UserInputData>
    objectID: Scalars["String"]
    telemetrySessionId: Scalars["Int"]
}

export type UserInputData = {
    timeToAnswer?: InputMaybe<Scalars["Float"]>
    value?: InputMaybe<VariableValueInput>
}

export type UserStats = {
    __typename?: "UserStats"
    losses: Scalars["Int"]
    matches: Scalars["Int"]
    points: Scalars["Int"]
    wins: Scalars["Int"]
}

export enum VariableCategory {
    Environment = "ENVIRONMENT",
    Experience = "EXPERIENCE",
    Local = "LOCAL",
    Profile = "PROFILE",
    Program = "PROGRAM",
    Thread = "THREAD",
}

export type VariableDefinition = {
    __typename?: "VariableDefinition"
    category: VariableCategory
    documentation: Scalars["String"]
    dynamic: Scalars["Boolean"]
    name: Scalars["String"]
    possibleValues?: Maybe<Array<Choice>>
    type: VariableType
    writable: Scalars["Boolean"]
}

export type VariableDescription = {
    __typename?: "VariableDescription"
    /** the variable name */
    name: Scalars["String"]
    /** DEPRECATED: see setInObjects */
    objectWhereBound?: Maybe<Scalars["String"]>
    /** when relevant, the possible values of the variable */
    possibleValues?: Maybe<Array<Choice>>
    /** the type of object where the variable is bound */
    provenance: ObjectType
    /** Object IDs within the thread where the object is referenced */
    referencedIn?: Maybe<Array<Scalars["String"]>>
    /** the IDs of the object where the variable was set. */
    setInObjects?: Maybe<Array<Scalars["String"]>>
    /** If the variable is referenced in user visible text, that is uncluded here */
    textWhereUsed?: Maybe<Array<Scalars["String"]>>
    /** the guid of the thread where this variable is referenced */
    threadGUID: Scalars["String"]
    /** the version of the thread where this varibable is referenced */
    threadVersion: Scalars["Time"]
    /** the type of variable, null if unknown */
    type?: Maybe<VariableType>
}

/** The type of a variable */
export enum VariableType {
    /** Boolean */
    Bool = "BOOL",
    /** Integer */
    Int = "INT",
    None = "NONE",
    /** REFerence to a single choice */
    Ref = "REF",
    /** A REFerence to a SET of choices */
    RefSet = "REF_SET",
    /** String */
    String = "STRING",
}

export type VariableValue = {
    __typename?: "VariableValue"
    /** present when VariableType == BOOL  */
    boolean?: Maybe<Scalars["Boolean"]>
    /** present when VariableType == INT  */
    integer?: Maybe<Scalars["Int"]>
    /** contains the selected choices when VariableType == REF or REF_SET */
    selectedChoices?: Maybe<Array<Choice>>
    /** present when VariableType == STRING  */
    string?: Maybe<Scalars["String"]>
    /** present when VariableType == REF  */
    valueID?: Maybe<Scalars["String"]>
    /** present when VariableType == REF_SET  */
    valueIDs?: Maybe<Array<Scalars["String"]>>
}

export type VariableValueInput = {
    /** present when VariableType == BOOL  */
    boolean?: InputMaybe<Scalars["Boolean"]>
    /** present when VariableType == INT  */
    integer?: InputMaybe<Scalars["Int"]>
    /** present when VariableType == STRING  */
    string?: InputMaybe<Scalars["String"]>
    /** present when VariableType == REF  */
    valueID?: InputMaybe<Scalars["String"]>
    /** present when VariableType == REF_SET  */
    valueIDs?: InputMaybe<Array<Scalars["String"]>>
}

export type VariableWithValue = {
    __typename?: "VariableWithValue"
    name: Scalars["String"]
    type: VariableType
    value: VariableValue
}

export enum WritingVerbosity {
    Average = "AVERAGE",
    Terse = "TERSE",
    Verbose = "VERBOSE",
}

export type SendCodeMutationVariables = Exact<{
    email: Scalars["EmailAddress"]
}>

export type SendCodeMutation = { __typename?: "Mutation"; sendCode?: any | null }

export type AuthenticateMutationVariables = Exact<{
    email: Scalars["EmailAddress"]
    code: Scalars["String"]
}>

export type AuthenticateMutation = {
    __typename?: "Mutation"
    authenticate: {
        __typename?: "AuthenticateResponse"
        jwt: any
        user: {
            __typename?: "User"
            id: number
            email?: string | null
            firstName?: string | null
            lastName?: string | null
        }
    }
}

export type StartThreadMutationVariables = Exact<{
    programGUID: Scalars["String"]
    threadGUID: Scalars["String"]
    context?: InputMaybe<SessionContextInput>
}>

export type StartThreadMutation = {
    __typename?: "Mutation"
    startThread?: {
        __typename?: "StartThreadResponse"
        sessionID?: number | null
        status?: ThreadStatus | null
        currentObjectID?: string | null
        challenger?: {
            __typename?: "Person"
            id: number
            email: string
            firstName: string
            lastName: string
            company: string
            role: string
            city: string
            state: string
            pronouns: string
            description: string
            emoji: string
            profileImageURL?: string | null
        } | null
        userAnswers?: Array<{
            __typename?: "UserAnswer"
            object: string
            name: string
            type: VariableType
            value?: {
                __typename?: "VariableValue"
                boolean?: boolean | null
                integer?: number | null
                string?: string | null
                valueID?: string | null
                valueIDs?: Array<string> | null
            } | null
            systemResponse?: {
                __typename?: "SystemResponse"
                content?: string | null
                correct?: boolean | null
                correctness?: number | null
                percentComplete: number
                choiceData?: Array<{
                    __typename?: "ChoiceData"
                    id: string
                    count?: number | null
                    total?: number | null
                    percent?: number | null
                    correct?: boolean | null
                }> | null
                score?: {
                    __typename?: "Score"
                    basePoints?: number | null
                    bonusPoints?: number | null
                    points?: number | null
                } | null
            } | null
        }> | null
        variables?: Array<{
            __typename?: "VariableWithValue"
            name: string
            type: VariableType
            value: {
                __typename?: "VariableValue"
                boolean?: boolean | null
                integer?: number | null
                string?: string | null
                valueID?: string | null
                valueIDs?: Array<string> | null
                selectedChoices?: Array<{
                    __typename?: "Choice"
                    id: string
                    text?: string | null
                    imageURL?: string | null
                }> | null
            }
        }> | null
        thread: {
            __typename?: "Thread"
            id: number
            guid: string
            preview?: string | null
            synopsis?: string | null
            blocks?: string | null
            lastUpdateTime: any
            title: string
            version: string
            threadVersionID: number
            font?: string | null
            theme?: string | null
            hasScore?: boolean | null
            imageURL?: string | null
            instructor?: {
                __typename?: "Person"
                id: number
                email: string
                firstName: string
                lastName: string
                company: string
                role: string
                city: string
                state: string
                description: string
                emoji: string
                pronouns: string
                profileImageURL?: string | null
            } | null
            attachments?: Array<{
                __typename?: "Attachment"
                guid: string
                changes?: string | null
                type: string
                url: string
            }> | null
        }
    } | null
}

export type AiCancelEditingMutationVariables = Exact<{
    threadGUID: Scalars["String"]
}>

export type AiCancelEditingMutation = {
    __typename?: "Mutation"
    aiCancelEditing?: {
        __typename?: "Thread"
        id: number
        guid: string
        preview?: string | null
        synopsis?: string | null
        blocks?: string | null
        lastUpdateTime: any
        title: string
        version: string
        threadVersionID: number
        theme?: string | null
        font?: string | null
        aiEditing?: boolean | null
        attachments?: Array<{
            __typename?: "Attachment"
            guid: string
            changes?: string | null
            type: string
            url: string
        }> | null
        instructor?: {
            __typename?: "Person"
            id: number
            email: string
            firstName: string
            lastName: string
            company: string
            role: string
            city: string
            state: string
            description: string
            emoji: string
            pronouns: string
            profileImageURL?: string | null
        } | null
        aiParams?: {
            __typename?: "AIParams"
            instructorProfile?: string | null
            referenceMaterial?: string | null
            prompt?: string | null
        } | null
    } | null
}

export type CreateThreadMutationVariables = Exact<{
    input?: InputMaybe<CreateThreadInput>
}>

export type CreateThreadMutation = {
    __typename?: "Mutation"
    createThread: {
        __typename?: "Thread"
        id: number
        guid: string
        blocks?: string | null
        lastUpdateTime: any
        title: string
        version: string
        threadVersionID: number
        aiEditing?: boolean | null
        instructor?: {
            __typename?: "Person"
            id: number
            email: string
            firstName: string
            lastName: string
            company: string
            role: string
            city: string
            state: string
            description: string
            emoji: string
            pronouns: string
            profileImageURL?: string | null
        } | null
        aiParams?: {
            __typename?: "AIParams"
            instructorProfile?: string | null
            referenceMaterial?: string | null
            prompt?: string | null
        } | null
    }
}

export type GenerateThreadMutationVariables = Exact<{
    input?: InputMaybe<GenerateThreadInput>
}>

export type GenerateThreadMutation = {
    __typename?: "Mutation"
    generateThread: {
        __typename?: "Thread"
        id: number
        guid: string
        blocks?: string | null
        lastUpdateTime: any
        title: string
        version: string
        threadVersionID: number
        aiEditing?: boolean | null
        instructor?: {
            __typename?: "Person"
            id: number
            email: string
            firstName: string
            lastName: string
            company: string
            role: string
            city: string
            state: string
            description: string
            emoji: string
            pronouns: string
            profileImageURL?: string | null
        } | null
        aiParams?: {
            __typename?: "AIParams"
            instructorProfile?: string | null
            referenceMaterial?: string | null
            prompt?: string | null
        } | null
    }
}

export type EditThreadMutationVariables = Exact<{
    threadId: Scalars["Int"]
    threadVersionId: Scalars["Int"]
    edit: EditThreadInput
}>

export type EditThreadMutation = {
    __typename?: "Mutation"
    editThread: {
        __typename?: "Thread"
        id: number
        guid: string
        blocks?: string | null
        lastUpdateTime: any
        title: string
        version: string
        threadVersionID: number
        imageURL?: string | null
        hasCustomCoverImage?: boolean | null
        instructor?: {
            __typename?: "Person"
            id: number
            email: string
            firstName: string
            lastName: string
            company: string
            role: string
            city: string
            state: string
            description: string
            emoji: string
            pronouns: string
            profileImageURL?: string | null
        } | null
    }
}

export type DuplicateThreadMutationVariables = Exact<{
    threadID: Scalars["Int"]
    threadVersionID?: InputMaybe<Scalars["Int"]>
}>

export type DuplicateThreadMutation = {
    __typename?: "Mutation"
    cloneThread: {
        __typename?: "Thread"
        id: number
        guid: string
        blocks?: string | null
        lastUpdateTime: any
        title: string
        version: string
        threadVersionID: number
        instructor?: {
            __typename?: "Person"
            id: number
            email: string
            firstName: string
            lastName: string
            company: string
            role: string
            city: string
            state: string
            description: string
            emoji: string
            pronouns: string
            profileImageURL?: string | null
        } | null
    }
}

export type AddParticipantsMutationVariables = Exact<{
    guid: Scalars["String"]
    participants?: InputMaybe<Array<ParticipantInput> | ParticipantInput>
}>

export type AddParticipantsMutation = {
    __typename?: "Mutation"
    addProgramParticipants?: Array<{
        __typename?: "Participant"
        id?: number | null
        registered: boolean
        hidden: boolean
        firstName?: string | null
        lastName?: string | null
        email: string
        company?: string | null
        imageURL?: string | null
    } | null> | null
}

export type SetParticipantVisibilityMutationVariables = Exact<{
    programGUID: Scalars["String"]
    userID: Scalars["Int"]
    hidden: Scalars["Boolean"]
}>

export type SetParticipantVisibilityMutation = {
    __typename?: "Mutation"
    setProgramParticipantVisibility: {
        __typename?: "Participant"
        id?: number | null
        registered: boolean
        hidden: boolean
        firstName?: string | null
        lastName?: string | null
        email: string
        company?: string | null
        imageURL?: string | null
    }
}

export type CreateLinkMutationVariables = Exact<{
    programID?: InputMaybe<Scalars["Int"]>
    threadID?: InputMaybe<Scalars["Int"]>
    n?: InputMaybe<Scalars["Int"]>
}>

export type CreateLinkMutation = {
    __typename?: "Mutation"
    createLink: { __typename?: "Link"; id: number; linkURL: string }
}

export type GetPathFromLinkTokenMutationVariables = Exact<{
    token: Scalars["String"]
}>

export type GetPathFromLinkTokenMutation = {
    __typename?: "Mutation"
    getPathFromLinkToken?: string | null
}

export type LoginAsMutationVariables = Exact<{
    id: Scalars["Int"]
}>

export type LoginAsMutation = {
    __typename?: "Mutation"
    loginAs: { __typename?: "AuthenticateResponse"; jwt: any }
}

export type ReportErrorMutationVariables = Exact<{
    error: FrontEndError
}>

export type ReportErrorMutation = { __typename?: "Mutation"; reportFrontEndError?: boolean | null }

export type SignUpMutationVariables = Exact<{
    email: Scalars["EmailAddress"]
    firstName: Scalars["String"]
}>

export type SignUpMutation = {
    __typename?: "Mutation"
    signUp: {
        __typename?: "AuthenticateResponse"
        jwt: any
        user: { __typename?: "User"; id: number; email?: string | null; firstName?: string | null }
    }
}

export type AiGenerateQuestionTopicMutationVariables = Exact<{
    specs: AiGenerateQuestionTopicInput
}>

export type AiGenerateQuestionTopicMutation = {
    __typename?: "Mutation"
    aiGenerateQuestionTopic?: string | null
}

export type AiGenerateContinueWritingTopicMutationVariables = Exact<{
    specs: AiGenerateContinueWritingTopicInput
}>

export type AiGenerateContinueWritingTopicMutation = {
    __typename?: "Mutation"
    aiGenerateContinueWritingTopic?: string | null
}

export type AddEventMutationVariables = Exact<{
    input: AddEventInput
}>

export type AddEventMutation = { __typename?: "Mutation"; addEvent?: any | null }

export type CloneProgramMutationVariables = Exact<{
    guid: Scalars["String"]
}>

export type CloneProgramMutation = {
    __typename?: "Mutation"
    cloneProgram?: {
        __typename?: "Program"
        id: number
        guid: string
        name: string
        live: boolean
        startTime?: any | null
        endTime?: any | null
        imageURL?: string | null
        socialSidebar: boolean
        isSingleShareThread: boolean
        leaderboard: boolean
    } | null
}

export type CreateAttachmentMutationVariables = Exact<{
    file: Scalars["Upload"]
    changes?: InputMaybe<Scalars["String"]>
}>

export type CreateAttachmentMutation = {
    __typename?: "Mutation"
    createAttachment: {
        __typename?: "Attachment"
        guid: string
        changes?: string | null
        type: string
        url: string
    }
}

export type CreateAttachmentFromHandleMutationVariables = Exact<{
    handle: Scalars["String"]
}>

export type CreateAttachmentFromHandleMutation = {
    __typename?: "Mutation"
    createAttachmentFromHandle: {
        __typename?: "Attachment"
        guid: string
        changes?: string | null
        type: string
        url: string
    }
}

export type CreateAttachmentFromUrlMutationVariables = Exact<{
    imageUrl: Scalars["String"]
}>

export type CreateAttachmentFromUrlMutation = {
    __typename?: "Mutation"
    createAttachmentFromUrl: {
        __typename?: "Attachment"
        guid: string
        changes?: string | null
        type: string
        url: string
    }
}

export type CreateProgramMutationVariables = Exact<{ [key: string]: never }>

export type CreateProgramMutation = {
    __typename?: "Mutation"
    createProgram?: {
        __typename?: "Program"
        id: number
        guid: string
        name: string
        live: boolean
        startTime?: any | null
        endTime?: any | null
        imageURL?: string | null
        socialSidebar: boolean
        isSingleShareThread: boolean
        leaderboard: boolean
    } | null
}

export type DeleteProgramMutationVariables = Exact<{
    guid: Scalars["String"]
}>

export type DeleteProgramMutation = { __typename?: "Mutation"; deleteProgram?: number | null }

export type DeleteThreadMutationVariables = Exact<{
    threadId: Scalars["Int"]
}>

export type DeleteThreadMutation = {
    __typename?: "Mutation"
    deleteThread: { __typename?: "DeleteThreadResponse"; threadId: number }
}

export type MarkThreadAsPublishedMutationVariables = Exact<{
    threadId: Scalars["Int"]
    threadVersionId: Scalars["Int"]
}>

export type MarkThreadAsPublishedMutation = {
    __typename?: "Mutation"
    markThreadAsPublished: {
        __typename?: "ThreadVersionHistory"
        id: number
        time: any
        published: boolean
        editor?: {
            __typename?: "Person"
            id: number
            firstName: string
            lastName: string
            profileImageURL?: string | null
        } | null
    }
}

export type ModifyAttachmentMutationVariables = Exact<{
    guid: Scalars["String"]
    changes?: InputMaybe<Scalars["String"]>
}>

export type ModifyAttachmentMutation = {
    __typename?: "Mutation"
    modifyAttachment: {
        __typename?: "Attachment"
        guid: string
        changes?: string | null
        type: string
        url: string
    }
}

export type RemoveParticipantsMutationVariables = Exact<{
    guid: Scalars["String"]
    emails?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
}>

export type RemoveParticipantsMutation = {
    __typename?: "Mutation"
    removeProgramParticipants?: boolean | null
}

export type ResetThreadMutationVariables = Exact<{
    threadId: Scalars["Int"]
    programId: Scalars["Int"]
}>

export type ResetThreadMutation = { __typename?: "Mutation"; resetThread?: number | null }

export type SetProgramNotificationConfigMutationVariables = Exact<{
    guid: Scalars["String"]
    connection: Scalars["String"]
    channel: Scalars["String"]
}>

export type SetProgramNotificationConfigMutation = {
    __typename?: "Mutation"
    setProgramNotificationConfig?: {
        __typename?: "ConnectionDetails"
        id: string
        name: string
        channels?: Array<{ __typename?: "ChannelDetails"; id: string; name: string }> | null
    } | null
}

export type SetProgramContentsMutationVariables = Exact<{
    guid: Scalars["String"]
    content?: InputMaybe<Array<ProgramContentInput> | ProgramContentInput>
}>

export type SetProgramContentsMutation = {
    __typename?: "Mutation"
    setProgramContents?: Array<
        | {
              __typename: "EmailMessage"
              title: string
              body: string
              deliveryTime: any
              link?: string | null
              clientData?: string | null
              attachment?: {
                  __typename?: "Attachment"
                  guid: string
                  changes?: string | null
                  url: string
              } | null
          }
        | {
              __typename: "ProgramThread"
              lockTime?: any | null
              unlockTime?: any | null
              clientData?: string | null
              thread: { __typename?: "Thread"; id: number; guid: string; title: string }
              messages?: Array<
                  | {
                        __typename: "EmailMessage"
                        title: string
                        body: string
                        deliveryTime: any
                        link?: string | null
                        clientData?: string | null
                        attachment?: {
                            __typename?: "Attachment"
                            guid: string
                            changes?: string | null
                            url: string
                        } | null
                    }
                  | {
                        __typename: "SlackMessage"
                        title: string
                        body: string
                        deliveryTime: any
                        link?: string | null
                        clientData?: string | null
                        attachment?: {
                            __typename?: "Attachment"
                            guid: string
                            changes?: string | null
                            url: string
                        } | null
                    }
              > | null
          }
        | {
              __typename: "SlackMessage"
              title: string
              body: string
              deliveryTime: any
              link?: string | null
              clientData?: string | null
              attachment?: {
                  __typename?: "Attachment"
                  guid: string
                  changes?: string | null
                  url: string
              } | null
          }
    > | null
}

export type UpdateProgramMutationVariables = Exact<{
    guid: Scalars["String"]
    updates: ProgramUpdate
}>

export type UpdateProgramMutation = {
    __typename?: "Mutation"
    updateProgram?: {
        __typename?: "Program"
        id: number
        guid: string
        name: string
        live: boolean
        startTime?: any | null
        endTime?: any | null
        imageURL?: string | null
        socialSidebar: boolean
        isSingleShareThread: boolean
        leaderboard: boolean
        displayName: string
        description?: string | null
        socialFeatures: boolean
        threadsInOrder: boolean
        updatedAt: any
        defaultSender?: number | null
    } | null
}

export type UpdateUserProfileMutationVariables = Exact<{
    firstName?: InputMaybe<Scalars["String"]>
    lastName?: InputMaybe<Scalars["String"]>
    company?: InputMaybe<Scalars["String"]>
    role?: InputMaybe<Scalars["String"]>
    city?: InputMaybe<Scalars["String"]>
    state?: InputMaybe<Scalars["String"]>
    description?: InputMaybe<Scalars["String"]>
    emojis?: InputMaybe<Scalars["String"]>
    links?: InputMaybe<Scalars["String"]>
    pronouns?: InputMaybe<Scalars["String"]>
    profileImageGuid?: InputMaybe<Scalars["String"]>
}>

export type UpdateUserProfileMutation = {
    __typename?: "Mutation"
    updateUserProfile: { __typename?: "User"; id: number }
}

export type UpdateUserProfileImageMutationVariables = Exact<{
    profileImageGuid: Scalars["String"]
}>

export type UpdateUserProfileImageMutation = {
    __typename?: "Mutation"
    updateUserProfile: { __typename?: "User"; profileImageURL?: string | null }
}

export type EnrolledProgramsThreadsQueryVariables = Exact<{
    guid: Scalars["String"]
}>

export type EnrolledProgramsThreadsQuery = {
    __typename?: "Query"
    enrolledProgramThreads?: Array<{
        __typename?: "EnrolledProgramThread"
        id: string
        status: ThreadStatus
        progress?: number | null
        thread: {
            __typename?: "Thread"
            id: number
            guid: string
            title: string
            description?: string | null
            imageURL?: string | null
            instructor?: {
                __typename?: "Person"
                id: number
                firstName: string
                lastName: string
                profileImageURL?: string | null
            } | null
        }
    }> | null
}

export type GetCreatorToolThreadByGuidQueryVariables = Exact<{
    threadGUID: Scalars["String"]
}>

export type GetCreatorToolThreadByGuidQuery = {
    __typename?: "Query"
    getThreadByGuid: {
        __typename?: "Thread"
        id: number
        guid: string
        preview?: string | null
        synopsis?: string | null
        blocks?: string | null
        lastUpdateTime: any
        title: string
        version: string
        threadVersionID: number
        theme?: string | null
        font?: string | null
        description?: string | null
        aiEditing?: boolean | null
        imageURL?: string | null
        hasCustomCoverImage?: boolean | null
        attachments?: Array<{
            __typename?: "Attachment"
            guid: string
            changes?: string | null
            type: string
            url: string
        }> | null
        instructor?: {
            __typename?: "Person"
            id: number
            email: string
            firstName: string
            lastName: string
            company: string
            role: string
            city: string
            state: string
            description: string
            emoji: string
            pronouns: string
            profileImageURL?: string | null
        } | null
        aiParams?: {
            __typename?: "AIParams"
            instructorProfile?: string | null
            referenceMaterial?: string | null
            prompt?: string | null
        } | null
    }
}

export type GetCreatorThreadsQueryVariables = Exact<{ [key: string]: never }>

export type GetCreatorThreadsQuery = {
    __typename?: "Query"
    getAllCreatorToolThreads: Array<{
        __typename?: "CreatorToolThreadBrief"
        id: number
        guid: string
        instructorId: number
        instructor?: {
            __typename?: "Person"
            id: number
            firstName: string
            lastName: string
            profileImageURL?: string | null
        } | null
        threadVersion?: {
            __typename?: "ThreadVersion"
            id: number
            title: string
            published: boolean
            threadId: number
            createdAt: any
        } | null
    }>
}

export type GetGlobalVariablesQueryVariables = Exact<{ [key: string]: never }>

export type GetGlobalVariablesQuery = {
    __typename?: "Query"
    globalVariables?: Array<{
        __typename?: "VariableDefinition"
        category: VariableCategory
        name: string
        type: VariableType
        documentation: string
        dynamic: boolean
        writable: boolean
        possibleValues?: Array<{
            __typename?: "Choice"
            id: string
            text?: string | null
            imageURL?: string | null
        }> | null
    }> | null
}

export type GetProgramQueryVariables = Exact<{
    guid?: InputMaybe<Scalars["String"]>
}>

export type GetProgramQuery = {
    __typename?: "Query"
    program?: Array<{
        __typename?: "Program"
        id: number
        guid: string
        name: string
        live: boolean
        startTime?: any | null
        endTime?: any | null
        imageURL?: string | null
        socialSidebar: boolean
        isSingleShareThread: boolean
        leaderboard: boolean
        displayName: string
        description?: string | null
        socialFeatures: boolean
        threadsInOrder: boolean
        updatedAt: any
        defaultSender?: number | null
    }> | null
}

export type GetProgramsQueryVariables = Exact<{
    search?: InputMaybe<Scalars["String"]>
    guid?: InputMaybe<Scalars["String"]>
}>

export type GetProgramsQuery = {
    __typename?: "Query"
    program?: Array<{
        __typename?: "Program"
        id: number
        guid: string
        name: string
        live: boolean
        startTime?: any | null
        endTime?: any | null
        imageURL?: string | null
        socialSidebar: boolean
        leaderboard: boolean
    }> | null
}

export type GetProgramContentsQueryVariables = Exact<{
    guid: Scalars["String"]
}>

export type GetProgramContentsQuery = {
    __typename?: "Query"
    programContents?: Array<
        | {
              __typename: "EmailMessage"
              title: string
              body: string
              deliveryTime: any
              link?: string | null
              clientData?: string | null
              attachment?: {
                  __typename?: "Attachment"
                  guid: string
                  changes?: string | null
                  url: string
              } | null
          }
        | {
              __typename: "ProgramThread"
              lockTime?: any | null
              unlockTime?: any | null
              clientData?: string | null
              thread: { __typename?: "Thread"; id: number; guid: string; title: string }
              messages?: Array<
                  | {
                        __typename: "EmailMessage"
                        title: string
                        body: string
                        deliveryTime: any
                        link?: string | null
                        clientData?: string | null
                        attachment?: {
                            __typename?: "Attachment"
                            guid: string
                            changes?: string | null
                            url: string
                        } | null
                    }
                  | {
                        __typename: "SlackMessage"
                        title: string
                        body: string
                        deliveryTime: any
                        link?: string | null
                        clientData?: string | null
                        attachment?: {
                            __typename?: "Attachment"
                            guid: string
                            changes?: string | null
                            url: string
                        } | null
                    }
              > | null
          }
        | {
              __typename: "SlackMessage"
              title: string
              body: string
              deliveryTime: any
              link?: string | null
              clientData?: string | null
              attachment?: {
                  __typename?: "Attachment"
                  guid: string
                  changes?: string | null
                  url: string
              } | null
          }
    > | null
}

export type GetThreadVersionHistoryByGuidQueryVariables = Exact<{
    threadGUID: Scalars["String"]
}>

export type GetThreadVersionHistoryByGuidQuery = {
    __typename?: "Query"
    getThreadVersionHistoryByGuid: Array<{
        __typename?: "ThreadVersionHistory"
        id: number
        time: any
        published: boolean
        editor?: {
            __typename?: "Person"
            id: number
            firstName: string
            lastName: string
            profileImageURL?: string | null
        } | null
    } | null>
}

export type GetLintThreadQueryVariables = Exact<{
    id: Scalars["Int"]
}>

export type GetLintThreadQuery = {
    __typename?: "Query"
    lintThread?: Array<{
        __typename?: "Issue"
        id: string
        objectID: string
        description?: string | null
        severity: Severity
        category: Category
    }> | null
}

export type GetAllInstructorsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllInstructorsQuery = {
    __typename?: "Query"
    allInstructors: Array<{
        __typename?: "Person"
        id: number
        firstName: string
        lastName: string
        description: string
        profileImageURL?: string | null
    }>
}

export type EnrolledProgramParticipantsQueryVariables = Exact<{
    guid: Scalars["String"]
}>

export type EnrolledProgramParticipantsQuery = {
    __typename?: "Query"
    enrolledProgramParticipants?: Array<{
        __typename?: "EnrolledProgramParticipant"
        person: {
            __typename?: "Person"
            id: number
            email: string
            firstName: string
            lastName: string
            profileImageURL?: string | null
            company: string
            role: string
            city: string
            state: string
            emoji: string
            description: string
        }
        progress: {
            __typename?: "EnrolledProgramParticipantProgress"
            total: number
            totalScore: number
            lastActivity?: any | null
            threadScore?: Array<{
                __typename?: "ThreadScore"
                guid: string
                totalBasePoints: number
                totalBonusPoints: number
                totalPoints: number
            }> | null
            threadProgress?: Array<{
                __typename?: "ThreadProgress"
                guid: string
                progress: number
                status: ThreadStatus
            }> | null
        }
    }> | null
}

export type EnrolledProgramsQueryVariables = Exact<{ [key: string]: never }>

export type EnrolledProgramsQuery = {
    __typename?: "Query"
    enrolledPrograms?: Array<{
        __typename?: "EnrolledProgram"
        id: number
        name: string
        description?: string | null
        isSingleShareThread: boolean
        firstThreadId?: number | null
        firstThreadGUID?: string | null
        imageURL?: string | null
        guid: string
        socialSidebar: boolean
        leaderboard: boolean
        startTime?: any | null
        progress: { __typename?: "Progress"; percentComplete: number; status: ThreadStatus }
    }> | null
}

export type EnrolledProgramsIdOnlyQueryVariables = Exact<{ [key: string]: never }>

export type EnrolledProgramsIdOnlyQuery = {
    __typename?: "Query"
    enrolledPrograms?: Array<{ __typename?: "EnrolledProgram"; id: number; guid: string }> | null
}

export type GetProgramNotificationConfigQueryVariables = Exact<{
    guid: Scalars["String"]
}>

export type GetProgramNotificationConfigQuery = {
    __typename?: "Query"
    programNotificationConfig?: {
        __typename?: "ConnectionDetails"
        id: string
        name: string
        channels?: Array<{ __typename?: "ChannelDetails"; id: string; name: string }> | null
    } | null
}

export type GetSlackConnectionsQueryVariables = Exact<{ [key: string]: never }>

export type GetSlackConnectionsQuery = {
    __typename?: "Query"
    slackConnections?: Array<{
        __typename?: "ConnectionDetails"
        id: string
        name: string
        channels?: Array<{ __typename?: "ChannelDetails"; id: string; name: string }> | null
    } | null> | null
}

export type PreviewThreadQueryVariables = Exact<{
    threadGUID: Scalars["String"]
}>

export type PreviewThreadQuery = {
    __typename?: "Query"
    previewThreadByGuid: {
        __typename?: "PreviewThreadResponse"
        telemetrySessionId: number
        thread: {
            __typename?: "Thread"
            id: number
            blocks?: string | null
            preview?: string | null
            synopsis?: string | null
            title: string
            instructor?: { __typename?: "Person"; firstName: string; lastName: string } | null
        }
        variables?: Array<{
            __typename?: "VariableWithValue"
            name: string
            type: VariableType
            value: {
                __typename?: "VariableValue"
                string?: string | null
                valueID?: string | null
                integer?: number | null
                boolean?: boolean | null
            }
        }> | null
    }
}

export type GenerateEditContentQueryVariables = Exact<{
    content: Scalars["String"]
    type?: InputMaybe<EditType>
}>

export type GenerateEditContentQuery = { __typename?: "Query"; aiGenEditContent?: string | null }

export type UserPermissionsQueryVariables = Exact<{ [key: string]: never }>

export type UserPermissionsQuery = {
    __typename?: "Query"
    aboutMe: {
        __typename?: "User"
        id: number
        mayCreate?: boolean | null
        distinguishedInstructor?: boolean | null
        maySeeUnpublishedThreads?: boolean | null
        maySeeExperimentalFeatures?: boolean | null
        isAdministrator?: boolean | null
    }
}

export type GetLatestFreeTextResponsesQueryVariables = Exact<{
    programGUID: Scalars["String"]
    threadGUID: Scalars["String"]
    objectID: Scalars["String"]
}>

export type GetLatestFreeTextResponsesQuery = {
    __typename?: "Query"
    latestFreetextResponses?: Array<{
        __typename?: "FreeTextResponse"
        response: string
        author: {
            __typename?: "Person"
            id: number
            email: string
            firstName: string
            lastName: string
            profileImageURL?: string | null
            company: string
            role: string
            city: string
            state: string
            description: string
            emoji: string
        }
    }> | null
}

export type GetProgramParticipantsQueryVariables = Exact<{
    guid: Scalars["String"]
}>

export type GetProgramParticipantsQuery = {
    __typename?: "Query"
    programParticipants?: Array<{
        __typename?: "Participant"
        id?: number | null
        registered: boolean
        hidden: boolean
        firstName?: string | null
        lastName?: string | null
        email: string
        company?: string | null
        imageURL?: string | null
    }> | null
}

export type AboutMeQueryVariables = Exact<{ [key: string]: never }>

export type AboutMeQuery = {
    __typename?: "Query"
    aboutMe: {
        __typename?: "User"
        id: number
        email?: string | null
        firstName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        company?: string | null
        profileImageURL?: string | null
        role?: string | null
        city?: string | null
        state?: string | null
        description?: string | null
        emojis?: string | null
    }
}

export type SearchPlaylistsQueryVariables = Exact<{
    searchTerm?: InputMaybe<Scalars["String"]>
    onlyPublished?: InputMaybe<Scalars["Boolean"]>
}>

export type SearchPlaylistsQuery = {
    __typename?: "Query"
    searchPlaylists?: Array<{
        __typename?: "PlaylistSearchResult"
        playlist: {
            __typename?: "Playlist"
            id: number
            guid: string
            title: string
            description: string
            imageURL?: string | null
            length: number
        }
    }> | null
}

export type SearchThreadsQueryVariables = Exact<{
    input: ThreadSearchQuery
}>

export type SearchThreadsQuery = {
    __typename?: "Query"
    searchThreads?: Array<{
        __typename?: "ThreadSearchResult"
        thread: {
            __typename?: "Thread"
            id: number
            guid: string
            title: string
            lastUpdateTime: any
            instructor?: {
                __typename?: "Person"
                id: number
                firstName: string
                lastName: string
                profileImageURL?: string | null
            } | null
        }
    }> | null
}

export type SearchCreatorTollThreadsMobileQueryVariables = Exact<{
    input: ThreadSearchQuery
}>

export type SearchCreatorTollThreadsMobileQuery = {
    __typename?: "Query"
    searchThreads?: Array<{
        __typename?: "ThreadSearchResult"
        thread: {
            __typename?: "Thread"
            id: number
            guid: string
            title: string
            description?: string | null
            imageURL?: string | null
            lastUpdateTime: any
            instructor?: {
                __typename?: "Person"
                id: number
                firstName: string
                lastName: string
                profileImageURL?: string | null
            } | null
        }
    }> | null
}

export type CheckEmailQueryVariables = Exact<{
    email: Scalars["String"]
}>

export type CheckEmailQuery = { __typename?: "Query"; checkEmail: boolean }

export type ImageSearchQueryVariables = Exact<{
    input: ImageSearchInput
}>

export type ImageSearchQuery = {
    __typename?: "Query"
    imageSearch: {
        __typename?: "ImageSearchResponse"
        value?: Array<{
            __typename?: "ImageResult"
            handle: string
            imageURL: string
            thumbnailURL: string
        }> | null
    }
}

export type GetDynamicVariablesValuesQueryVariables = Exact<{
    input: DynamicVariableQuery
}>

export type GetDynamicVariablesValuesQuery = {
    __typename?: "Query"
    dynamicVariable?: Array<{
        __typename?: "VariableWithValue"
        name: string
        type: VariableType
        value: {
            __typename?: "VariableValue"
            string?: string | null
            valueID?: string | null
            integer?: number | null
            boolean?: boolean | null
        }
    } | null> | null
}

export type ThreadProgressForProgramQueryVariables = Exact<{
    input: ThreadProgressForProgramInput
}>

export type ThreadProgressForProgramQuery = {
    __typename?: "Query"
    threadProgressForProgram?: Array<{
        __typename?: "ThreadProgressForProgram"
        programGuid: string
        threadGuid: string
        person: {
            __typename?: "Person"
            id: number
            email: string
            firstName: string
            lastName: string
            profileImageURL?: string | null
            company: string
            role: string
            city: string
            state: string
            emoji: string
            description: string
        }
        threadScore?: {
            __typename?: "ThreadScore"
            guid: string
            totalBasePoints: number
            totalBonusPoints: number
            totalPoints: number
        } | null
    } | null> | null
}

export type ProgramProgressQueryVariables = Exact<{
    input: ProgramProgressInput
}>

export type ProgramProgressQuery = {
    __typename?: "Query"
    programProgress?: Array<{
        __typename?: "ProgramProgress"
        totalScore: number
        person: {
            __typename?: "Person"
            id: number
            email: string
            firstName: string
            lastName: string
            profileImageURL?: string | null
            company: string
            role: string
            city: string
            state: string
            emoji: string
            description: string
        }
    } | null> | null
}

export type GetThreadDurationQueryVariables = Exact<{
    input?: InputMaybe<ThreadSpec>
}>

export type GetThreadDurationQuery = {
    __typename?: "Query"
    fullThreadContent?: {
        __typename?: "ThreadBundle"
        timingData: { __typename?: "ThreadTiming"; expectedDurationMS?: number | null }
    } | null
}

export type UserProfileMetricsQueryVariables = Exact<{ [key: string]: never }>

export type UserProfileMetricsQuery = {
    __typename?: "Query"
    aboutMe: {
        __typename?: "User"
        id: number
        stats: {
            __typename?: "UserStats"
            matches: number
            points: number
            wins: number
            losses: number
        }
    }
}

export type AiGenerateQuestionSubscriptionVariables = Exact<{
    specs: AiGenerateQuestionInput
}>

export type AiGenerateQuestionSubscription = {
    __typename?: "Subscription"
    aiGenerateQuestion?: {
        __typename?: "ThreadFragment"
        guid: string
        object: string
        parentGUID?: string | null
        index: number
        threadId: number
        threadGUID: string
        threadVersionId: number
    } | null
}

export type AiContinueWritingSubscriptionVariables = Exact<{
    specs: AiContinueWritingInput
}>

export type AiContinueWritingSubscription = {
    __typename?: "Subscription"
    aiContinueWriting?: {
        __typename?: "ThreadFragment"
        guid: string
        object: string
        parentGUID?: string | null
        index: number
        threadId: number
        threadGUID: string
        threadVersionId: number
    } | null
}

export type HandleUserInputSubscriptionVariables = Exact<{
    input?: InputMaybe<UserInput>
}>

export type HandleUserInputSubscription = {
    __typename?: "Subscription"
    handleUserInput: {
        __typename?: "SystemResponse"
        correct?: boolean | null
        correctness?: number | null
        content?: string | null
        percentComplete: number
        score?: {
            __typename?: "Score"
            basePoints?: number | null
            bonusPoints?: number | null
            points?: number | null
        } | null
        choiceData?: Array<{
            __typename?: "ChoiceData"
            id: string
            count?: number | null
            total?: number | null
            percent?: number | null
            correct?: boolean | null
        }> | null
    }
}

export type IndexContentSourceSubscriptionVariables = Exact<{
    url: ContentSourceInput
}>

export type IndexContentSourceSubscription = {
    __typename?: "Subscription"
    indexContentSource: {
        __typename?: "ContentIndexingProgress"
        progress: number
        state: string
        error?: string | null
    }
}

export const SendCodeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "sendCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "EmailAddress" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "sendCode" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "email" },
                                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendCodeMutation, SendCodeMutationVariables>
export const AuthenticateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "authenticate" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "EmailAddress" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "authenticate" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "email" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "email" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "code" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "code" },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "jwt" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AuthenticateMutation, AuthenticateMutationVariables>
export const StartThreadDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "StartThread" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "programGUID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadGUID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "SessionContextInput" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "startThread" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "programGUID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "programGUID" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadGUID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadGUID" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "context" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "context" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "sessionID" } },
                                { kind: "Field", name: { kind: "Name", value: "status" } },
                                { kind: "Field", name: { kind: "Name", value: "currentObjectID" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "challenger" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "role" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "city" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "state" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "pronouns" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "emoji" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "userAnswers" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "object" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "type" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "value" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "boolean",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "integer",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "string" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "valueID",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "valueIDs",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "systemResponse" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "choiceData",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "count",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "total",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "percent",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "correct",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "content",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "correct",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "correctness",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "percentComplete",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "score" },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "basePoints",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "bonusPoints",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "points",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "variables" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "type" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "value" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "boolean",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "integer",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "string" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "valueID",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "valueIDs",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "selectedChoices",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "text",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "imageURL",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "thread" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "guid" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "preview" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "synopsis" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "blocks" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastUpdateTime" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "version" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "threadVersionID" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "font" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "theme" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "hasScore" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "imageURL" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "instructor" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "email" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "company",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "role" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "city" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "state" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "description",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "emoji" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "pronouns",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "profileImageURL",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "attachments" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "guid" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "changes",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "type" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "url" },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StartThreadMutation, StartThreadMutationVariables>
export const AiCancelEditingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "aiCancelEditing" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadGUID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "aiCancelEditing" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadGUID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadGUID" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "preview" } },
                                { kind: "Field", name: { kind: "Name", value: "synopsis" } },
                                { kind: "Field", name: { kind: "Name", value: "blocks" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUpdateTime" } },
                                { kind: "Field", name: { kind: "Name", value: "title" } },
                                { kind: "Field", name: { kind: "Name", value: "version" } },
                                { kind: "Field", name: { kind: "Name", value: "threadVersionID" } },
                                { kind: "Field", name: { kind: "Name", value: "theme" } },
                                { kind: "Field", name: { kind: "Name", value: "font" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attachments" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "guid" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "changes" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "type" },
                                            },
                                            { kind: "Field", name: { kind: "Name", value: "url" } },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "instructor" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "role" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "city" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "state" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "emoji" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "pronouns" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "aiParams" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "instructorProfile" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "referenceMaterial" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "prompt" },
                                            },
                                        ],
                                    },
                                },
                                { kind: "Field", name: { kind: "Name", value: "aiEditing" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AiCancelEditingMutation, AiCancelEditingMutationVariables>
export const CreateThreadDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createThread" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "CreateThreadInput" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createThread" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "blocks" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUpdateTime" } },
                                { kind: "Field", name: { kind: "Name", value: "title" } },
                                { kind: "Field", name: { kind: "Name", value: "version" } },
                                { kind: "Field", name: { kind: "Name", value: "threadVersionID" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "instructor" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "role" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "city" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "state" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "emoji" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "pronouns" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "aiParams" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "instructorProfile" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "referenceMaterial" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "prompt" },
                                            },
                                        ],
                                    },
                                },
                                { kind: "Field", name: { kind: "Name", value: "aiEditing" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateThreadMutation, CreateThreadMutationVariables>
export const GenerateThreadDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "generateThread" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "GenerateThreadInput" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "generateThread" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "blocks" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUpdateTime" } },
                                { kind: "Field", name: { kind: "Name", value: "title" } },
                                { kind: "Field", name: { kind: "Name", value: "version" } },
                                { kind: "Field", name: { kind: "Name", value: "threadVersionID" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "instructor" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "role" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "city" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "state" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "emoji" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "pronouns" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "aiParams" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "instructorProfile" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "referenceMaterial" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "prompt" },
                                            },
                                        ],
                                    },
                                },
                                { kind: "Field", name: { kind: "Name", value: "aiEditing" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GenerateThreadMutation, GenerateThreadMutationVariables>
export const EditThreadDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "editThread" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadId" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "threadVersionId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "edit" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "EditThreadInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "editThread" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadVersionId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadVersionId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "edit" },
                                value: { kind: "Variable", name: { kind: "Name", value: "edit" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "blocks" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUpdateTime" } },
                                { kind: "Field", name: { kind: "Name", value: "title" } },
                                { kind: "Field", name: { kind: "Name", value: "version" } },
                                { kind: "Field", name: { kind: "Name", value: "threadVersionID" } },
                                { kind: "Field", name: { kind: "Name", value: "imageURL" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "hasCustomCoverImage" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "instructor" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "role" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "city" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "state" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "emoji" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "pronouns" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EditThreadMutation, EditThreadMutationVariables>
export const DuplicateThreadDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "duplicateThread" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "threadVersionID" },
                    },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "cloneThread" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadID" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadVersionID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadVersionID" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "blocks" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUpdateTime" } },
                                { kind: "Field", name: { kind: "Name", value: "title" } },
                                { kind: "Field", name: { kind: "Name", value: "version" } },
                                { kind: "Field", name: { kind: "Name", value: "threadVersionID" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "instructor" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "role" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "city" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "state" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "emoji" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "pronouns" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DuplicateThreadMutation, DuplicateThreadMutationVariables>
export const AddParticipantsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "addParticipants" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "participants" } },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ParticipantInput" },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addProgramParticipants" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "participants" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "participants" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "registered" } },
                                { kind: "Field", name: { kind: "Name", value: "hidden" } },
                                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                { kind: "Field", name: { kind: "Name", value: "email" } },
                                { kind: "Field", name: { kind: "Name", value: "company" } },
                                { kind: "Field", name: { kind: "Name", value: "imageURL" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddParticipantsMutation, AddParticipantsMutationVariables>
export const SetParticipantVisibilityDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "setParticipantVisibility" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "programGUID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "userID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "hidden" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setProgramParticipantVisibility" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "programGUID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "programGUID" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "userID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "userID" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "hidden" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "hidden" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "registered" } },
                                { kind: "Field", name: { kind: "Name", value: "hidden" } },
                                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                { kind: "Field", name: { kind: "Name", value: "email" } },
                                { kind: "Field", name: { kind: "Name", value: "company" } },
                                { kind: "Field", name: { kind: "Name", value: "imageURL" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetParticipantVisibilityMutation,
    SetParticipantVisibilityMutationVariables
>
export const CreateLinkDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createLink" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "programID" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadID" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "n" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createLink" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "programID" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "programID" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "threadID" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "threadID" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "n" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "n" },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "linkURL" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateLinkMutation, CreateLinkMutationVariables>
export const GetPathFromLinkTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "getPathFromLinkToken" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "token" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "getPathFromLinkToken" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "token" },
                                value: { kind: "Variable", name: { kind: "Name", value: "token" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetPathFromLinkTokenMutation, GetPathFromLinkTokenMutationVariables>
export const LoginAsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "loginAs" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "loginAs" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "id" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{ kind: "Field", name: { kind: "Name", value: "jwt" } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LoginAsMutation, LoginAsMutationVariables>
export const ReportErrorDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "reportError" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "error" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "FrontEndError" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "reportFrontEndError" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "error" },
                                value: { kind: "Variable", name: { kind: "Name", value: "error" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ReportErrorMutation, ReportErrorMutationVariables>
export const SignUpDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "signUp" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "EmailAddress" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "firstName" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "signUp" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "email" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "email" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "firstName" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "jwt" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SignUpMutation, SignUpMutationVariables>
export const AiGenerateQuestionTopicDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "aiGenerateQuestionTopic" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "specs" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "AIGenerateQuestionTopicInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "aiGenerateQuestionTopic" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "specs" },
                                value: { kind: "Variable", name: { kind: "Name", value: "specs" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AiGenerateQuestionTopicMutation,
    AiGenerateQuestionTopicMutationVariables
>
export const AiGenerateContinueWritingTopicDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "aiGenerateContinueWritingTopic" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "specs" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "AIGenerateContinueWritingTopicInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "aiGenerateContinueWritingTopic" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "specs" },
                                value: { kind: "Variable", name: { kind: "Name", value: "specs" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AiGenerateContinueWritingTopicMutation,
    AiGenerateContinueWritingTopicMutationVariables
>
export const AddEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "addEvent" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "AddEventInput" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addEvent" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddEventMutation, AddEventMutationVariables>
export const CloneProgramDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "cloneProgram" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "cloneProgram" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "live" } },
                                { kind: "Field", name: { kind: "Name", value: "startTime" } },
                                { kind: "Field", name: { kind: "Name", value: "endTime" } },
                                { kind: "Field", name: { kind: "Name", value: "imageURL" } },
                                { kind: "Field", name: { kind: "Name", value: "socialSidebar" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSingleShareThread" },
                                },
                                { kind: "Field", name: { kind: "Name", value: "leaderboard" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CloneProgramMutation, CloneProgramMutationVariables>
export const CreateAttachmentDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createAttachment" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "file" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Upload" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "changes" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createAttachment" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "file" },
                                value: { kind: "Variable", name: { kind: "Name", value: "file" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "changes" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "changes" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "changes" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "url" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateAttachmentMutation, CreateAttachmentMutationVariables>
export const CreateAttachmentFromHandleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createAttachmentFromHandle" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "handle" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createAttachmentFromHandle" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "handle" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "handle" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "changes" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "url" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateAttachmentFromHandleMutation,
    CreateAttachmentFromHandleMutationVariables
>
export const CreateAttachmentFromUrlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createAttachmentFromUrl" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "imageUrl" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createAttachmentFromUrl" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "imageUrl" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "imageUrl" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "changes" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "url" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateAttachmentFromUrlMutation,
    CreateAttachmentFromUrlMutationVariables
>
export const CreateProgramDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createProgram" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createProgram" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "live" } },
                                { kind: "Field", name: { kind: "Name", value: "startTime" } },
                                { kind: "Field", name: { kind: "Name", value: "endTime" } },
                                { kind: "Field", name: { kind: "Name", value: "imageURL" } },
                                { kind: "Field", name: { kind: "Name", value: "socialSidebar" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSingleShareThread" },
                                },
                                { kind: "Field", name: { kind: "Name", value: "leaderboard" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateProgramMutation, CreateProgramMutationVariables>
export const DeleteProgramDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "deleteProgram" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "deleteProgram" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteProgramMutation, DeleteProgramMutationVariables>
export const DeleteThreadDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "deleteThread" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadId" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "deleteThread" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "threadId" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteThreadMutation, DeleteThreadMutationVariables>
export const MarkThreadAsPublishedDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "markThreadAsPublished" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadId" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "threadVersionId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "markThreadAsPublished" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadVersionId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadVersionId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "time" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "editor" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                        ],
                                    },
                                },
                                { kind: "Field", name: { kind: "Name", value: "published" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MarkThreadAsPublishedMutation, MarkThreadAsPublishedMutationVariables>
export const ModifyAttachmentDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "modifyAttachment" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "changes" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "modifyAttachment" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "changes" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "changes" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "changes" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "url" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ModifyAttachmentMutation, ModifyAttachmentMutationVariables>
export const RemoveParticipantsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "removeParticipants" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "emails" } },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NonNullType",
                            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "removeProgramParticipants" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "emails" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "emails" },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RemoveParticipantsMutation, RemoveParticipantsMutationVariables>
export const ResetThreadDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "resetThread" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadId" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "programId" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resetThread" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "programId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "programId" },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ResetThreadMutation, ResetThreadMutationVariables>
export const SetProgramNotificationConfigDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "setProgramNotificationConfig" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "connection" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "channel" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setProgramNotificationConfig" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "connection" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "connection" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "channel" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "channel" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "channels" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetProgramNotificationConfigMutation,
    SetProgramNotificationConfigMutationVariables
>
export const SetProgramContentsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "setProgramContents" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "content" } },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ProgramContentInput" },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setProgramContents" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "content" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "content" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: { kind: "Name", value: "SlackMessage" },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "body" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "deliveryTime" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "link" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "attachment" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "guid" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "changes",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "url" },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "clientData" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: { kind: "Name", value: "EmailMessage" },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "body" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "deliveryTime" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "link" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "attachment" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "guid" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "changes",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "url" },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "clientData" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: { kind: "Name", value: "ProgramThread" },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "thread" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "guid" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "title" },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lockTime" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "unlockTime" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "clientData" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "messages" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "__typename",
                                                            },
                                                        },
                                                        {
                                                            kind: "InlineFragment",
                                                            typeCondition: {
                                                                kind: "NamedType",
                                                                name: {
                                                                    kind: "Name",
                                                                    value: "SlackMessage",
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "title",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "body",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "deliveryTime",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "link",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "attachment",
                                                                        },
                                                                        selectionSet: {
                                                                            kind: "SelectionSet",
                                                                            selections: [
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "guid",
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "changes",
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "url",
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "clientData",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "InlineFragment",
                                                            typeCondition: {
                                                                kind: "NamedType",
                                                                name: {
                                                                    kind: "Name",
                                                                    value: "EmailMessage",
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "title",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "body",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "deliveryTime",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "link",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "attachment",
                                                                        },
                                                                        selectionSet: {
                                                                            kind: "SelectionSet",
                                                                            selections: [
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "guid",
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "changes",
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "url",
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "clientData",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetProgramContentsMutation, SetProgramContentsMutationVariables>
export const UpdateProgramDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "updateProgram" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "updates" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "ProgramUpdate" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateProgram" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "updates" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "updates" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "live" } },
                                { kind: "Field", name: { kind: "Name", value: "startTime" } },
                                { kind: "Field", name: { kind: "Name", value: "endTime" } },
                                { kind: "Field", name: { kind: "Name", value: "imageURL" } },
                                { kind: "Field", name: { kind: "Name", value: "socialSidebar" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSingleShareThread" },
                                },
                                { kind: "Field", name: { kind: "Name", value: "leaderboard" } },
                                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                                { kind: "Field", name: { kind: "Name", value: "description" } },
                                { kind: "Field", name: { kind: "Name", value: "socialFeatures" } },
                                { kind: "Field", name: { kind: "Name", value: "threadsInOrder" } },
                                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                                { kind: "Field", name: { kind: "Name", value: "defaultSender" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateProgramMutation, UpdateProgramMutationVariables>
export const UpdateUserProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "updateUserProfile" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "firstName" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "lastName" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "company" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "role" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "state" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "emojis" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "links" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "pronouns" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "profileImageGuid" },
                    },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateUserProfile" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "firstName" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "lastName" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "company" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "company" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "role" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "role" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "city" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "city" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "state" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "state" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "description" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "description" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "emojis" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "emojis" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "links" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "links" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "pronouns" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "pronouns" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "profileImageGuid" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "profileImageGuid" },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>
export const UpdateUserProfileImageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "updateUserProfileImage" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "profileImageGuid" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateUserProfile" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "profileImageGuid" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "profileImageGuid" },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "profileImageURL" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateUserProfileImageMutation,
    UpdateUserProfileImageMutationVariables
>
export const EnrolledProgramsThreadsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "EnrolledProgramsThreads" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "enrolledProgramThreads" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "status" } },
                                { kind: "Field", name: { kind: "Name", value: "progress" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "thread" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "guid" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "imageURL" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "instructor" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "profileImageURL",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EnrolledProgramsThreadsQuery, EnrolledProgramsThreadsQueryVariables>
export const GetCreatorToolThreadByGuidDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getCreatorToolThreadByGuid" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadGUID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "getThreadByGuid" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadGUID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadGUID" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "preview" } },
                                { kind: "Field", name: { kind: "Name", value: "synopsis" } },
                                { kind: "Field", name: { kind: "Name", value: "blocks" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUpdateTime" } },
                                { kind: "Field", name: { kind: "Name", value: "title" } },
                                { kind: "Field", name: { kind: "Name", value: "version" } },
                                { kind: "Field", name: { kind: "Name", value: "threadVersionID" } },
                                { kind: "Field", name: { kind: "Name", value: "theme" } },
                                { kind: "Field", name: { kind: "Name", value: "font" } },
                                { kind: "Field", name: { kind: "Name", value: "description" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attachments" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "guid" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "changes" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "type" },
                                            },
                                            { kind: "Field", name: { kind: "Name", value: "url" } },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "instructor" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "role" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "city" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "state" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "emoji" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "pronouns" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "aiParams" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "instructorProfile" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "referenceMaterial" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "prompt" },
                                            },
                                        ],
                                    },
                                },
                                { kind: "Field", name: { kind: "Name", value: "aiEditing" } },
                                { kind: "Field", name: { kind: "Name", value: "imageURL" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "hasCustomCoverImage" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetCreatorToolThreadByGuidQuery,
    GetCreatorToolThreadByGuidQueryVariables
>
export const GetCreatorThreadsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getCreatorThreads" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "getAllCreatorToolThreads" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "instructor" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                        ],
                                    },
                                },
                                { kind: "Field", name: { kind: "Name", value: "instructorId" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "threadVersion" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "published" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "threadId" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "createdAt" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCreatorThreadsQuery, GetCreatorThreadsQueryVariables>
export const GetGlobalVariablesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getGlobalVariables" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "globalVariables" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "category" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "documentation" } },
                                { kind: "Field", name: { kind: "Name", value: "dynamic" } },
                                { kind: "Field", name: { kind: "Name", value: "writable" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "possibleValues" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "text" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "imageURL" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetGlobalVariablesQuery, GetGlobalVariablesQueryVariables>
export const GetProgramDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getProgram" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "program" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "live" } },
                                { kind: "Field", name: { kind: "Name", value: "startTime" } },
                                { kind: "Field", name: { kind: "Name", value: "endTime" } },
                                { kind: "Field", name: { kind: "Name", value: "imageURL" } },
                                { kind: "Field", name: { kind: "Name", value: "socialSidebar" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSingleShareThread" },
                                },
                                { kind: "Field", name: { kind: "Name", value: "leaderboard" } },
                                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                                { kind: "Field", name: { kind: "Name", value: "description" } },
                                { kind: "Field", name: { kind: "Name", value: "socialFeatures" } },
                                { kind: "Field", name: { kind: "Name", value: "threadsInOrder" } },
                                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                                { kind: "Field", name: { kind: "Name", value: "defaultSender" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProgramQuery, GetProgramQueryVariables>
export const GetProgramsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getPrograms" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "search" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "program" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "searchTerm" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "search" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "live" } },
                                { kind: "Field", name: { kind: "Name", value: "startTime" } },
                                { kind: "Field", name: { kind: "Name", value: "endTime" } },
                                { kind: "Field", name: { kind: "Name", value: "imageURL" } },
                                { kind: "Field", name: { kind: "Name", value: "socialSidebar" } },
                                { kind: "Field", name: { kind: "Name", value: "leaderboard" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProgramsQuery, GetProgramsQueryVariables>
export const GetProgramContentsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getProgramContents" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "programContents" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: { kind: "Name", value: "SlackMessage" },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "body" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "deliveryTime" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "link" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "attachment" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "guid" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "changes",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "url" },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "clientData" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: { kind: "Name", value: "EmailMessage" },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "body" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "deliveryTime" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "link" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "attachment" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "guid" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "changes",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "url" },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "clientData" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: { kind: "Name", value: "ProgramThread" },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "thread" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "guid" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "title" },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lockTime" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "unlockTime" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "clientData" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "messages" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "__typename",
                                                            },
                                                        },
                                                        {
                                                            kind: "InlineFragment",
                                                            typeCondition: {
                                                                kind: "NamedType",
                                                                name: {
                                                                    kind: "Name",
                                                                    value: "SlackMessage",
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "title",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "body",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "deliveryTime",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "link",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "attachment",
                                                                        },
                                                                        selectionSet: {
                                                                            kind: "SelectionSet",
                                                                            selections: [
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "guid",
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "changes",
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "url",
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "clientData",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "InlineFragment",
                                                            typeCondition: {
                                                                kind: "NamedType",
                                                                name: {
                                                                    kind: "Name",
                                                                    value: "EmailMessage",
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "title",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "body",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "deliveryTime",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "link",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "attachment",
                                                                        },
                                                                        selectionSet: {
                                                                            kind: "SelectionSet",
                                                                            selections: [
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "guid",
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "changes",
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: "Field",
                                                                                    name: {
                                                                                        kind: "Name",
                                                                                        value: "url",
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "clientData",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProgramContentsQuery, GetProgramContentsQueryVariables>
export const GetThreadVersionHistoryByGuidDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "GetThreadVersionHistoryByGuid" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadGUID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "getThreadVersionHistoryByGuid" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadGUID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadGUID" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "time" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "editor" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                        ],
                                    },
                                },
                                { kind: "Field", name: { kind: "Name", value: "published" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetThreadVersionHistoryByGuidQuery,
    GetThreadVersionHistoryByGuidQueryVariables
>
export const GetLintThreadDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getLintThread" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "lintThread" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "id" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "id" },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "objectID" } },
                                { kind: "Field", name: { kind: "Name", value: "description" } },
                                { kind: "Field", name: { kind: "Name", value: "severity" } },
                                { kind: "Field", name: { kind: "Name", value: "category" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetLintThreadQuery, GetLintThreadQueryVariables>
export const GetAllInstructorsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "GetAllInstructors" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "allInstructors" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                { kind: "Field", name: { kind: "Name", value: "description" } },
                                { kind: "Field", name: { kind: "Name", value: "profileImageURL" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetAllInstructorsQuery, GetAllInstructorsQueryVariables>
export const EnrolledProgramParticipantsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "enrolledProgramParticipants" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "enrolledProgramParticipants" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "person" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "role" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "city" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "state" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "emoji" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "progress" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "total" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "totalScore" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "threadScore" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "guid" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalBasePoints",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalBonusPoints",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "totalPoints",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastActivity" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "threadProgress" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "guid" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "progress",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "status" },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EnrolledProgramParticipantsQuery,
    EnrolledProgramParticipantsQueryVariables
>
export const EnrolledProgramsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "EnrolledPrograms" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "enrolledPrograms" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "description" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSingleShareThread" },
                                },
                                { kind: "Field", name: { kind: "Name", value: "firstThreadId" } },
                                { kind: "Field", name: { kind: "Name", value: "firstThreadGUID" } },
                                { kind: "Field", name: { kind: "Name", value: "imageURL" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "socialSidebar" } },
                                { kind: "Field", name: { kind: "Name", value: "leaderboard" } },
                                { kind: "Field", name: { kind: "Name", value: "startTime" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "progress" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "percentComplete" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "status" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EnrolledProgramsQuery, EnrolledProgramsQueryVariables>
export const EnrolledProgramsIdOnlyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "EnrolledProgramsIdOnly" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "enrolledPrograms" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EnrolledProgramsIdOnlyQuery, EnrolledProgramsIdOnlyQueryVariables>
export const GetProgramNotificationConfigDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getProgramNotificationConfig" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "programNotificationConfig" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "channels" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetProgramNotificationConfigQuery,
    GetProgramNotificationConfigQueryVariables
>
export const GetSlackConnectionsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getSlackConnections" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "slackConnections" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "channels" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetSlackConnectionsQuery, GetSlackConnectionsQueryVariables>
export const PreviewThreadDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "previewThread" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadGUID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "previewThreadByGuid" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadGUID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadGUID" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "thread" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "blocks" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "preview" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "synopsis" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "instructor" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "variables" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "type" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "value" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "string" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "valueID",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "integer",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "boolean",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "telemetrySessionId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PreviewThreadQuery, PreviewThreadQueryVariables>
export const GenerateEditContentDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "generateEditContent" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "content" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "EditType" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "aiGenEditContent" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "content" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "content" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "type" },
                                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GenerateEditContentQuery, GenerateEditContentQueryVariables>
export const UserPermissionsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "UserPermissions" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "aboutMe" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "mayCreate" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "distinguishedInstructor" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "maySeeUnpublishedThreads" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "maySeeExperimentalFeatures" },
                                },
                                { kind: "Field", name: { kind: "Name", value: "isAdministrator" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserPermissionsQuery, UserPermissionsQueryVariables>
export const GetLatestFreeTextResponsesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getLatestFreeTextResponses" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "programGUID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "threadGUID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "objectID" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "latestFreetextResponses" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "programGUID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "programGUID" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "threadGUID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "threadGUID" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "objectID" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "objectID" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "author" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "role" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "city" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "state" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "emoji" },
                                            },
                                        ],
                                    },
                                },
                                { kind: "Field", name: { kind: "Name", value: "response" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetLatestFreeTextResponsesQuery,
    GetLatestFreeTextResponsesQueryVariables
>
export const GetProgramParticipantsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getProgramParticipants" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "programParticipants" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "guid" },
                                value: { kind: "Variable", name: { kind: "Name", value: "guid" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "registered" } },
                                { kind: "Field", name: { kind: "Name", value: "hidden" } },
                                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                { kind: "Field", name: { kind: "Name", value: "email" } },
                                { kind: "Field", name: { kind: "Name", value: "company" } },
                                { kind: "Field", name: { kind: "Name", value: "imageURL" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProgramParticipantsQuery, GetProgramParticipantsQueryVariables>
export const AboutMeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "AboutMe" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "aboutMe" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "email" } },
                                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                { kind: "Field", name: { kind: "Name", value: "phoneNumber" } },
                                { kind: "Field", name: { kind: "Name", value: "company" } },
                                { kind: "Field", name: { kind: "Name", value: "profileImageURL" } },
                                { kind: "Field", name: { kind: "Name", value: "role" } },
                                { kind: "Field", name: { kind: "Name", value: "city" } },
                                { kind: "Field", name: { kind: "Name", value: "state" } },
                                { kind: "Field", name: { kind: "Name", value: "description" } },
                                { kind: "Field", name: { kind: "Name", value: "emojis" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AboutMeQuery, AboutMeQueryVariables>
export const SearchPlaylistsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "searchPlaylists" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "searchTerm" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "onlyPublished" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "searchPlaylists" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "searchTerm" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "searchTerm" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "onlyPublished" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "onlyPublished" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "playlist" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "guid" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "imageURL" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "length" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchPlaylistsQuery, SearchPlaylistsQueryVariables>
export const SearchThreadsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "searchThreads" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ThreadSearchQuery" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "searchThreads" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "thread" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "guid" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastUpdateTime" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "instructor" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "profileImageURL",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchThreadsQuery, SearchThreadsQueryVariables>
export const SearchCreatorTollThreadsMobileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "searchCreatorTollThreadsMobile" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ThreadSearchQuery" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "searchThreads" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "thread" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "guid" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "imageURL" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastUpdateTime" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "instructor" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firstName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lastName",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "profileImageURL",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SearchCreatorTollThreadsMobileQuery,
    SearchCreatorTollThreadsMobileQueryVariables
>
export const CheckEmailDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "checkEmail" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "checkEmail" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "email" },
                                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CheckEmailQuery, CheckEmailQueryVariables>
export const ImageSearchDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "imageSearch" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ImageSearchInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "imageSearch" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "handle" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "imageURL" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "thumbnailURL" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ImageSearchQuery, ImageSearchQueryVariables>
export const GetDynamicVariablesValuesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getDynamicVariablesValues" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DynamicVariableQuery" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "dynamicVariable" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "string" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "valueID" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "integer" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "boolean" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetDynamicVariablesValuesQuery,
    GetDynamicVariablesValuesQueryVariables
>
export const ThreadProgressForProgramDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "threadProgressForProgram" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ThreadProgressForProgramInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "threadProgressForProgram" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "programGuid" } },
                                { kind: "Field", name: { kind: "Name", value: "threadGuid" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "person" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "role" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "city" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "state" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "emoji" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "threadScore" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "guid" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "totalBasePoints" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "totalBonusPoints" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "totalPoints" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ThreadProgressForProgramQuery, ThreadProgressForProgramQueryVariables>
export const ProgramProgressDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "programProgress" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ProgramProgressInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "programProgress" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "person" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "email" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "firstName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lastName" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "profileImageURL" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "role" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "city" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "state" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "emoji" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "description" },
                                            },
                                        ],
                                    },
                                },
                                { kind: "Field", name: { kind: "Name", value: "totalScore" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ProgramProgressQuery, ProgramProgressQueryVariables>
export const GetThreadDurationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getThreadDuration" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "ThreadSpec" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "fullThreadContent" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "timingData" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "expectedDurationMS" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetThreadDurationQuery, GetThreadDurationQueryVariables>
export const UserProfileMetricsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "userProfileMetrics" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "aboutMe" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "stats" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "matches" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "points" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "wins" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "losses" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserProfileMetricsQuery, UserProfileMetricsQueryVariables>
export const AiGenerateQuestionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: { kind: "Name", value: "aiGenerateQuestion" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "specs" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "AIGenerateQuestionInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "aiGenerateQuestion" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "specs" },
                                value: { kind: "Variable", name: { kind: "Name", value: "specs" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "object" } },
                                { kind: "Field", name: { kind: "Name", value: "parentGUID" } },
                                { kind: "Field", name: { kind: "Name", value: "index" } },
                                { kind: "Field", name: { kind: "Name", value: "threadId" } },
                                { kind: "Field", name: { kind: "Name", value: "threadGUID" } },
                                { kind: "Field", name: { kind: "Name", value: "threadVersionId" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AiGenerateQuestionSubscription,
    AiGenerateQuestionSubscriptionVariables
>
export const AiContinueWritingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: { kind: "Name", value: "aiContinueWriting" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "specs" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "AIContinueWritingInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "aiContinueWriting" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "specs" },
                                value: { kind: "Variable", name: { kind: "Name", value: "specs" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "guid" } },
                                { kind: "Field", name: { kind: "Name", value: "object" } },
                                { kind: "Field", name: { kind: "Name", value: "parentGUID" } },
                                { kind: "Field", name: { kind: "Name", value: "index" } },
                                { kind: "Field", name: { kind: "Name", value: "threadId" } },
                                { kind: "Field", name: { kind: "Name", value: "threadGUID" } },
                                { kind: "Field", name: { kind: "Name", value: "threadVersionId" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AiContinueWritingSubscription, AiContinueWritingSubscriptionVariables>
export const HandleUserInputDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: { kind: "Name", value: "handleUserInput" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "UserInput" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "handleUserInput" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "input" },
                                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "correct" } },
                                { kind: "Field", name: { kind: "Name", value: "correctness" } },
                                { kind: "Field", name: { kind: "Name", value: "content" } },
                                { kind: "Field", name: { kind: "Name", value: "percentComplete" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "score" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "basePoints" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bonusPoints" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "points" },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "choiceData" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "count" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "total" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "percent" },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "correct" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<HandleUserInputSubscription, HandleUserInputSubscriptionVariables>
export const IndexContentSourceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: { kind: "Name", value: "indexContentSource" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "url" } },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ContentSourceInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "indexContentSource" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "url" },
                                value: { kind: "Variable", name: { kind: "Name", value: "url" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "progress" } },
                                { kind: "Field", name: { kind: "Name", value: "state" } },
                                { kind: "Field", name: { kind: "Name", value: "error" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    IndexContentSourceSubscription,
    IndexContentSourceSubscriptionVariables
>
