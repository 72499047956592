import { capitalize, values } from "lodash"
import Input from "../../../../components/InputNew"
import { StyledInputLabel } from "../../../../components/InputNew/styles"
import Select, { isMultiValue } from "../../../../components/Select/Select"
import { StyledBox, StyledColumn, StyledRow } from "../../../../styles/styledcomponents"
import { GenericSelectOption } from "../../../../types"
import { useEffect, useMemo } from "react"
import {
    QuestionFormat,
    QuestionIntent,
    QuestionResponseStyle,
} from "../../../../apollo/generated/graphql"
import { useFormik } from "formik"
import { StyledAiPopupQuestionForm } from "./styles"
import { useTheme } from "styled-components"

enum TextFields {
    topic = "topic",
}

enum SelectFields {
    intent = "intent",
    format = "format",
    responseStyle = "responseStyle",
}

export type QuestionFormValues = {
    [SelectFields.intent]?: GenericSelectOption<QuestionIntent>
    [SelectFields.format]?: GenericSelectOption<QuestionFormat>
    [SelectFields.responseStyle]?: GenericSelectOption<QuestionResponseStyle>
    [TextFields.topic]?: string
}

interface AiQuestionFormProps {
    setValues: (values: Required<QuestionFormValues>) => void
    defaultIntent?: QuestionIntent
    defaultTopic?: string | null
    onTopicFocused?: () => void
    onTopicBlur?: () => void
}

const AiPopupQuestionForm = ({
    setValues,
    defaultIntent,
    defaultTopic,
    onTopicFocused,
    onTopicBlur,
}: AiQuestionFormProps) => {
    const theme = useTheme()
    const getOptionLabel = (value: string): string => capitalize(value.replaceAll("_", " "))

    const QuestionIntentOptions = useMemo(
        () =>
            values(QuestionIntent).map((intent) => ({
                value: intent,
                label: getOptionLabel(intent),
            })),
        []
    )

    const QuestionFormatOptions = useMemo(
        () =>
            values(QuestionFormat).map((format) => ({
                value: format,
                label: getOptionLabel(format),
            })),
        []
    )

    const QuestionResponseStyleOptions = useMemo(
        () =>
            values(QuestionResponseStyle).map((responseStyle) => ({
                value: responseStyle,
                label: getOptionLabel(responseStyle),
            })),
        []
    )

    const formik = useFormik<QuestionFormValues>({
        initialValues: {
            [SelectFields.intent]: QuestionIntentOptions.find(
                (option) => option.value === defaultIntent
            ),
            [SelectFields.format]: {
                value: QuestionFormat.MultipleChoice,
                label: getOptionLabel(QuestionFormat.MultipleChoice),
            },
            [SelectFields.responseStyle]: {
                value: QuestionResponseStyle.Terse,
                label: getOptionLabel(QuestionResponseStyle.Terse),
            },
        },
        onSubmit: () => {},
    })

    useEffect(() => {
        if (defaultTopic) formik.setFieldValue(TextFields.topic, defaultTopic)
    }, [defaultTopic])

    useEffect(() => {
        if (
            formik.values[TextFields.topic] &&
            formik.values[SelectFields.intent] &&
            formik.values[SelectFields.format] &&
            formik.values[SelectFields.responseStyle]
        ) {
            // safe `Required` cast above we check all values are present
            setValues(formik.values as Required<QuestionFormValues>)
        }
    }, [formik.values])

    const selectStyles = {
        control: () => ({
            borderRadius: "8px",
            margin: 0,
            boxShadow: "unset",
        }),
        singleValue: () => ({ color: theme.colors.base.uiLabelBase }),
    }

    return (
        <StyledAiPopupQuestionForm>
            <StyledRow>
                <Input
                    id={TextFields.topic}
                    name={TextFields.topic}
                    value={formik.values.topic}
                    onChange={formik.handleChange}
                    onFocus={onTopicFocused}
                    onBlur={onTopicBlur}
                    label={"Topic"}
                    type={"textArea"}
                    minRows={2}
                    maxRows={2}
                    containerStyles={{ width: "100%" }}
                />
            </StyledRow>
            <StyledRow css={{ margin: "6px 0", gap: "6px" }}>
                <StyledColumn css={{ flex: 1 }}>
                    <StyledBox css={{ flex: 1 }}>
                        <StyledInputLabel>Intent</StyledInputLabel>
                        <Select
                            id={SelectFields.intent}
                            name={SelectFields.intent}
                            value={formik.values[SelectFields.intent]}
                            onChange={(option) =>
                                option &&
                                !isMultiValue(option) &&
                                formik.setFieldValue(SelectFields.intent, option)
                            }
                            options={QuestionIntentOptions}
                            styles={selectStyles}
                        />
                    </StyledBox>
                    <StyledBox css={{ flex: 1 }}>
                        <StyledInputLabel>Format</StyledInputLabel>
                        <Select
                            id={SelectFields.format}
                            name={SelectFields.format}
                            value={formik.values[SelectFields.format]}
                            onChange={(option) =>
                                option &&
                                !isMultiValue(option) &&
                                formik.setFieldValue(SelectFields.format, option)
                            }
                            options={QuestionFormatOptions}
                            styles={selectStyles}
                        />
                    </StyledBox>
                </StyledColumn>
                <StyledColumn css={{ flex: 1 }}>
                    <StyledBox css={{ flex: 1 }}>
                        <StyledInputLabel>Response Style</StyledInputLabel>
                        <Select
                            id={SelectFields.responseStyle}
                            name={SelectFields.responseStyle}
                            value={formik.values[SelectFields.responseStyle]}
                            onChange={(option) =>
                                option &&
                                !isMultiValue(option) &&
                                formik.setFieldValue(SelectFields.responseStyle, option)
                            }
                            options={QuestionResponseStyleOptions}
                            styles={selectStyles}
                        />
                    </StyledBox>
                </StyledColumn>
            </StyledRow>
        </StyledAiPopupQuestionForm>
    )
}

export default AiPopupQuestionForm
