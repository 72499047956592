import { useParams } from "react-router"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { ResponseThread } from "../types"
import { Person, Thread, UserAnswer } from "../apollo/generated/graphql"
import { Maybe } from "graphql/jsutils/Maybe"

export const useCurrentThreadGuid = (): string => {
    const { threadGuid } = useParams<any>()
    return threadGuid
}

export const useCurrentThread = (): ResponseThread => {
    const currentThreadGuid = useCurrentThreadGuid()
    return useSelector((state: RootState) => state.threadsReducer.threads[currentThreadGuid])
}

export const useCurrentThreadVersionId = (): number | undefined => {
    const currentThreadGuid = useCurrentThreadGuid()
    return useSelector(
        (state: RootState) =>
            state.threadsReducer.threads[currentThreadGuid]?.thread?.threadVersionID
    )
}

export const useCurrentThreadId = (): number | undefined => {
    const currentThreadGuid = useCurrentThreadGuid()
    return useSelector(
        (state: RootState) => state.threadsReducer.threads[currentThreadGuid]?.thread?.id
    )
}

export const useCurrentThreadAnswers = (): Maybe<UserAnswer[]> | undefined => {
    const currentThreadGuid = useCurrentThreadGuid()
    return useSelector(
        (state: RootState) => state.threadsReducer.threads[currentThreadGuid]?.userAnswers
    )
}

export const useCurrentThreadInstructor = (): Maybe<Person> | undefined => {
    const currentThreadGuid = useCurrentThreadGuid()
    return useSelector(
        (state: RootState) => state.threadsReducer.threads[currentThreadGuid]?.thread?.instructor
    )
}

export const useCurrentThreadField = <T extends keyof Thread>(field: T): Thread[T] | undefined => {
    const currentThreadGuid = useCurrentThreadGuid()
    return useSelector((state: RootState) => {
        const thread = state.threadsReducer.threads[currentThreadGuid]?.thread
        if (thread) return thread[field]
        else return undefined
    })
}

export const useCurrentResponseThreadField = <T extends keyof ResponseThread>(
    field: T
): ResponseThread[T] | undefined => {
    const currentThreadGuid = useCurrentThreadGuid()
    return useSelector((state: RootState) => {
        const responseThread = state.threadsReducer.threads[currentThreadGuid]
        if (responseThread) return responseThread[field]
        else return undefined
    })
}
