import { StyledModal, StyledModalHeader } from "./styles"
import { StyledH2 } from "../../styles/styledcomponents"
import { StyledCloseIcon } from "../../deliver/components/AddThreadModal/styles"
import { PopupProps } from "reactjs-popup/dist/types"
import { CSSObject } from "styled-components"

export interface NewModalProps extends Omit<PopupProps, "modal"> {
    title?: string
    showCloseIcon?: boolean
    styles?: CSSObject
}

const Modal = ({ title, showCloseIcon, children, styles, ...modalPros }: NewModalProps) => {
    return (
        <StyledModal modal {...modalPros} css={styles}>
            <StyledModalHeader>
                {title && <StyledH2>{title}</StyledH2>}
                {showCloseIcon && <StyledCloseIcon onClick={modalPros.onClose} />}
            </StyledModalHeader>
            {children}
        </StyledModal>
    )
}

export default Modal
