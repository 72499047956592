import { useMutation, useReactiveVar } from "@apollo/client"
import { FC, useEffect } from "react"
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary"
import { REPORT_ERROR } from "../../apollo/mutations"
import { Button } from "../Button/Button"
import {
    StyledBackgroundImage,
    StyledBody,
    StyledContainer,
    StyledErrorDetailsContainer,
    StyledHeader,
} from "./styles"
import errorBackground from "../../assets/images/error_background.svg"
import errorBackgroundMobile from "../../assets/images/error_background_mobile.svg"
import useLayout from "../../hooks/layout.hook"
import { asyncErrorVar } from "../../apollo/cache-store"

const ErrorReporter: FC = ({ children }) => {
    const { isMobile } = useLayout()

    const [reportError] = useMutation(REPORT_ERROR)

    const asyncError = useReactiveVar(asyncErrorVar)

    return (
        <ErrorBoundary
            fallback={
                <>
                    <StyledContainer>
                        <StyledHeader>😵 Oops...</StyledHeader>
                        <StyledBody>
                            We’re sorry, It’s not you, it’s us. Something went wrong on our end.
                        </StyledBody>
                        <Button
                            label={"Reload"}
                            width={80}
                            onClick={() => window.location.reload()}
                        />
                        {asyncError && (
                            <StyledErrorDetailsContainer>
                                <pre>{JSON.stringify(asyncError, null, 2)}</pre>
                            </StyledErrorDetailsContainer>
                        )}
                    </StyledContainer>
                    <StyledBackgroundImage
                        src={isMobile ? errorBackgroundMobile : errorBackground}
                    />
                </>
            }
            onError={(error, { componentStack }) => {
                const report = (e: Error) =>
                    reportError({
                        variables: {
                            error: {
                                ua: navigator.userAgent,
                                error: e.message,
                                stack: componentStack,
                                url: window.location.href,
                            },
                        },
                    })
                if (Array.isArray(error)) {
                    error.forEach(report)
                } else {
                    report(error)
                }
            }}
        >
            {children}
        </ErrorBoundary>
    )
}

/*
 * This component is just used for surfacing the asyncError stored
 * variable up to the ErrorBoundary, since it's not able to catch it by itself.
 */
export const AsyncErrorCatcher = () => {
    const asyncError = useReactiveVar(asyncErrorVar)

    const { showBoundary } = useErrorBoundary()

    useEffect(() => {
        if (asyncError) showBoundary(asyncError)
    }, [asyncError, showBoundary])

    return null
}

export default ErrorReporter
