import styled, { css } from "styled-components"

const THREAD_WIDTH = "600px"
const ICON_COLUMN_WIDTH = "40px"

export const StyledBlockVideo = styled.div`
    border-radius: 10px 10px 10px 0;
    margin-bottom: 12px;
    margin-top: 12px;
    height: auto;
    width: fit-content;
    display: flex;
    cursor: pointer;
    position: relative;
`

export const StyledEmptyBlockVideo = styled.div<{ selected?: boolean }>`
    background-color: ${(props) => props.theme.headlandsGray5};
    border: 1px solid ${(props) => props.theme.headlandsGray3};
    border-radius: 16px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 400px;

    img {
        width: 24px;
        opacity: 0.2;
    }

    ${(props) =>
        props.selected &&
        css`
            border: 1px solid ${(props) => props.theme.headlandsPrimaryColor};
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
        `}
`

export const StyledResponsiveVideoContainer = styled.div<{ maxWidth?: number }>`
    max-height: 80vh;
    max-width: ${(props) =>
        `${props.maxWidth}px` || css`calc(${THREAD_WIDTH} - ${ICON_COLUMN_WIDTH})`};
    padding: 0;
    opacity: 1;

    iframe {
        border-radius: 10px 10px 10px 0;
    }
`

export const StyledResponsiveVideo = styled.div<{ paddingBottom?: string }>`
    overflow: hidden;
    padding-bottom: ${(props) => props.paddingBottom || "56.25%"};
    position: relative;
    height: 0;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
`
