import styled, { css } from "styled-components"
import { StyledBox, StyledRow } from "../../../styles/styledcomponents"

export const StyledAiEditMenu = styled(StyledBox)<{
    top?: string
    left: string
}>`
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px;
    width: 250px;
    gap: 6px;
    cursor: default;
    background-color: ${({ theme }) => theme.headlandsWhite};
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.25);
    border: 1px solid ${({ theme }) => theme.headlandsGray3};
    border-radius: 4px;
    left: 30px;

    ${({ left }) =>
        !!left &&
        css`
            left: calc(${left});
        `}

    ${({ top }) =>
        !!top &&
        css`
            top: ${top};
        `}
`

export const StyledAiEditContent = styled(StyledBox)`
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
`

export const StyledAiEditItemIconContainer = styled(StyledBox)`
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.headlandsGray3};
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledAiEditItem = styled(StyledRow)`
    gap: 12px;
    height: 30px;
    border-radius: 4px;
    padding: 3px 12px;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.headlandsGray4};
    }
`
