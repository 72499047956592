import { BlockType } from "../../../types"
import { InstructorAvatar } from "./InstructorAvatar"
import { QuestionsAndAnswers } from "./QuestionsAndAnswers"
import { Image } from "./Image"
import { Video } from "./Video"
import { ImageCarousel } from "./ImageCarousel"
import { ImageUpload } from "./ImageUpload"
import { Accordion } from "./Accordion"
import { Confetti } from "./Confetti"
import { Divider } from "./Divider"
import { Code } from "./Code"
import { ComponentSelectorItem } from "../../../creator/components/ComponentSelector/constants"

export const renderBlockIcon = (blockType: ComponentSelectorItem): JSX.Element => {
    switch (blockType) {
        case BlockType.IMAGE:
            return <Image />
        case BlockType.VIDEO:
            return <Video />
        case BlockType.IMAGE_CAROUSEL:
            return <ImageCarousel />
        case BlockType.INSTRUCTOR_AVATAR:
            return <InstructorAvatar />
        case BlockType.QUESTIONS_AND_ANSWERS:
            return <QuestionsAndAnswers />
        case BlockType.IMAGE_UPLOAD:
            return <ImageUpload />
        case BlockType.DIVIDER:
            return <Divider />
        case BlockType.ACCORDION:
            return <Accordion />
        case BlockType.CONFETTI:
            return <Confetti />
        case BlockType.CODE:
            return <Code />
    }
    return <></>
}
