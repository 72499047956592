import styled from "styled-components"
import { StyledBlockContent } from "../../../../styles/styledcomponents"
import { rgba } from "polished"

export const StyledImageUpload = styled(StyledBlockContent)`
    input {
        background-color: transparent;
        border: none;
        outline: none;
        color: ${({ theme }) => theme.headlandsWhite};
    }

    .square {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        height: 74px;
        width: 200px;
        background-color: ${({ theme }) => rgba(theme.colors.thread.student.bubble, 0.2)};
        border: 1px dashed ${({ theme }) => theme.colors.thread.student.bubble};
        border-radius: 16px;

        p {
            color: ${({ theme }) => theme.colors.thread.student.font};
        }
    }
`
