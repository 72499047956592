import styled from "styled-components"
import { StyledBox } from "../../../../styles/styledcomponents"
import { AI_POPUP_WIDTH } from "../styles"

const GHOST_INDICATOR_HEIGHT = "6px"

// note: is not the nicer way to do it but is quick and functional
export const StyledGhostIndicatorBar = styled(StyledBox)<{ parentWidth: number }>`
    position: absolute;
    top: -${GHOST_INDICATOR_HEIGHT};
    left: calc(
        -0.5 * (${({ parentWidth }) => parentWidth}px -
                    ${({ theme }) => theme.variables.creator.iconRowWidth} - ${AI_POPUP_WIDTH}px)
    );
    width: calc(
        ${({ parentWidth }) => parentWidth}px -
            ${({ theme }) => theme.variables.creator.iconRowWidth}
    );
    height: ${GHOST_INDICATOR_HEIGHT};
    background-color: ${({ theme }) => theme.colors.base.uiSystemHover};
`

export const StyledAiPopupQuestionForm = styled(StyledBox)`
    width: 100%;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.base.uiAi300};
    padding: 6px 12px 0 12px;
`
