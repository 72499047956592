import { useMemo } from "react"
import { Editor } from "../../../creator/components/EditorScreen/Editor"
import { EditorSectionProps } from "../../../creator/components/EditorScreen/components/EditorSection"
import { BlocksIndexReducer } from "../../../redux/blocks"
import {
    StyledBody1,
    StyledBox,
    StyledColumn,
    StyledH5,
    StyledHr,
    StyledRow,
} from "../../../styles/styledcomponents"
import { AvatarSize, Block, BlockType, BlocksType, OptionPreviewMode } from "../../../types"
import { Button } from "../../Button/Button"
import {
    StyledAvatarContainer,
    StyledCountUpScoreContainer,
    StyledEndPaneContentContainer,
    StyledEndPaneContentHeader,
    StyledEndPaneScore,
    StyledPanePreview,
    StyledPanePreviewContainer,
    StyledPlayArrowIcon,
    StyledResult,
    StyledScore,
} from "./styles"
import TextPreview from "../TextPreview"
import ImagePreview from "../ImagePreview"
import VideoPreview from "../VideoPreview"
import ImageCarousel from "../../Carousel/ImageCarousel"
import AccordionPreview from "../AccordionPreview"
import { InstructorAvatar } from "../../InstructorAvatar"
import Divider from "../../Divider"
import {
    useCurrentResponseThreadField,
    useCurrentThreadField,
} from "../../../hooks/currentThread.hook"
import { parseBlocksString } from "../../../thread/utils"
import ShareThreadButton, { CopyThreadButtonVariant } from "../PanePreview/ShareThreadButton"
import useLayout from "../../../hooks/layout.hook"
import { isEmpty, isNil } from "lodash"
import { useTheme } from "styled-components"
import { useHistory } from "react-router"
import {
    useSelectedEnrolledProgramAttribute,
    useSelectedProgramGuid,
} from "../../../hooks/enrolledProgram.hook"
import { ThreadStatus, User } from "../../../apollo/generated/graphql"
import { UserAvatar } from "../../../creator/components/UserAvatar"
import { BoltIcon } from "../../../assets/icons/Bolt"
import ProfileIcon from "../../../assets/icons/ProfileIcon"
import CountUp, { CountUpProps } from "react-countup"
import { motion } from "framer-motion"
import useTriviaMatchData, { MATCH_RESULT } from "../../../hooks/triviaMatchData.hook"
import EllipsisText from "../../EllipsisText"
import { usePreviewMode } from "../../../hooks/previewMode.hook"
import { useThreadContentRef } from "../../../hooks/threadContentRef.hook"

// the full size of the player avatar (including padding, border, etc)
const PLAYER_AVATAR_SIZE = 82

export enum PanePreviewContext {
    creator = "CREATOR",
    user = "USER",
}

interface PanePreviewBase {
    variant: BlockType.SYNOPSIS | BlockType.PREVIEW
}

interface PreviewPane extends PanePreviewBase {
    variant: BlockType.PREVIEW
    context: PanePreviewContext
    onNextClick?: () => void
}

interface EndPaneBase extends PanePreviewBase {
    variant: BlockType.SYNOPSIS
}

interface CreatorEndPane extends EndPaneBase, EditorSectionProps {
    context: PanePreviewContext.creator
    blockIds: BlocksIndexReducer
}

interface UserEndPane extends EndPaneBase {
    context: PanePreviewContext.user
}

type EndPane = CreatorEndPane | UserEndPane

type PanePreviewProps = PreviewPane | EndPane

const PanePreview = ({ variant, ...rest }: PanePreviewProps) => {
    const { previewDevice, previewMode } = usePreviewMode()
    const isMobilePreview = previewMode && previewDevice === OptionPreviewMode.MOBILE
    const { isMobile: deviceIsMobile } = useLayout()
    const isMobile = deviceIsMobile || isMobilePreview
    const threadContentRef = useThreadContentRef()

    const history = useHistory()

    const programGuid = useSelectedProgramGuid()
    const { attribute: isSingleShareThread } =
        useSelectedEnrolledProgramAttribute("isSingleShareThread")

    const { userScores } = useTriviaMatchData()

    const handlePlayAnother = () => {
        if (isSingleShareThread) history.push("/program")
        else history.push(`/program/${programGuid}`)
    }

    const renderBlock = (block: Block) => {
        switch (block.type) {
            case BlockType.TEXT:
                return <TextPreview block={block} />
            case BlockType.IMAGE:
                return <ImagePreview block={block} />
            case BlockType.VIDEO:
                return <VideoPreview block={block} />
            case BlockType.IMAGE_CAROUSEL:
                return (
                    <ImageCarousel
                        id={block.id}
                        imagesList={block.choices?.filter((images) => images !== undefined)}
                    />
                )
            case BlockType.DIVIDER:
                return <Divider bubble={block} />
            case BlockType.ACCORDION:
                return <AccordionPreview bubble={block} insidePane />
            case BlockType.INSTRUCTOR_AVATAR:
                return <InstructorAvatar preview />
        }
    }

    return (
        <StyledPanePreviewContainer
            id={`${variant}-pane`}
            context={rest.context}
            $height={deviceIsMobile ? window.innerHeight : threadContentRef?.clientHeight}
            deviceIsMobile={deviceIsMobile}
        >
            <StyledPanePreview
                isMobile={isMobile}
                isUserApp={rest.context === PanePreviewContext.user}
            >
                {variant === BlockType.SYNOPSIS && (
                    <EndPaneContent
                        {...(rest as EndPane)}
                        renderBlock={renderBlock}
                        isMobile={isMobile}
                    />
                )}
            </StyledPanePreview>
            <StyledColumn css={{ margin: "0 12px" }}>
                {isMobile && (
                    <Button
                        variant={"outline"}
                        onClick={handlePlayAnother}
                        buttonStyle={{ padding: "12px 8px", marginTop: "30px" }}
                        label={
                            <StyledBox css={{ display: "flex", gap: "6px", alignItems: "center" }}>
                                <StyledH5 useNewDesign>Play Another</StyledH5>
                                <StyledPlayArrowIcon />
                            </StyledBox>
                        }
                    />
                )}
                {rest.context === PanePreviewContext.user && !isMobile && (
                    <ShareThreadButton
                        copyVariant={CopyThreadButtonVariant.SHARE}
                        styles={{
                            maxWidth: "481px",
                            alignSelf: "center",
                            padding: "11px 12px",
                            marginTop: "12px",
                        }}
                        additionalText={
                            userScores
                                ? `I just scored ${userScores.totalPoints} pts on this trivia thred. Can you beat my score?`
                                : undefined
                        }
                        isMobile={isMobile}
                    />
                )}
                {rest.context === PanePreviewContext.user && (
                    <ShareThreadButton
                        styles={{
                            maxWidth: isMobile ? "unset" : "481px",
                            alignSelf: "center",
                            padding: "11px 12px",
                            marginTop: "6px",
                            // when the pane preview is open, the bottom navigation bar is shown, so we
                            // need to save some space for that navigation bar.
                            marginBottom: "80px",
                        }}
                        additionalText={
                            userScores
                                ? `I just scored ${userScores.totalPoints} pts on this trivia thred. Can you beat my score?`
                                : undefined
                        }
                        isMobile={isMobile}
                    />
                )}
            </StyledColumn>
        </StyledPanePreviewContainer>
    )
}

type EndPaneContentProps = EndPane & {
    renderBlock: (block: Block) => JSX.Element | undefined
    isMobile?: boolean
}

const EndPaneContent = ({ context, renderBlock, isMobile, ...rest }: EndPaneContentProps) => {
    const { previewMode } = usePreviewMode()
    const synopsisString = useCurrentThreadField(BlockType.SYNOPSIS)
    const threadStatus = useCurrentResponseThreadField("status")
    const synopsisBlocks = parseBlocksString(synopsisString)
    const shouldShowScore = useMemo(
        () =>
            (context === PanePreviewContext.user && threadStatus === ThreadStatus.Completed) ||
            context === PanePreviewContext.creator ||
            previewMode,
        [context, threadStatus, previewMode]
    )

    const {
        user,
        challenger,
        matchResult,
        userScores,
        challengerScores,
        loading: participantsLoading,
    } = useTriviaMatchData()

    const getTitle = () => {
        switch (matchResult) {
            case MATCH_RESULT.USER_WON:
                return "You Won!"
            case MATCH_RESULT.USER_LOST:
                return "You Lost!"
            case MATCH_RESULT.TIE:
                return "You Tied!"
            case MATCH_RESULT.NO_MATCH:
                return "Nicely Done!"
        }
    }

    return (
        <StyledEndPaneContentContainer mobile={isMobile}>
            <StyledEndPaneContentHeader
                disabled={context === PanePreviewContext.creator}
                mobile={isMobile}
            >
                {!participantsLoading && (
                    <>
                        <StyledResult
                            useNewDesign
                            variant={
                                [MATCH_RESULT.NO_MATCH, MATCH_RESULT.TIE].includes(matchResult)
                                    ? undefined
                                    : matchResult === MATCH_RESULT.USER_WON
                                    ? "winner"
                                    : "loser"
                            }
                        >
                            {getTitle()}
                        </StyledResult>
                        <StyledRow css={{ justifyContent: "space-between" }}>
                            <Player
                                user={context === PanePreviewContext.user ? user : undefined}
                                variant={
                                    [MATCH_RESULT.NO_MATCH, MATCH_RESULT.TIE].includes(matchResult)
                                        ? undefined
                                        : matchResult === MATCH_RESULT.USER_WON
                                        ? "winner"
                                        : "loser"
                                }
                            />
                            <StyledColumn
                                css={{
                                    height: `${PLAYER_AVATAR_SIZE}px`,
                                    justifyContent: "center",
                                }}
                            >
                                <BoltIcon />
                            </StyledColumn>
                            <Player
                                user={challenger}
                                variant={
                                    [MATCH_RESULT.NO_MATCH, MATCH_RESULT.TIE].includes(matchResult)
                                        ? undefined
                                        : matchResult === MATCH_RESULT.USER_WON
                                        ? "loser"
                                        : "winner"
                                }
                            />
                        </StyledRow>
                    </>
                )}
                {shouldShowScore && (
                    <StyledEndPaneScore>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.5 }}
                        >
                            <StyledRow
                                css={{ alignItems: "center", justifyContent: "space-between" }}
                            >
                                <CountUpScore score={userScores?.totalBasePoints} delay={0.5} />
                                <StyledBody1>Correct Answer Pts</StyledBody1>
                                <CountUpScore
                                    score={challengerScores?.totalBasePoints}
                                    delay={0.5}
                                    rightAlign
                                />
                            </StyledRow>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 1.5 }}
                        >
                            <StyledRow
                                css={{ alignItems: "center", justifyContent: "space-between" }}
                            >
                                <CountUpScore score={userScores?.totalBonusPoints} delay={1.5} />
                                <StyledBody1>Speed Bonus Pts</StyledBody1>
                                <CountUpScore
                                    score={challengerScores?.totalBonusPoints}
                                    delay={1.5}
                                    rightAlign
                                />
                            </StyledRow>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 2.5 }}
                        >
                            <StyledHr css={{ margin: "0 !important" }} />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 3.5 }}
                        >
                            <StyledRow
                                css={{
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: "6px",
                                }}
                            >
                                <CountUpScore
                                    score={userScores?.totalPoints}
                                    delay={3.5}
                                    withBorder
                                />
                                <StyledBody1>Total Points</StyledBody1>
                                <CountUpScore
                                    score={challengerScores?.totalPoints}
                                    delay={3.5}
                                    withBorder
                                    rightAlign
                                />
                            </StyledRow>
                        </motion.div>
                    </StyledEndPaneScore>
                )}
            </StyledEndPaneContentHeader>
            {context === PanePreviewContext.creator && (
                <StyledBox css={{ marginTop: "12px" }}>
                    <Editor
                        blocksType={(rest as CreatorEndPane).blocksType}
                        blockIds={(rest as CreatorEndPane).blockIds}
                        handlePreview={(rest as CreatorEndPane).handlePreview}
                        containsChunks={(rest as CreatorEndPane).blocksType === BlocksType.BLOCKS}
                    />
                </StyledBox>
            )}
            {context === PanePreviewContext.user && !isEmpty(synopsisBlocks) && (
                <StyledBox css={{ marginTop: "12px" }}>
                    {synopsisBlocks?.map((block: Block) => (
                        <StyledBox
                            key={block.id}
                            css={{ display: "flex", flexDirection: "column" }}
                        >
                            {renderBlock(block)}
                        </StyledBox>
                    ))}
                </StyledBox>
            )}
        </StyledEndPaneContentContainer>
    )
}

interface PlayerProps {
    user?: Pick<User, "profileImageURL" | "firstName" | "lastName"> | null
    variant?: "winner" | "loser"
}

const Player = ({ user, variant }: PlayerProps) => {
    const theme = useTheme()

    const getRingColor = () => {
        switch (variant) {
            case "winner":
                return theme.colors.base.uiCorrect
            case "loser":
                return theme.colors.base.uiIncorrect
            default:
                return theme.colors.base.uiPrimary500
        }
    }
    return (
        <StyledColumn css={{ alignItems: "center", gap: "6px" }}>
            <StyledAvatarContainer color={getRingColor()}>
                {user ? (
                    <UserAvatar user={user} size={AvatarSize.XXXL} />
                ) : (
                    <PlaceholderUserAvatar />
                )}
            </StyledAvatarContainer>
            <EllipsisText
                variant={"h3"}
                text={user?.firstName ?? "-"}
                textStyles={{ color: theme.colors.base.uiLabelBase }}
                maxWidth={PLAYER_AVATAR_SIZE}
            />
        </StyledColumn>
    )
}

const PlaceholderUserAvatar = () => {
    const theme = useTheme()

    return (
        <StyledColumn
            css={{
                width: AvatarSize.XXXL,
                height: AvatarSize.XXXL,
                backgroundColor: theme.colors.base.uiPrimary500,
                borderRadius: "50%",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <ProfileIcon color={theme.colors.base.uiBgBase} width={48} height={48} />
        </StyledColumn>
    )
}

interface CountUpScoreProps extends Omit<CountUpProps, "end"> {
    score?: number
    withBorder?: boolean
    rightAlign?: boolean
}

export const CountUpScore = ({ score, withBorder, rightAlign, ...rest }: CountUpScoreProps) => {
    return (
        <StyledRow
            css={{
                width: `${PLAYER_AVATAR_SIZE}px`,
                justifyContent: rightAlign ? "flex-end" : "flex-start",
            }}
        >
            {!isNil(score) ? (
                <StyledCountUpScoreContainer withBorder={withBorder}>
                    <CountUp start={0} end={score} duration={1} {...rest} />
                </StyledCountUpScoreContainer>
            ) : (
                <StyledScore useNewDesign>-</StyledScore>
            )}
        </StyledRow>
    )
}

export default PanePreview
