import { LocationOnOutlined, WorkOutline } from "@mui/icons-material"
import {
    StyledBody1,
    StyledBody2,
    StyledColumn,
    StyledH4,
    StyledH5,
    StyledHr,
    StyledRow,
} from "../../../styles/styledcomponents"
import UserProfileImage from "../UserProfileImage"
import { StyledHeaderCard, StyledMetric, StyledMetricContainer } from "./styles"
import { useTheme } from "styled-components"
import { useCurrentUser } from "../../../hooks/currentUser"
import { useQuery } from "@apollo/client"
import { USER_PROFILE_METRICS } from "../../../apollo/queries"
import { useEffect, useState } from "react"

export enum MetricVariant {
    black,
    blue,
    green,
    red,
}

const ProfileMainCard = () => {
    const theme = useTheme()
    const { user } = useCurrentUser()
    return (
        <StyledHeaderCard>
            <UserProfileImage />
            {(user?.firstName || user?.emojis) && (
                <StyledColumn css={{ alignItems: "center" }}>
                    <StyledH4 useNewDesign>{user?.firstName}</StyledH4>
                    <StyledBody2>{user?.emojis}</StyledBody2>
                </StyledColumn>
            )}
            {user?.description && (
                <StyledBody1 css={{ marginTop: "6px", color: theme.colors.base.uiLabelBase }}>
                    {user?.description}
                </StyledBody1>
            )}
            {(user?.role || user?.company || user?.city) && (
                <StyledRow css={{ alignSelf: "stretch", gap: "6px", flexWrap: "wrap" }}>
                    {user?.role && (
                        <StyledRow css={{ alignItems: "center", gap: "3px" }}>
                            <WorkOutline
                                sx={{ color: theme.colors.base.uiBgIcon, fontSize: "12px" }}
                            />
                            <StyledBody2>{user.role}</StyledBody2>
                        </StyledRow>
                    )}
                    {user?.company && (
                        <StyledRow css={{ alignItems: "center", gap: "3px" }}>
                            <StyledBody2>@</StyledBody2>
                            <StyledBody2>{user.company}</StyledBody2>
                        </StyledRow>
                    )}
                    {user?.city && (
                        <StyledRow css={{ alignItems: "center", gap: "3px" }}>
                            <LocationOnOutlined
                                sx={{ color: theme.colors.base.uiBgIcon, fontSize: "12px" }}
                            />
                            <StyledBody2>{user.city}</StyledBody2>
                        </StyledRow>
                    )}
                </StyledRow>
            )}
            <StyledHr />
            <MetricsRow />
        </StyledHeaderCard>
    )
}

type Metric = {
    label: string
    amount: number
    variant: MetricVariant
}

const MetricsRow = () => {
    const [metrics, setMetrics] = useState<Metric[]>([])

    const { data } = useQuery(USER_PROFILE_METRICS)

    useEffect(() => {
        if (data?.aboutMe?.stats) {
            setMetrics([
                {
                    label: "Matches",
                    amount: data?.aboutMe?.stats?.matches,
                    variant: MetricVariant.black,
                },
                {
                    label: "Points",
                    amount: data?.aboutMe?.stats?.points,
                    variant: MetricVariant.blue,
                },
                {
                    label: "Wins",
                    amount: data?.aboutMe?.stats?.wins,
                    variant: MetricVariant.green,
                },
                {
                    label: "Losses",
                    amount: data?.aboutMe?.stats?.losses,
                    variant: MetricVariant.red,
                },
            ])
        }
    }, [data])

    const numberFormatter = Intl.NumberFormat("en", { notation: "compact" })

    return (
        <StyledRow css={{ width: "100%", justifyContent: "space-between" }}>
            {metrics.map(({ label, amount, variant }) => (
                <StyledMetricContainer key={`metric-${label}`} variant={variant}>
                    <StyledMetric>{numberFormatter.format(amount)}</StyledMetric>
                    <StyledH5 useNewDesign>{label}</StyledH5>
                </StyledMetricContainer>
            ))}
        </StyledRow>
    )
}

export default ProfileMainCard
