import { StyledTable, StyledTbody, StyledTd, StyledTh, StyledTHead, StyledTr } from "./styles"
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    createColumnHelper,
} from "@tanstack/react-table"

export enum Alignment {
    left = "left",
    center = "center",
    right = "right",
}

export type ColumnItem = {
    header: string
    alignment?: Alignment
    renderCell?: (rowData: any) => JSX.Element
}

export type Columns = {
    [key: string]: ColumnItem
}

type DataItem = {
    [keys: string]: unknown
}
interface TableProps {
    data: DataItem[]
    columns: Columns
    fullWidth?: boolean
}

const Table = ({ data, columns, fullWidth = true }: TableProps) => {
    const columnHelper = createColumnHelper<DataItem>()

    const table = useReactTable({
        data: data,
        columns: Object.keys(columns).map((columnKey) =>
            columnHelper.accessor((row) => row[columnKey], {
                id: columnKey,
                header: columns[columnKey].header,
                cell: (cell) => {
                    const renderCell = columns[columnKey].renderCell
                    if (renderCell) return renderCell(cell.row.original)
                    else return cell.getValue()
                },
            })
        ),
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <StyledTable fullWidth={fullWidth}>
            <StyledTHead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <StyledTr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <StyledTh key={header.id} alignment={columns[header.id]?.alignment}>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </StyledTh>
                        ))}
                    </StyledTr>
                ))}
            </StyledTHead>
            <StyledTbody>
                {table.getRowModel().rows.map((row) => (
                    <StyledTr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <StyledTd key={cell.id} alignment={columns[cell.column.id]?.alignment}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </StyledTd>
                        ))}
                    </StyledTr>
                ))}
            </StyledTbody>
        </StyledTable>
    )
}

export default Table
