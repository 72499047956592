import { CSSObject } from "styled-components"
import {
    StyledAnswerChoicesContainer,
    StyledLeftColumn,
    StyledManageResponsesModal,
    StyledManageResponsesModalHeader,
    StyledChoice,
    StyledNewChoiceButton,
    StyledRightColumn,
    StyledAddChunkContainer,
    StyledModalContent,
    StyledChoiceContainer,
    ChoiceVariant,
    StyledCorrectnessRadio,
    StyledCorrectIcon,
    StyledIncorrectIcon,
} from "./styles"
import CloseIcon from "@mui/icons-material/Close"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import CloseButton from "../../../../components/CloseButton"
import { isEmpty, isNil } from "lodash"
import SortableList, { MoveItemArguments } from "../../../../components/SortableList"
import {
    Block,
    BlockType,
    BlocksType,
    ChoiceQuestionOption,
    FreeTextResponse,
} from "../../../../types"
import { useDispatch, useSelector } from "react-redux"
import {
    addBlocks,
    addFreeTextResponse,
    addQuestionOption,
    deleteFreeTextResponse,
    deleteQuestionOption,
    findBlock,
    getFullBlockById,
    updateQuestionOptions,
} from "../../../../redux/blocks"
import { Editor } from "../../EditorScreen/Editor"
import { RootState } from "../../../../redux/store"
import { getFreeTextResponse, getQuestionOption } from "../../../../utils/utils"
import AddChunkMenu from "../../AddChunkMenu"
import { ContentBlockMenuKey } from "../../AddChunkMenu/constants"
import { StyledColumn, StyledHr } from "../../../../styles/styledcomponents"
import SearchBar from "../../../../deliver/components/SearchBar"
import TextareaAutosize from "react-textarea-autosize"

interface ManageResponsesModalProps {
    block: Block
    blocksType: BlocksType
    onClose: () => void
}

const mapFreeTextResponseToQuestionOption = (response: FreeTextResponse): ChoiceQuestionOption => ({
    id: response.id,
    text: response.input,
    objects: response.objects,
    correct: response.correct,
})

const getChoices = (block: Block): ChoiceQuestionOption[] => {
    switch (block.type) {
        case BlockType.FREE_TEXT_QUESTION:
            if (block.responses) return block.responses.map(mapFreeTextResponseToQuestionOption)
            return []
        case BlockType.CHOICE_QUESTION:
            if (block.options) return block.options
            return []
        default:
            return []
    }
}

const ManageResponsesModal = ({ block, blocksType, onClose }: ManageResponsesModalProps) => {
    const [selectedOptionId, setSelectedOptionId] = useState<string>()
    const [search, setSearch] = useState("")

    const choices = useSelector((state: RootState) => {
        const fullBlock = getFullBlockById(
            block.id,
            state.blocksReducer.blocks,
            state.blocksReducer.blocksIndex
        )
        return getChoices(fullBlock)
    })

    const dispatch = useDispatch()

    const maySelectMultiple =
        block.type === BlockType.FREE_TEXT_QUESTION || block.properties?.may_select_multiple

    // `choices` filtered by `search`
    const filteredChoices = useMemo(
        () =>
            choices.filter((choice) =>
                search ? choice.text?.toLowerCase().includes(search.toLowerCase()) : choice
            ),
        [search, choices]
    )

    const selectedOptionIndex = useMemo(
        () => choices?.findIndex((option) => option.id === selectedOptionId),
        [choices, selectedOptionId]
    )

    useEffect(() => {
        // Focus the selected option when it changes
        selectedOptionId &&
            setTimeout(() => document.getElementById(`choice-${selectedOptionId}`)?.focus())
    }, [selectedOptionId])

    useEffect(() => {
        // On mount, set the first option as selected
        if (!isEmpty(choices)) setSelectedOptionId(choices[0].id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const blockIdsObjects = useSelector((state: RootState) => {
        const foundBlock = findBlock(block.id, state.blocksReducer.blocksIndex)
        if (block.type === BlockType.CHOICE_QUESTION) {
            if (foundBlock?.options && foundBlock.options[selectedOptionIndex]) {
                return foundBlock.options[selectedOptionIndex].objects
            }
        } else if (block.type === BlockType.FREE_TEXT_QUESTION) {
            if (foundBlock?.responses && foundBlock.responses[selectedOptionIndex]) {
                return foundBlock.responses[selectedOptionIndex].objects
            }
        }
    })

    const onChoiceChange = (id: string, text: string) => {
        const updatedAnswerChoices = choices!.map((option) => {
            if (option.id === id) {
                return { ...option, text }
            }
            return option
        })
        dispatch(
            updateQuestionOptions({ blocksType, blockId: block.id, options: updatedAnswerChoices })
        )
    }

    const onChoiceDelete = (optionId: string) => {
        if (selectedOptionIndex && choices?.length && choices?.length > 1) {
            const prevOptionId = choices[selectedOptionIndex - 1].id
            setSelectedOptionId(prevOptionId)
        }

        if (block.type === BlockType.CHOICE_QUESTION) {
            dispatch(deleteQuestionOption({ blocksType, optionId, blockId: block.id }))
        } else if (block.type === BlockType.FREE_TEXT_QUESTION) {
            dispatch(
                deleteFreeTextResponse({ blocksType, responseId: optionId, blockId: block.id })
            )
        }
    }

    const onChoiceAdd = () => {
        if (block.type === BlockType.CHOICE_QUESTION) {
            const newOption = getQuestionOption()
            dispatch(addQuestionOption({ blocksType, option: newOption, blockId: block.id }))
            setSelectedOptionId(newOption.id)
        } else if (block.type === BlockType.FREE_TEXT_QUESTION) {
            const newResponse = getFreeTextResponse()
            dispatch(addFreeTextResponse({ blocksType, response: newResponse, blockId: block.id }))
            setSelectedOptionId(newResponse.id)
        }
    }

    const changeAnswersCorrectness = (
        ids: string[],
        correct: boolean,
        otherAnswerCorrectness?: boolean
    ) => {
        const updatedAnswerChoices = choices.map((option) => {
            if (ids.includes(option.id)) return { ...option, correct }
            if (otherAnswerCorrectness !== undefined)
                return { ...option, correct: otherAnswerCorrectness }
            return option
        })
        dispatch(
            updateQuestionOptions({ blocksType, blockId: block.id, options: updatedAnswerChoices })
        )
    }

    const onCorrectnessChange = (id: string) => {
        const answerChoiceFound = choices.find((option) => option.id === id)
        // Single correct answer, always one answer has to be correct
        if (!maySelectMultiple) {
            if (answerChoiceFound?.correct) return
            changeAnswersCorrectness([id], true, false)
        }
        // Multiple correct answer, at least 2 answer have to be correct
        else {
            const quantityOfCorrect = choices.filter((option) => option.correct).length

            if (!answerChoiceFound?.correct) {
                changeAnswersCorrectness([id], true)
            } else if (quantityOfCorrect && quantityOfCorrect > 2) {
                changeAnswersCorrectness([id], false)
            }
        }
    }

    const onChoiceMove = (args: MoveItemArguments) => {
        const { indexFrom, indexTo } = args
        let updatedAnswerChoices = [...(choices || [])]
        const fromItem = updatedAnswerChoices[indexFrom]
        updatedAnswerChoices.splice(indexFrom, 1)
        updatedAnswerChoices.splice(indexFrom < indexTo ? indexTo - 1 : indexTo, 0, fromItem)
        dispatch(
            updateQuestionOptions({ blocksType, blockId: block.id, options: updatedAnswerChoices })
        )
    }

    const handleAddChunk = useCallback(
        (chunk: Block) => {
            dispatch(
                addBlocks({
                    blocksType: BlocksType.BLOCKS,
                    blocks: [chunk],
                    questionOptionId:
                        block.type === BlockType.CHOICE_QUESTION ? selectedOptionId : undefined,
                    freeTextResponseId:
                        block.type === BlockType.FREE_TEXT_QUESTION ? selectedOptionId : undefined,
                })
            )
        },
        [block.type, dispatch, selectedOptionId]
    )

    const choiceVariant = useMemo(() => {
        switch (block.type) {
            case BlockType.CHOICE_QUESTION:
                return ChoiceVariant.MULTIPLE_CHOICE
            case BlockType.FREE_TEXT_QUESTION:
                return ChoiceVariant.FREE_TEXT
        }
    }, [block.type])

    return (
        <StyledManageResponsesModal open nested onClose={onClose}>
            <StyledManageResponsesModalHeader>
                <CloseIcon onClick={onClose} sx={{ fontSize: "15px", cursor: "pointer" }} />
            </StyledManageResponsesModalHeader>
            <StyledModalContent>
                <StyledLeftColumn>
                    {choiceVariant === ChoiceVariant.FREE_TEXT && (
                        <SearchBar value={search} onChange={setSearch} />
                    )}
                    <StyledAnswerChoicesContainer>
                        <SortableList
                            list={filteredChoices}
                            moveItem={onChoiceMove}
                            renderItem={(choice, index) => (
                                <Choice
                                    key={`answer-choice-${index}`}
                                    id={choice.id}
                                    variant={choiceVariant}
                                    text={choice.text}
                                    correct={choice.correct}
                                    selected={selectedOptionId === choice.id}
                                    onSelect={(optionId) => {
                                        setSelectedOptionId(optionId)
                                    }}
                                    onChange={onChoiceChange}
                                    onDelete={onChoiceDelete}
                                    onEnterPress={onChoiceAdd}
                                    onCorrectnessChange={onCorrectnessChange}
                                    showCorrectness={!block.properties?.no_correct_answers}
                                    correctnessShape={
                                        maySelectMultiple
                                            ? CorrectnessShape.square
                                            : CorrectnessShape.circular
                                    }
                                    styles={{ marginBottom: "3px" }}
                                    canBeDeleted
                                />
                            )}
                        ></SortableList>
                    </StyledAnswerChoicesContainer>
                    <StyledNewChoiceButton onClick={onChoiceAdd} variant={choiceVariant}>
                        {choiceVariant === ChoiceVariant.FREE_TEXT ? "+ Answer" : "+ Choice"}
                    </StyledNewChoiceButton>
                </StyledLeftColumn>
                <StyledRightColumn>
                    {selectedOptionId && !isNil(selectedOptionIndex) && (
                        <>
                            <Editor
                                blocksType={blocksType}
                                blockIds={blockIdsObjects || []}
                                questionOptionId={
                                    block.type === BlockType.CHOICE_QUESTION
                                        ? selectedOptionId
                                        : undefined
                                }
                                freeTextResponseId={
                                    block.type === BlockType.FREE_TEXT_QUESTION
                                        ? selectedOptionId
                                        : undefined
                                }
                                parentType={block.type}
                                containsChunks
                                enableDeletingAllBlocks
                            />
                            <StyledAddChunkContainer>
                                <AddChunkMenu
                                    handleAddChunk={handleAddChunk}
                                    allowedChunks={Object.values(ContentBlockMenuKey)}
                                />
                            </StyledAddChunkContainer>
                        </>
                    )}
                </StyledRightColumn>
            </StyledModalContent>
        </StyledManageResponsesModal>
    )
}

interface ChoiceProps {
    id: string
    variant?: ChoiceVariant
    text?: string
    canBeDeleted?: boolean
    correct?: boolean
    showCorrectness?: boolean
    correctnessShape?: CorrectnessShape
    styles?: CSSObject
    selected?: boolean
    onSelect: (id: string) => void
    onChange: (id: string, newText: string) => void
    onDelete: (id: string) => void
    onEnterPress: () => void
    onCorrectnessChange: (id: string) => void
}

const Choice = ({
    id,
    variant,
    text,
    canBeDeleted,
    correct,
    showCorrectness,
    correctnessShape,
    styles,
    selected,
    onSelect,
    onChange,
    onDelete,
    onEnterPress,
    onCorrectnessChange,
}: ChoiceProps) => {
    const [focused, setFocused] = useState(false)
    let inputRef = useRef<HTMLTextAreaElement | null>(null)

    const handleKeyDown = (event: any) => {
        switch (event.code) {
            case "Backspace":
                text === "" && onDelete(id)
                break
            case "Enter":
                event.preventDefault()
                text && onEnterPress()
                break
        }
    }

    return (
        <StyledChoiceContainer
            css={{ ...styles }}
            onMouseEnter={() => setFocused(true)}
            onMouseLeave={() => setFocused(false)}
            variant={variant}
        >
            {showCorrectness && (
                <CorrectnessRadio
                    correct={correct}
                    css={{ marginRight: "6px" }}
                    shape={correctnessShape}
                    onChange={() => onCorrectnessChange(id)}
                />
            )}
            <StyledColumn css={{ width: "inherit", alignItems: "flex-end" }}>
                <StyledChoice onClick={() => onSelect(id)} selected={selected} variant={variant}>
                    <TextareaAutosize
                        id={`choice-${id}`}
                        value={text}
                        onChange={(event) => onChange(id, event.target.value)}
                        onKeyDown={handleKeyDown}
                        ref={(innerRef) => (inputRef.current = innerRef)}
                        maxRows={3}
                    />
                </StyledChoice>
                {variant === ChoiceVariant.FREE_TEXT && <StyledHr />}
            </StyledColumn>
            {focused && canBeDeleted && (
                <CloseButton
                    css={{ top: "-10px" }}
                    onClose={(e) => {
                        e.stopPropagation()
                        onDelete(id)
                    }}
                />
            )}
        </StyledChoiceContainer>
    )
}

export enum CorrectnessShape {
    circular,
    square,
}

type CorrectnessRadioProps = {
    correct?: boolean
    size?: number
    css?: CSSObject
    shape?: CorrectnessShape
    onChange: () => void
}

export const CorrectnessRadio = ({
    correct,
    size = 18,
    shape = CorrectnessShape.circular,
    css,
    onChange,
}: CorrectnessRadioProps) => {
    return (
        <StyledCorrectnessRadio
            correct={correct}
            size={size}
            css={css}
            shape={shape}
            onClick={onChange}
        >
            {correct ? <StyledCorrectIcon /> : <StyledIncorrectIcon />}
        </StyledCorrectnessRadio>
    )
}

export default ManageResponsesModal
