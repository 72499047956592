import { useEffect, useMemo } from "react"
import { useThreadBlocks } from "./threadHook"
import "./ThreadPreview.scss"
import {
    Block,
    BlockType,
    OptionPreviewMode,
    QuestionChoice,
    ThreadFont,
    ThreadTheme,
} from "../../types"
import classNames from "classnames"
import { sessionDataVar } from "../../apollo/cache-store"
import { useReactiveVar } from "@apollo/client"
import { setThreadTheme } from "../../utils/utils"
import { useCurrentThread } from "../../hooks/currentThread.hook"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { ThreadSkeleton } from "../../common/components/LoaderSkeleton/ThreadSkeleton"
import { StyledBox } from "../../styles/styledcomponents"
import { THREAD_CONTENT_REF_ID } from "../../utils/consts"
import { CSSObject, DefaultTheme, ThemeProvider, useTheme } from "styled-components"
import useIntersectionObserver from "../../hooks/intersection.hook"
import Confetti from "react-confetti"
import {
    setShowConfetti,
    setThreadDoneScrolling,
    setThreadFoundLastBubble,
} from "../../redux/layout"
import { useSelectedEnrolledProgram } from "../../hooks/enrolledProgram.hook"
import { ThreadStatus, UserAnswer } from "../../apollo/generated/graphql"
import { Maybe } from "graphql/jsutils/Maybe"
import RenderPreviewBlock from "./RenderPreviewBlock"
import SwitchedDevicesModal from "../SwitchedDevicesModal"
import { StyledThreadPreviewContainer } from "./styles"
import { useThreadContentRef } from "../../hooks/threadContentRef.hook"

export interface ThreadPreviewProps {
    isPreview?: boolean
    isScoreHeaderShown?: boolean
    loading?: boolean
    isMobile?: boolean
    setThreadReady?: (ready: boolean) => void
}

const ThreadPreview = ({
    isPreview,
    isScoreHeaderShown,
    loading,
    isMobile,
    setThreadReady,
}: ThreadPreviewProps) => {
    const thread = useCurrentThread()
    const { selectedEnrolledProgram } = useSelectedEnrolledProgram()
    const sessionData = useReactiveVar(sessionDataVar)
    const mode = useSelector((state: RootState) => state.threadsReducer.previewDevice)
    const showConfetti = useSelector((state: RootState) => state.layoutReducer.showConfetti)
    const doneScrolling = useSelector((state: RootState) => state.layoutReducer.threadDoneScrolling)
    const foundLastBubble = useSelector(
        (state: RootState) => state.layoutReducer.threadFoundLastBubble
    )

    const threadContentRef = useThreadContentRef()

    const theme = useTheme()

    const dispatch = useDispatch()

    const scrollToBottom = (behavior?: ScrollBehavior) => {
        /*
         * If there's a lastMessageRendered and it hasn't been found yet
         * the thread shouldn't scroll to the bottom until it's found
         */
        const noScroll = !!thread.currentObjectID && !foundLastBubble
        if (!noScroll) {
            setTimeout(() => {
                threadContentRef?.scroll({
                    behavior: behavior ?? "smooth",
                    top: threadContentRef.scrollHeight - threadContentRef.clientHeight,
                })
            })
        }
    }

    const showSwitchedDevicesModal = useSelector(
        (state: RootState) => state.layoutReducer.showSwitchedDevicesModal
    )

    const { bubbles, handleAnswer } = useThreadBlocks(scrollToBottom, isPreview)

    /*
     * When the lastMessageRendered intersects the viewport, we set
     * doneScrolling to true.
     */
    const { observe } = useIntersectionObserver({
        partialViewableCallback: () => dispatch(setThreadDoneScrolling(true)),
        fullyViewableCallback: () => dispatch(setThreadDoneScrolling(true)),
    })

    useEffect(() => {
        // Cleanup thread data in redux when unmounting
        return () => {
            dispatch(setShowConfetti(false))
            dispatch(setThreadDoneScrolling(false))
            dispatch(setThreadFoundLastBubble(false))
        }
    }, [])

    /*
     * Here we set an intersection observer to the lastMessageRendered
     */
    useEffect(() => {
        /*
         * If the thread is completed we need to search for the synopsis pane instead of the last message render
         * otherwise it won't find the last bubble and the skeleton loader won't go away
         * */
        if (thread.status === ThreadStatus.Completed) {
            const synopsisPane = document.querySelector(`#${BlockType.SYNOPSIS}-pane`)
            synopsisPane && observe(synopsisPane)
        } else if (thread.currentObjectID && foundLastBubble) {
            const element = document.querySelector(`#bubble-${thread.currentObjectID}`)
            element && observe(element)
        }
    }, [thread.status, thread.currentObjectID, foundLastBubble])

    const showLoader = useMemo(
        () =>
            /*
             * If setThreadReady exists then we don't show a loader,
             * since that's handled externally
             */
            !setThreadReady &&
            (loading ||
                !thread ||
                (!isPreview && !!thread.currentObjectID && (!foundLastBubble || !doneScrolling))),
        [loading, thread, isPreview, foundLastBubble, doneScrolling]
    )
    const showBubbles = useMemo(() => !loading && thread, [loading, thread])

    /*
     * When using an external loader, here's where
     * we check when the thread is ready to be shown
     */
    useEffect(() => {
        if (
            setThreadReady &&
            !loading &&
            thread &&
            (isPreview || !thread.currentObjectID || (foundLastBubble && doneScrolling))
        ) {
            setThreadReady(true)
        }
    }, [loading, thread, isPreview, foundLastBubble, doneScrolling])

    const bubbleRender = (bubble: Block, index: number) => {
        // We check for the groupId field for the case of bult-in pause components
        const answer =
            thread.userAnswers &&
            thread.userAnswers.find((answer) =>
                bubble.groupId ? answer.object === bubble.groupId : answer.object === bubble.id
            )

        return (
            <RenderPreviewBlock
                bubble={bubble}
                index={index}
                readonly={false}
                answer={answer}
                scrollToBottom={scrollToBottom}
                isMobile={isMobile}
                mode={mode}
                handleAnswer={handleAnswer}
                isPreview={isPreview}
                threadId={parseInt(thread.thread!.id.toString())}
                threadGuid={thread.thread?.guid}
                programId={selectedEnrolledProgram?.id}
                programGuid={selectedEnrolledProgram?.guid}
            />
        )
    }

    const containerStyle = useMemo(() => {
        const baseStyle: CSSObject = {
            display: "flex",
            justifyContent: "center",
            backgroundColor: theme.colors.thread.background,
        }
        if (isPreview) {
            baseStyle.borderRadius = "8px"
            baseStyle.height = "calc(100% - 50px)"
            baseStyle.marginTop = 0
        } else {
            baseStyle.height = `calc(100% - 69px - ${isScoreHeaderShown ? "68px" : "0px"})`
            baseStyle.marginTop = `calc(69px + ${isScoreHeaderShown ? "68px" : "0px"})`
        }
        return baseStyle
    }, [isPreview, isScoreHeaderShown])

    /*
     * This check handles the case where the start telemetry event fails.
     * Note that the session id could be null in the case that the thread status is different from 'unlocked' or
     * 'in progress'
     * */
    if (
        !isPreview &&
        ((!sessionData?.id &&
            (thread?.status === ThreadStatus.Unlocked ||
                thread?.status === ThreadStatus.InProgress)) ||
            showSwitchedDevicesModal)
    ) {
        return <SwitchedDevicesModal />
    }

    return (
        <ThemeProvider
            theme={(baseTheme: DefaultTheme) =>
                setThreadTheme(
                    baseTheme,
                    thread.thread?.theme as ThreadTheme,
                    thread.thread?.font as ThreadFont
                )
            }
        >
            <StyledBox css={containerStyle}>
                <StyledThreadPreviewContainer
                    id={THREAD_CONTENT_REF_ID}
                    mobile={isMobile}
                    isPreview={isPreview}
                >
                    <div
                        className={classNames("preview-mobile", {
                            visible: isPreview && mode === OptionPreviewMode.MOBILE,
                        })}
                    >
                        <div
                            className={classNames("thread-preview", {
                                mobile: isMobile,
                            })}
                            data-testid={"thread-preview"}
                        >
                            {showLoader && (
                                <StyledBox
                                    css={{
                                        position: "fixed",
                                        width: isPreview ? "calc(100% - 24px)" : "100%",
                                        height: isPreview
                                            ? `calc(844px - 98px)`
                                            : `calc(100vh - ${theme.variables.common.topBarHeight})`,
                                        top: isPreview
                                            ? "unset"
                                            : `${theme.variables.common.topBarHeight}`,
                                        paddingTop: isPreview ? "0" : "29px",
                                        backgroundColor: theme.colors.base.uiBgBase,
                                        zIndex: 1,
                                        overflow: "hidden",
                                        borderRadius: "8px",
                                        marginTop: "-12px",
                                    }}
                                >
                                    <StyledBox
                                        css={{
                                            width: "100%",
                                            maxWidth: "590px",
                                        }}
                                    >
                                        <ThreadSkeleton />
                                    </StyledBox>
                                </StyledBox>
                            )}
                            {showConfetti && (
                                <Confetti
                                    run={true}
                                    onConfettiComplete={() => dispatch(setShowConfetti(false))}
                                    numberOfPieces={100}
                                    recycle={false}
                                    style={{
                                        position: "fixed",
                                        overflowX: "hidden",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    gravity={0.5}
                                />
                            )}
                            {showBubbles &&
                                bubbles.map((bubble: Block, index: number) => {
                                    return (
                                        <span
                                            key={bubble.id}
                                            id={`bubble-${bubble.id}`}
                                            style={{ display: "flex", flexDirection: "column" }}
                                            data-testid={"preview-bubble-" + index}
                                            data-testtype={bubble.type}
                                        >
                                            {bubbleRender(bubble, index)}
                                        </span>
                                    )
                                })}
                        </div>
                    </div>
                </StyledThreadPreviewContainer>
            </StyledBox>
        </ThemeProvider>
    )
}

export interface QuestionPreviewProps {
    bubble: Block
    // todo: check if we can use `Choice` instead
    handleAnswer: (option: QuestionChoice[], showTypingIndicator?: boolean) => void
    answer?: Maybe<UserAnswer>
    threadId?: number
    programId?: number
    scrollToBottom?: () => void
    isMobile?: boolean
    readonly?: boolean
    isPreview?: boolean
}

export default ThreadPreview
