import styled from "styled-components"
import {
    StyledBody1Styles,
    StyledColumn,
    StyledH2,
    StyledH3,
    StyledH4Styles,
    StyledHr,
} from "../styles/styledcomponents"

export const StyledLeaderboardContainer = styled.div`
    background-color: ${({ theme }) => theme.headlandsGray4};
    flex: 1;
    height: fit-content;
    min-height: ${window.innerHeight}px;
    display: flex;
    flex-direction: column;

    ${StyledH2} {
        font-size: 24px;
        line-height: 30px;
    }
`

export const StyledLeaderboardCard = styled.div<{ mobile?: boolean }>`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.headlandsWhite};
    margin: 18px;
    padding: 12px;
    border-radius: 16px;
    width: ${({ mobile }) => (mobile ? "unset" : "fit-content")};
    max-width: ${({ mobile }) => (mobile ? "unset" : "calc(100% - 36px)")};
    align-self: ${({ mobile }) => (mobile ? "unset" : "flex-start")};
    box-sizing: ${({ mobile }) => (mobile ? "unset" : "border-box")};

    ${StyledH2} {
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.headlandsGray1};
    }

    ${StyledH3} {
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme.headlandsGray1};
    }

    ${StyledHr} {
        width: 100%;
        border-color: ${({ theme }) => theme.headlandsGray3};
        margin-bottom: 24px;
    }
`

export const StyledMobileLeaderboardScreen = styled(StyledColumn)`
    min-height: 100%;
    height: fit-content;
    box-sizing: border-box;
    flex: 1;
    gap: 3px;
    padding: 12px;
    /* 66px is for the fixed positioned TopBar */
    padding-top: calc(12px + 66px);
    /* 60px is for the fixed positioned bottom nav bar */
    padding-bottom: calc(12px + 60px);
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
`

export const StyledProgramSelectContainer = styled(StyledColumn)`
    .react-select {
        width: 100%;
        display: flex;
        justify-content: center;

        .react-select__control {
            min-height: 22px;
            padding: 0;
            margin: 0;
            border: none !important;
            box-shadow: none;
            border-radius: 0;
            transition: unset;
            cursor: pointer;
            width: fit-content;

            .react-select__single-value {
                ${StyledH4Styles};
                color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
            }

            .react-select__dropdown-indicator {
                color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
                &:hover {
                    color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
                }
            }
        }
        .react-select__menu {
            width: 300px;
            left: unset;
            top: 30px;
            border-radius: 8px;
            box-shadow: ${({ theme }) => theme.shadows.small};

            .react-select__menu-list {
                // each item on the list has 32px height, we render  5.5 items
                max-height: 176px;

                .react-select__option {
                    ${StyledBody1Styles};
                    color: ${({ theme }) => theme.colors.base.uiLabelBase};
                    cursor: pointer;

                    &:hover,
                    &.react-select__option--is-focused {
                        background-color: unset;
                    }

                    &.react-select__option--is-selected {
                        font-weight: 700;
                        background-color: ${({ theme }) => theme.colors.base.uiBgThreadActive};
                    }
                }
            }
        }
    }
`
