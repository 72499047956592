import styled, { css } from "styled-components"
import { StyledBox, StyledHeadlandsPopup } from "../../styles/styledcomponents"

export const StyledMenuPopup = styled(StyledHeadlandsPopup)`
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: unset;
    border-radius: ${({ theme }) => theme.borderRadius2};

    hr {
      width: 80%;
      color: ${({ theme }) => theme.headlandsGray3};
      border-top: solid 1px;
      margin: 0;
    }
`

export const StyledMenuPopupOption = styled(StyledBox)<{ disabled?: boolean; centered?: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 6px 12px;

    :first-of-type {
        border-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    :last-of-type {
        border-radius: inherit;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    :only-child {
        border-radius: inherit;
    }

    span {
        cursor: pointer;
        display: flex;
        justify-content: center;
        color: ${({ theme }) => theme.headlandsBlack1};
    }

    &:hover {
        background-color: ${({ theme }) => theme.headlandsGray6};
    }

    ${({ centered }) =>
        centered &&
        css`
            justify-content: center;
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            background-color: ${({ theme }) => theme.headlandsGray4};
            cursor: not-allowed;

            span {
                cursor: not-allowed;
                color: ${({ theme }) => theme.headlandsGray7} !important;
            }

            svg {
                path {
                    fill: ${(props) => props.theme.headlandsGray8} !important;
                }
            }
        `}
`

export const StyledIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 18px;
        height: 18px;
        margin-right: 7px;
        color: ${({ theme }) => theme.headlandsGrayDark};
    }
`
