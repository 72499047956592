import { StyledText, StyledTitle } from "./styles"
import Input from "../components/InputNew"
import { Button } from "../components/Button/Button"
import { emailRegex } from "../utils/consts"
import { BaseContentProps } from "./index"

interface EmailContentProp extends BaseContentProps {
    email: string
    loading?: boolean
}

const EmailContent = ({ handleSubmit, handleChange, email, loading }: EmailContentProp) => {
    return (
        <>
            <StyledTitle>Welcome</StyledTitle>
            <StyledText css={{ marginBottom: "24px" }}>
                We’ll sign you in or create an account if you don’t have one yet
            </StyledText>
            <form onSubmit={handleSubmit}>
                <Input
                    id={"email"}
                    type={"email"}
                    name={"email"}
                    label={"Email Address"}
                    value={email}
                    onChange={handleChange}
                />
                <Button
                    type={"submit"}
                    label={"Continue"}
                    disabled={!emailRegex.test(email)}
                    loading={loading}
                />
            </form>
        </>
    )
}

export default EmailContent
