export const CloseSidebarIcon = () => {
    return (
        <svg
            width="18.05"
            height="13.95"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.41255 11.2308H12.2021C13.4365 11.2308 14.0765 10.6085 14.0765 9.41435V2.58576C14.0765 1.3916 13.4365 0.769287 12.2021 0.769287H2.41255C1.17815 0.769287 0.538086 1.38599 0.538086 2.58576V9.41435C0.538086 10.6085 1.17815 11.2308 2.41255 11.2308ZM2.48113 10.132C1.94965 10.132 1.6582 9.86286 1.6582 9.31904V2.68107C1.6582 2.13725 1.94965 1.86253 2.48113 1.86253H12.1335C12.6593 1.86253 12.9564 2.13725 12.9564 2.68107V9.31904C12.9564 9.86286 12.6593 10.132 12.1335 10.132H2.48113ZM2.94403 9.31344H4.56134C4.88708 9.31344 5.01281 9.18449 5.01281 8.86493V3.13519C5.01281 2.81562 4.88708 2.68107 4.56134 2.68107H2.94403C2.61829 2.68107 2.49256 2.81562 2.49256 3.13519V8.86493C2.49256 9.18449 2.61829 9.31344 2.94403 9.31344Z"
                fill="#888888"
            />
        </svg>
    )
}
