import { useQuery } from "@apollo/client"
import { GENERATE_EDIT_CONTENT } from "../../../../apollo/queries"
import { AiEditData } from "../../EditorScreen/Editor"
import AiPopupFooter from "../AiPopupFooter"
import AiEditPopupContent from "./AiEditPopupContent"
import AiPopup, { AiPopupProps } from ".."

interface AiEditPopupProps extends AiPopupProps {
    data: AiEditData
}

const AiEditPopup = ({ data, onCancel, parentHeight }: AiEditPopupProps) => {
    const { type, text, onSubmit } = data

    const {
        data: generatedData,
        error: generateError,
        loading: generateLoading,
        refetch,
    } = useQuery(GENERATE_EDIT_CONTENT, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        notifyOnNetworkStatusChange: true,
        variables: {
            content: text,
            type: type,
        },
    })

    const handleSubmit = () => {
        onSubmit(generatedData?.aiGenEditContent || "")
        onCancel()
    }

    return (
        <AiPopup onCancel={onCancel} parentHeight={parentHeight}>
            <>
                <AiEditPopupContent
                    text={generatedData?.aiGenEditContent || ""}
                    loading={generateLoading}
                    error={generateError}
                />
                <AiPopupFooter
                    retry={() => refetch()}
                    discard={onCancel}
                    // we use the ref here so the listeners can work properly
                    submit={handleSubmit}
                    loading={generateLoading}
                />
            </>
        </AiPopup>
    )
}

export default AiEditPopup
