import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Block, OptionPreviewMode, ResponseThread, ThreadFont, ThreadTheme } from "../types"

import {
    Attachment,
    StartThreadResponse,
    Thread,
    ThreadStatus,
    UserAnswer,
    VariableWithValue,
} from "../apollo/generated/graphql"

export interface ThreadsState {
    threads: { [guid: string]: ResponseThread }
    previewMode: boolean
    previewDevice: OptionPreviewMode
}

const initialState: ThreadsState = {
    threads: {},
    previewMode: false,
    previewDevice: OptionPreviewMode.MOBILE,
}

/*
 * Auxiliary function to Partially update a `Thread` on the state
 * */
const updateThreadInState = (
    state: ThreadsState,
    guid: string,
    partialThread: Partial<Thread>
): ThreadsState => {
    state.threads[guid] = {
        ...state.threads[guid],
        thread: {
            ...state.threads[guid]?.thread,
            ...partialThread,
        } as Thread,
    }
    return state
}

/*
 * Auxiliary function to Partially update a `ResponseThread` on the state
 * */
const updateResponseThreadInState = (
    state: ThreadsState,
    guid: string,
    responseThread: Partial<ResponseThread>
): ThreadsState => {
    state.threads[guid] = {
        ...state.threads[guid],
        ...responseThread,
    }
    return state
}

export const threadsSlice = createSlice({
    name: "threads",
    initialState,
    reducers: {
        setPreviewMode: (
            state: ThreadsState,
            action: PayloadAction<{
                guid: string
                previewMode: boolean
                initialBlockId?: string
                variablesToAdd?: VariableWithValue[]
                blocksToPreview?: {
                    blocks?: Block[]
                    previewBlocks?: Block[]
                    synopsisBlocks?: Block[]
                }
            }>
        ) => {
            const thread = state.threads[action.payload.guid]

            const { previewMode, blocksToPreview, variablesToAdd, initialBlockId } = action.payload

            if (thread && blocksToPreview?.blocks) {
                if (initialBlockId) {
                    const slicedBlocks = removeBlocksBeforeGuid(
                        blocksToPreview.blocks,
                        initialBlockId
                    )
                    thread.thread!.blocks = JSON.stringify(slicedBlocks)
                } else {
                    thread.thread!.blocks = JSON.stringify(blocksToPreview?.blocks)
                }
                thread.thread!.preview = JSON.stringify(blocksToPreview?.previewBlocks)
                thread.thread!.synopsis = JSON.stringify(blocksToPreview?.synopsisBlocks)
                // reset the user answers
                thread.userAnswers = []
                /*
                 * We have to manually set some variables' values in order to use them in thread preview.
                 * This is because variables values can only be requested on startThread mutation, and that's
                 * not used in preview mode.
                 * For now we are setting all the user data variables, thread title and thread instructor name
                 */
                if (variablesToAdd) {
                    thread.variables = variablesToAdd
                }
            }

            state.previewMode = previewMode

            return state
        },
        setThread: (
            state: ThreadsState,
            action: PayloadAction<{ guid: string; thread: Partial<StartThreadResponse> }>
        ) => {
            state.threads[action.payload.guid] = action.payload.thread
            return state
        },
        clearThreads: (state: ThreadsState) => {
            state.threads = initialState.threads
            return state
        },
        setPreviewDevice: (
            state: ThreadsState,
            action: PayloadAction<{ previewDevice: OptionPreviewMode }>
        ) => {
            state.previewDevice = action.payload.previewDevice
            return state
        },
        updateThread: (
            state: ThreadsState,
            action: PayloadAction<{ guid: string; thread: Partial<Thread> }>
        ) => {
            return updateThreadInState(state, action.payload.guid, action.payload.thread)
        },
        updateThreadStatus: (
            state: ThreadsState,
            action: PayloadAction<{ guid: string; status: ThreadStatus }>
        ) => {
            return updateResponseThreadInState(state, action.payload.guid, {
                status: action.payload.status,
            })
        },
        updateThreadVariables: (
            state: ThreadsState,
            action: PayloadAction<{ guid: string; variables: VariableWithValue[] }>
        ) => {
            return updateResponseThreadInState(state, action.payload.guid, {
                variables: action.payload.variables,
            })
        },
        addThreadVariable: (
            state: ThreadsState,
            action: PayloadAction<{ guid: string; variable: VariableWithValue }>
        ) => {
            const prevVariables = state.threads[action.payload.guid]?.variables || []
            const variableExists = prevVariables.some(
                (variable: VariableWithValue) => variable.name === action.payload.variable.name
            )
            if (variableExists) {
                // If the variable exists, we overwrite it with the new value
                return updateResponseThreadInState(state, action.payload.guid, {
                    variables: prevVariables.map((variable: VariableWithValue) =>
                        variable.name === action.payload.variable.name
                            ? action.payload.variable
                            : variable
                    ),
                })
            } else {
                return updateResponseThreadInState(state, action.payload.guid, {
                    variables: [...prevVariables, action.payload.variable],
                })
            }
        },
        addThreadUserAnswer: (
            state: ThreadsState,
            action: PayloadAction<{ guid: string; userAnswer: UserAnswer }>
        ) => {
            const prevUserAnswers = state.threads[action.payload.guid]?.userAnswers || []
            return updateResponseThreadInState(state, action.payload.guid, {
                userAnswers: [...prevUserAnswers, action.payload.userAnswer],
            })
        },
        addDynamicAttachment: (
            state: ThreadsState,
            action: PayloadAction<{ threadGuid: string; dynamicAttachment: Attachment }>
        ) => {
            const threadGuid = action.payload.threadGuid
            const newDynamicAttachments = state.threads[threadGuid]?.thread?.attachments
                ? [
                      ...state.threads[threadGuid]?.thread?.attachments!,
                      action.payload.dynamicAttachment,
                  ]
                : [action.payload.dynamicAttachment]
            updateThreadInState(state, action.payload.threadGuid, {
                attachments: newDynamicAttachments,
            })

            return state
        },
        updateStyle: (
            state: ThreadsState,
            action: PayloadAction<{ guid: string; theme?: ThreadTheme; font?: ThreadFont }>
        ) => {
            if (action.payload.theme) {
                state = updateThreadInState(state, action.payload.guid, {
                    theme: action.payload.theme,
                })
            }
            if (action.payload.font) {
                state = updateThreadInState(state, action.payload.guid, {
                    font: action.payload.font,
                })
            }
            return state
        },
    },
})

const removeBlocksBeforeGuid = (blocks: Block[], guid: string): Block[] => {
    for (let i = 0; i < blocks.length; i++) {
        if (blocks[i].id === guid) {
            return blocks.slice(i)
        }
        if (blocks[i].objects?.length) {
            for (let j = 0; j < blocks[i].objects!.length; j++) {
                if (blocks[i].objects![j].id === guid) {
                    return [
                        { ...blocks[i], objects: blocks[i].objects!.slice(j) },
                        ...blocks.slice(i + 1),
                    ]
                }
            }
        }
    }

    return blocks
}

// Action creators are generated for each case reducer function
export const {
    setThread,
    updateThread,
    updateThreadStatus,
    updateThreadVariables,
    addThreadVariable,
    addThreadUserAnswer,
    setPreviewMode,
    setPreviewDevice,
    addDynamicAttachment,
    clearThreads,
    updateStyle,
} = threadsSlice.actions

export default threadsSlice.reducer
