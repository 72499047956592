export const UndoIcon = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.33331 5.33341C6.56665 5.33341 4.96665 5.99342 3.73331 7.06675L1.33331 4.66675V10.6667H7.33331L4.91998 8.25342C5.84665 7.48008 7.02665 7.00008 8.33331 7.00008C10.6933 7.00008 12.7 8.54008 13.4 10.6667L14.98 10.1467C14.0533 7.35342 11.4333 5.33341 8.33331 5.33341Z"
                fill="#888888"
            />
        </svg>
    )
}
