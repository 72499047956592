import { FC } from "react"
import { StyledCloseButton, StyledCloseIcon, StyledDeletableWrapper } from "./styles"

interface DeletableWrapperProps {
    disabled?: boolean
    onDelete: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

/*
 * A simple wrapper that adds deletable icon.
 * Note: please use this component instead of `CloseButton` component
 */
const DeletableWrapper: FC<DeletableWrapperProps> = ({ children, disabled = false, onDelete }) => (
    <StyledDeletableWrapper>
        {!disabled && (
            <StyledCloseButton onClick={onDelete}>
                <StyledCloseIcon />
            </StyledCloseButton>
        )}
        {children}
    </StyledDeletableWrapper>
)

export default DeletableWrapper
