import { PopupPosition, PopupProps } from "reactjs-popup/dist/types"
import {
    StyledColumn,
    StyledH2,
    StyledH4,
    StyledP,
    StyledRow,
    StyledSpan,
} from "../../../styles/styledcomponents"
import {
    StyledAICreateModal,
    StyledColorIcon,
    StyledDropdownsSection,
    StyledModalHeader,
    StyledInstructorRow,
    StyledLanguageIcon,
    StyledSlider,
    StyledTemplateIcon,
    StyledModalContent,
    StyledSourceUrlContainer,
    StyledDeleteIcon,
    StyledSourcesContainer,
    StyledInstructorSelect,
    StyledChevron,
    StyledInstructorSelectPopup,
} from "./styles"
import { Button } from "../../../components/Button/Button"
import { useTheme } from "styled-components"
import { useQuery, useSubscription } from "@apollo/client"
import { useEffect, useMemo, useRef, useState } from "react"
import { ABOUT_ME, GET_ALL_INSTRUCTORS } from "../../../apollo/queries"
import {
    CreateThreadInput,
    GenerateThreadInput,
    Language,
    Person,
    ThreadTemplate,
} from "../../../apollo/generated/graphql"
import { UserAvatar } from "../UserAvatar"
import EllipsisText from "../../../components/EllipsisText"
import Select from "../../../components/Select/Select"
import { AvatarSize, SelectOption } from "../../../types"
import {
    LanguageModelOptions,
    LanguageOptions,
    TemplateOptions,
    ThemeOptions,
    durationMarks,
    questionMarks,
} from "./consts"
import { SparkleIcon } from "../../../assets/icons/SparklesIcon"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { pick, sample } from "lodash"
import { useUserPermissions } from "../../../hooks/userPermissions"
import Input, { InputErrorMessage } from "../../../components/InputNew"
import { StyledLinkIcon } from "../../../deliver/components/ProgramDetailsCard/styles"
import { useFormik } from "formik"
import * as Yup from "yup"
import { buildStyles, CircularProgressbar } from "react-circular-progressbar"
import { INDEX_CONTENT_SOURCE } from "../../../apollo/subscriptions"

type SourceUrlObject = {
    url: string
    error: boolean
    loading: boolean
}

interface FormValues {
    topic: string
    threadTemplate: SelectOption
    source: string
    sources: SourceUrlObject[]
    instructor: Partial<Person> | null
    duration: number
    languageModel: SelectOption
    language: SelectOption
    threadTheme: SelectOption
    questionCount: number
}

const sourceUrlSchema = Yup.object().shape({
    url: Yup.string().required(),
    error: Yup.boolean().required().oneOf([false]),
    loading: Yup.boolean().required().oneOf([false]),
})

const FormValidationSchema = Yup.object().shape({
    topic: Yup.string().required("Topic is required"),
    sources: Yup.array().when("threadTemplate", {
        is: (threadTemplate: SelectOption) => threadTemplate.value === ThreadTemplate.TriviaQuizV2,
        then: Yup.array()
            .of(sourceUrlSchema)
            .required("Sources are required")
            .min(1, "Sources are required"),
    }),
    source: Yup.string().when("threadTemplate", {
        is: (threadTemplate: SelectOption) => threadTemplate.value === ThreadTemplate.TriviaQuizV2,
        then: Yup.string()
            .url("The URL must be valid")
            .when("sources", {
                is: (sources: string[]) => sources?.length === 0,
                then: Yup.string().required("URL is required"),
            }),
    }),
})

const AICreateModal = (
    props: Omit<PopupProps, "children"> & {
        onCreate: (input: CreateThreadInput) => void
        onGenerate: (input: GenerateThreadInput) => void
    }
) => {
    const theme = useTheme()

    const { data: aboutMeData } = useQuery(ABOUT_ME)

    const { data: instructorsData } = useQuery(GET_ALL_INSTRUCTORS)

    const {
        permissions: { distinguishedInstructor },
    } = useUserPermissions()

    const createThreadTemplate = useSelector(
        (state: RootState) => state.aiReducer.createThreadTemplate
    )

    const initialValues: FormValues = {
        topic: "",
        threadTemplate: TemplateOptions[0],
        source: "",
        sources: [],
        instructor: null,
        duration: 15,
        languageModel: LanguageModelOptions[0],
        language: LanguageOptions[0],
        threadTheme: ThemeOptions[0],
        questionCount: 20,
    }

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            if (values.threadTemplate.value === ThreadTemplate.TriviaQuizV2) {
                props.onGenerate({
                    topic: values.topic,
                    sources: values.sources.map((source) => source.url),
                    instructorId: values.instructor?.id!,
                    threadTemplate: values.threadTemplate.value,
                    language: values.language.value as Language,
                    size: values.questionCount,
                })
            } else {
                props.onCreate({
                    edit: {
                        instructorId: values.instructor?.id,
                        theme: values.threadTheme.value,
                    },
                    specs: {
                        prompt: values.topic,
                        referenceMaterial: values.source,
                        instructorProfile: values.instructor?.description || "",
                        language: values.language.value as Language,
                        threadTemplate: values.threadTemplate.value as ThreadTemplate,
                        durationMinutes: values.duration,
                        numberOfQuestions: values.questionCount,
                        generateEntireThread: true,
                    },
                })
            }
            props.onClose && props.onClose()
        },
        validationSchema: FormValidationSchema,
    })

    // Set the initial value for `instructor`
    useEffect(() => {
        // If current user is a distinguished instructor, select them
        if (aboutMeData?.aboutMe && distinguishedInstructor) {
            formik.setFieldValue("instructor", aboutMeData.aboutMe as Person)
        }
        // Else, select a random one from the instructors query data
        else if (instructorsData?.allInstructors) {
            formik.setFieldValue("instructor", sample(instructorsData.allInstructors))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aboutMeData, distinguishedInstructor, instructorsData])

    useEffect(() => {
        const template = TemplateOptions.find((temp) => temp.value === createThreadTemplate)
        template && formik.setFieldValue("threadTemplate", template)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createThreadTemplate])

    const handleInstructorDescriptionChange = (newValue: string) =>
        formik.setFieldValue("instructor", {
            ...formik.values.instructor,
            description: newValue,
        })

    const handleInstructorSelect = (instructor: Partial<Person>) => {
        formik.setFieldValue("instructor", instructor)
    }

    const handleSourceAdd = () => {
        if (!formik.errors.source && formik.values.source.length) {
            formik.setFieldValue(
                "sources",
                formik.values.sources.concat({
                    url: formik.values.source,
                    error: false,
                    loading: true,
                })
            )
            formik.setFieldValue("source", "")
            setTimeout(formik.validateForm)
        }
    }

    const handleSourceDelete = (index: number) =>
        formik.setFieldValue(
            "sources",
            formik.values.sources.filter((_, i) => i !== index)
        )

    const renderSlider = () => {
        switch (formik.values.threadTemplate.value) {
            case ThreadTemplate.CoreContent:
            case ThreadTemplate.ThreeRings:
            case ThreadTemplate.Default:
                return (
                    <StyledColumn css={{ gap: "6px", alignItems: "center" }}>
                        <StyledH4>Duration</StyledH4>
                        <StyledSlider
                            min={5}
                            max={25}
                            step={5}
                            marks={durationMarks}
                            value={formik.values.duration}
                            onChange={(_, value) =>
                                formik.setFieldValue(
                                    "duration",
                                    Array.isArray(value) ? value[0] : value
                                )
                            }
                            sx={{ color: theme.colors.base.uiAi500 }}
                        />
                    </StyledColumn>
                )
            // Add upcoming new Trivia template
            case ThreadTemplate.Test:
            case ThreadTemplate.TriviaQuiz:
            case ThreadTemplate.TriviaQuizV2:
                return (
                    <StyledColumn css={{ gap: "6px", alignItems: "center" }}>
                        <StyledH4>Number of Questions</StyledH4>
                        <StyledSlider
                            min={10}
                            max={30}
                            step={5}
                            marks={questionMarks}
                            value={formik.values.questionCount}
                            onChange={(_, value) =>
                                formik.setFieldValue(
                                    "questionCount",
                                    Array.isArray(value) ? value[0] : value
                                )
                            }
                            sx={{ color: theme.colors.base.uiAi500 }}
                        />
                    </StyledColumn>
                )
            case ThreadTemplate.Tutor:
            default:
                return null
        }
    }

    return (
        <StyledAICreateModal
            nested
            modal
            open
            overlayStyle={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
            lockScroll
            {...props}
        >
            <StyledModalHeader>
                <SparkleIcon width={30} height={30} />
                <StyledH2 useNewDesign>Create New Thread</StyledH2>
            </StyledModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <StyledModalContent>
                    <StyledColumn css={{ gap: "18px" }}>
                        <StyledColumn css={{ gap: "6px" }}>
                            <StyledH4>Topic</StyledH4>
                            <Input
                                id={"topic"}
                                name={"topic"}
                                type={"textArea"}
                                minRows={3}
                                maxRows={3}
                                placeholder={
                                    "A challenging quiz written for avid NFL fans on the career highlights of football player Tom Brady. The questions should be as difficult as possible."
                                }
                                value={formik.values.topic}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.topic && !!formik.errors.topic}
                                errorMessage={formik.errors.topic}
                            />
                        </StyledColumn>
                        {formik.values.threadTemplate.value === ThreadTemplate.TriviaQuizV2 ? (
                            <StyledColumn css={{ gap: "6px" }}>
                                <StyledH4>Sources</StyledH4>
                                <Input
                                    id={"source"}
                                    name={"source"}
                                    placeholder={"Paste URLS here"}
                                    value={formik.values.source}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.source && !!formik.errors.source}
                                    errorMessage={formik.errors.source}
                                    icon={<StyledLinkIcon sx={{ fontSize: "16px !important" }} />}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            // Prevent the form getting submitted
                                            event.preventDefault()
                                            handleSourceAdd()
                                        }
                                    }}
                                />
                                {formik.values.sources.length > 0 && (
                                    <StyledSourcesContainer>
                                        {formik.values.sources.map((source, index) => (
                                            <SourceUrl
                                                key={source.url}
                                                url={source.url}
                                                onDelete={() => handleSourceDelete(index)}
                                                setError={(error) =>
                                                    formik.setFieldValue(
                                                        "sources",
                                                        formik.values.sources.map((src, i) =>
                                                            i === index ? { ...src, error } : src
                                                        )
                                                    )
                                                }
                                                setLoading={(loading) =>
                                                    formik.setFieldValue(
                                                        "sources",
                                                        formik.values.sources.map((src, i) =>
                                                            i === index ? { ...src, loading } : src
                                                        )
                                                    )
                                                }
                                            />
                                        ))}
                                    </StyledSourcesContainer>
                                )}
                            </StyledColumn>
                        ) : (
                            <StyledColumn css={{ gap: "6px" }}>
                                <StyledH4>Source</StyledH4>
                                <Input
                                    id={"source"}
                                    name={"source"}
                                    placeholder={"Insert any sources to help guide your thread"}
                                    value={formik.values.source}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.source && !!formik.errors.source}
                                    errorMessage={formik.errors.source}
                                />
                            </StyledColumn>
                        )}
                        <StyledColumn css={{ gap: "6px" }}>
                            <StyledH4>Instructor</StyledH4>
                            <InstructorSelect
                                instructor={formik.values.instructor}
                                onChange={handleInstructorSelect}
                            />
                        </StyledColumn>
                        {renderSlider()}
                        <StyledDropdownsSection>
                            <StyledColumn css={{ alignItems: "center", flex: 1 }}>
                                <StyledLanguageIcon />
                                <StyledP>Language</StyledP>
                                <ConfigDropdown
                                    value={formik.values.language}
                                    options={LanguageOptions}
                                    onChange={(newValue) =>
                                        formik.setFieldValue("language", newValue)
                                    }
                                />
                            </StyledColumn>
                            <StyledColumn css={{ alignItems: "center", flex: 1 }}>
                                <StyledColorIcon />
                                <StyledP>Theme</StyledP>
                                <ConfigDropdown
                                    value={formik.values.threadTheme}
                                    options={ThemeOptions}
                                    onChange={(newValue) =>
                                        formik.setFieldValue("threadTheme", newValue)
                                    }
                                />
                            </StyledColumn>
                            {!createThreadTemplate && (
                                <StyledColumn css={{ alignItems: "center", flex: 1 }}>
                                    <StyledTemplateIcon />
                                    <StyledP>Template</StyledP>
                                    <ConfigDropdown
                                        value={formik.values.threadTemplate}
                                        options={TemplateOptions}
                                        onChange={(newValue) =>
                                            formik.setFieldValue(
                                                "threadthreadTemplateTheme",
                                                newValue
                                            )
                                        }
                                    />
                                </StyledColumn>
                            )}
                            <StyledColumn css={{ alignItems: "center", flex: 1 }}>
                                <SparkleIcon width={24} height={24} />
                                <StyledP>Model</StyledP>
                                <ConfigDropdown
                                    value={formik.values.languageModel}
                                    options={LanguageModelOptions}
                                    onChange={(newValue) =>
                                        formik.setFieldValue("languageModel", newValue)
                                    }
                                />
                            </StyledColumn>
                        </StyledDropdownsSection>
                    </StyledColumn>
                    <StyledRow css={{ gap: "6px", justifyContent: "center" }}>
                        <Button
                            width={110}
                            variant={"outline"}
                            label={"Cancel"}
                            color={theme.colors.base.uiAi500}
                            buttonStyle={{
                                height: "42px",
                                fontSize: "18px",
                                fontWeight: "500",
                                padding: "12px 24px",
                            }}
                            onClick={props.onClose}
                        />
                        <Button
                            width={110}
                            label={"Create"}
                            color={theme.colors.base.uiAi500}
                            buttonStyle={{
                                height: "42px",
                                fontSize: "18px",
                                fontWeight: "500",
                                padding: "12px 24px",
                            }}
                            type={"submit"}
                            disabled={!formik.isValid}
                        />
                    </StyledRow>
                </StyledModalContent>
            </form>
        </StyledAICreateModal>
    )
}

interface SourceUrlProps {
    url: string
    onDelete: () => void
    setError: (error: boolean) => void
    setLoading: (loading: boolean) => void
}

const SourceUrl = ({ url, onDelete, setError, setLoading }: SourceUrlProps) => {
    const [hovered, setHovered] = useState(false)
    const [glow, setGlow] = useState(false)

    const theme = useTheme()

    const { data: indexSourceData, error: indexSourceError } = useSubscription(
        INDEX_CONTENT_SOURCE,
        {
            variables: {
                url: { url },
            },
        }
    )

    const indexSourceLoading = useMemo(
        () => indexSourceData?.indexContentSource.state !== "complete",
        [indexSourceData?.indexContentSource.state]
    )
    const indexSourceHasData = !!indexSourceData
    const indexSourceHasError = useMemo(
        () => !!indexSourceError || !!indexSourceData?.indexContentSource.error,
        [indexSourceData?.indexContentSource.error, indexSourceError]
    )

    useEffect(() => {
        if (indexSourceLoading) setLoading(true)
        else setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexSourceLoading])

    useEffect(() => {
        if (indexSourceHasError) setError(true)
        else setError(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexSourceHasError])

    // Make the element glow for a second after it's done loading
    useEffect(() => {
        if (!indexSourceLoading && indexSourceHasData) {
            setGlow(true)
            setTimeout(() => setGlow(false), 1000)
        }
    }, [indexSourceLoading, indexSourceHasData])

    return (
        <StyledColumn>
            <StyledSourceUrlContainer
                invalid={indexSourceHasError}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                glow={glow}
            >
                <StyledLinkIcon />
                <EllipsisText
                    variant="h5"
                    maxWidth={380}
                    text={url}
                    onClick={() => !indexSourceHasError && window.open(url, "_blank")}
                    containerStyles={{ flex: 1 }}
                />
                {hovered ? (
                    <StyledDeleteIcon onClick={onDelete} />
                ) : (
                    indexSourceLoading && (
                        <StyledColumn css={{ width: "14px", height: "14px" }}>
                            <CircularProgressbar
                                value={indexSourceData?.indexContentSource.progress || 0}
                                strokeWidth={16}
                                styles={buildStyles({
                                    pathColor: theme.colors.base.uiPrimary500,
                                    trailColor: theme.colors.base.uiBgSecondary,
                                })}
                            />
                        </StyledColumn>
                    )
                )}
            </StyledSourceUrlContainer>
            {indexSourceHasError && <InputErrorMessage message={"The URL must be valid"} />}
        </StyledColumn>
    )
}

type InstructorSelectProps = {
    onChange: (instructor: Partial<Person>) => void
    instructor: Partial<Person> | null
}

const InstructorSelect = ({ onChange, instructor }: InstructorSelectProps) => {
    const instructorPopupRef = useRef(null)

    const { data: instructorsData } = useQuery(GET_ALL_INSTRUCTORS)

    const onInstructorSelect = (instructor: Partial<Person>) => {
        onChange(instructor)
        /*
         * `reactjs-popup` doesn't have a type for it's ref, so that's
         * why we have to use @ts-ignore here.
         */
        // @ts-ignore
        instructorPopupRef.current?.close()
    }

    return (
        <StyledInstructorSelectPopup
            nested
            lockScroll
            ref={instructorPopupRef}
            on={"click"}
            arrow={false}
            position={"bottom left"}
            offsetY={10}
            offsetX={-6}
            trigger={
                <StyledInstructorSelect>
                    <UserAvatar
                        user={{ ...pick(instructor, ["profileImageURL", "firstName", "lastName"]) }}
                        size={AvatarSize.XXL}
                    />
                    <StyledH4>{`${instructor?.firstName || ""} ${
                        instructor?.lastName || ""
                    }`}</StyledH4>
                    <StyledChevron />
                </StyledInstructorSelect>
            }
        >
            {instructorsData?.allInstructors.map((instructor) => (
                <InstructorRow
                    key={instructor.id}
                    instructor={instructor}
                    onSelect={() => onInstructorSelect(instructor)}
                    ellipsisPopupPosition={"left center"}
                />
            ))}
        </StyledInstructorSelectPopup>
    )
}

interface InstructorRowProps {
    instructor: Partial<Person>
    onSelect?: () => void
    ellipsisPopupPosition?: PopupPosition
}

export const InstructorRow = ({
    instructor,
    onSelect,
    ellipsisPopupPosition,
}: InstructorRowProps) => {
    const theme = useTheme()

    return (
        <StyledInstructorRow onClick={onSelect}>
            <UserAvatar
                user={{ ...pick(instructor, ["profileImageURL", "firstName", "lastName"]) }}
                size={AvatarSize.XXL}
            />
            <StyledColumn>
                <StyledSpan>
                    {instructor.firstName} {instructor.lastName}
                </StyledSpan>
                <EllipsisText
                    variant={"span"}
                    text={instructor.description || ""}
                    maxLines={2}
                    maxHeight={36}
                    popupOptions={{ position: ellipsisPopupPosition }}
                    textStyles={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        color: theme.colors.base.uiLabelDisabled,
                    }}
                />
            </StyledColumn>
        </StyledInstructorRow>
    )
}

interface ConfigDropdownProps {
    value?: SelectOption
    options: SelectOption[]
    onChange: (newValue: SelectOption) => void
}

const ConfigDropdown = ({ value, options, onChange }: ConfigDropdownProps) => {
    const theme = useTheme()

    return (
        <Select
            value={value}
            options={options}
            onChange={(newValue) => newValue && onChange(newValue)}
            isMulti={false}
            styles={{
                container: () => ({
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }),
                control: () => ({
                    minHeight: "22px",
                    padding: 0,
                    margin: 0,
                    border: "none",
                    boxShadow: "none",
                    borderRadius: 0,
                    transition: "unset",
                    cursor: "pointer",
                    width: "fit-content",
                }),
                singleValue: () => ({
                    color: theme.colors.base.uiLabelSubtitle,
                    fontSize: "12px",
                }),
                menu: () => ({
                    width: "inherit",
                    left: "unset",
                    borderRadius: "8px",
                }),
                menuList: () => ({
                    maxHeight: "120px",
                }),
                option: () => ({
                    cursor: "pointer",
                    fontSize: "12px",
                }),
                dropdownIndicator: () => ({
                    color: theme.colors.base.uiLabelSubtitle,
                    "&:hover": {
                        color: theme.colors.base.uiLabelSubtitle,
                    },
                }),
            }}
        />
    )
}

export default AICreateModal
