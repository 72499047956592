import styled from "styled-components"
import { StyledBox } from "../../../styles/styledcomponents"

export const StyledTypingDot = styled(StyledBox)`
    animation: mercuryTypingAnimation 1s infinite ease-in-out;
    border-radius: 50%;
    display: inline-block;
    height: 4px;
    margin-right: 2px;
    width: 4px;
    background-color: ${({ theme }) => theme.headlandsGray2};

    &:nth-child(1) {
        animation-delay: 200ms;
    }
    &:nth-child(2) {
        animation-delay: 300ms;
    }
    &:nth-child(3) {
        animation-delay: 400ms;
    }

    @keyframes mercuryTypingAnimation {
        0% {
            transform: translateY(0px);
        }
        28% {
            transform: translateY(-5px);
        }
        44% {
            transform: translateY(0px);
        }
    }
`
