import styled, { css } from "styled-components"

export const StyledCheckbox = styled.div<{ selected: boolean; color?: string }>`
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.headlandsGray3};
    border-radius: 2px;
    box-sizing: border-box;
    margin-right: 6px;
    background-color: ${(props) => props.theme.headlandsWhite};
    transition: border 0.2s, background-color 0.2s;

    ${({ selected, color, theme }) =>
        selected &&
        css`
            border: 1px solid ${color || theme.headlandsPrimaryColor};
            background-color: ${color || theme.headlandsPrimaryColor};
        `}
`
