import { StyledSpan } from "../../../../styles/styledcomponents"
import Select, { isMultiValue } from "../../../../components/Select/Select"
import { useEffect, useMemo, useState } from "react"
import { useFormik } from "formik"
import { SelectOption } from "../../../../types"
import { Button } from "../../../../components/Button/Button"
import { StyledChannelInputRow, StyledForm, StyledFormFooter } from "./styles"
import { useMutation, useQuery } from "@apollo/client"
import { SET_NOTIFICATION_CONFIG } from "../../../../apollo/mutations"
import * as Yup from "yup"
import { useSelectedProgramGuid } from "../../../../hooks/selectedProgram.hook"
import { GET_PROGRAM_NOTIFICATION_CONFIG, GET_SLACK_CONNECTIONS } from "../../../../apollo/queries"
import { Loader } from "../../../../components/Loader"
import { compact } from "lodash"
import { IntegrationItem } from ".."

enum SelectFields {
    account = "Account",
    workspace = "Workspace",
    channel = "Channel",
}

type SelectFormValues = {
    [key in SelectFields]?: SelectOption
}

type FormValues = SelectFormValues

type SelectFieldItem = {
    id: SelectFields
    label: string
    placeholder: string
    options: SelectOption[]
    disabled?: boolean
}

type SlackFormProps = {
    closeModal: () => void
    integration: IntegrationItem
}

type Channel = {
    id: string
    name: string
}

const SelectRequiredOption = Yup.object({ value: Yup.string().required() }).required()

const SlackFormSchema = Yup.object().shape({
    [SelectFields.account]: SelectRequiredOption,
    [SelectFields.workspace]: SelectRequiredOption,
    [SelectFields.channel]: SelectRequiredOption,
})

const SlackForm = ({ closeModal, integration }: SlackFormProps) => {
    const programGuid = useSelectedProgramGuid()
    const [workspaceOptions, setWorkspaceOptions] = useState<SelectOption[]>([])

    const { data: slackConnectionData, loading: slackConnectionLoading } = useQuery(
        GET_PROGRAM_NOTIFICATION_CONFIG,
        {
            fetchPolicy: "no-cache",
            skip: !programGuid,
            variables: { guid: programGuid! },
        }
    )

    const { data: availableSlackConnectionsData, loading: availableSlackConnectionsLoading } =
        useQuery(GET_SLACK_CONNECTIONS, {
            fetchPolicy: "no-cache",
        })

    const [setNotificationConfig, { loading: setNotificationConfigLoading }] = useMutation(
        SET_NOTIFICATION_CONFIG,
        {
            onCompleted: () => closeModal(),
        }
    )

    /*
     * When the availableSlackConnectionsData arrives, set the available workspace options
     * */
    useEffect(() => {
        if (availableSlackConnectionsData?.slackConnections) {
            setWorkspaceOptions(
                compact(availableSlackConnectionsData.slackConnections).map((workspace) => ({
                    value: workspace.id,
                    label: workspace.name,
                }))
            )
        }
    }, [availableSlackConnectionsData])

    const formik = useFormik<FormValues>({
        initialValues: {
            [SelectFields.account]: { value: "@Headlands", label: "@Headlands" },
            [SelectFields.workspace]: undefined,
            [SelectFields.channel]: undefined,
        },
        validationSchema: SlackFormSchema,
        onSubmit: (values) => {
            if (!programGuid) return
            const variables = {
                guid: programGuid,
                connection: values.Workspace!.value,
                channel: values.Channel!.value,
            }
            void setNotificationConfig({ variables })
        },
    })

    /*
     * Returns the available channels option depending on the selected workspace, also reset the channel field
     * if it doesn't belong to the selected workspace
     * */
    const channelOptions = useMemo(() => {
        const workspaceSelected = formik.values.Workspace
        if (workspaceSelected) {
            const workspaceFound = availableSlackConnectionsData?.slackConnections?.find(
                (workspace) => workspace?.id === workspaceSelected.value
            )
            if (workspaceFound) {
                const channelSelectedBelongsToWorkspace = !!workspaceFound.channels?.find(
                    (channel) => channel.id === formik.values.Channel?.value
                )
                // if the selected channel doesn't belong to selected workspace, reset the channel selection
                if (!channelSelectedBelongsToWorkspace)
                    formik.setFieldValue(SelectFields.channel, null)

                return workspaceFound.channels?.map((channel: Channel) => ({
                    value: channel.id,
                    label: channel.name,
                }))
            }
        }
        return []
    }, [formik.values.Workspace, availableSlackConnectionsData])

    /*
     * This effect populates the form with the already selected values
     * */
    useEffect(() => {
        const workspaceSelected = slackConnectionData?.programNotificationConfig
        if (workspaceSelected) {
            formik.setFieldValue(SelectFields.workspace, {
                value: workspaceSelected.id,
                label: workspaceSelected.name,
            })
            // note: why the channel is an array? is to support multiples on the future?
            const channelSelected = workspaceSelected.channels
                ? workspaceSelected.channels[0]
                : undefined
            if (channelSelected) {
                formik.setFieldValue(SelectFields.channel, {
                    value: channelSelected.id,
                    label: channelSelected.name,
                })
            }
        }
    }, [slackConnectionData])

    const selectFields: SelectFieldItem[] = [
        {
            id: SelectFields.account,
            label: "Account",
            placeholder: "Select Account",
            options: [{ value: "@Headlands", label: "@Headlands" }],
            disabled: true,
        },
        {
            id: SelectFields.workspace,
            label: "Workspace",
            placeholder: "Select Workspace",
            options: workspaceOptions,
        },
        {
            id: SelectFields.channel,
            label: "Channel",
            placeholder: "Select Channel",
            options: channelOptions || [],
        },
    ]

    const renderFooter = () => {
        return (
            <StyledFormFooter>
                <Button
                    label={"Cancel"}
                    variant={"outline"}
                    onClick={closeModal}
                    buttonStyle={{ marginRight: "24px" }}
                />
                <Button
                    label={"Done"}
                    onClick={formik.submitForm}
                    disabled={!formik.isValid || setNotificationConfigLoading}
                    loading={setNotificationConfigLoading}
                />
            </StyledFormFooter>
        )
    }

    if (slackConnectionLoading || availableSlackConnectionsLoading) return <Loader size={60} />

    return (
        <StyledForm>
            {selectFields.map((selectField) => (
                <StyledChannelInputRow key={selectField.id}>
                    <StyledSpan>{selectField.label}</StyledSpan>
                    <Select
                        key={selectField.id}
                        id={selectField.id}
                        name={selectField.id}
                        value={formik.values[selectField.id]}
                        options={selectField.options}
                        isDisabled={selectField.disabled}
                        onChange={(option) =>
                            option &&
                            !isMultiValue(option) &&
                            formik.setFieldValue(selectField.id, option)
                        }
                        styles={{
                            container: () => ({
                                width: "100%",
                                flex: "unset",
                            }),
                        }}
                    />
                </StyledChannelInputRow>
            ))}
            {renderFooter()}
        </StyledForm>
    )
}

export default SlackForm
