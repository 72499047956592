import { useEffect, useMemo, useRef, useState } from "react"
import ReactQuill from "react-quill"
import {
    Block,
    BlocksType,
    BlockType,
    ContextMenuType,
    MenuType,
    MENU_KEYS,
    RichtTextFormatOptions,
    BlockContext,
} from "../../../types"
import ComponentSelector from "../ComponentSelector/ComponentSelector"
import ContextMenu from "../../../components/ContextMenu/ContextMenu"
import { editorModules } from "../../../utils/consts"
import EmojiPicker from "../../../components/EmojiPicker/EmojiPicker"
import useStateRef from "../../../hooks/stateRef.hook"
import { Quill } from "react-quill"
import { Delta as DeltaType, Sources } from "quill"
import VariableBlot from "./customBlots/VariableBlot"
import {
    getLastSpecialCharacterAndIndex,
    hexToRGB,
    isBlockEmpty,
    isBlockTypeAllowedInContext,
    isBlockValueEmpty,
    removeHTML,
    removeInvisibleCharacters,
    removeStylesFromHtml,
    removeWhiteSpaces,
} from "../../../utils/utils"
import parse from "html-react-parser"
import { useDispatch } from "react-redux"
import { selectBlock } from "../../../redux/blocks"
import { useIsBlockSelected } from "../../../hooks/isBlockSelected.hook"
import "react-quill/dist/quill.snow.css"
import CustomLinkTooltip from "./CustomTooltips/Link"
import { parsePastedBlocks, sanitizeInvalidBlockInContext } from "../../../thread/utils"
import { compact, debounce, flattenDeep, isEmpty, isNil } from "lodash"
import { HeadlandsFontColors, HeadlandsHighlightColors } from "../../../utils/colors"
import { StyledTextBlock } from "./styles"
import { AddBlockOptions, AiEditData } from "../EditorScreen/Editor"
import { ComponentSelectorItem } from "../ComponentSelector/constants"
import AiEditMenu from "../AiEditMenu"
import { EditType } from "../../../apollo/generated/graphql"
import katex from "katex"
import "katex/dist/katex.min.css"
import CustomFormulaTooltip from "./CustomTooltips/Formula"

interface WindowWithKatex extends Window {
    katex: katex
}
declare let window: WindowWithKatex
window.katex = katex

const Clipboard = Quill.import("modules/clipboard")

const Delta = Quill.import("delta")

export interface BlockProps {
    onChange: (text: string, blockId: string) => void
    onDelete: (blockId: string, blockIndex: number) => void
    handleAddBlock: (options: AddBlockOptions) => void
    blockIndex: number
    block: Block
    blocksType: BlocksType
    parentType?: BlockType
    // if `context` is given, it will ignore the `blocksType` and `parentType` for component selection
    context?: BlockContext
    setAiEditData?: (aiEditData?: AiEditData) => void
    onScreen?: boolean
}

class PlainClipboard extends Clipboard {
    onPaste() {
        // we don't do a prevent default here because seems to be affecting all the quill instances
    }
}

const BlockComponent = ({
    onChange,
    onDelete,
    handleAddBlock,
    blockIndex,
    block,
    blocksType,
    parentType,
    context = compact([blocksType, parentType]),
    setAiEditData,
    onScreen = true,
}: BlockProps) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const editorRef = useRef(null)
    const [shouldDelete, setShouldDelete] = useState(true)
    const [selection, setSelection, selectionRef] = useStateRef({ index: 0, length: 0 })
    const [activeMenu, setActiveMenu] = useState<MenuType | undefined>()
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 })
    const [contextMenuPosition, setContextMenuPosition] = useState({ top: 0, left: 0 })
    const [linkInputOpen, setLinkInputOpen] = useState(false)
    const [formulaTooltipOpen, setFormulaTooltipOpen] = useState(false)
    const [aiEditOpen, setAiEditOpen] = useState(false)
    const [contentSearch, setContentSearch, contentSearchRef] = useStateRef("")
    const [customPopupPosition, setCustomPopupPosition] = useState<
        { top: number; bottom: number } | undefined
    >()
    const focused = useIsBlockSelected(block.id)

    const dispatch = useDispatch()

    const closeActiveMenu = () => setActiveMenu(undefined)

    useEffect(() => {
        if (Quill) {
            Quill.register(VariableBlot, true)
            // we override the clipboard from quill because it was not working correctly with our on paste function
            Quill.register("modules/clipboard", PlainClipboard, true)
        }
    }, [])

    useEffect(() => {
        // @ts-ignore
        const editor = editorRef?.current?.editor
        if (editor) {
            if (onScreen) {
                /*
                 * We check if the editor is empty because we don't want to trigger this effect if the user is writing on
                 * the editor. We only want this effect to trigger if is trying to load a previous created block
                 * */
                if (
                    removeInvisibleCharacters(block.value || "") !==
                    removeInvisibleCharacters(quillGetHTML(editor.getContents()))
                ) {
                    const content = editor?.clipboard.convert(block.value)
                    // the source 'api' is important, we do this to avoid triggering the onChange function
                    editor.setContents(content, "api")
                    !isBlockValueEmpty(block.value) && setShouldDelete(false)
                }
            }
        }
    }, [block.value, editorRef.current, onScreen])

    /*
     * Future Feature: We could handle pasting images and creating
     * new image blocks with them. They can be accessed by doing
     * e.clipboardData.files[0]
     */
    const handlePaste = (e: ClipboardEvent) => {
        const textPlain = e.clipboardData?.getData("text/plain") || ""
        const parsedBlocks: Block[] | undefined = parsePastedBlocks(textPlain)
        // @ts-ignore
        const editorText = editorRef.current.editor.getText(0).replace(/\n$/, "")
        const emptyBlock = editorText.length === 0
        const validBlocks = flattenDeep(
            compact(
                parsedBlocks?.map((parsedBlock) => {
                    if (isBlockTypeAllowedInContext(parsedBlock.type, context)) return parsedBlock
                    else return sanitizeInvalidBlockInContext(parsedBlock, context)
                })
            )
        )
        if (parsedBlocks) {
            e.preventDefault()
            if (validBlocks?.length) {
                let options: AddBlockOptions = {
                    index: emptyBlock ? blockIndex : blockIndex + 1,
                    blocks: validBlocks,
                    id: block.id,
                }
                if (emptyBlock) options = { ...options, replace: true }
                handleAddBlock(options)
            }
        }
        // just pasting simple text, we filter some styles that are not supported
        else {
            const textHtml = e.clipboardData?.getData("text/html")
            if (textHtml) {
                // @ts-ignore
                const editor = editorRef?.current?.editor
                const currentCursorPosition = selectionRef.current.index
                const updatedTextHtml = removeStylesFromHtml(textHtml, [
                    { key: "background-color", allowedValues: HeadlandsHighlightColors },
                    {
                        key: "color",
                        allowedValues: HeadlandsFontColors.map((hexColor) => hexToRGB(hexColor)),
                    },
                ])
                const beforeContentDelta = editor?.getContents(0, currentCursorPosition)
                const afterContentDelta = editor?.getContents(
                    currentCursorPosition + selectionRef.current.length,
                    editor.getLength()
                )
                const deltaFromHtml = editor?.clipboard.convert(updatedTextHtml)
                // only if there is something on the delta we try to paste it
                if (!isEmpty(deltaFromHtml.ops)) {
                    // @ts-ignore
                    editor.setContents(
                        [...beforeContentDelta.ops, ...deltaFromHtml.ops, ...afterContentDelta.ops],
                        "user"
                    )
                    // This has to be done to place cursor at the correct place
                    setTimeout(
                        () => editor.setSelection(currentCursorPosition + (textPlain || "").length),
                        100
                    )
                    e.preventDefault()
                }
            }
        }
    }

    useEffect(() => {
        return () => {
            if (containerRef.current) containerRef.current.onpaste = null
        }
    }, [])

    useEffect(() => {
        if (containerRef.current) {
            if (focused && !linkInputOpen && !formulaTooltipOpen) {
                // Add listener for the paste event only when the editor is focused, the
                // link input and the formula tooltip is not open (if is open we should paste on the link input or formula tooltip instead)
                containerRef.current.onpaste = handlePaste
            } else {
                // Removed the listener for the paste event when the editor is unfocused
                containerRef.current.onpaste = null
            }
        }

        // `handlePaste` dependency is not necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused, linkInputOpen, formulaTooltipOpen])

    const debouncedOnChange = useMemo(() => debounce(onChange, 300), [])

    useEffect(() => {
        // @ts-ignore
        const editor = editorRef?.current?.editor
        if (focused && editor) {
            editor.focus()
            editor.root.dataset.placeholder = " Type ‘/’ for commands"
        } else if (editor && !focused) {
            editor.root.dataset.placeholder = ""
        }
    }, [focused])

    const handleChange = (newText: string, delta: any, source: Sources) => {
        /*
         * Avoid triggering if the source is the 'api'.
         * This was done to prevent calling the onChange function when the content is set by the first time.
         * */
        if (source === "api") return

        // @ts-ignore
        const editor = editorRef?.current?.editor
        const textBeforeCursor = editor.getText(0, selection.index)
        const specialCharacter = getLastSpecialCharacterAndIndex(textBeforeCursor)
        // We add 1 to specialCharacter.index to account for the special character itself
        const searchText = specialCharacter && textBeforeCursor.slice(specialCharacter.index + 1)
        !isNil(searchText) && setContentSearch(searchText)
        if (specialCharacter) {
            const bounds = editor.getBounds(selection.index, selection.length)
            let diffToEdge
            switch (specialCharacter.key) {
                case MENU_KEYS.ComponentMenu:
                    // {editor width} - {popup width} - {cursor poition} - {cursor offset}
                    diffToEdge = 600 - 320 - bounds.left - 16
                    setMenuPosition({
                        top: bounds.top + 26,
                        left: diffToEdge > 0 ? bounds.left : bounds.left + diffToEdge,
                    })
                    setActiveMenu(MenuType.COMPONENTS)
                    break
                case MENU_KEYS.VariableMenu:
                    // {editor width} - {popup width} - {cursor poition} - {cursor offset}
                    diffToEdge = 600 - 196 - (bounds.left + 12) - 16
                    setContextMenuPosition({
                        top: bounds.bottom - 18,
                        left: diffToEdge > 0 ? bounds.left + 12 : bounds.left + 12 + diffToEdge,
                    })
                    setActiveMenu(MenuType.VARIABLES)
                    break
                case MENU_KEYS.EmojiMenu:
                    if (searchText.length > 1) {
                        // {editor width} - {popup width} - {cursor poition} - {cursor offset}
                        diffToEdge = 600 - 196 - (bounds.left + 12) - 50
                        setContextMenuPosition({
                            top: bounds.bottom - 18,
                            left: diffToEdge > 0 ? bounds.left + 12 : bounds.left + 12 + diffToEdge,
                        })
                        setActiveMenu(MenuType.EMOJIS)
                    }
                    break
            }
        } else {
            closeActiveMenu()
        }
        !isBlockValueEmpty(newText) && setShouldDelete(false)
        debouncedOnChange(newText, block.id)
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        // @ts-ignore
        const editor = editorRef?.current?.editor
        const isEmpty = isBlockEmpty(block)

        if (event.code === "Enter" && !event.shiftKey && !activeMenu) {
            // here we check if the user had pressed enter on the end of the block or not. If there is content after
            // the cursor, we add a new block with that content otherwise we just add a new empty block
            const contentAfterCursor = editor.getContents(selection.index)
            const contentAfterCursorLength = contentAfterCursor?.ops?.length || 0
            const hasContentAfterTheCursor =
                contentAfterCursorLength > 1 ||
                (contentAfterCursor?.ops &&
                    contentAfterCursor?.ops[contentAfterCursorLength - 1]?.insert !== "\n")

            if (hasContentAfterTheCursor) {
                const contentBeforeCursor = editor.getContents(0, selection.index)
                handleAddBlock({
                    index: blockIndex + 1,
                    initialContent: quillGetHTML(contentAfterCursor),
                    id: block.id,
                })
                editor.setContents(contentBeforeCursor, "user")
            } else if (!isEmpty) {
                handleAddBlock({ index: blockIndex + 1, id: block.id })
            }
        } else if (event.code === "Backspace") {
            if (isEmpty && !shouldDelete) {
                setShouldDelete(true)
            } else if (isEmpty && shouldDelete) {
                onDelete(block.id, blockIndex)
            }
        }
    }

    const handleOptionSelect = (type: ComponentSelectorItem) => {
        // @ts-ignore
        const editorText = editorRef.current.editor.getText(0).replace(/\n$/, "")
        const searchLength = contentSearchRef.current.length
        const emptyBlock = editorText.length - (searchLength + 1) === 0
        const index = selectionRef.current.index - searchLength
        !emptyBlock &&
            editorRef.current &&
            // @ts-ignore
            editorRef.current?.getEditor().deleteText(index - 1, searchLength + 1, "user")
        if (emptyBlock) {
            handleAddBlock({ type, index: blockIndex, replace: true, id: block.id })
        } else {
            handleAddBlock({ type, index: blockIndex + 1, id: block.id })
        }
        closeActiveMenu()
    }

    const handleEmojiSelect = (emoji: string) => {
        const searchLength = contentSearchRef.current.length
        const index = selectionRef.current.index
        const searchIndex = index - searchLength
        // @ts-ignore
        editorRef.current?.editor.insertText(index, `${emoji} `, "user")
        editorRef.current &&
            setTimeout(() =>
                // @ts-ignore
                editorRef.current
                    .getEditor()
                    .deleteText(searchIndex - 1, searchLength + 1, "user", 0)
            )
        // @ts-ignore
        editorRef.current.focus()
        // @ts-ignore
        setTimeout(() => editorRef.current.editor.setSelection(searchIndex + 2, 0, "user"), 0)
        closeActiveMenu()
    }

    const handleSelectionChange = (newSelection: { index: number; length: number }) => {
        newSelection && setSelection(newSelection)
    }

    // If already open, we update the position of the CustomPopupPosition each time the selection changes
    useEffect(() => {
        if (selection?.length > 0 && customPopupPosition) changeCustomPopupPosition()
        else setCustomPopupPosition(undefined)
    }, [selection])

    const handleVariableSelect = (variableText: string, error?: boolean) => {
        const variable = `@${variableText}`
        const searchLength = contentSearchRef.current.length
        const index = selectionRef.current.index - searchLength

        // deletes the text the user enter on the editor
        editorRef.current &&
            setTimeout(
                () =>
                    // @ts-ignore
                    editorRef.current?.getEditor().deleteText(index - 1, searchLength + 1, "user"),
                0
            )

        const newIndex = selectionRef.current.index

        // @ts-ignore
        editorRef.current?.editor.insertEmbed(
            newIndex,
            "VariableBlot",
            JSON.stringify({ name: variable, error: !!error }),
            "user"
        )
        // @ts-ignore
        editorRef.current.focus()

        /*
         * We place the cursor at the end of the inserted variable.
         * We add 2 to the current index, 1 for the length of the VariableBlot,
         * and another for it to be placed after it.
         */
        // @ts-ignore
        editorRef.current.editor.setSelection(newIndex + 2, 0, "user")
        closeActiveMenu()
    }

    /*
     * This function handles the link submission.
     * It formats the string into a correct link and set it as a link on the quill editor
     * */
    const handleLinkSubmit = (link: string) => {
        // we send the event as the "user" to trigger the onChange function
        // @ts-ignore
        const editor = editorRef?.current?.editor
        editor.format("link", removeWhiteSpaces(removeHTML(link)), "user")
        setLinkInputOpen(false)
    }

    const handleFormulaUpdate = (formula: string, shouldDeleteSelection: boolean) => {
        // @ts-ignore
        const editor = editorRef?.current?.editor

        if (shouldDeleteSelection) {
            // delete the selected text so we can do the live update
            editor.deleteText(selectionRef.current.index, selectionRef.current.length)
        } else {
            // all embed have length 1
            editor.deleteText(selectionRef.current.index, 1)
        }
        // we send the event as the "user" to trigger the onChange function
        editor.insertEmbed(
            selectionRef.current.index,
            "formula",
            formula?.replaceAll(/(<p>|<\/p>)/g, ""),
            "user"
        )
    }

    const handleFormulaSubmit = (formula: string) => {
        handleFormulaUpdate(formula, false)
        // @ts-ignore
        const editor = editorRef?.current?.editor
        editor.setSelection({ index: selection.index + 1, length: 0 })
        // close the formula tooltip
        setFormulaTooltipOpen(false)
    }

    const handleFormulaCancel = (originalSelection: string) => {
        // @ts-ignore
        const editor = editorRef?.current?.editor
        // we delete the embed formula added and replace it with the original text selection
        editor.deleteText(selectionRef.current.index, 1)
        editor.insertText(selectionRef.current.index, originalSelection, "user")
        // close the formula tooltip
        setFormulaTooltipOpen(false)
    }

    const changeCustomPopupPosition = () => {
        // @ts-ignore
        const editor = editorRef?.current?.editor
        const bounds = editor.getBounds(selectionRef?.current?.index, selectionRef?.current?.length)
        setCustomPopupPosition({
            top: bounds.top,
            bottom: bounds.bottom,
        })
    }

    const handleAiEdit = (type: EditType) => {
        // @ts-ignore
        const editor = editorRef?.current?.editor
        const editorText = editor.getText(selection).replace(/\n$/, "")
        const aiEditData: AiEditData = {
            objectGUID: block.id,
            type,
            text: editorText,
            onSubmit: (newValue: string) => {
                const deltaFromValue = editor?.clipboard.convert(newValue)
                editor.updateContents(
                    new Delta()
                        .retain(selection.index)
                        .delete(selection.length)
                        .concat(deltaFromValue),
                    "user"
                )
                editor.setSelection({ index: selection.index + newValue.length, length: 0 })
            },
        }
        editor.setSelection(selection)
        setAiEditData && setAiEditData(aiEditData)
    }

    const modules = useMemo(() => {
        const { keyboard, toolbar, ...otherModules } = editorModules
        const { handleEnter } = keyboard.bindings
        return {
            ...otherModules,
            keyboard: { bindings: { handleEnter } },
            toolbar: {
                container: [...toolbar],
                // add a custom handler for the link, so we can use our custom component instead
                handlers: {
                    link: (value: string | undefined | null) => {
                        if (value) setLinkInputOpen(true)
                        else {
                            // @ts-ignore
                            const editor = editorRef?.current?.editor
                            editor.format("link", false, "user")
                        }
                    },
                    ai: () => {
                        changeCustomPopupPosition()
                        setAiEditOpen(true)
                    },
                    formula: (value: any) => {
                        if (value) {
                            changeCustomPopupPosition()
                            setFormulaTooltipOpen(true)
                        }
                    },
                },
            },
        }
    }, [])

    /*
     * This method converts Quill Delta type to html
     * */
    const quillGetHTML = (inputDelta: DeltaType) => {
        const tempCont = document.createElement("div")
        new Quill(tempCont).setContents(inputDelta, "user")
        const innerHTML = tempCont.getElementsByClassName("ql-editor")[0].innerHTML
        tempCont.remove()
        return innerHTML
    }

    const getTextSelection = (): string => {
        // @ts-ignore
        const editor = editorRef?.current?.editor
        return editor?.getText(selectionRef.current?.index, selectionRef?.current?.length) || ""
    }

    const handleFocus = () => !focused && dispatch(selectBlock({ blockId: block.id }))

    return (
        <StyledTextBlock
            id={`block-${block.id}`}
            gray={!block.color}
            onClick={handleFocus}
            ref={containerRef}
        >
            {onScreen ? (
                <>
                    <ReactQuill
                        bounds={`#block-${block.id}`}
                        ref={editorRef}
                        theme={"bubble"}
                        modules={modules}
                        formats={[...Object.values(RichtTextFormatOptions), "VariableBlot"]}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onChangeSelection={handleSelectionChange}
                    />
                </>
            ) : (
                <div className={"quill"}>
                    <div className={"ql-container ql-bubble"}>
                        <div className={"ql-editor"}>{parse(block.value || "")}</div>
                    </div>
                </div>
            )}
            {activeMenu === MenuType.COMPONENTS && (
                <ComponentSelector
                    position={menuPosition}
                    handleOptionSelect={handleOptionSelect}
                    onRequestClose={closeActiveMenu}
                    search={contentSearch}
                    context={context}
                />
            )}
            {activeMenu === MenuType.VARIABLES && (
                <ContextMenu
                    position={contextMenuPosition}
                    handleOptionSelect={handleVariableSelect}
                    onRequestClose={closeActiveMenu}
                    type={ContextMenuType.VARIABLES}
                    search={contentSearch}
                />
            )}
            {activeMenu === MenuType.EMOJIS && contentSearch.length > 1 && (
                <EmojiPicker
                    handleEmoji={handleEmojiSelect}
                    onRequestClose={closeActiveMenu}
                    position={contextMenuPosition}
                    search={contentSearch}
                />
            )}
            {aiEditOpen && (
                <AiEditMenu
                    onCancel={() => setAiEditOpen(false)}
                    onSelect={handleAiEdit}
                    position={customPopupPosition}
                />
            )}
            {linkInputOpen && (
                <CustomLinkTooltip
                    onSubmit={handleLinkSubmit}
                    onOutsideClick={() => setLinkInputOpen(false)}
                />
            )}
            {formulaTooltipOpen && (
                <CustomFormulaTooltip
                    onUpdate={handleFormulaUpdate}
                    onCancel={handleFormulaCancel}
                    onSubmit={handleFormulaSubmit}
                    textSelection={getTextSelection()}
                    position={customPopupPosition}
                />
            )}
        </StyledTextBlock>
    )
}

export default BlockComponent
