import styled from "styled-components"
import { StyledColumn } from "../styles/styledcomponents"
import { StyledLibraryRow } from "../components/LibraryBar/styles"

export const StyledCatalogContainer = styled(StyledColumn)`
    width: calc(100% - ${({ theme }) => theme.sidebarWidths.nav}px);
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
`

export const StyledCatalogContent = styled(StyledColumn)`
    padding: 42px 24px 30px;
    gap: 30px;
    overflow-y: auto;

    ${StyledLibraryRow} {
        width: 339px;
    }
`
