import styled, { css } from "styled-components"
import { StyledBox } from "../../../../../styles/styledcomponents"

export const StyledEditorSection = styled(StyledBox)<{ isAPane?: boolean }>`
    ${({ isAPane }) =>
        isAPane &&
        css`
            margin-left: 40px;
            background-color: ${({ theme }) => theme.colors.base.uiBgTertiary};
            border-radius: ${({ theme }) => theme.borderRadius1};
            padding: 12px;
        `}
`
