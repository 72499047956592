import { StyledCard } from "../../styles"
import { StyledBox, StyledH3, StyledSpan } from "../../../styles/styledcomponents"
import { Button } from "../../../components/Button/Button"
import { useState } from "react"
import NoContent from "../NoContent"
import PersonIcon from "@mui/icons-material/Person"
import Table, { Alignment } from "../../../components/Table"
import { UserAvatar } from "../../../creator/components/UserAvatar"
import { useTheme } from "styled-components"
import { StyledHiddenPill, StyledMoreVertIcon, StyledStatusSpan } from "./styles"
import PopupMenu, { PopupOptionType } from "../../../components/PopupMenu"
import EditIcon from "@mui/icons-material/Edit"
import SendIcon from "@mui/icons-material/Send"
import RemoveIcon from "@mui/icons-material/Remove"
import CsvModal, { CsvModalValueType } from "../../../components/CsvModal"
import { useQuery, useMutation } from "@apollo/client"
import { useSelectedProgramGuid } from "../../../hooks/selectedProgram.hook"
import { REMOVE_PROGRAM_PARTICIPANTS } from "../../../apollo/mutations"
import { updateProgramParticipants } from "../../../apollo/cacheHelper"
import { OverlayOpacityLoader } from "../../../components/Loader"
import { ENROLLED_PROGRAMS, GET_PROGRAM_PARTICIPANTS } from "../../../apollo/queries"
import { compact } from "lodash"
import { ADD_PROGRAM_PARTICIPANTS, SET_PARTICIPANT_VISIBILITY } from "../../../apollo/mutations"
import { Participant } from "../../../apollo/generated/graphql"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"

const ParticipantsCard = () => {
    const theme = useTheme()
    const [showAddParticipantsModal, setShowAddParticipantsModal] = useState(false)

    const programGuid = useSelectedProgramGuid()

    const { data: participantsData } = useQuery(GET_PROGRAM_PARTICIPANTS, {
        skip: !programGuid,
        variables: { guid: programGuid! },
    })

    const participants = compact(participantsData?.programParticipants)

    const [addParticipants, { loading: addParticipantsLoading }] = useMutation(
        ADD_PROGRAM_PARTICIPANTS,
        {
            update: (cache, { data }) => {
                data?.addProgramParticipants &&
                    updateProgramParticipants(
                        programGuid!,
                        participants.concat(compact(data.addProgramParticipants))
                    )
            },
            refetchQueries: [ENROLLED_PROGRAMS], // to keep the enrolled programs updated
        }
    )

    const [setParticipantVisibility] = useMutation(SET_PARTICIPANT_VISIBILITY)

    const [removeParticipants, { loading: removeParticipantsLoading }] = useMutation(
        REMOVE_PROGRAM_PARTICIPANTS,
        {
            refetchQueries: [ENROLLED_PROGRAMS], // to keep the enrolled programs updated
        }
    )

    const getStatusLabel = (registered: boolean) => {
        return registered ? "Registered" : "Unregistered"
    }

    const handleAddParticipants = (values: string[]) => {
        if (values?.length) {
            const mappedValues = values.map((value: string) => ({ email: value }))
            programGuid &&
                void addParticipants({
                    variables: { guid: programGuid!, participants: mappedValues },
                })
        }
    }

    const handleRemoveParticipant = (email: string) => {
        removeParticipants({ variables: { guid: programGuid, emails: [email] } }).then(
            ({ data }) => {
                if (data?.removeProgramParticipants) {
                    updateProgramParticipants(
                        programGuid!,
                        participants.filter((participant) => participant.email !== email)
                    )
                }
            }
        )
    }

    const handleChangeParticipantVisibility = (participant: Participant, hidden: boolean) => {
        if (programGuid && participant.id) {
            setParticipantVisibility({
                variables: { programGUID: programGuid, userID: participant.id, hidden },
            })
        }
    }

    const columns = {
        email: {
            header: "Email",
            alignment: Alignment.left,
            renderCell: (rowData: any) => (
                <StyledBox css={{ display: "flex", alignItems: "center" }}>
                    <UserAvatar
                        user={{
                            profileImageURL: rowData.imageURL,
                            firstName: rowData.firstName,
                            lastName: rowData.lastName,
                        }}
                    />
                    <StyledSpan
                        css={{
                            color: theme.headlandsGrayDark,
                            marginLeft: "12px",
                            marginRight: "6px",
                        }}
                    >
                        {rowData.email}
                    </StyledSpan>
                    {rowData.hidden && <StyledHiddenPill>Hidden</StyledHiddenPill>}
                </StyledBox>
            ),
        },
        company: {
            header: "Company",
            renderCell: (rowData: any) => (
                <StyledSpan css={{ color: theme.headlandsGrayDark }}>{rowData.company}</StyledSpan>
            ),
        },
        status: {
            header: "Status",
            renderCell: (rowData: any) => (
                <StyledStatusSpan registered={rowData.registered}>
                    {getStatusLabel(rowData.registered)}
                </StyledStatusSpan>
            ),
        },
        actions: {
            header: "",
            renderCell: (rowData: any) => (
                <StyledBox css={{ display: "flex", justifyContent: "flex-end" }}>
                    <PopupMenu
                        position={"left center"}
                        on={["click"]}
                        arrow={false}
                        overlayStyle={{ backgroundColor: "transparent" }}
                        options={[
                            {
                                label: "Edit",
                                icon: EditIcon,
                                onClick: () => {},
                                noDivider: true,
                                type: PopupOptionType.TEXT_AND_ICON,
                            },
                            {
                                label: "Re-send Invite",
                                icon: SendIcon,
                                onClick: () => {},
                                styles: {
                                    witheSpace: "nowrap",
                                },
                                noDivider: true,
                                type: PopupOptionType.TEXT_AND_ICON,
                            },
                            {
                                label: rowData.hidden ? "Show in Program" : "Hide from Program",
                                icon: rowData.hidden ? VisibilityOutlined : VisibilityOffOutlined,
                                onClick: () =>
                                    handleChangeParticipantVisibility(rowData, !rowData.hidden),
                                styles: {
                                    witheSpace: "nowrap",
                                },
                                hide: !rowData.id,
                                type: PopupOptionType.TEXT_AND_ICON,
                            },
                            {
                                label: "Remove",
                                icon: RemoveIcon,
                                onClick: () => handleRemoveParticipant(rowData.email),
                                styles: { color: `${theme.headlandsError}!important` },
                                type: PopupOptionType.TEXT_AND_ICON,
                            },
                        ]}
                        trigger={<StyledMoreVertIcon />}
                    />
                </StyledBox>
            ),
        },
    }

    return (
        <StyledCard>
            <StyledBox
                css={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
                <StyledH3>Participants</StyledH3>
                {!!participants?.length && (
                    <Button
                        label="Add Participant"
                        width={140}
                        onClick={() => setShowAddParticipantsModal(true)}
                    />
                )}
            </StyledBox>
            <hr />
            {participants &&
                (!participants.length ? (
                    <NoContent
                        text={"No Participants have been added"}
                        icon={PersonIcon}
                        buttonLabel={"Add Participant"}
                        buttonProps={{ width: 140 }}
                        onAdd={() => setShowAddParticipantsModal(true)}
                    />
                ) : (
                    <OverlayOpacityLoader
                        loading={addParticipantsLoading || removeParticipantsLoading}
                    >
                        <Table data={participants} columns={columns} />
                    </OverlayOpacityLoader>
                ))}
            {showAddParticipantsModal && (
                <CsvModal
                    values={[]}
                    onSubmit={handleAddParticipants}
                    closeModal={() => setShowAddParticipantsModal(false)}
                    title={"Add Participant"}
                    confirmButtonLabel={"Add"}
                    valueType={CsvModalValueType.Email}
                    avoidDuplicateValues
                />
            )}
        </StyledCard>
    )
}

export default ParticipantsCard
