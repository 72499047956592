import { Block, BlockContext, BlocksType, BlockType, PartialBlock } from "../../../types"
import TextBlock from "../TextBlock"
import BlockImage from "../BlockContent/BlockImage"
import BlockVideo from "../BlockContent/BlockVideo/BlockVideo"
import ImageUploadBlock from "../BlockContent/ImageUploadBlock/ImageUploadBlock"
import Message from "../BlockContent/Message/Message"
import SetVariableBlock from "../SetVariableBlock/SetVariableBlock"
import BlockComment from "../BlockContent/BlockComment/BlockComment"
import CarouselImage from "../CarouselImage/CarouselImage"
import DividerComponent from "../DividerComponent"
import Accordion from "../Accordion"
import { InstructorAvatar } from "../../../components/InstructorAvatar"
import { Confetti } from "../../../components/Confetti"
import QuestionsAndAnswers from "../QuestionsAndAnswers"
import { AddBlockOptions, AiEditData } from "./Editor"
import RenderChunk from "./RenderChunk"
import { StyledBox } from "../../../styles/styledcomponents"
import Section from "../Section"
import { useDispatch } from "react-redux"
import { selectChunk, updateBlock } from "../../../redux/blocks"
import { withHidableWrapper } from "../HidableBlockWrapper"
import CodeBlock from "../CodeBlock"

type RenderBlockProps = {
    blocksType: BlocksType
    block: Block
    parentType?: BlockType
    index: number
    handleBlockChange: (value: string, id: string) => void
    handleBlockDelete: (blockId: string, index: number) => void
    handleAddBlock: (options: AddBlockOptions) => void
    context?: BlockContext
    setAiEditData?: (aiEditData?: AiEditData) => void
    handlePreview?: (previewMode: boolean, initialBlockId?: string) => void
    onScreen?: boolean
}

const RenderBlock = ({
    blocksType,
    block,
    parentType,
    index,
    handleBlockChange,
    handleBlockDelete,
    handleAddBlock,
    context,
    setAiEditData,
    handlePreview,
    onScreen,
}: RenderBlockProps) => {
    const dispatch = useDispatch()

    if (!block) return null
    switch (block.type) {
        case BlockType.TEXT:
            return (
                <TextBlock
                    key={`block-${block.id}`}
                    blocksType={blocksType}
                    block={block}
                    blockIndex={index}
                    parentType={parentType}
                    onChange={handleBlockChange}
                    onDelete={handleBlockDelete}
                    handleAddBlock={handleAddBlock}
                    context={context}
                    setAiEditData={setAiEditData}
                    onScreen={onScreen}
                />
            )
        case BlockType.IMAGE:
            const onImageBlockUpdate = (updatedBlock: PartialBlock) => {
                dispatch(updateBlock({ blocksType, id: block.id, block: updatedBlock }))
            }

            return (
                <BlockImage
                    key={`block-${block.id}`}
                    block={block}
                    blocksType={blocksType}
                    onUpdate={onImageBlockUpdate}
                />
            )
        case BlockType.VIDEO:
            return (
                <BlockVideo
                    key={`block-${block.id}`}
                    blocksType={blocksType}
                    block={block}
                    onChange={handleBlockChange}
                />
            )
        case BlockType.NOTIFICATION:
            return withHidableWrapper(<Message key={`block-${block.id}`} block={block} />)(
                block.hidden
            )
        case BlockType.IMAGE_UPLOAD:
            return (
                <ImageUploadBlock
                    key={`block-${block.id}`}
                    block={block}
                    blockIndex={index}
                    handleAddBlock={handleAddBlock}
                />
            )
        case BlockType.SET_STRING_VARIABLE:
            return (
                <SetVariableBlock
                    key={`block-${block.id}`}
                    block={block}
                    onChange={handleBlockChange}
                />
            )
        case BlockType.COMMENT:
            return withHidableWrapper(
                <BlockComment
                    key={`block-${block.id}`}
                    block={block}
                    blockIndex={index}
                    onChange={handleBlockChange}
                    onDelete={handleBlockDelete}
                    onScreen={onScreen}
                />
            )(block.hidden)
        case BlockType.IMAGE_CAROUSEL:
            return <CarouselImage key={`block-${block.id}`} blocksType={blocksType} block={block} />
        case BlockType.DIVIDER:
            return <DividerComponent key={`block-${block.id}`} block={block} />
        case BlockType.ACCORDION:
            return (
                <Accordion
                    key={`block-${block.id}`}
                    blocksType={blocksType}
                    block={block}
                    blockIndex={index}
                    onChange={handleBlockChange}
                />
            )
        case BlockType.INSTRUCTOR_AVATAR:
            return <InstructorAvatar />
        case BlockType.CONFETTI:
            return <Confetti />
        case BlockType.QUESTIONS_AND_ANSWERS:
            return <QuestionsAndAnswers block={block} blocksType={blocksType} />
        case BlockType.SECTION:
            return withHidableWrapper(
                <Section block={block} context={context} handlePreview={handlePreview} />
            )(block.hidden)
        // chunks
        case BlockType.CONDITIONAL:
        case BlockType.INCLUDE:
        case BlockType.GROUP:
        case BlockType.CHOICE_QUESTION:
        case BlockType.FREE_TEXT_QUESTION:
            return (
                <StyledBox onClick={() => dispatch(selectChunk({ chunkId: block.id }))}>
                    <RenderChunk block={block} blocksType={blocksType} context={context} />
                </StyledBox>
            )
        case BlockType.CODE:
            return <CodeBlock key={`block-${block.id}`} block={block} blocksType={blocksType} />
        default:
            return null
    }
}

export default RenderBlock
