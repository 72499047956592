import { useCallback } from "react"
import { useTheme } from "styled-components"
import { Loader } from "../Loader"
import { useCurrentThreadInstructor } from "../../hooks/currentThread.hook"
import { useToast } from "../../hooks/useToast.hook"
import { StyledColumn, StyledHr, StyledRow, StyledSpan } from "../../styles/styledcomponents"
import {
    StyledChevronDown,
    StyledCopyLinkButton,
    StyledEmailsContainer,
    StyledLinkIcon,
    StyledShareModal,
    StyledSocialMediaIcon,
} from "./styles"
import facebook from "../../assets/images/facebook-square.png"
import twitter from "../../assets/images/twitter-square.png"
import linkedin from "../../assets/images/linkedin-square.png"
import teams from "../../assets/images/teams-square.png"
import slack from "../../assets/images/slack-square.png"
import { PopupProps } from "reactjs-popup/dist/types"
import { UserAvatar } from "../../creator/components/UserAvatar"
import { AvatarSize } from "../../types"
import { useShareThread } from "../../hooks/shareThread.hook"
import { pick } from "lodash"
import { Maybe } from "graphql/jsutils/Maybe"

interface ShareThreadModalProps extends Omit<PopupProps, "children"> {
    programID?: Maybe<number>
    threadID?: Maybe<number>
    additionalText?: string
}

// note: the `ShareThreadModal` now is used only in creator tool
const ShareThreadModal = ({
    programID,
    threadID,
    additionalText,
    ...popupProps
}: ShareThreadModalProps) => {
    const theme = useTheme()
    const { showToast } = useToast()
    const { linkData, linkLoading, generateLink } = useShareThread({ threadID, programID })
    const instructor = useCurrentThreadInstructor()

    const handleCopyLink = useCallback(() => {
        const link = linkData?.createLink.linkURL
        if (link) {
            navigator.clipboard
                .writeText(additionalText ? link + "\n" + additionalText : link)
                .then(() =>
                    showToast({
                        icon: <StyledLinkIcon sx={{ color: "white" }} />,
                        message: "Thread link copied to clipboard",
                    })
                )
        }
    }, [linkData])

    return (
        <StyledShareModal
            overlayStyle={{ backgroundColor: "transparent" }}
            arrow={false}
            {...popupProps}
            onOpen={generateLink}
        >
            <StyledRow
                css={{ alignItems: "center", justifyContent: "space-between", width: "100%" }}
            >
                {/* TODO: replace with real input and dropdown when this component is integrated */}
                <StyledEmailsContainer css={{ width: "100%", opacity: 0.3 }}>
                    <StyledSpan css={{ color: theme.colors.base.uiLabelDisabled }}>
                        Enter emails or names
                    </StyledSpan>
                    <StyledRow css={{ alignItems: "center" }}>
                        <StyledSpan css={{ color: theme.colors.base.uiLabelDisabled }}>
                            Can edit
                        </StyledSpan>
                        <StyledChevronDown sx={{ color: theme.colors.base.uiLabelDisabled }} />
                    </StyledRow>
                </StyledEmailsContainer>
            </StyledRow>
            <StyledRow
                css={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: "6px",
                    width: "100%",
                    opacity: 0.3,
                }}
            >
                <StyledRow css={{ alignItems: "center" }}>
                    <UserAvatar
                        user={{ ...pick(instructor, ["profileImageURL", "firstName", "lastName"]) }}
                        size={AvatarSize.XXS}
                    />
                    <StyledSpan css={{ marginLeft: "6px", color: theme.colors.base.uiLabelBase }}>
                        {instructor?.firstName} {instructor?.lastName}
                    </StyledSpan>
                </StyledRow>
                <StyledSpan css={{ color: theme.colors.base.uiLabelDisabled }}>Owner</StyledSpan>
            </StyledRow>
            <StyledHr css={{ width: "100%", color: theme.colors.base.uiBgBorder }} />
            <StyledColumn css={{ gap: "6px", width: "100%" }}>
                <StyledRow
                    css={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "6px",
                        opacity: 0.3,
                    }}
                >
                    <StyledRow css={{ alignItems: "center" }}>
                        <StyledLinkIcon
                            sx={{
                                padding: "4px",
                                color: theme.colors.base.uiPrimary300,
                                backgroundColor: theme.colors.base.uiPrimary500,
                                borderRadius: "50%",
                                fontSize: "16px !important",
                            }}
                        />
                        <StyledSpan
                            css={{ marginLeft: "6px", color: theme.colors.base.uiLabelBase }}
                        >
                            Anyone with the link
                        </StyledSpan>
                    </StyledRow>
                    <StyledRow css={{ alignItems: "center" }}>
                        {/* TODO: replace with real dropdown when this component is integrated */}
                        <StyledSpan css={{ color: theme.colors.base.uiLabelDisabled }}>
                            Can play
                        </StyledSpan>
                        <StyledChevronDown sx={{ color: theme.colors.base.uiLabelDisabled }} />
                    </StyledRow>
                </StyledRow>
            </StyledColumn>
            {/* row with social media icons and copy button */}
            <StyledRow css={{ gap: "18px", alignItems: "center" }}>
                <StyledRow css={{ gap: "12px", padding: "6px 8px" }}>
                    <StyledSocialMediaIcon src={facebook} disabled />
                    <StyledSocialMediaIcon src={twitter} disabled />
                    <StyledSocialMediaIcon src={linkedin} disabled />
                    <StyledSocialMediaIcon src={teams} disabled />
                    <StyledSocialMediaIcon src={slack} disabled />
                </StyledRow>
                <StyledCopyLinkButton onClick={handleCopyLink}>
                    {linkLoading ? (
                        <Loader size={18} />
                    ) : (
                        <StyledLinkIcon sx={{ color: theme.headlandsPrimaryColor }} />
                    )}
                    <StyledSpan>Copy link</StyledSpan>
                </StyledCopyLinkButton>
            </StyledRow>
        </StyledShareModal>
    )
}

export default ShareThreadModal
