import styled from "styled-components"
import { StyledColumn } from "../../../styles/styledcomponents"

export const StyledEmptyActivity = styled(StyledColumn)`
    padding: 12px 6px;
    gap: 12px;
    background-color: ${({ theme }) => theme.colors.base.uiPrimary300};
    border-radius: 8px;
    align-items: center;
`
