import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react"

const useStateRef = (initialValue: any) => {
    const [value, setValue] = useState(initialValue)

    const ref = useRef(value)

    useEffect(() => {
        ref.current = value
    }, [value])

    return [value, setValue, ref]
}
// todo: migrate all `useStateRef` to use this instead, and remove the `generic` word
export const useStateRefGeneric = <T>(
    initialValue?: T
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, MutableRefObject<T | undefined>] => {
    const [value, setValue] = useState<T | undefined>(initialValue)

    const ref = useRef<T | undefined>(value)

    useEffect(() => {
        ref.current = value
    }, [value])

    return [value, setValue, ref]
}

export default useStateRef
