import { useMemo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../redux/store"
import { Block, BlockType, BlocksType } from "../../../../../types"
import { Editor } from "../../Editor"
import { StyledEditorSection } from "./styles"
import PanePreviewNew, {
    PanePreviewContext,
} from "../../../../../components/ThreadPreview/PanePreviewNew"

export interface EditorSectionProps {
    blocksType: BlocksType
    handlePreview: (
        previewMode: boolean,
        initialBlockId?: string,
        blocksToPreview?: {
            blocks?: Block[]
            previewBlocks?: Block[]
            synopsisBlocks?: Block[]
        }
    ) => void
}

const EditorSection = ({ blocksType, handlePreview }: EditorSectionProps) => {
    const blocksIndex = useSelector((state: RootState) => state.blocksReducer[`${blocksType}Index`])

    const isAPane = useMemo(
        () => [BlocksType.PREVIEW, BlocksType.SYNOPSIS].includes(blocksType),
        [blocksType]
    )

    if (blocksType === BlocksType.SYNOPSIS) {
        return (
            <PanePreviewNew
                variant={BlockType.SYNOPSIS}
                context={PanePreviewContext.creator}
                blocksType={blocksType}
                handlePreview={handlePreview}
                blockIds={blocksIndex}
            />
        )
    }

    return (
        <StyledEditorSection isAPane={isAPane}>
            <Editor
                blocksType={blocksType}
                blockIds={blocksIndex}
                handlePreview={handlePreview}
                containsChunks={blocksType === BlocksType.BLOCKS}
            />
        </StyledEditorSection>
    )
}

export default EditorSection
