import styled, { css } from "styled-components"

interface StyledIconButtonProps {
    rounded: boolean
    disabled: boolean
    transparent: boolean
}

export const StyledIconButton = styled.button<StyledIconButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.thread.student.bubble};
    width: 42px;
    height: 42px;
    border: none;
    cursor: pointer;

    svg {
        color: ${(props) => props.theme.colors.thread.student.font};
    }

    ${(props) =>
        props.rounded &&
        css`
            border-radius: 50px;
        `}

    ${(props) =>
        props.disabled &&
        css`
            opacity: ${(props) => props.theme.disabledOpacity};
            cursor: default;
        `}

    ${(props) =>
        props.transparent &&
        css`
            background-color: transparent;
            transition: background-color 0.2s;
            svg {
                color: ${(props) => props.theme.colors.thread.student.bubble};
                transition: color 0.2s;
            }
        `}
`
