import styled, { css as styledComponentCss } from "styled-components"
import Popup from "reactjs-popup"
import { CustomCssProps, StyledBox, StyledH2 } from "../../styles/styledcomponents"

export const StyledModal = styled(Popup)<CustomCssProps>`
    &-overlay {
        background-color: rgba(119, 119, 119, 0.4);
    }
    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.base.uiBgBase};
        border-radius: ${({ theme }) => theme.borderRadius1};
        box-shadow: 0 0 24px 2px rgba(0, 0, 0, 0.25);
        padding: 24px !important;
        cursor: default;
        text-align: unset !important;

        ${(props) => styledComponentCss`${props.css}`}
    }
`

export const StyledModalHeader = styled(StyledBox)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    ${StyledH2} {
        display: flex;
        font-size: 24px !important;
        color: ${({ theme }) => theme.headlandsGray1};
    }
`
