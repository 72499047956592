import styled, { css } from "styled-components"
import {
    StyledBox,
    StyledColumn,
    StyledH4,
    StyledImage,
    StyledRow,
    VerticalScrollableContentWithHiddenScroll,
} from "../../../../styles/styledcomponents"
import { StyledEmptyImagePlaceholder } from "../../BlockContent/BlockImage/styles"
import TextareaAutosize from "react-textarea-autosize"
import Popup from "reactjs-popup"
import Menu from "@mui/icons-material/Menu"

const VariablePadding = {
    vertical: 4,
    horizontal: 2,
}
const VariableLineHeight = 18
const VariableHeight = VariableLineHeight + 2 * VariablePadding.vertical

export const StyledPropertyTabContent = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

export const StyledCoverImage = styled(StyledImage)`
    width: 128px;
    object-fit: cover;
    border-radius: 8px;

    &:hover {
        cursor: pointer;
    }
`

export const StyledVariable = styled.div<{ error?: boolean }>`
    padding: ${VariablePadding.vertical}px ${VariablePadding.horizontal}px;
    border-radius: 4px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    input {
        flex: 1;
        padding: 2px 0;
        border: none;
        outline: none;
        color: ${({ theme }) => theme.headlandsGray2};
        background-color: transparent;
    }

    svg {
        display: none;
    }

    span {
        font-size: 12px;
        line-height: ${VariableLineHeight}px;
        color: ${({ theme }) => theme.headlandsGray3};
    }

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.headlandsGray5};

        svg {
            display: block;
        }
    }

    ${({ error }) =>
        error &&
        css`
            input {
                color: ${({ theme }) => theme.headlandsError};
            }
        `}
`

export const StyledVariablesContainer = styled(StyledBox)`
    ${VerticalScrollableContentWithHiddenScroll};
    margin-top: 12px;
    // we render up to 15.5 variables
    max-height: ${15.5 * VariableHeight}px;
    display: flex;
    flex-direction: column;
    flex: 1;
`

export const StyledEmptyImage = styled(StyledEmptyImagePlaceholder)`
    min-height: 80px;
    max-height: 80px;
    min-width: 128px;
    max-width: 128px;
    border-radius: ${({ theme }) => theme.borderRadius1};

    &:hover {
        cursor: pointer;
    }
`

export const StyledInstructorCard = styled(StyledColumn)`
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    overflow: hidden;
`

export const StyledInstructorBody = styled(StyledColumn)<{ dark?: boolean }>`
    height: 52px;
    box-sizing: border-box;
    padding: 6px;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};

    ${({ dark }) =>
        dark &&
        css`
            background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
        `}
`

export const StyledInstructorDescriptionInput = styled(TextareaAutosize)`
    font-size: 12px;
    line-height: 18px;
    outline: none;
    resize: none;
    padding: 0 !important;
    border: none;
    background-color: transparent;
`

export const StyledInstructorHeader = styled(StyledRow)`
    align-items: center;
    gap: 8px;
    padding: 6px;
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
    border-bottom: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};

    ${StyledH4} {
        color: ${({ theme }) => theme.colors.base.uiPrimary500};
    }
`

export const StyledInstructorPopup = styled(Popup)`
    &-content {
        width: 300px;
        height: 284px;
        background-color: ${({ theme }) => theme.colors.base.uiBgBase};
        border-radius: 16px;
        padding: 6px;
        overflow-y: auto;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    }
`

export const StyledMenuIcon = styled(Menu)`
    font-size: 14px !important;
    color: ${({ theme }) => theme.colors.base.uiBgIcon};
    cursor: pointer;
`
