import { Check } from "@mui/icons-material"
import { StyledBox } from "../../styles/styledcomponents"
import { StyledCheckbox } from "./styles"
import { StyledInputLabel } from "../InputNew/styles"
import { CSSProperties } from "react"

export interface CheckboxProps {
    selected: boolean
    onChange?: () => void
    label?: string
    dataTestId?: string
    labelPosition?: "right" | "left"
    color?: string
    css?: CSSProperties
}

const Checkbox = ({
    selected,
    onChange,
    label,
    dataTestId,
    labelPosition = "left",
    color,
    css,
}: CheckboxProps) => (
    <StyledBox
        css={{
            display: "flex",
            alignItems: "center",
            flexDirection: labelPosition === "left" ? "row" : "row-reverse",
            ...css,
        }}
    >
        {!!label && <StyledInputLabel css={{ marginRight: 6 }}>{label}</StyledInputLabel>}
        <StyledCheckbox
            selected={selected}
            onClick={onChange}
            data-testid={dataTestId}
            color={color}
        >
            {selected && <Check sx={{ color: "white", fontSize: 14 }} />}
        </StyledCheckbox>
    </StyledBox>
)

export default Checkbox
