import styled from "styled-components"
import { Alignment } from "./index"

type StyledTableProps = {
    fullWidth?: boolean
}

type StyledThProps = {
    alignment?: Alignment
}

type StyledTdProps = Pick<StyledThProps, "alignment"> & {}

export const StyledTable = styled.table<StyledTableProps>`
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "unset")};
    border-collapse: collapse;
`

export const StyledTHead = styled.thead``

export const StyledTr = styled.tr`
    height: 52px;
    border-bottom: 1px solid #d9d9d9;

    :last-of-type {
        border-bottom: none;
    }
`

export const StyledTh = styled.th<StyledThProps>`
    text-align: ${({ alignment }) => alignment ?? Alignment.center};
    color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
`

export const StyledTbody = styled.tbody``

export const StyledTd = styled.td<StyledTdProps>`
    text-align: ${({ alignment }) => alignment ?? Alignment.center};
`
