import { useHistory, useParams } from "react-router"
import EyeIcon from "../../../assets/icons/EyeIcon"
import EllipsisText from "../../../components/EllipsisText"
import { StyledBody2, StyledColumn } from "../../../styles/styledcomponents"
import CreatorMobileTopBar from "../components/CreatorMobileTopBar"
import { StyledCardContainer, StyledCreatorMobileScreen, StyledList } from "../styles"
import { StyledReadOnlyPill } from "./styles"
import { useQuery } from "@apollo/client"
import { GET_CREATOR_THREAD } from "../../../apollo/queries"
import { parseBlocksString } from "../../../thread/utils"
import { useState } from "react"
import { Block } from "../../../types"
import CreatorBlockMobile from "../components/CreatorBlockMobile"

const CreatorThreadMobile = () => {
    const [parsedBlocks, setParsedBlocks] = useState<Block[]>()
    const history = useHistory()
    const { threadGuid } = useParams<{ threadGuid: string }>()

    const { data } = useQuery(GET_CREATOR_THREAD, {
        variables: { threadGUID: threadGuid },
        onCompleted: ({ getThreadByGuid }) => {
            if (getThreadByGuid?.blocks) setParsedBlocks(parseBlocksString(getThreadByGuid.blocks))
        },
    })

    return (
        <StyledCreatorMobileScreen>
            <CreatorMobileTopBar onBack={history.goBack}>
                <StyledColumn css={{ alignItems: "center" }}>
                    <EllipsisText
                        variant={"h4"}
                        text={data?.getThreadByGuid?.title || ""}
                        maxWidth={250}
                    />
                    <ReadOnlyPill />
                </StyledColumn>
            </CreatorMobileTopBar>
            <StyledCardContainer>
                <StyledList>
                    {parsedBlocks?.map((block) => (
                        <CreatorBlockMobile block={block} />
                    ))}
                </StyledList>
            </StyledCardContainer>
        </StyledCreatorMobileScreen>
    )
}

const ReadOnlyPill = () => {
    return (
        <StyledReadOnlyPill>
            <EyeIcon />
            <StyledBody2>Read Only</StyledBody2>
        </StyledReadOnlyPill>
    )
}

export default CreatorThreadMobile
