import styled, { css } from "styled-components"
import rgba from "polished/lib/color/rgba"

export const StyledDropzone = styled.div<{ error?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.headlandsGray4};
    border-radius: ${({ theme }) => theme.borderRadius1};
    border: 1px dashed ${({ theme }) => theme.headlandsGray3};
    padding: 12px 28px;
    text-align: center;
    cursor: pointer;
    min-height: 38px;

    p {
        color: ${({ theme }) => theme.headlandsGray2};
    }

    img {
        width: 24px;
        height: 24px;
    }

    ${({ error }) =>
        error &&
        css`
            border-radius: ${({ theme }) => theme.borderRadius1}
                ${({ theme }) => theme.borderRadius1} 0 0;
        `}
`

export const StyledErrorDropzone = styled.div`
    background-color: ${({ theme }) => rgba(theme.headlandsError, 0.2)};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border: 1px dashed ${({ theme }) => theme.headlandsError};
    border-radius: 0 0 ${({ theme }) => theme.borderRadius1} ${({ theme }) => theme.borderRadius1};
    p {
        margin-left: 5px;
        color: ${({ theme }) => theme.headlandsError};
        font-size: 14px;
    }
    path {
        fill: ${({ theme }) => theme.headlandsError};
    }
`
