import InvertColorsIcon from "@mui/icons-material/InvertColors"
import { StyledBox } from "../../../styles/styledcomponents"
import { useTheme } from "styled-components"

interface BackgroundColorOptionProps {
    handleBackgroundColor: () => void
    selected: boolean
}

const BackgroundColorOption = ({ handleBackgroundColor, selected }: BackgroundColorOptionProps) => {
    const theme = useTheme()
    return (
        <StyledBox
            css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50px",
                border: `1px solid ${theme.headlandsGray3}`,
                backgroundColor: theme.headlandsWhite,
                width: 20,
                height: 20,
                cursor: "pointer",
                position: "absolute",
                top: 0,
                right: "16px",
            }}
            onClick={handleBackgroundColor}
        >
            <InvertColorsIcon sx={{ height: 15, color: theme.headlandsGray10 }} />
        </StyledBox>
    )
}

export default BackgroundColorOption
