import "./ContextMenu.scss"
import { useRef, useEffect, useState } from "react"
import classNames from "classnames"
import { ContextMenuType } from "../../types"
import useStateRef from "../../hooks/stateRef.hook"
import useOnClickOutside from "../../hooks/onClickOutside.hook"
import useVariableDefinitions from "../../hooks/variables.hook"

interface ContextMenuProps {
    handleOptionSelect: (option: string, error?: boolean) => void
    onRequestClose: () => void
    position: { top: number; left: number; bottom?: number }
    type: ContextMenuType
    search?: string
}

const ContextMenu = ({
    handleOptionSelect,
    onRequestClose,
    position,
    type,
    search = "",
}: ContextMenuProps) => {
    const options = useVariableDefinitions().map((variable) => variable.name)
    const filterOption = options.filter((option) =>
        option.toLowerCase().includes(search?.toLowerCase())
    )
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [positionModal, setPositionModal] = useState<{ vertical: string; position: number }>({
        vertical: "top",
        position: position.top,
    })

    const [cursor, setCursor, cursorRef] = useStateRef(0)

    useOnClickOutside(wrapperRef, onRequestClose)

    const onKeyDown = (event: any) => {
        switch (event.key) {
            case "Escape":
                onRequestClose()
                break
            case "Enter":
                event.preventDefault()
                if (!!filterOption.length) handleOptionSelect(filterOption[cursorRef.current])
                else handleOptionSelect(search, true)
                break
            case "ArrowDown":
                event.preventDefault()
                if (cursorRef.current < filterOption.length - 1) {
                    setCursor(cursorRef.current + 1)
                }
                break
            case "ArrowUp":
                event.preventDefault()
                if (cursorRef.current > 0) {
                    setCursor(cursorRef.current - 1)
                }
                break
            case "ArrowLeft":
            case "ArrowRight":
                event.preventDefault()
                break
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown)
        return () => document.removeEventListener("keydown", onKeyDown)
    }, [search])

    const getInitial = (option: string) => {
        switch (type) {
            case ContextMenuType.VARIABLES:
                return option.charAt(0).toUpperCase()
        }
    }

    useEffect(() => {
        if (wrapperRef.current) {
            const wrapperTop = wrapperRef.current?.getBoundingClientRect().top
            const wrapperHeight = wrapperRef.current?.getBoundingClientRect().height
            if (wrapperTop - wrapperHeight - 100 > 0) {
                // Should appear on top
                setPositionModal({
                    vertical: "bottom",
                    position: position.bottom ? window.innerHeight - position.bottom + 30 : 30,
                })
            } else {
                // Should appear on bottom
                setPositionModal({ vertical: "top", position: position.top + 20 })
            }
        }
    }, [wrapperRef])

    // We don't allow spaces on variable names
    useEffect(() => {
        if (search.includes(" ")) onRequestClose()
    }, [search])

    return (
        <div
            className={classNames("context-menu", { bottom: positionModal.vertical === "top" })}
            ref={wrapperRef}
            style={{
                left: position.left,
                top: positionModal.vertical === "top" ? positionModal.position : "auto",
                bottom: positionModal.vertical === "bottom" ? positionModal.position : "auto",
            }}
        >
            {!!filterOption.length ? (
                filterOption.map((option, index) => (
                    <div
                        key={option}
                        className={classNames("option", { selected: index === cursor })}
                        onClick={() => handleOptionSelect(option)}
                    >
                        <div className={"initial"}>
                            <span>{getInitial(option)}</span>
                        </div>
                        <span>{option}</span>
                    </div>
                ))
            ) : (
                <div className={"variable"}>
                    <span>No variables found</span>
                </div>
            )}
        </div>
    )
}

export default ContextMenu
