import { ReactElement, useEffect, useRef, useState } from "react"
import { StyledClosedIcon, StyledModalFooter, StyledTag, StyledTagsContainer } from "./styles"
import _, { isArray, isString, uniqBy } from "lodash"
import { useCSVReader } from "react-papaparse"
import { ParseResult } from "papaparse"
import { useTheme } from "styled-components"
import { generateUUID, validateEmail } from "../../utils/utils"
import { Button } from "../Button/Button"
import { StyledBox, StyledH3, StyledSpan } from "../../styles/styledcomponents"
import Modal from "../Modal"

export enum CsvModalValueType {
    Text,
    Email,
}

interface CsvModalProps {
    values?: string[] | string
    onSubmit: (values: string[]) => void
    closeModal: () => void
    title?: string | ReactElement
    confirmButtonLabel?: string
    avoidDuplicateValues?: boolean
    valueType?: CsvModalValueType
}

type Item = {
    id: string
    value: string
}

const CsvModal = ({
    values,
    onSubmit,
    closeModal,
    title,
    confirmButtonLabel,
    avoidDuplicateValues,
    valueType = CsvModalValueType.Text,
}: CsvModalProps) => {
    const [items, setItems] = useState<Item[]>([])
    const [currentItemValue, setCurrentItemValue] = useState<string>("")
    const inputRef = useRef<HTMLInputElement>(null)
    const tagsContainerRef = useRef<HTMLDivElement>(null)
    const { CSVReader } = useCSVReader()
    const theme = useTheme()

    useEffect(() => {
        if (values) {
            let itemsToAdd: Item[]
            /*
             * Here we need to check if the 'values' props is an array or is a string, this could happen
             * when switching between operators with a value already set. So here we perform a value transformation
             * */
            if (isArray(values)) itemsToAdd = values.map((value) => ({ id: generateUUID(), value }))
            else itemsToAdd = [{ id: generateUUID(), value: values }]
            setItems(itemsToAdd)
        }
    }, [values])

    const validateValue = (value: string) => {
        switch (valueType) {
            case CsvModalValueType.Text:
                return true
            case CsvModalValueType.Email:
                return validateEmail(value)
            default:
                return true
        }
    }

    const formatValue = (value: string) => {
        switch (valueType) {
            case CsvModalValueType.Text:
                return value
            case CsvModalValueType.Email:
                const username = value.substring(0, value.indexOf("@")) ?? ""
                const domain = value.substring(value.indexOf("@"))?.toLowerCase() ?? ""
                return `${username}${domain}`
            default:
                return value
        }
    }

    const onCurrentValueChange = (itemValue: string) => {
        if (itemValue.includes(",")) {
            const value = itemValue.split(",")[0]
            setItems([...items, { id: generateUUID(), value }])
            setCurrentItemValue("")
        } else {
            setCurrentItemValue(itemValue)
        }
    }

    const removeItem = (id: string) => {
        setItems(items.filter((item) => item.id !== id))
    }

    const handleKeyDown = (key: string) => {
        if (key === "Enter") {
            if (currentItemValue !== "") {
                setItems([...items, { id: generateUUID(), value: currentItemValue }])
                setCurrentItemValue("")
            }
        } else if (key === "Backspace") {
            if (currentItemValue === "") {
                if (items.length > 0) {
                    setItems(_.dropRight(items))
                }
            }
        }
    }

    /*
     * Todo: handle wrong data format, to avoid runtime error
     * */
    const handleCSVDrop = (results: ParseResult<string[]>) => {
        let newValues: string[] = []
        results.data.map((row) => {
            newValues = [...newValues, ...row]
        })

        if (!_.isEmpty(newValues)) {
            setItems([
                ...items,
                ...newValues.map((value) => ({
                    id: generateUUID(),
                    value,
                })),
            ])
        }
    }

    const handleModalClose = () => {
        let validValues
        if (currentItemValue !== "") {
            // we don't add the new item via setItems because it's async, and the result is not ready in time
            validValues = [...items, { id: generateUUID(), value: currentItemValue }].filter(
                (item) => validateValue(item.value)
            )
        } else {
            validValues = items.filter((item) => validateValue(item.value))
        }

        if (avoidDuplicateValues) {
            validValues = uniqBy(validValues, (item) => item.value)
        }
        onSubmit(validValues.map((item) => item.value))
        closeModal()
    }

    const showPlaceholder = _.isEmpty(items)

    return (
        <CSVReader onUploadAccepted={handleCSVDrop}>
            {({ getRootProps }: any) => (
                <Modal open={true} onClose={handleModalClose} styles={{ maxWidth: "670px" }}>
                    <StyledBox
                        css={{
                            display: "flex",
                            width: "670px",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {isString(title) ? (
                            <StyledH3 css={{ color: theme.headlandsGray1 }}>{title}</StyledH3>
                        ) : (
                            title
                        )}
                        <StyledClosedIcon onClick={handleModalClose} />
                    </StyledBox>
                    <StyledTagsContainer
                        onClick={() => inputRef?.current?.focus()}
                        onDrop={(e) => getRootProps().onDrop(e)}
                        ref={tagsContainerRef}
                    >
                        {items.map((item, index) => (
                            <StyledTag
                                key={index}
                                error={!validateValue(item.value)}
                                preserveCase={valueType === CsvModalValueType.Email}
                            >
                                <StyledSpan>{formatValue(item.value)}</StyledSpan>
                                <StyledClosedIcon onClick={() => removeItem(item.id)} />
                            </StyledTag>
                        ))}
                        <input
                            value={currentItemValue}
                            onChange={(event) => onCurrentValueChange(event.target.value)}
                            onKeyDown={(event) => handleKeyDown(event.key)}
                            ref={inputRef}
                            type={"text"}
                            placeholder={
                                showPlaceholder
                                    ? "Start typing comma separated values or Drag & Drop .csv files"
                                    : undefined
                            }
                        />
                    </StyledTagsContainer>

                    <StyledModalFooter>
                        <Button
                            label={"Import from .csv"}
                            onClick={(e) => getRootProps().onClick(e)}
                            variant={"outline"}
                        />
                        <Button
                            label={confirmButtonLabel ?? "Done"}
                            onClick={handleModalClose}
                            buttonStyle={{ marginLeft: "12px" }}
                        />
                    </StyledModalFooter>
                </Modal>
            )}
        </CSVReader>
    )
}

export default CsvModal
