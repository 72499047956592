import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import AiPopupTitle from "../AiPopupTitle"
import AiAssistPopupContent from "../AiAssistPopup/AiAssistPopupContent"
import AiPopupFooter, { ButtonsAlignment } from "../AiPopupFooter"
import { Block, BlocksType } from "../../../../types"
import { useLazySubscriptionWithOptions } from "../../../../hooks/lazySubscription.hook"
import { AI_CONTINUE_WRITING, AI_GENERATE_QUESTION } from "../../../../apollo/subscriptions"
import AiPopup, { AiPopupProps } from ".."
import { CreateBlockData } from "../../../../redux/ai"
import { useCurrentThreadField } from "../../../../hooks/currentThread.hook"
import { compact, values } from "lodash"
import { parseBlocksString } from "../../../../thread/utils"
import { useDispatch } from "react-redux"
import { addBlocks, updateAutoSaveTimeout } from "../../../../redux/blocks"
import useSaveThread from "../../../../hooks/saveThread.hook"
import { StyledGhostIndicatorBar } from "./styles"
import { AiAssistMenuBlockKey } from "../../AddChunkMenu/constants"
import { useMutation } from "@apollo/client"
import { AI_GENERATE_QUESTION_TOPIC, AI_GENERATE_WRITING_TOPIC } from "../../../../apollo/mutations"
import AiPopupQuestionForm, { QuestionFormValues } from "./AiPopupQuestionForm"
import { QuestionIntent } from "../../../../apollo/generated/graphql"
import { useStateRefGeneric } from "../../../../hooks/stateRef.hook"
import AiPopupContinueWritingForm, { ContinueWritingFormValues } from "./AiPopupContinueWritingForm"

interface AiCreateBlockPopupProps extends AiPopupProps {
    data?: CreateBlockData
    parentWidth?: number
}

const AiCreateBlockPopup = ({
    data,
    parentHeight,
    parentWidth,
    showTitle,
    ...props
}: AiCreateBlockPopupProps) => {
    const [loading, setLoading] = useState(false)
    const [blocksGenerated, setBlocksGenerated] = useState<Block[]>([])
    const [aiGenerateQuestionComplete, setAiGenerateQuestionComplete] = useState(false)
    const [aiContinueWritingComplete, setAiContinueWritingComplete] = useState(false)
    const [threadSaved, setThreadSaved] = useState(false)
    const [aiGenerateQuestionCalled, setAiGenerateQuestionCalled] = useState(false)
    const [aiContinueWritingCalled, setAiContinueWritingCalled] = useState(false)
    const [disableKeyBinding, setDisableKeyBinding] = useState(false)
    const [questionFormValues, setQuestionFormValues, questionFormValuesRef] =
        useStateRefGeneric<Required<QuestionFormValues>>()
    const [continueWritingFormValues, setContinueWritingFormValues, continueWritingFormValuesRef] =
        useStateRefGeneric<Required<ContinueWritingFormValues>>()
    const blockGeneratedRef = useRef<Block[]>([])

    const currentThreadId = useCurrentThreadField("id")
    const currentThreadVersionId = useCurrentThreadField("threadVersionID")
    const dispatch = useDispatch()
    const [saveCurrentThread, { loading: savingLoading, error: savingError }] = useSaveThread()

    const isAQuestionType = useMemo(
        () => data?.type === AiAssistMenuBlockKey.Question,
        [data?.type]
    )

    const isContinueWriting = useMemo(
        () => data?.type && data.type === AiAssistMenuBlockKey.Writing,
        [data?.type]
    )

    const generateAiTopic = useCallback(() => {
        if (!currentThreadId || !currentThreadVersionId) {
            console.error(
                `Error: 'threadId' or 'threadVersionId' is missing on AI_GENERATE_QUESTION_TOPIC`
            )
        } else if (data) {
            void aiGenerateQuestionTopic({
                variables: {
                    specs: {
                        threadId: currentThreadId,
                        threadVersionId: currentThreadVersionId,
                        parentGUID: data?.sectionId,
                        index: data?.index || 0,
                        intent: QuestionIntent.PlayfulTrivia,
                    },
                },
            })
        }
    }, [data, currentThreadId, currentThreadVersionId])

    const generateWritingTopic = useCallback(() => {
        if (!currentThreadId || !currentThreadVersionId) {
            console.error(
                `Error: 'threadId' or 'threadVersionId' is missing on AI_GENERATE_WRITING_TOPIC`
            )
        } else if (data) {
            if (isContinueWriting) {
                void aiGenerateWritingTopic({
                    variables: {
                        specs: {
                            threadId: currentThreadId,
                            threadVersionId: currentThreadVersionId,
                            parentGUID: data?.sectionId,
                            index: data?.index || 0,
                        },
                    },
                })
            }
        }
    }, [data, currentThreadId, currentThreadVersionId, isContinueWriting])

    const generateQuestionBlocks = useCallback(
        (questionValues: Required<QuestionFormValues>) => {
            if (!currentThreadId || !currentThreadVersionId) {
                console.error(
                    `Error: 'threadId' or 'threadVersionId' is missing on AI_GENERATE_QUESTION`
                )
            } else if (data) {
                void aiGenerateQuestion({
                    variables: {
                        specs: {
                            threadId: currentThreadId,
                            threadVersionId: currentThreadVersionId,
                            parentGUID: data?.sectionId,
                            index: data?.index || 0,
                            topic: questionValues.topic,
                            intent: questionValues.intent.value,
                            format: questionValues.format.value,
                            responseStyle: questionValues?.responseStyle.value,
                        },
                    },
                })
            }
        },
        [data, currentThreadId, currentThreadVersionId]
    )

    const generateContinueWritingBlocks = useCallback(
        (continueWritingValues: Required<ContinueWritingFormValues>) => {
            if (!currentThreadId || !currentThreadVersionId) {
                console.error(
                    `Error: 'threadId' or 'threadVersionId' is missing on AI_CONTINUE_WRITING`
                )
            } else if (data) {
                void aiContinueWriting({
                    variables: {
                        specs: {
                            threadId: currentThreadId,
                            threadVersionId: currentThreadVersionId,
                            parentGUID: data?.sectionId,
                            index: data?.index || 0,
                            topic: continueWritingValues.topic,
                            verbosity: continueWritingValues.verbosity.value,
                        },
                    },
                })
            }
        },
        [data, currentThreadId, currentThreadVersionId]
    )

    // On mount save the thread
    useEffect(() => {
        // avoid auto-save
        dispatch(updateAutoSaveTimeout({ editing: undefined, timeoutID: undefined }))
        saveCurrentThread().then(() => setThreadSaved(true))
    }, [])

    // Only once the thread was saved, check if we need to generate the topic or just need to generate the blocks
    useEffect(() => {
        if (threadSaved) {
            if (isAQuestionType) {
                generateAiTopic()
            } else if (isContinueWriting) {
                generateWritingTopic()
            } else {
                throw new Error("unexpected case")
            }
        }
    }, [threadSaved, isAQuestionType, isContinueWriting])

    const [aiGenerateQuestionTopic, { data: aiGenerateQuestionTopicData }] = useMutation(
        AI_GENERATE_QUESTION_TOPIC
    )

    const [aiGenerateWritingTopic, { data: aiGenerateWritingTopicData }] =
        useMutation(AI_GENERATE_WRITING_TOPIC)

    const [
        aiGenerateQuestion,
        {
            loading: aiGenerateQuestionLoading,
            error: aiGenerateQuestionError,
            cancel: cancelAiGenerateQuestion,
        },
    ] = useLazySubscriptionWithOptions(AI_GENERATE_QUESTION, {
        onSubscriptionComplete: () => {
            setAiGenerateQuestionComplete(true)
        },
        onSubscriptionData: ({ subscriptionData }) => {
            if (aiGenerateQuestionComplete) setAiGenerateQuestionComplete(false)
            if (!aiGenerateQuestionCalled) setAiGenerateQuestionCalled(true)
            const blocks = compact(
                parseBlocksString(subscriptionData.data?.aiGenerateQuestion?.object)
            )
            setBlocksGenerated(blocks)
            blockGeneratedRef.current = blocks
        },
    })

    const [
        aiContinueWriting,
        {
            loading: aiContinueWritingLoading,
            error: aiContinueWritingError,
            cancel: cancelAiContinueWriting,
        },
    ] = useLazySubscriptionWithOptions(AI_CONTINUE_WRITING, {
        onSubscriptionComplete: () => {
            setAiContinueWritingComplete(true)
        },
        onSubscriptionData: ({ subscriptionData }) => {
            if (aiContinueWritingComplete) setAiContinueWritingComplete(false)
            if (!aiContinueWritingCalled) setAiContinueWritingCalled(true)
            const blocks = compact(
                parseBlocksString(subscriptionData.data?.aiContinueWriting?.object)
            )
            setBlocksGenerated(blocks)
            blockGeneratedRef.current = blocks
        },
    })

    useEffect(() => {
        if (savingLoading || aiGenerateQuestionLoading || aiContinueWritingLoading) setLoading(true)
    }, [savingLoading, aiGenerateQuestionLoading, aiContinueWritingLoading])

    useEffect(() => {
        if (savingError || aiGenerateQuestionError || aiContinueWritingError || blocksGenerated)
            setLoading(false)
    }, [savingError, aiGenerateQuestionError, aiContinueWritingError, blocksGenerated])

    // if all the question values are set and the generate question subscription wasn't called, then call subscription
    useEffect(() => {
        if (questionFormValues && !aiGenerateQuestionCalled)
            generateQuestionBlocks(questionFormValues)
    }, [questionFormValues, aiGenerateQuestionCalled])

    // if all the question values are set and the continue writing subscription wasn't called, then call subscription
    useEffect(() => {
        if (continueWritingFormValues && !aiContinueWritingCalled)
            generateContinueWritingBlocks(continueWritingFormValues)
    }, [continueWritingFormValues, aiContinueWritingCalled])

    const retry = () => {
        if (isAQuestionType) {
            const questionFormValuesToUse = questionFormValuesRef.current
            if (!questionFormValuesToUse) return
            if (!aiGenerateQuestionComplete) {
                cancelAiGenerateQuestion()
                // we need to wait for the cancel before calling `generateQuestionBlocks` again otherwise cancel will never occur
                setTimeout(() => generateQuestionBlocks(questionFormValuesToUse), 0)
            } else generateQuestionBlocks(questionFormValuesToUse)
        } else if (isContinueWriting) {
            const continueWritingFormValuesToUse = continueWritingFormValuesRef.current
            if (!continueWritingFormValuesToUse) return
            if (!aiContinueWritingComplete) {
                cancelAiContinueWriting()
                // we need to wait for the cancel before calling `generateContinueWritingBlocks` again otherwise cancel will never occur
                setTimeout(() => generateContinueWritingBlocks(continueWritingFormValuesToUse), 0)
            } else generateContinueWritingBlocks(continueWritingFormValuesToUse)
        } else {
            throw new Error("unexpected case")
        }
    }

    const onCancel = useCallback(() => {
        props.onCancel()
        if (isAQuestionType) cancelAiGenerateQuestion()
        else cancelAiContinueWriting()
    }, [isAQuestionType])

    const onSubmit = (blocks: Block[]) => {
        dispatch(
            addBlocks({
                blocks,
                blocksType: BlocksType.BLOCKS,
                branchId: data?.branchId,
                index: data?.index,
                sectionId: data?.sectionId,
            })
        )
        // close the popup
        onCancel()
    }

    return (
        <AiPopup
            onCancel={onCancel}
            parentHeight={parentHeight}
            parentWidth={parentWidth}
            position={"bottom"}
            styles={{ gap: 0 }}
        >
            {parentWidth && <StyledGhostIndicatorBar parentWidth={parentWidth} />}
            <>
                {showTitle && <AiPopupTitle title={`AI Assist: ${data?.title}`} />}
                <AiAssistPopupContent
                    blocks={blocksGenerated}
                    loading={loading}
                    error={aiGenerateQuestionError || aiContinueWritingError}
                    subscriptionComplete={aiContinueWritingComplete || aiGenerateQuestionComplete}
                    styles={{ marginBottom: "12px" }}
                />
                {isAQuestionType && (
                    <AiPopupQuestionForm
                        defaultIntent={QuestionIntent.PlayfulTrivia}
                        defaultTopic={aiGenerateQuestionTopicData?.aiGenerateQuestionTopic}
                        setValues={setQuestionFormValues}
                        onTopicFocused={() => setDisableKeyBinding(true)}
                        onTopicBlur={() => setDisableKeyBinding(false)}
                    />
                )}
                {isContinueWriting && (
                    <AiPopupContinueWritingForm
                        defaultTopic={aiGenerateWritingTopicData?.aiGenerateContinueWritingTopic}
                        setValues={setContinueWritingFormValues}
                        onTopicFocused={() => setDisableKeyBinding(true)}
                        onTopicBlur={() => setDisableKeyBinding(false)}
                    />
                )}
                <AiPopupFooter
                    retry={retry}
                    discard={onCancel}
                    // we use the ref here so the listeners can work properly
                    submit={() => onSubmit(blockGeneratedRef.current)}
                    loading={!(aiGenerateQuestionComplete || aiContinueWritingComplete)}
                    disableKeyBinding={disableKeyBinding}
                    buttonsAlignment={
                        isAQuestionType ? ButtonsAlignment.right : ButtonsAlignment.left
                    }
                />
            </>
        </AiPopup>
    )
}
export default AiCreateBlockPopup
