import { DatePickerProps, DatePicker as MuiDatePicker } from "@mui/x-date-pickers"
import { format } from "date-fns"
import { Maybe } from "graphql/jsutils/Maybe"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { timesForWholeDay, timezones } from "../../deliver/consts"
import { StyledDropdownContainer, StyledDropdownItem, StyledInput, StyledPopup } from "./styles"

export const DatePicker = <TInputDate, TDate>(
    props: Omit<DatePickerProps<TInputDate, TDate>, "renderInput" | "open" | "onClose">
) => {
    const [open, setOpen] = useState(false)

    const toggleOpen = () => setOpen(!open)

    return (
        <MuiDatePicker
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            renderInput={({ inputRef, inputProps }) => (
                <StyledInput
                    {...inputProps}
                    ref={inputRef}
                    style={{ width: 66 }}
                    onClick={toggleOpen}
                    open={open}
                    placeholder={"Date"}
                />
            )}
        />
    )
}

interface TimePickerProps {
    value: Maybe<string>
    onChange: (newValue: string) => void
}

export const TimePicker = ({ onChange, value }: TimePickerProps) => {
    const validateTime = useCallback((time: string) => {
        const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/
        return timeRegex.test(time)
    }, [])

    return (
        <DropdownPicker
            options={timesForWholeDay}
            value={value}
            onChange={onChange}
            validationFunction={validateTime}
            formatFunction={(value) => format(new Date(value), "hh:mm a")}
            placeholder={"Time"}
        />
    )
}

export const TimezonePicker = ({ onChange, value }: TimePickerProps) => {
    const validateTimezone = useCallback((timezone: string) => timezones.includes(timezone), [])

    return (
        <DropdownPicker
            options={timezones}
            value={value}
            onChange={onChange}
            validationFunction={validateTimezone}
            placeholder={"Timezone"}
            width={42}
        />
    )
}

interface DropdownPickerProps {
    options: string[]
    value: Maybe<string>
    onChange: (newValue: string) => void
    validationFunction?: (value: string) => boolean
    formatFunction?: (value: string) => string
    placeholder?: string
    width?: number
}

const DropdownPicker = ({
    options,
    value,
    onChange,
    validationFunction,
    formatFunction,
    placeholder,
    width = 66,
}: DropdownPickerProps) => {
    const [tempValue, setTempValue] = useState<Maybe<string>>()
    const [open, setOpen] = useState(false)
    const toggleOpen = () => setOpen(!open)
    const inputRef = useRef<HTMLInputElement>(null)

    const tempValueIsValid = useMemo(() => {
        if (!validationFunction || !tempValue) return true
        return validationFunction(tempValue)
    }, [tempValue, validationFunction])

    useEffect(() => {
        if (tempValue && tempValueIsValid) {
            onChange(tempValue)
        }
    }, [tempValue, tempValueIsValid])

    useEffect(() => {
        value && setTempValue(formatFunction ? formatFunction(value) : value)
    }, [value])

    useEffect(() => {
        if (!open && inputRef.current) inputRef.current.blur()
    }, [open, inputRef.current])

    const handleSelect = (time: string) => {
        onChange(time)
        toggleOpen()
    }

    return (
        <StyledPopup
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            on={"click"}
            trigger={
                <div>
                    <StyledInput
                        ref={inputRef}
                        value={tempValue || ""}
                        onChange={(e) => setTempValue(e.target.value)}
                        style={{ width }}
                        onClick={toggleOpen}
                        open={open}
                        placeholder={placeholder}
                        error={!tempValueIsValid}
                    />
                </div>
            }
        >
            <StyledDropdownContainer>
                {options.map((option, index) => (
                    <StyledDropdownItem
                        key={`dropdown-item-${index}`}
                        onClick={() => handleSelect(option)}
                    >
                        {option}
                    </StyledDropdownItem>
                ))}
            </StyledDropdownContainer>
        </StyledPopup>
    )
}
