import { StyledBox, StyledHr } from "../../../styles/styledcomponents"
import { Block, BlockType } from "../../../types"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { StyledAccordion } from "../../../creator/components/Accordion/styles"
import { useEffect, useRef, useState } from "react"
import { useTheme } from "styled-components"
import { getPresentVariableKeys, isHTML, replaceVariables } from "../../../utils/utils"
import parse from "html-react-parser"
import classNames from "classnames"
import useLayout from "../../../hooks/layout.hook"
import DividerPreview from "../../Divider/index"
import ImageCarousel from "../../Carousel/ImageCarousel"
import { InstructorAvatar } from "../../InstructorAvatar"
import TextPreview from "../TextPreview"
import VideoPreview from "../VideoPreview"
import ImagePreview from "../ImagePreview"
import { useDynamicVariableValues, useStaticVariableValues } from "../../../hooks/variables.hook"

interface AccordionInterface {
    bubble: Block
    insidePane?: boolean
}

const AccordionPreview = ({ bubble, insidePane }: AccordionInterface) => {
    const [open, setOpen] = useState<boolean>(false)
    const [header, setHeader] = useState("")
    const theme = useTheme()
    const { isMobile } = useLayout()
    const { getDynamicVariableValues } = useDynamicVariableValues()
    const { staticVariableValues } = useStaticVariableValues()

    const renderAccordionContent = (block: Block) => {
        switch (block.type) {
            case BlockType.TEXT:
                return <TextPreview key={block.id} block={block} />
            case BlockType.IMAGE:
                return <ImagePreview key={block.id} block={block} isMobile={isMobile} />
            case BlockType.VIDEO:
                return <VideoPreview key={block.id} block={block} />
            case BlockType.DIVIDER:
                return <DividerPreview key={block.id} bubble={block} />
            case BlockType.IMAGE_CAROUSEL:
                return (
                    <div style={{ margin: "3px 12px" }} className={"left-animation"}>
                        <ImageCarousel
                            id={block.id}
                            imagesList={block.choices?.filter((images) => images !== undefined)}
                        />
                    </div>
                )
            case BlockType.INSTRUCTOR_AVATAR:
                return <InstructorAvatar preview />
        }
    }

    const replaceVariablesOnText = async () => {
        const variableKeys = getPresentVariableKeys(bubble?.value)
        const dynamicVariableValues = await getDynamicVariableValues(variableKeys)
        const allVariableValues = [...dynamicVariableValues, ...staticVariableValues]
        setHeader(replaceVariables(bubble?.value || "", allVariableValues))
    }

    useEffect(() => {
        replaceVariablesOnText()
    }, [bubble?.value, staticVariableValues])

    return (
        <StyledAccordion
            id={`block-${bubble.id}`}
            className={classNames("bubble left-animation")}
            css={{
                width: "inherit !important",
                backgroundColor: bubble.color
                    ? "transparent !important"
                    : theme.colors.thread.instructor.bubble,
                transition: "background-color 0.2s ease-out",
                padding: "0 !important",
                // note: yeah this is far from being optimal but the container has the `bubble`
                // class-name which doesn't allow us to modify the font-color. We need to migrate
                // the entire component to style-components and stop using classnames.
                "p, li, h4, h6": {
                    color: theme.colors.thread?.instructor?.font,
                },
            }}
        >
            <StyledBox
                css={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: insidePane ? "12px 0" : "12px 6px 12px 18px",
                    cursor: "pointer",
                }}
                onClick={() => setOpen(!open)}
            >
                <StyledBox css={{ flex: 1 }}>
                    {isHTML(bubble.value!) ? parse(header) : <p>{parse(header)}</p>}
                </StyledBox>
                <KeyboardArrowDownIcon
                    sx={{
                        margin: "0 5px 0 5px",
                        transition: "transform 0.2s ease-out",
                        transform: open ? "rotate(180deg)" : "rotate(0deg)",
                        color: theme.colors.thread?.instructor?.font,
                    }}
                />
            </StyledBox>
            <StyledBox
                css={{
                    maxHeight: open ? "inherit" : 0,
                    overflowY: "hidden",
                    padding: open ? "0px 12px 9px 24px" : "0px 12px 0px 24px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {bubble?.branches &&
                    bubble?.branches[0]?.objects?.map((block) => renderAccordionContent(block))}
            </StyledBox>
            {(bubble.color || insidePane) && (
                <StyledHr color={theme.colors.thread?.instructor?.font} css={{ margin: 0 }} />
            )}
        </StyledAccordion>
    )
}

export default AccordionPreview
