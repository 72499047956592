import { Close } from "@mui/icons-material"
import styled from "styled-components"
import { StyledThreadRow } from "../../../common/components/ThreadRow/styles"
import { StyledBox } from "../../../styles/styledcomponents"

export const StyledCloseIcon = styled(Close)`
    color: ${({ theme }) => theme.headlandsGray1};

    &:hover {
        cursor: pointer;
    }
`

export const StyledThreadsContainer = styled(StyledBox)`
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    max-height: 474px;
    overflow-y: scroll;

    ${StyledThreadRow} {
        max-width: none;
    }
`
