import { IconProps } from "../../types"

export const CatalogIcon = ({ width = 16, height = 16, color = "#888888", onClick }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M11.9585 14.3968C12.0705 15.5738 12.5891 16.1264 13.5616 15.9757L14.6991 15.8178C15.6716 15.6671 16.09 15.0283 15.9839 13.8513L15.035 3.39465C14.929 2.21765 14.3985 1.65785 13.4319 1.81574L12.2944 1.97364C12.041 2.00952 11.8288 2.08129 11.652 2.18894V1.78704C11.652 0.602856 11.1687 0 10.1962 0H8.61671C7.63834 0 7.15505 0.602856 7.15505 1.78704V4.60036C7.05486 4.58601 6.94877 4.57883 6.83089 4.57883H4.10797C3.99009 4.57883 3.87811 4.58601 3.77791 4.60036V3.81809C3.77791 2.62673 3.29462 2.02387 2.32215 2.02387H1.46166C0.48329 2.02387 0 2.62673 0 3.81809V14.1671C0 15.3513 0.48329 15.9541 1.46166 15.9541H10.1962C11.1687 15.9541 11.652 15.3513 11.652 14.1671V11.0524L11.9585 14.3968ZM8.78763 1.37078H10.0253C10.3613 1.37078 10.5263 1.58609 10.5263 1.97364V13.9733C10.5263 14.3681 10.3613 14.5834 10.0253 14.5834H8.28666V1.97364C8.28666 1.58609 8.45168 1.37078 8.78763 1.37078ZM13.0606 14.0595L12.1412 3.97598C12.1058 3.58843 12.2532 3.37312 12.5832 3.31571L13.3789 3.20088C13.7089 3.14346 13.8975 3.34441 13.9329 3.72479L14.8523 13.8083C14.8877 14.203 14.7403 14.4183 14.4103 14.4757L13.6087 14.5905C13.2787 14.648 13.096 14.4542 13.0606 14.0595ZM1.62668 14.5834C1.29663 14.5834 1.12571 14.3681 1.12571 13.9733V3.99751C1.12571 3.60996 1.29663 3.40183 1.62668 3.40183H2.14534C2.48718 3.40183 2.64631 3.60996 2.64631 3.99751V14.5834H1.62668ZM4.27889 5.94961H6.65408C6.99592 5.94961 7.15505 6.15774 7.15505 6.54529V14.5834H3.77791V6.54529C3.77791 6.15774 3.94294 5.94961 4.27889 5.94961ZM4.6443 7.75818H6.31224C6.52442 7.75818 6.68944 7.55005 6.68944 7.29169C6.68944 7.03332 6.52442 6.83237 6.31224 6.83237H4.6443C4.42034 6.83237 4.2612 7.03332 4.2612 7.29169C4.2612 7.55005 4.42623 7.75818 4.6443 7.75818ZM4.6443 13.7078H6.31224C6.52442 13.7078 6.68944 13.4997 6.68944 13.2341C6.68944 12.9829 6.52442 12.7748 6.31224 12.7748H4.6443C4.42623 12.7748 4.2612 12.9829 4.2612 13.2341C4.2612 13.4997 4.42034 13.7078 4.6443 13.7078Z"
                fill={color}
            />
        </svg>
    )
}
