import { useEnrolledPrograms } from "../hooks/enrolledProgram.hook"
import { compact, isEmpty, orderBy } from "lodash"
import { useQuery } from "@apollo/client"
import { ABOUT_ME, PROGRAM_PROGRESS } from "../apollo/queries"
import { StyledMobileLeaderboardScreen, StyledProgramSelectContainer } from "./styles"
import TopBar from "../components/TopBar"
import UserRow from "../components/UserRow"
import Select from "../components/Select/Select"
import { useEffect, useMemo, useState } from "react"
import { EnrolledProgram } from "../apollo/generated/graphql"
import { StyledH4 } from "../styles/styledcomponents"

const MobileLeaderboardScreen = () => {
    const [selectedProgram, setSelectedProgram] = useState<EnrolledProgram>()
    const enrolledPrograms = useEnrolledPrograms()
    const programsWithLeaderboard: EnrolledProgram[] | undefined =
        enrolledPrograms.enrolledPrograms?.filter((program) => program.leaderboard)

    // Set the selected program for the first time
    useEffect(() => {
        if (!selectedProgram && programsWithLeaderboard && !isEmpty(programsWithLeaderboard))
            setSelectedProgram(programsWithLeaderboard[0])
    }, [programsWithLeaderboard, selectedProgram])

    const { data: aboutMeData } = useQuery(ABOUT_ME)

    const { data: programProgressData } = useQuery(PROGRAM_PROGRESS, {
        skip: !selectedProgram,
        variables: {
            input: { programGUID: selectedProgram?.guid! },
        },
    })

    const leaderboardItems = useMemo(
        () => compact(programProgressData?.programProgress),
        [programProgressData?.programProgress]
    )

    return (
        <StyledMobileLeaderboardScreen>
            <TopBar styles={{ justifyContent: "center" }}>
                <ProgramSelect
                    value={selectedProgram}
                    programs={programsWithLeaderboard}
                    onChange={setSelectedProgram}
                />
            </TopBar>
            {aboutMeData &&
                !!leaderboardItems.length &&
                orderBy(leaderboardItems, ({ totalScore }) => totalScore || "", "desc")?.map(
                    ({ person, totalScore }, index) => (
                        <UserRow
                            key={`user-${person.id}`}
                            person={person}
                            index={index}
                            isCurrentUser={person.id === aboutMeData.aboutMe.id}
                            score={totalScore}
                        />
                    )
                )}
        </StyledMobileLeaderboardScreen>
    )
}

interface ProgramSelectProps {
    value?: EnrolledProgram
    programs?: EnrolledProgram[]
    menuAlignment?: "right"
    onChange: (program: EnrolledProgram) => void
}

export const ProgramSelect = ({ value, programs, menuAlignment, onChange }: ProgramSelectProps) => (
    <StyledProgramSelectContainer>
        {programs && programs?.length > 1 ? (
            <Select
                value={value ? { label: value.name, value } : undefined}
                options={programs.map((program) => ({ label: program.name, value: program }))}
                onChange={(newValue) => newValue && onChange(newValue.value)}
                isSearchable={false}
                isMulti={false}
                styles={{
                    menu: () =>
                        menuAlignment === "right"
                            ? {
                                  right: 0,
                              }
                            : {},
                }}
            />
        ) : (
            value && <StyledH4 useNewDesign>{value.name}</StyledH4>
        )}
    </StyledProgramSelectContainer>
)

export default MobileLeaderboardScreen
