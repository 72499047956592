import { Block, BlocksType, Thread } from "../../../types"
import { useQuery } from "@apollo/client"
import { GET_CREATOR_THREADS } from "../../../apollo/queries"
import { BrickIcon } from "../../../assets/icons/BrickIcon"
import { useMemo } from "react"
import { format } from "date-fns"
import Select from "../../../components/Select/Select"
import { useDispatch } from "react-redux"
import { updateBlock } from "../../../redux/blocks"
import { OverlayLoader } from "../../../components/Loader"
import { StyledBrick, StyledIconContainer, StyledThreadContainer } from "./styles"
import { CreatorToolThreadBrief } from "../../../apollo/generated/graphql"

export interface BrickProps {
    block: Block
}

const Brick = ({ block }: BrickProps) => {
    const dispatch = useDispatch()

    // todo: refactor this query to use a new query with only the data needed instead of GET_CREATOR_THREADS
    const { data: { getAllCreatorToolThreads } = {}, loading } = useQuery(GET_CREATOR_THREADS)

    const thread: CreatorToolThreadBrief | any = useMemo(
        () => getAllCreatorToolThreads?.find((thr) => thr.guid === block.thread),
        [block, getAllCreatorToolThreads]
    )

    const handleThreadChange = (selectedThread: any) => {
        dispatch(
            updateBlock({
                blocksType: BlocksType.BLOCKS,
                id: block.id,
                block: { ...block, thread: selectedThread.value, objects: [] },
            })
        )
    }

    return (
        <StyledBrick id={`block-${block.id}`}>
            <StyledIconContainer>
                <BrickIcon />
            </StyledIconContainer>
            {loading ? (
                <OverlayLoader size={32} />
            ) : thread ? (
                <StyledThreadContainer>
                    <h5>{thread.threadVersion?.title}</h5>
                    <p>
                        by {thread.instructor?.firstName} {thread.instructor?.lastName}
                    </p>
                    <p>
                        Published on{" "}
                        {thread.threadVersion?.createdAt &&
                            format(
                                new Date(thread.threadVersion?.createdAt || Date.now()),
                                "MMMM y"
                            )}
                    </p>
                </StyledThreadContainer>
            ) : (
                <Select
                    options={getAllCreatorToolThreads?.map((thr) => ({
                        value: thr.guid,
                        label: thr.threadVersion?.title,
                    }))}
                    onChange={handleThreadChange}
                />
            )}
        </StyledBrick>
    )
}

export default Brick
