import styled, { css } from "styled-components"
import { StyledBox } from "../../../styles/styledcomponents"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"

export const StyledVisibilityOffIcon = styled(VisibilityOffOutlinedIcon)`
    font-size: 48px !important;
    color: ${({ theme }) => theme.headlandsGray10};
`

export const StyledHidableBlockOverlay = styled(StyledBox)<{ hidden?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.headlandsGray4};
    opacity: 0.6;
`
export const StyledHidableBlockWrapper = styled(StyledBox)<{ hidden?: boolean }>`
    display: flex;
    position: relative;

    ${({ hidden }) =>
        hidden &&
        css`
            .StyledHidableBlockWrapper {
                ${StyledHidableBlockOverlay} {
                    display: none !important;
                }
            }
        `}
`
