import { useMutation, useQuery } from "@apollo/client"
import { ABOUT_ME } from "../apollo/queries"
import { BlockType } from "../types"
import { clearThreads, setThread } from "../redux/threads"
import { useDispatch, useSelector } from "react-redux"
import { parseBlocksString, preFetchImages } from "./utils"
import { useEffect, useMemo, useState } from "react"
import useLayout from "../hooks/layout.hook"
import ThreadPreview from "../components/ThreadPreview/ThreadPreview"
import { useCurrentThread } from "../hooks/currentThread.hook"
import { StyledBox } from "../styles/styledcomponents"
import { ThreadSkeleton } from "../common/components/LoaderSkeleton/ThreadSkeleton"
import ThreadTopBar from "./components/ThreadTopBar"
import { START_THREAD } from "../apollo/mutations"
import useDeviceId from "../hooks/deviceId.hook"
import { sessionDataVar } from "../apollo/cache-store"
import { useTheme } from "styled-components"
import PanePreview from "../components/ThreadPreview/PanePreview/PanePreview"
import usePrevious from "../hooks/previous.hook"
import { SidebarType, setShowMobileBottomBar } from "../redux/layout"
import { useSidebars } from "../hooks/sidebars.hook"
import { ThreadStatus } from "../apollo/generated/graphql"
import { useShouldShowScoreHeader } from "../hooks/shouldShowScoreHeader"
import { RootState } from "../redux/store"

interface ThreadProps {
    programGuid: string
    threadGuid: string
}

const Thread = ({ programGuid, threadGuid }: ThreadProps) => {
    const { openSidebars } = useSidebars()
    const dispatch = useDispatch()
    const thread = useCurrentThread()
    const prevThread = usePrevious(thread)
    const { isMobile } = useLayout()
    const [threadReady, setThreadReady] = useState(false)
    const showMobileBottomBar = useSelector(
        (state: RootState) => state.layoutReducer.showMobileBottomBar
    )

    const theme = useTheme()
    const { data: aboutMeData } = useQuery(ABOUT_ME)

    const shouldShowScoreHeader = useShouldShowScoreHeader()

    /*
     * On mount try to open both sidebars the library and social, the method itself will handle
     * if there is enough room for them.
     * Only if device is not mobile.
     * */
    useEffect(() => {
        !isMobile && openSidebars([SidebarType.social, SidebarType.library], false)
        // openSidebars dependency is not needed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile])

    const [startThread, { loading: startThreadLoading, error: startThreadError }] = useMutation(
        START_THREAD,
        {
            onCompleted: async ({ startThread }) => {
                /*
                 * We wait for image pre fetching to finish before calling `setThread`,
                 * this way we don't remove the skeleton loader until all images are ready
                 * */
                await preFetchImages(parseBlocksString(startThread?.thread?.blocks))
                if (startThread) {
                    sessionDataVar({
                        id: startThread.sessionID,
                        timestamp: Date.now(),
                    })
                }
                if (startThread?.thread?.guid && startThread?.thread) {
                    dispatch(setThread({ guid: startThread?.thread?.guid, thread: startThread }))
                }
            },
        }
    )

    const { deviceId } = useDeviceId()

    useEffect(() => {
        if (programGuid && threadGuid && deviceId) {
            if (!thread || (prevThread && thread.thread?.guid !== prevThread.thread?.guid)) {
                setThreadReady(false)
                dispatch(clearThreads())
                void startThread({
                    variables: {
                        programGUID: programGuid,
                        threadGUID: threadGuid,
                        context: {
                            clientTime: new Date(),
                            deviceId,
                            userAgent: navigator.userAgent,
                            viewportWidth: window.innerWidth,
                            viewportHeight: window.innerHeight,
                        },
                    },
                })
            }
        }
    }, [programGuid, threadGuid, deviceId, thread?.thread?.guid])

    useEffect(() => {
        if (
            isMobile &&
            showMobileBottomBar &&
            thread?.status &&
            [ThreadStatus.InProgress, ThreadStatus.Unlocked].includes(thread?.status)
        ) {
            dispatch(setShowMobileBottomBar(false))
        } else if (isMobile && !showMobileBottomBar && thread?.status === ThreadStatus.Completed) {
            dispatch(setShowMobileBottomBar(true))
        }
    }, [thread?.status, isMobile, showMobileBottomBar])

    useEffect(() => {
        return () => {
            dispatch(setShowMobileBottomBar(true))
        }
    }, [])

    const getThreadContent = () => {
        switch (thread.status) {
            case ThreadStatus.Unlocked:
            case ThreadStatus.InProgress:
            case ThreadStatus.Completed:
                return (
                    thread.thread?.blocks &&
                    thread.thread.blocks.length > 0 && (
                        <ThreadPreview
                            isMobile={isMobile}
                            setThreadReady={setThreadReady}
                            isScoreHeaderShown={shouldShowScoreHeader}
                        />
                    )
                )
            case ThreadStatus.Expired:
            case ThreadStatus.Locked:
                return (
                    <StyledBox
                        css={{
                            width: isMobile ? "auto" : 568,
                            alignSelf: "center",
                            marginTop: isMobile ? "69px" : "69px",
                        }}
                    >
                        {thread.thread?.preview && <PanePreview type={BlockType.PREVIEW} />}
                    </StyledBox>
                )
            default:
                return <></>
        }
    }

    const showLoader = useMemo(() => {
        /*
         * If the thread is loading, or it hasn't been fetched yet,
         * or the thread is not defined, or if it is in fact defined but
         * it's still not ready to be shown, then we show the loader.
         */
        return (
            !startThreadError &&
            (startThreadLoading ||
                !thread ||
                (thread.status &&
                    [
                        ThreadStatus.Unlocked,
                        ThreadStatus.InProgress,
                        ThreadStatus.Completed,
                    ].includes(thread.status) &&
                    !threadReady))
        )
    }, [startThreadLoading, threadReady, thread, startThreadError])

    return (
        <>
            {showLoader && (
                <StyledBox
                    css={{
                        position: isMobile ? "fixed" : "absolute",
                        backgroundColor: theme.headlandsWhite,
                        width: "100%",
                        height: `calc(100vh - ${isMobile ? "65px" : "69px"})`,
                        zIndex: 99,
                        top: isMobile ? "65px" : "69px",
                        bottom: 0,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <StyledBox css={{ width: "100%", maxWidth: "580px" }}>
                        <ThreadSkeleton />
                    </StyledBox>
                </StyledBox>
            )}
            {thread && aboutMeData?.aboutMe && (
                <>
                    <ThreadTopBar thread={thread} showScoreSubHeader={shouldShowScoreHeader} />
                    {getThreadContent()}
                </>
            )}
        </>
    )
}

export default Thread
