import { Block, QuestionChoice } from "../../../types"
import { QuestionPreviewProps } from "../ThreadPreview"
import { useEffect, useMemo, useState } from "react"
import { StyledRow, StyledSpan } from "../../../styles/styledcomponents"
import IconButton from "../../IconButton"
import SendIcon from "@mui/icons-material/Send"
import { StyledChoicePollOption, StyledPollClarificationRow } from "./styles"
import { isNil } from "lodash"
import { Maybe } from "graphql/jsutils/Maybe"
import { ChoiceData, UserAnswer, VariableType } from "../../../apollo/generated/graphql"
import { CorrectnessCheck } from "../../../creator/components/QuestionBlock"
import { useCurrentThreadField } from "../../../hooks/currentThread.hook"
import useTimeToAnswer from "../../../hooks/timeToAnswer.hook"
import { blockIsScorable, getVariableType } from "../../../utils/utils"
import { StyledChoiceContainer } from "../ChoicePreview/styles"

type ChoicePollPreviewProps = Omit<QuestionPreviewProps, "answer"> & {
    answer?: Maybe<UserAnswer>
    isMultipleSelection?: boolean
    threadId?: number
    customChoicesSelector?: (block: Block) => Maybe<QuestionChoice[]>
    choiceData?: Maybe<ChoiceData[]>
    score?: Maybe<number>
    /*
     * We use this component for both (new choice poll question component and old question poll component), on the new poll question choice
     * the timer logic is done inside the hoc while on the older component the timer logic must be done inside ChoicePollPreview.
     * That's why we receive a prop wether we has to use the timer or not
     */
    avoidTimer?: boolean
}

export const ChoicePollPreview = ({
    bubble,
    handleAnswer,
    answer,
    isMultipleSelection,
    readonly,
    customChoicesSelector,
    score,
    avoidTimer,
    ...rest
}: ChoicePollPreviewProps) => {
    const [selectedOptions, setSelectedOptions] = useState<QuestionChoice[]>([])
    /*
     * These choices are from the block data (we only use it for rendering the possible choices to choose).
     * To know which one is correct we will use `choiceData` instead because is coming from the back-end
     */
    const choices = customChoicesSelector ? customChoicesSelector(bubble) : bubble?.choices
    const choiceData = rest.choiceData ?? answer?.systemResponse?.choiceData

    const threadHasScore = useCurrentThreadField("hasScore")

    const { startTimer } = useTimeToAnswer()

    // Start the timer under the right conditions
    useEffect(() => {
        if (threadHasScore && blockIsScorable(bubble) && !answer && !avoidTimer) {
            startTimer()
        }
    }, [bubble, answer, startTimer, threadHasScore, avoidTimer])

    type Percentages = {
        [key: string]: number
    }

    const percentages: Percentages = useMemo(() => {
        if (choiceData && choices) {
            const calculatePercentage = (choiceId: string): number => {
                const choiceDataFound = choiceData.find((choice) => choice.id === choiceId)
                if (
                    choiceDataFound &&
                    !isNil(choiceDataFound.count) &&
                    !isNil(choiceDataFound.total)
                )
                    return Math.round((choiceDataFound.count / choiceDataFound.total) * 100)
                return 0
            }

            let calculatedPercentages: { [key: string]: number } = {}
            choices?.forEach(({ id }) => (calculatedPercentages[id] = calculatePercentage(id)))
            return calculatedPercentages
        }
        return {}
    }, [choiceData, choices])

    const sendAnswer = (options: QuestionChoice[]) => {
        if (readonly) return
        handleAnswer(options, true)
    }

    const onChoiceSelection = (option: QuestionChoice) => {
        if (readonly) return
        if (!isMultipleSelection) {
            sendAnswer([option])
        } else {
            const optionIsAlreadySelected = selectedOptions.find(
                (optionSelected) => optionSelected.id === option.id
            )
            if (optionIsAlreadySelected) {
                // remove the option form the selected ones
                setSelectedOptions(
                    selectedOptions.filter((optionSelected) => optionSelected.id !== option.id)
                )
            } else {
                // add the option to the selected ones
                setSelectedOptions([...selectedOptions, option])
            }
        }
    }

    /*
     * Check if the option is selected or not.
     * A selected option is the one that has already been answered.
     * */
    const isOptionSelected = (option: QuestionChoice): boolean => {
        const variableType = getVariableType(bubble.type, isMultipleSelection)
        if (variableType === VariableType.Ref) {
            return !!(answer?.value?.valueID === option.id)
        } else if (variableType === VariableType.RefSet) {
            return !!answer?.value?.valueIDs?.some((valueID) => valueID === option.id)
        }
        return false
    }

    /*
     * Check if the option is active or not.
     * An active option is the one that has been selected but hasn't been answered yet (only for multiple-choice)
     * */
    const isOptionActive = (option: QuestionChoice): boolean => {
        if (!isMultipleSelection || isOptionSelected(option)) return false
        return selectedOptions.some((optionSelected) => optionSelected.id === option.id)
    }

    const isOptionCorrect = (option: QuestionChoice): boolean => {
        return !!choiceData?.find((choice) => choice.id === option.id)?.correct
    }

    const getOptionScore = (option: QuestionChoice): number | undefined => {
        if (answer?.value?.valueID === option.id && isOptionCorrect(option) && score) return score
        return undefined
    }

    const showAnswerCorrectness = useMemo(
        () => !!answer && choiceData?.some((choice) => choice.correct),
        [answer, choiceData]
    )

    return (
        <StyledChoiceContainer>
            {choices!.map((option) => (
                <StyledRow css={{ alignItems: "center", gap: "12px" }}>
                    {/*
                     * We use this Box for "reserving" the horizontal space for the
                     * CorrectnessCheck, so that all choices have the same width
                     * eventhough only one of them will display the check
                     * */}
                    <StyledRow css={{ width: "30px", justifyContent: "flex-end" }}>
                        {showAnswerCorrectness &&
                            (answer?.value?.valueID === option.id || isOptionCorrect(option)) && (
                                <CorrectnessCheck
                                    correct={isOptionCorrect(option)}
                                    size={28}
                                    animated={answer?.value?.valueID === option.id}
                                    score={getOptionScore(option)}
                                    css={{
                                        opacity: answer?.value?.valueID === option.id ? 1 : 0.4,
                                    }}
                                />
                            )}
                    </StyledRow>
                    <StyledChoicePollOption
                        key={option.id}
                        data-testid={"radio-group-option"}
                        onClick={() => onChoiceSelection(option)}
                        disabled={!!answer}
                        as={"button"}
                        multiple={!!isMultipleSelection}
                        percentage={!!answer ? percentages[option.id] : undefined}
                        selected={isOptionSelected(option)}
                        active={isOptionActive(option)}
                        readonly={readonly}
                    >
                        <StyledSpan>{option.text}</StyledSpan>
                        <div id={"choice-poll-space"} />
                        {/*<StyledSpan css={{ position: "absolute", top: "calc(50% - 8px)", right: 12 }}>*/}
                        {/*    {percentages[option.id] !== undefined ? `${percentages[option.id]}%` : ""}*/}
                        {/*</StyledSpan>*/}
                    </StyledChoicePollOption>
                </StyledRow>
            ))}
            {(isMultipleSelection || !!answer) && !readonly && (
                <StyledPollClarificationRow answered={!!answer}>
                    {!answer && isMultipleSelection && (
                        <StyledSpan>{`CHOOSE UP TO ${choices?.length}`}</StyledSpan>
                    )}

                    {(isMultipleSelection || !!answer) && (
                        <IconButton
                            data-testid={"submit"}
                            onClick={() => sendAnswer(selectedOptions)}
                            disabled={!selectedOptions.length || !!answer}
                            transparent={!!answer}
                            icon={<SendIcon fontSize="small" />}
                        />
                    )}
                </StyledPollClarificationRow>
            )}
        </StyledChoiceContainer>
    )
}
