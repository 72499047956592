import { useTheme } from "styled-components"
import { SparkleIcon } from "../../../../assets/icons/SparklesIcon"
import { StyledBox } from "../../../../styles/styledcomponents"
import { StyledTitle } from "../styles"

interface AiPopupTitleProps {
    title: string
}

const AiPopupTitle = ({ title }: AiPopupTitleProps) => {
    const theme = useTheme()
    return (
        <StyledBox css={{ display: "flex", alignItems: "center", gap: "6px", padding: "0 12px" }}>
            <SparkleIcon color={theme.headlandsAiColor} />
            <StyledTitle>{`${title}`}</StyledTitle>
        </StyledBox>
    )
}

export default AiPopupTitle
