import { useQuery } from "@apollo/client"
import { GET_PROGRAM } from "../apollo/queries"
import { useParams } from "react-router"
import { Program } from "../apollo/generated/graphql"

export const useSelectedProgramGuid = (): string => {
    const { programGuid } = useParams<{ programGuid: string }>()
    return programGuid
}

export const useSelectedDeliverProgram = (): { program: Program; loading: boolean } => {
    const programGuid = useSelectedProgramGuid()
    const { data, loading } = useQuery(GET_PROGRAM, {
        skip: !programGuid,
        variables: { guid: programGuid },
        fetchPolicy: "cache-and-network",
    })
    // @ts-ignore
    return { program: data?.program[0], loading }
}
