import { useTheme } from "styled-components"
import { IconProps } from "../../types"

export const SaveOutlineIcon = ({ width = 16, height = 16, color }: IconProps) => {
    const theme = useTheme()

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#save_outline_icon_path)">
                <path
                    d="M11.2714 2.52145C11.1777 2.42768 11.0505 2.375 10.9179 2.375H3.625C2.93125 2.375 2.375 2.9375 2.375 3.625V12.375C2.375 13.0625 2.93125 13.625 3.625 13.625H12.375C13.0625 13.625 13.625 13.0625 13.625 12.375V5.08211C13.625 4.9495 13.5723 4.82232 13.4786 4.72855L11.2714 2.52145ZM12.375 11.875C12.375 12.1511 12.1511 12.375 11.875 12.375H4.125C3.84886 12.375 3.625 12.1511 3.625 11.875V4.125C3.625 3.84886 3.84886 3.625 4.125 3.625H10.3991C10.5318 3.625 10.6589 3.67768 10.7527 3.77145L12.2286 5.2473C12.3223 5.34107 12.375 5.46825 12.375 5.60086V11.875ZM8 8C6.9625 8 6.125 8.8375 6.125 9.875C6.125 10.9125 6.9625 11.75 8 11.75C9.0375 11.75 9.875 10.9125 9.875 9.875C9.875 8.8375 9.0375 8 8 8ZM4.25 4.75C4.25 4.47386 4.47386 4.25 4.75 4.25H9.375C9.65114 4.25 9.875 4.47386 9.875 4.75V6.25C9.875 6.52614 9.65114 6.75 9.375 6.75H4.75C4.47386 6.75 4.25 6.52614 4.25 6.25V4.75Z"
                    fill={color ?? theme.colors.base.uiBgIcon}
                />
            </g>
            <defs>
                <clipPath id="save_outline_icon_path">
                    <rect
                        width="15"
                        height="15"
                        fill={color ?? theme.colors.base.uiBgIcon}
                        transform="translate(0.5 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
