import { ChangeEvent, FormEvent, useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import { CHECK_EMAIL } from "../apollo/queries"
import { LOGIN, SEND_CODE, SIGN_UP } from "../apollo/mutations"
import { useHistory } from "react-router"
import { useFormik } from "formik"
import { times } from "lodash"
import CodeContent from "./CodeContent"
import FirstNameContent from "./FirstNameContent"
import EmailContent from "./EmailContent"
import withResponsiveAuthScreen from "./responsiveAuthScreen.hoc"

enum AuthContent {
    email,
    firstName,
    code,
}

export const CODE_DIGITS_LENGTH = 6
export const EMPTY_CODE = times(CODE_DIGITS_LENGTH).map(() => "")
// todo: in the future we must type this error
export const NEW_CODE_MUST_BE_REQUESTED_ERROR = "New code must be requested"

export const Auth = () => {
    const history = useHistory()
    const [currentContent, setCurrentContent] = useState<AuthContent>(AuthContent.email)
    const [checkEmail, { loading: checkEmailLoading }] = useLazyQuery(CHECK_EMAIL)
    const [sendCode] = useMutation(SEND_CODE)
    // we add this mutation so we can keep track when a code was normally sent or if was resent
    const [resendCode, { called: codeResent, reset: resetResendCode }] = useMutation(SEND_CODE)
    const [signUp] = useMutation(SIGN_UP)
    const [login, { loading: loginLoading, error: loginError, reset: resetLoginStatus }] =
        useMutation(LOGIN, {
            onError: (error) => {
                // reset all inputs from code and focus the first one
                formik.setFieldValue("code", EMPTY_CODE)
                document.getElementById("code.0")?.focus()
                // if a new code must be requested we requested it
                if (error.message === NEW_CODE_MUST_BE_REQUESTED_ERROR) {
                    void sendCode({ variables: { email: formik.values.email } })
                }
            },
        })

    const handleSuccessfulLogin = (jwt: string) => {
        localStorage.setItem("token", jwt)
        const redirectUrl = localStorage.getItem("redirectUrl")
        if (redirectUrl) {
            history.push(redirectUrl)
            localStorage.removeItem("redirectUrl")
        } else {
            history.push("/")
        }
    }

    const formik = useFormik({
        initialValues: { email: "", firstName: "", code: EMPTY_CODE },
        onSubmit: ({ email, firstName, code }) => {
            switch (currentContent) {
                case AuthContent.email:
                    checkEmail({ variables: { email } }).then(({ data }) => {
                        if (data?.checkEmail) {
                            void sendCode({ variables: { email } })
                            setCurrentContent(AuthContent.code)
                        } else setCurrentContent(AuthContent.firstName)
                    })
                    break
                case AuthContent.firstName:
                    void signUp({ variables: { email, firstName } }).then(({ data }) => {
                        if (data?.signUp.jwt) handleSuccessfulLogin(data.signUp.jwt)
                    })
                    break
                case AuthContent.code:
                    void login({ variables: { email, code: code.join("") } }).then(({ data }) => {
                        if (data?.authenticate.jwt) handleSuccessfulLogin(data.authenticate.jwt)
                    })
            }
        },
    })

    const onResendCode = () => {
        void resendCode({ variables: { email: formik.values.email } })
    }

    const changeEmail = () => {
        formik.setFieldValue("email", "")
        resetLoginStatus()
        setCurrentContent(AuthContent.email)
    }

    return (
        <>
            {currentContent === AuthContent.email && (
                <EmailContent
                    handleSubmit={formik.handleSubmit}
                    handleChange={formik.handleChange}
                    email={formik.values.email}
                    loading={checkEmailLoading}
                />
            )}
            {currentContent === AuthContent.firstName && (
                <FirstNameContent
                    handleSubmit={formik.handleSubmit}
                    handleChange={formik.handleChange}
                    email={formik.values.email}
                    changeEmail={changeEmail}
                    firstName={formik.values.firstName}
                />
            )}
            {currentContent === AuthContent.code && (
                <CodeContent
                    code={formik.values.code}
                    email={formik.values.email}
                    changeEmail={changeEmail}
                    setFieldValue={(field, value) => formik.setFieldValue(field, value)}
                    resendCode={onResendCode}
                    codeResent={codeResent}
                    handleSubmit={formik.handleSubmit}
                    loading={loginLoading}
                    error={loginError}
                    resetLoginStatus={resetLoginStatus}
                    resetResendCode={resetResendCode}
                />
            )}
        </>
    )
}

export interface BaseContentProps {
    handleSubmit: (event?: FormEvent<HTMLFormElement>) => void
    handleChange: (event: ChangeEvent) => void
}

export default withResponsiveAuthScreen(Auth)
