import styled, { css } from "styled-components"
import Modal from "../../../../components/Modal"
import {
    StyledBody1Styles,
    StyledBox,
    StyledColumn,
    StyledHr,
    StyledRow,
    VerticalScrollableContentWithHiddenScroll,
} from "../../../../styles/styledcomponents"
import { EditorContainerStyles } from "../../../styles"
import { StyledAddChunkButton } from "../../AddChunkMenu/styles"
import { StyledSearchBar } from "../../../../deliver/components/SearchBar/styles"
import { StyledCloseButtonContainer } from "../../../../components/CloseButton/styles"
import { rgba } from "polished"
import { Check, Close } from "@mui/icons-material"
import { CorrectnessShape } from "."

export const StyledManageResponsesModal = styled(Modal)`
    &-content {
        height: 690px;
        max-height: 80vh;
        padding: 12px 12px 0 !important;
        box-sizing: border-box;
    }
`

export const StyledManageResponsesModalHeader = styled(StyledRow)`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: 15px;
`

export const StyledModalContent = styled(StyledRow)`
    /* 15px is for the header's height */
    height: calc(100% - 15px);
`

export const StyledLeftColumn = styled(StyledColumn)`
    width: 336px;
    border-right: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    margin-bottom: 18px;
    gap: 12px;

    ${StyledSearchBar} {
        margin-top: 0;
        margin-right: 18px;
    }
`

export const StyledRightColumn = styled(StyledColumn)`
    flex: 1;
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
    border-radius: 8px 8px 0 0;
    margin-left: 18px;
    padding: 24px 24px 0 0;
    overflow: auto;
    width: 658px;

    ${EditorContainerStyles}

    .block-container {
        width: auto;
    }
`

export const StyledAddChunkContainer = styled(StyledRow)`
    // the 40px is for the icons-row space
    margin: 6px 40px;

    ${StyledAddChunkButton} {
        padding: 8px;
    }
`

export enum ChoiceVariant {
    MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
    FREE_TEXT = "FREE_TEXT",
}

export const StyledCorrectnessRadio = styled(StyledColumn)<{
    correct?: boolean
    size: number
    shape: CorrectnessShape
}>`
    justify-content: center;
    align-items: center;
    border-radius: ${({ shape }) => (shape === CorrectnessShape.circular ? "50%" : "2px")};
    transition: all 0.3s;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    flex: 0 0 auto;
    box-sizing: border-box;
    cursor: pointer;

    ${({ correct }) =>
        !correct &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.base.uiIncorrect};
            background-color: ${({ theme }) => rgba(theme.colors.base.uiIncorrect, 0.15)};
        `}

    ${({ correct }) =>
        correct &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.base.uiCorrect};
            background-color: ${({ theme }) => rgba(theme.colors.base.uiCorrect, 0.15)};
        `}
`

export const StyledChoiceContainer = styled(StyledRow)<{ variant?: ChoiceVariant }>`
    align-items: flex-start;
    width: 100%;
    position: relative;

    ${StyledCorrectnessRadio} {
        margin-top: 9px;
    }

    ${StyledCloseButtonContainer} {
        top: -10px;
    }

    ${StyledHr} {
        width: 100%;
        border-color: ${({ theme }) => theme.colors.base.uiBgBorder};
        margin: 0;
    }

    ${({ variant }) =>
        variant === ChoiceVariant.FREE_TEXT &&
        css`
            ${StyledCloseButtonContainer} {
                top: 8px;
                right: 2px;
                background-color: unset;
                border: none;
            }
        `}
`

export const StyledAnswerChoicesContainer = styled(StyledBox)`
    ${VerticalScrollableContentWithHiddenScroll};
    display: flex;
    flex-direction: column;
    min-width: 200px;
    max-width: 336px;
    padding-right: 18px;

    ${StyledChoiceContainer} {
        &:last-child {
            ${StyledHr} {
                display: none;
            }
        }
    }
`

export const StyledChoice = styled(StyledBox)<{ selected?: boolean; variant?: ChoiceVariant }>`
    textarea {
        ${StyledBody1Styles};
        background-color: transparent;
        border: none;
        outline: none;
        min-width: inherit;
        max-width: inherit;
        text-overflow: ellipsis;
        padding: 0;
        width: 100%;
        resize: none;
    }
    background-color: transparent;
    padding: 8px 12px;
    width: inherit;
    box-sizing: border-box;
    align-self: flex-end;
    text-overflow: ellipsis;
    transition: border-radius 0.3s;
    transition: background-color 0.1s;

    ${({ variant, selected, theme }) =>
        variant === ChoiceVariant.MULTIPLE_CHOICE &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.thread.student.bubble};
            color: ${({ theme }) => theme.colors.thread.student.font};
            opacity: ${selected ? 1 : 0.5};
            background-color: ${selected ? theme.colors.thread.student.bubble : "transparent"};
            border-radius: 16px;

            textarea {
                color: ${selected
                    ? theme.colors.thread.student.font
                    : theme.colors.thread.student.bubble};
            }
        `}

    ${({ variant, selected, theme }) =>
        variant === ChoiceVariant.FREE_TEXT &&
        css`
            border-radius: 8px;
            background-color: ${selected ? theme.colors.base.uiBgHover : "transparent"};
            color: ${theme.colors.base.uiLabelBase};

            &:hover {
                background-color: ${theme.colors.base.uiBgHover}};
            }

            textarea {
                color: ${({ theme }) => theme.colors.base.uiLabelBase};
            }
        `}

    span {
        user-select: none;
    }
`

export const StyledNewChoiceButton = styled.button<{ variant?: ChoiceVariant }>`
    background-color: white;
    outline: none;
    border: 1px dashed ${({ theme }) => theme.colors.base.uiBgIcon};
    border-radius: ${({ theme }) => theme.borderRadius2};
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.base.uiBgIcon};
    text-align: start;
    margin: 3px 18px 0 0;

    &:hover {
        cursor: pointer;
    }

    ${({ variant }) =>
        variant === ChoiceVariant.FREE_TEXT &&
        css`
            border-radius: ${({ theme }) => theme.borderRadius1};
            text-align: center;
        `}
`

export const StyledCorrectIcon = styled(Check)`
    color: ${({ theme }) => theme.colors.base.uiCorrect};
    font-size: 12px !important;
`

export const StyledIncorrectIcon = styled(Close)`
    color: ${({ theme }) => theme.colors.base.uiIncorrect};
    font-size: 12px !important;
`
