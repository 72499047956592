import { RefObject, useEffect } from "react"

/*
 * This hook given a ref it will disable the scroll (desktop and mobile) for that ref
 * */
const usePreventScroll = (ref: RefObject<HTMLElement>) => {
    const preventScroll = (e: Event) => {
        e.preventDefault()
        e.stopPropagation()
    }

    useEffect(() => {
        ref?.current?.addEventListener("wheel", preventScroll)
        ref?.current?.addEventListener("touchmove", preventScroll)

        return () => {
            ref?.current?.removeEventListener("wheel", preventScroll)
            ref?.current?.removeEventListener("touchmove", preventScroll)
        }
    }, [ref])
}

export default usePreventScroll
