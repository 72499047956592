import styled from "styled-components"
import { StyledHr } from "../../../../styles/styledcomponents"
import { StyledGroupBlock } from "../GroupBlock/styles"

export const StyledMultipleChoiceBlock = styled(StyledGroupBlock)`
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
`

export const StyledDivider = styled(StyledHr)`
    border-top: dashed 2px ${({ theme }) => theme.colors.base.uiBgSecondary};
    width: 100%;
    margin: 6px 0;
`
