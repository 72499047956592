import { IconProps } from "../../types"

export const ReturnKeyIcon = ({ color, opacity }: IconProps) => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#return_key_icon_path)">
                <path
                    d="M6.21 5.21L5.5 4.5L2.5 7.5L5.5 10.5L6.21 9.79L4.415 8H10V2H7V3H9V7H4.415L6.21 5.21Z"
                    fill={color ?? "white"}
                    fillOpacity={opacity ?? 1}
                />
            </g>
            <defs>
                <clipPath id="return_key_icon_path">
                    <rect width="12" height="12" fill={color ?? "white"} />
                </clipPath>
            </defs>
        </svg>
    )
}
