import styled from "styled-components"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { StyledSpan } from "../../../styles/styledcomponents"

export const StyledMoreVertIcon = styled(MoreVertIcon)`
    color: ${({ theme }) => theme.headlandsGray10};
    font-size: 18px !important;

    :hover {
        cursor: pointer;
    }
`

export const StyledStatusSpan = styled(StyledSpan)<{ registered?: boolean }>`
    color: ${({ registered, theme }) =>
        registered ? theme.headlandsPrimaryColor : theme.headlandsError};
`

export const StyledHiddenPill = styled(StyledSpan)`
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.headlandsGray10};
    background-color: ${({ theme }) => theme.headlandsGray4};
    padding: 0 6px;
    border-radius: 50px;
`
