import styled from "styled-components"
import { StyledBody2, StyledBox, StyledH5 } from "../../../../styles/styledcomponents"

export const StyledDesktopOnlyBlock = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.base.uiPrimary400};
    border-radius: 3px;
    padding: 12px;
    gap: 6px;

    ${StyledH5}, ${StyledBody2} {
        color: ${({ theme }) => theme.colors.base.uiBgBase};
    }
`
