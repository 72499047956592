import styled from "styled-components"
import { MediaSize } from "../../../../types"
import { Device, IMAGE_SIZE_RATIO } from "../../../../components/ThreadPreview/ImagePreview/styles"

const calculateWidth = (size: MediaSize) => {
    switch (size) {
        case MediaSize.extraSmall:
            return "46px"
        default:
            return `calc(${IMAGE_SIZE_RATIO[Device.mobile][size]} * 100%)`
    }
}

export const StyledImageBlock = styled.img<{ size: MediaSize }>`
    object-fit: contain;
    border-radius: 8px 8px 8px 0;
    width: ${({ size }) => calculateWidth(size)};
`
