import { AvatarSize, BlocksType } from "../../../../types"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo, useRef, useState } from "react"
import { RootState } from "../../../../redux/store"
import useVariableDefinitions, {
    VariableDefinitionWithBlockId,
} from "../../../../hooks/variables.hook"
import { CSSObject, useTheme } from "styled-components"
import { ApolloCache, useMutation, useQuery } from "@apollo/client"
import { GET_ALL_INSTRUCTORS, GET_THREAD_DURATION } from "../../../../apollo/queries"
import { updateBlock } from "../../../../redux/blocks"
import { updateThread } from "../../../../redux/threads"
import {
    StyledBox,
    StyledColumn,
    StyledH5,
    StyledH6,
    StyledRow,
} from "../../../../styles/styledcomponents"
import {
    StyledCoverImage,
    StyledEmptyImage,
    StyledInstructorBody,
    StyledInstructorCard,
    StyledInstructorDescriptionInput,
    StyledInstructorHeader,
    StyledInstructorPopup,
    StyledMenuIcon,
    StyledPropertyTabContent,
    StyledVariable,
    StyledVariablesContainer,
} from "./styles"
import Input from "../../../../components/InputNew"
import pluralize from "pluralize"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { DeleteOutline, LockOutlined } from "@mui/icons-material"
import { Colors } from "../../../../utils/colors"
import { StyledSettingsBarSectionTitle, StyledSettingsBarSectionTitleContainer } from "../styles"
import { EDIT_THREAD } from "../../../../apollo/mutations"
import { updateCreatorThread } from "../../../../apollo/cacheHelper"
import { EditThreadMutation, Person } from "../../../../apollo/generated/graphql"
import { SparkleIcon } from "../../../../assets/icons/SparklesIcon"
import { StyledInputLabel } from "../../../../components/InputNew/styles"
import { useCurrentThreadField, useCurrentThreadGuid } from "../../../../hooks/currentThread.hook"
import { OverlayOpacityLoader } from "../../../../components/Loader"
import ImagePopup from "../../ImagePopup"
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera"
import DeletableWrapper from "../../../../components/DeletableWrapper"
import { UserAvatar } from "../../UserAvatar"
import EllipsisText from "../../../../components/EllipsisText"
import { pick } from "lodash"
import { InstructorRow } from "../../AICreateModal"

const PropertiesTab = () => {
    const dispatch = useDispatch()

    const threadGuid = useCurrentThreadGuid()
    const threadId = useCurrentThreadField("id")
    const threadVersionID = useCurrentThreadField("threadVersionID")
    const threadAiParams = useCurrentThreadField("aiParams")
    const threadDescription = useCurrentThreadField("description")
    const threadInstructor = useCurrentThreadField("instructor")
    const imageUrl = useCurrentThreadField("imageURL")
    const hasCustomCoverImage = useCurrentThreadField("hasCustomCoverImage")

    const [searching, setSearching] = useState<boolean>(false)
    const [search, setSearch] = useState<string>("")
    const [coverImageError, setCoverImageError] = useState(false)
    const searchInputRef = useRef<HTMLInputElement>(null)
    const rawVariables = useVariableDefinitions()
    const filteredVariables = rawVariables.filter((variable) =>
        variable.name.toLowerCase().includes(search.toLowerCase())
    )
    const theme = useTheme()

    const onThreadUpdated = (
        cache: ApolloCache<any>,
        data: EditThreadMutation | null | undefined
    ) => {
        if (data?.editThread) {
            updateCreatorThread(cache, data.editThread)
            dispatch(
                updateThread({
                    guid: threadGuid,
                    thread: {
                        instructor: data.editThread.instructor,
                        threadVersionID: data.editThread.threadVersionID,
                        imageURL: data.editThread.imageURL,
                        hasCustomCoverImage: data.editThread.hasCustomCoverImage,
                    },
                })
            )
        }
    }

    const [editThread] = useMutation(EDIT_THREAD, {
        update: (cache, { data }) => onThreadUpdated(cache, data),
    })

    const [editCoverImage, { loading: coverImageLoading }] = useMutation(EDIT_THREAD, {
        update: (cache, { data }) => onThreadUpdated(cache, data),
    })

    const handleCoverImageChange = (attachmentGuid?: string) => {
        if (threadId && threadVersionID) {
            editCoverImage({
                variables: {
                    threadId: threadId,
                    threadVersionId: threadVersionID,
                    edit: {
                        imageGUID: attachmentGuid ?? "",
                    },
                },
            })
        }
    }

    const handleVariableSearch = () => {
        setSearching(true)
    }

    const handleDescriptionChange = (text: string) => {
        dispatch(
            updateThread({
                guid: threadGuid,
                thread: { description: text },
            })
        )
        dispatch({ type: "thread/updateThread" })
    }

    const handleInstructorChange = (instructor: Partial<Person>) => {
        if (threadId && threadVersionID) {
            void editThread({
                variables: {
                    threadId: threadId,
                    threadVersionId: threadVersionID,
                    edit: {
                        instructorId: instructor.id,
                    },
                },
            })
        }
    }

    const showAIData = !!threadAiParams

    return (
        <StyledPropertyTabContent>
            <StyledSettingsBarSectionTitle>Cover</StyledSettingsBarSectionTitle>
            <StyledBox css={{ display: "flex", justifyContent: "center" }}>
                <OverlayOpacityLoader loading={coverImageLoading}>
                    <ImagePopup
                        position={["left center"]}
                        offsetX={10}
                        trigger={
                            <div>
                                {imageUrl ? (
                                    <DeletableWrapper
                                        disabled={!hasCustomCoverImage}
                                        onDelete={(event) => {
                                            event?.stopPropagation()
                                            handleCoverImageChange(undefined)
                                        }}
                                    >
                                        <StyledCoverImage src={imageUrl} />
                                    </DeletableWrapper>
                                ) : (
                                    <StyledEmptyImage>
                                        <PhotoCameraIcon sx={{ fontSize: "18px" }} />
                                    </StyledEmptyImage>
                                )}
                            </div>
                        }
                        onImageUploaded={({ attachmentGuid }) =>
                            handleCoverImageChange(attachmentGuid)
                        }
                        closeOnUploaded
                    />
                </OverlayOpacityLoader>
            </StyledBox>
            <StyledSettingsBarSectionTitle>Description</StyledSettingsBarSectionTitle>
            <Input
                placeholder={"Thread description"}
                value={threadDescription || ""}
                type={"textArea"}
                minRows={4}
                maxRows={4}
                onChange={(e) => handleDescriptionChange(e.target.value)}
            />
            {/* AI data start */}
            {showAIData && (
                <StyledColumn css={{ gap: "12px" }}>
                    <StyledSettingsBarSectionTitleContainer
                        css={{ justifyContent: "flex-start", marginBottom: 0 }}
                    >
                        <SparkleIcon color={theme.colors.base.uiBgIcon} />
                        <StyledH6 css={{ marginLeft: "4px" }}>Thread Inputs</StyledH6>
                    </StyledSettingsBarSectionTitleContainer>
                    {threadInstructor && (
                        <StyledBox>
                            <StyledRow
                                css={{ alignItems: "center", justifyContent: "space-between" }}
                            >
                                <StyledInputLabel>Instructor Persona</StyledInputLabel>
                                <InstructorSelectionMenu onChange={handleInstructorChange} />
                            </StyledRow>
                            <InstructorDetails
                                instructor={threadInstructor}
                                description={threadAiParams?.instructorProfile || ""}
                                minRows={2}
                                maxRows={2}
                                readOnly
                                disabled
                                styles={{
                                    border: `1px solid ${theme.colors.base.uiBgBorder}`,
                                    borderRadius: "8px",
                                    backgroundColor: theme.colors.base.uiBgSecondary,
                                }}
                            />
                        </StyledBox>
                    )}
                    <Input
                        label={"Topic"}
                        value={threadAiParams?.prompt || ""}
                        type={"textArea"}
                        minRows={3}
                        maxRows={3}
                        disabled
                    />
                    <Input
                        label={"Instructor Profile"}
                        value={threadAiParams?.instructorProfile || ""}
                        type={"textArea"}
                        minRows={3}
                        maxRows={3}
                        disabled
                    />
                    <Input
                        label={"Source Content"}
                        value={threadAiParams?.referenceMaterial || ""}
                        type={"textArea"}
                        minRows={3}
                        maxRows={9}
                        disabled
                    />
                    <ThreadDuration />
                </StyledColumn>
            )}
            {/* AI data end */}
            {!showAIData && (
                <StyledColumn>
                    <StyledSettingsBarSectionTitleContainer>
                        <StyledH6>Instructor</StyledH6>
                        <InstructorSelectionMenu onChange={handleInstructorChange} />
                    </StyledSettingsBarSectionTitleContainer>
                    {threadInstructor && (
                        <InstructorDetails
                            instructor={threadInstructor}
                            maxRows={2}
                            minRows={2}
                            readOnly
                            disabled
                        />
                    )}
                    <StyledSettingsBarSectionTitle>Metadata</StyledSettingsBarSectionTitle>
                    <ThreadDuration />
                </StyledColumn>
            )}
            {searching ? (
                <StyledSettingsBarSectionTitleContainer>
                    <input
                        ref={searchInputRef}
                        placeholder={"Search"}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <CloseIcon
                        sx={{ color: theme.headlandsGray2 }}
                        fontSize={"small"}
                        onClick={() => setSearching(false)}
                    />
                </StyledSettingsBarSectionTitleContainer>
            ) : (
                <StyledSettingsBarSectionTitleContainer>
                    <StyledH6 css={{ color: theme.headlandsGray2 }}>Variables</StyledH6>
                    <SearchIcon
                        sx={{ color: theme.headlandsGray2 }}
                        fontSize={"small"}
                        onClick={handleVariableSearch}
                    />
                </StyledSettingsBarSectionTitleContainer>
            )}
            <StyledVariablesContainer>
                {filteredVariables.length > 0 ? (
                    filteredVariables.map((variable, index) => (
                        <VariableItem
                            key={`${index}-${variable.name}`}
                            variable={variable}
                            assignedVariables={rawVariables}
                        />
                    ))
                ) : (
                    <span>No variables found</span>
                )}
            </StyledVariablesContainer>
        </StyledPropertyTabContent>
    )
}

const ThreadDuration = () => {
    const threadGuid = useCurrentThreadGuid()
    const threadVersion = useCurrentThreadField("version")

    const { data: durationData, previousData: previousDurationData } = useQuery(
        GET_THREAD_DURATION,
        {
            skip: !threadGuid || !threadVersion,
            variables: {
                input: {
                    guid: threadGuid,
                    version: threadVersion,
                },
            },
            fetchPolicy: "network-only",
        }
    )

    const duration = useMemo(() => {
        const milliseconds = durationData?.fullThreadContent?.timingData?.expectedDurationMS
        if (!milliseconds || isNaN(milliseconds)) return ""
        else {
            const roundedMin = Math.round((milliseconds || 0) / 60000)
            return `${roundedMin} ${pluralize("min", roundedMin)}`
        }
    }, [durationData, previousDurationData])

    return <Input value={duration} label={"Estimated Duration"} disabled />
}

type VariableItemType = {
    variable: VariableDefinitionWithBlockId
    assignedVariables: VariableDefinitionWithBlockId[]
}

const VariableItem = ({ variable, assignedVariables }: VariableItemType) => {
    const [altVariableName, setAltVariableName] = useState<string>(variable.name)
    const [error, setError] = useState<boolean>(false)
    const dispatch = useDispatch()
    const blocks = useSelector((state: RootState) => state.blocksReducer.blocks, shallowEqual)
    const block = variable.blockId ? blocks[variable.blockId] : undefined

    /*
     * This useEffect is used when the variable name is change from a SaveAnswer or SetVariableBlock component
     * */
    useEffect(() => {
        if (variable.name !== altVariableName) {
            setAltVariableName(variable.name || "")
        }
    }, [variable.name])

    /*
     * This function check whether the variable name is a valid one or not.
     * We consider a valid variable name if these items are accomplished:
     * - there is no other variable with the same name OR
     * - there is a variable with the same name and both variables types are the exact same OR
     * - there is a variable name with the same name but it belongs to the same block (it catches a bug related to the
     * last previous valid variable name)
     * */
    const isVariableNameValid = (variableNameToTest: string): boolean => {
        if (assignedVariables) {
            return !assignedVariables.find(
                (assignedVariable) =>
                    assignedVariable.name === variableNameToTest &&
                    assignedVariable.blockId !== variable.blockId &&
                    assignedVariable.type !== variable.type
            )
        }
        return true
    }

    const handleVariableDelete = () => {
        if (!block) return
        const { save_to_variable, ...updatedBlock } = block
        dispatch(
            updateBlock({
                blocksType: BlocksType.BLOCKS,
                id: updatedBlock.id,
                block: updatedBlock,
                replace: true,
            })
        )
    }

    const handleVariableRename = (newName: string) => {
        if (!block) return
        if (isVariableNameValid(newName)) {
            dispatch(
                updateBlock({
                    blocksType: BlocksType.BLOCKS,
                    id: block.id,
                    block: {
                        ...block,
                        save_to_variable: newName,
                    },
                })
            )
            setAltVariableName(newName)
            if (error) setError(false)
        } else {
            setError(true)
            setAltVariableName(newName)
        }
    }
    if (block) {
        return (
            <StyledVariable error={error}>
                <span>@</span>
                <input
                    value={altVariableName}
                    onChange={(e) => handleVariableRename(e.target.value)}
                />
                <DeleteOutline
                    sx={{ color: Colors.headlandsGray3, fontSize: 16 }}
                    onClick={handleVariableDelete}
                />
            </StyledVariable>
        )
    }

    return (
        <StyledVariable error={error}>
            <StyledRow>
                <span>@</span>
                <span>{altVariableName}</span>
            </StyledRow>
            <LockOutlined sx={{ color: Colors.headlandsGray3, fontSize: 16 }} />
        </StyledVariable>
    )
}

interface InstructorDetailsProps {
    instructor: Partial<Person>
    description?: string
    disabled?: boolean
    handleInstructorDescriptionChange?: (description: string) => void
    minRows?: number
    maxRows?: number
    styles?: CSSObject
    readOnly?: boolean
}

export const InstructorDetails = ({
    instructor,
    description,
    disabled,
    handleInstructorDescriptionChange,
    minRows,
    maxRows,
    styles,
    readOnly,
}: InstructorDetailsProps) => {
    const theme = useTheme()
    return (
        <StyledInstructorCard style={styles}>
            <StyledInstructorHeader>
                <UserAvatar
                    user={{ ...pick(instructor, ["profileImageURL", "firstName", "lastName"]) }}
                    size={AvatarSize.XS}
                />
                <StyledH5 useNewDesign>
                    {instructor.firstName} {instructor.lastName}
                </StyledH5>
            </StyledInstructorHeader>
            <StyledInstructorBody dark={readOnly}>
                {readOnly ? (
                    <EllipsisText
                        text={description || instructor.description || ""}
                        maxLines={maxRows ?? 2}
                        maxHeight={(maxRows ?? 2) * 18}
                        variant={"body2"}
                        textStyles={{ color: theme.colors.base.uiLabelDisabled }}
                        popupOptions={{
                            position: "bottom center",
                            contentStyle: { maxHeight: "400px", overflow: "auto" },
                        }}
                    />
                ) : (
                    <StyledInstructorDescriptionInput
                        minRows={minRows ?? 2}
                        maxRows={maxRows ?? 2}
                        value={description || instructor.description}
                        disabled={disabled}
                        onChange={(e) =>
                            handleInstructorDescriptionChange &&
                            handleInstructorDescriptionChange(e.target.value)
                        }
                    />
                )}
            </StyledInstructorBody>
        </StyledInstructorCard>
    )
}

type InstructorSelectionMenuProps = {
    onChange: (instructor: Partial<Person>) => void
}

export const InstructorSelectionMenu = ({ onChange }: InstructorSelectionMenuProps) => {
    const instructorPopupRef = useRef(null)

    const { data: instructorsData } = useQuery(GET_ALL_INSTRUCTORS)

    const onInstructorSelect = (instructor: Partial<Person>) => {
        onChange(instructor)
        /*
         * `reactjs-popup` doesn't have a type for it's ref, so that's
         * why we have to use @ts-ignore here.
         */
        // @ts-ignore
        instructorPopupRef.current?.close()
    }

    return (
        <StyledInstructorPopup
            nested
            lockScroll
            ref={instructorPopupRef}
            on={"click"}
            arrow={false}
            position={"left top"}
            trigger={<StyledMenuIcon />}
        >
            {instructorsData?.allInstructors.map((instructor) => (
                <InstructorRow
                    key={instructor.id}
                    instructor={instructor}
                    onSelect={() => onInstructorSelect(instructor)}
                    ellipsisPopupPosition={"left center"}
                />
            ))}
        </StyledInstructorPopup>
    )
}

export default PropertiesTab
