import { useTheme } from "styled-components"
import { ReturnKeyIcon } from "../../../../assets/icons/ReturnKeyIcon"
import { Button, ButtonProps } from "../../../../components/Button/Button"
import { StyledBody2, StyledRow } from "../../../../styles/styledcomponents"
import { Loader } from "../../../../components/Loader"
import { StyledButtonLabel, StyledFooter } from "./styles"

export enum ButtonsAlignment {
    left = "left",
    right = "right",
}
interface AiPopupFooterProps {
    retry: () => void
    discard: () => void
    submit: () => void
    loading?: boolean
    disableKeyBinding?: boolean
    buttonsAlignment?: ButtonsAlignment
}

const AiPopupFooter = ({
    retry,
    discard,
    submit,
    loading,
    disableKeyBinding = false,
    buttonsAlignment,
}: AiPopupFooterProps) => {
    const theme = useTheme()
    return (
        <StyledFooter buttonAlignment={buttonsAlignment}>
            <StyledRow css={{ justifyContent: "space-between" }}>
                <StyledRow css={{ gap: "6px" }}>
                    <AiButton
                        label={
                            <StyledButtonLabel>
                                <StyledBody2>Keep</StyledBody2>
                                <ReturnKeyIcon opacity={0.7} />
                            </StyledButtonLabel>
                        }
                        onClick={submit}
                        disabled={!!loading}
                        keyBinding={!disableKeyBinding ? "Enter" : undefined}
                    />
                    <AiButton
                        label={
                            <StyledButtonLabel>
                                <StyledBody2>Try again</StyledBody2>
                                <StyledBody2 css={{ opacity: 0.7, fontWeight: "400!important" }}>
                                    R
                                </StyledBody2>
                            </StyledButtonLabel>
                        }
                        variant={"outline"}
                        onClick={retry}
                        keyBinding={!disableKeyBinding ? "r" : undefined}
                    />
                    <AiButton
                        label={
                            <StyledButtonLabel>
                                <StyledBody2>Discard</StyledBody2>
                                <StyledBody2 css={{ opacity: 0.7, fontWeight: "400!important" }}>
                                    ESC
                                </StyledBody2>
                            </StyledButtonLabel>
                        }
                        variant={"outline"}
                        onClick={discard}
                        keyBinding={!disableKeyBinding ? "Escape" : undefined}
                    />
                </StyledRow>
                <StyledRow>
                    {loading && (
                        <Loader
                            size={16}
                            color={theme.colors.base.uiAi500}
                            css={{ justifySelf: "flex-end" }}
                        />
                    )}
                </StyledRow>
            </StyledRow>
        </StyledFooter>
    )
}

const AiButton = (props: ButtonProps) => {
    const theme = useTheme()
    return <Button {...props} color={theme.headlandsAiColor} />
}

export default AiPopupFooter
