import { Block } from "../../../types"
import parse from "html-react-parser"
import { StyledCommentPreview } from "./styles"

interface CommentPreviewProps {
    block: Block
}

// NOTE: Even though the comment block shouldn't be visible on thread preview
// we did this component to support comment preview inside the `AiCreateBlockPopup`
const CommentPreview = ({ block }: CommentPreviewProps) => {
    return (
        <StyledCommentPreview>
            <div className={"text-block-value ql-editor"}>{parse(block.value || "")}</div>
        </StyledCommentPreview>
    )
}

export default CommentPreview
