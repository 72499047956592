import { useEffect, useMemo, useState } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { Block } from "../types"
import { getVariableChoices, getVariableType } from "../utils/utils"
import { useLazyQuery, useQuery } from "@apollo/client"
import { GET_DYNAMIC_VARIABLES_VALUE, GET_GLOBAL_VARIABLES } from "../apollo/queries"
import {
    DynamicVariableQuery,
    UserAnswer,
    VariableCategory,
    VariableDefinition,
    VariableWithValue,
} from "../apollo/generated/graphql"
import { compact, difference, intersectionWith, isEmpty, pick, unionBy } from "lodash"
import { useCurrentResponseThreadField, useCurrentThreadField } from "./currentThread.hook"
import { useSelectedEnrolledProgramAttribute } from "./enrolledProgram.hook"

export type VariableDefinitionWithBlockId = VariableDefinition & { blockId?: string }

const useGlobalVariableDefinitions = (): VariableDefinition[] => {
    const { data: globalVariablesData } = useQuery(GET_GLOBAL_VARIABLES)
    const variableDefinitionFromGlobalVariables = useMemo(() => {
        return (globalVariablesData?.globalVariables || []).concat()
    }, [globalVariablesData])

    return variableDefinitionFromGlobalVariables
}

interface useStaticVariableValuesResponse {
    staticVariableValues: VariableWithValue[]
}

export const useStaticVariableValues = (): useStaticVariableValuesResponse => {
    const [staticVariableValues, setStaticVariableValues] = useState<VariableWithValue[]>([])

    const variableDefinitionFromGlobalVariables = useGlobalVariableDefinitions()
    const threadVariables = useCurrentResponseThreadField("variables") || []
    const userAnswersAsVariables: VariableWithValue[] = (
        useCurrentResponseThreadField("userAnswers") || []
    )
        .filter((answer: UserAnswer) => answer.value)
        .map((answer: UserAnswer) => ({
            __typename: "VariableWithValue",
            value: answer.value!,
            ...pick(answer, ["name", "type"]),
        }))

    useEffect(() => {
        const staticVariableDefinitions = variableDefinitionFromGlobalVariables.filter(
            (variableDefinition) => !variableDefinition.dynamic
        )
        // merge thread variables and user answer into a single duplicate free array
        // (in case of duplication `userAnswersAsVariables` value will prevail)
        const allVariables = unionBy(userAnswersAsVariables, threadVariables, "name")

        const allStaticVariables = intersectionWith(
            allVariables,
            staticVariableDefinitions,
            (variable1, variable2) => variable1.name === variable2.name
        )

        // only if `allStaticVariables` is different from `staticVariableValues` set it into the state
        if (!isEmpty(difference(allStaticVariables, staticVariableValues))) {
            setStaticVariableValues(allStaticVariables)
        }
    }, [variableDefinitionFromGlobalVariables, threadVariables, userAnswersAsVariables])

    return { staticVariableValues }
}

interface useDynamicVariableValuesResponse {
    getDynamicVariableValues: (variableKeys: string[]) => Promise<VariableWithValue[]>
}

/*
 * This hook returns all `VariableWithValue` items for each dynamic variable given in `variableKeys`
 */
export const useDynamicVariableValues = (): useDynamicVariableValuesResponse => {
    const telemetrySessionId = useCurrentResponseThreadField("sessionID")
    const threadId = useCurrentThreadField("id")
    const { attribute: programId } = useSelectedEnrolledProgramAttribute("id")
    const variableDefinitionFromGlobalVariables = useGlobalVariableDefinitions()
    const dynamicVariables = variableDefinitionFromGlobalVariables.filter(
        (variableDefinition) => variableDefinition.dynamic
    )

    const [getDynamicVariablesValue] = useLazyQuery(GET_DYNAMIC_VARIABLES_VALUE, {
        fetchPolicy: "network-only",
    })

    const getDynamicVariableValues = async (
        variableKeys: string[]
    ): Promise<VariableWithValue[]> => {
        const dynamicVariableNamesToQuery = intersectionWith(
            variableKeys,
            dynamicVariables,
            (variableKey, variableDefinition) => variableDefinition.name === variableKey
        )

        if (isEmpty(dynamicVariableNamesToQuery)) return []

        let input: DynamicVariableQuery = {
            names: dynamicVariableNamesToQuery,
        }
        if (telemetrySessionId) {
            input.telemetrySessionId = telemetrySessionId
        } else if (programId && threadId) {
            input.programID = programId
            input.threadID = threadId
        } else {
            return []
        }

        return getDynamicVariablesValue({ variables: { input } }).then(({ data }) => {
            const variablesWithValue = data?.dynamicVariable
            return compact(variablesWithValue)
        })
    }

    return { getDynamicVariableValues }
}

const useVariableDefinitions = (): VariableDefinitionWithBlockId[] => {
    const variableDefinitionFromGlobalVariables = useGlobalVariableDefinitions()

    const variableDefinitionsFromBlocks = useSelector((state: RootState) => {
        let result: VariableDefinitionWithBlockId[] = []
        Object.values(state.blocksReducer.blocks)
            .filter((block: Block) => block.save_to_variable)
            .forEach((block: Block) => {
                // If the variable exists, we overwrite it with the new value
                const existingVariableIndex = result.findIndex(
                    (variable: VariableDefinitionWithBlockId) =>
                        variable.name === block.save_to_variable
                )
                const newVariable = {
                    category: VariableCategory.Thread,
                    documentation: "",
                    name: block.save_to_variable,
                    possibleValues: getVariableChoices(block),
                    type: getVariableType(block.type, block?.properties?.may_select_multiple),
                    dynamic: false,
                    writable: true,
                    blockId: block.id,
                } as VariableDefinitionWithBlockId
                if (existingVariableIndex >= 0) {
                    result[existingVariableIndex] = newVariable
                } else {
                    result.push(newVariable)
                }
            })
        return result
    }, shallowEqual)

    const duplicatedVariableNames = intersectionWith(
        variableDefinitionFromGlobalVariables,
        variableDefinitionsFromBlocks,
        (variable1, variable2) => variable1.name === variable2.name
    ).map((e) => e.name)

    // Here we remove the duplicate variables from the `globalVariables` this use case is used when
    // a global variable is overwritten by a block variable
    const variableDefinitionFromGlobalVariablesDuplicateFree =
        variableDefinitionFromGlobalVariables.filter(
            (variable) => !duplicatedVariableNames.includes(variable.name)
        )

    return [...variableDefinitionFromGlobalVariablesDuplicateFree, ...variableDefinitionsFromBlocks]
}

export default useVariableDefinitions
