import styled from "styled-components"

export const StyledSaveAnswer = styled.div<{ inlineLabel?: boolean }>`
    .save-answer-input {
        display: flex;
        flex-direction: ${({ inlineLabel }) => (inlineLabel ? "row" : "column")};
        align-items: ${({ inlineLabel }) => (inlineLabel ? "center" : "flex-start")};

        label {
            font-size: 14px;
            line-height: 20px;
            color: ${({ theme }) => theme.headlandsGray10};
        }

        .variable-picker {
            border: 1px solid ${({ theme }) => theme.headlandsGray3};
            border-radius: ${({ theme }) => theme.borderRadius1};
            padding: 2px 12px;
            background-color: ${({ theme }) => theme.headlandsWhite};
            margin-left: ${({ inlineLabel }) => (inlineLabel ? "12px" : "unset")};

            input {
                border: none;
                outline: none;
                color: ${({ theme }) => theme.headlandsGray2};
                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: ${({ theme }) => theme.headlandsGray2};
                    opacity: 1; /* Firefox */
                }
            }
        }
    }

    .save-answer-error {
        color: ${({ theme }) => theme.headlandsError};
        font-size: 12px;
    }
`
