import styled from "styled-components"
import { StyledBox } from "../../../../styles/styledcomponents"
import StyledButton from "../../../../components/Button/styles"

export const StyledChannelInputRow = styled(StyledBox)`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 6px;
`

export const StyledFormFooter = styled(StyledBox)`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    ${StyledButton} {
        width: fit-content;
    }
`
export const StyledForm = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    width: 100%;
`
