export const Divider = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1.5 9H16.5" stroke="white" stroke-width="2" />
        </svg>
    )
}
