import Modal from "../Modal"
import { StyledH4, StyledP } from "../../styles/styledcomponents"
import { Button } from "../Button/Button"

const SwitchedDevicesModal = () => {
    return (
        <Modal open closeOnDocumentClick={false} closeOnEscape={false}>
            <StyledH4 css={{ marginBottom: "18px" }}>Looks like you've switched devices</StyledH4>
            <StyledP css={{ marginBottom: "6px" }}>📱 💻</StyledP>
            <StyledP>Pick up where you left off:</StyledP>
            <Button
                label={"Continue"}
                width={120}
                buttonStyle={{ marginTop: "18px" }}
                onClick={() => window.location.reload()}
            />
        </Modal>
    )
}

export default SwitchedDevicesModal
