import { DefaultTheme } from "styled-components"
import { getFontName } from "../../utils/utils"

// NOTE: When adding new variables remember to add them to the styles.d.ts file
export const theme = (isMobile: boolean): DefaultTheme => ({
    // when we update the app colors we should add it here
    colors: {
        base: {
            // backgrounds
            uiBgBase: "#FFFFFF",
            uiBgSecondary: "#F1F1F1",
            uiBgDark: "#000000",
            uiBgTertiary: "#FAFAFA",
            uiBgThreadActive: "#F8F8F8",
            uiBgBorder: "#DDDDDD",
            uiBgHover: "#F5F5F5",
            uiBgHoverSecondary: "#FBFBFB",
            uiBgIcon: "#888888",
            // labels
            uiLabelTitle: "#333333",
            uiLabelSubtitle: "#4F4F4F",
            uiLabelBase: "#323232",
            uiLabelDisabled: "#777777",
            uiLabelPlaceholder: "#DCDCDC",
            uiLabelActive: "#000000",
            uiLabelLink: "#4A99CB",
            // primaries
            uiPrimary300: "#E7E7E7",
            uiPrimary400: "#444444D9",
            uiPrimary500: "#444444",
            // warnings
            uiWarning: "#FFE03C",
            uiWarning400: "#FFE03C33",
            uiWarning500: "#F1B875",
            // errors
            uiError300: "#D8454533",
            uiError400: "#d8454599",
            uiError500: "#D84545",
            // ai
            uiAi300: "#E9E3EF",
            uiAi400: "#C0AFD0",
            uiAi500: "#825EA2",
            // system
            uiSystemHover: "#C0E6F4",
            // correctness
            uiCorrect: "#73BF45",
            uiIncorrect: "#EF3E36",
            // leaderboard
            uiGold: {
                background: "#EBBC2B",
                border: "#F8DC68",
            },
            uiSilver: {
                background: "#A2A2A2",
                border: "#CBC8C6",
            },
            uiBronze: {
                background: "#C7621E",
                border: "#EA8D3F",
            },
            uiChallenge: "#4F60DC",
        },
        // thread theme colors
        thread: {
            background: "#FFFFFF",
            instructor: {
                bubble: "#F1F1F1",
                font: "#323232",
            },
            student: {
                bubble: "#444444",
                font: "#FFFFFF",
            },
        },
    },
    shadows: {
        small: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    },
    fonts: {
        thread: getFontName(),
    },
    headlandsPrimaryColor: "#444444",
    headlandsPrimaryColorAlpha: "#E7E7E7",
    headlandsPrimaryDark: "#0cb969",
    headlandsError: "#d84545",
    headlandsWarning: "#f1e675",
    headlandsBlack: "#000000",
    headlandsBlack1: "#2d2727",
    headlandsGrayDark: "#323232",
    headlandsGray1: "#4f4f4f",
    headlandsGray2: "#777777",
    headlandsGray3: "#dddddd",
    headlandsGray4: "#f1f1f1",
    headlandsGray5: "#fafafa",
    headlandsGray6: "#f5f5f5",
    headlandsGray6opacity: "#f5f5f560",
    headlandsGray7: "#c4c4c4",
    headlandsGray8: "#d0d0d0",
    headlandsGray9: "#f9f9f9",
    headlandsGray10: "#888888",
    headlandsGray11: "#E0E0E0",
    headlandsWhite: "#ffffff",
    headlandsAiColor: "#825EA2",
    messageBackground1: "#E6E9EDFF",
    borderRadius1: "8px",
    borderRadius2: "16px",
    boxShadow2: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    boxShadow1: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    backgroundDark: "rgba(24, 24, 24, 0.56)  !important",
    blur1: "blur(1px)",
    disabledOpacity: 0.2,

    // thread preview common styles
    bubbleHorizontalMargin: isMobile ? "12px" : "20px",
    bubbleVerticalMargin: "6px",
    bubbleVerticalPadding: "12px",
    bubbleHorizontalPadding: "18px",
    buttonBorder: "1px",
    rightBubbleBorderRadius: "16px 16px 2px 16px",
    leftBubbleBorderRadius: "16px 16px 16px 2px",
    threadPreviewMaxWidth: isMobile ? "100vw" : "600px",
    threadPreviewHorizontalPadding: isMobile ? "0" : "16px",
    radioMinWidth: "200px",
    mobilePreviewDeviceHeight: "calc(100vh - 67px - 80px)",
    mobilePreviewDeviceMinHeight: "600px",
    mobilePreviewDeviceMaxHeight: "1000px",
    mobilePreviewDeviceBorderWidth: "18px",

    sidebarWidths: {
        nav: 72,
        library: isMobile ? window.innerWidth * 0.9 : 360,
        social: isMobile ? window.innerWidth * 0.9 : 360,
        creatorThread: 360,
        properties: 320,
        deliver: 360,
        creatorThreadOutline: 320,
    },

    utils: {
        addOpacityToHex: (hex: string, opacity: number): string => {
            if (opacity > 1 || opacity < 0) return hex
            let _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255)
            return hex + _opacity.toString(16).toUpperCase()
        },
    },

    variables: {
        creator: {
            iconRowWidth: "40px",
            groupPadding: {
                top: "12px",
                right: "12px",
                bottom: "12px",
                left: "6px",
            },
            threadBarPadding: {
                top: "12px",
                right: "18px",
                bottom: "0",
                left: "18px",
            },
            canvasMaxWidth: "600px",
        },
        program: {
            libraryBarPadding: {
                top: "18px",
                right: "18px",
                bottom: "18px",
                left: "18px",
            },
            socialBarPadding: {
                top: "0",
                right: "12px",
                bottom: "9px",
                left: "12px",
            },
        },
        deliver: {
            programBarPadding: {
                top: "0",
                right: "18px",
                bottom: "0",
                left: "18px",
            },
        },
        common: {
            fabIconWidth: "36px",
            fabIconHeight: "36px",
            topBarHeight: "66px",
            topBarPadding: {
                right: "18px",
                left: "18px",
            },
            bottomNavbarHeight: "60px",
        },
    },
})
