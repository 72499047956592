import { IconProps } from "../../types"
import { useTheme } from "styled-components"

const CorrectIcon = ({ width = 28, height = 28, color }: IconProps) => {
    const theme = useTheme()

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#correct_icon_path)">
                <path
                    d="M9.33706 19.7793L3.11782 13.56L1 15.6629L9.33706 24L27.2342 6.10291L25.1313 4L9.33706 19.7793Z"
                    fill={color ?? theme.colors.base.uiCorrect}
                />
            </g>
            <defs>
                <clipPath id="correct_icon_path">
                    <rect width="28" height="28" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CorrectIcon
