import { forwardRef } from "react"
import { StyledBreakpointButton, StyledChevron } from "./styles"

export interface BreakpointButtonProps {
    onClick?: () => void
    white?: boolean
    border?: boolean
    value?: string
    mobile?: boolean
}

const BreakpointButton = forwardRef<HTMLDivElement, BreakpointButtonProps>(
    ({ onClick, white, border, value, mobile }, ref) => {
        const chevron = <StyledChevron primary={white} />

        return (
            <StyledBreakpointButton
                ref={ref}
                onClick={onClick}
                data-testid={"breakpoint-button"}
                pointer={!!onClick}
                white={white}
                border={border}
                mobile={mobile}
                text={!!value}
            >
                {value ? value : chevron}
            </StyledBreakpointButton>
        )
    }
)

export default BreakpointButton
