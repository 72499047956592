import styled from "styled-components"
import {
    StyledBox,
    StyledHr,
    VerticalScrollableContentWithHiddenScroll,
} from "../../../styles/styledcomponents"
import StyledButton from "../../../components/Button/styles"
import MoreVertIcon from "@mui/icons-material/MoreVert"

export const StyledProgramsBar = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    width: calc(${({ theme }) => theme.sidebarWidths.deliver} * 1px);
    border-right: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    background-color: ${({ theme }) => theme.headlandsWhite};
    box-sizing: border-box;
    padding: ${({ theme }) => `
        ${theme.variables.deliver.programBarPadding.top}
        ${theme.variables.deliver.programBarPadding.right}
        ${theme.variables.deliver.programBarPadding.bottom}
        ${theme.variables.deliver.programBarPadding.left}
  `};
`

export const StyledProgramsBarHeader = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    padding-bottom: 18px;

    h2 {
        font-size: 24px;
        line-height: 30px;
    }

    ${StyledButton} {
        width: 66px;
    }
`

export const StyledProgramsBarContentContainer = styled(StyledBox)`
    flex-grow: 1;
    min-height: 0;
    ${VerticalScrollableContentWithHiddenScroll};
`

export const StyledMoreVerticalIcon = styled(MoreVertIcon)`
    opacity: 0;

    &:hover {
        cursor: pointer;
    }
`

export const StyledProgramItem = styled(StyledBox)<{ active?: boolean }>`
    display: flex;
    align-items: center;
    height: 64px;
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 12px 6px;
    box-sizing: border-box;
    background-color: ${({ active, theme }) =>
        active ? theme.colors.base.uiBgSecondary : "unset"};

    h4 {
        font-size: 14px;
        line-height: 18px;
        ${({ theme }) => theme.headlandsBlack};
    }

    span {
        font-size: 10px;
        line-height: 18px;
        color: ${({ theme }) => theme.headlandsGray2};
    }

    img {
        width: 46px;
        height: 46px;
        border-radius: ${({ theme }) => theme.borderRadius1};
        margin-right: 6px;
        object-fit: cover;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.base.uiBgHover};
        cursor: pointer;

        ${StyledMoreVerticalIcon} {
            opacity: 1;
        }
    }
`

export const StyledLivePill = styled.div`
    position: absolute;
    bottom: 0;
    left: calc(50% - 21px / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.headlandsPrimaryColor};
    border-radius: 3px;
    padding: 1px 2px;
    height: 10px;

    span {
        font-size: 8px;
        line-height: 8px;
        font-weight: 500;
        color: ${({ theme }) => theme.headlandsWhite};
        text-transform: uppercase;
    }
`

export const StyledProgramItemDivider = styled(StyledHr)`
    border-color: ${({ theme }) => theme.headlandsGray3};
    // 6px is the 'StyledProgramItem' horizontal padding
    margin: 0 6px;
`
