import { startCase } from "lodash"
import { Language, ThreadTemplate } from "../../../apollo/generated/graphql"
import { ThreadTheme } from "../../../types"

export const LanguageModelOptions = [
    {
        value: "GPT-4",
        label: "GPT-4",
    },
    {
        value: "GPT-3.5",
        label: "GPT-3.5",
    },
    {
        value: "PaLM 2",
        label: "PaLM 2",
    },
    {
        value: "Claude",
        label: "Claude",
    },
]

export const LanguageOptions = [
    {
        value: Language.English,
        label: "🇺🇸 English",
    },
    {
        value: Language.Spanish,
        label: "🇲🇽 Español",
    },
    {
        value: Language.French,
        label: "🇫🇷 Français",
    },
    {
        value: Language.German,
        label: "🇩🇪 Deutsch",
    },
    {
        value: Language.Bulgarian,
        label: "🇧🇬 Български",
    },
]

const getLabel = (key: ThreadTheme): string => startCase(key.replace("_", " "))

export const ThemeOptions = Object.values(ThreadTheme).map((value) => ({
    value,
    label: getLabel(value),
}))

export const TemplateOptions = [
    {
        value: ThreadTemplate.CoreContent,
        label: "Core Content",
    },
    {
        value: ThreadTemplate.Default,
        label: "Default",
    },
    {
        value: ThreadTemplate.Exposition,
        label: "Exposition",
    },
    {
        value: ThreadTemplate.ThreeRings,
        label: "Three Rings",
    },
    {
        value: ThreadTemplate.Test,
        label: "Test",
    },
    {
        value: ThreadTemplate.TriviaQuiz,
        label: "Trivia Quiz",
    },
    {
        value: ThreadTemplate.TriviaQuizV2,
        label: "Trivia Quiz V2",
    },
    {
        value: ThreadTemplate.Tutor,
        label: "Tutor",
    },
]

export const durationMarks = [
    {
        value: 5,
        label: "5 mins",
    },
    {
        value: 10,
    },
    {
        value: 15,
        label: "15 mins",
    },
    {
        value: 20,
    },
    {
        value: 25,
        label: "25 mins",
    },
]

export const questionMarks = [
    {
        value: 10,
        label: "10",
    },
    {
        value: 15,
    },
    {
        value: 20,
        label: "20",
    },
    {
        value: 25,
    },
    {
        value: 30,
        label: "30",
    },
]
