import useLayout from "../../../hooks/layout.hook"
import { usePreviewMode } from "../../../hooks/previewMode.hook"
import { Block, MediaSize, OptionPreviewMode } from "../../../types"
import { getVideoUtils } from "../../../utils/utils"
import { StyledResponsiveVideoContainer, StyledVideoBubble } from "./styles"

export interface VideoPreviewProps {
    block: Block
}

const VideoPreview = ({ block }: VideoPreviewProps) => {
    const { parsed, maxWidth, paddingBottom } = getVideoUtils(block.value!)
    const { isMobile } = useLayout()
    const { previewDevice, previewMode } = usePreviewMode()
    const isMobilePreview = previewMode && previewDevice === OptionPreviewMode.MOBILE

    return (
        <StyledVideoBubble
            isMobile={isMobile}
            isMobilePreview={isMobilePreview}
            size={block.videoSize || MediaSize.medium}
            css={{ maxWidth }}
        >
            <StyledResponsiveVideoContainer css={{ paddingBottom }}>
                {parsed}
            </StyledResponsiveVideoContainer>
        </StyledVideoBubble>
    )
}

export default VideoPreview
