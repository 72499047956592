import { AboutMeQuery } from "../apollo/generated/graphql"
import { useQuery } from "@apollo/client"
import { ABOUT_ME } from "../apollo/queries"

type useUserPermissionsResponse = {
    user?: AboutMeQuery["aboutMe"]
    loading?: boolean
    error?: boolean
}
export const useCurrentUser = (): useUserPermissionsResponse => {
    const { data, loading, error } = useQuery(ABOUT_ME)
    return { user: data?.aboutMe ? { ...data?.aboutMe } : undefined, loading, error: !!error }
}

/*
 * This hook return a specific attribute of the current user
 * */
export const useCurrentUserAttribute = <T extends keyof AboutMeQuery["aboutMe"]>(
    key: T
): {
    attribute: AboutMeQuery["aboutMe"][T] | undefined
    loading: boolean | undefined
} => {
    const { user: currentUser, loading } = useCurrentUser()
    return {
        attribute: currentUser ? currentUser[key] : undefined,
        loading,
    }
}
