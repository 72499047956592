import { StyledBody1 } from "../../../styles/styledcomponents"
import { StyledCreateContent, StyledItemSetContainer } from "./styles"
import { useTheme } from "styled-components"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { MOCKED_TEMPLATES } from "./mocked/data"
import { useMutation } from "@apollo/client"
import { CREATE_THREAD } from "../../../apollo/mutations"
import { ErrorToastIcon } from "../../../assets/icons/ErrorToastIcon"
import { addThreadCreatorToSearchList } from "../../../apollo/cacheHelper"
import { useHistory } from "react-router"
import { useCurrentThreadGuid } from "../../../hooks/currentThread.hook"
import { useToast } from "../../../hooks/useToast.hook"
import { ModalType, setModalState } from "../../../redux/layout"
import { useDispatch } from "react-redux"
import Template from "./Template"
import { setAiCreateThreadTemplate } from "../../../redux/ai"
import { ThreadTemplate } from "../../../apollo/generated/graphql"

const CreateContent = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const history = useHistory()
    const currentThreadGuid = useCurrentThreadGuid()
    const { showErrorToast } = useToast()

    const [createThread, { loading: createThreadLoading }] = useMutation(CREATE_THREAD, {
        onCompleted: (data) => {
            // if the thread created is not on the url then add it
            if (data.createThread.guid !== currentThreadGuid) {
                history.push(`/creator/${data.createThread.guid}`)
            }
        },
        onError: () => {
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
        },
        update: (cache, { data }) => {
            data?.createThread && addThreadCreatorToSearchList(cache, data.createThread)
        },
    })

    const handleTemplateClick = (threadTemplate?: ThreadTemplate) => {
        if (threadTemplate) {
            dispatch(setAiCreateThreadTemplate(threadTemplate))
            dispatch(setModalState({ modalType: ModalType.aiCreate, open: true }))
        } else {
            if (!createThreadLoading) void createThread()
        }
    }

    return (
        <StyledCreateContent>
            <Tabs>
                <TabList>
                    <Tab key={"templates"}>Thred Templates</Tab>
                </TabList>
                <TabPanel key={"templates"}>
                    <StyledBody1 css={{ color: theme.colors.base.uiLabelSubtitle }}>
                        Use these pre-configured templates to generate threads that can teach,
                        train, test, or tutor anyone about anything
                    </StyledBody1>
                    <StyledItemSetContainer>
                        {MOCKED_TEMPLATES.map((mockedTemplate) => (
                            <Template template={mockedTemplate} onClick={handleTemplateClick} />
                        ))}
                    </StyledItemSetContainer>
                </TabPanel>
            </Tabs>
        </StyledCreateContent>
    )
}

export default CreateContent
