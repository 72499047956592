import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { CreatorToolIcon } from "../../assets/icons/CreatorToolIcon"
import { CatalogIcon } from "../../assets/icons/CatalogIcon"
import { CalendarToday } from "@mui/icons-material"
import { useTheme } from "styled-components"
import { useUserPermissions } from "../../hooks/userPermissions"
import HomeIcon from "../../assets/icons/HomeIcon"
import { StyledHeadlandsPopup, StyledHr, StyledSpan } from "../../styles/styledcomponents"
import {
    StyledBottomContainer,
    StyledIconContainer,
    StyledMainContainer,
    StyledNavBar,
} from "./styles"
import { useQuery, useMutation } from "@apollo/client"
import { UserAvatar } from "../../creator/components/UserAvatar"
import { AvatarSize } from "../../types"
import ProfileIcon from "../../assets/icons/ProfileIcon"
import { ABOUT_ME } from "../../apollo/queries"
import { ADD_EVENT } from "../../apollo/mutations"
import { pick } from "lodash"
import AnalyzeIcon from "../../assets/icons/AnalyzeIcon"

export type SideBarItem = {
    id: number | string
    title: string
    icon: JSX.Element
    onClick?: () => void
    shouldRender?: boolean
    showDivider?: boolean
}

const NavBar = () => {
    const location = useLocation()
    const [addEvent] = useMutation(ADD_EVENT)
    useEffect(() => {
        addEvent({
            variables: {
                input: {
                    url: location.pathname,
                    clientTime: new Date(),
                    data: "{}",
                    type: "NAVIGATION",
                },
            },
        })
    }, [location])

    const [active, setActive] = useState<string>()
    const history = useHistory()
    let { pathname } = useLocation()
    const {
        permissions: { mayCreate, maySeeExperimentalFeatures, isAdministrator },
    } = useUserPermissions()
    const theme = useTheme()

    const { data: aboutMeData } = useQuery(ABOUT_ME, { fetchPolicy: "cache-and-network" })

    useEffect(() => {
        if (pathname.includes("creator")) setActive("creator")
        else if (pathname.includes("deliver")) setActive("deliver")
        else if (pathname.includes("program")) setActive("myLibrary")
        else if (pathname.includes("catalog")) setActive("catalog")
        else if (pathname.includes("settings")) setActive("settings")
        else if (pathname.includes("admin")) setActive("analyze")
    }, [pathname])

    const sidebarItems: SideBarItem[] = [
        {
            id: "myLibrary",
            title: "Home",
            icon: <HomeIcon />,
            onClick: () => history.push("/program"),
            shouldRender: true,
        },
        {
            id: "catalog",
            title: "Catalog",
            icon: <CatalogIcon />,
            onClick: () => history.push("/catalog"),
            shouldRender: maySeeExperimentalFeatures,
            // avoid showing divider if the item below are not rendered
            showDivider: mayCreate || isAdministrator,
        },
        {
            id: "creator",
            title: "Create",
            icon: <CreatorToolIcon />,
            onClick: () => history.push("/creator"),
            shouldRender: mayCreate,
        },
        {
            id: "deliver",
            title: "Share",
            icon: <CalendarToday sx={{ fontSize: 18, color: theme.headlandsGray10 }} />,
            onClick: () => history.push("/deliver"),
            shouldRender: mayCreate,
        },
        {
            id: "analyze",
            title: "Analyze",
            icon: <AnalyzeIcon />,
            onClick: () => history.push("/admin"),
            shouldRender: isAdministrator,
        },
    ]

    const settingsItem: SideBarItem = {
        id: "settings",
        title: "Profile",
        icon: <ProfileIcon />,
        onClick: () => history.push("/settings"),
        shouldRender: true,
    }

    return (
        <StyledNavBar>
            <StyledMainContainer>
                {sidebarItems.map((item) => (
                    <SidebarItem key={item.id} item={item} active={active === item.id} />
                ))}
            </StyledMainContainer>
            <StyledBottomContainer>
                <SidebarItem item={settingsItem} active={active === settingsItem.id} />
                <UserAvatar
                    user={pick(aboutMeData?.aboutMe, ["profileImageURL", "firstName", "lastName"])}
                    size={AvatarSize.XL}
                />
            </StyledBottomContainer>
        </StyledNavBar>
    )
}

interface SidebarItemProps {
    item: SideBarItem
    active?: boolean
}

const SidebarItem = ({ item, active }: SidebarItemProps) => {
    const theme = useTheme()

    if (!item.shouldRender) return null

    return (
        <>
            <StyledHeadlandsPopup
                on={["hover"]}
                position={["right center"]}
                mouseEnterDelay={500}
                nested
                trigger={
                    <StyledIconContainer onClick={item.onClick} active={active}>
                        {item.icon}
                    </StyledIconContainer>
                }
            >
                <StyledSpan>{item.title}</StyledSpan>
            </StyledHeadlandsPopup>
            {item.showDivider && <StyledHr css={{ width: "42px", color: theme.headlandsGray3 }} />}
        </>
    )
}

export default NavBar
