import { gql } from "./generated"

export const SEND_CODE = gql(`
    mutation sendCode($email: EmailAddress!) {
        sendCode(email: $email)
    }
`)

export const LOGIN = gql(`
    mutation authenticate($email: EmailAddress!, $code: String!) {
        authenticate(input: { email: $email, code: $code }) {
            jwt
            user {
                id
                email
                firstName
                lastName
            }
        }
    }
`)

export const START_THREAD = gql(`
    mutation StartThread(
        $programGUID: String!
        $threadGUID: String!
        $context: SessionContextInput
    ) {
        startThread(programGUID: $programGUID, threadGUID: $threadGUID, context: $context) {
            sessionID
            status
            currentObjectID
            challenger {
                id
                email
                firstName
                lastName
                company
                role
                city
                state
                pronouns
                description
                emoji
                profileImageURL
            }
            userAnswers {
                object
                name
                type
                value {
                    boolean
                    integer
                    string
                    valueID
                    valueIDs
                }
                systemResponse {
                    choiceData {
                        id
                        count
                        total
                        percent
                        correct
                    }
                    content
                    correct
                    correctness
                    percentComplete
                    score {
                        basePoints
                        bonusPoints
                        points
                    }
                }
            }
            variables {
                name
                type
                value {
                    boolean
                    integer
                    string
                    valueID
                    valueIDs
                    selectedChoices {
                        id
                        text
                        imageURL
                    }
                }
            }
            thread {
                id
                guid
                preview
                synopsis
                blocks
                lastUpdateTime
                title
                version
                threadVersionID
                font
                theme
                hasScore
                imageURL
                instructor {
                    id
                    email
                    firstName
                    lastName
                    company
                    role
                    city
                    state
                    description
                    emoji
                    pronouns
                    profileImageURL
                }
                attachments {
                    guid
                    changes
                    type
                    url
                }
            }
        }
    }
`)

export const CANCEL_AI_EDITING = gql(`
mutation aiCancelEditing(
    $threadGUID: String!
) {
    aiCancelEditing(threadGUID: $threadGUID) {
        id
        guid
        preview
        synopsis
        blocks
        lastUpdateTime
        title
        version
        threadVersionID
        theme
        font
        attachments {
            guid
            changes
            type
            url
        }
        instructor {
            id
            email
            firstName
            lastName
            company
            role
            city
            state
            description
            emoji
            pronouns
            profileImageURL
        }
        aiParams {
            instructorProfile
            referenceMaterial
            prompt
        }
        aiEditing
    }
}
`)

export const CREATE_THREAD = gql(`
    mutation createThread($input: CreateThreadInput) {
        createThread(input: $input) {
            id
            guid
            blocks
            lastUpdateTime
            title
            version
            threadVersionID
            instructor {
                id
                email
                firstName
                lastName
                company
                role
                city
                state
                description
                emoji
                pronouns
                profileImageURL
            }
            aiParams {
                instructorProfile
                referenceMaterial
                prompt
            }
            aiEditing
        }
    }
`)

export const GENERATE_THREAD = gql(`
    mutation generateThread($input: GenerateThreadInput) {
        generateThread(input: $input) {
            id
            guid
            blocks
            lastUpdateTime
            title
            version
            threadVersionID
            instructor {
                id
                email
                firstName
                lastName
                company
                role
                city
                state
                description
                emoji
                pronouns
                profileImageURL
            }
            aiParams {
                instructorProfile
                referenceMaterial
                prompt
            }
            aiEditing
        }
    }
`)

export const EDIT_THREAD = gql(`
    mutation editThread(
        $threadId: Int!
        $threadVersionId: Int!
        $edit: EditThreadInput!
    ) {
        editThread(
            threadId: $threadId,
            threadVersionId: $threadVersionId,
            edit: $edit
        ) {
            id
            guid
            blocks
            lastUpdateTime
            title
            version
            threadVersionID
            imageURL
            hasCustomCoverImage
            instructor {
                id
                email
                firstName
                lastName
                company
                role
                city
                state
                description
                emoji
                pronouns
                profileImageURL
            }
        }
    }
`)

export const DUPLICATE_THREAD = gql(`
    mutation duplicateThread(
        $threadID: Int!
        $threadVersionID: Int
    ) {
        cloneThread(
            threadID: $threadID,
            threadVersionID: $threadVersionID
        ) {
            id
            guid
            blocks
            lastUpdateTime
            title
            version
            threadVersionID
            instructor {
                id
                email
                firstName
                lastName
                company
                role
                city
                state
                description
                emoji
                pronouns
                profileImageURL
            }
        }
    }
`)

export const ADD_PROGRAM_PARTICIPANTS = gql(/* GraphQL */ `
    mutation addParticipants($guid: String!, $participants: [ParticipantInput!]) {
        addProgramParticipants(guid: $guid, participants: $participants) {
            id
            registered
            hidden
            firstName
            lastName
            email
            company
            imageURL
        }
    }
`)

export const SET_PARTICIPANT_VISIBILITY = gql(/* GraphQL */ `
    mutation setParticipantVisibility($programGUID: String!, $userID: Int!, $hidden: Boolean!) {
        setProgramParticipantVisibility(
            programGUID: $programGUID
            userID: $userID
            hidden: $hidden
        ) {
            id
            registered
            hidden
            firstName
            lastName
            email
            company
            imageURL
        }
    }
`)

export const CREATE_LINK = gql(`
    mutation createLink(
        $programID: Int
        $threadID: Int
        $n: Int
    ) {
        createLink(
            input: {
                programID: $programID
                threadID: $threadID
                n: $n
            }
        ) {
            id
            linkURL
        }
    }
`)

export const GET_PATH_FROM_LINK_TOKEN = gql(`
    mutation getPathFromLinkToken($token: String!) {
        getPathFromLinkToken(token: $token)
    }
`)

export const LOGIN_AS = gql(`
    mutation loginAs($id: Int!) {
        loginAs(id: $id) {
            jwt
        }
    }
`)

export const REPORT_ERROR = gql(`
    mutation reportError($error: FrontEndError!) {
        reportFrontEndError(error: $error)
    }
`)

export const SIGN_UP = gql(`
    mutation signUp(
        $email: EmailAddress!
        $firstName: String!
    ) {
        signUp(
            input: {
                email: $email
                firstName: $firstName
            }
        ) {
            jwt
            user {
                id
                email
                firstName
            }
        }
    }
`)

export const AI_GENERATE_QUESTION_TOPIC = gql(`
    mutation aiGenerateQuestionTopic($specs: AIGenerateQuestionTopicInput!) {
        aiGenerateQuestionTopic(specs: $specs)
    }
`)

export const AI_GENERATE_WRITING_TOPIC = gql(`
    mutation aiGenerateContinueWritingTopic($specs: AIGenerateContinueWritingTopicInput!) {
        aiGenerateContinueWritingTopic(specs: $specs)
    }
`)

export const ADD_EVENT = gql(`
    mutation addEvent($input: AddEventInput!) {
        addEvent(input: $input)
    }
`)

export const CLONE_PROGRAM = gql(`
    mutation cloneProgram($guid: String!) {
        cloneProgram(guid: $guid) {
            id
            guid
            name
            live
            startTime
            endTime
            imageURL
            socialSidebar
            isSingleShareThread
            leaderboard
        }
    }
`)

export const CREATE_ATTACHMENT = gql(`
    mutation createAttachment($file: Upload!, $changes: String) {
        createAttachment(file: $file, changes: $changes) {
            guid
            changes
            type
            url
        }
    }
`)

export const CREATE_ATTACHMENT_FROM_HANDLE = gql(`
    mutation createAttachmentFromHandle($handle: String!) {
        createAttachmentFromHandle(handle: $handle) {
            guid
            changes
            type
            url
        }
    }
`)

export const CREATE_ATTACHMENT_FROM_URL = gql(`
    mutation createAttachmentFromUrl($imageUrl: String!) {
        createAttachmentFromUrl(imageUrl: $imageUrl) {
            guid
            changes
            type
            url
        }
    }
`)

export const CREATE_PROGRAM = gql(`
    mutation createProgram {
        createProgram {
            id
            guid
            name
            live
            startTime
            endTime
            imageURL
            socialSidebar
            isSingleShareThread
            leaderboard
        }
    }
`)

export const DELETE_PROGRAM = gql(`
    mutation deleteProgram($guid: String!) {
        deleteProgram(guid: $guid)
    }
`)

export const DELETE_THREAD = gql(`
    mutation deleteThread($threadId: Int!) {
        deleteThread(threadId: $threadId) {
            threadId
        }
    }
`)

export const MARK_THREAD_AS_PUBLISHED = gql(`
    mutation markThreadAsPublished($threadId: Int!, $threadVersionId: Int!) {
        markThreadAsPublished(threadId: $threadId, threadVersionId: $threadVersionId) {
            id
            time
            editor {
                id
                firstName
                lastName
                profileImageURL
            }
            published
        }
    }
`)

export const MODIFY_ATTACHMENT = gql(`
    mutation modifyAttachment($guid: String!, $changes: String) {
        modifyAttachment(guid: $guid, changes: $changes) {
            guid
            changes
            type
            url
        }
    }
`)

export const REMOVE_PROGRAM_PARTICIPANTS = gql(`
    mutation removeParticipants($guid: String!, $emails: [String!]) {
        removeProgramParticipants(guid: $guid, emails: $emails)
    }
`)

export const RESET_THREAD = gql(`
    mutation resetThread($threadId: Int!, $programId: Int!) {
        resetThread(threadId: $threadId, programId: $programId)
    }
`)

export const SET_NOTIFICATION_CONFIG = gql(`
    mutation setProgramNotificationConfig($guid: String!, $connection: String!, $channel: String!) {
        setProgramNotificationConfig(guid: $guid, connection: $connection, channel: $channel) {
            id
            name
            channels {
                id
                name
            }
        }
    }
`)

export const SET_PROGRAM_CONTENTS = gql(`
    mutation setProgramContents($guid: String!, $content: [ProgramContentInput!]) {
        setProgramContents(guid: $guid, content: $content) {
            __typename
            ... on SlackMessage {
                title
                body
                deliveryTime
                link
                attachment {
                    guid
                    changes
                    url
                }
                clientData
            }
            ... on EmailMessage {
                title
                body
                deliveryTime
                link
                attachment {
                    guid
                    changes
                    url
                }
                clientData
            }
            ... on ProgramThread {
                thread {
                    id
                    guid
                    title
                }
                lockTime
                unlockTime
                clientData
                messages {
                    __typename
                    ... on SlackMessage {
                        title
                        body
                        deliveryTime
                        link
                        attachment {
                            guid
                            changes
                            url
                        }
                        clientData
                    }
                    ... on EmailMessage {
                        title
                        body
                        deliveryTime
                        link
                        attachment {
                            guid
                            changes
                            url
                        }
                        clientData
                    }
                }
            }
        }
    }
`)

export const UPDATE_PROGRAM = gql(`
    mutation updateProgram($guid: String!, $updates: ProgramUpdate!) {
        updateProgram(guid: $guid, updates: $updates) {
            id
            guid
            name
            live
            startTime
            endTime
            imageURL
            socialSidebar
            isSingleShareThread
            leaderboard
            displayName
            description
            socialFeatures
            threadsInOrder
            updatedAt
            defaultSender
        }
    }
`)

export const UPDATE_USER_PROFILE = gql(`
    mutation updateUserProfile(
        $firstName: String
        $lastName: String
        $company: String
        $role: String
        $city: String
        $state: String
        $description: String
        $emojis: String
        $links: String
        $pronouns: String
        $profileImageGuid: String
    ) {
        updateUserProfile(
            input: {
                firstName: $firstName
                lastName: $lastName
                company: $company
                role: $role
                city: $city
                state: $state
                description: $description
                emojis: $emojis
                links: $links
                pronouns: $pronouns
                profileImageGuid: $profileImageGuid
            }
        ) {
            id
        }
    }
`)

export const UPDATE_USER_PROFILE_IMAGE = gql(`
    mutation updateUserProfileImage($profileImageGuid: String!) {
        updateUserProfile(input: { profileImageGuid: $profileImageGuid }) {
            profileImageURL
        }
    }
`)
