import { AI_POPUP_CONTENT_HEIGHT, AI_POPUP_PADDING_TOP, StyledAiPopup } from "./styles"
import { FC, useMemo, useRef } from "react"
import useOnClickOutside from "../../../hooks/onClickOutside.hook"
import { AI_POPUP_FOOTER_HEIGHT } from "./AiPopupFooter/styles"
import { CSSObject } from "styled-components"

export interface AiPopupProps {
    onCancel: () => void
    parentHeight?: number
    parentWidth?: number
    position?: "bottom" | "top"
    showTitle?: boolean
    styles?: CSSObject
}

const AiPopup: FC<AiPopupProps> = ({
    onCancel,
    parentHeight = 0,
    parentWidth,
    children,
    position,
    styles,
}) => {
    const popupRef = useRef<HTMLDivElement>(null)

    // TODO: try to do this with a dynamic calc
    // https://codingbeautydev.com/blog/react-get-element-height-before-render/
    const placeBelow = useMemo(() => {
        const top = popupRef?.current?.getBoundingClientRect().top || 0
        const aiPopupHeight =
            AI_POPUP_CONTENT_HEIGHT + AI_POPUP_PADDING_TOP + AI_POPUP_FOOTER_HEIGHT
        return window.innerHeight - 66 - top - aiPopupHeight > 0
    }, [popupRef?.current])

    useOnClickOutside(popupRef, onCancel)

    return (
        <StyledAiPopup
            ref={popupRef}
            parentHeight={parentHeight}
            parentWidth={parentWidth}
            position={position ?? placeBelow ? "bottom" : "top"}
            marginTop={position === "top" ? 12 : undefined}
            marginBottom={position === "bottom" ? 12 : undefined}
            style={styles}
        >
            {children}
        </StyledAiPopup>
    )
}

export default AiPopup
