import styled, { css } from "styled-components"
import { FontOverride, StyledBlockContent } from "../../../styles/styledcomponents"
import { ExpandMore } from "@mui/icons-material"

export const StyledBreakpoint = styled(StyledBlockContent)`
    padding: 0px;
`

interface StyledBreakpointInputProps {
    active: boolean
}

export const StyledBreakpointInput = styled.div<StyledBreakpointInputProps>`
    align-self: flex-end;
    min-width: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.thread.student.bubble};
    cursor: pointer;
    transition: all 0.3s;

    ${({ active }) =>
        active &&
        css`
            display: block;
            background-color: ${({ theme }) => theme.headlandsWhite};
            border: 1px solid ${({ theme }) => theme.colors.thread.student.bubble};
            border-radius: 100px;
            min-width: 125px;
            overflow: hidden;
            width: unset;
            height: unset;
            padding: 0 36px;
            cursor: default;

            .quill {
                margin: 0;
                border: none;
                box-shadow: none;

                ${({ theme }) => FontOverride(theme.fonts.thread)}

                &.focused {
                    border: none !important;
                    box-shadow: none !important;

                    .ql-editor {
                        p {
                            color: ${({ theme }) => theme.headlandsGrayDark};
                        }
                    }
                }
                .ql-blank {
                    &::before {
                        font-style: normal !important;
                    }
                }

                .ql-editor {
                    text-align: center;
                    padding: 6px 16px;

                    p {
                        color: ${({ theme }) => theme.colors.thread.student.bubble} !important;
                        transition: color 100ms;
                    }
                }
            }
        `}
`

export const StyledChevron = styled(ExpandMore)`
    color: ${({ theme }) => theme.colors.thread.student.bubble};
`
