import { ThreadTemplate } from "../../../../apollo/generated/graphql"
import { TemplateItem } from "../Template"

export const MOCKED_TEMPLATES: TemplateItem[] = [
    {
        name: "Blank Canvas",
        description: "Create a thread from scratch",
        icon: "🗒️",
    },
    {
        name: "Trivia Quiz",
        description: "Share a simple trivia quiz on any topic",
        icon: "🎲",
        templateName: ThreadTemplate.TriviaQuizV2,
    },
    {
        name: "Interactive Lesson",
        description: "Teach any topic in minutes",
        icon: "📱",
        disabled: true,
    },
    {
        name: "Book Summary",
        description: "Summarize a book with interactive examples",
        icon: "📖",
        disabled: true,
    },
    {
        name: "Tutor",
        description: "Tutor someone on any topic",
        icon: "🧑‍🏫",
        disabled: true,
    },
    {
        name: "Test",
        description: "Create a quick quiz on any topic",
        icon: "❓",
        disabled: true,
    },
    {
        name: "Job Assessment",
        description: "Evaluate job candidates in any role",
        icon: "📝",
        disabled: true,
    },
    {
        name: "Cognitive Behavioral Therapist",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        icon: "🧠",
        disabled: true,
    },
    {
        name: "Personal Reflection Coach",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        icon: "🤝",
        disabled: true,
    },
]
