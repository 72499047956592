/*
 * This file contains some useful functions to handle the graphql apollo cache
 * */
import { ApolloCache } from "@apollo/client"
import { apolloClient } from ".."
import { AddParticipantsMutation, Thread } from "./generated/graphql"
import {
    ABOUT_ME,
    ENROLLED_PROGRAMS,
    ENROLLED_PROGRAM_THREADS,
    GET_CREATOR_THREAD,
    GET_PROGRAMS,
    GET_PROGRAM_PARTICIPANTS,
    GET_THREAD_VERSION_HISTORY_BY_GUID,
    SEARCH_THREADS,
} from "./queries"
import { compact, set } from "lodash"
import produce from "immer"

/*
 * This function 'deletes' (it really evicts it but the result is the same) a given item from the cache.
 * */
export const deleteItemFromCache = (
    cache: ApolloCache<any>,
    __typename: string,
    id: number | string
) => {
    const normalizedId = cache.identify({ id, __typename })
    cache.evict({ id: normalizedId })
    cache.gc()
}

/*
 * This function adds a `SearchThread` item to the `SEARCH_THREADS` query
 */
export const addThreadCreatorToSearchList = (cache: ApolloCache<any>, item: Thread) => {
    try {
        const userID = cache.readQuery({
            query: ABOUT_ME,
        })?.aboutMe.id

        // Update query for "Team Threads"
        cache.updateQuery(
            {
                query: SEARCH_THREADS,
                variables: {
                    input: {
                        query: "",
                    },
                },
            },
            (data) => ({ searchThreads: data?.searchThreads?.concat({ thread: item }) })
        )
        // Update query for "My Threads"
        cache.updateQuery(
            {
                query: SEARCH_THREADS,
                variables: {
                    input: {
                        query: "",
                        userID: userID,
                    },
                },
            },
            (data) => ({ searchThreads: data?.searchThreads?.concat({ thread: item }) })
        )
    } catch (error) {
        console.error(error)
    }
}

export const updateCreatorThread = (cache: ApolloCache<any>, thread: Thread) => {
    try {
        const userID = cache.readQuery({
            query: ABOUT_ME,
        })?.aboutMe.id

        // Update query for "Team Threads"
        cache.updateQuery(
            {
                query: SEARCH_THREADS,
                variables: {
                    input: {
                        query: "",
                    },
                },
            },
            (data) => ({
                searchThreads: data?.searchThreads?.map((searchResult) => {
                    if (searchResult.thread.id === thread.id) return { ...searchResult, thread }
                    else return searchResult
                }),
            })
        )
        // Update query for "My Threads"
        cache.updateQuery(
            {
                query: SEARCH_THREADS,
                variables: {
                    input: {
                        query: "",
                        userID: userID,
                    },
                },
            },
            (data) => ({
                searchThreads: data?.searchThreads?.map((searchResult) => {
                    if (searchResult.thread.id === thread.id) return { ...searchResult, thread }
                    else return searchResult
                }),
            })
        )

        // update the active creator thread
        cache.writeQuery({
            query: GET_CREATOR_THREAD,
            variables: { threadGUID: thread.guid! },
            data: { getThreadByGuid: thread },
        })
    } catch (error) {
        console.error(error)
    }
}

/*
 * This function adds a `ThreadVersionHistory` item to the `GET_THREAD_VERSION_HISTORY_BY_GUID` query
 * */
export const addThreadVersionToHistoryList = (
    cache: ApolloCache<any>,
    item: any,
    threadGUID: string
) => {
    try {
        // gets the version history data
        const historyData = cache.readQuery({
            query: GET_THREAD_VERSION_HISTORY_BY_GUID,
            variables: {
                threadGUID,
            },
        })

        if (!historyData) return

        // add the new version to the history data
        cache.writeQuery({
            query: GET_THREAD_VERSION_HISTORY_BY_GUID,
            variables: {
                threadGUID,
            },
            data: {
                getThreadVersionHistoryByGuid: [...historyData.getThreadVersionHistoryByGuid, item],
            },
        })
    } catch (error) {
        console.error(error)
    }
}

/*
 * This functions updates an enrolled program value by path, meaning that it will update the enrolled program
 * specific attribute (given on the path, like `progress.percentComplete`) with the given value.
 */
export const updateEnrolledProgramByPath = (programGuid: string, path: string, value: any) => {
    try {
        const enrolledProgramData = apolloClient.readQuery({ query: ENROLLED_PROGRAMS })

        if (!enrolledProgramData?.enrolledPrograms) return

        const updatedData = produce(enrolledProgramData, (draft) => {
            const programIndex = draft.enrolledPrograms!.findIndex(
                (program) => program.guid === programGuid
            )
            if (programIndex > -1) set(draft.enrolledPrograms![programIndex], path, value)
        })

        if (!updatedData) return

        apolloClient.writeQuery({
            query: ENROLLED_PROGRAMS,
            data: updatedData,
        })
    } catch (error) {
        console.error(error)
    }
}

/*
 * This functions updates an enrolled program thread value by path, meaning that it will update the enrolled program
 * thread specific attribute (given on the path, like `progress`) with the given value.
 */
export const updateEnrolledProgramThreadByPath = (
    programGuid: string,
    threadGuid: string,
    path: string,
    value: any
) => {
    try {
        const enrolledProgramThreadsData = apolloClient.readQuery({
            query: ENROLLED_PROGRAM_THREADS,
            variables: { guid: programGuid },
        })

        if (!enrolledProgramThreadsData?.enrolledProgramThreads) return

        const updatedData = produce(enrolledProgramThreadsData, (draft) => {
            const threadIndex = draft.enrolledProgramThreads!.findIndex(
                (enrolledThread) => enrolledThread.thread.guid === threadGuid
            )
            if (threadIndex > -1) set(draft.enrolledProgramThreads![threadIndex], path, value)
        })

        if (!updatedData) return

        apolloClient.writeQuery({
            query: ENROLLED_PROGRAM_THREADS,
            variables: { guid: programGuid },
            data: updatedData,
        })
    } catch (error) {
        console.error(error)
    }
}

export const updateProgramParticipants = (
    programGuid: string,
    updatedParticipants: NonNullable<AddParticipantsMutation["addProgramParticipants"]>
) => {
    try {
        apolloClient.writeQuery({
            query: GET_PROGRAM_PARTICIPANTS,
            variables: { guid: programGuid },
            data: {
                programParticipants: compact(updatedParticipants),
            },
        })
    } catch (error) {
        console.error(error)
    }
}

/*
 * This function adds a `Program` item to the `GET_PROGRAMS` query
 * */
export const addProgramToSearchList = (cache: ApolloCache<any>, item: any) => {
    try {
        // gets the program 'search' list
        const programList: any = cache.readQuery({
            query: GET_PROGRAMS,
            variables: {
                search: "",
            },
        })

        // add the new program to the search list
        cache.writeQuery({
            query: GET_PROGRAMS,
            variables: {
                search: "",
            },
            data: {
                program: [item, ...programList.program],
            },
        })
    } catch (error) {
        console.error(error)
    }
}
