import { IconProps } from "../../types"
import { useTheme } from "styled-components"

const EditIcon = ({ width = 24, height = 24, color }: IconProps) => {
    const theme = useTheme()

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_26480_1140)">
                <path
                    d="M14.0609 9.02L14.9809 9.94L5.92095 19H5.00095V18.08L14.0609 9.02ZM17.6609 3C17.4109 3 17.1509 3.1 16.9609 3.29L15.1309 5.12L18.8809 8.87L20.7109 7.04C21.1009 6.65 21.1009 6.02 20.7109 5.63L18.3709 3.29C18.1709 3.09 17.9209 3 17.6609 3ZM14.0609 6.19L3.00095 17.25V21H6.75095L17.8109 9.94L14.0609 6.19Z"
                    fill={color ?? theme.colors.base.uiBgIcon}
                />
            </g>
            <defs>
                <clipPath id="clip0_26480_1140">
                    <rect
                        width={width}
                        height={height}
                        fill="transparent"
                        transform="translate(0.000946045)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default EditIcon
