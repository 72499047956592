import { Article, Remove } from "@mui/icons-material"
import { useEffect, useMemo, useRef, useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { useTheme } from "styled-components"
import { Button } from "../../../components/Button/Button"
import Checkbox from "../../../components/Checkbox"
import PopupMenu, { PopupOptionType } from "../../../components/PopupMenu"
import {
    StyledBox,
    StyledColumn,
    StyledH3,
    StyledH5,
    StyledH6,
    StyledP,
    StyledRow,
    StyledSpan,
} from "../../../styles/styledcomponents"
import {
    BaseDeliverMessage,
    DeliverAttachment,
    DeliverMessage,
    DeliverProgramContent,
    DeliverProgramContentType,
    DeliverThread,
    MessagingProviders,
    PartialRecursive,
    SlackMessage,
} from "../../../types"
import { StyledCard } from "../../styles"
import NoContent from "../NoContent"
import { DatePicker, TimePicker, TimezonePicker } from "../../../components/DateTimePickers"
import {
    StyledAddIcon,
    StyledAddMessageContainer,
    StyledArticleIcon,
    StyledChannelPopup,
    StyledChevronDown,
    StyledCollapsibleContainer,
    StyledCollapsibleRow,
    StyledDragIndicator,
    StyledErrorIcon,
    StyledLinkIcon,
    StyledLinkIconBase,
    StyledLockIcon,
    StyledPlayIcon,
    StyledRowHeader,
    StyledSendIcon,
} from "./styles"
import { addHours, isValid, parse } from "date-fns"
import AddThreadModal from "../AddThreadModal"
import MessageProviderImage from "../MessageProviderImage"
import Input from "../../../components/InputNew"
import { useResizeDetector } from "react-resize-detector"
import RichTextEditor from "../../../components/RichTextEditorNew"
import ImageUpload from "../ImageUpload"
import { GET_PROGRAM_CONTENTS } from "../../../apollo/queries"
import { useMutation, useQuery } from "@apollo/client"
import {
    useSelectedDeliverProgram,
    useSelectedProgramGuid,
} from "../../../hooks/selectedProgram.hook"
import { SET_PROGRAM_CONTENTS, UPDATE_PROGRAM } from "../../../apollo/mutations"
import {
    generateUUID,
    getMessagingProviderByTypename,
    mapProgramContentForSaving,
    removeHTML,
} from "../../../utils/utils"
import produce from "immer"
import usePrevious from "../../../hooks/previous.hook"
import { compact, debounce, uniq } from "lodash"
import { areContentsDifferent } from "../../utils"
import InstructorSelect from "../../../creator/components/InstructorSelect"
import { GET_ALL_INSTRUCTORS, GET_PROGRAM_NOTIFICATION_CONFIG } from "../../../apollo/queries"
import { Person, Thread } from "../../../apollo/generated/graphql"
import designerFundLogo from "../../../assets/images/designer-fund-green.png"
import EllipsisText from "../../../components/EllipsisText"
import ChannelsModal from "../ChannelsModal"
import Modal from "../../../components/Modal"
import { useToast } from "../../../hooks/useToast.hook"
import { timezones } from "../../consts"
import { baseSortableOptions } from "../../../utils/consts"
import { StyledInputLabel } from "../../../components/InputNew/styles"

type SortableDeliverContent = DeliverProgramContent & { id: string }

/*
 * Here we add:
 *  - `id` field for react-sortablejs
 *  - `attachmentGUID` field for messages that have attachments. If we don't add it
 *  the BE deletes them when saving changes via the setProgramContents mutation
 */
const mapProgramContents = (contents: DeliverProgramContent[]): SortableDeliverContent[] =>
    contents.map((content: DeliverProgramContent) => {
        let newContent = { ...content }
        // @ts-ignore
        newContent.id = newContent.clientData
        if ((newContent as DeliverMessage).attachment) {
            ;(newContent as DeliverMessage).attachmentGUID = (
                newContent as DeliverMessage
            ).attachment?.guid
        }
        if ((newContent as DeliverThread).messages?.length) {
            ;(newContent as DeliverThread).messages = mapProgramContents(
                (newContent as DeliverThread).messages
            ) as DeliverMessage[]
        }
        return newContent as SortableDeliverContent
    })

const defaultSenderObject: Partial<Person> = {
    id: -1,
    firstName: "Designer",
    lastName: "Fund",
    profileImageURL: designerFundLogo,
}

const ContentCard = () => {
    const theme = useTheme()
    const [addThreadModalOpen, setAddThreadModalOpen] = useState(false)
    const [contents, setContents] = useState<SortableDeliverContent[] | null>(null)
    const prevContents = usePrevious(contents)
    const [messagingProvider, setMessagingProvider] = useState<MessagingProviders>()

    const programThreadGuids = useMemo(() => {
        return (
            (contents?.filter(
                (content) => content.__typename === DeliverProgramContentType.ProgramThread
            ) as DeliverThread[]) || []
        ).map((thread: DeliverThread) => thread.thread.guid)
    }, [contents])

    const { program } = useSelectedDeliverProgram()

    const programGuid = useSelectedProgramGuid()

    const { loading: contentsLoading } = useQuery(GET_PROGRAM_CONTENTS, {
        variables: { guid: programGuid },
        onCompleted: ({ programContents }) => {
            // only set the content the first time
            if (contents) return
            if (programContents) {
                setContents(mapProgramContents(programContents))
            } else {
                setContents([])
            }
        },
        // this policy was added because we want to fetch the data everytime the user selects a program on
        // deliver page
        fetchPolicy: "network-only",
    })

    const { data: instructorsData } = useQuery(GET_ALL_INSTRUCTORS)

    const instructorsList = instructorsData?.allInstructors
        ? [defaultSenderObject, ...instructorsData.allInstructors]
        : []

    const defaultSender = instructorsData?.allInstructors.find(
        (instructor: Partial<Person>) => instructor.id === program?.defaultSender
    )

    const handleDefaultSenderChange = (sender: Partial<Person>) => {
        const isDefaultSender = sender.id === -1
        void updateProgram({
            variables: {
                guid: programGuid,
                updates: {
                    defaultSender: isDefaultSender ? null : sender.id,
                },
            },
        })
    }

    const { data: notificationConfig } = useQuery(GET_PROGRAM_NOTIFICATION_CONFIG, {
        fetchPolicy: "no-cache",
        skip: !programGuid,
        variables: { guid: programGuid! },
    })

    const slackConfiguration = useMemo(() => {
        return {
            workspace: notificationConfig?.programNotificationConfig?.name,
            channel: notificationConfig?.programNotificationConfig?.channels
                ? notificationConfig?.programNotificationConfig?.channels[0].name
                : undefined,
        }
    }, [notificationConfig])

    const [updateProgram] = useMutation(UPDATE_PROGRAM)

    const handleThreadsInOrderChange = () => {
        void updateProgram({
            variables: {
                guid: programGuid,
                updates: {
                    threadsInOrder: !program?.threadsInOrder,
                },
            },
        })
    }

    const [setProgramContents, { error: setProgramContentsError }] =
        useMutation(SET_PROGRAM_CONTENTS)

    const handleSetContents = useRef(
        debounce((newContents: DeliverProgramContent[]) => {
            setProgramContents({
                variables: {
                    guid: programGuid,
                    content: mapProgramContentForSaving(newContents),
                },
            })
        }, 200)
    ).current

    /*
     * This effect check whether the content must be saved or not
     * */
    useEffect(() => {
        const shouldSave = prevContents && contents && areContentsDifferent(prevContents, contents)
        shouldSave && handleSetContents(contents)
    }, [contents])

    const handleAddThreads = (threads: PartialRecursive<Thread>[]) => {
        const newContents = contents?.concat(
            threads.map((thread) => ({
                __typename: DeliverProgramContentType.ProgramThread,
                thread,
                id: thread.guid,
                clientData: thread.guid,
                unlockTime: addHours(new Date(), 1).toISOString(),
                lockTime: null,
            })) as SortableDeliverContent[]
        )
        newContents && setContents(newContents)
    }

    const handleRemoveThread = (threadGuid: string) => {
        const newContents = contents?.filter(
            (content: DeliverProgramContent) =>
                content.__typename !== DeliverProgramContentType.ProgramThread ||
                (content as DeliverThread).thread.guid !== threadGuid
        )
        newContents && setContents(newContents)
    }

    const handleAddMessage = () => {
        const newGuid = generateUUID()
        const newContents = contents?.concat({
            __typename: DeliverProgramContentType.SlackMessage,
            id: newGuid,
            clientData: newGuid,
            title: "",
            body: "",
            link: "",
            deliveryTime: addHours(new Date(), 1).toISOString(),
        })
        newContents && setContents(newContents)
    }

    const handleRemoveMessage = (messageGuid: string) => {
        const newContents = contents?.filter(
            (content) =>
                content.__typename !== DeliverProgramContentType.SlackMessage ||
                (content as SlackMessage).clientData !== messageGuid
        )
        newContents && setContents(newContents)
    }

    const handleContentChange = (newContent: SortableDeliverContent, index: number) => {
        if (!contents) return
        const newContents = produce(contents, (draft) => {
            draft[index] = newContent
        })
        setContents(newContents)
    }

    const selectedMessageProviders = useMemo(
        () =>
            compact(
                uniq(
                    contents?.reduce((providers: string[], content: SortableDeliverContent) => {
                        const contentAsThread = content as DeliverThread
                        if (!!contentAsThread.messages) {
                            return providers.concat(
                                content.__typename,
                                contentAsThread.messages.map((message) => message.__typename)
                            )
                        } else {
                            return providers.concat(content.__typename)
                        }
                    }, [])
                ).map(getMessagingProviderByTypename)
            ),
        [contents]
    )

    return (
        <StyledCard>
            <StyledBox
                css={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
                <StyledH3>Content</StyledH3>
                {!!contents?.length && (
                    <StyledRow css={{ gap: 6 }}>
                        <Button
                            label="Add Message"
                            width={120}
                            onClick={handleAddMessage}
                            variant={"outline"}
                        />
                        <Button
                            label="Add Thread"
                            width={104}
                            onClick={() => setAddThreadModalOpen(true)}
                        />
                    </StyledRow>
                )}
            </StyledBox>
            <hr />
            {!contentsLoading &&
                (!contents?.length ? (
                    <NoContent
                        text={"No Threads have been added"}
                        icon={Article}
                        buttonLabel={"Add Thread"}
                        onAdd={() => setAddThreadModalOpen(true)}
                    />
                ) : (
                    <StyledBox>
                        <StyledBox css={{ display: "flex", justifyContent: "space-between" }}>
                            <StyledColumn>
                                <StyledH5 css={{ color: theme.headlandsGrayDark, margin: 0 }}>
                                    Messaging
                                </StyledH5>
                                <StyledRow
                                    css={{
                                        gap: 18,
                                        flexWrap: "wrap",
                                        marginTop: 6,
                                    }}
                                >
                                    <StyledColumn>
                                        <StyledInputLabel>Sender</StyledInputLabel>
                                        <InstructorSelect
                                            instructors={instructorsList}
                                            value={defaultSender || defaultSenderObject}
                                            onChange={handleDefaultSenderChange}
                                            selectProps={{
                                                placeholder: "Default sender...",
                                                styles: {
                                                    container: () => ({
                                                        minWidth: "200px",
                                                        flex: "unset",
                                                    }),
                                                },
                                            }}
                                        />
                                    </StyledColumn>
                                    <StyledColumn>
                                        <StyledInputLabel css={{ fontSize: "12px" }}>
                                            Channels
                                        </StyledInputLabel>
                                        <StyledRow css={{ marginTop: 6, gap: 6 }}>
                                            {Object.values(MessagingProviders).map(
                                                (provider, index) => (
                                                    <StyledChannelPopup
                                                        key={`channel-popup-${index}`}
                                                        position={"top center"}
                                                        on={"hover"}
                                                        mouseEnterDelay={300}
                                                        mouseLeaveDelay={0}
                                                        arrow={false}
                                                        offsetY={12}
                                                        disabled={
                                                            !selectedMessageProviders.includes(
                                                                provider
                                                            )
                                                        }
                                                        trigger={
                                                            <div>
                                                                <MessageProviderImage
                                                                    key={provider}
                                                                    provider={provider}
                                                                    disabled={
                                                                        !selectedMessageProviders.includes(
                                                                            provider
                                                                        )
                                                                    }
                                                                    onClick={() =>
                                                                        setMessagingProvider(
                                                                            provider
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                    >
                                                        <StyledSpan>
                                                            <strong>
                                                                Workspace:{" "}
                                                                {slackConfiguration.workspace}
                                                            </strong>
                                                        </StyledSpan>
                                                        <br />
                                                        <StyledSpan>
                                                            <strong>
                                                                Channel:{" "}
                                                                {slackConfiguration.channel}
                                                            </strong>
                                                        </StyledSpan>
                                                    </StyledChannelPopup>
                                                )
                                            )}
                                        </StyledRow>
                                    </StyledColumn>
                                </StyledRow>
                            </StyledColumn>
                            <StyledColumn>
                                <StyledH5 css={{ color: theme.headlandsGrayDark, margin: 0 }}>
                                    Settings
                                </StyledH5>
                                <StyledColumn
                                    css={{ display: "flex", marginTop: "6px", gap: "12px" }}
                                >
                                    <Checkbox
                                        selected={program?.threadsInOrder}
                                        onChange={handleThreadsInOrderChange}
                                        label={"Threads must be taken in order"}
                                        labelPosition={"right"}
                                    />
                                    <StyledRow css={{ alignItems: "center", gap: "6px" }}>
                                        <TimezonePicker
                                            // TODO: Integrate
                                            value={timezones[0]}
                                            onChange={() => undefined}
                                        />
                                        <StyledInputLabel>Timezone</StyledInputLabel>
                                    </StyledRow>
                                </StyledColumn>
                            </StyledColumn>
                        </StyledBox>
                        {!!contents && (
                            <ReactSortable
                                /*
                                 * We're not sure why this works. It was taken from here:
                                 * https://github.com/SortableJS/react-sortablejs/issues/149
                                 */
                                list={contents?.map((content) => ({ ...content, chosen: true }))}
                                setList={setContents}
                                style={{ marginTop: 18 }}
                                {...baseSortableOptions}
                            >
                                {contents?.map((content: DeliverProgramContent, index: number) => {
                                    switch (content.__typename) {
                                        case DeliverProgramContentType.ProgramThread:
                                            const thread = content as DeliverThread
                                            return (
                                                <CollapsibleThreadRow
                                                    key={thread.thread.guid}
                                                    thread={thread}
                                                    defaultSender={
                                                        defaultSender || defaultSenderObject
                                                    }
                                                    onRemove={handleRemoveThread}
                                                    onThreadChange={(newThread: DeliverThread) =>
                                                        handleContentChange(
                                                            newThread as SortableDeliverContent,
                                                            index
                                                        )
                                                    }
                                                />
                                            )
                                        case DeliverProgramContentType.SlackMessage:
                                            const message = content as DeliverMessage
                                            return (
                                                <CollapsibleMessageRow
                                                    key={message.clientData}
                                                    message={message}
                                                    defaultSender={
                                                        defaultSender || defaultSenderObject
                                                    }
                                                    onRemove={handleRemoveMessage}
                                                    onMessageChange={(newMessage: DeliverMessage) =>
                                                        handleContentChange(
                                                            newMessage as SortableDeliverContent,
                                                            index
                                                        )
                                                    }
                                                />
                                            )
                                        default:
                                            return null
                                    }
                                })}
                            </ReactSortable>
                        )}
                    </StyledBox>
                ))}
            {addThreadModalOpen && (
                <AddThreadModal
                    onSubmit={handleAddThreads}
                    closeModal={() => setAddThreadModalOpen(false)}
                    selectedThreadGuids={programThreadGuids}
                />
            )}
            {!!messagingProvider && (
                <ChannelsModal
                    provider={messagingProvider}
                    onClose={() => setMessagingProvider(undefined)}
                />
            )}
            <Modal
                open={!!setProgramContentsError}
                closeOnDocumentClick={false}
                closeOnEscape={false}
            >
                <StyledH3>
                    <StyledErrorIcon sx={{ color: theme.headlandsError }} /> An error has occurred
                </StyledH3>
                <StyledP css={{ margin: "15px 0", textAlign: "center" }}>
                    The program couldn't be saved, reload and try again
                </StyledP>
                <StyledBox css={{ display: "flex", gap: "15px" }}>
                    <Button label={"Reload"} onClick={() => window.location.reload()} />
                </StyledBox>
            </Modal>
        </StyledCard>
    )
}

interface CollapsibleThreadRowProps {
    thread: DeliverThread
    defaultSender: Partial<Person>
    onRemove: (threadGuid: string) => void
    onThreadChange: (newThread: DeliverThread) => void
}

const CollapsibleThreadRow = ({
    thread,
    defaultSender,
    onRemove,
    onThreadChange,
}: CollapsibleThreadRowProps) => {
    const [open, setOpen] = useState(false)

    const { height: contentHeight, ref: contentRef } = useResizeDetector()

    const toggleExpanded = () => setOpen(!open)

    const theme = useTheme()

    const { showToast } = useToast()

    const handleAddMessage = () => {
        onThreadChange({
            ...thread,
            messages: (thread.messages || []).concat({
                __typename: DeliverProgramContentType.SlackMessage,
                clientData: generateUUID(),
                title: "",
                body: "",
                link: "",
                deliveryTime: thread.unlockTime || addHours(new Date(), 1).toISOString(),
            }),
        })
    }

    const handleRemoveMessage = (messageGuid: string) => {
        onThreadChange({
            ...thread,
            messages: thread.messages.filter(
                (message: DeliverMessage) => message.clientData !== messageGuid
            ),
        })
    }

    const handleMessageChange = (newMessage: DeliverMessage, index: number) => {
        const newMessages = produce(thread.messages, (draft) => {
            draft[index] = newMessage
        })
        onThreadChange({ ...thread, messages: newMessages })
    }

    const copyThreadLink = () => {
        const currentUrl = window.location.href
        const programUrl = currentUrl.replace("deliver", "program")
        const threadUrl = programUrl.concat(`/thread/${thread.thread.guid}`)
        navigator.clipboard.writeText(threadUrl).catch((e) => console.log(e))
        showToast({
            icon: <StyledLinkIconBase sx={{ color: "white" }} />,
            message: "Thread link copied to clipboard",
        })
    }

    return (
        <StyledCollapsibleRow>
            <StyledRowHeader>
                <PopupMenu
                    position={"right bottom"}
                    on={["click"]}
                    arrow={false}
                    overlayStyle={{ backgroundColor: "transparent" }}
                    options={[
                        {
                            label: "Remove",
                            icon: Remove,
                            onClick: () => onRemove(thread.thread.guid),
                            styles: { color: `${theme.headlandsError}!important` },
                            type: PopupOptionType.TEXT_AND_ICON,
                        },
                    ]}
                    trigger={<StyledDragIndicator className="drag-handle" />}
                />
                <StyledArticleIcon />
                <StyledRow css={{ flex: 1, alignItems: "center" }}>
                    <StyledH6 css={{ marginRight: "6px" }}>{thread.thread.title}</StyledH6>
                    <StyledLinkIcon onClick={copyThreadLink} />
                </StyledRow>
                <StyledPlayIcon sx={{ opacity: thread.unlockTime ? 1 : 0.3 }} />
                <DatePicker
                    value={thread.unlockTime}
                    inputFormat={"MMM do"}
                    onChange={(newValue: any) =>
                        handleDateTimePickerChange(newValue, (newDate: string | null) => {
                            onThreadChange({
                                ...thread,
                                unlockTime: newDate,
                            })
                        })
                    }
                    disableMaskedInput
                />
                <TimePicker
                    value={thread.unlockTime}
                    onChange={(newValue: string) => {
                        const parsedDate = parse(
                            newValue,
                            "hh:mm a",
                            thread.unlockTime ? new Date(thread.unlockTime) : new Date()
                        )
                        onThreadChange({
                            ...thread,
                            unlockTime: parsedDate.toISOString(),
                        })
                    }}
                />
                <StyledLockIcon sx={{ opacity: thread.lockTime ? 1 : 0.3 }} />
                <DatePicker
                    value={thread.lockTime}
                    inputFormat={"MMM do"}
                    onChange={(newValue: any) =>
                        handleDateTimePickerChange(newValue, (newDate: string | null) => {
                            onThreadChange({
                                ...thread,
                                lockTime: newDate,
                            })
                        })
                    }
                    disableMaskedInput
                />
                <TimePicker
                    value={thread.lockTime}
                    onChange={(newValue: string) => {
                        const parsedDate = parse(
                            newValue,
                            "hh:mm a",
                            thread.lockTime ? new Date(thread.lockTime) : new Date()
                        )
                        onThreadChange({
                            ...thread,
                            lockTime: parsedDate.toISOString(),
                        })
                    }}
                />
                <StyledChevronDown onClick={toggleExpanded} open={open} />
            </StyledRowHeader>
            <StyledCollapsibleContainer open={open} height={contentHeight}>
                <div ref={contentRef}>
                    <StyledColumn>
                        {thread.messages && (
                            <ReactSortable
                                // @ts-ignore
                                list={thread.messages.map((message: DeliverMessage) => ({
                                    ...message,
                                    // react-sortablejs specifically needs an id field
                                    id: message.clientData,
                                    /*
                                     * We're not sure why this works. It was taken from here:
                                     * https://github.com/SortableJS/react-sortablejs/issues/149
                                     */
                                    chosen: true,
                                }))}
                                setList={(newState) =>
                                    onThreadChange({
                                        ...thread,
                                        messages: newState as unknown as BaseDeliverMessage[],
                                    })
                                }
                                {...baseSortableOptions}
                            >
                                {thread.messages.map((message: DeliverMessage, index: number) => (
                                    <CollapsibleMessageRow
                                        key={message.clientData}
                                        message={message}
                                        defaultSender={defaultSender}
                                        onRemove={handleRemoveMessage}
                                        onMessageChange={(newMessage: DeliverMessage) =>
                                            handleMessageChange(newMessage, index)
                                        }
                                    />
                                ))}
                            </ReactSortable>
                        )}
                        <StyledAddMessageContainer onClick={handleAddMessage}>
                            <StyledAddIcon />
                            <StyledSpan>Add Message</StyledSpan>
                        </StyledAddMessageContainer>
                    </StyledColumn>
                </div>
            </StyledCollapsibleContainer>
        </StyledCollapsibleRow>
    )
}

interface CollapsibleMessageRowProps {
    message: DeliverMessage
    defaultSender: Partial<Person>
    onRemove: (guid: string) => void
    onMessageChange: (newMessage: DeliverMessage) => void
}

const CollapsibleMessageRow = ({
    message,
    defaultSender,
    onRemove,
    onMessageChange,
}: CollapsibleMessageRowProps) => {
    const [open, setOpen] = useState(false)
    const contentRef = useRef<HTMLDivElement>(null)
    const toggleExpanded = () => setOpen(!open)
    const { width: headerWidth, ref: headerRef } = useResizeDetector()

    const theme = useTheme()

    const handleImageChange = (attachment: DeliverAttachment) => {
        onMessageChange({ ...message, attachment, attachmentGUID: attachment.guid })
    }

    return (
        <StyledCollapsibleRow>
            <StyledRowHeader>
                <PopupMenu
                    position={"right bottom"}
                    on={["click"]}
                    arrow={false}
                    overlayStyle={{ backgroundColor: "transparent" }}
                    options={[
                        {
                            label: "Remove",
                            icon: Remove,
                            onClick: () => onRemove(message.clientData as string),
                            styles: { color: `${theme.headlandsError}!important` },
                            type: PopupOptionType.TEXT_AND_ICON,
                        },
                    ]}
                    trigger={<StyledDragIndicator className="drag-handle" />}
                />
                <MessageProviderImage provider={MessagingProviders.SLACK} size={22} />
                <StyledBox
                    css={{ flex: 1, display: "flex", flexDirection: "column", marginRight: 16 }}
                    ref={headerRef}
                >
                    <EllipsisText
                        variant={"span"}
                        text={
                            message.title || `${defaultSender.firstName} ${defaultSender.lastName}`
                        }
                        textStyles={{
                            fontSize: 14,
                            color: theme.colors.base.uiLabelBase,
                            fontWeight: "bold",
                        }}
                        maxWidth={headerWidth ?? 600}
                        disableTooltip
                    />
                    <EllipsisText
                        variant={"span"}
                        text={removeHTML(message?.body as string)}
                        textStyles={{
                            fontSize: 12,
                            color: theme.colors.base.uiLabelDisabled,
                        }}
                        maxWidth={headerWidth ?? 600}
                        disableTooltip
                    />
                </StyledBox>
                <StyledSendIcon />
                <DatePicker
                    value={message.deliveryTime}
                    inputFormat={"MMM do"}
                    onChange={(newValue: any) =>
                        handleDateTimePickerChange(newValue, (newDate: string | null) => {
                            newDate &&
                                onMessageChange({
                                    ...message,
                                    deliveryTime: newDate,
                                })
                        })
                    }
                    disableMaskedInput
                />
                <TimePicker
                    value={message.deliveryTime}
                    onChange={(newValue: string) => {
                        const parsedDate = parse(
                            newValue,
                            "hh:mm a",
                            message.deliveryTime ? new Date(message.deliveryTime) : new Date()
                        )
                        onMessageChange({
                            ...message,
                            deliveryTime: parsedDate.toISOString(),
                        })
                    }}
                />
                <StyledChevronDown onClick={toggleExpanded} open={open} />
            </StyledRowHeader>
            <StyledCollapsibleContainer open={open} height={contentRef.current?.clientHeight}>
                <div ref={contentRef}>
                    <StyledP>Settings</StyledP>
                    <StyledRow css={{ gap: 12, justifyContent: "space-between" }}>
                        <StyledColumn css={{ gap: 12, flex: 1 }}>
                            <Input
                                placeholder={`${defaultSender.firstName} ${defaultSender.lastName}`}
                                value={message.title}
                                onChange={(e) =>
                                    onMessageChange({ ...message, title: e.target.value })
                                }
                                label={"Custom Sender"}
                            />
                            <RichTextEditor
                                label={"Message"}
                                value={message.body}
                                onChange={(newValue: string, delta, source) => {
                                    if (source === "api") return
                                    onMessageChange({ ...message, body: newValue })
                                }}
                            />
                        </StyledColumn>
                        <StyledColumn>
                            <StyledInputLabel>Image</StyledInputLabel>
                            <ImageUpload
                                imageUrl={message.attachment?.url}
                                width={300}
                                height={200}
                                onImageUpload={handleImageChange}
                            />
                        </StyledColumn>
                    </StyledRow>
                </div>
            </StyledCollapsibleContainer>
        </StyledCollapsibleRow>
    )
}

const handleDateTimePickerChange = (
    newValue: string | null,
    callback: (newDate: string | null) => void
) => {
    if (!newValue) {
        callback(null)
    } else {
        const newDate = new Date(newValue)
        isValid(newDate) && callback(newDate.toISOString())
    }
}

export default ContentCard
