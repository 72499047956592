import { IconProps } from "../../types"

export const DeleteIcon = ({ width = 18, height = 18, color = "#888888", ...rest }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M6.16116 16.0537C5.2624 16.0537 4.67975 15.4835 4.63636 14.5971L4.20248 5.57851H3.46488C3.21694 5.57851 3 5.36777 3 5.10744C3 4.85331 3.21694 4.64256 3.46488 4.64256H6.27893V3.69421C6.27893 2.79545 6.86157 2.25 7.81612 2.25H10.1715C11.126 2.25 11.7087 2.79545 11.7087 3.69421V4.64256H14.5413C14.7955 4.64256 15 4.85331 15 5.10744C15 5.36157 14.7955 5.57851 14.5413 5.57851H13.7975L13.3698 14.5909C13.3264 15.4773 12.7314 16.0537 11.845 16.0537H6.16116ZM7.26446 3.7562V4.64256H10.7231V3.7562C10.7231 3.40289 10.4752 3.17355 10.1033 3.17355H7.8843C7.5124 3.17355 7.26446 3.40289 7.26446 3.7562ZM6.24793 15.1178H11.7459C12.1116 15.1178 12.3781 14.8574 12.3905 14.4855L12.7996 5.57851H5.17562L5.6095 14.4855C5.6281 14.8512 5.89463 15.1178 6.24793 15.1178ZM7.16529 14.0579C6.93595 14.0579 6.78099 13.9153 6.77479 13.6921L6.58884 7.06612C6.58264 6.84917 6.7376 6.70041 6.97314 6.70041C7.19628 6.70041 7.35124 6.84298 7.35744 7.05992L7.54959 13.6921C7.55579 13.9091 7.40083 14.0579 7.16529 14.0579ZM9 14.0579C8.76446 14.0579 8.60331 13.9091 8.60331 13.6921V7.06612C8.60331 6.84917 8.76446 6.70041 9 6.70041C9.23554 6.70041 9.40289 6.84917 9.40289 7.06612V13.6921C9.40289 13.9091 9.23554 14.0579 9 14.0579ZM10.8409 14.0579C10.6054 14.0579 10.4504 13.9091 10.4566 13.6921L10.6426 7.06612C10.6488 6.84298 10.8037 6.70041 11.0269 6.70041C11.2624 6.70041 11.4174 6.84917 11.4112 7.06612L11.2252 13.6921C11.219 13.9153 11.064 14.0579 10.8409 14.0579Z"
                fill={color}
            />
        </svg>
    )
}
