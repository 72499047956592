import styled, { css } from "styled-components"
import { Button } from "../../Button/Button"
import { DisableTextSelection, StyledBody2, StyledBox } from "../../../styles/styledcomponents"

export const StyledSubmitRow = styled.div<{ active?: boolean }>`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-top: 9px;

    ${StyledBody2} {
        font-size: 12px !important;
        color: ${({ theme }) => theme.colors.base.uiBgIcon}!important;
        margin: 0 12px;
    }

    ${({ active }) =>
        !active &&
        css`
            ${StyledBody2} {
                opacity: 0;
                transition: opacity 0.2s;
            }
        `}
`

interface StyledChoiceOptionProps {
    selected?: boolean
    disabled?: boolean
    multiple?: boolean
    active?: boolean
    readonly?: boolean
}

export const StyledChoiceOption = styled(Button)<StyledChoiceOptionProps>`
    margin: 3px 0;
    padding: ${({ theme }) =>
        `calc(${theme.bubbleVerticalPadding} - ${theme.buttonBorder}) calc(${theme.bubbleHorizontalPadding} - ${theme.buttonBorder})`};
    transition: opacity 0.2s;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.thread.student.bubble};
    border-color: ${({ theme }) => theme.colors.thread.student.bubble};
    transition: background-color 0.2s, color 0.2s, border-radius 0.2s, font-weight 0.2s;

    ${({ multiple }) =>
        multiple &&
        css`
            border-radius: 6px;
        `}

    ${({ multiple, active }) =>
        multiple &&
        active &&
        css`
            background-color: ${({ theme }) => theme.colors.thread.student.bubble};
            color: ${({ theme }) => theme.colors.thread.student.font};
        `}

    ${({ selected }) =>
        selected &&
        css`
            background-color: ${({ theme }) => theme.colors.thread.student.bubble};
            color: ${({ theme }) => theme.colors.thread.student.font};
            border-radius: ${({ theme }) => theme.rightBubbleBorderRadius};
            font-weight: normal;
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: ${({ theme }) => theme.disabledOpacity};
            cursor: default;
            ${DisableTextSelection}
        `}

    // the !important is necessary to override button default styles when disabled
    ${({ disabled, selected }) =>
        disabled &&
        selected &&
        css`
            opacity: 1;
        `}

    ${({ readonly }) =>
        readonly &&
        css`
            cursor: default;
            background-color: ${({ theme }) => theme.colors.thread.student.bubble};
            color: ${({ theme }) => theme.colors.thread.student.font};
            padding: 8px 12px;
            justify-content: flex-start;
            font-weight: normal;
            max-width: 70%;
            align-self: flex-end;
            // ellipsis
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
        `}
`

export const StyledChoiceContainer = styled(StyledBox)<{ choicesAreImages?: boolean }>`
    display: flex;
    flex-direction: column;
    min-width: 262px;
    width: fit-content;
    align-self: flex-end;
    padding: 3px 12px;
    box-sizing: border-box;

    ${({ choicesAreImages }) =>
        choicesAreImages &&
        css`
            width: 100% !important;
            align-self: stretch;
        `}
`
