import Popup from "reactjs-popup"
import styled, { css } from "styled-components"
import {
    StyledBox,
    StyledColumn,
    StyledH2,
    StyledH3,
    StyledH4,
    StyledH5Styles,
    StyledP,
    StyledRow,
    StyledSpan,
} from "../../../styles/styledcomponents"
import TextareaAutosize from "react-textarea-autosize"
import { Slider } from "@mui/material"
import LanguageIcon from "@mui/icons-material/LanguageOutlined"
import ColorIcon from "@mui/icons-material/ColorLensOutlined"
import TemplateIcon from "@mui/icons-material/InsertDriveFileOutlined"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { StyledInputContainer } from "../../../components/InputNew/styles"
import { DeleteIcon } from "../../../assets/icons/DeleteIcon"
import { StyledLinkIcon } from "../../../deliver/components/ProgramDetailsCard/styles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const H4Styles = css`
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
`
export const StyledAICreateModal = styled(Popup)`
    &-content {
        display: flex;
        flex-direction: column;
        width: 580px;
        max-height: 95vh;
        overflow-y: auto;
        box-sizing: border-box;
        border-radius: 16px;
        background-color: ${({ theme }) => theme.colors.base.uiBgBase};
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

        ${StyledH2} {
            color: ${({ theme }) => theme.colors.base.uiLabelTitle};
        }

        ${StyledH4} {
            ${H4Styles};
        }

        ${StyledSpan} {
            color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
        }
    }
`

export const StyledModalHeader = styled(StyledColumn)`
    padding: 18px;
    gap: 6px;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.base.uiAi300};
`

export const StyledModalContent = styled(StyledColumn)`
    padding: 24px 60px;
    gap: 24px;

    ${StyledInputContainer} {
        input,
        textarea {
            box-shadow: none !important;
            &:focus,
            &:focus-visible {
                box-shadow: none !important;
            }
        }
    }
`

export const StyledSourcesContainer = styled(StyledColumn)`
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
    padding: 6px;
    gap: 6px;
    max-height: 120px;
    box-sizing: border-box;
    overflow: auto;
`

export const StyledDeleteIcon = styled(DeleteIcon)``

export const StyledSourceUrlContainer = styled(StyledRow)<{ invalid?: boolean; glow?: boolean }>`
    padding: 6px;
    border-radius: 8px;
    align-items: center;
    gap: 6px;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    transition: background-color 0.2s;

    ${StyledLinkIcon} {
        font-size: 20px !important;
        color: ${({ theme }) => theme.colors.base.uiAi500};
    }

    h5 {
        flex: 1;
        cursor: ${({ invalid }) => (!invalid ? "pointer" : "default")};
        ${StyledH5Styles};
    }

    ${StyledDeleteIcon} {
        cursor: pointer;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.base.uiBgHover};
    }

    ${({ glow }) =>
        glow &&
        css`
            background-color: ${({ theme }) => theme.colors.base.uiBgHover};
        `}

    ${({ invalid }) =>
        invalid &&
        css`
            background-color: ${({ theme }) => theme.colors.base.uiError300};

            ${StyledLinkIcon} {
                color: ${({ theme }) => theme.colors.base.uiError500};
            }

            h5 {
                color: ${({ theme }) => theme.colors.base.uiError500};
            }

            &:hover {
                background-color: ${({ theme }) => theme.colors.base.uiError300};
            }
        `}
`

export const StyledTabsContainer = styled(StyledRow)`
    border-bottom: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    gap: 60px;
    justify-content: center;
    padding: 0 36px;
`

export const StyledTab = styled(StyledH3)<{ active?: boolean }>`
    flex: 1;
    text-align: center;
    cursor: pointer;
    border-bottom: 4px solid transparent;
    font-weight: 500;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
    transition-property: border, color;
    transition-duration: 0.2s;
    text-transform: uppercase;
    padding-bottom: 4px;

    ${({ active }) =>
        active &&
        css`
            color: ${({ theme }) => theme.colors.base.uiAi500};
            border-bottom: 4px solid ${({ theme }) => theme.colors.base.uiAi500};
        `}
`

export const StyledInstructorSelectPopup = styled(Popup)`
    &-content {
        width: 460px;
        /* height: 284px; */
        height: 215px;
        background-color: ${({ theme }) => theme.colors.base.uiBgBase};
        border-radius: 16px;
        padding: 6px;
        overflow-y: auto;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    }
`

export const StyledInstructorRow = styled(StyledRow)`
    align-items: center;
    gap: 6px;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.base.uiBgHover};
    }

    ${StyledSpan} {
        color: ${({ theme }) => theme.colors.base.uiLabelBase};
    }
`

export const StyledTextarea = styled(TextareaAutosize)`
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.base.uiLabelDisabled};
    padding: 6px;
    font-size: 14px;
    line-height: 20px;
    outline: none;
    resize: none;

    &::placeholder {
        color: ${({ theme }) => theme.colors.base.uiLabelPlaceholder};
    }
`

const ConfigIconStyle = css`
    color: ${({ theme }) => theme.colors.base.uiAi500};
`

export const StyledLanguageIcon = styled(LanguageIcon)`
    ${ConfigIconStyle}
`

export const StyledColorIcon = styled(ColorIcon)`
    ${ConfigIconStyle}
`

export const StyledTemplateIcon = styled(TemplateIcon)`
    ${ConfigIconStyle}
`

export const StyledChevronDown = styled(ExpandMore)``

export const StyledDropdownsSection = styled(StyledRow)`
    gap: 12px;
    justify-content: center;

    ${StyledP} {
        font-weight: 500;
        color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
    }

    ${StyledSpan} {
        font-size: 12px;
        line-height: 18px;
    }
`

export const StyledSlider = styled(Slider)`
    span {
        color: unset;
    }

    .MuiSlider-markLabel {
        color: ${({ theme }) => theme.colors.base.uiLabelBase};
        font-size: 12px;
        line-height: 18px;
    }
`

export const StyledInstructorSelect = styled(StyledBox)`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    max-height: 40px;
    padding: 6px 6px 3px 6px;
    gap: 8px;

    &:hover {
        cursor: pointer;
    }

    ${StyledH4} {
        flex: 1;
        color: ${({ theme }) => theme.colors.base.uiPrimary500};
    }
`

export const StyledChevron = styled(ExpandMoreIcon)`
    fill: ${({ theme }) => theme.colors.base.uiBgIcon}!important;
`
