import { PopupProps } from "reactjs-popup/dist/types"
import {
    StyledBody2,
    StyledBox,
    StyledChevron,
    StyledHr,
    StyledRow,
    StyledSpan,
} from "../../styles/styledcomponents"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import { SvgIconProps, SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon"
import _ from "lodash"
import { StyledIconWrapper, StyledMenuPopup, StyledMenuPopupOption } from "./styles"
import { useRef } from "react"
import Checkbox from "../Checkbox"
import { StyledMoreIcon } from "../../creator/components/ComponentSelector/styles"

type MuiIcon = OverridableComponent<SvgIconTypeMap> & { muiName: string }

export enum PopupOptionType {
    TEXT,
    TEXT_AND_ICON,
    TEXT_AND_IMAGE,
    TEXT_AND_CHECKBOX,
    SECTION,
    WITH_CHILDREN,
}

type BaseOption = {
    label: string
    onClick?: (e: any) => void
    disabled?: boolean
    styles?: object
    dataTestId?: string
    noDivider?: boolean
    hide?: boolean
    type: PopupOptionType
}

type TextOption = BaseOption & {
    type: PopupOptionType.TEXT
}

export type TextAndIconOption = BaseOption & {
    icon: MuiIcon
    iconProps?: SvgIconProps
    type: PopupOptionType.TEXT_AND_ICON
}

type TextAndImageOption = BaseOption & {
    // TODO: use the correct type for a react component
    image: any
    type: PopupOptionType.TEXT_AND_IMAGE
}

type TextAndCheckboxOption = BaseOption & {
    checkboxId: string
    selected: boolean
    type: PopupOptionType.TEXT_AND_CHECKBOX
}

export type SectionOption = Omit<TextOption, "onClick" | "disabled" | "type"> & {
    options: Option[]
    type: PopupOptionType.SECTION
    image?: any
    icon?: MuiIcon
    iconProps?: SvgIconProps
}

export type WithChildrenOption = Omit<BaseOption, "onClick" | "type"> & {
    options: Option[]
    type: PopupOptionType.WITH_CHILDREN
    icon?: MuiIcon
    iconProps?: SvgIconProps
}

type Option =
    | TextOption
    | TextAndIconOption
    | TextAndImageOption
    | TextAndCheckboxOption
    | SectionOption
    | WithChildrenOption

interface PopupMenuProps extends Omit<PopupProps, "children"> {
    options: Option[]
    closeOnOptionClick?: boolean
}

const PopupMenu = ({ options, closeOnOptionClick = true, ...props }: PopupMenuProps) => {
    const ref = useRef<any>(null)

    const renderOption = (option: Option, index: number) => {
        switch (option.type) {
            case PopupOptionType.SECTION: {
                let SectionIcon = option.icon
                let SectionImage = option.image

                return (
                    <StyledBox css={{ width: "100%" }}>
                        <StyledRow css={{ alignItems: "center", gap: "4px", margin: "4px 0" }}>
                            {SectionIcon && (
                                <SectionIcon sx={{ ...option.styles }} {...option.iconProps} />
                            )}
                            {SectionImage && <SectionImage />}
                            <StyledBody2>{option.label}</StyledBody2>
                        </StyledRow>
                        <StyledBox>
                            {option.options?.map((option, index) => renderOption(option, index))}
                        </StyledBox>
                    </StyledBox>
                )
            }
            case PopupOptionType.WITH_CHILDREN: {
                let WithChildrenIcon = option.icon
                return (
                    <PopupMenu
                        position={["right center", "left center"]}
                        on={["hover"]}
                        arrow={false}
                        overlayStyle={{ backgroundColor: "transparent" }}
                        offsetX={6}
                        trigger={
                            <StyledMenuPopupOption
                                disabled={option.disabled}
                                key={`popup-menu-option-${index}`}
                                css={{ justifyContent: "space-between" }}
                                style={{ ...option.styles }}
                            >
                                <StyledRow css={{ alignItems: "center", gap: "4px" }}>
                                    {WithChildrenIcon ? (
                                        <WithChildrenIcon {...option.iconProps} />
                                    ) : (
                                        <StyledMoreIcon />
                                    )}
                                    <StyledSpan>{option.label}</StyledSpan>
                                </StyledRow>
                                <StyledChevron
                                    sx={{ transform: "rotate(-90deg)", fontSize: "14px" }}
                                />
                            </StyledMenuPopupOption>
                        }
                        options={option.options}
                    />
                )
            }
            default: {
                let Icon = null
                let Image = null
                let iconProps = {}
                let checkboxProps: { id: string; selected: boolean } | null = null
                const isTheLastOption = index === options.length - 1

                if ((option as TextAndIconOption).icon) {
                    Icon = (option as TextAndIconOption).icon
                    iconProps = (option as TextAndIconOption).iconProps || {}
                } else if ((option as TextAndImageOption).image) {
                    Image = (option as TextAndImageOption).image
                } else if ((option as TextAndCheckboxOption).checkboxId) {
                    checkboxProps = {
                        id: (option as TextAndCheckboxOption).checkboxId,
                        selected: (option as TextAndCheckboxOption).selected,
                    }
                }

                return _.compact([
                    <StyledMenuPopupOption
                        disabled={option.disabled}
                        onClick={(event) => {
                            if (option.disabled) return
                            option.onClick && option.onClick(event)
                            closeOnOptionClick && ref?.current?.close && ref?.current?.close()
                        }}
                        centered={!Icon && !Image && !checkboxProps}
                        data-testid={option.dataTestId}
                        key={`popup-menu-option-${index}`}
                    >
                        {Icon && (
                            <StyledIconWrapper>
                                <Icon sx={{ ...option.styles }} {...iconProps} />
                            </StyledIconWrapper>
                        )}
                        {Image && <Image />}
                        {checkboxProps && (
                            <Checkbox selected={checkboxProps.selected} onChange={() => {}} />
                        )}
                        <StyledSpan css={{ ...option.styles }}>{option.label}</StyledSpan>
                    </StyledMenuPopupOption>,
                    !isTheLastOption && !option.noDivider ? (
                        <StyledHr key={`popup-menu-option-hr-${index}`} />
                    ) : null,
                ])
            }
        }
    }

    return (
        <StyledMenuPopup {...props} light ref={ref} nested>
            {options.filter((option) => !option.hide).map(renderOption)}
        </StyledMenuPopup>
    )
}

export default PopupMenu
