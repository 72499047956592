import { useEffect, useMemo, useRef, useState } from "react"
import { BlockContext } from "../../../types"
import useStateRef from "../../../hooks/stateRef.hook"
import useOnClickOutside from "../../../hooks/onClickOutside.hook"
import { renderBlockIcon } from "../../../assets/icons/BlockIcon"
import { isBlockTypeAllowedInContext } from "../../../utils/utils"
import {
    StyledComponentSelector,
    StyledIconWrapper,
    StyledOption,
    StyledTextOptionContainer,
} from "./styles"
import { StyledBody1, StyledBody2, StyledColumn, StyledRow } from "../../../styles/styledcomponents"
import {
    ComponentSelectorItem,
    SectionItem,
    SectionItemOption,
    SectionKey,
    sections,
} from "./constants"
import scrollIntoView from "scroll-into-view-if-needed"
import RuleIcon from "@mui/icons-material/Rule"
import { useTheme } from "styled-components"

interface PopupProps {
    handleOptionSelect: (key: ComponentSelectorItem) => void
    onRequestClose: () => void
    position: { top: number; left: number }
    search: string
    context: BlockContext
}

type FlatOption = SectionItemOption & {
    sectionKey: SectionKey
}

const ComponentSelector = ({
    handleOptionSelect,
    onRequestClose,
    position,
    search,
    context,
}: PopupProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [positionModal, setPositionModal] = useState<{ vertical: string; position: number }>({
        vertical: "top",
        position: position.top,
    })
    const [cursor, setCursor, cursorRef] = useStateRef(0)

    const theme = useTheme()

    useEffect(() => {
        if (wrapperRef.current) {
            if (
                wrapperRef.current?.getBoundingClientRect().top -
                    wrapperRef.current?.getBoundingClientRect().height -
                    100 >
                0
            ) {
                setPositionModal({ vertical: "bottom", position: -30 })
            } else {
                setPositionModal({ vertical: "top", position: position.top })
            }
        }
    }, [wrapperRef])

    // returns true if the given option matches the search, it checks the option title and also the option keywords
    const shouldShowOption = (option: SectionItemOption, search: string) => {
        const allKeywords = option.keywords ? [option.title, ...option.keywords] : [option.title]
        const matchesSearch = allKeywords.some((keyword) =>
            keyword.toLowerCase().includes(search.toLowerCase())
        )
        return matchesSearch && isBlockTypeAllowedInContext(option.key, context)
    }

    /*
     * Returns an id for a given option. The id is a combination of the:
     * - section key (because we can have the same option on different sections)
     * - the option key
     * */
    const getOptionId = (option: FlatOption) => {
        return `${option.sectionKey}-${option.key}}`
    }

    const flatOptions: FlatOption[] = useMemo(
        () =>
            sections
                // add the `sectionKey` to each option
                .map((section) =>
                    section.options.map((option) => ({ sectionKey: section.key, ...option }))
                )
                .flat()
                // filter the options given the search
                .filter((option) => shouldShowOption(option, search)),
        // `shouldShowSection` and `shouldShowOption` dependencies are not necessary
        // eslint-disable-next-line
        [search, context, sections]
    )
    useOnClickOutside(wrapperRef, onRequestClose)

    const onKeyDown = (event: any) => {
        switch (event.key) {
            case "Escape":
                onRequestClose()
                break
            case "Enter":
                event.preventDefault()
                !!flatOptions.length && handleOptionSelect(flatOptions[cursorRef.current].key)
                break
            case "ArrowDown":
            case "ArrowUp":
                event.preventDefault()
                // check if the cursor can be moved
                if (event.key === "ArrowDown" && cursorRef.current >= flatOptions.length - 1) return
                else if (event.key === "ArrowUp" && cursorRef.current <= 0) return
                // move the cursor and scroll if needed
                const newCursor = cursorRef.current + (event.key === "ArrowDown" ? 1 : -1)
                const selectedOptionNode =
                    flatOptions[newCursor] &&
                    document.getElementById(getOptionId(flatOptions[newCursor]))
                selectedOptionNode &&
                    scrollIntoView(selectedOptionNode, {
                        scrollMode: "if-needed",
                        behavior: "smooth",
                    })
                setCursor(newCursor)
                break
            case "ArrowLeft":
            case "ArrowRight":
                event.preventDefault()
                break
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown)
        return () => document.removeEventListener("keydown", onKeyDown)
        // `onKeyDown` dependency is not necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flatOptions.length])
    useEffect(() => {
        setTimeout(() => wrapperRef.current?.scrollIntoView({ block: "nearest" }), 0)
    }, [])

    const isOptionSelected = (section: SectionItem, option: SectionItemOption) => {
        const flatOptionSelected = flatOptions[cursor]
        return (
            flatOptionSelected &&
            getOptionId(flatOptionSelected) ===
                getOptionId({
                    sectionKey: section.key,
                    ...option,
                })
        )
    }

    return (
        <StyledComponentSelector
            ref={wrapperRef}
            left={position.left}
            top={positionModal.vertical === "top" ? positionModal.position : undefined}
            bottom={positionModal.vertical === "bottom" ? -positionModal.position : undefined}
            data-testid={"popup-container"}
        >
            {!!flatOptions.length ? (
                sections
                    .filter((section) =>
                        section.options.some((option) => shouldShowOption(option, search))
                    )
                    .map((section) => (
                        <StyledColumn key={section.title}>
                            <StyledRow
                                css={{ gap: "3px", alignItems: "center", padding: "3px 6px" }}
                            >
                                <RuleIcon
                                    sx={{
                                        fontSize: "12px",
                                        color: theme.colors.base.uiLabelSubtitle,
                                    }}
                                />
                                <StyledBody2 css={{ color: theme.colors.base.uiLabelSubtitle }}>
                                    {section.title}
                                </StyledBody2>
                            </StyledRow>
                            {section.options
                                .filter((option) => shouldShowOption(option, search))
                                .map((option: SectionItemOption) => (
                                    <ComponentItem
                                        id={getOptionId({
                                            sectionKey: section.key,
                                            ...option,
                                        })}
                                        key={option.title}
                                        option={option}
                                        selected={isOptionSelected(section, option)}
                                        onSelect={handleOptionSelect}
                                    />
                                ))}
                        </StyledColumn>
                    ))
            ) : (
                <p>No results</p>
            )}
        </StyledComponentSelector>
    )
}

const ComponentItem = ({
    id,
    option,
    selected,
    onSelect,
}: {
    id: string
    option: SectionItemOption
    selected: boolean
    onSelect?: (key: ComponentSelectorItem) => void
}) => {
    return (
        <StyledOption
            selected={selected}
            onClick={onSelect ? () => onSelect(option.key) : undefined}
            id={id}
        >
            <StyledIconWrapper>{option.icon || renderBlockIcon(option.key)}</StyledIconWrapper>
            <StyledTextOptionContainer>
                <StyledBody1>{option.title}</StyledBody1>
                <StyledBody2>{option.description}</StyledBody2>
            </StyledTextOptionContainer>
        </StyledOption>
    )
}

export default ComponentSelector
