import { useState, useCallback, useEffect } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { SubModalProps } from "./MediaModal"
import { Button } from "../../../components/Button/Button"
import { useDropzone } from "react-dropzone"
import { CREATE_ATTACHMENT, CREATE_ATTACHMENT_FROM_URL } from "../../../apollo/mutations"
import { useLazyQuery, useMutation } from "@apollo/client"
import { OverlayOpacityLoader } from "../../../components/Loader"
import { Colors } from "../../../utils/colors"
import imageIcon from "../../../assets/icons/image.svg"
import thumbnail from "../../../assets/icons/thumbnail.svg"
import { ImagePreviewIcon } from "../../../assets/icons/ImagePreviewIcon"
import { addDynamicAttachment } from "../../../redux/threads"
import { useCurrentThreadGuid } from "../../../hooks/currentThread.hook"
import { useDispatch } from "react-redux"
import { isBlockValueEmpty, removeHTML } from "../../../utils/utils"
import {
    StyledBox,
    StyledColumn,
    StyledH4,
    StyledImage,
    StyledP,
    StyledRow,
    StyledSpan,
} from "../../../styles/styledcomponents"
import SearchBar from "../../../deliver/components/SearchBar"
import {
    StyledDropzone,
    StyledDropzoneError,
    StyledImagesContainer,
    StyledTabTitle,
} from "./styles"
import { debounce, isEmpty, times } from "lodash"
import bing from "../../../assets/images/bing.png"
import imagePreviewOutline from "../../../assets/images/image-preview-outline.png"
import unsplash from "../../../assets/images/unsplash.png"
import giphy from "../../../assets/images/giphy.png"
import dallE from "../../../assets/images/dall-e.png"
import RichTextEditor from "../../../components/Input/RichTextEditor"
import { IMAGE_SEARCH } from "../../../apollo/queries"
import { Attachment, ImageResult, ImageSearchProvider } from "../../../apollo/generated/graphql"
import { SkeletonHeadlands } from "../../../common/components/LoaderSkeleton/styles"
import { useTheme } from "styled-components"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { CREATE_ATTACHMENT_FROM_HANDLE } from "../../../apollo/mutations"

export enum ImageModalOption {
    UploadOrUrl = "UPLOAD_OR_URL",
    DallE = "DALL_E",
}

export type ImageModalTab = ImageSearchProvider | ImageModalOption

type ImageModalContentProps = SubModalProps & {
    availableTabs?: ImageModalTab[]
}

const defaultTabsOptions = [
    ImageSearchProvider.Bing,
    ImageSearchProvider.Unsplash,
    ImageSearchProvider.BingGif,
    ImageSearchProvider.Giphy,
    ImageModalOption.DallE,
    ImageModalOption.UploadOrUrl,
]

const ImageModalContent = ({
    onMediaChange,
    mediaUrl,
    setMediaUrl,
    setLoading,
    imageComment = "",
    availableTabs = defaultTabsOptions,
}: ImageModalContentProps) => {
    const threadGuid = useCurrentThreadGuid()
    const dispatch = useDispatch()

    const [providerSearch, setProviderSearch] = useState(imageComment)

    const handleAttachment = (attachment: Attachment) => {
        onMediaChange &&
            onMediaChange({
                value: attachment.url,
                comment: providerSearch,
                attachmentGuid: attachment.guid,
            })
        dispatch(
            addDynamicAttachment({
                threadGuid,
                dynamicAttachment: {
                    guid: attachment.guid,
                    url: attachment.url,
                    type: attachment.type,
                    changes: attachment.changes,
                },
            })
        )
    }

    const handleImageUrl = (imageUrl: string) => {
        createAttachmentFromUrl({ variables: { imageUrl } })
    }

    const [createAttachmentFromUrl, { loading: createFromUrlLoading }] = useMutation(
        CREATE_ATTACHMENT_FROM_URL,
        {
            onCompleted: (res) => handleAttachment(res.createAttachmentFromUrl),
        }
    )

    const handleImageHandle = (handle: string) => {
        createAttachmentFromHandle({ variables: { handle } })
    }

    const [createAttachmentFromHandle, { loading: createFromHandleLoading }] = useMutation(
        CREATE_ATTACHMENT_FROM_HANDLE,
        {
            onCompleted: (res) => handleAttachment(res.createAttachmentFromHandle),
        }
    )

    useEffect(() => {
        setLoading && setLoading(createFromUrlLoading || createFromHandleLoading)
        // setLoading dependency not necessary
        // eslint-disable-next-line
    }, [createFromUrlLoading, createFromHandleLoading])

    const renderTabTitle = (option: ImageModalOption | ImageSearchProvider) => {
        switch (option) {
            case ImageSearchProvider.Bing:
                return (
                    <StyledTabTitle>
                        <img src={bing} alt={"bing-logo"} />
                        <StyledSpan>Bing Images</StyledSpan>
                    </StyledTabTitle>
                )
            case ImageSearchProvider.BingGif:
                return (
                    <StyledTabTitle>
                        <img src={bing} alt={"bing-logo"} />
                        <StyledSpan>Bing GIFs</StyledSpan>
                    </StyledTabTitle>
                )
            case ImageModalOption.UploadOrUrl:
                return (
                    <StyledTabTitle>
                        <img src={imagePreviewOutline} alt={"preview-icon"} />
                        <StyledSpan>Image Upload or URL</StyledSpan>
                    </StyledTabTitle>
                )
            case ImageSearchProvider.Unsplash:
                return (
                    <StyledTabTitle>
                        <img src={unsplash} alt={"unsplash-logo"} />
                        <StyledSpan>Unsplash</StyledSpan>
                    </StyledTabTitle>
                )
            case ImageSearchProvider.Giphy:
                return (
                    <StyledTabTitle>
                        <img src={giphy} alt={"giphy-logo"} />
                        <StyledSpan>Giphy</StyledSpan>
                    </StyledTabTitle>
                )
            case ImageModalOption.DallE:
                return (
                    <StyledTabTitle>
                        <img src={dallE} alt={"dall-e-logo"} />
                        <StyledSpan>DALL·E</StyledSpan>
                    </StyledTabTitle>
                )
        }
    }

    const renderTabContent = (option: ImageModalOption | ImageSearchProvider) => {
        switch (option) {
            case ImageSearchProvider.Bing:
            case ImageSearchProvider.BingGif:
            case ImageSearchProvider.Unsplash:
            case ImageSearchProvider.Giphy:
                return (
                    <ImageProviderSearch
                        provider={option}
                        search={providerSearch}
                        setSearch={setProviderSearch}
                        onImageHandle={handleImageHandle}
                        loading={createFromHandleLoading}
                    />
                )
            case ImageModalOption.UploadOrUrl:
                return (
                    <StyledBox>
                        <StyledH4>URL</StyledH4>
                        <UrlUpload
                            mediaUrl={mediaUrl}
                            setMediaUrl={setMediaUrl}
                            onMediaChange={onMediaChange}
                            onImageUrl={handleImageUrl}
                            loading={createFromUrlLoading}
                        />
                        <StyledH4 css={{ marginTop: "18px" }}>Upload</StyledH4>
                        <DropzoneUpload setLoading={setLoading} onAttachment={handleAttachment} />
                    </StyledBox>
                )
            case ImageModalOption.DallE:
                return <DallEContent />
        }
    }

    return (
        <Tabs>
            <TabList>
                {availableTabs.map((value) => (
                    <Tab key={value}>{renderTabTitle(value)}</Tab>
                ))}
            </TabList>
            {availableTabs.map((value) => (
                <TabPanel key={value}>{renderTabContent(value)}</TabPanel>
            ))}
        </Tabs>
    )
}

type ImageProviderSearchProps = Pick<SubModalProps, "loading"> & {
    provider: ImageSearchProvider
    search: string
    setSearch: (newSearch: string) => void
    onImageHandle: (handle: string) => void
}

// 36 equals to 3 "visible" pages, since we display 12 images at a time
const PROVIDER_PAGE_SIZE = 36

const ImageProviderSearch = ({
    provider,
    loading,
    search,
    setSearch,
    onImageHandle,
}: ImageProviderSearchProps) => {
    const [offset, setOffset] = useState(0)
    const [results, setResults] = useState<ImageResult[]>([])

    const [imageSearch, { data, loading: searchLoading, called }] = useLazyQuery(IMAGE_SEARCH, {
        fetchPolicy: "no-cache",
        onCompleted: ({ imageSearch }) => imageSearch.value && setResults(imageSearch.value),
    })

    const debouncedImageSearch = useCallback(
        debounce(
            (query: string) =>
                imageSearch({
                    variables: {
                        input: {
                            query,
                            provider,
                            count: PROVIDER_PAGE_SIZE,
                            offset: 0,
                        },
                    },
                }),
            500
        ),
        []
    )

    useEffect(() => {
        // Reset the offset when `search` changes
        setOffset(0)
        void debouncedImageSearch(search)
    }, [search])

    const [loadMoreImages, { loading: loadMoreLoading }] = useLazyQuery(IMAGE_SEARCH, {
        fetchPolicy: "no-cache",
        onCompleted: ({ imageSearch }) =>
            imageSearch.value && setResults(results.concat(imageSearch.value)),
    })

    useEffect(() => {
        if (offset > 0) {
            void loadMoreImages({
                variables: {
                    input: {
                        query: search,
                        provider,
                        count: PROVIDER_PAGE_SIZE,
                        offset,
                    },
                },
            })
        }
    }, [offset])

    const [sentryRef] = useInfiniteScroll({
        loading: loadMoreLoading,
        // We asume there's always a next page
        hasNextPage: true,
        onLoadMore: () => setOffset(offset + PROVIDER_PAGE_SIZE),
    })

    const handleImageSelect = (handle: string) => onImageHandle(handle)

    return (
        <StyledBox>
            <OverlayOpacityLoader loading={!!loading} size={48}>
                <SearchBar placeholder={"Find an image"} value={search} onChange={setSearch} />
                {!called || searchLoading ? (
                    <StyledImagesContainer $height={312}>
                        <ImageSkeletons />
                    </StyledImagesContainer>
                ) : isEmpty(data?.imageSearch.value) ? (
                    <StyledSpan>No images found{search ? ` for "${search}"` : ""}</StyledSpan>
                ) : (
                    <StyledImagesContainer $height={312}>
                        {results.map((image, index) => {
                            const isLast = index === results.length - 1
                            return (
                                <StyledImage
                                    // Use the last image as the `sentryRef` for infinite scroll
                                    ref={isLast ? sentryRef : undefined}
                                    key={`image-${index}`}
                                    src={image.thumbnailURL}
                                    alt={"search"}
                                    onClick={() => handleImageSelect(image.handle)}
                                    css={{ height: "100px !important" }}
                                />
                            )
                        })}
                    </StyledImagesContainer>
                )}
            </OverlayOpacityLoader>
        </StyledBox>
    )
}

const ImageSkeletons = () => {
    const theme = useTheme()

    return (
        <>
            {times(12, (index) => (
                <SkeletonHeadlands
                    key={`image-skeleton-${index}`}
                    width={"150px"}
                    height={"100px"}
                    css={{
                        borderRadius: "8px",
                        backgroundColor: theme.colors.base.uiBgSecondary,
                    }}
                />
            ))}
        </>
    )
}

type DropzoneUploadProps = Pick<SubModalProps, "setLoading" | "onAttachment">

const DropzoneUpload = ({ setLoading, onAttachment }: DropzoneUploadProps) => {
    const [imageError, setImageError] = useState(false)
    const [image, setImage] = useState<File>()
    const [fileRejected, setFileRejected] = useState<File>()

    const [createAttachment, { loading }] = useMutation(CREATE_ATTACHMENT, {
        onCompleted: (res) => onAttachment && onAttachment(res.createAttachment),
        onError: () => {
            setImageError(true)
        },
    })

    useEffect(() => {
        setLoading && setLoading(loading)
        // `setLoading` dependency is not necessary
        // eslint-disable-next-line
    }, [loading])

    const handleUploadImage = (event: MouseEvent) => {
        event.stopPropagation()
        image && createAttachment({ variables: { file: image, changes: "{}" } })
    }

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (acceptedFiles.length) {
            setImageError(false)
            setImage(acceptedFiles[0])
        } else {
            setImageError(true)
        }
        if (fileRejections.length) {
            setImageError(true)
            setFileRejected(fileRejections[0]?.file)
        }
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/jpeg, image/png, image/gif",
        maxFiles: 1,
        maxSize: 20000000,
    })

    return (
        <StyledColumn>
            <OverlayOpacityLoader color={Colors.headlandsGreen} size={48} loading={loading}>
                <StyledDropzone error={imageError} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <img src={imageIcon} alt={"icon"} />
                    ) : (
                        <StyledBox>
                            {image ? (
                                <StyledRow css={{ alignItems: "center" }}>
                                    <img
                                        alt={"thumbnail"}
                                        src={thumbnail}
                                        style={{ width: 16, marginRight: 6 }}
                                    />
                                    <StyledP>{image.name}</StyledP>
                                </StyledRow>
                            ) : (
                                <StyledColumn>
                                    <StyledP>
                                        <u>Upload</u> or drop an image here
                                    </StyledP>
                                    <StyledP>JPG, PNG or GIF. Up to 20MB</StyledP>
                                </StyledColumn>
                            )}
                        </StyledBox>
                    )}
                </StyledDropzone>
            </OverlayOpacityLoader>
            {imageError && (
                <StyledDropzoneError>
                    <ImagePreviewIcon />
                    <p>{fileRejected?.name || image?.name}</p>
                </StyledDropzoneError>
            )}
            <Button
                label={"Upload"}
                width={200}
                disabled={!image}
                buttonStyle={{ opacity: !image ? 0.5 : 1, margin: "6px auto 0" }}
                onClick={handleUploadImage}
            />
        </StyledColumn>
    )
}

type UrlUploadProps = Pick<
    SubModalProps,
    "mediaUrl" | "setMediaUrl" | "loading" | "onMediaChange"
> & {
    onImageUrl: (imageUrl: string) => void
}
const UrlUpload = ({
    mediaUrl,
    setMediaUrl,
    onMediaChange,
    loading,
    onImageUrl,
}: UrlUploadProps) => {
    const handleAddUrl = (event: MouseEvent) => {
        event.stopPropagation()
        if (mediaUrl === undefined || mediaUrl === "") return
        if (!mediaUrl.includes("hyperise")) {
            onImageUrl(removeHTML(mediaUrl))
        } else {
            onMediaChange && onMediaChange({ value: mediaUrl })
        }
    }

    return (
        <StyledRow>
            <RichTextEditor
                value={mediaUrl}
                onChange={setMediaUrl}
                placeholder={"Paste or enter URL"}
            />
            <Button
                label={"Add"}
                width={50}
                variant={"outline"}
                buttonStyle={{ marginLeft: "6px" }}
                disabled={loading || isBlockValueEmpty(mediaUrl)}
                onClick={handleAddUrl}
                loading={loading}
            />
        </StyledRow>
    )
}

export const DallEContent = () => {
    const theme = useTheme()
    return (
        <StyledBox>
            <StyledSpan
                css={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    color: theme.colors.base.uiLabelBase,
                }}
            >
                Prompt
            </StyledSpan>
            <StyledRow css={{ marginBottom: "12px" }}>
                <RichTextEditor value={""} onChange={() => {}} placeholder={"Describe the image"} />
                <Button
                    label={"Generate"}
                    width={81}
                    variant={"outline"}
                    buttonStyle={{ marginLeft: "6px" }}
                    disabled={false}
                    onClick={() => {}}
                    loading={false}
                />
            </StyledRow>
            <StyledImagesContainer $height={414} columns={2} rows={2}>
                {/* todo: remove this mocked images */}
                {times(8, (index) => (
                    <img
                        key={`mocked-image-${index}`}
                        src={`https://picsum.photos/612/408?random=${index}`}
                        alt={"mocked"}
                    />
                ))}
            </StyledImagesContainer>
        </StyledBox>
    )
}

export default ImageModalContent
