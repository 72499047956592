import { useState } from "react"
import {
    StyledSettingsBar,
    StyledSettingsBarContent,
    StyledSettingsBarTab,
    StyledSettingsBarTabs,
} from "./styles"
import PropertiesTab from "./PropertiesTab"
import HistoryTab from "./HistoryTab"
import StyleTab from "./StyleTab"

enum SettingBarTab {
    Properties = "Properties",
    Style = "Style",
    History = "History",
}

const tabs = [SettingBarTab.Properties, SettingBarTab.Style, SettingBarTab.History]

const SettingsBar = () => {
    const [selectedTab, setSelectedTab] = useState<SettingBarTab>(SettingBarTab.Properties)

    const renderTab = (tab: SettingBarTab, index: number) => (
        <StyledSettingsBarTab
            selected={selectedTab === tab}
            onClick={() => setSelectedTab(tab)}
            key={`tab-${index}`}
        >
            {tab}
        </StyledSettingsBarTab>
    )

    const renderTabContent = (tab: SettingBarTab) => {
        switch (tab) {
            case SettingBarTab.Properties:
                return <PropertiesTab />
            case SettingBarTab.Style:
                return <StyleTab />
            case SettingBarTab.History:
                return <HistoryTab />
        }
    }

    return (
        <StyledSettingsBar>
            <StyledSettingsBarTabs>
                {tabs.map((tab: SettingBarTab, index) => renderTab(tab, index))}
            </StyledSettingsBarTabs>
            <StyledSettingsBarContent>{renderTabContent(selectedTab)}</StyledSettingsBarContent>
        </StyledSettingsBar>
    )
}

export default SettingsBar
