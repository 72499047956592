import styled, { css } from "styled-components"
import { StyledBox, StyledColumn, StyledH2 } from "../../../../../styles/styledcomponents"

interface StyledThreadScoreHeaderProps {
    dark?: boolean
    isMobile?: boolean
}
export const StyledThreadScoreHeader = styled(StyledBox)<StyledThreadScoreHeaderProps>`
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
    justify-content: center;
    align-items: flex-start;
    background-color: transparent;
    padding: 6px 0 12px 0;
    gap: 13px;
    align-self: center;
    box-sizing: border-box;

    ${({ isMobile }) =>
        isMobile &&
        css`
            justify-content: space-between;
            width: 100%;
            padding: 6px 18px 12px;
        `}

    body1, ${StyledH2} {
        color: ${({ theme, dark }) =>
            dark ? theme.colors.base.uiBgBase : theme.colors.base.uiLabelTitle};
    }
`

export const StyledUserThreadScore = styled(StyledBox)<{ alignment: "right" | "left" }>`
    display: flex;
    flex-direction: ${({ alignment }) => (alignment === "left" ? "row" : "row-reverse")};
    gap: 6px;
    justify-content: flex-start;
`

export const StyledQuestionTimer = styled(StyledColumn)`
    align-items: center;

    ${StyledH2} {
        color: ${({ theme }) => theme.colors.base.uiCorrect};
        line-height: 20px;
    }
`

export const StyledTextsColumn = styled(StyledColumn)<{ alignment: "right" | "left" }>`
    display: flex;
    align-items: ${({ alignment }) => (alignment === "left" ? "flex-start" : "flex-end")};
    gap: 3px;
`
