import styled, { css } from "styled-components"
import {
    StyledBody1,
    StyledBox,
    StyledColumn,
    StyledH1,
    StyledH3,
    StyledH3Styles,
    StyledHr,
} from "../../../styles/styledcomponents"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { PanePreviewContext } from "."
import { StyledEllipsisText } from "../../EllipsisText/styles"

export const StyledPanePreviewContainer = styled(StyledBox)<{
    context?: PanePreviewContext
    $height?: number
    deviceIsMobile?: boolean
}>`
    height: ${({ theme, context, $height = 0, deviceIsMobile }) =>
        context === PanePreviewContext.creator
            ? "unset"
            : deviceIsMobile
            ? `calc(${$height}px - ${theme.variables.common.topBarHeight} - 30px)`
            : `calc(${$height}px - 30px)`};
`

export const StyledPanePreview = styled(StyledBox)<{ isMobile: boolean; isUserApp: boolean }>`
    display: flex;
    flex-direction: column;
    max-width: 481px;
    min-width: 481px;
    min-height: 327px;
    box-sizing: border-box;
    padding: 24px 12px 12px 12px;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border-radius: ${({ theme }) => theme.borderRadius2};
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    align-items: center;

    .block-container {
        width: auto !important;
    }

    ${({ isMobile }) =>
        isMobile &&
        css`
            min-width: unset;
            margin: 12px 12px 0;
        `}

    ${({ isUserApp }) =>
        isUserApp &&
        css`
            background-color: ${({ theme }) => theme.colors.base.uiBgTertiary};
        `}
`

export const StyledEndPaneContentHeader = styled(StyledBox)<{
    disabled?: boolean
    mobile?: boolean
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${({ mobile }) => (mobile ? "24px" : "30px")};

    ${StyledHr} {
        margin: 6px 0;
        color: ${({ theme }) => theme.colors.base.uiBgBorder};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
    ${StyledEllipsisText} {
        ${StyledH3Styles};
    }
`

export const StyledResult = styled(StyledH1)<{ variant?: "winner" | "loser" }>`
    color: ${({ theme }) => theme.colors.base.uiLabelTitle};
    margin: 0;
    text-align: center;

    ${({ variant }) =>
        variant === "winner" &&
        css`
            color: ${({ theme }) => theme.colors.base.uiCorrect};
        `}

    ${({ variant }) =>
        variant === "loser" &&
        css`
            color: ${({ theme }) => theme.colors.base.uiIncorrect};
        `}
`

export const StyledAvatarContainer = styled(StyledColumn)<{ color?: string }>`
    border: 4px solid ${({ theme, color }) => color ?? theme.colors.base.uiPrimary500};
    border-radius: 50%;
    padding: 4px;
`

export const StyledEndPaneScore = styled(StyledColumn)`
    padding: 0 11px;
    gap: 6px;
    overflow: hidden;

    ${StyledH3} {
        font-weight: 700;
        color: ${({ theme }) => theme.colors.base.uiPrimary500};
    }

    ${StyledBody1} {
        color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
    }
`

export const StyledCountUpScoreContainer = styled(StyledBox)<{ withBorder?: boolean }>`
    span {
        ${StyledH3Styles};
        color: ${({ theme }) => theme.colors.base.uiPrimary500};
        display: block;
        justify-content: center;
        padding: 6px 12px;
        min-width: 60px;
        box-sizing: border-box;
        text-align: center;
        width: fit-content;

        ${({ withBorder }) =>
            withBorder &&
            css`
                border-radius: 8px;
                border: 2px solid ${({ theme }) => theme.colors.base.uiPrimary500};
            `}
    }
`

export const StyledScore = styled(StyledH3)<{ withBorder?: boolean }>`
    justify-content: center;
    padding: 6px 12px;
    min-width: 60px;
    box-sizing: border-box;
    text-align: center;

    ${({ withBorder }) =>
        withBorder &&
        css`
            border-radius: 8px;
            border: 2px solid ${({ theme }) => theme.colors.base.uiPrimary500};
        `}
`

export const StyledPlayArrowIcon = styled(PlayArrowIcon)`
    font-size: 18px !important;
    path {
        fill: ${({ theme }) => theme.colors.base.uiPrimary500};
    }
`

export const StyledEndPaneContentContainer = styled(StyledBox)<{ mobile?: boolean }>`
    max-width: 330px;
    min-width: ${({ mobile }) => (mobile ? "unset" : "330px")};
`
