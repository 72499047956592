import styled from "styled-components"
import { StyledBox } from "../../../styles/styledcomponents"
import { MediaSize } from "../../../types"
import { StyledLeftBubble } from "../styles"

interface StyledVideoBubbleProps {
    size: MediaSize
    isMobile: boolean
    isMobilePreview: boolean
}

const getVideoWidthRatio = (size: MediaSize) => {
    switch (size) {
        case MediaSize.small:
            return 1 / 2
        case MediaSize.medium:
            return 3 / 4
        case MediaSize.large:
            return 1
    }
}

export const StyledVideoBubble = styled(StyledLeftBubble)<StyledVideoBubbleProps>`
    padding: 0;
    opacity: 1;
    max-height: ${({ isMobilePreview, theme }) =>
        isMobilePreview ? `cal(${theme.mobilePreviewDeviceHeight} * 0.8)` : "80vh"};
    max-width: calc(
        ${({ theme }) => theme.threadPreviewMaxWidth} - 2 *
            ${({ theme }) => theme.threadPreviewHorizontalPadding} - 2 *
            ${({ theme }) => theme.bubbleHorizontalMargin}
    );
    width: calc(${({ size }) => getVideoWidthRatio(size)} * 100% - 2 * 12px);
`

export const StyledResponsiveVideoContainer = styled(StyledBox)`
    width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    border-radius: inherit;

    iframe {
        margin-bottom: -4px;
        border-radius: inherit;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
`
