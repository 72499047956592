import { Add } from "@mui/icons-material"
import styled, { css } from "styled-components"
import PopupMenu from "../../../components/PopupMenu"
import { StyledMenuPopupOption } from "../../../components/PopupMenu/styles"
import { StyledHr } from "../../../styles/styledcomponents"

const AddChunkButtonStyles = css`
    padding: 12px;
    background-color: ${({ theme }) => theme.headlandsWhite};
    border-radius: ${({ theme }) => theme.borderRadius1};
    color: ${({ theme }) => theme.headlandsGray10};
    cursor: pointer;
    font-size: 18px !important;
`

export const StyledAddChunkButton = styled(Add)<{ open?: boolean }>`
    ${AddChunkButtonStyles};
    transition: border-radius 0.3s !important;

    ${({ open }) =>
        open &&
        css`
            border-radius: ${({ theme }) => theme.borderRadius1} 0 0
                ${({ theme }) => theme.borderRadius1};
        `}
`

export const StyledAddChunkMenu = styled(PopupMenu)`
    @keyframes expand {
        0% {
            opacity: 0;
            clip-path: circle(0% at 0% 0%);
        }
        100% {
            opacity: 1;
            clip-path: circle(150%);
        }
    }

    &-content {
        min-width: 300px;
        box-sizing: border-box;
        border-radius: ${({ theme }) => theme.borderRadius1};
        box-shadow: 4px 1px 4px rgb(0 0 0 / 25%);
        -webkit-animation: expand 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
        padding: 6px;
        border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
        max-width: 240px;
        ${StyledMenuPopupOption} {
            padding: 6px;
            border-radius: ${({ theme }) => theme.borderRadius1};
        }

        ${StyledHr} {
            width: 100%;
            margin: 6px 0;
        }
    }
`
