import styled, { css } from "styled-components"
import { expandRightBubble } from "../ThreadPreview/styles"

export const StyledInstructorAvatar = styled.div<{ preview?: boolean }>`
    height: 30px;
    width: 30px;

    img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 0.5px solid ${({ theme }) => theme.colors.thread.student.bubble};
        object-fit: cover;
    }

    ${({ preview }) =>
        preview &&
        css`
            margin: 0 12px 3px 12px;
            animation: ${expandRightBubble} 0.75s forwards;
            transform-origin: 0 100%;
        `};
`
