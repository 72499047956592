import styled, { css } from "styled-components"
import { StyledBox, StyledImage } from "../../../../styles/styledcomponents"
import { MediaSize } from "../../../../types"

interface StyledImageContainerProps {
    size?: MediaSize
}

const calculateContainerWidth = (size?: MediaSize, iconRowWidth?: string): string => {
    switch (size) {
        case MediaSize.extraSmall:
            return `46px`
        case MediaSize.small:
            return `calc(300px - ${iconRowWidth})`
        case MediaSize.large:
            return `calc(600px - ${iconRowWidth})`
        case MediaSize.medium:
        default:
            return `calc(450px - ${iconRowWidth})`
    }
}

export const StyledImageContainer = styled(StyledBox)<StyledImageContainerProps>`
    object-fit: contain;
    border-radius: 10px 10px 10px 0;
    height: auto;
    width: fit-content;
    display: flex;
    cursor: pointer;
    position: relative;
    transition: width 0.2s;
    max-width: fit-content;

    width: ${({ size, theme }) =>
        calculateContainerWidth(size, theme.variables.creator.iconRowWidth)};

    ${StyledImage} {
        border-radius: 10px 10px 10px 0;
        object-fit: contain;
        width: 100%;
    }
`

interface StyledEmptyImagePlaceholderProps {
    selected?: boolean
    error?: boolean
}

export const StyledEmptyImagePlaceholder = styled(StyledBox)<StyledEmptyImagePlaceholderProps>`
    background-color: ${({ theme }) => theme.colors.base.uiBgTertiary};
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    border-radius: 16px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    min-width: 396px;

    svg {
        fill: ${({ theme }) => theme.colors.base.uiBgIcon};
    }

    ${({ selected }) =>
        selected &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.base.uiPrimary500};
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
        `}

    ${({ error }) =>
        error &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.base.uiError500};
            p {
                color: ${({ theme }) => theme.colors.base.uiError500};
            }
        `}
`
