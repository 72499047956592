import styled from "styled-components"
import { StyledImage } from "../../../../styles/styledcomponents"

export const StyledInstructorAvatar = styled(StyledImage)`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 0.5px solid ${({ theme }) => theme.colors.thread.student.bubble};
    object-fit: cover;
`
