import { IconProps } from "../../types"
import { useTheme } from "styled-components"

const WrongIcon = ({ width = 28, height = 28, color }: IconProps) => {
    const theme = useTheme()

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#wrong_icon_path)">
                <path
                    d="M24 6.01429L21.9857 4L14 11.9857L6.01429 4L4 6.01429L11.9857 14L4 21.9857L6.01429 24L14 16.0143L21.9857 24L24 21.9857L16.0143 14L24 6.01429Z"
                    fill={color ?? theme.colors.base.uiIncorrect}
                />
            </g>
            <defs>
                <clipPath id="wrong_icon_path">
                    <rect width="28" height="28" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default WrongIcon
