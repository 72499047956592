import { StyledAdminScreen, StyledIframe } from "./styles"
import { getAdminUrl } from "../utils/utils"
import { useMemo } from "react"
import TopBar from "../components/TopBar"

const Admin = () => {
    const adminUrl = useMemo(function () {
        let u = new URL(getAdminUrl() || "")
        u.pathname = "/activity"
        return u.href
    }, [])

    return (
        <StyledAdminScreen>
            <TopBar title={"Analyze"} />
            <StyledIframe src={adminUrl} />
        </StyledAdminScreen>
    )
}

export default Admin
