import {
    StyledButtonArrow,
    StyledCarouselArrowsContainer,
    StyledCarouselContent,
    StyledPaginationContainer,
    StyledPaginationIndicator,
    StyledPaginationItem,
} from "./styles"
import { ReactElement, useEffect, useImperativeHandle, useRef, useState } from "react"
import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { StyledBox } from "../../styles/styledcomponents"
import useIntersectionObserver, { IntersectionObserverOptions } from "../../hooks/intersection.hook"
import _, { isEmpty, sortBy } from "lodash"
import scrollIntoView from "scroll-into-view-if-needed"

type CarouselProps<T> = {
    id: string
    items?: T[]
    // Note: Is very important to add this `carrouselItemId` to the `id` of each item rendered.
    // This will be used to observe each item.
    renderItem: (item: T, carrouselItemId: string) => JSX.Element
    scrollToBottom?: (() => void) | undefined
    bottomElement?: ReactElement
    hideNavigationIfAllItemsAreVisible?: boolean
    hideNavigation?: boolean
    showPagination?: boolean
    innerRef?: React.Ref<{ next: () => void; previous: () => void }>
    alignment?: "start" | "end"
    moveEntireRow?: boolean
    intersectionObserverOptions?: Partial<IntersectionObserverOptions>
}

enum Side {
    right,
    left,
}

interface ButtonArrowSlideProps {
    side: Side
    disabled: boolean
    onClick: () => void
}

const ButtonArrowSlide = ({ side, disabled, onClick }: ButtonArrowSlideProps) => {
    return (
        <StyledButtonArrow disabled={disabled} onClick={() => (!disabled ? onClick() : "")}>
            {side === Side.right ? <ChevronRight /> : <ChevronLeft />}
        </StyledButtonArrow>
    )
}

const Carousel = <T extends unknown>({
    id,
    items,
    renderItem,
    bottomElement,
    hideNavigationIfAllItemsAreVisible = true,
    hideNavigation = false,
    showPagination = false,
    innerRef,
    alignment = "start",
    moveEntireRow,
    intersectionObserverOptions,
}: CarouselProps<T>) => {
    useImperativeHandle(innerRef, () => ({
        next: () => handleArrowClick("next"),
        previous: () => handleArrowClick("previous"),
    }))

    const [viewableItemIndexes, setViewableItemIndexes] = useState<number[]>([])
    const viewableItemIndexesRef = useRef<number[]>([])
    const [allItemsAreVisible, setAllItemsAreVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)

    /*
     * There's cases where `viewableItemIndexes` is empty,
     * since we always want the pagination component to display
     * the indicator, we use this state to handle that.
     */
    useEffect(() => {
        if (!isEmpty(viewableItemIndexes)) setCurrentPage(viewableItemIndexes[0])
    }, [viewableItemIndexes])

    const addFullyViewableItem = (elementId: string) => {
        const stringIndex = _.last(elementId.split("-"))
        if (!stringIndex) return
        const intIndex = parseInt(stringIndex)
        const indexAlreadyAdded = viewableItemIndexesRef.current.some(
            (viewableItemIndex: number) => viewableItemIndex === intIndex
        )
        if (!indexAlreadyAdded) {
            viewableItemIndexesRef.current = [...viewableItemIndexesRef.current, intIndex]
            setViewableItemIndexes([...viewableItemIndexesRef.current])
        }
    }

    const removeFullyViewableItem = (elementId: string) => {
        const stringIndex = _.last(elementId.split("-"))
        if (!stringIndex) return
        const intIndex = parseInt(stringIndex)
        const indexAlreadyRemoved = !viewableItemIndexesRef.current.some(
            (viewableItemIndex: number) => viewableItemIndex === intIndex
        )
        if (!indexAlreadyRemoved) {
            viewableItemIndexesRef.current = [
                ...viewableItemIndexesRef.current.filter(
                    (viewableItemIndex: number) => viewableItemIndex !== intIndex
                ),
            ]
            setViewableItemIndexes([...viewableItemIndexesRef.current])
        }
    }

    const { observe: observeElement } = useIntersectionObserver({
        fullyViewableCallback: addFullyViewableItem,
        partialUnViewableCallback: removeFullyViewableItem,
        fullyUnViewableCallback: removeFullyViewableItem,
        observerOptions: {
            root: document.querySelector(`#carousel-scrollable-container-${id}`),
            ...intersectionObserverOptions,
        },
    })

    const getCarrouselItemId = (id: string, index: number) => `carrousel-item-${id}-${index}`

    /*
     * Here we set an intersection observer to all the elements
     * We check whether elements are fully viewable or not
     */
    useEffect(() => {
        if (items) {
            items.forEach((_, index) => {
                const element = document.querySelector(`#${getCarrouselItemId(id, index)}`)
                element && observeElement(element)
            })
        }
    }, [items])

    /*
     * This effect checks if all items are visible only at the first render to know if we have
     * to show the navigation or not
     * */
    useEffect(() => {
        // only the first time, to avoid calculation after first render
        if (!allItemsAreVisible) {
            // only when we have `items` and `viewableItemIndexes`
            if (
                items?.length &&
                viewableItemIndexes.length &&
                items?.length === viewableItemIndexes.length
            ) {
                setAllItemsAreVisible(true)
            }
        }
    }, [items, viewableItemIndexes, allItemsAreVisible])

    const handleArrowClick = (direction: "previous" | "next") => {
        let indexToGo
        const sortedViewableIndexes = sortBy(viewableItemIndexesRef.current)
        if (moveEntireRow) {
            if (direction === "previous") {
                const nextIndex = sortedViewableIndexes[0] - sortedViewableIndexes.length
                indexToGo = nextIndex < 0 ? 0 : nextIndex
            } else {
                const nextIndex = sortedViewableIndexes[sortedViewableIndexes.length - 1] + 1
                indexToGo = nextIndex >= items!.length ? items!.length - 1 : nextIndex
            }
        } else {
            const nextIndex = alignment === "start" ? 0 : viewableItemIndexesRef.current.length - 1
            indexToGo = sortedViewableIndexes[nextIndex] + (direction === "next" ? 1 : -1)
        }
        const node = document.getElementById(`${getCarrouselItemId(id, indexToGo)}`)
        if (indexToGo !== undefined && node) {
            scrollIntoView(node, {
                inline: alignment,
                boundary: document.getElementById(`carousel-scrollable-container-${id}`),
            })
        }
    }

    const handlePageChange = (page: number) => {
        const node = document.getElementById(`${getCarrouselItemId(id, page)}`)
        if (page !== undefined && node) {
            scrollIntoView(node, {
                inline: "start",
                boundary: document.getElementById(`carousel-scrollable-container-${id}`),
            })
        }
    }

    return (
        <StyledBox css={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {/* the content of the carousel */}
            <StyledCarouselContent id={`carousel-scrollable-container-${id}`}>
                {items?.map((item, index) => renderItem(item, getCarrouselItemId(id, index)))}
            </StyledCarouselContent>
            {/* the arrow buttons to move between items */}
            {(hideNavigationIfAllItemsAreVisible ? !allItemsAreVisible : true) && !hideNavigation && (
                <StyledCarouselArrowsContainer>
                    {bottomElement}
                    <ButtonArrowSlide
                        side={Side.left}
                        disabled={viewableItemIndexes.some((index: number) => index === 0)}
                        onClick={() => handleArrowClick("previous")}
                    />
                    <ButtonArrowSlide
                        side={Side.right}
                        disabled={viewableItemIndexes.some(
                            (index: number) => index === (items?.length || 0) - 1
                        )}
                        onClick={() => handleArrowClick("next")}
                    />
                </StyledCarouselArrowsContainer>
            )}
            {showPagination && !!items?.length && (
                <StyledPaginationContainer>
                    {Array.from({ length: items?.length || 0 }).map((_, index) => (
                        <StyledPaginationItem
                            key={`page-indicator-${index}`}
                            onClick={() => handlePageChange(index)}
                        >
                            {currentPage === index && <StyledPaginationIndicator />}
                        </StyledPaginationItem>
                    ))}
                    <StyledBox />
                </StyledPaginationContainer>
            )}
        </StyledBox>
    )
}
export default Carousel
