export const RedoIcon = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.2667 7.06675C11.0333 5.99342 9.43334 5.33341 7.66667 5.33341C4.56667 5.33341 1.94667 7.35342 1.02667 10.1467L2.60001 10.6667C3.30001 8.54008 5.30001 7.00008 7.66667 7.00008C8.96667 7.00008 10.1533 7.48008 11.08 8.25342L8.66667 10.6667H14.6667V4.66675L12.2667 7.06675Z"
                fill="#888888"
            />
        </svg>
    )
}
