import { useTheme } from "styled-components"
import { BoltIcon } from "../../../../../assets/icons/Bolt"
import { UserAvatarWithProgress } from "../../.././../../creator/components/UserAvatar"
import { StyledColumn, StyledH2 } from "../../../../../styles/styledcomponents"
import { AvatarSize } from "../../../../../types"
import {
    StyledQuestionTimer,
    StyledTextsColumn,
    StyledThreadScoreHeader,
    StyledUserThreadScore,
} from "./styles"
import { first, nth, isNil } from "lodash"
import { useCurrentUserAttribute } from "../../../../../hooks/currentUser"
import { Maybe } from "graphql/jsutils/Maybe"
import { RefObject, useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_DYNAMIC_VARIABLES_VALUE } from "../../../../../apollo/queries"
import {
    useCurrentResponseThreadField,
    useCurrentThreadField,
} from "../../../../../hooks/currentThread.hook"
import ShareThreadButton, {
    ShareThreadButtonVariant,
} from "../../../../../components/ThreadPreview/PanePreview/ShareThreadButton"
import useLayout from "../../../../../hooks/layout.hook"
import MetaOverride from "../../../../../components/MetaOverride"
import CountUp from "react-countup"
import { useTimer } from "react-timer-hook"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../redux/store"
import { add } from "date-fns"
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar"
import { AnimatePresence, motion, useAnimationControls } from "framer-motion"
import { useSelectedEnrolledProgramAttribute } from "../../../../../hooks/enrolledProgram.hook"
import EllipsisText from "../../../../../components/EllipsisText"
import useTriviaMatchData from "../../../../../hooks/triviaMatchData.hook"

interface ThreadScoreHeaderProps {
    dark?: boolean
}

const ThreadScoreHeader = ({ dark }: ThreadScoreHeaderProps) => {
    const { isMobile } = useLayout()
    const theme = useTheme()
    const { attribute: firstName } = useCurrentUserAttribute("firstName")
    const { attribute: lastName } = useCurrentUserAttribute("lastName")
    const { attribute: profileImageURL } = useCurrentUserAttribute("profileImageURL")
    const telemetrySessionId = useCurrentResponseThreadField("sessionID")
    const threadId = useCurrentThreadField("id")
    const { attribute: programId } = useSelectedEnrolledProgramAttribute("id")
    const { data: scoreData } = useQuery(GET_DYNAMIC_VARIABLES_VALUE, {
        skip: !telemetrySessionId && (!programId || !threadId),
        variables: {
            input: {
                names: ["threadScore", "threadChallengerScore"],
                telemetrySessionId: telemetrySessionId,
                programID: !telemetrySessionId ? programId : undefined,
                threadID: !telemetrySessionId ? threadId : undefined,
            },
        },
    })
    const { refetchThreadProgressForProgram } = useTriviaMatchData()
    const [threadScore, setThreadScore] = useState<number>(0)
    const [threadChallengerScore, setThreadChallengerScore] = useState<number>(0)

    const challenger = useCurrentResponseThreadField("challenger")

    const questionAvailableTime = useSelector(
        (state: RootState) => state.layoutReducer.questionAvailableTime
    )

    /*
     * Update the scores states every time the related dynamicVariable changes
     */
    useEffect(() => {
        if (scoreData) {
            const ownScore = first(scoreData?.dynamicVariable)?.value?.integer
            const challengerScore = nth(scoreData?.dynamicVariable, 1)?.value?.integer
            if (ownScore) setThreadScore(ownScore)
            if (challengerScore) setThreadChallengerScore(challengerScore)
            // Refetch program participants so score is updated on the Leaderboard/Social bar on the right
            refetchThreadProgressForProgram()
        }
    }, [scoreData])

    return (
        <StyledThreadScoreHeader dark={dark} isMobile={isMobile}>
            {dark && <MetaOverride name="theme-color" content={theme.colors.base.uiBgDark} />}
            <UserThreadScore
                firstName={firstName}
                lastName={lastName}
                score={threadScore}
                profileImageURL={profileImageURL}
                dark={dark}
            />
            <StyledColumn
                css={{
                    width: "50px",
                    height: "50px",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                <AnimatePresence initial={false} exitBeforeEnter>
                    {isNil(questionAvailableTime) ? (
                        <motion.div
                            key={"bolt"}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            style={{ position: "absolute" }}
                            transition={{ duration: 0.3 }}
                        >
                            <BoltIcon
                                color={
                                    dark
                                        ? theme.colors.base.uiBgBase
                                        : theme.colors.base.uiPrimary500
                                }
                            />
                        </motion.div>
                    ) : (
                        <motion.div
                            key={"timer"}
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.5 }}
                            style={{ position: "absolute" }}
                            transition={{ scale: { type: "spring", stiffness: 300 } }}
                        >
                            <QuestionTimer availableTimeInSeconds={questionAvailableTime} />
                        </motion.div>
                    )}
                </AnimatePresence>
            </StyledColumn>
            {challenger ? (
                <UserThreadScore
                    firstName={challenger.firstName}
                    lastName={challenger.lastName}
                    score={threadChallengerScore}
                    profileImageURL={challenger.profileImageURL}
                    dark={dark}
                    alignment={"right"}
                />
            ) : (
                <ShareThreadButton variant={ShareThreadButtonVariant.CIRCLE} dark={dark} />
            )}
        </StyledThreadScoreHeader>
    )
}
interface UserThreadScoreProps {
    firstName?: Maybe<string>
    lastName?: Maybe<string>
    score: number
    profileImageURL?: Maybe<string>
    alignment?: "right" | "left"
    dark?: boolean
    animated?: boolean
}
const UserThreadScore = ({
    firstName,
    lastName,
    profileImageURL,
    score,
    alignment = "left",
    dark,
    animated,
}: UserThreadScoreProps) => {
    const theme = useTheme()
    const controls = useAnimationControls()

    const handleTimerUpdate = async () => {
        if (!animated) return

        await controls.start({
            color: theme.colors.base.uiCorrect,
            scale: 1.5,
            x: 6,
            y: 4,
            transition: { duration: 0.3 },
        })
        controls.start({
            color: theme.colors.base.uiLabelTitle,
            scale: 1,
            x: 0,
            y: 0,
            transition: { delay: 0.7, duration: 0.5 },
        })
    }

    return (
        <StyledUserThreadScore alignment={alignment}>
            <UserAvatarWithProgress
                user={{ profileImageURL, firstName, lastName }}
                size={AvatarSize.L}
                progress={100}
                progressBarStyles={{
                    pathColor: dark ? theme.colors.base.uiBgBase : theme.colors.base.uiPrimary500,
                    trailColor: "transparent",
                    rotation: 0,
                }}
                progressBarProps={{ strokeWidth: 5 }}
            />
            <StyledTextsColumn alignment={alignment}>
                <EllipsisText
                    text={firstName || ""}
                    variant={"body1"}
                    maxWidth={100}
                    textStyles={{ fontWeight: 500, lineHeight: "18px" }}
                />
                <CountUp end={score} preserveValue onUpdate={handleTimerUpdate} duration={1}>
                    {({ countUpRef }) => (
                        <AnimatedScoreH2
                            ref={countUpRef as RefObject<HTMLHeadingElement>}
                            useNewDesign
                            animate={controls}
                        />
                    )}
                </CountUp>
            </StyledTextsColumn>
        </StyledUserThreadScore>
    )
}

const AnimatedScoreH2 = motion(StyledH2)

interface QuestionTimerProps {
    availableTimeInSeconds: number
}

const QuestionTimer = ({ availableTimeInSeconds }: QuestionTimerProps) => {
    const theme = useTheme()

    const { seconds } = useTimer({
        expiryTimestamp: add(new Date(), { seconds: availableTimeInSeconds }),
    })

    return (
        <StyledQuestionTimer>
            <CircularProgressbarWithChildren
                value={seconds}
                strokeWidth={12}
                maxValue={availableTimeInSeconds}
                minValue={0}
                counterClockwise
                styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: theme.colors.base.uiCorrect,
                    trailColor: theme.colors.base.uiPrimary300,
                })}
            >
                <StyledH2 useNewDesign>{seconds}</StyledH2>
            </CircularProgressbarWithChildren>
        </StyledQuestionTimer>
    )
}

export default ThreadScoreHeader
