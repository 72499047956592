import { useQuery } from "@apollo/client"
import { useCurrentThreadGuid } from "./currentThread.hook"
import { GET_THREAD_VERSION_HISTORY_BY_GUID } from "../apollo/queries"
import { orderBy } from "lodash"

const useThreadVersionHistory = () => {
    const threadGuid = useCurrentThreadGuid()
    const queryResult = useQuery(GET_THREAD_VERSION_HISTORY_BY_GUID, {
        variables: { threadGUID: threadGuid },
        skip: !threadGuid,
    })

    const latestVersionIsPublished = orderBy(
        queryResult.data?.getThreadVersionHistoryByGuid,
        "time",
        "desc"
    )[0]?.published

    return { ...queryResult, latestVersionIsPublished }
}

export default useThreadVersionHistory
