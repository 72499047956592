import { IconProps } from "../../types"
import { useTheme } from "styled-components"

export const SparkleIcon = ({ color, width = 18, height = 18 }: IconProps) => {
    const theme = useTheme()
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 8.64C6.85714 7.92 7.71429 5.76 8.57143 0C9.42857 6.48 11.1429 7.92 18 8.64C11.1429 9.36 9.42857 10.8 8.57143 18C7.71429 10.8 6.85714 10.08 0 8.64Z"
                fill={color ?? theme.headlandsAiColor}
            />
            <path
                d="M0.400146 2.79941C2.30491 2.59941 2.543 1.99941 2.7811 0.399414C3.01919 2.19941 3.49538 2.59941 5.40015 2.79941C3.49538 2.99941 3.01919 3.39941 2.7811 5.39941C2.543 3.39941 2.30491 3.19941 0.400146 2.79941Z"
                fill={color ?? theme.headlandsAiColor}
            />
            <path
                d="M2.40039 14.3204C3.9242 14.1604 4.11468 13.6804 4.30515 12.4004C4.49563 13.8404 4.87658 14.1604 6.40039 14.3204C4.87658 14.4804 4.49563 14.8004 4.30515 16.4004C4.11468 14.8004 3.9242 14.6404 2.40039 14.3204Z"
                fill={color ?? theme.headlandsAiColor}
            />
        </svg>
    )
}
