import styled, { css } from "styled-components"
import Popup from "reactjs-popup"
import { StyledH5, StyledMini, StyledRow } from "../../styles/styledcomponents"

export const StyledUserRow = styled(StyledRow)<{ isCurrentUser?: boolean }>`
    align-items: center;
    border-radius: 8px;
    padding: 6px;
    gap: 6px;
    background-color: ${(props) => props.theme.colors.base.uiBgBase};

    ${({ isCurrentUser }) =>
        isCurrentUser &&
        css`
            background-color: ${(props) => props.theme.colors.base.uiBgThreadActive};
        `}

    &:hover {
        background-color: ${(props) => props.theme.colors.base.uiBgThreadActive};
        cursor: pointer;
    }

    ${StyledH5} {
        color: ${({ theme }) => theme.colors.base.uiLabelBase};
    }

    /* EllipsisText uses this tag */
    body2 {
        color: ${({ theme }) => theme.colors.base.uiLabelDisabled};
    }
`

export const StyledPosition = styled(StyledRow)<{ position: number }>`
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    box-sizing: border-box;

    ${StyledMini} {
        line-height: 10px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.base.uiLabelDisabled};
    }

    ${({ position }) => {
        switch (position) {
            case 1:
                return css`
                    background-color: ${({ theme }) => theme.colors.base.uiGold.background};
                    border: 1px solid ${({ theme }) => theme.colors.base.uiGold.border};

                    ${StyledMini} {
                        color: ${({ theme }) => theme.colors.base.uiGold.border};
                    }
                `
            case 2:
                return css`
                    background-color: ${({ theme }) => theme.colors.base.uiSilver.background};
                    border: 1px solid ${({ theme }) => theme.colors.base.uiSilver.border};

                    ${StyledMini} {
                        color: ${({ theme }) => theme.colors.base.uiSilver.border};
                    }
                `
            case 3:
                return css`
                    background-color: ${({ theme }) => theme.colors.base.uiBronze.background};
                    border: 1px solid ${({ theme }) => theme.colors.base.uiBronze.border};

                    ${StyledMini} {
                        color: ${({ theme }) => theme.colors.base.uiBronze.border};
                    }
                `
        }
    }}
`

export const StyledUserInfoPopup = styled(Popup)`
    &-overlay {
        // note: this !important is necessary because on Modal.scss we overwrite the background-color
        background-color: transparent !important;
    }
`

export const StyledProgressPopup = styled(Popup)`
    &-content {
        background-color: ${(props) => props.theme.headlandsGrayDark};
        padding: 6px 12px;
        border-radius: 8px;

        span {
            color: ${(props) => props.theme.headlandsWhite};
            font-size: 14px;
        }
    }
    &-overlay {
        // note: this !important is necessary because on Modal.scss we overwrite the background-color
        background-color: transparent !important;
    }
`
