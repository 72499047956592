import { Block, BlockType, OptionPreviewMode } from "../../types"
import { Choice, UserAnswer } from "../../apollo/generated/graphql"
import TextPreview from "./TextPreview"
import ImagePreview from "./ImagePreview"
import VideoPreview from "./VideoPreview"
import ImageCarousel from "../Carousel/ImageCarousel"
import ImageUploadPreview from "./ImageUploaderPreview"
import BreakpointPreview from "./BreakpointPreview/BreakpointPreview"
import Divider from "../Divider"
import AccordionPreview from "./AccordionPreview"
import PanePreview from "./PanePreview/PanePreview"
import PanePreviewNew, { PanePreviewContext } from "./PanePreviewNew"
import { generateUUID } from "../../utils/utils"
import { InstructorAvatar } from "../InstructorAvatar"
import QuestionsAndAnswersPreview from "./QuestionsAndAnswersPreview"
import { Maybe } from "graphql/jsutils/Maybe"
import TypingIndicator from "./TypingIndicator"
import GroupPreview from "./GroupPreview"
import CommentPreview from "./CommentPreview"
import CodeBlock from "../../creator/components/CodeBlock"
import ChoiceQuestionPreview from "./ChoiceQuestionPreview"
import FreeTextQuestionPreview from "./FreeTextQuestionPreview"

interface BaseRenderPreviewBubbleProps {
    bubble: Block
    index: number
    readonly?: boolean
}

interface RenderPreviewBubblePropsReadonly extends BaseRenderPreviewBubbleProps {
    readonly: true
    answer?: never
    scrollToBottom?: never
    isMobile?: never
    mode?: never
    handleAnswer?: never
    isPreview?: never
    threadId?: never
    threadGuid?: never
    programId?: never
    programGuid?: never
}

interface RenderPreviewBubblePropsInteractive extends BaseRenderPreviewBubbleProps {
    readonly: false
    answer?: Maybe<UserAnswer>
    scrollToBottom: () => void
    isMobile?: boolean
    mode: OptionPreviewMode
    handleAnswer: (options: Choice[]) => void
    isPreview?: boolean
    threadId?: number
    threadGuid?: string
    programId?: number
    programGuid?: string
}

type RenderPreviewBubbleProps =
    | RenderPreviewBubblePropsReadonly
    | RenderPreviewBubblePropsInteractive

const RenderPreviewBlock = ({ bubble, index, readonly, ...rest }: RenderPreviewBubbleProps) => {
    // The default handlers are when `readonly=true` (cause ' we DON'T need them)
    const {
        answer,
        scrollToBottom = () => {},
        isMobile,
        mode,
        handleAnswer = (_: Choice[]) => {},
        isPreview,
        threadId,
        threadGuid,
        programId,
        programGuid,
    } = rest

    if (readonly) {
        // These preview blocks are ONLY use for ai assist content
        switch (bubble.type) {
            case BlockType.GROUP:
                return <GroupPreview block={bubble} />
            case BlockType.COMMENT:
                return <CommentPreview block={bubble} />
        }
    }

    switch (bubble.type) {
        case BlockType.TEXT:
            return <TextPreview key={index} block={bubble} />
        case BlockType.IMAGE:
            return (
                <ImagePreview
                    key={index}
                    block={bubble}
                    scrollToBottom={scrollToBottom}
                    isMobile={isMobile || (isPreview && mode === OptionPreviewMode.MOBILE)}
                />
            )
        case BlockType.VIDEO:
            return <VideoPreview key={index} block={bubble} />
        case BlockType.IMAGE_CAROUSEL:
            return (
                <div style={{ margin: "3px 12px" }} className={"left-animation"}>
                    <ImageCarousel
                        id={bubble.id}
                        imagesList={bubble.choices?.filter((images) => images !== undefined)}
                        scrollToBottom={scrollToBottom}
                    />
                </div>
            )
        case BlockType.IMAGE_UPLOAD:
            /*
             * note: this component doesn't have support for `readonly`.
             * Not sure if there is a use case where the AI will generate an image upload
             * component, if so, we'll need to add support.
             * */
            return (
                <ImageUploadPreview
                    key={index}
                    bubble={bubble}
                    handleAnswer={handleAnswer}
                    answer={answer}
                    threadId={threadId}
                    programId={programId}
                    scrollToBottom={scrollToBottom}
                    isMobile={isMobile || (isPreview && mode === OptionPreviewMode.MOBILE)}
                />
            )
        case BlockType.PAUSE:
            /*
             * note: this component doesn't have support for `readonly`.
             * Not sure if there is a use case where the AI will generate a pause
             * component, if so, we'll need to add support.
             * */

            return (
                <BreakpointPreview
                    key={index}
                    block={bubble}
                    isMobile={isMobile || (isPreview && mode === OptionPreviewMode.MOBILE)}
                    choiceProps={{
                        bubble,
                        handleAnswer,
                        mode: mode!,
                        answer,
                    }}
                />
            )
        case BlockType.DIVIDER:
            return <Divider key={index} bubble={bubble} />
        case BlockType.ACCORDION:
            return <AccordionPreview key={index} bubble={bubble} />
        case BlockType.SYNOPSIS:
            return <PanePreviewNew variant={BlockType.SYNOPSIS} context={PanePreviewContext.user} />
        case BlockType.PREVIEW:
            return (
                <PanePreview
                    key={index}
                    type={bubble.type}
                    isPreview={isPreview}
                    onNextClick={() => handleAnswer([{ id: generateUUID() }])}
                    disableNext={!!answer}
                />
            )
        case BlockType.INSTRUCTOR_AVATAR:
            return <InstructorAvatar preview />
        case BlockType.QUESTIONS_AND_ANSWERS:
            return (
                <QuestionsAndAnswersPreview
                    block={bubble}
                    programGuid={programGuid}
                    threadGuid={threadGuid}
                />
            )
        case BlockType.TYPING_INDICATOR:
            return <TypingIndicator scrollToBottom={scrollToBottom} />
        case BlockType.CODE:
            return <CodeBlock block={bubble} readOnly />
        case BlockType.CHOICE_QUESTION:
            const choiceProps = {
                isMultipleSelection: bubble?.properties?.may_select_multiple,
                choicesAreImages: bubble.options?.some((option) => option.image_url !== undefined),
                bubble,
                answer,
                handleAnswer,
                scrollToBottom,
                readonly,
                mode: mode!,
                isPreview,
                isMobile: isMobile || (isPreview && mode === OptionPreviewMode.MOBILE),
            }
            return (
                <ChoiceQuestionPreview
                    block={bubble}
                    choiceProps={choiceProps}
                    isMobile={isMobile || (isPreview && mode === OptionPreviewMode.MOBILE)}
                />
            )
        case BlockType.FREE_TEXT_QUESTION:
            const freeTextProps = {
                bubble,
                answer,
                handleAnswer,
                scrollToBottom,
                readonly,
                programId,
                isMobile,
                isPreview,
                mode: mode!,
            }
            return (
                <FreeTextQuestionPreview
                    block={bubble}
                    choiceProps={freeTextProps}
                    isMobile={isMobile || (isPreview && mode === OptionPreviewMode.MOBILE)}
                />
            )
        case BlockType.GROUP:
            // we render an empty div with the bubble id to be able to find it when searching for the last bubble rendered
            return <div id={`bubble-${bubble.id}`} />
        default:
            return null
    }
}

export default RenderPreviewBlock
