import styled, { css } from "styled-components"
import { StyledBody1 } from "../../../../styles/styledcomponents"

export const StyledTextBlock = styled(StyledBody1)<{ noBackground?: boolean }>`
    padding: 12px 18px;
    color: ${({ theme }) => theme.colors.base.uiLabelBase};
    border-radius: 16px 16px 16px 2px;
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
    transition: background-color 0.3s;

    p,
    span,
    li,
    h4,
    h6 {
        color: ${({ theme }) => theme.colors.base.uiLabelBase};
    }

    ${({ noBackground }) =>
        noBackground &&
        css`
            background-color: transparent;
        `}
`
