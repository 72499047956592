import { PopupProps } from "reactjs-popup/dist/types"
import { StyledImagePopup } from "./styles"
import ImageModalContent from "../MediaModal/ImageModalContent"
import { useState } from "react"

interface OnImageUploadParams {
    value: string
    comment?: string
    attachmentGuid?: string
}

interface ImagePopupProps extends Omit<PopupProps, "children"> {
    onImageUploaded?: (params: OnImageUploadParams) => void
    closeOnUploaded?: boolean
    comment?: string
}

const ImagePopup = ({ onImageUploaded, closeOnUploaded, comment, ...props }: ImagePopupProps) => {
    const [open, setOpen] = useState(false)

    return (
        <StyledImagePopup
            arrow={false}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            {...props}
        >
            <ImageModalContent
                mediaUrl={""}
                setMediaUrl={() => {}}
                onMediaChange={(options) => {
                    onImageUploaded && onImageUploaded(options)
                    closeOnUploaded && setOpen(false)
                }}
                imageComment={comment}
            />
        </StyledImagePopup>
    )
}

export default ImagePopup
