import BreakpointButton from "../../../../components/BreakpointButton/BreakpointButton"
import { Block } from "../../../../types"
import { questionTypes } from "../../../../utils/consts"
import CreatorBlockMobile from "../CreatorBlockMobile"
import { StyledGroupBlock } from "./styles"

const GroupBlock = ({ block }: { block: Block }) => {
    const objects = block.branches?.[0]?.objects ?? []

    const hasInteractiveChild = objects.some((obj) => questionTypes.includes(obj.type))

    return (
        <StyledGroupBlock>
            {objects.map((innerBlock) => (
                <CreatorBlockMobile block={innerBlock} />
            ))}
            {!hasInteractiveChild && <BreakpointButton white border mobile />}
        </StyledGroupBlock>
    )
}

export default GroupBlock
