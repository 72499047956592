export const QuestionsAndAnswers = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_28251_94556)">
                <path
                    d="M6.75 9C8.1975 9 9.375 7.8225 9.375 6.375C9.375 4.9275 8.1975 3.75 6.75 3.75C5.3025 3.75 4.125 4.9275 4.125 6.375C4.125 7.8225 5.3025 9 6.75 9ZM6.75 5.25C7.3725 5.25 7.875 5.7525 7.875 6.375C7.875 6.9975 7.3725 7.5 6.75 7.5C6.1275 7.5 5.625 6.9975 5.625 6.375C5.625 5.7525 6.1275 5.25 6.75 5.25ZM6.7875 12.75H3.5775C4.32 12.375 5.6025 12 6.75 12C6.8325 12 6.9225 12.0075 7.005 12.0075C7.26 11.46 7.7025 11.01 8.235 10.65C7.6875 10.5525 7.17 10.5 6.75 10.5C4.995 10.5 1.5 11.3775 1.5 13.125V14.25H6.75V13.125C6.75 12.9975 6.765 12.87 6.7875 12.75ZM12.375 10.875C10.995 10.875 8.25 11.6325 8.25 13.125V14.25H16.5V13.125C16.5 11.6325 13.755 10.875 12.375 10.875ZM13.2825 9.51C13.8525 9.1875 14.25 8.58 14.25 7.875C14.25 6.84 13.41 6 12.375 6C11.34 6 10.5 6.84 10.5 7.875C10.5 8.58 10.8975 9.1875 11.4675 9.51C11.7375 9.66 12.045 9.75 12.375 9.75C12.705 9.75 13.0125 9.66 13.2825 9.51Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_28251_94556">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
