import { ContentType } from "../../../apollo/generated/graphql"
import { BlockType } from "../../../types"

export enum SectionKey {
    Media,
    Presentation,
    Misc,
}

export type SectionItem = {
    key: SectionKey
    title: string
    options: SectionItemOption[]
    icon?: JSX.Element
}

export type SectionItemOption = {
    key: ComponentSelectorItem
    title: string
    keywords?: string[]
    description: string
    icon?: string
    containsInteractiveElement?: boolean
    children?: SectionItemOption[]
}

export type ComponentSelectorItem = BlockType | ContentType

export const sections: SectionItem[] = [
    {
        key: SectionKey.Media,
        title: "Media",
        options: [
            {
                key: BlockType.IMAGE,
                title: "Image",
                keywords: ["photo", "picture", "img"],
                description: "Upload or embed with a link",
            },
            {
                key: BlockType.VIDEO,
                title: "Video",
                keywords: ["youtube", "vimeo"],
                description: "Embed from YouTube, Vimeo...",
            },
            {
                key: BlockType.IMAGE_CAROUSEL,
                title: "Image Carousel",
                keywords: ["carousel"],
                description: "Upload multiple images",
            },
            {
                key: BlockType.CODE,
                title: "Code Block",
                keywords: ["code", "programming", "software"],
                description: "Share scripts with your users",
            },
        ],
    },
    {
        key: SectionKey.Presentation,
        title: "Presentation",
        options: [
            {
                key: BlockType.DIVIDER,
                title: "Divider",
                keywords: ["divider", "line", "separator"],
                description: "Visually divide content",
            },
            {
                key: BlockType.ACCORDION,
                title: "Accordion",
                keywords: ["accordion"],
                description: "Show and hide content",
            },
            {
                key: BlockType.INSTRUCTOR_AVATAR,
                title: "Instructor Avatar",
                keywords: ["instructor avatar", "instructor", "avatar"],
                description: "Show an instructor's avatar",
            },
            {
                key: BlockType.CONFETTI,
                title: "Confetti",
                keywords: ["confetti", "party", "celebrate"],
                description: "Show a celebratory animation.",
            },
        ],
    },
    {
        key: SectionKey.Misc,
        title: "Misc",
        options: [
            {
                key: BlockType.IMAGE_UPLOAD,
                title: "Image Upload",
                keywords: ["photo", "picture", "img", "upload"],
                description: "Let users share media with you",
            },
            {
                key: BlockType.QUESTIONS_AND_ANSWERS,
                title: "Collective Wisdom",
                keywords: ["share", "user responses", "responses"],
                description: "Share users responses with each other",
            },
        ],
    },
]
