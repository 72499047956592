import styled from "styled-components"
import { StyledColumn, StyledH1, StyledH3, StyledImage } from "../../styles/styledcomponents"

export const StyledContainer = styled(StyledColumn)`
    position: absolute;
    left: 15%;
    top: 20%;
    justify-content: center;
    align-items: center;
    gap: 18px;
    padding: 18px;
    text-align: center;
    z-index: 10;

    @media only screen and (max-width: 768px) {
        top: 20%;
        left: 0;
        right: 0;
        margin: auto;
    }
`

export const StyledErrorDetailsContainer = styled(StyledColumn)`
    text-align: initial;
    max-height: 40vh;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.base.uiBgTertiary};
    padding: 12px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
`

export const StyledBackgroundImage = styled(StyledImage)`
    position: absolute;
    right: 0;
    height: 100%;

    @media only screen and (max-width: 768px) {
        height: 100%;
        width: 100%;
    }
`

export const StyledHeader = styled(StyledH1)`
    font-size: 90px;
    line-height: 90px;
    color: ${({ theme }) => theme.headlandsGrayDark};
    margin: 0;

    @media only screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 36px;
    }
`

export const StyledBody = styled(StyledH3)`
    font-size: 24px;
    line-height: 30px;
    color: ${({ theme }) => theme.headlandsGrayDark};
    max-width: 376px;

    @media only screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
        max-width: 80%;
    }
`
