import { useEffect } from "react"
import { StyledLeftBubble } from "../styles"
import { StyledTypingDot } from "./styles"

interface TypingIndicatorProps {
    scrollToBottom?: () => void
}

const TypingIndicator = ({ scrollToBottom }: TypingIndicatorProps) => {
    useEffect(() => {
        scrollToBottom && scrollToBottom()
    }, [])
    return (
        <StyledLeftBubble>
            <StyledTypingDot />
            <StyledTypingDot />
            <StyledTypingDot />
        </StyledLeftBubble>
    )
}

export default TypingIndicator
