import { QueryHookOptions, useQuery } from "@apollo/client"
import { useSelectedProgramGuid } from "./selectedProgram.hook"
import { useCurrentThreadGuid } from "./currentThread.hook"
import { THREAD_PROGRESS_FOR_PROGRAM } from "../apollo/queries"

const useThreadProgressForProgram = (options?: Pick<QueryHookOptions, "fetchPolicy">) => {
    const programGUID = useSelectedProgramGuid()
    const threadGUID = useCurrentThreadGuid()
    return useQuery(THREAD_PROGRESS_FOR_PROGRAM, {
        skip: !programGUID || !threadGUID,
        variables: { input: { programGUID: programGUID!, threadGUID: threadGUID! } },
        ...options,
    })
}

export default useThreadProgressForProgram
