import { useEnrolledProgramThreadsByGuid } from "../../hooks/enrolledProgramThread.hook"
import {
    StyledArrowBackIcon,
    StyledLibraryBarContent,
    StyledLibraryTopBar,
    StyledPlaylistHeader,
    StyledScrollableContainer,
} from "./styles"
import { useCallback, useMemo } from "react"
import { SkeletonHeadlands } from "../../common/components/LoaderSkeleton/styles"
import { StyledBody2, StyledBox, StyledColumn, StyledH4 } from "../../styles/styledcomponents"
import LibraryRow, { LibraryRowSkeletonLoader, LibraryRowType } from "./LibraryRow"
import { useTheme } from "styled-components"
import { pick, times } from "lodash"
import { getCustomImageUrl } from "../../utils/utils"
import defaultImage from "../../assets/images/default-image.png"
import { LibraryItem } from "./LibraryBar"
import { EnrolledProgramsThreadsQuery } from "../../apollo/generated/graphql"
import { useHistory } from "react-router-dom"
import { useCurrentThreadGuid } from "../../hooks/currentThread.hook"
import {
    useEnrolledProgramsIdOnly,
    useSelectedEnrolledProgramAttribute,
    useSelectedProgramGuid,
} from "../../hooks/enrolledProgram.hook"
import { useSidebars } from "../../hooks/sidebars.hook"
import { SidebarType } from "../../redux/layout"
import useLayout from "../../hooks/layout.hook"
import TopBar from "../TopBar"

interface PlaylistBarProps {
    clearSelectedPlaylist: () => void
    playlist?: LibraryItem
}

export const mapEnrolledProgramThread = (
    enrolledProgramThread: NonNullable<EnrolledProgramsThreadsQuery["enrolledProgramThreads"]>[0],
    programGuid?: string | undefined,
    programId?: number | undefined
): LibraryItem => {
    return {
        threadGuid: enrolledProgramThread.thread.guid,
        programGuid,
        threadId: enrolledProgramThread.thread.id,
        programId,
        type: LibraryRowType.thread,
        title: enrolledProgramThread.thread.title,
        subtitle: enrolledProgramThread.thread.description || "",
        status: enrolledProgramThread.status,
        progress: enrolledProgramThread.progress ?? 0,
        imageSrc: enrolledProgramThread.thread.imageURL,
        instructor: pick(enrolledProgramThread.thread.instructor, [
            "profileImageURL",
            "firstName",
            "lastName",
        ]),
    }
}

const PlaylistBar = ({ clearSelectedPlaylist, playlist }: PlaylistBarProps) => {
    const theme = useTheme()
    const history = useHistory()
    const currentProgramGuid = useSelectedProgramGuid()
    const currentThreadGuid = useCurrentThreadGuid()
    const { isMobile } = useLayout()
    const { attribute: leaderboardEnabled } = useSelectedEnrolledProgramAttribute("leaderboard")
    const { enrolledProgramThreads, loading: enrolledProgramThreadsLoading } =
        useEnrolledProgramThreadsByGuid(playlist?.programGuid, { fetchPolicy: "cache-and-network" })
    const { socialBarOpen, closeSidebars, setSidebarAnimation } = useSidebars()

    const { enrolledProgramsIds } = useEnrolledProgramsIdOnly()

    const userHasManyPrograms = enrolledProgramsIds && enrolledProgramsIds.length > 1

    const playlistImageSize = isMobile ? 150 : 200

    const customImageSrc = useMemo(
        () =>
            playlist?.imageSrc
                ? getCustomImageUrl(playlist.imageSrc, {
                      width: playlistImageSize,
                      webp: true,
                      crop: "center",
                  })
                : defaultImage,
        [playlist?.imageSrc, playlistImageSize]
    )

    const handleTitleClick = () => {
        // go to the leaderboard
        if (!playlist?.programGuid) return
        history.push(`/catalog/${playlist.programGuid}`)
    }

    const goBack = useCallback(() => {
        const goHome = () => {
            clearSelectedPlaylist()
            history.push(`/catalog`)
            /*
             * As the playlist is not an actual sidebar closing it will not change anything on
             * the `layout` reducer, so if the sidebar animations are disabled it won't animate
             * the sidebar. This is a problem when doing a refresh from a playlist view because
             * it will cause the animations not to work if the user click the back button.
             * That's why we manually enable the sidebar animation here.
             * */
            setSidebarAnimation(true)
            socialBarOpen && closeSidebars([SidebarType.social])
        }
        if (currentProgramGuid) {
            // if the user is on the thread view
            if (currentThreadGuid) {
                // if the program has the leaderboard enabled go to the leaderboard
                if (leaderboardEnabled) history.push(`/catalog/${currentProgramGuid}`)
                // otherwise go home
                else goHome()
            }
            // if the user is on the leaderboard, go home
            else goHome()
        }
        // `history`, `clearSelectedPlaylist` and `closeSidebars` dependencies are not necessary
        // eslint-disable-next-line
    }, [currentProgramGuid, currentThreadGuid, socialBarOpen])

    if (enrolledProgramThreadsLoading || !enrolledProgramThreads) {
        return (
            <StyledLibraryBarContent>
                <StyledBox
                    css={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <StyledLibraryTopBar
                        isMobile={isMobile}
                        css={{ borderBottom: `1px solid ${theme.colors.base.uiBgBorder}` }}
                    >
                        {userHasManyPrograms && <StyledArrowBackIcon onClick={goBack} />}
                    </StyledLibraryTopBar>
                    <StyledPlaylistHeader css={{ margin: "12px 18px" }}>
                        {/* playlist image */}
                        <SkeletonHeadlands
                            height={`${playlistImageSize}px`}
                            width={`${playlistImageSize}px`}
                            css={{
                                borderRadius: "3px",
                                minWidth: `${playlistImageSize}px`,
                                marginTop: "8px", // for the go back icon
                                marginBottom: "5px",
                            }}
                        />
                        {/* playlist title */}
                        <SkeletonHeadlands
                            height={"20px"}
                            width={"80%"}
                            css={{
                                borderRadius: theme.borderRadius1,
                                marginBottom: "5px",
                            }}
                        />
                        {/* playlist description */}
                        <SkeletonHeadlands
                            width={"100%"}
                            height={"56px"}
                            css={{
                                borderRadius: theme.borderRadius1,
                            }}
                        />
                    </StyledPlaylistHeader>
                    {/* threads */}
                    <StyledBox
                        css={{
                            alignSelf: "stretch",
                            padding: theme.variables.program.libraryBarPadding.left,
                        }}
                    >
                        {times(3, () => (
                            <LibraryRowSkeletonLoader type={LibraryRowType.thread} />
                        ))}
                    </StyledBox>
                </StyledBox>
            </StyledLibraryBarContent>
        )
    }

    return (
        <StyledLibraryBarContent>
            <TopBar>{userHasManyPrograms && <StyledArrowBackIcon onClick={goBack} />}</TopBar>
            <StyledScrollableContainer isMobile={isMobile} css={{ gap: "6px" }}>
                <StyledPlaylistHeader>
                    <img
                        alt={`playlist-${playlist?.title}`}
                        width={`${playlistImageSize}px`}
                        height={`${playlistImageSize}px`}
                        src={customImageSrc}
                    />
                    <StyledColumn css={{ gap: "6px", alignItems: "center" }}>
                        <StyledH4 useNewDesign onClick={handleTitleClick}>
                            {playlist?.title}
                        </StyledH4>
                        <StyledBody2>{playlist?.subtitle}</StyledBody2>
                    </StyledColumn>
                </StyledPlaylistHeader>
                {enrolledProgramThreads
                    .map((item) =>
                        mapEnrolledProgramThread(item, playlist?.programGuid, playlist?.programId)
                    )
                    .map((item, index) => (
                        <LibraryRow
                            key={`library-item-${item.type}-${index}}`}
                            {...item}
                            onClick={() => {
                                if (isMobile) {
                                    history.push(
                                        `/program/${item.programGuid}/thread/${item.threadGuid}`
                                    )
                                } else {
                                    history.push(`/catalog/${item.programGuid}/${item.threadGuid}`)
                                }
                            }}
                        />
                    ))}
            </StyledScrollableContainer>
        </StyledLibraryBarContent>
    )
}

export default PlaylistBar
