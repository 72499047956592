import Modal from "../Modal"
import { StyledH4, StyledP } from "../../styles/styledcomponents"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import { Button } from "../Button/Button"
import { UserAvatar } from "../../creator/components/UserAvatar"

interface OutdatedThreadVersionModalProps {
    profileImageURL?: string
    firstName?: string
    lastName?: string
    createdAt?: string
}
const OutdatedThreadVersionModal = ({
    profileImageURL,
    firstName = "",
    lastName = "",
    createdAt,
}: OutdatedThreadVersionModalProps) => {
    return (
        <Modal open>
            <StyledH4>You are not seeing the latest version of this thread</StyledH4>
            <UserAvatar
                user={{ profileImageURL, firstName, lastName }}
                styles={{ margin: "6px 0" }}
            />
            <StyledP>
                {`${firstName} ${lastName} performed changes`}
                {createdAt &&
                    `${formatDistanceToNowStrict(new Date(createdAt), {
                        addSuffix: true,
                    })}`}
            </StyledP>
            <StyledP>Reload the page to fetch the latest changes</StyledP>
            <Button
                label={"Reload"}
                width={120}
                onClick={() => window.location.reload()}
                buttonStyle={{ marginTop: "6px" }}
            />
        </Modal>
    )
}

export default OutdatedThreadVersionModal
