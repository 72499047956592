import { useCurrentThread } from "../../../../hooks/currentThread.hook"
import useLayout from "../../../../hooks/layout.hook"
import {
    StyledCloseButtonRow,
    StyledMoreResponsesDivider,
    StyledMoreResponsesModal,
    StyledMoreResponsesModalContentContainer,
    StyledScrollableContainer,
    StyledUserInfoPopup,
    StyledUserResponseItem,
} from "./styles"
import CloseIcon from "@mui/icons-material/Close"
import {
    StyledBox,
    StyledH4,
    StyledHr,
    StyledP,
    StyledSpan,
} from "../../../../styles/styledcomponents"
import { Maybe } from "graphql/jsutils/Maybe"
import { GetLatestFreeTextResponsesQuery } from "../../../../apollo/generated/graphql"
import { UserAvatar } from "../../../../creator/components/UserAvatar"
import { AvatarSize } from "../../../../types"
import ProfileCard from "../../../ProfileCard"
import { pick } from "lodash"

type MoreResponsesModalProps = {
    responses: NonNullable<GetLatestFreeTextResponsesQuery["latestFreetextResponses"]>
    question: string
    onClose: () => void
}

const MoreResponsesModal = ({ responses, question, onClose }: MoreResponsesModalProps) => {
    const threadInstructor = useCurrentThread()?.thread?.instructor
    const { isMobile } = useLayout()

    return (
        <StyledMoreResponsesModal
            open
            onClose={onClose}
            mobile={isMobile}
            // avoid closing the modal when clicking the user info popup
            closeOnDocumentClick={!isMobile}
        >
            <StyledCloseButtonRow>
                <CloseIcon onClick={() => onClose()} />
            </StyledCloseButtonRow>
            <StyledMoreResponsesModalContentContainer>
                <UserResponseItem response={question} person={threadInstructor} fullName />
                <StyledMoreResponsesDivider>
                    <StyledSpan>{`${responses.length} responses`}</StyledSpan>
                    <StyledHr />
                </StyledMoreResponsesDivider>
                <StyledScrollableContainer>
                    {responses.map((response) => (
                        <UserResponseItem response={response.response} person={response.author} />
                    ))}
                </StyledScrollableContainer>
            </StyledMoreResponsesModalContentContainer>
        </StyledMoreResponsesModal>
    )
}

type UserResponseItemProps = {
    response: string
    person: Maybe<
        NonNullable<GetLatestFreeTextResponsesQuery["latestFreetextResponses"]>[0]["author"]
    >
    fullName?: boolean
}
const UserResponseItem = ({ response, person, fullName }: UserResponseItemProps) => {
    const { isTouchable } = useLayout()
    return (
        <StyledUserResponseItem>
            <StyledUserInfoPopup
                mobile
                nested
                position={["right center", "top right", "bottom right"]}
                on={isTouchable ? "click" : "hover"}
                arrow={false}
                mouseLeaveDelay={0}
                mouseEnterDelay={300}
                trigger={
                    <div>
                        <UserAvatar
                            size={AvatarSize.XS}
                            user={{ ...pick(person, ["profileImageURL", "firstName", "lastName"]) }}
                        />
                    </div>
                }
            >
                {person && <ProfileCard person={person} />}
            </StyledUserInfoPopup>
            <StyledBox
                css={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "3px",
                }}
            >
                <StyledH4>
                    {fullName
                        ? `${person?.firstName || ""} ${person?.lastName || ""}`
                        : person?.firstName}
                </StyledH4>
                <StyledP>{response}</StyledP>
            </StyledBox>
        </StyledUserResponseItem>
    )
}

export default MoreResponsesModal
