import { Block } from "../../../../types"
import { isHTML } from "../../../../utils/utils"
import parse from "html-react-parser"
import { StyledTextBlock } from "./styles"

type BaseTextBlockProps = {
    block?: Block
    value?: string
}

type TextBlockProps = BaseTextBlockProps & {
    block?: Block
    value?: never
}

type TextBlockWithValueProps = BaseTextBlockProps & {
    block?: Block
    value?: string
}

const TextBlock = (props: TextBlockProps | TextBlockWithValueProps) => {
    const value = props.value || props.block?.value

    if (!value) return null

    return (
        <StyledTextBlock noBackground={props.block?.color}>
            {isHTML(value) ? parse(value) : <p>{parse(value)}</p>}
        </StyledTextBlock>
    )
}

export default TextBlock
