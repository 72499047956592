import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { useCallback, useEffect } from "react"
import { resetQuestionTimestampData, setQuestionTimestampData } from "../redux/layout"

const useTimeToAnswer = () => {
    const dispatch = useDispatch()

    const questionTimestamp = useSelector(
        (state: RootState) => state.layoutReducer.questionTimestamp
    )

    // Reset question timestamp data when unmounting. This could be when switching threads for example
    useEffect(() => {
        return () => {
            resetTimeToAnswer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const resetTimeToAnswer = useCallback(() => dispatch(resetQuestionTimestampData()), [dispatch])

    // Returns time to answer and resets timer
    const getTimeToAnswer = useCallback(() => {
        // `timeToAnswer` is represented in seconds with decimals
        const timeToAnswer = questionTimestamp && (Date.now() - questionTimestamp) / 1000

        // Reset question timestamp data after answering
        questionTimestamp && resetTimeToAnswer()

        return timeToAnswer
    }, [questionTimestamp, resetTimeToAnswer])

    const startTimer = useCallback(() => {
        // For now we always set the timer at 15 seconds, in the future this could be set at the block level
        dispatch(setQuestionTimestampData({ timestamp: Date.now(), availableTime: 15 }))
    }, [dispatch])

    return { getTimeToAnswer, resetTimeToAnswer, startTimer }
}

export default useTimeToAnswer
