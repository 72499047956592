import { ColorValue } from "../utils/colors"
import { BlocksIndexReducer } from "../redux/blocks"
import {
    EnrolledProgramParticipantsQuery,
    StartThreadResponse,
    VariableType,
    VariableWithValue,
} from "../apollo/generated/graphql"
import { Maybe } from "graphql/jsutils/Maybe"

export type PartialThread = {
    id?: number
    guid?: string
    instructor?: Instructor
    profileImageLink?: string
    index?: number
    status?: ThreadStatus
    progress?: number
    threadVersion?: PartialThreadVersion
    variables?: { [key: string]: Variable }
    userAnswers?: { [key: string]: string }[]
    userAnswersMap?: { [key: string]: QuestionChoice[] }
    lastMessageRendered?: string
    isReplaying?: boolean
}

export type Thread = {
    id?: number
    guid?: string
    instructor?: Instructor
    profileImageLink?: string
    index?: number
    status?: ThreadStatus
    progress?: number
    threadVersion: ThreadVersion
    variables: { [key: string]: Variable }
    userAnswers: { [key: string]: string }[]
    userAnswersMap: { [key: string]: QuestionChoice[] }
    lastMessageRendered?: string
    isReplaying?: boolean
    globalVariables: {
        user: Object
        program: Object
        thread: Object
    }
    lastPublishedVersion?: Partial<ThreadVersion>
    programs?: Partial<Program>[]
}

export type ProgramThread = {
    id: number
    guid: string
    version: string
    instructor: NewInstructor
    title: string
    lastUpdateTime: string
    json: string
}

export type PartialThreadVersion = {
    id?: number
    title?: string
    published?: boolean
    synopsis?: string
    preview?: string
    blocks?: Block[]
    threadId?: number
    displayDescription?: string
    createdAt?: string
    dynamicAttachments?: DynamicAttachment[]
}

export type ThreadVersion = {
    id?: number
    title: string
    published: boolean
    synopsis: string
    preview: string
    blocks: Block[]
    threadId?: number
    displayDescription: string
    createdAt?: string
    dynamicAttachments?: DynamicAttachment[]
    theme?: ThreadTheme
    font?: ThreadFont
}

export type DynamicAttachment = {
    guid: string
    originalImageLink: string
    changes: object
    type?: string
    fileName?: string
    size?: string
}

export type Variable = {
    type: VariableType
    value?: number | string | string[]
    text?: string
    blockId?: string
    possibleValues?: string[]
}

// export enum VariableType {
//     INT = "int",
//     STRING = "string",
//     REF = "ref",
//     REF_SET = "ref-set",
// }

export type Attachment = {
    id: string
    link: string
}

export type Program = {
    id: number
    externalName: string
    externalDescription: string
    threads: Thread[]
    guid: string
    programImageLink: string
    variables: [{ [key: string]: string }]
}

export type EnrolledProgramThread = {
    status: ThreadStatus
    progress: number
    thread: {
        id: number
        guid: string
        title: string
        instructor: {
            id: number
            firstName: string
            lastName: string
            profileImageURL?: string
        }
    }
}

export type KeyValue = {
    key: string
    value: string
}

export enum ProgramType {
    SIMPLE = "SIMPLE",
    MANUAL = "MANUAL",
}

export type Condition = {
    id: string
    variableName: string
    type: ConditionType
    value: string
}

export enum ConditionType {
    CONTAINS = "CONTAINS",
    EQUALS = "EQUALS",
}

export enum ContextMenuType {
    VARIABLES = "VARIABLES",
}

export enum ThreadStatus {
    LOCKED = "LOCKED",
    UNLOCKED = "UNLOCKED",
    EXPIRED = "EXPIRED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
}

export enum NotificationNature {
    SOCIAL = "social",
    FEEDBACK = "feedback",
}

export enum OptionPreviewMode {
    DESKTOP = "desktop",
    MOBILE = "mobile",
}

export enum MENU_KEYS {
    ComponentMenu = "/",
    VariableMenu = "@",
    EmojiMenu = ":",
}

export enum MenuType {
    COMPONENTS = "COMPONENTS",
    VARIABLES = "VARIABLES",
    EMOJIS = "EMOJIS",
}

export type OptionWithIcon = {
    label: OptionPreviewMode
    icon: JSX.Element
}

export type ChoiceQuestionProperties = {
    display_poll: boolean
    no_correct_answers: boolean
    hide_answer_correctness: boolean
    may_select_multiple: boolean
}

export type ChoiceQuestionOption = {
    id: string
    text?: string
    image_url?: string
    correct?: boolean
    objects: Block[]
}

export type FreeTextResponse = {
    id: string
    input?: string
    correct?: boolean
    objects: Block[]
}

export type Block = {
    id: string
    type: BlockType
    value?: string
    color?: boolean
    choices?: QuestionChoice[]
    save_to_variable?: string
    branches?: ConditionalBranch[]
    nature?: NotificationNature
    sender?: string
    thread?: string
    version?: string
    objects?: Block[]
    // todo check if we can unify imageSize and videoSize
    imageSize?: MediaSize
    videoSize?: MediaSize
    attachmentGuid?: string
    group_replies?: boolean
    anchor_sender?: string
    anchor_message?: string
    default_avatar?: boolean
    isInsideGroup?: boolean
    question_text?: string
    variable_block_id?: string
    poll?: boolean
    suggestions?: string[]
    optimal_responses?: string[]
    hide_free_text_input?: boolean
    scope?: string
    hidden?: boolean
    title?: string
    comment?: string
    language?: string
    pause_label?: string
    groupId?: string
    properties?: Partial<ChoiceQuestionProperties>
    question?: string
    options?: Maybe<ChoiceQuestionOption[]>
    responses?: Maybe<FreeTextResponse[]>
    image?: Block //used on the "Question" block
    // used when the buil-in pause for `appear_together` should be hidden
    skip_pause?: boolean
}

export type ShallowBlock = Block & {
    branches?: ShallowConditionalBranch[]
}

export type PartialBlock = {
    id?: string
    type?: BlockType
    imageSize?: MediaSize
    videoSize?: MediaSize
    value?: string
    color?: boolean
    choices?: QuestionChoice[]
    save_to_variable?: string
    branches?: ConditionalBranch[]
    nature?: NotificationNature
    sender?: string
    thread?: string
    version?: string
    objects?: Block[]
    group_replies?: boolean
    anchor_sender?: string
    anchor_message?: string
    default_avatar?: boolean
    question_text?: string
    variable_block_id?: string
    suggestions?: string[]
    optimal_responses?: string[]
    hide_free_text_input?: boolean
    scope?: string
    hidden?: boolean
    title?: string
    language?: string
    pause_label?: string
    properties?: Partial<ChoiceQuestionProperties>
    question?: string
    options?: Maybe<ChoiceQuestionOption[]>
    responses?: Maybe<FreeTextResponse[]>
    image?: Block //used on the "Question" block
    skip_pause?: boolean
}

export type ReadOnlyBlock = {
    id: string
    branches?: { [index: number]: ReadOnlyConditionalBranch }
    objects?: { [index: number]: ReadOnlyBlock }
    options?: Maybe<{ [index: number]: ReadOnlyQuestionOption }>
    responses?: Maybe<{ [index: number]: ReadOnlyFreeTextResponse }>
}

export type ReadOnlyConditionalBranch = {
    id: string
    objects: BlocksIndexReducer
}

export type ReadOnlyQuestionOption = {
    id: string
    objects: BlocksIndexReducer
}

export type ReadOnlyFreeTextResponse = {
    id: string
    objects: BlocksIndexReducer
}

export type ConditionalBranch = {
    id: string
    objects: Block[]
    test: ConditionalTest
}

export type ShallowConditionalBranch = {
    id: string
    test: ConditionalTest
}

export type PartialConditionalBranch = {
    id?: string
    objects?: Block[]
    test?: ConditionalTest
}

export type ConditionalTest = {
    op?: Operator
    var: string
    val?: string | string[]
    tests?: ConditionalTest[]
}

export enum Operator {
    AND = "and",
    OR = "or",
    ALWAYS_TRUE = "always_true",
    // string
    GLOB = "glob",
    IGLOB = "iglob",
    CONTAINS_STRING = "contains_string",
    EQUALS_STRING = "equals_string",
    // ref
    IN = "in",
    //ref-set
    CONTAINS = "contains",
    CONTAINS_ANY = "contains_any",
    CONTAINS_EXACTLY = "contains_exactly",
    // free text
    ANY_OF = "any_of",
    ALL = "all",
}

export enum BlockType {
    TEXT = "text",
    IMAGE = "static_image",
    VIDEO = "embedded_video",
    CONDITIONAL = "conditional",
    NOTIFICATION = "notification",
    IMAGE_UPLOAD = "image_upload",
    SET_STRING_VARIABLE = "set_string_variable",
    PAUSE = "pause",
    INCLUDE = "include",
    GROUP = "appear_together",
    COMMENT = "comment",
    IMAGE_CAROUSEL = "image_carousel",
    DIVIDER = "divider",
    ACCORDION = "accordion",
    PREVIEW = "preview",
    SYNOPSIS = "synopsis",
    INSTRUCTOR_AVATAR = "instructor_avatar",
    CONFETTI = "confetti",
    QUESTIONS_AND_ANSWERS = "questions_and_answers",
    TYPING_INDICATOR = "typing_indicator",
    CHOICE_QUESTION = "multiple_choice_question",
    FREE_TEXT_QUESTION = "free_text_question",
    SECTION = "section",
    CODE = "code",
}

export type QuestionChoice = {
    id: string
    text?: string
    image_url?: string
    correct?: boolean
}

export type Instructor = {
    id?: string
    firstName: string
    lastName: string
    profileImageLink: string
}

/*
 * TODO: APIs Migration
 *  the instructor type would change with the migration, we should stop using the `Instructor` type when migration is ready
 *  and rename this one
 * */
export type NewInstructor = Pick<Person, "id" | "firstName" | "lastName" | "profileImageURL">

export enum TelemetryType {
    RENDER = "RENDER",
    COMPLETE = "COMPLETE",
    INPUT = "INPUT",
    INITIATE_INPUT = "INITIATE_INPUT",
    NOTIFICATION = "NOTIFICATION",
    SET_VARIABLE = "SET_VARIABLE",
    INCLUDE = "INCLUDE",
    REPLAY_FROM = "REPLAY_FROM",
}

export enum RichtTextFormatOptions {
    BOLD = "bold",
    ITALIC = "italic",
    UNDERLINE = "underline",
    LIST = "list",
    COLOR = "color",
    BLOCKQUOTE = "blockquote",
    HEADER = "header",
    CLEAN = "clean",
    INDENT = "indent",
    BACKGROUND = "background",
    LINK = "link",
    Formula = "formula",
}

export type TimingData = {
    perObjectTiming: ObjectTiming[]
}

export type ObjectTiming = {
    percentComplete: number
}

// todo check if we can delete this type
export type SideBarItem = {
    id: number | string
    title: string
    icon: any
    onClick: () => void
    shouldRender?: boolean
    renderDivider?: boolean
}

export type TelemetrySessionData = {
    id: Maybe<number>
    timestamp: number
}

export enum GraphQLErrors {
    THREAD_VERSION_ERROR = "THREAD_VERSION_ERROR",
    TELEMETRY_SESSION_CLOSED_ERROR = "TELEMETRY_SESSION_CLOSED_ERROR",
}

export type IconProps = Partial<{
    color: ColorValue
    className: string
    onClick: () => void
    width: number
    height: number
    opacity?: number
}>

export enum CursorMovement {
    next,
    previous,
}

export enum MediaSize {
    extraSmall = "extraSmall",
    small = "small",
    medium = "medium",
    large = "large",
}

export type ImageEditionChanges = { [key: string]: any }

export enum UserPermissions {
    mayCreate = "mayCreate",
    distinguishedInstructor = "distinguishedInstructor",
    maySeeUnpublishedThreads = "maySeeUnpublishedThreads",
    maySeeExperimentalFeatures = "maySeeExperimentalFeatures",
    isAdministrator = "isAdministrator",
}

export enum UserStatus {
    online,
}

export enum AttachmentFolder {
    Profile = "PROFILE",
    CreatorTool = "CREATOR_TOOL",
    UserThread = "USER_THREAD",
    Program = "PROGRAM",
}

export type PlaylistThread = {
    threadVersion: PlaylistThreadVersion
}

export type PlaylistThreadVersion = {
    title: string
    displayDescription: string
}

export type CatalogItemType = {
    guid: string
    externalName: string
    playlistImageLink: string
    externalDescription: string
    instructors: Instructor[]
    threads: PlaylistThread[]
    date?: string
}

export type Catalog = {
    externalName: string
    playlists: CatalogItemType[]
}

export enum LoaderType {
    THREAD = "thread",
    SOCIAL = "social",
    BLOCK = "block",
    THREADCREATOR = "thread-creator",
    PROGRAM = "program",
}

export enum BlockLoaderType {
    LONG_TEXT = "long-text",
    MEDIUM_TEXT = "medium-text",
    SHORT_TEXT = "short-text",
    IMAGE = "image",
    PANE = "pane",
}

export enum BlockLoaderSide {
    INSTRUCTOR = "from-left",
    STUDENT = "from-right",
}

export enum AvatarSize {
    XXXS = 18,
    XXS = 24,
    XS = 30,
    S = 34,
    M = 36,
    L = 40,
    XL = 42,
    XXL = 46,
    XXXL = 66,
    XXXXL = 96,
}

export {}

declare global {
    interface Window {
        __forceSmoothScrollPolyfill__: boolean
    }
}

export type GenericSelectOption<T extends unknown> = {
    value: T
    label: string
}

export type SelectOption = GenericSelectOption<string>

export enum BlocksType {
    PREVIEW = "previewBlocks",
    BLOCKS = "blocks",
    SYNOPSIS = "synopsisBlocks",
}

export type ThreadVersionHistory = {
    id: number
    published: boolean
    createdAt: string
    editor: {
        id: number
        firstName: string
        lastName: string
        profileImageLink: string
    }
}

export enum ThreadTheme {
    Default = "default",
    AppleMessages = "apple_messages",
    GoogleMessages = "google_messages",
    WhatsApp = "whatsApp",
    MSTeams = "MS_teams",
    Slack = "slack",
    Discord = "discord",
    Purple = "purple",
    Orange = "orange",
    Olive = "olive",
    Blue = "blue",
    Green = "green",
    Ocean = "ocean",
    Cherry = "cherry",
    // TODO: add support for custom on the future
    // Custom = "custom",
}

export enum ThreadFont {
    System = "system",
    Arial = "arial",
    HelveticaNeue = "helvetica_neue",
    Courier = "courier",
    Georgia = "georgia",
    Roboto = "roboto",
    OpenSans = "open_sans",
    Lato = "lato",
    Montserrat = "montserrat",
    Raleway = "raleway",
    Ubuntu = "ubuntu",
    Nunito = "nunito",
    Karma = "karma",
    Cambay = "cambay",
    Dosis = "dosis",
    NotoSans = "noto Sans",
    Oswald = "oswald",
    Inconsolata = "inconsolata",
    ComicSans = "comic_sans",
    MarkerFelt = "marker_felt",
    GGSans = "gg_sans",
    SegoeUI = "segoe_ui",
    Circular = "circular",
}

export type ThemeColors = {
    background?: string
    instructor?: {
        bubble?: string
        font?: string
    }
    student?: {
        bubble?: string
        font?: string
        inputText?: string
    }
}

export type BlocksClipboardItem = {
    type: string
    blocks: Block[]
}

export enum MessagingProviders {
    SMS = "sms",
    EMAIL = "email",
    SLACK = "slack",
    TEAMS = "teams",
    LINKEDIN = "linkedin",
    TWITTER = "twitter",
    DISCORD = "discord",
    WHATSAPP = "whatsapp",
}

export type BaseDeliverMessage = {
    __typename: string
    title?: string
    body?: string
    deliveryTime?: string
    link?: Maybe<string>
    attachment?: Maybe<DeliverAttachment>
    attachmentGUID?: string
    clientData?: Maybe<string>
}

/*
 * SlackMessage is the same as BaseDeliverMessage for now,
 * but this allows us to differentiate them further in the future
 */
export type SlackMessage = BaseDeliverMessage & {}

/*
 * EmailMessage is the same as BaseDeliverMessage for now,
 * but this allows us to differentiate them further in the future
 */
export type EmailMessage = BaseDeliverMessage & {}

export type DeliverMessage = SlackMessage | EmailMessage

export type DeliverThread = {
    __typename: DeliverProgramContentType
    thread: ProgramThread
    unlockTime?: Maybe<string>
    lockTime?: Maybe<string>
    messages: DeliverMessage[]
    clientData: string
}

export enum DeliverProgramContentType {
    ProgramThread = "ProgramThread",
    SlackMessage = "SlackMessage",
    EmailMessage = "EmailMessage",
}

export type DeliverProgramContent = DeliverThread | DeliverMessage

export type DeliverAttachment = {
    guid: string
    changes?: Maybe<string>
    url: string
}

export type Person = {
    id: number
    email: string
    firstName: string
    lastName: string
    company: string
    role: string
    city: string
    state: string
    emoji: string
    profileImageURL?: string
    description: string
}

export type ReplacementVariable = VariableWithValue & {
    object?: string
}

export type ResponseThread = Partial<Omit<StartThreadResponse, "__typename">>

export interface ItemWithChildren<T> {
    children?: Maybe<T[]>

    [key: string]: any
}

export enum SortableOutlineGroupType {
    ROOT = "OUTLINE-ROOT",
    SECTION = "OUTLINE-SECTION",
    CHUNK = "OUTLINE-CHUNK",
}

export enum SortableGroupType {
    ROOT = "ROOT",
    SECTION = "SECTION",
    CHUNK = "CHUNK",
    COMPONENT = "COMPONENT",
}

export enum Environment {
    PRODUCTION = "production",
    STAGING = "staging",
    DEVELOP = "develop",
    LOCALHOST = "localhost",
}

export type PartialRecursive<K> = {
    [attr in keyof K]?: K[attr] extends object
        ? PartialRecursive<K[attr]>
        : K[attr] extends object | null
        ? PartialRecursive<K[attr]> | null
        : K[attr] extends object | null | undefined
        ? PartialRecursive<K[attr]> | null | undefined
        : K[attr]
}

export type BlockContext = (BlocksType | BlockType)[]

export type EnrolledProgramParticipant = Exclude<
    EnrolledProgramParticipantsQuery["enrolledProgramParticipants"],
    null | undefined
>[number]

export type EnrolledProgramParticipantPerson = EnrolledProgramParticipant["person"]
