import { Loader } from "../../../components/Loader"
import { StyledHeadlandsPopup, StyledSpan } from "../../../styles/styledcomponents"
import { StyledIconTopBar } from "./styles"

interface IconTopBarProps {
    icon: JSX.Element
    onClick?: () => void
    loading?: boolean
    disabled?: boolean
    tootltipMessage: string
    dataTestid?: string
}

export const IconTopBar = ({
    icon,
    onClick,
    loading,
    disabled,
    tootltipMessage,
    dataTestid,
}: IconTopBarProps) => {
    return (
        <StyledHeadlandsPopup
            position={["bottom center", "bottom left"]}
            mouseEnterDelay={750}
            on={["hover"]}
            trigger={
                <StyledIconTopBar
                    data-testid={dataTestid}
                    // Transient prop to avoid warning (https://styled-components.com/docs/api#transient-props)
                    $loading={loading}
                    disabled={disabled}
                    onClick={!disabled ? onClick : undefined}
                >
                    {loading ? <Loader size={16} /> : icon}
                </StyledIconTopBar>
            }
        >
            <StyledSpan>{tootltipMessage}</StyledSpan>
        </StyledHeadlandsPopup>
    )
}
