import { Colors } from "../../../utils/colors"
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { StyledPlayArrowRoundedIcon } from "./styles"
import { StyledBox } from "../../../styles/styledcomponents"

export interface ProgressCircleProps {
    progress?: number
    color?: string
    size?: number
    colorBackground?: string
}

const ProgressCircle = ({
    progress = 0,
    color = Colors.headlandsBlack,
    colorBackground = Colors.headlandsGray3,
    size = 18,
}: ProgressCircleProps) => {
    const styles = buildStyles({
        pathColor: color,
        trailColor: colorBackground,
    })

    return (
        <StyledBox css={{ width: size, height: size }} id={"ProgressCircle"}>
            <CircularProgressbarWithChildren value={progress} strokeWidth={13} styles={styles}>
                <StyledPlayArrowRoundedIcon />
            </CircularProgressbarWithChildren>
        </StyledBox>
    )
}

export default ProgressCircle
