import {
    Block,
    BlockContext,
    BlocksClipboardItem,
    BlockType,
    ChoiceQuestionOption,
    ConditionalBranch,
    QuestionChoice,
} from "../types"
import { generateUUID, getAllBlockOfType } from "../utils/utils"
import { compact, concat, first, flatten, omitBy, uniq } from "lodash"
import { HEADLANDS_BLOCK_TYPE } from "../utils/consts"
import { Maybe } from "graphql/jsutils/Maybe"
import { UserAnswer } from "../apollo/generated/graphql"

// TODO: We should do this recursively, to look for images inside groups, etc
export const preFetchImages = async (blocks: Block[]) => {
    /*
     * note:
     * The carousel, single image selection and multiple image selection components all three have the same
     * block structure, so in order to get the image urls from them, we use the following logic that searches on the
     * choices of these components
     * */
    const choiceImageUrls = flatten(
        getAllBlockOfType(blocks, [BlockType.IMAGE_CAROUSEL]).map((block: Block) => block.choices)
    ).map((choice: QuestionChoice | undefined) => choice?.image_url)

    const questionImageUrls = getAllBlockOfType(blocks, [
        BlockType.CHOICE_QUESTION,
        BlockType.FREE_TEXT_QUESTION,
    ]).map((block: Block) => block.image?.value)

    const choiceQuestionOptionsImages = flatten(
        getAllBlockOfType(blocks, BlockType.CHOICE_QUESTION).map((block: Block) => block.options)
    ).map((option: Maybe<ChoiceQuestionOption>) => option?.image_url)

    const simpleImageUrls = getAllBlockOfType(blocks, BlockType.IMAGE).map(
        (block: Block) => block.value
    )

    // image urls without null, undefined or duplicates
    const all_image_urls = uniq(
        compact(
            concat(choiceImageUrls, questionImageUrls, choiceQuestionOptionsImages, simpleImageUrls)
        )
    )

    const fetchImage = (url: string) =>
        new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = resolve
            img.onerror = reject
            img.src = url
        })

    await Promise.allSettled(all_image_urls.map(fetchImage))
}

export const parseBlocksString = (blocksString?: string | null): Block[] => {
    if (!blocksString) return []
    let result
    try {
        result = JSON.parse(blocksString)
    } catch {
        result = []
    }
    return result
}

/*
 * We build choicesMap in order to keep the reference to the
 * old choices ids after changing them, since we need them to update
 * branch's tests accordingly
 */
export const duplicateBlock = (
    block: Block,
    choicesMap: { [oldId: string]: string } = {}
): Block => {
    let generatedSaveToVariable: string | undefined
    const newBlock: Block = {
        ...block,
        id: generateUUID(),
        save_to_variable: generatedSaveToVariable,
        choices: block.choices
            ? block.choices.map((choice: QuestionChoice) => {
                  const newChoiceId = generateUUID()
                  choicesMap[choice.id] = newChoiceId
                  return {
                      ...choice,
                      id: newChoiceId,
                  }
              })
            : undefined,
        branches: block.branches
            ? block.branches.map((branch: ConditionalBranch) => ({
                  ...branch,
                  id: generateUUID(),
                  objects: branch.objects.map((childBlock: Block) =>
                      duplicateBlock(childBlock, choicesMap)
                  ),
                  test: {
                      ...branch.test,
                      var: generatedSaveToVariable ?? branch.test.var,
                      val: Array.isArray(branch.test.val)
                          ? branch.test.val.map((testVal: string) =>
                                !!choicesMap[testVal] ? choicesMap[testVal] : testVal
                            )
                          : branch.test.val,
                  },
              }))
            : undefined,
        options: block.options
            ? block.options.map((option) => ({
                  ...option,
                  id: generateUUID(),
                  objects: option.objects.map((childBlock: Block) =>
                      duplicateBlock(childBlock, choicesMap)
                  ),
              }))
            : undefined,
        responses: block.responses
            ? block.responses.map((response) => ({
                  ...response,
                  id: generateUUID(),
                  objects: response.objects.map((childBlock: Block) =>
                      duplicateBlock(childBlock, choicesMap)
                  ),
              }))
            : undefined,
        objects: block.objects
            ? block.objects.map((object) => duplicateBlock(object, choicesMap))
            : undefined,
    }

    // Here we remove keys that have an undefined value
    return omitBy(newBlock, (value) => value === undefined) as Block
}

export const generateBlockClipboardData = (blocks: Block[]): string => {
    const clipboardData: BlocksClipboardItem = {
        type: HEADLANDS_BLOCK_TYPE,
        blocks: blocks,
    }
    return JSON.stringify(clipboardData)
}

export const parsePastedBlocks = (pasteString: string): Block[] | undefined => {
    let parsed
    try {
        parsed = JSON.parse(pasteString)
    } catch {
        return undefined
    }
    if (parsed.type === HEADLANDS_BLOCK_TYPE && parsed.blocks?.length) {
        return parsed.blocks.map((block: Block) => duplicateBlock(block))
    } else {
        return undefined
    }
}

/*
 * This functions tries to do the unfurl paste logic, the idea is to sanitize any invalid block to became a valid one.
 * @returns { Block[] | undefined } sanitized valid blocks in context or undefined if can not be sanitized
 */
export const sanitizeInvalidBlockInContext = (
    invalidBlock: Block,
    context: BlockContext
): Block[] | undefined => {
    if (context.includes(BlockType.GROUP)) {
        switch (invalidBlock.type) {
            case BlockType.GROUP: {
                return first(invalidBlock.branches)?.objects
            }
            default:
                return undefined
        }
    }
    return undefined
}

// Add an `id` field to all the `responses` of all the `free_text_question` blocks
export const addIdsToFreeTextResponses = (blocks: Block[]): Block[] =>
    blocks?.map((block) => {
        if (block.responses) {
            return {
                ...block,
                responses: block.responses.map((response) => ({ ...response, id: generateUUID() })),
            }
        } else if (block.objects) {
            return {
                ...block,
                objects: addIdsToFreeTextResponses(block.objects),
            }
        } else if (block.options) {
            return {
                ...block,
                options: block.options.map((option) => ({
                    ...option,
                    objects: addIdsToFreeTextResponses(option?.objects),
                })),
            }
        } else if (block.branches) {
            return {
                ...block,
                branches: block.branches.map((branch) => ({
                    ...branch,
                    objects: addIdsToFreeTextResponses(branch.objects),
                })),
            }
        } else {
            return block
        }
    })

/*
 * This function return wether an specific block has an answer or not.
 * Support recursive answer search in the case the block has system responses associated (all system response blocks
 * must be answered in order to be true)
 */
export const blockHasAnswer = (
    block: Block,
    userAnswers: Maybe<UserAnswer[]> | undefined
): boolean => {
    // `groupId` key is used for the Pause component that's rendered by Groups without interactive children
    const answer = userAnswers?.find(
        (answer) => answer.object === block?.id || answer.object === block?.groupId
    )
    if (!answer) return false
    // if the answer doesn't have system response content then we only care for the block answer
    else if (!answer.systemResponse?.content) return true
    // if the answer has system response content we need to search all the answers for the content blocks
    else {
        const systemResponseContent = answer.systemResponse?.content
        const contentBlocksParsed = parseBlocksString(systemResponseContent)
        const allContentBlockHaveResponses = contentBlocksParsed?.every((contentBlock) =>
            blockHasAnswer(contentBlock, userAnswers)
        )
        return allContentBlockHaveResponses
    }
}
