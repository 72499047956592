import classNames from "classnames"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Block, BlockContext, BlocksType, BlockType, ReadOnlyBlock } from "../../../types"
import RenderBlock from "./RenderBlock"
import { DragImageWrapper } from "./DragImageWrapper"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { WarningIcon } from "../../../assets/icons/Warning"
import { AdviceIcon } from "../../../assets/icons/Advice"
import { Colors } from "../../../utils/colors"
import ReactDOM from "react-dom"
import {
    Add as AddIcon,
    ContentCopy as CopyIcon,
    ControlPointDuplicate as DuplicateIcon,
    Delete as DeleteIcon,
    DragIndicator as DragIcon,
    PlayArrowOutlined as PlayArrowIcon,
    VisibilityOffOutlined as VisibilityOffIcon,
    VisibilityOutlined as VisibilityIcon,
} from "@mui/icons-material"
import ComponentSelector from "../ComponentSelector/ComponentSelector"
import CloseButton from "../../../components/CloseButton"
import {
    StyledBox,
    StyledHeadlandsPopup,
    StyledP,
    StyledSpan,
} from "../../../styles/styledcomponents"
import { useTheme } from "styled-components"
import { isBlockEmpty } from "../../../utils/utils"
import { addBlocks, duplicateBlock, selectBlock, updateBlock } from "../../../redux/blocks"
import BackgroundColorOption from "../BackgroundColorOption"
import PopupMenu, { PopupOptionType } from "../../../components/PopupMenu"
import { AddBlockOptions, AiAssistData, AiEditData } from "./Editor"
import { Severity } from "../../../apollo/generated/graphql"
import AddChunkMenu from "../AddChunkMenu"
import { StyledIconContainer } from "../../styles"
import { compact } from "lodash"
import { ComponentSelectorItem } from "../ComponentSelector/constants"
import { useResizeDetector } from "react-resize-detector"
import AiEditPopup from "../AiPopup/AiEditPopup"
import AiCreateBlockPopup from "../AiPopup/AiCreateBlockPopup"
import { setAiCreateBlockData } from "../../../redux/ai"
import { ContentBlockMenuKey } from "../AddChunkMenu/constants"

const QuestionChunks = [BlockType.CHOICE_QUESTION, BlockType.FREE_TEXT_QUESTION]

interface EditorBlocksProps {
    blocksType: BlocksType
    blockId: string
    readOnlyBlock: ReadOnlyBlock
    blockIndex: number
    branchId?: string
    sectionId?: string
    questionOptionId?: string
    freeTextResponseId?: string
    parentType?: BlockType
    handleBlockChange: (value: string, id: string) => void
    handleBlockDelete: (blockId: string, index: number) => void
    handleAddBlock: (options: AddBlockOptions) => void
    handlePreview?: (previewMode: boolean, initialBlockId?: string) => void
    disabledColorBubble?: boolean
    context?: BlockContext
    aiAssistData?: AiAssistData
    clearAiData?: () => void
    aiEditData?: AiEditData
    setAiEditData?: (aiEditData?: AiEditData) => void
    onScreen?: boolean
}

const EditorBlock = ({
    blocksType,
    blockId,
    readOnlyBlock,
    blockIndex,
    branchId,
    sectionId,
    questionOptionId,
    freeTextResponseId,
    parentType,
    handleBlockChange,
    handleBlockDelete,
    handleAddBlock,
    handlePreview,
    disabledColorBubble,
    context,
    aiAssistData,
    clearAiData,
    aiEditData,
    setAiEditData,
    onScreen,
}: EditorBlocksProps) => {
    const [focused, setFocused] = useState(false)
    const [menuPopupOpen, setMenuPopupOpen] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const block = useSelector((state: RootState) => state.blocksReducer[blocksType][blockId])
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 })
    const isInPane = useMemo(
        () => [BlocksType.PREVIEW, BlocksType.SYNOPSIS].includes(blocksType),
        [blocksType]
    )
    // If block has no parent, and is not in a pane, then it's at the root level,
    // meaning it is a chunk
    const isAChunk =
        (!parentType ||
            parentType === BlockType.SECTION ||
            parentType === BlockType.CONDITIONAL ||
            QuestionChunks.includes(parentType)) &&
        !isInPane

    const theme = useTheme()
    const {
        height: editorBlockHeight,
        width: editorBlockWidth,
        ref: editorBlockRef,
    } = useResizeDetector()

    const blockIssues = useSelector(
        (state: RootState) =>
            state.blocksReducer.lintWarningAndAdvices.issues?.filter(
                (issue) => issue.objectID === block?.id
            ),
        shallowEqual
    )

    const aiCreateBlockData = useSelector((state: RootState) => state.aiReducer.createBlockData)

    const isInBranch = !!branchId
    const dispatch = useDispatch()

    const onPreviewClick = () => {
        setMenuPopupOpen(false)
        handleFocus()
        handlePreview && handlePreview(true, block.id)
    }

    const deleteBlock = () => {
        handleBlockDelete(block?.id, blockIndex)
    }

    const openAddBlock = () => {
        setMenuPopupOpen(false)
        const bounds = ref.current?.getBoundingClientRect()
        if (!bounds) return
        setMenuPosition({
            top: bounds.top,
            left: bounds.left - 300,
        })
    }

    const renderWarningOrAdvise = () => {
        let Icon: JSX.Element | null = null

        const warning = blockIssues?.find((issue) => issue.severity === Severity.Warning)
        const advice = blockIssues?.find((issue) => issue.severity === Severity.Advice)

        if (warning) Icon = <WarningIcon color={Colors.headlandsError} />
        else if (advice) Icon = <AdviceIcon color={Colors.headlandsWarning} />

        if (Icon) {
            return (
                <StyledHeadlandsPopup
                    on={["hover"]}
                    position={["left center"]}
                    arrow={false}
                    offsetX={20}
                    trigger={
                        <StyledBox
                            css={{
                                position: "absolute",
                                right: isInBranch ? "-36px" : "-24px",
                                top: "16px",
                            }}
                        >
                            {Icon}
                        </StyledBox>
                    }
                >
                    <StyledSpan>{warning?.description || advice?.description}</StyledSpan>
                </StyledHeadlandsPopup>
            )
        }

        return null
    }

    const updateBackgroundColor = () => {
        dispatch(updateBlock({ blocksType, id: block?.id, block: { color: !block?.color } }))
    }

    const handleFocus = () => dispatch(selectBlock({ blockId: block?.id }))

    const closeActiveMenu = () => {
        setMenuPosition({ top: 0, left: 0 })
    }

    const handleOptionSelectBlock = (key: ComponentSelectorItem) => {
        const replace = isBlockEmpty(block)
        if (replace) {
            handleAddBlock({ type: key, index: blockIndex, replace: true, id: block?.id })
        } else {
            handleAddBlock({ type: key, index: blockIndex + 1 })
        }
        closeActiveMenu()
    }

    const onDelete = () => handleBlockDelete(block?.id, blockIndex)

    useEffect(() => {
        if (disabledColorBubble && block?.type === BlockType.TEXT && !block?.color) {
            updateBackgroundColor()
        }
    }, [disabledColorBubble])

    const handleCopyBlock = () => {
        // TODO: For now we only support copying 1 block at a time
        dispatch({
            type: "blocks/copyBlocks",
            payload: { blocksType, blocks: [readOnlyBlock] },
        })
        setMenuPopupOpen(false)
    }

    const handleDuplicateBlock = () => {
        dispatch(
            duplicateBlock({
                blocksType,
                blockId: readOnlyBlock.id,
                branchId,
                blockIndex,
                sectionId,
                questionOptionId,
                freeTextResponseId,
            })
        )
        setMenuPopupOpen(false)
    }

    // At the root level we should use dark Icons
    const darkIcons = isAChunk || isInPane

    const handleAddChunk = useCallback(
        (chunk: Block, index?: number) => {
            dispatch(
                addBlocks({
                    blocksType: BlocksType.BLOCKS,
                    blocks: [chunk],
                    index,
                    branchId,
                    sectionId,
                    questionOptionId,
                    freeTextResponseId,
                })
            )
        },
        [branchId, dispatch, freeTextResponseId, questionOptionId, sectionId]
    )

    const toggleBlockVisibility = () => {
        dispatch(updateBlock({ blocksType, id: block?.id, block: { hidden: !block?.hidden } }))
    }

    const BlockMenuPopup = () => {
        const theme = useTheme()

        return (
            <PopupMenu
                on={["click"]}
                position={"left center"}
                nested
                arrow={false}
                overlayStyle={{ backgroundColor: "transparent" }}
                data-testid={"tooltip-thread" + block?.id}
                open={menuPopupOpen}
                onOpen={() => setMenuPopupOpen(true)}
                onClose={() => setMenuPopupOpen(false)}
                options={[
                    {
                        label: "Preview",
                        icon: PlayArrowIcon,
                        onClick: !isInBranch ? onPreviewClick : undefined,
                        hide: isInBranch,
                        type: PopupOptionType.TEXT_AND_ICON,
                    },
                    {
                        label: block?.hidden
                            ? `Show ${block.type === BlockType.SECTION ? "Section" : "Block"}`
                            : `Hide ${block.type === BlockType.SECTION ? "Section" : "Block"}`,
                        icon: block?.hidden ? VisibilityIcon : VisibilityOffIcon,
                        onClick: isAChunk ? toggleBlockVisibility : undefined,
                        noDivider: true,
                        hide: !isAChunk,
                        type: PopupOptionType.TEXT_AND_ICON,
                    },
                    {
                        label: "Add",
                        icon: AddIcon,
                        onClick: openAddBlock,
                        noDivider: true,
                        hide: isAChunk,
                        type: PopupOptionType.TEXT_AND_ICON,
                    },
                    {
                        label: "Copy",
                        icon: CopyIcon,
                        onClick: handleCopyBlock,
                        noDivider: true,
                        type: PopupOptionType.TEXT_AND_ICON,
                    },
                    {
                        label: "Duplicate",
                        icon: DuplicateIcon,
                        onClick: handleDuplicateBlock,
                        type: PopupOptionType.TEXT_AND_ICON,
                    },
                    {
                        label: "Delete",
                        icon: DeleteIcon,
                        onClick: deleteBlock,
                        noDivider: true,
                        styles: { color: `${theme.headlandsError}!important` },
                        type: PopupOptionType.TEXT_AND_ICON,
                    },
                ]}
                trigger={
                    <div>
                        <StyledHeadlandsPopup
                            position={"top center"}
                            on={"hover"}
                            arrow={false}
                            keepTooltipInside={".app"}
                            nested
                            mouseEnterDelay={500}
                            trigger={
                                <StyledBox css={{ cursor: "pointer" }} className={"drag-handle"}>
                                    <DragIcon
                                        sx={{
                                            color: darkIcons
                                                ? theme.headlandsGray2
                                                : theme.headlandsGray3,
                                            fontSize: "18px",
                                        }}
                                    />
                                </StyledBox>
                            }
                        >
                            <StyledBox
                                css={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <StyledP>
                                    <span className={"bold"}>Drag </span> to move
                                </StyledP>
                                <StyledP>
                                    <span className={"bold"}>Click </span> to open menu
                                </StyledP>
                            </StyledBox>
                        </StyledHeadlandsPopup>
                    </div>
                }
            />
        )
    }

    const AddBlockMenu = () => {
        const TooltipPopup = ({ onTriggerClick }: { onTriggerClick?: () => void }) => {
            return (
                <StyledHeadlandsPopup
                    position={"top center"}
                    on={"hover"}
                    arrow={false}
                    keepTooltipInside={".app"}
                    nested
                    mouseEnterDelay={300}
                    trigger={
                        <div>
                            <StyledIconContainer onClick={onTriggerClick}>
                                <AddIcon
                                    sx={{
                                        color: darkIcons
                                            ? theme.headlandsGray2
                                            : theme.headlandsGray3,
                                        width: "18px",
                                    }}
                                />
                            </StyledIconContainer>
                        </div>
                    }
                >
                    <StyledP>
                        <StyledSpan className={"bold"}>Click </StyledSpan> to add a{" "}
                        {`${isAChunk ? "block" : "bubble"}`} below
                    </StyledP>
                </StyledHeadlandsPopup>
            )
        }

        if (isAChunk) {
            return (
                <AddChunkMenu
                    handleAddChunk={(chunk) => handleAddChunk(chunk, blockIndex + 1)}
                    trigger={
                        <div>
                            <TooltipPopup />
                        </div>
                    }
                    chunkParams={{
                        blockGuid: blockId,
                        index: blockIndex + 1,
                        branchId,
                        sectionId,
                        questionOptionId,
                        freeTextResponseId,
                    }}
                    allowedChunks={
                        parentType && QuestionChunks.includes(parentType)
                            ? Object.values(ContentBlockMenuKey)
                            : undefined
                    }
                />
            )
        }
        return <TooltipPopup onTriggerClick={openAddBlock} />
    }

    const showAiEdit = aiEditData && aiEditData.objectGUID === block.id
    const showAiCreateBlock = aiCreateBlockData && aiCreateBlockData.blockGuid === block.id

    if (!block) return null

    return (
        <div
            key={block?.id}
            id={block?.id}
            className={classNames(`block-container block-type-${block.type}`, {
                branch: !!block?.branches && block.type !== BlockType.ACCORDION,
                "in-accordion": parentType === BlockType.ACCORDION,
                chunk: isAChunk,
                isInPane: blocksType === BlocksType.PREVIEW || blocksType === BlocksType.SYNOPSIS,
            })}
            style={{ position: "relative" }}
            data-testid={`block-${block?.id}`}
            onMouseEnter={() => setFocused(true)}
            onMouseLeave={() => setFocused(false)}
            ref={editorBlockRef}
        >
            <div className={classNames("block-row", { "in-pane": isInPane })}>
                <div
                    className={classNames("icons-row", {
                        focused: focused,
                        "short-margin": BlockType.DIVIDER === block?.type,
                        section: block?.type === BlockType.SECTION,
                    })}
                    ref={ref}
                >
                    <AddBlockMenu />
                    <BlockMenuPopup />
                </div>
                <div className={"block-column"}>
                    <DragImageWrapper handleAddBlock={handleAddBlock} blockIndex={blockIndex} top>
                        <div
                            className={"top-padding"}
                            onClick={() => {
                                if (
                                    isInPane ||
                                    (parentType &&
                                        ![
                                            BlockType.SECTION,
                                            BlockType.CHOICE_QUESTION,
                                            BlockType.FREE_TEXT_QUESTION,
                                        ].includes(parentType))
                                )
                                    handleAddBlock({
                                        index: blockIndex,
                                        type: BlockType.TEXT,
                                    })
                                else
                                    handleAddBlock({
                                        index: blockIndex,
                                        type: BlockType.GROUP,
                                    })
                            }}
                            onMouseEnter={() => setFocused(false)}
                            onMouseLeave={() => setFocused(true)}
                        />
                    </DragImageWrapper>
                    <RenderBlock
                        blocksType={blocksType}
                        block={block}
                        parentType={parentType}
                        index={blockIndex}
                        handleBlockDelete={handleBlockDelete}
                        handleBlockChange={handleBlockChange}
                        handleAddBlock={handleAddBlock}
                        setAiEditData={setAiEditData}
                        context={context}
                        handlePreview={handlePreview}
                        onScreen={onScreen}
                    />
                    {!isInPane &&
                        focused &&
                        (block?.type === BlockType.TEXT || block?.type === BlockType.ACCORDION) &&
                        !disabledColorBubble && (
                            <BackgroundColorOption
                                selected={!!block?.color}
                                handleBackgroundColor={updateBackgroundColor}
                            />
                        )}
                    {focused && <CloseButton onClose={onDelete} />}
                </div>
                {renderWarningOrAdvise()}
            </div>
            {ReactDOM.createPortal(
                menuPosition.top !== 0 && (
                    <ComponentSelector
                        position={menuPosition}
                        handleOptionSelect={handleOptionSelectBlock}
                        onRequestClose={closeActiveMenu}
                        search={""}
                        context={context ?? compact([blocksType, parentType])}
                    />
                ),
                document.body
            )}
            {showAiEdit && aiEditData && (
                <AiEditPopup
                    data={aiEditData}
                    onCancel={() => clearAiData && clearAiData()}
                    parentHeight={editorBlockHeight}
                />
            )}
            {showAiCreateBlock && aiCreateBlockData && (
                <AiCreateBlockPopup
                    data={aiCreateBlockData}
                    onCancel={() => dispatch(setAiCreateBlockData(undefined))}
                    parentHeight={editorBlockHeight}
                    parentWidth={editorBlockWidth}
                />
            )}
        </div>
    )
}

export default memo(EditorBlock)
