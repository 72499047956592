import styled, { css } from "styled-components"
import { StyledBox, StyledRow } from "../../../../styles/styledcomponents"
import { ButtonsAlignment } from "."

export const AI_POPUP_FOOTER_HEIGHT = 48

export const StyledFooter = styled(StyledBox)<{ buttonAlignment?: ButtonsAlignment }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 6px 12px;
    background-color: ${({ theme }) => theme.colors.base.uiAi300};
    border-radius: 0 0 8px 8px;
    height: ${AI_POPUP_FOOTER_HEIGHT}px;

    button {
        white-space: nowrap;
        width: fit-content;
    }

    ${StyledRow} {
        ${({ buttonAlignment }) =>
            buttonAlignment === ButtonsAlignment.right &&
            css`
                flex-direction: row-reverse;
            `}
    }
`

export const StyledButtonLabel = styled(StyledBox)`
    display: flex;
    align-items: center;
    gap: 4px;
`
