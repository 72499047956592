import styled from "styled-components"
import { StyledBox, StyledSpan } from "../../../styles/styledcomponents"
import { Link } from "@mui/icons-material"

export const StyledInputsContainer = styled(StyledBox)`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 6px;
`

export const StyledLastColumnContainer = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
`

export const StyledCopyLinkButton = styled("button")`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.headlandsPrimaryColorAlpha};
    border-radius: ${({ theme }) => theme.borderRadius2};
    border: none;
    padding: 6px 12px;
    align-self: flex-end;
    gap: 3px;

    ${StyledSpan} {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: ${({ theme }) => theme.headlandsPrimaryColor};
    }

    :hover {
        cursor: pointer;
    }
`

export const StyledLinkIcon = styled(Link)`
    font-size: 14px !important;
    transform: rotate(-45deg);
`
