import { useDispatch } from "react-redux"
import {
    addBlocks,
    BlocksIndexReducer,
    deleteBlock,
    moveBlock,
    selectBlock,
    updateBlock,
} from "../../../redux/blocks"
import {
    Block,
    BlockContext,
    BlocksType,
    BlockType,
    MediaSize,
    NotificationNature,
    Operator,
    SortableGroupType,
} from "../../../types"
import {
    generateUUID,
    getAccordionContent,
    getEmptyBranch,
    getGroupBlock,
} from "../../../utils/utils"
import EditorBlock from "./EditorBlock"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ReactSortable, SortableEvent } from "react-sortablejs"
import { capitalize, cloneDeep, isEmpty } from "lodash"
import { useCurrentThreadField, useCurrentThreadGuid } from "../../../hooks/currentThread.hook"
import { ComponentSelectorItem } from "../ComponentSelector/constants"
import { ContentType, EditType } from "../../../apollo/generated/graphql"
import { ErrorToastIcon } from "../../../assets/icons/ErrorToastIcon"
import { useToast } from "../../../hooks/useToast.hook"
import { baseSortableOptions } from "../../../utils/consts"
import useIntersectionObserver from "../../../hooks/intersection.hook"
import { StyledEditorWrapper } from "./styles"

export interface EditorProps {
    blocksType: BlocksType
    blockIds: BlocksIndexReducer
    branchId?: string
    parentType?: BlockType
    handlePreview?: (previewMode: boolean, initialBlockId?: string) => void
    disabledColorBubble?: boolean
    context?: BlockContext
    containsChunks?: boolean
    sectionId?: string
    questionOptionId?: string
    freeTextResponseId?: string
    readOnly?: boolean
    enableDeletingAllBlocks?: boolean
}

interface HandleAddBlockBaseOptions {
    type?: ComponentSelectorItem
    index: number
    initialContent?: string
    blocks?: Block[]
    id?: string
    block?: Block
}

interface HandleAddBlockOptions extends HandleAddBlockBaseOptions {
    replace?: never
}

interface HandleUpdateBlockOptions extends HandleAddBlockBaseOptions {
    replace: true
}

export type AddBlockOptions = HandleAddBlockOptions | HandleUpdateBlockOptions

export type AiAssistData = {
    type: ContentType
    threadGUID: string
    objectGUID: string
    onSubmit: (aiBlocks: Block[]) => void
}

export type AiEditData = {
    objectGUID: string
    type: EditType
    text: string
    onSubmit: (newValue: string) => void
}

export const Editor = ({
    blocksType,
    blockIds,
    branchId,
    parentType,
    handlePreview,
    disabledColorBubble,
    context,
    containsChunks,
    sectionId,
    questionOptionId,
    freeTextResponseId,
    readOnly,
    enableDeletingAllBlocks,
}: EditorProps) => {
    const [blocksArray, setBlocksArray] = useState<Block[]>([])
    const duplicatedBlock = useRef<HTMLElement | null>(null)
    const [aiAssistData, setAiAssistData] = useState<AiAssistData | undefined>()
    const [aiEditData, setAiEditData] = useState<AiEditData | undefined>()
    const [onScreen, setOnScreen] = useState(false)
    const containerRef = useRef(null)
    const dispatch = useDispatch()
    const threadGuid = useCurrentThreadGuid()
    const { showErrorToast } = useToast()
    const isInPane = useMemo(
        () => [BlocksType.PREVIEW, BlocksType.SYNOPSIS].includes(blocksType),
        [blocksType]
    )
    const aiCreateRunning = useCurrentThreadField("aiEditing")

    const { observe, unobserve } = useIntersectionObserver({
        fullyViewableCallback: () => setOnScreen(true),
        partialViewableCallback: () => setOnScreen(true),
        fullyUnViewableCallback: () => setOnScreen(false),
    })

    useEffect(() => {
        if (containerRef.current) observe(containerRef.current)
        return () => {
            if (containerRef.current) unobserve(containerRef.current)
        }
    }, [containerRef])

    const sortableGroup = useMemo(() => {
        // at root level only allow to drop sections and chunks
        if (!parentType) {
            return {
                name: SortableGroupType.ROOT,
                put: [SortableGroupType.SECTION, SortableGroupType.CHUNK],
            }
        }
        // at section level only allow to drop chunks
        if (sectionId) {
            return {
                name: SortableGroupType.SECTION,
                put: (to: any, from: any, element: any) => {
                    const fromGroupName = from.options.group.name
                    const elementIsASection = element?.className.includes(
                        `block-type-${BlockType.SECTION}`
                    )
                    const elementIsAComponent = fromGroupName === SortableGroupType.COMPONENT

                    // we don't allow section inside another section, and we don't allow
                    // components inside a section
                    return !(elementIsASection || elementIsAComponent)
                },
            }
        }
        // TODO: Should we do something here for the new question type?
        // at group level only allow to drop components
        if (containsChunks) {
            return {
                name: SortableGroupType.CHUNK,
                put: [SortableGroupType.COMPONENT],
            }
        }
        return {
            name: SortableGroupType.COMPONENT,
        }
    }, [parentType, sectionId, containsChunks])

    const sortableOptions = {
        ...baseSortableOptions,
        group: sortableGroup,
    }

    useEffect(() => {
        /*
         * For ReactSortable to work, it's `list` prop must be a
         * different object that the actual list of data
         */
        setBlocksArray(cloneDeep(Object.values(blockIds)))
    }, [blockIds])

    const handleAddBlock = useCallback(
        (options: AddBlockOptions) => {
            const { type, index, initialContent, blocks, block } = options
            /*
             * `blocks` is used when pasting blocks, so by default we define
             * `blocksToAdd` to that value. This variable could change if, and AI
             * option is selected
             */
            let blocksToAdd = blocks || []
            let newBlock: Block = block || {
                id: options.replace && options.id ? options.id : generateUUID(),
                type: (type as BlockType) ?? BlockType.TEXT,
                value: initialContent ?? "",
            }

            // Todo: change name please!
            const addBlocksAux = () => {
                if (options.replace && options.id && !isEmpty(blocksToAdd)) {
                    dispatch(
                        deleteBlock({
                            blocksType,
                            branchId,
                            blockId: options.id,
                            index,
                            sectionId,
                            questionOptionId,
                            freeTextResponseId,
                        })
                    )
                }
                dispatch(
                    addBlocks({
                        blocksType,
                        branchId,
                        index,
                        blocks: blocksToAdd,
                        sectionId,
                        questionOptionId,
                        freeTextResponseId,
                    })
                )
                dispatch(selectBlock({ blockId: newBlock.id }))
            }

            const isAiOption = Object.values(ContentType).includes(type as ContentType)
            if (isAiOption) {
                if (options.id) {
                    setAiAssistData({
                        threadGUID: threadGuid,
                        objectGUID: options.id,
                        type: type as ContentType,
                        onSubmit: (aiBlocks: Block[]) => {
                            blocksToAdd = aiBlocks
                            addBlocksAux()
                            setAiAssistData(undefined)
                        },
                    })
                } else {
                    showErrorToast({
                        icon: <ErrorToastIcon />,
                        message: capitalize("An error had occurred"),
                    })
                    console.error("The object GUID is undefined")
                }
            } else if (isEmpty(blocksToAdd)) {
                let newBranch
                switch (type) {
                    case BlockType.CONDITIONAL:
                        newBranch = getEmptyBranch()
                        newBlock.branches = [newBranch]
                        break
                    case BlockType.GROUP:
                        newBranch = getGroupBlock()
                        newBlock.branches = [newBranch]
                        break
                    case BlockType.NOTIFICATION:
                        newBlock.nature = NotificationNature.SOCIAL
                        break
                    case BlockType.INCLUDE:
                        delete newBlock.value
                        newBlock.objects = []
                        break
                    case BlockType.IMAGE:
                        newBlock.imageSize = MediaSize.medium
                        break
                    case BlockType.IMAGE_CAROUSEL:
                        newBlock.choices = []
                        break
                    case BlockType.ACCORDION:
                        newBranch = getAccordionContent()
                        newBlock.branches = [newBranch]
                        newBlock.value = ""
                        break
                    default:
                        break
                }
                blocksToAdd = [newBlock]
            }

            !isEmpty(blocksToAdd) && addBlocksAux()
        },
        [blocksType, branchId, threadGuid, sectionId, questionOptionId, freeTextResponseId]
    )

    const handleBlockChange = useCallback(
        (value: string, id: string) => {
            dispatch(updateBlock({ blocksType, id, block: { value } }))
        },
        [blocksType]
    )

    const handleBlockDelete = useCallback(
        (blockId: string, index: number) => {
            /*
             * Only delete the block if:
             * - is not the last one
             * - is inside either of the panes
             * - 'enableDeletingAllBlocks' prop is true
             */
            if (Object.entries(blockIds).length > 1 || isInPane || enableDeletingAllBlocks)
                dispatch(
                    deleteBlock({
                        blocksType,
                        blockId,
                        index,
                        branchId,
                        sectionId,
                        questionOptionId,
                        freeTextResponseId,
                    })
                )
            // Focus previous block
            // @ts-ignore
            // document.querySelector(`#block-${index - 1}`)?.querySelector('.ql-editor')?.focus()
        },
        [Object.entries(blockIds).length, blocksType, branchId]
    )

    const handleBlockMove = useCallback(
        (event: SortableEvent) => {
            const blockId = event.item.id
            const branchFrom = event.from.id
            const branchTo = event.to.id
            const indexFrom = event.oldIndex
            const indexTo = event.newIndex
            const shouldCancel =
                indexFrom === undefined ||
                indexTo === undefined ||
                (branchFrom === branchTo && indexFrom === indexTo)

            duplicatedBlock.current?.remove()
            duplicatedBlock.current = null

            if (!shouldCancel) {
                dispatch(
                    moveBlock({
                        blocksType,
                        blockId,
                        branchFrom,
                        branchTo,
                        indexFrom,
                        /*
                         * The duplicated block adds 1 item to the list, but when we finish moving the block the
                         * duplicated one gets deleted that's why we have to subtract 1 to the 'indexTo'. This is
                         * only required when moving from a list to the same list, if we move from a group to a
                         * another group we don't have to do it.
                         * */
                        indexTo:
                            indexFrom < indexTo && branchFrom === branchTo ? indexTo - 1 : indexTo,
                    })
                )
            }
        },
        [duplicatedBlock]
    )

    /*
     * This method does the trick to avoid removing the list to be reordered when dragging.
     * We add a clone block to the list on the same index as the original then remove it from the list once finished
     * */
    const onStart = useCallback(
        (event: SortableEvent) => {
            const oldIndex = event.oldIndex
            if (oldIndex === undefined) return
            duplicatedBlock.current = event.from.insertBefore(
                event.item.cloneNode(true),
                event.from.children[oldIndex]
            ) as HTMLElement
            duplicatedBlock.current?.classList.remove("ghost", "sortable-chosen")
        },
        [duplicatedBlock]
    )

    const handleClickOnWrapper = useCallback(() => {
        /*
         * When the preview or synopsis panes are empty,
         * clicking on them results on a new text block being added
         */
        if (
            !readOnly &&
            isEmpty(blockIds) &&
            [BlocksType.PREVIEW, BlocksType.SYNOPSIS].includes(blocksType)
        ) {
            handleAddBlock({ index: 0 })
        }
    }, [blockIds, blocksType, readOnly])

    const clearAiData = useCallback(() => {
        setAiAssistData(undefined)
        setAiEditData(undefined)
    }, [])

    // setList is required by react-sortablejs, but we don't use it
    const reactSortableSetList = useCallback(() => undefined, [])

    return (
        <StyledEditorWrapper ref={containerRef} onClick={handleClickOnWrapper} readOnly={readOnly}>
            <ReactSortable
                id={sectionId ?? branchId ?? questionOptionId ?? freeTextResponseId}
                className={"react-sortable"}
                list={blocksArray}
                setList={reactSortableSetList}
                onEnd={handleBlockMove}
                onStart={onStart}
                disabled={readOnly}
                {...sortableOptions}
            >
                {Object.entries(blockIds).map(([i, block], index) => (
                    <EditorBlock
                        key={`editor-block-${index}`}
                        blocksType={blocksType}
                        blockId={block.id}
                        readOnlyBlock={block}
                        handlePreview={handlePreview}
                        blockIndex={index}
                        branchId={branchId}
                        sectionId={sectionId}
                        questionOptionId={questionOptionId}
                        freeTextResponseId={freeTextResponseId}
                        parentType={parentType}
                        handleBlockDelete={handleBlockDelete}
                        handleBlockChange={handleBlockChange}
                        handleAddBlock={handleAddBlock}
                        disabledColorBubble={disabledColorBubble}
                        context={context}
                        aiAssistData={aiAssistData}
                        clearAiData={clearAiData}
                        aiEditData={aiEditData}
                        setAiEditData={setAiEditData}
                        // avoid rendering quills if ai is running
                        onScreen={onScreen && !aiCreateRunning}
                    />
                ))}
            </ReactSortable>
            {/* add a bottom padding to add blocks, only on preview panes */}
            {isInPane && (
                <div
                    className={"bottom-padding"}
                    onClick={() => handleAddBlock({ index: Object.entries(blockIds).length })}
                />
            )}
        </StyledEditorWrapper>
    )
}
