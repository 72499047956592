import SaveAnswer from "../../SaveAnswer/SaveAnswer"
import { Block } from "../../../../types"
import { useRef, useState, useCallback } from "react"
import useOnClickOutside from "../../../../hooks/onClickOutside.hook"
import { StyledImageUpload } from "./styles"
import { AddBlockOptions } from "../../EditorScreen/Editor"

export interface ImageUploadBlockProps {
    block: Block
    blockIndex: number
    handleAddBlock: (options: AddBlockOptions) => void
}

const ImageUploadBlock = ({ block, blockIndex, handleAddBlock }: ImageUploadBlockProps) => {
    const [focused, setFocused] = useState(false)

    const wrapperRef = useRef(null)

    const handler = useCallback(() => setFocused(false), [])
    useOnClickOutside(wrapperRef, handler)

    return (
        <StyledImageUpload
            id={`block-${block.id}`}
            ref={wrapperRef}
            onClick={() => setFocused(true)}
        >
            <div className={"square"}>
                <p>Image Upload</p>
            </div>
            {(block.save_to_variable || focused) && (
                <SaveAnswer
                    block={block}
                    onAddBlock={() => handleAddBlock({ index: blockIndex + 1 })}
                />
            )}
        </StyledImageUpload>
    )
}

export default ImageUploadBlock
