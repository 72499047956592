import { ItemInterface, ReactSortableProps, SortableEvent } from "react-sortablejs"
import { useCallback, useMemo, useRef } from "react"
import { baseSortableOptions } from "../../utils/consts"
import { cloneDeep } from "lodash"
import { StyledReactSortable } from "./styles"

export interface MoveItemArguments {
    id: string
    groupFrom: string
    groupTo: string
    indexFrom: number
    indexTo: number
}

interface SortableListProps<T> {
    list: T[]
    renderItem: (item: T, index: number) => JSX.Element
    moveItem: (args: MoveItemArguments) => void
    id?: string
    sortableProps?: Partial<ReactSortableProps<T>>
}
const SortableList = <T extends ItemInterface>({
    id,
    list,
    renderItem,
    sortableProps,
    moveItem,
}: SortableListProps<T>) => {
    const duplicatedBlock = useRef<HTMLElement | null>(null)
    const itemsArray: T[] = useMemo(() => cloneDeep(list), [list])

    /*
     * This method does the trick to avoid removing the list to be reordered when dragging.
     * We add a clone block to the list on the same index as the original then remove it from the list once finished
     * */
    const onStart = useCallback(
        (event: SortableEvent) => {
            const oldIndex = event.oldIndex
            if (oldIndex === undefined) return
            duplicatedBlock.current = event.from.insertBefore(
                event.item.cloneNode(true),
                event.from.children[oldIndex]
            ) as HTMLElement
            duplicatedBlock.current?.classList.remove("ghost", "sortable-chosen")
        },
        [duplicatedBlock]
    )

    const onEnd = useCallback(
        (event: SortableEvent) => {
            const blockId = event.item.id
            const groupFrom = event.from.id
            const groupTo = event.to.id
            const indexFrom = event.oldIndex
            const indexTo = event.newIndex
            const shouldCancel =
                indexFrom === undefined ||
                indexTo === undefined ||
                (groupFrom === groupTo && indexFrom === indexTo)

            duplicatedBlock.current?.remove()
            duplicatedBlock.current = null

            if (!shouldCancel) {
                moveItem({ id: blockId, groupFrom, groupTo, indexFrom, indexTo })
            }
        },
        [duplicatedBlock, moveItem]
    )

    const sortableOptions = {
        ...baseSortableOptions,
        handle: undefined,
        ...sortableProps,
    }

    // setList is required by react-sortablejs, but we don't use it
    const reactSortableSetList = useCallback(() => undefined, [])

    return (
        <StyledReactSortable
            id={id}
            className={"react-sortable"}
            list={itemsArray}
            setList={reactSortableSetList}
            onEnd={onEnd}
            onStart={onStart}
            {...sortableOptions}
        >
            {list?.map((item: T, index: number) => renderItem(item, index))}
        </StyledReactSortable>
    )
}

export default SortableList
