import { ThreadTemplate } from "../../../../apollo/generated/graphql"
import EllipsisText from "../../../../components/EllipsisText"
import { StyledBody2, StyledColumn, StyledH4, StyledRow } from "../../../../styles/styledcomponents"
import { StyledArrowRight, StyledComingSoon, StyledIconContainer, StyledTemplate } from "./styles"

export type TemplateItem = {
    name: string
    description: string
    icon: string
    templateName?: ThreadTemplate
    disabled?: boolean
}

type TemplateProps = {
    template: TemplateItem
    onClick: (threadTemplate?: ThreadTemplate) => void
}

const Template = ({ template, onClick }: TemplateProps) => {
    const handleClick = () => !template.disabled && onClick(template.templateName)

    return (
        <StyledTemplate onClick={handleClick} disabled={template.disabled}>
            <StyledIconContainer>{template.icon}</StyledIconContainer>
            <StyledColumn>
                <StyledH4 useNewDesign>{template.name}</StyledH4>
                <EllipsisText
                    variant={"body2"}
                    maxLines={2}
                    maxHeight={36}
                    text={template.description}
                />
            </StyledColumn>
            {template.disabled ? (
                <StyledComingSoon>Coming Soon</StyledComingSoon>
            ) : (
                <StyledRow css={{ alignItems: "center", gap: "6px" }}>
                    <StyledBody2>Try This Template</StyledBody2>
                    <StyledArrowRight />
                </StyledRow>
            )}
        </StyledTemplate>
    )
}

export default Template
