import { rgba } from "polished"

export type ColorKey = keyof typeof Colors
export type ColorValue = typeof Colors[ColorKey]

export const Colors = {
    headlandsGreen: "#37827A",
    headlandsError: "#D84545",
    headlandsWarning: "#F1E675",
    headlandsGreenAlpha: "#D9E7E6",
    headlandsGreenDark: "#0CB969",
    headlandsBlack: "#000000",
    headlandsGrayDark: "#323232",
    headlandsGray1: "#4F4F4F",
    headlandsGray2: "#777777",
    headlandsGray3: "#DDDDDD",
    headlandsGray4: "#F1F1F1",
    headlandsGray5: "#FAFAFA",
    headlandsGray6: "#F5F5F5",
    headlandsGray7: "#C4C4C4",
    headlandsGray8: "#D0D0D0",
    headlandsGray9: "#F9F9F9",
    headlandsGray10: "#888888",
    headlandsWhite: "#FFFFFF",
    headlandsTextColor1: "#323232",
    headlandsTextColor2: "#B28773",
    headlandsTextColor3: "#BD8052",
    headlandsTextColor4: "#C29A56",
    headlandsTextColor5: "#659C75",
    headlandsTextColor6: "#6786C4",
    headlandsTextColor7: "#956ACD",
    headlandsTextColor8: "#C25F94",
    headlandsTextColor9: "#CF5D57",

    headlandsHighlightColor1: "transparent",
    headlandsHighlightColor2: rgba("#B28773", 0.25),
    headlandsHighlightColor3: rgba("#BD8052", 0.25),
    headlandsHighlightColor4: rgba("#C29A56", 0.25),
    headlandsHighlightColor5: rgba("#659C75", 0.25),
    headlandsHighlightColor6: rgba("#6786C4", 0.25),
    headlandsHighlightColor7: rgba("#956ACD", 0.25),
    headlandsHighlightColor8: rgba("#C25F94", 0.25),
    headlandsHighlightColor9: rgba("#CF5D57", 0.25),
}

export const HeadlandsFontColors = [
    Colors.headlandsTextColor1,
    Colors.headlandsTextColor2,
    Colors.headlandsTextColor3,
    Colors.headlandsTextColor4,
    Colors.headlandsTextColor5,
    Colors.headlandsTextColor6,
    Colors.headlandsTextColor7,
    Colors.headlandsTextColor8,
    Colors.headlandsTextColor9,
]

export const HeadlandsHighlightColors = [
    Colors.headlandsHighlightColor1,
    Colors.headlandsHighlightColor2,
    Colors.headlandsHighlightColor3,
    Colors.headlandsHighlightColor4,
    Colors.headlandsHighlightColor5,
    Colors.headlandsHighlightColor6,
    Colors.headlandsHighlightColor7,
    Colors.headlandsHighlightColor8,
    Colors.headlandsHighlightColor9,
]
