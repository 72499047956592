import { useState } from "react"
import {
    Block,
    BlockContext,
    BlockType,
    BlocksType,
    ChoiceQuestionOption,
    ChoiceQuestionProperties,
    FreeTextResponse,
    PartialBlock,
} from "../../../types"
import {
    StyledCorrectIcon,
    StyledCorrectnessCheck,
    StyledIncorrectIcon,
    StyledButton,
    StyledAnswersContainer,
    StyledQuestionBlock,
    StyledQuestionBlockColumn,
    StyledQuestionBlockDivider,
    StyledQuestionBlockRow,
    StyledChoiceOption,
    StyledRichTextEditor,
    StyledRuleIcon,
    StyledSettingsOutlinedIcon,
    StyledFreeTextAnswer,
    StyledSendIcon,
    StyledInsertPhotoOutlineIcon,
    StyledChoiceOptionImage,
    StyledAnimatedScore,
    StyledCollapseIcon,
} from "./styles"
import ManageResponsesModal from "./ManageResponsesModal"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { findBlock, updateBlock } from "../../../redux/blocks"
import { Editor } from "../EditorScreen/Editor"
import { StyledBody1, StyledBox, StyledHr, StyledRow } from "../../../styles/styledcomponents"
import SaveAnswer from "../SaveAnswer/SaveAnswer"
import { CSSObject, useTheme } from "styled-components"
import PopupMenu, { PopupOptionType } from "../../../components/PopupMenu"
import BlockImage from "../BlockContent/BlockImage"
import { generateUUID } from "../../../utils/utils"
import DeletableWrapper from "../../../components/DeletableWrapper"
import { ImageSearchProvider } from "../../../apollo/generated/graphql"
import { ImageModalOption } from "../MediaModal/ImageModalContent"
import ImagePopup from "../ImagePopup"
import { Maybe } from "graphql/jsutils/Maybe"
import { isNil } from "lodash"
import { AnimatePresence, motion } from "framer-motion"
import EllipsisText from "../../../components/EllipsisText"
import { useUserPermissions } from "../../../hooks/userPermissions"

type QuestionBlockProps = {
    block: Block
    blocksType: BlocksType
    context?: BlockContext
}

export enum CorrectnessCheckShape {
    circular,
    square,
}

const QuestionBlock = ({ block, blocksType }: QuestionBlockProps) => {
    const [collapsed, setCollapsed] = useState(true)
    const [modalOpen, setModalOpen] = useState(false)
    const dispatch = useDispatch()

    const {
        permissions: { maySeeExperimentalFeatures },
    } = useUserPermissions()

    // TODO: Select the correct option. But what happens when `no_correct_answers` is true? Define
    const correctOptionId = block.options?.find((option) => option.correct)?.id
    const correctOptionIndex = block.options?.findIndex((option) => option.correct)

    const blockIdsObjects = useSelector((state: RootState) => {
        const foundBlock = findBlock(block.id, state.blocksReducer.blocksIndex)
        if (block.type === BlockType.CHOICE_QUESTION && correctOptionIndex !== undefined) {
            if (foundBlock?.options && foundBlock.options[correctOptionIndex]) {
                return foundBlock.options[correctOptionIndex].objects
            }
        } else if (block.type === BlockType.FREE_TEXT_QUESTION) {
            if (foundBlock?.responses && foundBlock.responses[0]) {
                return foundBlock.responses[0].objects
            }
        }
    }, shallowEqual)

    const handleQuestionUpdate = (question?: string) => {
        dispatch(
            updateBlock({
                blocksType,
                id: block.id,
                block: { question },
            })
        )
    }

    const handlePropertiesUpdate = (properties?: Partial<ChoiceQuestionProperties>) => {
        dispatch(
            updateBlock({
                blocksType,
                id: block.id,
                block: { properties: { ...block.properties, ...properties } },
            })
        )
    }

    const handleAddImage = () => {
        dispatch(
            updateBlock({
                blocksType,
                id: block.id,
                block: {
                    image: {
                        id: generateUUID(),
                        type: BlockType.IMAGE,
                        value: "",
                    },
                },
            })
        )
    }

    const handleDeleteImage = () => {
        dispatch(
            updateBlock({
                blocksType,
                id: block.id,
                block: {
                    image: undefined,
                },
            })
        )
    }

    const handleImageUpdate = (imageBlock: Block) => {
        dispatch(
            updateBlock({
                blocksType,
                id: block.id,
                block: { image: { ...block.image, ...imageBlock } },
            })
        )
    }

    const handleQuestionChoiceImageUpdate = (imageUrl: string | null, optionId: string) => {
        const updatedOption = block.options?.map((option) => {
            if (option.id === optionId) {
                // here we spit the previous image url of the option (which we won't use it) and the 'rest' of the option
                const { image_url: _, ...rest } = option
                if (imageUrl) return { ...rest, image_url: imageUrl }
                // delete image_url if `imageUrl` is null
                return { ...rest }
            }
            return option
        })

        let blockToUpdate: PartialBlock = { options: updatedOption }

        // avoid `display_poll` if the choices have images
        if (block.properties?.display_poll) {
            blockToUpdate.properties = { ...block.properties, display_poll: false }
        }
        dispatch(
            updateBlock({
                blocksType,
                id: block.id,
                block: blockToUpdate,
            })
        )
    }

    const handleMultipleSelectionChange = (allowMultipleSelection: boolean) => {
        handlePropertiesUpdate({
            may_select_multiple: !block?.properties?.may_select_multiple,
        })

        dispatch(
            updateBlock({
                blocksType,
                id: block.id,
                block: {
                    options: block.options?.map((option, index) => ({
                        ...option,
                        /*
                         * If `allowMultipleSelection` set the first and second options as correct,
                         * else set only the first one as correct.
                         */
                        correct: allowMultipleSelection ? [0, 1].includes(index) : index === 0,
                    })),
                },
            })
        )
    }

    return (
        <StyledQuestionBlock>
            <StyledQuestionBlockColumn css={{ gap: "6px" }}>
                <StyledRow css={{ justifyContent: "space-between" }}>
                    {!block?.image ? (
                        <AddImageOrVideoRow handleAddImage={handleAddImage} />
                    ) : (
                        <DeletableWrapper onDelete={handleDeleteImage}>
                            <BlockImage
                                block={block.image}
                                blocksType={BlocksType.BLOCKS}
                                onUpdate={handleImageUpdate}
                                availableImageModalTabs={[
                                    ImageSearchProvider.Bing,
                                    ImageSearchProvider.Unsplash,
                                    ImageSearchProvider.BingGif,
                                    ImageSearchProvider.Giphy,
                                    ImageModalOption.DallE,
                                    ImageModalOption.UploadOrUrl,
                                ]}
                            />
                        </DeletableWrapper>
                    )}
                    <StyledCollapseIcon
                        onClick={() => setCollapsed(!collapsed)}
                        collapsed={collapsed}
                    />
                </StyledRow>
                <StyledRichTextEditor
                    value={block?.question}
                    onChange={(value) => handleQuestionUpdate(value)}
                    enableFormatting
                    enableVariables
                    enableEmojis
                />
            </StyledQuestionBlockColumn>
            <AnimatePresence initial={false}>
                {!collapsed && (
                    <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <StyledQuestionBlockColumn css={{ gap: "6px" }}>
                            <StyledAnswersContainer>
                                <AnswerSection
                                    blockType={block.type}
                                    responses={block.responses}
                                    options={block.options}
                                    properties={block.properties}
                                    onImageUploaded={handleQuestionChoiceImageUpdate}
                                />
                            </StyledAnswersContainer>
                            <StyledRow css={{ justifyContent: "space-between" }}>
                                <SaveAnswer block={block} />
                                <StyledRow css={{ gap: "6px" }}>
                                    <StyledButton onClick={() => setModalOpen(true)}>
                                        <StyledRuleIcon />
                                        Manage Responses
                                    </StyledButton>
                                    {maySeeExperimentalFeatures && (
                                        <PopupMenu
                                            position={["right center", "left center"]}
                                            on={["click"]}
                                            arrow={false}
                                            options={[
                                                {
                                                    label: "Show as Poll",
                                                    checkboxId: "show_as_poll",
                                                    selected: !!block?.properties?.display_poll,
                                                    noDivider: true,
                                                    onClick: () =>
                                                        handlePropertiesUpdate({
                                                            display_poll:
                                                                !block?.properties?.display_poll,
                                                        }),
                                                    type: PopupOptionType.TEXT_AND_CHECKBOX,
                                                    hide:
                                                        block.type === BlockType.FREE_TEXT_QUESTION,
                                                    // disable poll if some options has images
                                                    disabled: block.options?.some(
                                                        (option) => option.image_url
                                                    ),
                                                },
                                                {
                                                    label: "Allow Multiple Selection",
                                                    checkboxId: "may_select_multiple",
                                                    selected:
                                                        !!block?.properties?.may_select_multiple,
                                                    noDivider: true,
                                                    onClick: () =>
                                                        handleMultipleSelectionChange(
                                                            !block?.properties?.may_select_multiple
                                                        ),
                                                    type: PopupOptionType.TEXT_AND_CHECKBOX,
                                                    hide:
                                                        block.type === BlockType.FREE_TEXT_QUESTION,
                                                },
                                                {
                                                    label: "Has Correct Answer (s)",
                                                    checkboxId: "has_correct_answer",
                                                    selected:
                                                        !block?.properties?.no_correct_answers,
                                                    onClick: () =>
                                                        handlePropertiesUpdate({
                                                            no_correct_answers:
                                                                !block?.properties
                                                                    ?.no_correct_answers,
                                                        }),
                                                    type: PopupOptionType.TEXT_AND_CHECKBOX,
                                                },
                                            ]}
                                            trigger={
                                                <StyledButton>
                                                    <StyledSettingsOutlinedIcon />
                                                </StyledButton>
                                            }
                                        />
                                    )}
                                </StyledRow>
                            </StyledRow>
                        </StyledQuestionBlockColumn>
                        <QuestionBlockDivider />
                        <StyledQuestionBlockRow>
                            <Editor
                                blocksType={blocksType}
                                blockIds={blockIdsObjects || []}
                                questionOptionId={
                                    block.type === BlockType.CHOICE_QUESTION
                                        ? correctOptionId
                                        : undefined
                                }
                                freeTextResponseId={
                                    block.type === BlockType.FREE_TEXT_QUESTION && block.responses
                                        ? block.responses[0].id
                                        : undefined
                                }
                                parentType={block.type}
                                context={[BlockType.CHOICE_QUESTION]}
                                containsChunks
                                readOnly
                            />
                        </StyledQuestionBlockRow>
                    </motion.div>
                )}
            </AnimatePresence>
            {modalOpen && (
                <ManageResponsesModal
                    block={block}
                    blocksType={blocksType}
                    onClose={() => setModalOpen(false)}
                />
            )}
        </StyledQuestionBlock>
    )
}

interface AnswerSectionProps {
    blockType: BlockType
    responses?: Maybe<FreeTextResponse[]>
    options?: Maybe<ChoiceQuestionOption[]>
    properties?: Partial<ChoiceQuestionProperties>
    onImageUploaded: (imageUrl: string | null, optionId: string) => void
}

const AnswerSection = ({
    blockType,
    responses,
    options,
    properties,
    onImageUploaded,
}: AnswerSectionProps) => {
    switch (blockType) {
        case BlockType.FREE_TEXT_QUESTION:
            return (
                <StyledFreeTextAnswer>
                    {responses && responses[0].input && (
                        <EllipsisText
                            variant={"body1"}
                            text={responses[0].input}
                            maxLines={3}
                            maxHeight={60}
                            popupOptions={{ position: "top center" }}
                        />
                    )}
                    <StyledSendIcon />
                </StyledFreeTextAnswer>
            )
        case BlockType.CHOICE_QUESTION:
        default:
            return (
                <>
                    {options?.map((option) => (
                        <ChoiceOption
                            key={`question-option-${option.id}`}
                            option={option}
                            showCorrectness={!properties?.no_correct_answers}
                            onImageUploaded={onImageUploaded}
                            correctnessShape={
                                properties?.may_select_multiple
                                    ? CorrectnessCheckShape.square
                                    : CorrectnessCheckShape.circular
                            }
                        />
                    ))}
                </>
            )
    }
}

const QuestionBlockDivider = () => (
    <StyledQuestionBlockDivider>
        <StyledHr />
    </StyledQuestionBlockDivider>
)

interface ChoiceOptionProps {
    option: ChoiceQuestionOption
    showCorrectness?: boolean
    onImageUploaded: (imageUrl: string | null, optionId: string) => void
    correctnessShape?: CorrectnessCheckShape
}

const ChoiceOption = ({
    option,
    showCorrectness,
    onImageUploaded,
    correctnessShape,
}: ChoiceOptionProps) => {
    return (
        <StyledRow css={{ alignItems: "center", gap: "6px", width: "100%" }}>
            {showCorrectness && (
                <CorrectnessCheck correct={option.correct} shape={correctnessShape} />
            )}
            <StyledChoiceOption correct={option.correct}>
                <ImagePopup
                    position={["left center"]}
                    trigger={
                        option.image_url ? (
                            <div>
                                <DeletableWrapper
                                    onDelete={(event) => {
                                        event?.stopPropagation()
                                        onImageUploaded(null, option.id)
                                    }}
                                >
                                    <StyledChoiceOptionImage src={option.image_url} />
                                </DeletableWrapper>
                            </div>
                        ) : (
                            <StyledInsertPhotoOutlineIcon />
                        )
                    }
                    onImageUploaded={({ value }) => onImageUploaded(value, option.id)}
                    closeOnUploaded
                    comment={option.text}
                />
                <StyledBody1>{option.text}</StyledBody1>
            </StyledChoiceOption>
        </StyledRow>
    )
}

type CorrectnessCheckProps = {
    correct?: boolean
    score?: number
    size?: number
    css?: CSSObject
    shape?: CorrectnessCheckShape
    animated?: boolean
    onChange?: () => void
}

export const CorrectnessCheck = ({
    correct,
    score,
    size = 18,
    shape = CorrectnessCheckShape.circular,
    animated,
    css,
    onChange,
}: CorrectnessCheckProps) => {
    return (
        <StyledCorrectnessCheck
            correct={correct}
            size={size}
            css={css}
            shape={shape}
            animated={animated}
            onClick={onChange}
        >
            {correct ? (
                <StyledCorrectIcon sx={{ fontSize: Math.floor(size - size * 0.33) }} />
            ) : (
                <StyledIncorrectIcon sx={{ fontSize: Math.floor(size - size * 0.33) }} />
            )}
            {!isNil(score) && <StyledAnimatedScore>+{score} pts</StyledAnimatedScore>}
        </StyledCorrectnessCheck>
    )
}

interface AddImageOrVideoRowProps {
    handleAddImage: () => void
}

const AddImageOrVideoRow = ({ handleAddImage }: AddImageOrVideoRowProps) => {
    const theme = useTheme()
    return (
        <StyledBox css={{ display: "flex", gap: "3px" }}>
            <StyledButton onClick={handleAddImage}>
                <StyledInsertPhotoOutlineIcon />
                Add Image
            </StyledButton>
            {/* <StyledBody1 css={{ color: theme.colors.base.uiLabelPlaceholder }}>or</StyledBody1>
            <StyledButton onClick={() => {}}>
                <StyledVideocamOutlinedIcon />
                Add Video
            </StyledButton> */}
        </StyledBox>
    )
}

export default QuestionBlock
