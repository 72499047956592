import { LoaderType } from "../../types"
import { StyledSocialBar, StyledSocialBarScrollableContainer, StyledSocialTopBar } from "./styles"
import { StyledH4 } from "../../styles/styledcomponents"
import UserRow from "../UserRow"
import { compact, orderBy } from "lodash"
import LoaderSkeleton from "../../common/components/LoaderSkeleton"
import { useCurrentUserAttribute } from "../../hooks/currentUser"
import useLockScroll from "../../hooks/lockSroll.hook"
import { useSidebars } from "../../hooks/sidebars.hook"
import useThreadProgressForProgram from "../../hooks/threadProgressForProgram.hook"
import { useMemo } from "react"
import { useCurrentThreadField } from "../../hooks/currentThread.hook"

interface SocialBarProps {
    title?: string
}

const SocialBar = ({ title }: SocialBarProps) => {
    const { data, loading } = useThreadProgressForProgram()

    const leaderboardItems = useMemo(
        () => compact(data?.threadProgressForProgram),
        [data?.threadProgressForProgram]
    )

    const { attribute: currentUserId } = useCurrentUserAttribute("id")
    const { socialBarOpen } = useSidebars()

    const threadTitle = useCurrentThreadField("title")

    useLockScroll({ condition: !!socialBarOpen })

    return (
        <StyledSocialBar>
            <StyledSocialTopBar>
                <StyledH4 useNewDesign>{title ?? threadTitle}</StyledH4>
            </StyledSocialTopBar>
            <StyledSocialBarScrollableContainer>
                {loading ? (
                    <>
                        <LoaderSkeleton type={LoaderType.SOCIAL} withDivider />
                        {Array.from(Array(6).keys()).map((item, index) => (
                            <LoaderSkeleton key={index} type={LoaderType.SOCIAL} />
                        ))}
                    </>
                ) : (
                    orderBy(
                        leaderboardItems,
                        ({ threadScore }) => threadScore?.totalPoints || "",
                        "desc"
                    )?.map(
                        ({ person, threadScore }, index) =>
                            person &&
                            threadScore && (
                                <UserRow
                                    key={`user-${person.id}-${index}`}
                                    person={person}
                                    index={index}
                                    isCurrentUser={person.id === currentUserId}
                                    score={threadScore.totalPoints}
                                />
                            )
                    )
                )}
            </StyledSocialBarScrollableContainer>
            {/* this svg won't be visible, but we'll use is for the progress ring of each UserRow */}
            <svg style={{ height: 0 }}>
                <defs>
                    <linearGradient id={"progress-gradient"}>
                        <stop offset="0%" stopColor={"#86F175"} />
                        <stop offset="50%" stopColor={"#F1E675"} />
                        <stop offset="100%" stopColor={"#F17575"} />
                    </linearGradient>
                </defs>
            </svg>
        </StyledSocialBar>
    )
}

export default SocialBar
