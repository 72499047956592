import { StyledBox, StyledH3, StyledP } from "../../styles/styledcomponents"
import { TrashIcon } from "../../assets/icons/TrashIcon"
import { Button } from "../Button/Button"
import Modal, { NewModalProps } from "../Modal"
import React from "react"

interface DeleteConfirmationModalProps extends Omit<NewModalProps, "children"> {
    loading?: boolean
    onCancel?: () => void
    onConfirm?: () => void
    deleteItemName: string
}

const DeleteConfirmationModal = ({
    loading,
    onCancel,
    onConfirm,
    deleteItemName,
    ...modalProps
}: DeleteConfirmationModalProps) => {
    return (
        <Modal {...modalProps} styles={{ width: "500px" }}>
            <StyledH3>
                <TrashIcon /> Are you sure?
            </StyledH3>
            <StyledP css={{ margin: "15px 0", textAlign: "center" }}>
                {`Do you really want to delete `}
                <b>{`${deleteItemName ?? "it"}? `}</b>
                <br />
                This process cannot be undone
            </StyledP>
            <StyledBox css={{ display: "flex", gap: "15px" }}>
                <Button
                    label={"Cancel"}
                    disabled={loading}
                    variant={"outline"}
                    onClick={onCancel ?? modalProps?.onClose}
                />
                <Button
                    label={"Delete"}
                    loading={loading}
                    onClick={onConfirm}
                    data-testid={"delete-thread-button"}
                />
            </StyledBox>
        </Modal>
    )
}

export default DeleteConfirmationModal
