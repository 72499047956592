import { StyledBox, StyledH4 } from "../../styles/styledcomponents"
import { StyledCardContainer, StyledCreatorMobileScreen, StyledList } from "./styles"
import SearchBar from "../../deliver/components/SearchBar"
import CreatorThreadItem from "./components/CreatorThreadItem"
import { useInfinitiveScrollPagination } from "../../hooks/pagination.hook"
import { SEARCH_CREATOR_TOOL_THREADS_MOBILE } from "../../apollo/queries"
import { Loader } from "../../components/Loader"
import { isEmpty, pick } from "lodash"
import CreatorMobileTopBar from "./components/CreatorMobileTopBar"

const CreatorMobile = () => {
    const { items, loading, endRef, setSearch } = useInfinitiveScrollPagination({
        query: SEARCH_CREATOR_TOOL_THREADS_MOBILE,
        mapData: (data) =>
            data?.searchThreads?.map(({ thread }) => ({
                thread: pick(thread, [
                    "guid",
                    "title",
                    "description",
                    "imageURL",
                    "lastUpdateTime",
                ]),
                instructor: pick(thread.instructor, ["firstName", "lastName", "profileImageURL"]),
            })) || [],
        mapVariables: ({ pageSize, pageOffset, search }) => ({
            input: { query: search, count: pageSize, offset: pageOffset },
        }),
    })

    return (
        <StyledCreatorMobileScreen>
            <CreatorMobileTopBar showCreateButton>
                <StyledH4 useNewDesign css={{ alignSelf: "center", textAlign: "center" }}>
                    Create
                </StyledH4>
            </CreatorMobileTopBar>
            <StyledCardContainer>
                <SearchBar placeholder={"Search for Threads or Instructors"} onChange={setSearch} />
                <StyledList css={{ marginTop: "12px" }}>
                    {items?.map((item, index) => (
                        <CreatorThreadItem key={`thread-item-${index}`} {...item} />
                    ))}
                    {!isEmpty(items) && (
                        <StyledBox ref={endRef} style={{ minHeight: "30px", padding: "3px" }}>
                            {loading && <Loader size={24} />}
                        </StyledBox>
                    )}
                </StyledList>
            </StyledCardContainer>
        </StyledCreatorMobileScreen>
    )
}

export default CreatorMobile
