import { useWindowWidth } from "@react-hook/window-size"
import { isMobileOnly, isTablet, isMobile } from "react-device-detect"

const useLayout = () => {
    const width = useWindowWidth()

    return {
        width,
        isMobile: isMobileOnly,
        isTablet,
        isTouchable: isMobile,
    }
}

export default useLayout
