import styled from "styled-components"
import { StyledBody1Styles, StyledBox } from "../../../../styles/styledcomponents"

export const StyledCommentBlock = styled(StyledBox)`
    border-radius: 0 3px 3px 0;
    background-color: ${({ theme }) => theme.colors.base.uiWarning400};
    border-left: 4px solid ${({ theme }) => theme.colors.base.uiWarning};
    padding: 14px 6px;

    p {
        ${StyledBody1Styles};
        color: ${({ theme }) => theme.colors.base.uiLabelBase};
    }
`
