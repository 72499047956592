import { ReactSortable } from "react-sortablejs"
import styled from "styled-components"

export const StyledReactSortable = styled(ReactSortable)`
    .ghost {
        width: 100%;
        height: 6px;
        background-color: ${({ theme }) => theme.colors.base.uiSystemHover};
        border-radius: 8px;
        padding: 0;
        margin: 0;

        * {
            display: none;
        }
    }
`
