import { SkeletonChevron, SkeletonHeadlands, StyledRightContainerBox } from "./styles"
import { BlockLoaderType, LoaderType } from "../../../types"
import { StyledBox, StyledHr } from "../../../styles/styledcomponents"
import { useTheme } from "styled-components"

interface LoaderSkeletonProp {
    type: LoaderType
    className?: string
    withDivider?: boolean
    from?: string
    blockType?: BlockLoaderType
}

const LoaderSkeleton = ({ type, withDivider, from, blockType }: LoaderSkeletonProp) => {
    const theme = useTheme()

    const manageSkeleton = () => {
        switch (type) {
            case LoaderType.THREAD:
                return threadLoader()
            case LoaderType.THREADCREATOR:
                return threadCreatorLoader()
            case LoaderType.SOCIAL:
                return socialLoader()
            case LoaderType.BLOCK:
                return blockLoader(blockType, from)
            case LoaderType.PROGRAM:
                return programLoader()
        }
    }

    const blockLoader = (type: BlockLoaderType | undefined, from: string | undefined) => {
        return (
            // @ts-ignore
            <StyledBox
                css={{
                    display: "flex",
                    margin: "8px 0",
                    flexDirection: `${from === "from-left" ? "row" : "row-reverse"}`,
                }}
            >
                <SkeletonHeadlands className={`${type} ${from}`} />
            </StyledBox>
        )
    }

    const programLoader = () => {
        return (
            <StyledBox
                css={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <StyledBox
                    css={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "8px 4px",
                    }}
                >
                    <SkeletonHeadlands
                        height={"18px"}
                        width={"80%"}
                        css={{ borderRadius: "7px" }}
                    />
                    <SkeletonChevron />
                </StyledBox>
            </StyledBox>
        )
    }

    const threadLoader = () => {
        return (
            <StyledBox
                css={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "8px 0 6px 0",
                }}
            >
                <SkeletonHeadlands
                    height={"44px"}
                    width={"44px"}
                    css={{ borderRadius: "50%", minWidth: 44 }}
                />
                <StyledRightContainerBox>
                    <SkeletonHeadlands
                        height={"12px"}
                        width={"90%"}
                        css={{
                            borderRadius: "7px",
                            marginBottom: 5,
                        }}
                    />
                    <SkeletonHeadlands
                        width={"60%"}
                        height={"12px"}
                        css={{
                            borderRadius: "7px",
                            marginBottom: 5,
                        }}
                    />
                    <SkeletonHeadlands
                        height={"12px"}
                        width={"85%"}
                        css={{
                            borderRadius: "7px",
                            marginBottom: 5,
                        }}
                    />
                </StyledRightContainerBox>
            </StyledBox>
        )
    }

    const threadCreatorLoader = () => {
        return (
            <StyledBox
                css={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "11px 0 8px 0",
                }}
            >
                <SkeletonHeadlands
                    height={"44px"}
                    width={"44px"}
                    css={{ borderRadius: "50%", minWidth: 44 }}
                />
                <StyledRightContainerBox>
                    <SkeletonHeadlands
                        height={"12px"}
                        width={"90%"}
                        css={{
                            borderRadius: "7px",
                            marginBottom: 5,
                        }}
                    />
                    <SkeletonHeadlands
                        width={"60%"}
                        height={"12px"}
                        css={{
                            borderRadius: "7px",
                            marginBottom: 5,
                        }}
                    />
                    <SkeletonHeadlands
                        height={"12px"}
                        width={"90%"}
                        css={{
                            borderRadius: "7px",
                            marginBottom: 5,
                        }}
                    />
                </StyledRightContainerBox>
            </StyledBox>
        )
    }

    const socialLoader = () => {
        return (
            <StyledBox
                css={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "11px 0 8px 15px",
                }}
            >
                <StyledBox css={{ position: "relative" }}>
                    <SkeletonHeadlands
                        width={"44px"}
                        height={"44px"}
                        css={{ borderRadius: "50%", minWidth: 44 }}
                    />
                    <span
                        style={{
                            position: "absolute",
                            bottom: "-3px",
                            right: "-3px",
                            border: `3px solid ${theme.headlandsWhite}`,
                            height: 12,
                            width: 12,
                            borderRadius: 50,
                            backgroundColor: "white",
                        }}
                    />
                    <SkeletonHeadlands
                        width={"12px"}
                        height={"12px"}
                        css={{
                            borderRadius: "50%",
                            minWidth: 12,
                            position: "absolute",
                            bottom: "-3px",
                            right: "-3px",
                            border: `3px solid ${theme.headlandsWhite}`,
                        }}
                    />
                </StyledBox>
                <StyledRightContainerBox
                    css={{
                        height: "auto !important",
                    }}
                >
                    <SkeletonHeadlands
                        width={"60%"}
                        height={"12px"}
                        css={{
                            borderRadius: "7px",
                            marginBottom: 5,
                        }}
                    />
                    <SkeletonHeadlands
                        width={"80%"}
                        height={"12px"}
                        css={{
                            borderRadius: "7px",
                            marginBottom: 5,
                        }}
                    />
                </StyledRightContainerBox>
            </StyledBox>
        )
    }

    return (
        <StyledBox>
            {manageSkeleton()}
            {withDivider && (
                <StyledHr
                    css={{
                        marginTop: "10px",
                        bottom: "-1px",
                        width: "100%",
                        borderColor: theme.headlandsGray3,
                    }}
                />
            )}
        </StyledBox>
    )
}

export default LoaderSkeleton
