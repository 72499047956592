import { useMemo, useState } from "react"
import { StyledBody1, StyledColumn, StyledRow } from "../../../../styles/styledcomponents"
import { Block } from "../../../../types"
import {
    StyledChoiceOption,
    StyledChoiceOptionImage,
    StyledCollapseIcon,
} from "../../../components/QuestionBlock/styles"
import TextBlock from "../TextBlock"
import { StyledDivider, StyledMultipleChoiceBlock } from "./styles"
import { CorrectnessCheck, CorrectnessCheckShape } from "../../../components/QuestionBlock"
import CreatorBlockMobile from "../CreatorBlockMobile"
import ImageBlock from "../ImageBlock"
import { AnimatePresence, motion } from "framer-motion"

const MultipleChoiceBlock = ({ block }: { block: Block }) => {
    const [collapsed, setCollapsed] = useState(false)

    const correctOption = useMemo(() => block?.options?.find((option) => option.correct), [block])

    return (
        <StyledMultipleChoiceBlock>
            {block.image && <ImageBlock block={block.image} />}
            <StyledRow css={{ alignItems: "center" }}>
                <TextBlock value={block.question} />
                <StyledCollapseIcon
                    collapsed={collapsed}
                    onClick={() => setCollapsed(!collapsed)}
                />
            </StyledRow>
            <AnimatePresence initial={false}>
                {!collapsed && (
                    <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                        }}
                    >
                        <StyledColumn css={{ padding: "12px 6px", gap: "6px" }}>
                            {block.options?.map((option) => (
                                <ChoiceOption
                                    text={option.text}
                                    imageUrl={option.image_url}
                                    correct={option.correct}
                                    correctnessShape={
                                        block.properties?.may_select_multiple
                                            ? CorrectnessCheckShape.square
                                            : CorrectnessCheckShape.circular
                                    }
                                />
                            ))}
                        </StyledColumn>
                        <StyledDivider />
                        <StyledColumn>
                            {correctOption?.objects.map((objectBlock) => (
                                <CreatorBlockMobile block={objectBlock} />
                            ))}
                        </StyledColumn>
                    </motion.div>
                )}
            </AnimatePresence>
        </StyledMultipleChoiceBlock>
    )
}

type ChoiceOptionProps = {
    text?: string
    imageUrl?: string
    correct?: boolean
    correctnessShape?: CorrectnessCheckShape
}

const ChoiceOption = ({ correct, correctnessShape, text = "", imageUrl }: ChoiceOptionProps) => {
    return (
        <StyledRow css={{ alignItems: "center", gap: "6px" }}>
            <CorrectnessCheck correct={correct} shape={correctnessShape} />
            <StyledChoiceOption
                correct={correct}
                css={{ justifyContent: imageUrl ? "unset" : "center" }}
            >
                {imageUrl && <StyledChoiceOptionImage src={imageUrl} />}
                <StyledBody1>{text}</StyledBody1>
            </StyledChoiceOption>
        </StyledRow>
    )
}

export default MultipleChoiceBlock
