import { useCurrentThread } from "../../../hooks/currentThread.hook"
import useLayout from "../../../hooks/layout.hook"
import { StyledBody1, StyledBox, StyledRow, StyledSpan } from "../../../styles/styledcomponents"
import { Block, BlockType, OptionPreviewMode } from "../../../types"
import { StyledNextThreadContainer, StyledPaneBubble, StyledPaneContainer } from "./styles"
import ImageCarousel from "../../Carousel/ImageCarousel"
import Divider from "../../Divider"
import { useMemo } from "react"
import { parseBlocksString } from "../../../thread/utils"
import AccordionPreview from "../AccordionPreview"
import BreakpointButton from "../../BreakpointButton/BreakpointButton"
import { useHistory } from "react-router"
import { useTheme } from "styled-components"
import { InstructorAvatar } from "../../InstructorAvatar"
import classNames from "classnames"
import "../ThreadPreview.scss"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import TextPreview from "../TextPreview"
import VideoPreview from "../VideoPreview"
import ImagePreview from "../ImagePreview"
import { useSelectedEnrolledProgram } from "../../../hooks/enrolledProgram.hook"
import { useEnrolledProgramThreadsByGuid } from "../../../hooks/enrolledProgramThread.hook"
import { CompletedIcon } from "../../../assets/icons/CompletedIcon"
import ShareThreadButton from "./ShareThreadButton"
import { useShouldShowScoreHeader } from "../../../hooks/shouldShowScoreHeader"

interface PanePreviewProps {
    type: BlockType.SYNOPSIS | BlockType.PREVIEW
    isPreview?: boolean
    onNextClick?: () => void
    disableNext?: boolean
}

const PanePreview = ({ type, isPreview, onNextClick, disableNext }: PanePreviewProps) => {
    const { isMobile } = useLayout()
    const thread = useCurrentThread()
    const blocks = parseBlocksString(thread.thread ? thread.thread[type] : undefined)
    const history = useHistory()
    const { selectedEnrolledProgram } = useSelectedEnrolledProgram()
    const { enrolledProgramThreads } = useEnrolledProgramThreadsByGuid(
        selectedEnrolledProgram?.guid
    )
    const theme = useTheme()
    const isMobilePreview = useSelector(
        (state: RootState) =>
            state.threadsReducer.previewMode &&
            state.threadsReducer.previewDevice === OptionPreviewMode.MOBILE
    )
    const scoreHeaderIsShown = useShouldShowScoreHeader()

    const currentThreadIndex = useMemo(
        () =>
            enrolledProgramThreads?.findIndex(
                (enrolledProgramThread) => enrolledProgramThread.thread.guid === thread.thread?.guid
            ),
        [enrolledProgramThreads]
    )

    const nextThreadGuid = useMemo(
        () =>
            typeof currentThreadIndex === "number" &&
            enrolledProgramThreads &&
            enrolledProgramThreads[currentThreadIndex + 1]?.thread?.guid,
        [currentThreadIndex, enrolledProgramThreads]
    )

    const hasNext = useMemo(
        () => !!nextThreadGuid || !!(isPreview && type === BlockType.PREVIEW),
        [nextThreadGuid, isPreview, type]
    )

    const handleNextThread = () => {
        if (isPreview && onNextClick) {
            onNextClick()
        } else {
            nextThreadGuid &&
                history.push(`/program/${selectedEnrolledProgram?.guid}/thread/${nextThreadGuid}`)
        }
    }

    const renderBlock = (block: Block) => {
        switch (block.type) {
            case BlockType.TEXT:
                return <TextPreview block={block} />
            case BlockType.IMAGE:
                return <ImagePreview block={block} />
            case BlockType.VIDEO:
                return <VideoPreview block={block} />
            case BlockType.IMAGE_CAROUSEL:
                return (
                    <ImageCarousel
                        id={block.id}
                        imagesList={block.choices?.filter((images) => images !== undefined)}
                    />
                )
            case BlockType.DIVIDER:
                return <Divider bubble={block} />
            case BlockType.ACCORDION:
                return <AccordionPreview bubble={block} insidePane />
            case BlockType.INSTRUCTOR_AVATAR:
                return <InstructorAvatar preview />
        }
    }

    return (
        <StyledPaneContainer
            id={`${type}-pane`}
            className={classNames({
                "thread-preview": type === BlockType.PREVIEW,
                mobile: isMobile,
            })}
        >
            <StyledPaneBubble
                data-testid={`${type}-pane`}
                isMobile={isMobile}
                isMobilePreview={isMobilePreview}
                hasNext={hasNext}
                isPreview={isPreview}
                scoreHeaderIsShown={scoreHeaderIsShown}
                type={type}
            >
                {type === BlockType.SYNOPSIS && (
                    <StyledRow css={{ alignItems: "center", gap: "8px" }}>
                        <CompletedIcon />
                        <StyledBody1
                            css={{ fontWeight: 600, color: theme.colors.base.uiPrimary500 }}
                        >
                            Completed
                        </StyledBody1>
                    </StyledRow>
                )}

                {blocks?.map((block: Block) => (
                    <StyledBox key={block.id} css={{ display: "flex", flexDirection: "column" }}>
                        {renderBlock(block)}
                    </StyledBox>
                ))}
                {type === BlockType.SYNOPSIS && <ShareThreadButton />}
            </StyledPaneBubble>
            {hasNext && (
                <StyledNextThreadContainer
                    data-testid={"next-thread-button"}
                    mobile={isMobile}
                    onClick={handleNextThread}
                    disabled={disableNext}
                >
                    <StyledSpan css={{ marginRight: "6px", color: theme.headlandsGray3 }}>
                        Tap to continue
                    </StyledSpan>
                    <BreakpointButton white border />
                </StyledNextThreadContainer>
            )}
        </StyledPaneContainer>
    )
}

export default PanePreview
