import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { ACCEPTED_IMAGE_FORMATS } from "../ThreadPreview/ImageUploaderPreview"
import imageIcon from "../../assets/icons/image.svg"
import thumbnail from "../../assets/icons/thumbnail.svg"
import { ImagePreviewIcon } from "../../assets/icons/ImagePreviewIcon"
import { StyledDropzone, StyledErrorDropzone } from "./styles"

type ImageUploadDropzoneProps = {
    image?: File
    onChange: (file: File) => void
    error?: boolean
    onError: (error: boolean) => void
}

const ImageUploadDropzone = ({ image, error, onChange, onError }: ImageUploadDropzoneProps) => {
    const [fileRejected, setFileRejected] = useState<File>()

    const onDrop = useCallback(
        (acceptedFiles, fileRejections) => {
            if (acceptedFiles.length) {
                onError(false)
                onChange(acceptedFiles[0])
            } else {
                onError(true)
            }
            if (fileRejections.length) {
                onError(true)
                setFileRejected(fileRejections[0]?.file)
            }
        },
        [onChange, onError]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ACCEPTED_IMAGE_FORMATS,
        maxFiles: 1,
        maxSize: 20000000,
    })
    return (
        <>
            <StyledDropzone error={error} {...getRootProps()}>
                <input {...getInputProps()} data-testid={"upload-input"} />
                {isDragActive ? (
                    <img src={imageIcon} alt={"icon"} />
                ) : (
                    <div>
                        {image ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                    alt={"thumbnail"}
                                    src={thumbnail}
                                    style={{ width: 16, marginRight: 6 }}
                                />
                                <p>{image.name}</p>
                            </div>
                        ) : (
                            <>
                                <p>
                                    <u>Upload</u> or drop an image here
                                </p>
                                <p>JPG, PNG or GIF. Up to 20MB</p>
                            </>
                        )}
                    </div>
                )}
            </StyledDropzone>
            {error && (
                <StyledErrorDropzone>
                    <ImagePreviewIcon />
                    <p>{fileRejected?.name || image?.name}</p>
                </StyledErrorDropzone>
            )}
        </>
    )
}

export default ImageUploadDropzone
