import { THREAD_CONTENT_REF_ID } from "../utils/consts"
import useLayout from "./layout.hook"

export const useThreadContentRef = (): HTMLDivElement | HTMLBodyElement | null => {
    const { isMobile } = useLayout()
    const threadContentRef: HTMLDivElement | null = document.querySelector(
        `#${THREAD_CONTENT_REF_ID}`
    )
    const body = document.getElementsByTagName("body")[0]
    return isMobile ? body : threadContentRef
}
