import { EnrolledProgramParticipantPerson, UserStatus } from "../../types"
import { StyledProfileCard } from "./styles"
import { useTheme } from "styled-components"
import { StyledBox, StyledH6, StyledP } from "../../styles/styledcomponents"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import { UserAvatar } from "../../creator/components/UserAvatar"

export interface ProfileCardProps {
    person: EnrolledProgramParticipantPerson
}

const ProfileCard = ({
    person: {
        firstName,
        profileImageURL,
        lastName,
        company,
        role,
        city,
        state,
        description,
        emoji,
    },
}: ProfileCardProps) => {
    const theme = useTheme()

    return (
        <StyledProfileCard>
            <StyledBox css={{ display: "flex" }}>
                <UserAvatar
                    user={{ profileImageURL, firstName, lastName }}
                    status={UserStatus.online}
                />
                <StyledBox css={{ display: "flex", flexDirection: "column", marginLeft: "7px" }}>
                    <StyledH6 css={{ fontSize: "14px" }}>
                        {firstName} {lastName}
                    </StyledH6>
                    <StyledP css={{ color: theme.headlandsGray2, fontSize: "12px" }}>
                        {role && company ? `${role} at ${company}` : role ?? ""}
                    </StyledP>
                    <StyledP css={{ color: theme.headlandsGray2, fontSize: "10px" }}>
                        {city && (
                            <LocationOnIcon
                                sx={{
                                    fontSize: 10,
                                    color: theme.headlandsGray2,
                                    marginRight: "3px",
                                }}
                            />
                        )}
                        {city && state ? `${city}, ${state}` : city ?? ""}
                    </StyledP>
                </StyledBox>
            </StyledBox>
            <StyledP css={{ color: theme.headlandsBlack, marginTop: "12px" }}>
                {description}
            </StyledP>
            <StyledP css={{ marginTop: "3px" }}>{emoji}</StyledP>
        </StyledProfileCard>
    )
}

export default ProfileCard
