import { CreatorToolIcon } from "../../assets/icons/CreatorToolIcon"
import LeaderboardIcon from "../../assets/icons/LeaderboardIcon"
import { StyledBottomNavigationBar, StyledNavLink } from "./styles"
import HomeIcon from "../../assets/icons/HomeIcon"
import ProfileIcon from "../../assets/icons/ProfileIcon"

interface IconLinkProps {
    link: string
    icon: any
}

const IconLink = ({ link, icon: Icon }: IconLinkProps) => {
    return (
        <StyledNavLink to={link} exact>
            <Icon />
        </StyledNavLink>
    )
}

interface BottomNavigationBarProps {
    hide?: boolean
}

const BottomNavigationBar = ({ hide }: BottomNavigationBarProps) => {
    return (
        <StyledBottomNavigationBar hide={hide}>
            <IconLink icon={HomeIcon} link={"/program"} />
            <IconLink icon={LeaderboardIcon} link={"/leaderboard"} />
            <IconLink icon={CreatorToolIcon} link={"/creator"} />
            <IconLink icon={ProfileIcon} link={"/settings"} />
        </StyledBottomNavigationBar>
    )
}

export default BottomNavigationBar
