import styled from "styled-components"
import { StyledInstructorAvatar } from "../../../components/InstructorAvatar/styles"
import { StyledBox } from "../../../styles/styledcomponents"

export const StyledAccordion = styled(StyledBox)`
    .richtext-title {
        flex: 1;
        .quill {
            height: 36px;
            max-height: 36px;
        }
    }
    .rich-text-editor {
        .quill {
            border: none !important;
            margin: 0;
            &.focused {
                border-radius: 0 !important;
                border: none !important;
                box-shadow: none !important;
            }
        }
    }
    .block-image {
        border-radius: 10px !important;
    }
    .bubble {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        border-radius: 0 !important;
        &.image {
            border-radius: 16px !important;
        }

        &:first-child {
            padding-top: 0 !important;
        }
    }
    ${StyledInstructorAvatar} {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    iframe {
        border-radius: 10px !important;
    }
    .in-pane {
        .icons-row {
            margin-left: -24px !important;
            margin-right: 24px !important;
        }
    }
`

export const StyledHeaderAccordion = styled(StyledBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    .rich-text-editor {
        .quill {
            .ql-editor {
                display: flex;
                align-items: center;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }
    }
`
