import styled from "styled-components"
import { StyledBody2, StyledBox, StyledRow } from "../../../styles/styledcomponents"

export const StyledReadOnlyPill = styled(StyledRow)`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.base.uiPrimary400};
    border: 1px solid ${({ theme }) => theme.colors.base.uiPrimary500};
    border-radius: ${({ theme }) => theme.borderRadius2};
    padding: 0 6px;
    gap: 6px;

    ${StyledBody2} {
        color: ${({ theme }) => theme.colors.base.uiPrimary300};
    }
`
