import { AvatarColor } from "."
import { StyledBox, StyledSpan } from "../../../styles/styledcomponents"
import { AvatarSize, UserStatus } from "../../../types"
import styled, { css, DefaultTheme } from "styled-components"

export interface StyledAvatarProps {
    status?: UserStatus
    size?: AvatarSize
    $color?: AvatarColor
}

const _getStatusColor = (status: UserStatus, theme: DefaultTheme) => {
    switch (status) {
        case UserStatus.online:
            return theme.headlandsPrimaryColor
        default:
            return theme.headlandsPrimaryColor
    }
}

const _getFontSizeFromAvatarSize = (avatarSize?: AvatarSize) => {
    switch (avatarSize) {
        case AvatarSize.XXXXL:
            return `54px`
        case AvatarSize.XXXL:
            return `36px`
        case AvatarSize.XXL:
            return `21px`
        case AvatarSize.XL:
            return `19px`
        case AvatarSize.L:
            return `18px`
        case AvatarSize.M:
        case AvatarSize.S:
            return `16px`
        case AvatarSize.XS:
        case AvatarSize.XXS:
            return `14px`
        case AvatarSize.XXXS:
            return `12px`
        default:
            return `16px`
    }
}

export const _getPixelsFromAvatarSize = (avatarSize?: AvatarSize) => {
    if (!avatarSize) return `${AvatarSize.M}px`
    return `${avatarSize}px`
}

export const StyledAvatar = styled.div<StyledAvatarProps>`
    position: relative;
    width: ${(props) => _getPixelsFromAvatarSize(props.size)};
    height: ${(props) => _getPixelsFromAvatarSize(props.size)};
    flex-shrink: 0;
    background-color: ${({ $color }) => $color?.background || "unset"};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: ${(props) => _getPixelsFromAvatarSize(props.size)};
        width: ${(props) => _getPixelsFromAvatarSize(props.size)};
        border-radius: 50%;
        object-fit: cover;
    }

    ${(props) =>
        props.status !== undefined &&
        css<StyledAvatarProps>`
            :after {
                content: " ";
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: ${(props) => _getStatusColor(UserStatus.online, props.theme)};
                border: 1px solid ${(props) => props.theme.headlandsWhite};
                bottom: 0;
                right: 0;
            }
        `}

    /* note: '&&&' is used because some component/screens also overrides the 'StyledSpan' */
    &&& ${StyledSpan} {
        font-size: ${({ size }) => _getFontSizeFromAvatarSize(size)};
        line-height: unset;
        font-weight: 400;
        color: ${({ $color }) => $color?.font || "unset"};
        text-transform: uppercase;
    }
`

export const StyledUserAvatarWithProgress = styled(StyledBox)<{ size: AvatarSize }>`
    width: ${({ size }) => size + 8}px;
    height: ${({ size }) => size + 8}px;

    ${StyledAvatar} {
        height: ${(props) => `calc(${_getPixelsFromAvatarSize(props.size)} + 6px)`};
        width: ${(props) => `calc(${_getPixelsFromAvatarSize(props.size)} + 6px)`};

        // for status
        // TODO: check if works as expected
        :after {
            bottom: -4px;
            right: -4px;
        }
    }
`
