import { Button } from "../../../components/Button/Button"
import { useLazyQuery, useMutation } from "@apollo/client"
import { MARK_THREAD_AS_PUBLISHED } from "../../../apollo/mutations"
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"
import { Block, GraphQLErrors } from "../../../types"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import {
    clearTimeouts,
    redoBlockVersion,
    undoBlockVersion,
    updateAutoSaveTimeout,
} from "../../../redux/blocks"
import { SaveToastIcon } from "../../../assets/icons/SaveToastIcon"
import { ErrorToastIcon } from "../../../assets/icons/ErrorToastIcon"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import { SaveIcon } from "../../../assets/icons/SaveIcon"
import { RedoIcon } from "../../../assets/icons/RedoIcon"
import { UndoIcon } from "../../../assets/icons/UndoIcon"
import { DuplicateIcon } from "../../../assets/icons/DuplicateIcon"
import { IconTopBar } from "./IconTopBar"
import AutosizeInput from "react-input-autosize"
import { useCurrentThreadField, useCurrentThreadGuid } from "../../../hooks/currentThread.hook"
import { setPreviewMode, updateThread } from "../../../redux/threads"
import { ConfettiIcon } from "../../../assets/icons/ConfettiIcon"
import {
    addThreadCreatorToSearchList,
    addThreadVersionToHistoryList,
} from "../../../apollo/cacheHelper"
import { parseBlocksString, preFetchImages } from "../../../thread/utils"
import _, { compact, debounce } from "lodash"
import { PREVIEW_THREAD } from "../../../apollo/queries"
import { DUPLICATE_THREAD } from "../../../apollo/mutations"
import { VariableWithValue } from "../../../apollo/generated/graphql"
import useSaveThread from "../../../hooks/saveThread.hook"
import { useToast } from "../../../hooks/useToast.hook"
import TopBar from "../../../components/TopBar"
import { useSidebars } from "../../../hooks/sidebars.hook"
import { StyledRow } from "../../../styles/styledcomponents"
import { StyledSavedLabel, StyledShareIcon, StyledThreadTitleContainer } from "./styles"
import ShareThreadModal from "../../../components/ShareThreadModal"
import { PlayArrowOutlined } from "@mui/icons-material"
import OutdatedThreadVersionModal from "../../../components/OutdatedThreadVersionModal"
import useThreadVersionHistory from "../../../hooks/threadVersionHistory.hook"
import { sessionDataVar } from "../../../apollo/cache-store"

interface TopbarProps {
    handlePreview: (
        previewMode: boolean,
        initialBlockId?: string,
        blocksToPreview?: {
            blocks?: Block[]
            previewBlocks?: Block[]
            synopsisBlocks?: Block[]
        },
        variablesToAdd?: VariableWithValue[]
    ) => void
}

//  todo: rename this component to something like `CreatorToolTopBar`
const Topbar = ({ handlePreview }: TopbarProps) => {
    const dispatch = useDispatch()
    const threadGuid = useCurrentThreadGuid()
    const threadId = useCurrentThreadField("id")
    const threadVersionID = useCurrentThreadField("threadVersionID")
    const threadLastUpdateTime = useCurrentThreadField("lastUpdateTime")
    const { showToast, showErrorToast } = useToast()
    const [saveErrorData, setSaveErrorData] = useState<any>()
    const autoSaveEditing = useSelector(
        (state: RootState) => state.blocksReducer.autoSaveTimeout.editing
    )
    const blockVersionIndex = useSelector(
        (state: RootState) => state.blocksReducer.blockVersionIndex
    )

    const blockVersionsLength = useSelector(
        (state: RootState) => state.blocksReducer.blockVersions.length
    )
    const [lastDateSaved, setLastDateSaved] = useState<Date>()

    const [shareModalOpen, setShareModalOpen] = useState(false)

    const history = useHistory()
    const { creatorThreadBarOpen } = useSidebars()

    const onSaveError = (error: any) => {
        dispatch(clearTimeouts())

        if (error.graphQLErrors[0]?.extensions?.code === GraphQLErrors.THREAD_VERSION_ERROR) {
            setSaveErrorData(error.graphQLErrors[0].extensions)
        } else {
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error occurred while saving" })
        }
    }

    const previewMode = useSelector((state: RootState) => state.threadsReducer.previewMode)

    const [saveCurrentThread, { loading: editLoading, error: editThreadError }] = useSaveThread()

    const [autoSaveCurrentThread, { loading: autoSaveLoading, error: autoSaveError }] =
        useSaveThread()

    const [duplicateThread, { loading: loadingDuplicate }] = useMutation(DUPLICATE_THREAD, {
        onCompleted: (data) => {
            showToast({ icon: <ConfettiIcon />, message: "Thread duplicated successfully" })
            history.push(`/creator/${data?.cloneThread.guid}`)
        },
        onError: () => {
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
        },
        update: (cache, { data }) => {
            data?.cloneThread && addThreadCreatorToSearchList(cache, data.cloneThread)
        },
    })

    const [markAsPublish, { loading: markAsPublishLoading }] = useMutation(
        MARK_THREAD_AS_PUBLISHED,
        {
            variables: {
                threadId: threadId!,
                threadVersionId: threadVersionID!,
            },
            onCompleted: (data) => {
                dispatch(
                    updateThread({
                        guid: threadGuid,
                        thread: {
                            threadVersionID: data.markThreadAsPublished.id,
                        },
                    })
                )
                setLastDateSaved(new Date())
            },
            update: (cache, { data }) => {
                if (!threadId) return
                const newVersionHistory = _.pick(data!.markThreadAsPublished, [
                    "id",
                    "time",
                    "published",
                    "editor.id",
                    "editor.firstName",
                    "editor.lastName",
                    "editor.profileImageLink",
                ])
                addThreadVersionToHistoryList(cache, newVersionHistory, threadGuid)
            },
        }
    )

    const [previewThread, { loading: previewLoading }] = useLazyQuery(PREVIEW_THREAD, {
        fetchPolicy: "network-only",
    })

    const { loading: threadVersionHistoryLoading, latestVersionIsPublished } =
        useThreadVersionHistory()

    /*
     * Add save keyword shortcut (cmd/ctrl+s)
     * */
    useEffect(() => {
        const handleSave = (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey) && event.key === "s") {
                // prevent save when there is no changes
                if (autoSaveEditing) onSave()
                event.preventDefault()
            }
        }
        document.addEventListener("keydown", handleSave)
        return () => document.removeEventListener("keydown", handleSave)
    }, [autoSaveEditing])

    /*
     * This effect handles the auto-save. When the editing prop changes to 'false' it triggers the save function
     * */
    useEffect(() => {
        if (autoSaveEditing === false && onSave) onSave(true)
    }, [autoSaveEditing])

    const getPreviewThreadAux = () => {
        threadGuid &&
            previewThread({ variables: { threadGUID: threadGuid } }).then((res) => {
                const parsedBlocks = parseBlocksString(res.data?.previewThreadByGuid.thread.blocks)
                const parsedPreviewBlocks = parseBlocksString(
                    res.data?.previewThreadByGuid.thread.preview
                )
                const parsedSynopsisBlocks = parseBlocksString(
                    res.data?.previewThreadByGuid.thread.synopsis
                )

                const sessionId = res?.data?.previewThreadByGuid?.telemetrySessionId
                if (sessionId) {
                    sessionDataVar({
                        id: sessionId,
                        timestamp: Date.now(),
                    })
                }

                preFetchImages(parsedBlocks)
                handlePreview(
                    !previewMode,
                    undefined,
                    {
                        blocks: parsedBlocks,
                        previewBlocks: parsedPreviewBlocks,
                        synopsisBlocks: parsedSynopsisBlocks,
                    },
                    compact(res.data?.previewThreadByGuid.variables)
                )
            })
    }

    const onPreviewClick = () => {
        if (!previewMode) {
            if (autoSaveEditing) {
                onSave(false)?.then(() => {
                    getPreviewThreadAux()
                })
            } else {
                getPreviewThreadAux()
            }
        } else {
            threadGuid && dispatch(setPreviewMode({ guid: threadGuid, previewMode: false }))
        }
    }

    const handleDuplicateThread = () => {
        if (threadId) {
            duplicateThread({
                variables: {
                    threadID: threadId,
                },
            }).catch((e) => console.error(e))
        }
    }

    const onSave = (wasAutoSaved = false) => {
        if (!threadId) {
            return
        }
        if (wasAutoSaved)
            return autoSaveCurrentThread({
                onCompleted: () => {
                    setLastDateSaved(new Date())
                },
                onError: onSaveError,
            })
        else
            return saveCurrentThread({
                onCompleted: () => {
                    setLastDateSaved(new Date())
                    dispatch(clearTimeouts())
                    dispatch(updateAutoSaveTimeout({ editing: undefined, timeoutID: undefined }))
                    showToast({ icon: <SaveToastIcon />, message: "Saved successfully" })
                },
                onError: onSaveError,
            })
    }

    const SaveLabel = () => {
        const getLabelText = () => {
            // when editing the thread the correct date to use is `lastDateSaved`
            const date = lastDateSaved || threadLastUpdateTime
            // wait for the thread version history to avoid showing the wrong label
            if (threadVersionHistoryLoading) return ""
            else if (editLoading || autoSaveLoading) return "Saving..."
            else if (editThreadError || autoSaveError) return "Thread couldn't be saved"
            else if (date) {
                const distance = formatDistanceToNowStrict(new Date(date), { addSuffix: true })
                if (latestVersionIsPublished) return `Published ${distance}`
                return `Draft saved ${distance}`
            }
            return ""
        }

        return (
            <StyledSavedLabel error={!!editThreadError || !!autoSaveError}>
                {getLabelText()}
            </StyledSavedLabel>
        )
    }

    // enable undo if:
    // - there is a block version index
    // - the previous block version index is greater or equal than 0
    // - the previous block version index is less than the length of the block versions
    const canUndo = useMemo(
        () =>
            blockVersionIndex !== undefined &&
            blockVersionIndex - 1 >= 0 &&
            blockVersionIndex - 1 < blockVersionsLength,
        [blockVersionIndex, blockVersionsLength]
    )
    // enable redo if:
    // - there is a block version index
    // - the next block version index is less than the length of the block versions
    const canRedo = useMemo(
        () => blockVersionIndex !== undefined && blockVersionIndex + 1 < blockVersionsLength,
        [blockVersionIndex, blockVersionsLength]
    )

    const canBeShared = !threadVersionHistoryLoading && latestVersionIsPublished

    if (!threadId) return null

    return (
        <TopBar
            extraLeftPadding={!creatorThreadBarOpen}
            styles={{ justifyContent: "space-between" }}
        >
            <ThreadTitleInput />
            <StyledRow css={{ alignItems: "center" }}>
                <SaveLabel />
                <IconTopBar
                    icon={<SaveIcon />}
                    tootltipMessage={"Save"}
                    onClick={() => onSave()}
                    loading={editLoading}
                    disabled={editLoading || autoSaveLoading || !autoSaveEditing}
                />
                <IconTopBar
                    icon={<UndoIcon />}
                    tootltipMessage={"Undo"}
                    onClick={() => dispatch(undoBlockVersion())}
                    disabled={!canUndo}
                />
                <IconTopBar
                    icon={<RedoIcon />}
                    tootltipMessage={"Redo"}
                    onClick={() => dispatch(redoBlockVersion())}
                    disabled={!canRedo}
                />
                <IconTopBar
                    icon={<DuplicateIcon />}
                    tootltipMessage={"Duplicate"}
                    onClick={() => handleDuplicateThread()}
                    loading={loadingDuplicate}
                />
                <IconTopBar
                    dataTestid={"preview-icon"}
                    icon={<PlayArrowOutlined sx={{ fontSize: "18px" }} />}
                    tootltipMessage={"Preview from start"}
                    onClick={onPreviewClick}
                    loading={previewLoading}
                    disabled={editLoading || autoSaveLoading || previewLoading}
                />
                <ShareThreadModal
                    disabled={!canBeShared}
                    on={"click"}
                    keepTooltipInside={".app"}
                    position={"bottom right"}
                    offsetY={10}
                    trigger={
                        <div>
                            <IconTopBar
                                icon={<StyledShareIcon />}
                                tootltipMessage={
                                    canBeShared
                                        ? "Share"
                                        : "You must publish the thread first in order to share it"
                                }
                                disabled={!canBeShared}
                            />
                        </div>
                    }
                />
                <Button
                    label={"Publish"}
                    disabled={threadVersionHistoryLoading || latestVersionIsPublished}
                    loading={markAsPublishLoading}
                    onClick={markAsPublish}
                />
            </StyledRow>
            {saveErrorData && (
                <OutdatedThreadVersionModal
                    profileImageURL={saveErrorData.profilePicURL}
                    firstName={saveErrorData.firstName}
                    lastName={saveErrorData.lastName}
                    createdAt={saveErrorData.createdAt}
                />
            )}
            <ShareThreadModal open={shareModalOpen} onClose={() => setShareModalOpen(false)} />
        </TopBar>
    )
}

const ThreadTitleInput = () => {
    const [title, setTitle] = useState("")

    const threadGuid = useCurrentThreadGuid()
    const threadTitle = useCurrentThreadField("title")

    const dispatch = useDispatch()

    useEffect(() => {
        if (threadTitle) setTitle(threadTitle)
    }, [threadTitle])

    const debouncedUpdateThreadTitle = useMemo(
        () =>
            debounce((text: string) => {
                if (text?.length > 0 && text !== threadTitle) {
                    dispatch(updateThread({ guid: threadGuid, thread: { title: text } }))
                    dispatch({ type: "thread/updateThread" })
                }
            }, 500),
        []
    )

    const handleTitleChange = (newTitle: string) => {
        setTitle(newTitle)
        debouncedUpdateThreadTitle(newTitle)
    }

    return (
        // 520px is about the whole width of the components on the right
        <StyledThreadTitleContainer rightContainerWidth={520}>
            <AutosizeInput
                value={title}
                placeholder="Thread title"
                placeholderIsMinWidth
                onChange={(event: { target: { value: string } }) =>
                    handleTitleChange(event.target.value)
                }
                style={{ maxWidth: "100%" }}
            />
        </StyledThreadTitleContainer>
    )
}

export default Topbar
