import {
    StyledClearIcon,
    StyledCloseIcon,
    StyledFilterListIcon,
    StyledPill,
    StyledSearchBar,
    StyledSearchIcon,
    StyledSearchInputContainer,
} from "./styles"
import { StyledSpan } from "../../../styles/styledcomponents"
import { isEmpty } from "lodash"
import PopupMenu, { PopupOptionType } from "../../../components/PopupMenu"
import { ChangeEvent, useEffect, useState } from "react"
import { CSSObject } from "styled-components"

export type Filter = {
    id: string
    label: string
}

type SearchBarBaseProps = {
    value?: string
    placeholder?: string
    onChange?: (newValue: string) => void
    styles?: CSSObject
}

interface SearchBarWithoutFiltersProps extends SearchBarBaseProps {
    availableFilters?: never
    onFilterChange?: never
}

interface SearchBarWithFiltersProps extends SearchBarBaseProps {
    availableFilters: Filter[]
    onFilterChange: (filters: Filter[]) => void
}

type SearchBarProps = SearchBarWithoutFiltersProps | SearchBarWithFiltersProps

const SearchBar = ({
    value,
    placeholder,
    onChange,
    availableFilters,
    onFilterChange,
    styles,
}: SearchBarProps) => {
    const [internalValue, setInternalValue] = useState("")
    const [selectedFilters, setSelectedFilters] = useState<Filter[]>([])

    useEffect(() => {
        value && setInternalValue(value)
    }, [value])

    useEffect(() => {
        onFilterChange && onFilterChange(selectedFilters)
        // there is no need to add `onFilterChange` as dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters])

    const toggleFilter = (filterToToggle: Filter) => {
        const alreadySelected = selectedFilters.find((filter) => filter.id === filterToToggle.id)
        if (alreadySelected) {
            setSelectedFilters(selectedFilters.filter((filter) => filter.id !== filterToToggle.id))
        } else {
            setSelectedFilters([...selectedFilters, filterToToggle])
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value
        onChange && onChange(text)
        setInternalValue(text)
    }

    const handleClear = () => {
        onChange && onChange("")
        setInternalValue("")
    }

    return (
        <StyledSearchBar style={styles}>
            <StyledSearchInputContainer>
                <StyledSearchIcon />
                {selectedFilters.map((filter) => (
                    <StyledPill>
                        <StyledSpan>{filter.label}</StyledSpan>
                        <StyledCloseIcon onClick={() => toggleFilter(filter)} />
                    </StyledPill>
                ))}
                <input placeholder={placeholder} onChange={handleChange} value={internalValue} />
                {internalValue && <StyledClearIcon onClick={handleClear} />}
            </StyledSearchInputContainer>
            {!isEmpty(availableFilters) && (
                <PopupMenu
                    arrow={false}
                    overlayStyle={{ backgroundColor: "transparent" }}
                    position={["top right"]}
                    closeOnOptionClick={false}
                    options={availableFilters!.map((filter) => ({
                        label: filter.label,
                        noDivider: true,
                        checkboxId: filter.id,
                        selected: selectedFilters.find(
                            (selectedFilter) => selectedFilter.id === filter.id
                        ),
                        onClick: () => toggleFilter(filter),
                        type: PopupOptionType.TEXT,
                    }))}
                    trigger={<StyledFilterListIcon />}
                />
            )}
        </StyledSearchBar>
    )
}

export default SearchBar
