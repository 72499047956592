import styled from "styled-components"
import { StyledH4Styles } from "../../../../styles/styledcomponents"

export const StyledCreateButton = styled.button`
    height: 30px;
    width: 30px;
    padding: 6px;
    border-radius: ${({ theme }) => theme.borderRadius1};
    background-color: ${({ theme }) => theme.colors.base.uiAi500};
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
        opacity: 0.5;
    }

    h4 {
        ${StyledH4Styles};
    }
`
