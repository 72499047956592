import { useEffect, useMemo, useRef, useState } from "react"
import { Block, BlockType, ChoiceQuestionOption, ConditionalBranch } from "../../types"
import { sessionDataVar } from "../../apollo/cache-store"
import {
    evaluateCondition,
    filterHiddenBlocks,
    filterSectionsBlocks,
    generateUUID,
    getVariableObject,
    getVariableValues,
} from "../../utils/utils"
import { useLazyQuery, useReactiveVar } from "@apollo/client"
import { questionTypes } from "../../utils/consts"
import { useCurrentThread } from "../../hooks/currentThread.hook"
import { useDispatch, useSelector } from "react-redux"
import {
    addThreadUserAnswer,
    addThreadVariable,
    clearThreads,
    updateThreadStatus,
} from "../../redux/threads"
import _, { sample } from "lodash"
import {
    setShowConfetti,
    setThreadDoneScrolling,
    setThreadFoundLastBubble,
} from "../../redux/layout"
import { useSelectedProgramGuid } from "../../hooks/enrolledProgram.hook"
import { blockHasAnswer, parseBlocksString } from "../../thread/utils"
import { Choice, ThreadStatus, VariableType } from "../../apollo/generated/graphql"
import { ENROLLED_PROGRAMS } from "../../apollo/queries"
import useTimeToAnswer from "../../hooks/timeToAnswer.hook"
import { RootState } from "../../redux/store"
import useTriviaMatchData, { MATCH_RESULT } from "../../hooks/triviaMatchData.hook"
import { updateEnrolledProgramThreadByPath } from "../../apollo/cacheHelper"

export const useThreadBlocks = (
    scrollToBottom: (behavior?: ScrollBehavior) => void,
    isPreview?: boolean
) => {
    const thread = useCurrentThread()
    const threadRef = useRef(thread)
    const selectedEnrolledProgramGuid = useSelectedProgramGuid()
    const sessionData = useReactiveVar(sessionDataVar)
    const dispatch = useDispatch()
    const initialBubbles = useMemo(() => {
        const parsedBlocks = parseBlocksString(thread?.thread?.blocks)
        // note: is important to do the hidden filtering before filtering the sections cause the
        // 'hidden' bool could be at the section level and should remove all it's children
        const nonHiddenBlocks = filterHiddenBlocks(parsedBlocks)
        const nonSectionsBlocks = filterSectionsBlocks(nonHiddenBlocks)
        return nonSectionsBlocks
    }, [])
    const threadBubbles = useRef<Block[]>(initialBubbles)
    const [bubbles, setBubbles] = useState<Block[]>([])
    const [bubbleIndex, setBubbleIndex] = useState<number>(0)
    const lastBubble = threadBubbles.current[bubbleIndex - 1]
    // If block is a question it should stop the scroll to wait for user input
    const shouldStopScroll =
        questionTypes.includes(lastBubble?.type) && !blockHasAnswer(lastBubble, thread.userAnswers)

    const foundLastBubble = useSelector(
        (state: RootState) => state.layoutReducer.threadFoundLastBubble
    )

    const { resetTimeToAnswer } = useTimeToAnswer()

    // Reset question timestamp data when unmounting. This could be when switching threads for example
    useEffect(() => {
        return () => {
            resetTimeToAnswer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /*
     * update thread ref with latest value. This is ref is used to always get the latest changes of the thread,
     * is useful when trying to use the thread data inside a timeout.
     * */
    useEffect(() => {
        threadRef.current = thread
    }, [thread])

    const { refetchThreadProgressForProgram } = useTriviaMatchData()

    // this query is used to update the enrolled program progress and status when completing a
    // thread (for the library bar)
    // note: there is no query for getting a particular enrolled program, so we have to fetch
    // them all.
    const [updateEnrolledPrograms] = useLazyQuery(ENROLLED_PROGRAMS, {
        fetchPolicy: "network-only",
    })

    const { matchResult } = useTriviaMatchData()

    useEffect(() => {
        return () => {
            if (!isPreview) {
                /*
                 * When exiting the thread preview we:
                 * 1. clear the session data to avoid an old bug
                 * 2. clear the threads to avoid showing the 'switch device' error modal
                 * */
                sessionDataVar(undefined)
                dispatch(clearThreads())
            }
        }
    }, [isPreview, dispatch])

    const addTypingIndicator = () => {
        const typingIndicatorBlock = {
            id: generateUUID(),
            type: BlockType.TYPING_INDICATOR,
        }
        setBubbles(bubbles.concat(typingIndicatorBlock))
        return new Promise((resolve) =>
            setTimeout(() => {
                setBubbles(bubbles.filter((bubble) => bubble.id !== typingIndicatorBlock.id))
                resolve(true)
            }, sample([1000, 1500, 2000]))
        )
    }

    const handleAnswer = async (
        options: Choice[] | ChoiceQuestionOption[],
        showTypingIndicator?: boolean
    ) => {
        const block = threadBubbles.current[bubbleIndex - 1]
        const nextBlock = threadBubbles.current[bubbleIndex]

        // We check for the `groupId` field for the case of built-in pause components
        const answeredBlockId = block.groupId || block.id

        const UserAnswer = {
            name: block.save_to_variable || "",
            object: answeredBlockId,
            type: getVariableObject(
                block.type,
                options,
                undefined,
                block?.properties?.may_select_multiple
            ).type,
            // TODO: We're having problems because of the distinction of UserAnswer and VariableWithValue
            value: getVariableObject(
                block.type,
                options,
                undefined,
                block?.properties?.may_select_multiple
            ).value,
        }

        dispatch(
            addThreadUserAnswer({
                guid: thread.thread?.guid!,
                userAnswer: UserAnswer,
            })
        )

        // because the threadRef is being updating late, we manually update it here
        threadRef.current = {
            ...threadRef.current,
            userAnswers: [...(threadRef.current.userAnswers || []), UserAnswer],
        }

        // Reset question timestamp data after answering
        resetTimeToAnswer()

        if (showTypingIndicator) await addTypingIndicator()

        if (!isPreview && sessionData?.id) {
            // update UI blocks
            updateUIBlocks()
            // If the user has answered the last question and there is no next block
            // add the synopsis block
            if (!nextBlock) {
                onThreadCompleted()
                addSynopsisBlock({ showConfetti: true })
            }
        } else {
            if (isPreview && !nextBlock) {
                addSynopsisBlock({ showConfetti: true })
            } else {
                updateUIBlocks()
            }
        }
    }

    // TODO: check if there is a better way to do this
    const onThreadCompleted = () => {
        /*
         * - manually update the thread on redux to show the completed screen
         * - query for the threads of the program (this is useful for the thread status and for progressive programs)
         * */

        // to update the UI
        // here we manually set the thread status to COMPLETED
        dispatch(
            updateThreadStatus({
                guid: thread.thread?.guid!,
                status: ThreadStatus.Completed,
            })
        )

        // update the status on the cache so the LibraryBarItem is updated when finishing the thread
        selectedEnrolledProgramGuid &&
            thread.thread?.guid &&
            updateEnrolledProgramThreadByPath(
                selectedEnrolledProgramGuid,
                thread.thread?.guid,
                "status",
                ThreadStatus.Completed
            )

        if (selectedEnrolledProgramGuid) {
            void refetchThreadProgressForProgram()
            void updateEnrolledPrograms()
        }
    }

    /*
     * This function do the 'processing' on a block, this mean handle special blocks like (groups or includes).
     * It returns the bubbles to add to the ref and also returns the bubbles to be rendered
     * */
    const processBlock = (block: Block): { bubblesToRender: Block[]; bubblesRefToAdd: Block[] } => {
        let bubblesToRender = [block]
        let bubblesRefToAdd = [block]

        // special block types
        switch (block.type) {
            case BlockType.GROUP:
                if (!block.branches || !block.branches[0].objects) break
                bubblesRefToAdd = [
                    block,
                    ..._.compact(
                        _.flatten(
                            block.branches[0].objects.map((innerBlock) => ({
                                ...processBlock(innerBlock).bubblesRefToAdd[0],
                                isInsideGroup: true,
                            }))
                        )
                    ),
                ]
                const hasInteractiveChild = bubblesRefToAdd.some((bubble) =>
                    questionTypes.includes(bubble.type)
                )
                if (!hasInteractiveChild) {
                    bubblesRefToAdd.push({
                        id: generateUUID(),
                        type: BlockType.PAUSE,
                        value: block.pause_label,
                        // Since this is a built-in pause, we associate it to it's parent group
                        groupId: block.id,
                    })
                }
                bubblesToRender = bubblesRefToAdd
                break
            case BlockType.INCLUDE:
                bubblesRefToAdd = block.objects || []
                bubblesToRender = []
                break
            case BlockType.SET_STRING_VARIABLE:
                dispatch(
                    addThreadVariable({
                        guid: threadRef.current.thread?.guid!,
                        variable: {
                            __typename: "VariableWithValue",
                            name: block.save_to_variable || "",
                            type: VariableType.String,
                            value: {
                                string: block.value,
                            },
                        },
                    })
                )
                bubblesRefToAdd = []
                bubblesToRender = []
                break
            case BlockType.CONDITIONAL:
                const variablesValues = getVariableValues(threadRef.current)
                const result = block.branches!.find((branch: ConditionalBranch) =>
                    branch.test ? evaluateCondition(branch.test, variablesValues, block.id) : false
                )
                bubblesRefToAdd = result?.objects ?? []
                bubblesToRender = []
                break
            case BlockType.CONFETTI:
                dispatch(setShowConfetti(true))
                bubblesRefToAdd = []
                bubblesToRender = []
                break
        }
        return { bubblesToRender, bubblesRefToAdd }
    }

    const updateUIBlocks = () => {
        const newBubble = threadBubbles.current[bubbleIndex]

        if (newBubble) {
            // here we process the bubble this give us back the bubbles to add to the ref, and to the state
            const { bubblesToRender, bubblesRefToAdd } = processBlock(newBubble)

            // only if the bubble is of type group, include, conditional or question choice we update the ref (on other types the
            // ref would be already updated)
            if (
                newBubble.type === BlockType.GROUP ||
                newBubble.type === BlockType.INCLUDE ||
                newBubble.type === BlockType.CONDITIONAL
            ) {
                threadBubbles.current = [
                    ...threadBubbles.current.slice(0, bubbleIndex + 1),
                    ...bubblesRefToAdd,
                    ...threadBubbles.current.slice(bubbleIndex + 1),
                ]
            }

            setBubbles([...bubbles, ...bubblesToRender])
            // Only Group bubbles could have bubblesToRender greater than 1, that's why we add the bubblesToRender
            // length to the index
            setBubbleIndex(
                bubbleIndex + 1 + (newBubble.type === BlockType.GROUP ? bubblesToRender.length : 0)
            )

            /*
             * Here we calculate if the last bubble was found or not.
             * Notes:
             * 1. The last render bubble could be inside a group
             * 2. If the thread is a completed one, we avoid setting `foundLastBubble`, this will be done when adding the end pane
             */
            const currentBubbleWasLastRendered = bubblesToRender?.some(
                (bubble) => bubble.id === threadRef.current.currentObjectID
            )
            if (currentBubbleWasLastRendered && thread.status !== ThreadStatus.Completed) {
                dispatch(setThreadFoundLastBubble(true))
            }
        }
        // Images and typing indicator scroll themselves into view
        if (
            newBubble?.type !== BlockType.IMAGE &&
            newBubble?.type !== BlockType.TYPING_INDICATOR &&
            (!threadRef.current.currentObjectID || foundLastBubble)
        )
            scrollToBottom()
    }

    useEffect(() => {
        foundLastBubble && scrollToBottom("auto")
    }, [foundLastBubble])

    /*
     * This is where thread playback starts therefore, we want to make sure that
     * timings and session data are ready before starting
     */
    useEffect(() => {
        // Now preview mode should also have an ephemeral session id that is why we don't need to check for `isPreview` anymore
        if (
            (sessionData?.id || thread?.status === ThreadStatus.Completed) &&
            bubbles.length === 0
        ) {
            updateUIBlocks()
        }
    }, [sessionData])

    const addSynopsisBlock = (options?: { showConfetti?: boolean }) => {
        const addBlock = () => {
            setBubbles([...bubbles, { id: generateUUID(), type: BlockType.SYNOPSIS }])
            scrollToBottom()
            dispatch(setThreadFoundLastBubble(true))
        }
        if (options?.showConfetti) {
            setTimeout(() => {
                addBlock()
                /*
                 * If the thread is a trivia, and the user lost,
                 * we don't show the confetti, no matter the value of `showConfetti`.
                 */
                matchResult !== MATCH_RESULT.USER_LOST && dispatch(setShowConfetti(true))
            }, 2000)
        } else {
            addBlock()
        }
    }

    useEffect(() => {
        const nextBubble = threadBubbles.current[bubbleIndex]

        /*
         * TODO:
         * Check if there is a way to end a thread with a non-interactive block, we think that
         * right now that couldn't be possible. Check if `skip_pause` boolean on group block is still being used.
         * If not please remove the following code that handles thread finish because this will be done on the `handleAnswer` instead.
         */
        // Finished thread
        if (!nextBubble && !shouldStopScroll) {
            if (!isPreview && sessionData?.id && thread.status !== ThreadStatus.Completed) {
                onThreadCompleted()
                // Add the recap pane bubble with delay and confetti
                addSynopsisBlock({ showConfetti: true })
            } else {
                // Add the recap pane bubble with delay and confetti only if we're in preview mode
                addSynopsisBlock({ showConfetti: isPreview })
            }
        }

        // if the thread didn't finish
        if (nextBubble && lastBubble && bubbleIndex < threadBubbles.current.length) {
            if (!shouldStopScroll) updateUIBlocks()
            // if there is a question without an answer we force the set of the found last bubble and done scrolling
            else if (!foundLastBubble) {
                dispatch(setThreadFoundLastBubble(true))
                dispatch(setThreadDoneScrolling(true))
            }
        }
    }, [bubbleIndex])

    return {
        bubbles,
        handleAnswer,
    }
}
