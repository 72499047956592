import { useTheme } from "styled-components"
import { IconProps } from "../../types"

export const SaveIcon = ({ width = 18, height = 18, color }: IconProps) => {
    const theme = useTheme()

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.3333 2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V4.66667L11.3333 2ZM8 12.6667C6.89333 12.6667 6 11.7733 6 10.6667C6 9.56 6.89333 8.66667 8 8.66667C9.10667 8.66667 10 9.56 10 10.6667C10 11.7733 9.10667 12.6667 8 12.6667ZM10 6H3.33333V3.33333H10V6Z"
                fill={color ?? theme.colors.base.uiBgIcon}
            />
        </svg>
    )
}
