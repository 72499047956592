import { DeliverMessage, DeliverProgramContent } from "../types"
import { removeReactSortableFieldsFromObject } from "../utils/utils"
import { isEqual } from "lodash"

/*
 * This function removes all the fields added by 'react-sortablejs' from a DeliverProgramContent[]
 * */
export const removeReactSortableFieldsFromContent = (
    data: DeliverProgramContent[]
): DeliverProgramContent[] => {
    return data.map((element) => {
        const omittedElement = removeReactSortableFieldsFromObject(element)
        if ("messages" in omittedElement) {
            return {
                ...omittedElement,
                messages: removeReactSortableFieldsFromContent(omittedElement.messages || []),
            } as DeliverProgramContent
        } else {
            return omittedElement as DeliverMessage
        }
    }) as DeliverProgramContent[]
}

/*
 * This function check if the content had changed ignoring the fields that the react-sortablejs library adds to
 * the content
 * */
export const areContentsDifferent = (
    newContent: DeliverProgramContent[],
    previousContent: DeliverProgramContent[]
): boolean => {
    const newContentFiltered = removeReactSortableFieldsFromContent(newContent)
    const previousContentFiltered = removeReactSortableFieldsFromContent(previousContent)
    const areEquals = isEqual(newContentFiltered, previousContentFiltered)
    return !areEquals
}
