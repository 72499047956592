import { ChangeEvent, ClipboardEvent, KeyboardEvent } from "react"
import { compact, times, toInteger } from "lodash"
import {
    StyledArrowBackIcon,
    StyledButtonText,
    StyledCodeInputsContainer,
    StyledText,
} from "./styles"
import Input from "../components/InputNew"
import { Loader } from "../components/Loader"
import { StyledColumn } from "../styles/styledcomponents"
import { BaseContentProps, CODE_DIGITS_LENGTH, NEW_CODE_MUST_BE_REQUESTED_ERROR } from "./index"
import useLayout from "../hooks/layout.hook"
import { ApolloError } from "@apollo/client"

interface CodeContentProps extends Omit<BaseContentProps, "handleChange"> {
    email: string
    code: string[]
    setFieldValue: (field: string, value: string | string[]) => void
    changeEmail: () => void
    resendCode: () => void
    codeResent?: boolean
    resetLoginStatus: () => void
    resetResendCode: () => void
    loading?: boolean
    error?: ApolloError
}

const CodeContent = ({
    handleSubmit,
    email,
    code,
    setFieldValue,
    changeEmail,
    resendCode,
    codeResent,
    resetLoginStatus,
    resetResendCode,
    loading,
    error,
}: CodeContentProps) => {
    const { isMobile } = useLayout()

    /*
     * This handler is used to change each code input. It:
     *   1. filters the non-numbers chars
     *   2. slice the value to only one digit
     *   3. triggers the submit if the last input and the code is complete
     *   4. moves the focus forward if is not the last input
     *   5. moves the focus backward if the user is clearing the input and is not the first input
     *   6. clears the error if trying entered the code again
     * */
    const onCodeDigitChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputName = event.target.name
        const inputValue = event.target.value
        const inputCurrentIndex = toInteger(inputName.split(".")?.slice(-1))
        const onlyNumbers = new RegExp("[0-9]")
        // clear error if trying to enter the code again
        if (error && inputCurrentIndex === 0) resetLoginStatus()
        // clear the re-sended message if trying yo enter the code again
        if (codeResent && inputCurrentIndex === 0) resetResendCode()
        if (onlyNumbers.test(inputValue)) {
            // we set the last chart only, this way we can replace the previous digit if there is one
            const lastDigit = event.target.value.slice(-1)
            setFieldValue(inputName, lastDigit)

            const isTheLastInput = inputCurrentIndex === CODE_DIGITS_LENGTH - 1
            // the last input entered probably is not set up yet, so we check if the code has at
            // least a length of CODE_DIGITS_LENGTH - 1
            const codeIsComplete = compact(code).length >= CODE_DIGITS_LENGTH - 1

            // move focus forward if necessary
            if (!isTheLastInput) {
                document.getElementById(`code.${inputCurrentIndex + 1}`)?.focus()
            }
            // submit code if necessary
            else if (codeIsComplete) {
                handleSubmit()
            }
        }
        // when user wants to clear the input
        if (inputValue === "") {
            setFieldValue(inputName, "")
            /*
             * Move focus backward if necessary. the `handleKeyDownOnCode` handles the logic for
             * moving the focus if the input is already empty because it won't enter on this function
             * */
            if (inputCurrentIndex > 0) {
                document.getElementById(`code.${inputCurrentIndex - 1}`)?.focus()
            }
        }
    }

    /*
     * This handler is used to paste the code on the inputs. It:
     *   1. remove the spaces
     *   2. remove the non-number chars
     *   3. slices the first `CODE_DIGITS_LENGTH` digits
     *   4. paste the valid digit on the code input
     *   5. triggers the submit if the code is complete
     * */
    const handlePasteOnCode = (event: ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault()
        const plainString = event.clipboardData?.getData("text/plain")
        const withoutSpaces = plainString.replace(/\s/g, "")
        const onlyNumbers = withoutSpaces.replace(/\D/g, "")
        const codeLengthSliced = onlyNumbers.slice(0, CODE_DIGITS_LENGTH)
        // create an array of empty strings
        let code = Array(CODE_DIGITS_LENGTH).fill("")
        // update the code string array with the code pasted
        codeLengthSliced.split("").map((digit, index) => (code[index] = digit))
        setFieldValue("code", code)
        // submit code id the code length is correct
        if (compact(code).length === CODE_DIGITS_LENGTH) handleSubmit()
        // otherwise focus the first empty input
        else {
            const firstEmptyInputIndex = compact(code).length
            document.getElementById(`code.${firstEmptyInputIndex}`)?.focus()
        }
    }

    /*
     * This handler is used to move the focus backwards when input is empty, we need this because
     * onChange event is not trigger if the input is already empty.
     * */
    const handleKeyDownOnCode = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === "Backspace") {
            const inputValue = (event.target as HTMLInputElement).value
            if (inputValue === "") {
                const inputName = (event.target as HTMLInputElement).name
                const inputCurrentIndex = toInteger(inputName.split(".")?.slice(-1))
                if (inputCurrentIndex > 0) {
                    event.preventDefault()
                    document.getElementById(`code.${inputCurrentIndex - 1}`)?.focus()
                }
            }
        }
    }

    return (
        <>
            {isMobile && <StyledArrowBackIcon onClick={changeEmail} />}
            <StyledText css={{ marginBottom: "3px" }}>
                We sent a temporary sign-in code to:
            </StyledText>
            <StyledText css={{ marginBottom: "12px" }}>
                <strong>{email}</strong>
            </StyledText>
            <StyledText>Please paste (or type) your 6-digit code:</StyledText>
            <form onSubmit={handleSubmit} autoComplete={"off"}>
                <StyledCodeInputsContainer>
                    {times(CODE_DIGITS_LENGTH).map((index) => (
                        <Input
                            id={`code.${index}`}
                            key={`code-input-${index}`}
                            name={`code.${index}`}
                            value={code[index]}
                            onChange={onCodeDigitChange}
                            disabled={loading}
                            error={!!error && !loading}
                            autoFocus={index === 0}
                            onPaste={handlePasteOnCode}
                            onKeyDown={handleKeyDownOnCode}
                            pattern={"[0-9]*"}
                        />
                    ))}
                </StyledCodeInputsContainer>
            </form>
            {loading && <Loader size={20} css={{ marginTop: "8px" }} />}
            {!loading && error && (
                <StyledColumn>
                    <StyledText error>
                        <strong>Look’s like that code is incorrect</strong>
                    </StyledText>
                    {error.message === NEW_CODE_MUST_BE_REQUESTED_ERROR ? (
                        <StyledText error>
                            Please check your inbox, we just sent you a new one.
                        </StyledText>
                    ) : (
                        <StyledText error>Please try again</StyledText>
                    )}
                </StyledColumn>
            )}
            {!error && !loading && (
                <StyledColumn>
                    <StyledText disabled>Didn’t get a code?</StyledText>
                    <StyledButtonText onClick={resendCode}>Resend</StyledButtonText>
                    {codeResent && (
                        <StyledText>
                            Please check your inbox, we just sent you a new one.
                        </StyledText>
                    )}
                </StyledColumn>
            )}
            {!loading && !isMobile && (
                <StyledColumn css={{ marginTop: "auto" }}>
                    <StyledText css={{ marginTop: "24px" }} disabled>
                        Wrong email?
                    </StyledText>
                    <StyledButtonText onClick={changeEmail}>Change it</StyledButtonText>
                </StyledColumn>
            )}
        </>
    )
}

export default CodeContent
