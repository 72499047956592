import "./Switch.scss"
import classNames from "classnames"
import { OptionPreviewMode, OptionWithIcon } from "../../../types"
import { CSSObject } from "styled-components"

export interface SwitchProps {
    enabled: boolean
    onChange?: (newValue?: boolean) => void
    onChangeWithIcon?: (option?: OptionPreviewMode) => void
    options?: string[] | OptionWithIcon[]
    disabled?: boolean
    style?: CSSObject
}

const Switch = ({
    enabled,
    disabled,
    onChange,
    onChangeWithIcon,
    options,
    ...props
}: SwitchProps) => (
    <div className={"switch-container"} {...props}>
        {options?.length && (
            <Option
                text={options[0]}
                onClick={() =>
                    onChange
                        ? onChange(false)
                        : onChangeWithIcon &&
                          typeof options[0] !== "string" &&
                          onChangeWithIcon(options[0].label)
                }
            />
        )}
        <div
            className={classNames("switch", { enabled, disabled })}
            onClick={
                !disabled
                    ? onChange
                        ? () => onChange()
                        : onChangeWithIcon
                        ? () => onChangeWithIcon()
                        : undefined
                    : undefined
            }
        >
            <div className={classNames("circle", { off: !enabled, on: enabled })} />
        </div>
        {options?.length && (
            <Option
                text={options[1]}
                onClick={() =>
                    onChange
                        ? onChange(true)
                        : onChangeWithIcon &&
                          typeof options[1] !== "string" &&
                          onChangeWithIcon(options[1].label)
                }
            />
        )}
    </div>
)

interface OptionProps {
    text: string | OptionWithIcon
    onClick: () => void
}

const Option = ({ text, onClick }: OptionProps) => (
    <div className={"option"} onClick={onClick}>
        {typeof text === "string" ? <span>{text}</span> : text.icon}
    </div>
)

export default Switch
