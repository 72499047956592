import { useEffect, useCallback } from "react"

const useOnClickOutside = (ref: any, handler: any) => {
    const listener = useCallback(
        (event: any) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return
            }
            handler(event)
        },
        [ref, handler]
    )
    useEffect(() => {
        document.addEventListener("click", listener, true)
        document.addEventListener("touchstart", listener, true)
        return () => {
            document.removeEventListener("click", listener, true)
            document.removeEventListener("touchstart", listener, true)
        }
    }, [ref, handler])
}

export default useOnClickOutside
