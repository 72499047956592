import styled, { css } from "styled-components"
import CheckIcon from "@mui/icons-material/Check"
import { StyledBox, StyledHr } from "../../../styles/styledcomponents"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import CachedIcon from "@mui/icons-material/Cached"

interface StyledThreadRowProps {
    active?: boolean
    fullWidth?: boolean
}

export const StyledMoreVerticalIcon = styled(MoreVertIcon)`
    opacity: 0;

    &:hover {
        cursor: pointer;
    }
`

export const StyledThreadRowMiddleContainer = styled.div<{ maxWidth: number }>`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 6px;
    max-width: ${({ maxWidth }) => maxWidth}px;
`

export const StyledTitleContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    h4 {
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.headlandsBlack};
    }
`

export const StyledThreadRow = styled.div<StyledThreadRowProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius1};
    width: inherit;
    background-color: ${({ theme, active }) =>
        active ? theme.colors.base.uiBgSecondary : "unset"};

    ${({ fullWidth }) =>
        !fullWidth &&
        css`
            min-width: 252px;
            max-width: 252px;
        `}

    margin: 0;
    padding: 12px 6px;

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.headlandsGray6};

        ${StyledMoreVerticalIcon} {
            opacity: 1;
        }
    }
`

export const StyledThreadRowHr = styled(StyledHr)`
    height: unset;
    margin: 0 6px;
    color: ${({ theme }) => theme.headlandsGray3};
`

export const StyledPublishIcon = styled(CheckIcon)`
    background-color: ${({ theme }) => theme.headlandsPrimaryColor};
    border-radius: 50%;
    font-size: 8px !important;
    padding: 2px;

    path {
        fill: ${({ theme }) => theme.headlandsWhite};
    }
`

export const StyledIconContainer = styled(StyledBox)<{ showResetThread?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: ${({ showResetThread }) => (showResetThread ? "space-between" : "center")};
    align-items: center;
    height: 46px;

    *:hover {
        cursor: default;
    }
`

export const StyledCacheIcon = styled(CachedIcon)`
    color: ${({ theme }) => theme.headlandsGray3};

    &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.headlandsGray1};
    }
`
