import { IconProps } from "../../types"

export const MobileIcon = ({ color }: IconProps) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.3334 0.666626H5.00004C4.08004 0.666626 3.33337 1.41329 3.33337 2.33329V13.6666C3.33337 14.5866 4.08004 15.3333 5.00004 15.3333H10.3334C11.2534 15.3333 12 14.5866 12 13.6666V2.33329C12 1.41329 11.2534 0.666626 10.3334 0.666626ZM7.66671 14.6666C7.11337 14.6666 6.66671 14.22 6.66671 13.6666C6.66671 13.1133 7.11337 12.6666 7.66671 12.6666C8.22004 12.6666 8.66671 13.1133 8.66671 13.6666C8.66671 14.22 8.22004 14.6666 7.66671 14.6666ZM10.6667 12H4.66671V2.66663H10.6667V12Z"
                fill={color}
            />
        </svg>
    )
}
