import { StyledBox, StyledSpan } from "../../styles/styledcomponents"
import {
    LeaderboardContainer,
    StyledCheckIcon,
    StyledLeaderboardContentCell,
    StyledLeaderboardContentRow,
    StyledLeaderboardRow,
    StyledMedal,
    StyledPlayIcon,
    StyledThreadTitleCell,
} from "./styles"
import { UserAvatar } from "../../creator/components/UserAvatar"
import {
    AvatarSize,
    EnrolledProgramParticipant,
    EnrolledProgramParticipantPerson,
} from "../../types"
import EllipsisText from "../EllipsisText"
import { useTheme } from "styled-components"
import { ThreadStatus } from "../../apollo/generated/graphql"
import { orderBy, pick } from "lodash"

type LeaderboardCell = {
    threadGuid: string
    status: ThreadStatus
}

interface LeaderboardRowProps {
    person: EnrolledProgramParticipantPerson
    cells: LeaderboardCell[]
    index: number
    currentUserId?: number
    mobile?: boolean
}

const LeaderboardRow = ({ person, cells, index, currentUserId, mobile }: LeaderboardRowProps) => {
    const getIconByStatus = (status: ThreadStatus) => {
        switch (status) {
            case ThreadStatus.Completed:
                return <StyledCheckIcon />
            case ThreadStatus.InProgress:
                if (currentUserId === person.id) return <StyledPlayIcon />
                return null
            default:
                return null
        }
    }

    const renderMedal = () => {
        switch (index) {
            case 0: {
                return <Medal place={1} />
            }
            case 1: {
                return <Medal place={2} />
            }
            case 2: {
                return <Medal place={3} />
            }
            default: {
                return null
            }
        }
    }

    return (
        <StyledLeaderboardRow mobile={mobile}>
            <StyledBox css={{ display: "flex", alignItems: "center" }}>
                <UserAvatar
                    size={mobile ? AvatarSize.XXS : AvatarSize.M}
                    user={{ ...pick(person, ["profileImageURL", "firstName", "lastName"]) }}
                />
                <StyledBox css={{ display: "flex", flexDirection: "column", marginLeft: "6px" }}>
                    <StyledBox css={{ display: "flex", alignItems: "center", gap: "6px" }}>
                        <EllipsisText
                            variant={"h4"}
                            text={`${person.firstName || ""} ${person.lastName || ""}`}
                            textStyles={{
                                fontSize: "14px",
                                lineHeight: "18px",
                            }}
                            maxWidth={200}
                            popupOptions={{ position: "right center" }}
                        />
                    </StyledBox>
                    {!mobile && (
                        <EllipsisText
                            variant={"span"}
                            text={person.role || ""}
                            textStyles={{
                                fontSize: "12px",
                                lineHeight: "18px",
                            }}
                            maxWidth={200}
                            popupOptions={{ position: "right center" }}
                        />
                    )}
                </StyledBox>
            </StyledBox>
            <StyledBox>
                <StyledLeaderboardContentRow quantity={cells.length} mobile={mobile}>
                    {cells.map((cell, index) => (
                        <StyledLeaderboardContentCell key={index} status={cell.status}>
                            {getIconByStatus(cell.status)}
                        </StyledLeaderboardContentCell>
                    ))}
                </StyledLeaderboardContentRow>
            </StyledBox>
        </StyledLeaderboardRow>
    )
}

interface ThreadTitlesRowProps {
    threadTitles: string[]
    mobile?: boolean
}

const ThreadTitlesRow = ({ threadTitles, mobile }: ThreadTitlesRowProps) => {
    const theme = useTheme()
    return (
        <StyledLeaderboardRow>
            <StyledBox id={"empty-user-content"} />
            <StyledBox>
                <StyledLeaderboardContentRow quantity={threadTitles.length} mobile={mobile}>
                    {threadTitles.map((title, index) => (
                        <StyledThreadTitleCell key={`thread-title-cell-${index}`}>
                            <EllipsisText
                                variant={"span"}
                                text={title}
                                textStyles={{
                                    fontSize: "10px",
                                    lineHeight: "12px",
                                    fontWeight: 600,
                                    color: theme.headlandsGray1,
                                }}
                                maxLines={2}
                                maxHeight={24}
                                popupOptions={{ position: "bottom center" }}
                            />
                        </StyledThreadTitleCell>
                    ))}
                </StyledLeaderboardContentRow>
            </StyledBox>
        </StyledLeaderboardRow>
    )
}

const Medal = ({ place }: { place: 1 | 2 | 3 }) => {
    return (
        <StyledMedal place={place}>
            <StyledSpan>{place}</StyledSpan>
        </StyledMedal>
    )
}

export interface LeaderboardProps {
    threads: { guid: string; title: string }[]
    usersData: EnrolledProgramParticipant[]
    currentUserId?: number
    mobile?: boolean
}

const Leaderboard = ({ threads, usersData, currentUserId, mobile }: LeaderboardProps) => {
    /*
     * This method calculate the leaderboard cells for each user.
     * */
    const calculateCells = (userData: EnrolledProgramParticipant): LeaderboardCell[] =>
        threads.map((thread) => ({
            threadGuid: thread.guid,
            status:
                userData.progress?.threadProgress?.find(
                    (threadProgress) => threadProgress.guid === thread.guid
                )?.status || ThreadStatus.Locked,
        }))

    return (
        <LeaderboardContainer mobile={mobile}>
            {!mobile && (
                <ThreadTitlesRow
                    threadTitles={threads.map((thread) => thread.title)}
                    mobile={mobile}
                />
            )}
            {orderBy(usersData, "progress.total", "desc").map((userData, index) => (
                <LeaderboardRow
                    person={userData.person}
                    cells={calculateCells(userData)}
                    key={`user-leaderboard-row-${index}`}
                    index={index}
                    currentUserId={currentUserId}
                    mobile={mobile}
                />
            ))}
        </LeaderboardContainer>
    )
}

export default Leaderboard
