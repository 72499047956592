import styled, { css } from "styled-components"
import { StyledBox } from "../styles/styledcomponents"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

export const StyledProgramScreen = styled.div<{ isMobile?: boolean }>`
    height: 100%;
    display: flex;
    flex: 1;
    position: relative;

    ${(props) =>
        props.isMobile &&
        css`
            > div[role="navigation"] {
                z-index: 999;
            }
        `}
    .sidebar-overlay {
        background-color: rgba(0, 0, 0, 0.1) !important;
        z-index: 6 !important;
    }

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        .sidebar-overlay {
            background-color: ${(props) => props.theme.backgroundDark};
            -webkit-backdrop-filter: ${(props) => props.theme.blur1};
            backdrop-filter: ${(props) => props.theme.blur1};
        }
    }
`

export const StyledHomeMobileScreen = styled(StyledBox)`
    display: flex;
    flex: 1;
`

export const StyledArrowBackIcon = styled(ArrowBackIcon)`
    position: fixed;
    z-index: 10;
    top: ${({ theme }) => `calc(${theme.variables.common.topBarHeight} * 0.5 - 12px)`};
    left: 18px;
    color: ${({ theme }) => theme.headlandsGray10};
    justify-self: flex-start;

    &:hover {
        cursor: pointer;
    }
`
