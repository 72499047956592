import { useMutation } from "@apollo/client"
import { CREATE_LINK } from "../apollo/mutations"
import { useCurrentThreadId } from "./currentThread.hook"
import { useCallback } from "react"
import { useSelectedEnrolledProgramAttribute } from "./enrolledProgram.hook"
import { Maybe } from "graphql/jsutils/Maybe"
interface useShareThreadProps {
    threadID?: Maybe<number>
    programID?: Maybe<number>
}

export const useShareThread = (props?: useShareThreadProps) => {
    const currentThreadId = useCurrentThreadId()
    const { attribute: currentProgramId } = useSelectedEnrolledProgramAttribute("id")
    const threadID = props?.threadID ?? currentThreadId
    const programID = props?.programID ?? currentProgramId

    const [createLink, { data: linkData, loading: linkLoading }] = useMutation(CREATE_LINK, {
        fetchPolicy: "network-only",
    })
    const generateLink = useCallback(
        () => createLink({ variables: { threadID, programID } }),
        [threadID, programID]
    )

    return { linkData, linkLoading, generateLink }
}
