import styled, { CSSObject, css } from "styled-components"
import { TooltipPosition } from "./index"

export const StyledHoverOverlayOptions = styled.div`
    display: flex;
    position: relative;
`

export const StyledMenusContainer = styled.div<{
    position: TooltipPosition
    styles?: CSSObject
}>`
    position: absolute;
    z-index: 1;
    display: flex;
    border-radius: ${(props) => `${props.theme.borderRadius1}`};
    padding: 4px;
    color: ${(props) => `${props.theme.headlandsWhite}`};
    top: ${(props) =>
        props.position === "top-left" || props.position === "top-right" ? "6px" : "unset"};
    bottom: ${(props) =>
        props.position === "bottom-left" || props.position === "bottom-right" ? "6px" : "unset"};
    right: ${(props) =>
        props.position === "top-right" || props.position === "bottom-right" ? "6px" : "unset"};
    left: ${(props) =>
        props.position === "top-left" || props.position === "bottom-left" ? "6px" : "unset"};

    ${({ styles }) =>
        css`
            ${styles}
        `}
`

export const StyledMenuOptionsContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: ${(props) => `${props.theme.borderRadius1}`};
    padding: 4px;
    color: ${(props) => `${props.theme.headlandsWhite}`};
    font-size: 14px;

    span {
        color: ${(props) => `${props.theme.headlandsWhite}`};
        padding: 0 4px;
        font-weight: bold;
        :not(:last-child) {
            border-right: ${(props) => `solid 1px ${props.theme.headlandsGrayDark}`};
        }
    }

    :not(:last-of-type) {
        margin-right: 6px;
    }
`
