import { useCallback, useEffect, useRef, useState } from "react"
import {
    StyledButtonLabelContainer,
    StyledCustomFormulaTooltip,
    StyledFormulaInfoPopup,
    StyledInfoOutlinedIcon,
} from "./styles"
import ReactQuill from "react-quill"
import useOnClickOutside from "../../../../../hooks/onClickOutside.hook"
import RichTextEditor from "../../../../../components/Input/RichTextEditor"
import { Button } from "../../../../../components/Button/Button"
import { StyledBody2, StyledP } from "../../../../../styles/styledcomponents"
import { ReturnKeyIcon } from "../../../../../assets/icons/ReturnKeyIcon"

interface CustomFormulaTooltipProps {
    onUpdate: (formula: string, deleteSelection: boolean) => void
    onCancel: (originalSelection: string) => void
    onSubmit: (formula: string) => void
    textSelection: string
    position?: { top: number; bottom: number }
}

const CustomFormulaTooltip = ({
    onUpdate,
    onCancel,
    onSubmit,
    textSelection,
    position,
}: CustomFormulaTooltipProps) => {
    const [originalSelection, setOriginalSelection] = useState<string>()
    const [shouldDeleteSelection, setShouldDeleteSelection] = useState(true)
    const [formula, setFormula] = useState(textSelection)

    const tooltipRef = useRef<HTMLDivElement>(null)
    const quillRef = useRef<ReactQuill>(null)

    const toolbar: HTMLDivElement | null = document.querySelector(".ql-tooltip")

    const outsideClickHandler = useCallback(() => {
        originalSelection && onCancel(originalSelection)
    }, [originalSelection])

    useOnClickOutside(tooltipRef, outsideClickHandler)

    useEffect(() => {
        if (!originalSelection) setOriginalSelection(textSelection)
    }, [textSelection, originalSelection])

    // focus the editor and place the cursor at the end
    useEffect(() => {
        const editor = quillRef.current?.editor
        editor?.focus()
        editor?.setSelection(editor?.getLength(), 0)
    }, [quillRef])

    useEffect(() => {
        const formulaWithHighlight = `\\colorbox{#dcebf0}{$${formula}$}`
        onUpdate(formulaWithHighlight, shouldDeleteSelection)
        // only the first time we need to delete the whole text selection
        if (shouldDeleteSelection) setShouldDeleteSelection(false)
    }, [formula])

    const handleSubmit = () => {
        onSubmit(formula)
    }

    return (
        <StyledCustomFormulaTooltip
            ref={tooltipRef}
            top={position?.bottom ? `${position?.bottom + 18}px` : undefined}
            left={toolbar?.style?.left}
        >
            <RichTextEditor
                placeholder={"Please enter the Formula"}
                value={formula}
                noBorder
                onChange={(newValue: string) => setFormula(newValue)}
                onEnterPress={handleSubmit}
                reactQuillRef={quillRef}
            />
            <StyledFormulaInfoPopup
                position={["top center", "bottom center"]}
                mouseEnterDelay={500}
                on={["hover"]}
                trigger={<StyledInfoOutlinedIcon />}
            >
                <StyledP>
                    sqrt → Square root <br />
                    ^ → Superscript <br />
                    _ → Subscript <br />
                    int → Integral <br />
                    sum → Summation <br />
                    prod → Product <br />
                    alpha, ... omega → Small Greek letter <br />
                    Alpha, ... Omega → Capital Greek letter <br />
                </StyledP>
            </StyledFormulaInfoPopup>

            <Button
                label={
                    <StyledButtonLabelContainer>
                        <StyledBody2>Done</StyledBody2>
                        <ReturnKeyIcon opacity={0.7} />
                    </StyledButtonLabelContainer>
                }
                onClick={handleSubmit}
            />
        </StyledCustomFormulaTooltip>
    )
}

export default CustomFormulaTooltip
