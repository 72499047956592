import styled, { css } from "styled-components"
import { StyledLeftBubble } from "../styles"
import { generateQlIndents } from "../../../creator/components/TextBlock/styles"

const SINGLE_LINE_PADDING = "10px 12px 8px 12px"
const MULTI_LINE_PADDING = "6px 12px"

interface StyledTextBubbleProps {
    hide?: boolean
    noBackground?: boolean
    isMultiLine?: boolean
}

export const StyledTextBubble = styled(StyledLeftBubble)<StyledTextBubbleProps>`
    padding: ${({ isMultiLine }) => (isMultiLine ? MULTI_LINE_PADDING : SINGLE_LINE_PADDING)};
    ${({ noBackground }) =>
        noBackground &&
        css`
            background: transparent;
        `}
    ${({ hide }) =>
        hide &&
        css`
            display: none;
        `}
    li {
        padding-left: 3px !important;
        ${generateQlIndents(10)}
    }
`
