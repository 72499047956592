import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { useCurrentThreadGuid } from "./currentThread.hook"

const useThreadStyles = () => {
    const threadGuid = useCurrentThreadGuid()
    const font = useSelector(
        (state: RootState) => state.threadsReducer.threads[threadGuid]?.thread?.font
    )
    const theme = useSelector(
        (state: RootState) => state.threadsReducer.threads[threadGuid]?.thread?.theme
    )
    return { font, theme }
}

export default useThreadStyles
