import { useRef, useState } from "react"
import { useTheme } from "styled-components"
import { useCurrentUser } from "../../../hooks/currentUser"
import { useToast } from "../../../hooks/useToast.hook"
import { useMutation, useQuery } from "@apollo/client"
import { ABOUT_ME } from "../../../apollo/queries"
import { getCustomImageUrl } from "../../../utils/utils"
import { CREATE_ATTACHMENT, UPDATE_USER_PROFILE_IMAGE } from "../../../apollo/mutations"
import { ErrorToastIcon } from "../../../assets/icons/ErrorToastIcon"
import { SaveToastIcon } from "../../../assets/icons/SaveToastIcon"
import { StyledBox } from "../../../styles/styledcomponents"
import { OverlayOpacityLoader } from "../../../components/Loader"
import { UserAvatar } from "../../../creator/components/UserAvatar"
import { AvatarSize } from "../../../types"
import { StyledImageOutlined } from "./styles"

const UserProfileImage = () => {
    const theme = useTheme()
    const [changeImagePending, setChangeImagePending] = useState(false)
    const [profileImageURL, setProfileImageURL] = useState<string>()
    const { user } = useCurrentUser()
    const { showToast, showErrorToast } = useToast()
    const uploadButton = useRef<HTMLInputElement>(null)

    const { refetch: refetchAboutMeData } = useQuery(ABOUT_ME, {
        fetchPolicy: "cache-and-network",
        onCompleted: ({ aboutMe }) => {
            if (aboutMe.profileImageURL) {
                setProfileImageURL(
                    getCustomImageUrl(aboutMe.profileImageURL, {
                        height: 120,
                        width: 120,
                        crop: "face",
                        webp: true,
                    })
                )
            }
        },
    })

    const [updateProfileImage] = useMutation(UPDATE_USER_PROFILE_IMAGE, {
        onError: () => {
            setChangeImagePending(false)
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
        },
        onCompleted: () => {
            void refetchAboutMeData()
            setChangeImagePending(false)
            showToast({ icon: <SaveToastIcon />, message: "Saved successfully" })
        },
    })

    const [createAttachment] = useMutation(CREATE_ATTACHMENT, {
        onError: () => {
            setChangeImagePending(false)
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
        },
        onCompleted: (res) => {
            const attachment = res.createAttachment
            void updateProfileImage({ variables: { profileImageGuid: attachment.guid } })
        },
    })

    const handleImageSelect = (event: any) => {
        setChangeImagePending(true)
        const file = event.target.files[0]
        createAttachment({ variables: { file } })
    }

    // @ts-ignore
    const handleUploadButtonClick = () => uploadButton.current?.click()

    return (
        <StyledBox css={{ position: "relative" }} onClick={handleUploadButtonClick}>
            <OverlayOpacityLoader loading={changeImagePending}>
                <UserAvatar
                    user={{
                        profileImageURL,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                    }}
                    size={AvatarSize.XXXXL}
                    styles={{ border: `6px solid ${theme.colors.base.uiPrimary500}` }}
                />
                <input
                    id={"upload-input"}
                    type={"file"}
                    name={"profile-image"}
                    ref={uploadButton}
                    onChange={handleImageSelect}
                    hidden
                />
                {!profileImageURL && <StyledImageOutlined />}
            </OverlayOpacityLoader>
        </StyledBox>
    )
}

export default UserProfileImage
