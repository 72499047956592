import styled from "styled-components"
import { StyledChunkContent } from "../../../../styles/styledcomponents"

export const StyledMessageComponent = styled(StyledChunkContent)`
    .header-container {
        display: flex;
        flex-direction: column;

        .toggles-row {
            display: flex;
            justify-content: space-between;
        }
    }
`

export const StyledMessageRow = styled.div<{ secondary?: boolean }>`
    display: flex;
    margin-top: 6px;

    .logo {
        width: 58px;
        height: 58px;
        opacity: ${(props) => (props.secondary ? 0.7 : "unset")};
    }
    .sender-and-message-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-left: 12px;

        .rich-text-editor {
            .quill {
                margin-top: unset;
                margin-bottom: unset;

                .ql-editor {
                    padding: 6px 12px;
                }

                &:not(.focused) {
                    .ql-editor {
                        padding-left: 0;
                    }
                }
            }

            &.sender {
                width: fit-content;
                // this min-width is a simple way to handle autosize but still having enough width for the placeholder
                // the down side: it depends on the placeholder text
                min-width: 160px;
                .quill {
                    &:not(.focused) {
                        // make the text bold if not focused
                        p {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
`

export const StyledMainSeparator = styled.div`
    display: flex;
    flex-direction: column;
    margin: 18px 0 12px;

    font-size: 14px;
    font-weight: 700;

    :after {
        content: "";
        flex: 1;
        border-bottom: 1px solid ${(props) => props.theme.headlandsGray10};
        margin-top: 6px;
    }
`

export const StyledSecondarySeparator = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    margin-top: 6px;

    font-weight: 400;
    font-size: 12px;
    color: ${(props) => props.theme.headlandsGray3};

    :after {
        content: "";
        flex: 1;
        border-bottom: 1px solid ${(props) => props.theme.headlandsGray3};
    }

    :not(:empty)::before {
        margin-right: 0.25em;
    }
    :not(:empty)::after {
        margin-left: 0.25em;
    }
`
