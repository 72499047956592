import Select from "../../../components/Select/Select"
import { components, Props } from "react-select"
import defaultAvatar from "../../../assets/images/default-avatar.png"
import { useTheme } from "styled-components"
import { Person } from "../../../apollo/generated/graphql"
import { Maybe } from "graphql/jsutils/Maybe"
import { getCustomImageUrl } from "../../../utils/utils"

export interface InstructorSelectProps {
    instructors: Partial<Person>[]
    value?: Maybe<Partial<Person>>
    onChange: (newInstructor: any) => void
    selectProps?: Partial<Props>
}

const InstructorSelect = ({ instructors, value, onChange, selectProps }: InstructorSelectProps) => {
    const theme = useTheme()

    // TODO: We should remove this, not sure how to correctly type it atm
    // @ts-ignore
    const { styles: selectStyles, ...otherSelectProps } = selectProps || {}

    return (
        <Select
            value={value}
            options={instructors}
            onChange={onChange}
            components={{
                Option: InstructorOption,
                SingleValue: InstructorSingleValue,
            }}
            isSearchable={false}
            styles={{
                container: () => ({
                    marginTop: 12,
                }),
                control: (_, state) => ({
                    padding: 6,
                    margin: 0,
                    border: state.isFocused
                        ? `1px solid ${theme.headlandsGray3}`
                        : "1px solid transparent",
                    boxShadow: "none",
                    borderRadius: state.menuIsOpen
                        ? `${theme.borderRadius1} ${theme.borderRadius1} 0 0`
                        : theme.borderRadius1,
                    "&:hover": {
                        border: `1px solid ${theme.headlandsGray3}`,
                    },
                }),
                menu: () => ({
                    width: "100%",
                    left: 0,
                }),
                singleValue: () => ({
                    display: "flex",
                    alignItems: "center",
                    img: {
                        width: 30,
                        height: 30,
                        borderRadius: "50%",
                        objectFit: "cover",
                        marginRight: 8,
                    },
                    span: {
                        fontSize: 12,
                        lineHeight: "18px",
                        color: theme.headlandsGrayDark,
                    },
                }),
                option: () => ({
                    display: "flex",
                    alignItems: "center",
                    img: {
                        width: 30,
                        height: 30,
                        borderRadius: "50%",
                        objectFit: "cover",
                        marginRight: 8,
                    },
                    span: {
                        fontSize: 12,
                        lineHeight: "18px",
                        color: theme.headlandsGrayDark,
                    },
                }),
                ...selectStyles,
            }}
            {...otherSelectProps}
        />
    )
}

const InstructorSingleValue = (props: any) => (
    <components.SingleValue {...props}>
        <img
            alt={"profile-image-avatar"}
            className={"avatar"}
            src={
                props.data.profileImageURL
                    ? getCustomImageUrl(props.data.profileImageURL, {
                          width: 30,
                          crop: "face",
                      })
                    : defaultAvatar
            }
        />
        <span>
            {props.data.firstName} {props.data.lastName}
        </span>
    </components.SingleValue>
)

const InstructorOption = (props: any) => (
    <components.Option {...props}>
        <img
            alt={"profile-image-avatar"}
            className={"avatar"}
            src={
                props.data.profileImageURL
                    ? getCustomImageUrl(props.data.profileImageURL, {
                          width: 30,
                          crop: "face",
                      })
                    : defaultAvatar
            }
        />
        <span>
            {props.data.firstName} {props.data.lastName}
        </span>
    </components.Option>
)

export default InstructorSelect
