import { useEffect, useState } from "react"
import { Block, BlocksType } from "../../../types"
import Input from "../../../components/Input/Input"
import { useDispatch } from "react-redux"
import { updateBlock } from "../../../redux/blocks"
import useVariableDefinitions from "../../../hooks/variables.hook"
import { VariableType } from "../../../apollo/generated/graphql"
import {
    StyledBox,
    StyledChunkContent,
    StyledRow,
    StyledSpan,
} from "../../../styles/styledcomponents"
import { useTheme } from "styled-components"

export interface SetVariableBlockProps {
    block: Block
    onChange: (text: string, blockId: string) => void
}

const SetVariableBlock = ({ block, onChange }: SetVariableBlockProps) => {
    const dispatch = useDispatch()
    const theme = useTheme()

    const [altVariableName, setAltVariableName] = useState<string>(block.save_to_variable || "")
    const assignedVariables = useVariableDefinitions()
    const [error, setError] = useState<string>()

    /*
     * This useEffect is used when the variable name is change from the setting bar
     * */
    useEffect(() => {
        if (block.save_to_variable !== altVariableName) {
            setAltVariableName(block.save_to_variable || "")
        }
    }, [block.save_to_variable])

    /*
     * This function check whether the variable name is a valid one or not.
     * We consider a valid variable name if these items are accomplished:
     * - there is no other variable with the same name OR
     * - there is a variable with the same name and both variables types are the exact same OR
     * - there is a variable name with the same name but it belongs to the same block (it catches a bug related to the
     * last previous valid variable name)
     * */
    const isVariableNameValid = (variableNameToTest: string): boolean => {
        if (assignedVariables) {
            return !assignedVariables.find(
                (assignedVariable) =>
                    assignedVariable.name === variableNameToTest &&
                    assignedVariable.blockId !== block.id &&
                    // note that a SetVariableBlock component will always create a variable of type 'string'
                    assignedVariable.type !== VariableType.String
            )
        }
        return true
    }

    // This function add a new variable to a wanted thread
    const addVariableName = (variableNameToAdd: string): void => {
        dispatch(
            updateBlock({
                blocksType: BlocksType.BLOCKS,
                id: block.id,
                block: {
                    ...block,
                    save_to_variable: variableNameToAdd,
                },
            })
        )
    }

    const handleVariableChange = (newName: string) => {
        if (isVariableNameValid(newName)) {
            addVariableName(newName)
            setAltVariableName(newName)
            setError(undefined)
        } else {
            setError("You can not reassign a variable name with a different type")
            setAltVariableName(newName)
        }
    }

    return (
        <StyledChunkContent id={`block-${block.id}`}>
            <StyledRow css={{ maxWidth: "532px", alignItems: "center" }}>
                <StyledBox css={{ position: "relative", display: "flex", alignItems: "center" }}>
                    <StyledSpan css={{ position: "absolute", left: "12px" }}>@</StyledSpan>
                    <Input
                        value={altVariableName}
                        onChange={handleVariableChange}
                        placeholder={"Variable name"}
                        style={{ paddingLeft: "30px", backgroundColor: theme.colors.base.uiBgBase }}
                    />
                </StyledBox>
                <StyledSpan css={{ fontSize: "20px", margin: "0 18px" }}>=</StyledSpan>
                <Input
                    value={block.value || ""}
                    onChange={(newValue: string) => onChange(newValue, block.id)}
                    placeholder={"Value"}
                    style={{ backgroundColor: theme.colors.base.uiBgBase }}
                />
            </StyledRow>
            {error && (
                <StyledSpan css={{ color: theme.colors.base.uiError300 }}>{error}</StyledSpan>
            )}
        </StyledChunkContent>
    )
}

export default SetVariableBlock
