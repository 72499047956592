import { IconProps } from "../../types"

export const CloseIcon = ({ width = 32, height = 32, color = "#888888", ...rest }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M8.76109 23.7364C8.40794 23.3933 8.41803 22.8081 8.76109 22.4651L15.2186 15.9975L8.76109 9.53997C8.41803 9.19692 8.41803 8.6218 8.76109 8.26866C9.10414 7.90542 9.69944 7.91551 10.0425 8.26866L16.5 14.7262L22.9575 8.26866C23.3006 7.91551 23.8858 7.91551 24.2389 8.26866C24.5921 8.61171 24.582 9.19692 24.2389 9.53997L17.7814 15.9975L24.2389 22.4651C24.582 22.8081 24.582 23.3832 24.2389 23.7364C23.8959 24.0895 23.3006 24.0794 22.9575 23.7364L16.5 17.2789L10.0425 23.7364C9.69944 24.0794 9.11423 24.0794 8.76109 23.7364Z"
                fill={color}
            />
        </svg>
    )
}
