import styled, { css } from "styled-components"

export const StyledTimeline = styled.div`
    position: relative;

    ::after {
        content: "";
        position: absolute;
        width: 1px;
        background-color: #d9d9d9;
        top: 10px;
        bottom: 0;
        left: 22px;
        margin-right: 9px;
    }
`

export const StyledHistorySpan = styled.span<{
    leftMargin?: boolean
    bold?: boolean
}>`
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.headlandsGray2};
    ${({ bold }) =>
        bold &&
        css`
            font-weight: 700;
        `};
    ${({ leftMargin }) =>
        leftMargin &&
        css`
            margin-left: 6px;
        `};
`

export const StyledTimelineItem = styled.div<{ colored?: boolean; filled?: boolean }>`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: ${({ theme }) => theme.borderRadius1};

    ::after {
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        left: 18px;
        background-color: ${({ theme, filled }) =>
            filled ? theme.headlandsPrimaryColor : theme.headlandsWhite};
        border: 1px solid
            ${({ theme, colored }) =>
                colored ? theme.headlandsPrimaryColor : theme.headlandsGray10};
        top: 10px;
        border-radius: 50%;
        z-index: 1;
    }

    &:hover {
        background-color: ${({ theme }) => theme.headlandsGray6};
        transition: background-color 0.2s;
        cursor: default;
    }

    ${StyledHistorySpan} {
        margin-left: 31px;
        padding: 6px 0;
        width: calc(100% - 31px);
    }
`

export const StyledHistoryAuthorAvatar = styled.img`
    height: 24px;
    width: 24px;
    border-radius: 50%;
    object-fit: cover;
`
