import {
    StyledAddChunkContainer,
    StyledExpandLessIcon,
    StyledExpandMoreIcon,
    StyledSection,
    StyledSectionContent,
    StyledSectionHeader,
    StyledSectionTitleContainer,
} from "./styles"
import { Block, BlockContext, BlocksType, BlockType } from "../../../types"
import { useCallback, useState } from "react"
import AutosizeInput from "react-input-autosize"
import { addBlocks, findSection, updateBlock } from "../../../redux/blocks"
import { useDispatch, useSelector } from "react-redux"
import AddChunkMenu from "../AddChunkMenu"
import { RootState } from "../../../redux/store"
import { Editor } from "../EditorScreen/Editor"
import { last } from "lodash"

interface SectionProps {
    block: Block
    context?: BlockContext
    handlePreview?: (previewMode: boolean, initialBlockId?: string) => void
}

const Section = ({ block, handlePreview }: SectionProps) => {
    const [collapsed, setCollapsed] = useState(false)
    const dispatch = useDispatch()
    const sectionReadOnlyBlock = useSelector((state: RootState) => {
        return findSection(block.id, state.blocksReducer[`${BlocksType.BLOCKS}Index`])
    })

    const handleTitleChange = (title: string) => {
        dispatch(
            updateBlock({
                blocksType: BlocksType.BLOCKS,
                id: block.id,
                block: { ...block, title },
            })
        )
    }

    const handleAddChunk = useCallback(
        (chunk: Block) => {
            dispatch(
                addBlocks({
                    blocksType: BlocksType.BLOCKS,
                    blocks: [chunk],
                    sectionId: block.id,
                })
            )
        },
        [block.id, dispatch]
    )

    return (
        <StyledSection id={`block-${block.id}`}>
            <StyledSectionHeader onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? <StyledExpandMoreIcon /> : <StyledExpandLessIcon />}
                <StyledSectionTitleContainer>
                    <AutosizeInput
                        value={block.title || ""}
                        placeholder={"Section"}
                        placeholderIsMinWidth
                        onChange={(event: { target: { value: string } }) =>
                            handleTitleChange(event.target.value)
                        }
                        onClick={(e) => e.stopPropagation()}
                        style={{ maxWidth: "100%" }}
                    />
                </StyledSectionTitleContainer>
            </StyledSectionHeader>
            <StyledSectionContent open={!collapsed}>
                {sectionReadOnlyBlock.objects && (
                    <Editor
                        blocksType={BlocksType.BLOCKS}
                        blockIds={sectionReadOnlyBlock.objects}
                        parentType={BlockType.SECTION}
                        sectionId={block.id}
                        handlePreview={handlePreview}
                        containsChunks
                    />
                )}
                <StyledAddChunkContainer>
                    <AddChunkMenu
                        handleAddChunk={handleAddChunk}
                        chunkParams={{
                            sectionId: block.id,
                            index: sectionReadOnlyBlock?.objects
                                ? Object.keys(sectionReadOnlyBlock?.objects).length
                                : 0,
                            // inside a section, associate the "add" menu to the last object of the section
                            blockGuid: sectionReadOnlyBlock?.objects
                                ? last(Object.values(sectionReadOnlyBlock?.objects))?.id
                                : undefined,
                        }}
                    />
                </StyledAddChunkContainer>
            </StyledSectionContent>
        </StyledSection>
    )
}

export default Section
