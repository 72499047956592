import { IconProps } from "../../types"
import { useTheme } from "styled-components"

const AddRoundedIcon = ({ width = 16, height = 16, color }: IconProps) => {
    const theme = useTheme()

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Add" clip-path="url(#add_rounded_icon_path)">
                <path
                    id="Vector"
                    d="M12.376 8.125C12.376 8.40114 12.1521 8.625 11.876 8.625H9.12598C8.84983 8.625 8.62598 8.84886 8.62598 9.125V11.875C8.62598 12.1511 8.40212 12.375 8.12598 12.375H7.87598C7.59983 12.375 7.37598 12.1511 7.37598 11.875V9.125C7.37598 8.84886 7.15212 8.625 6.87598 8.625H4.12598C3.84983 8.625 3.62598 8.40114 3.62598 8.125V7.875C3.62598 7.59886 3.84983 7.375 4.12598 7.375H6.87598C7.15212 7.375 7.37598 7.15114 7.37598 6.875V4.125C7.37598 3.84886 7.59983 3.625 7.87598 3.625H8.12598C8.40212 3.625 8.62598 3.84886 8.62598 4.125V6.875C8.62598 7.15114 8.84983 7.375 9.12598 7.375H11.876C12.1521 7.375 12.376 7.59886 12.376 7.875V8.125Z"
                    fill={color ?? theme.colors.base.uiBgIcon}
                />
            </g>
            <defs>
                <clipPath id="add_rounded_icon_path">
                    <rect
                        width="15"
                        height="15"
                        fill={color ?? theme.colors.base.uiBgIcon}
                        transform="translate(0.500977 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default AddRoundedIcon
