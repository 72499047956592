import classNames from "classnames"
import React, { useCallback, useMemo, useRef, useState } from "react"
import {
    Block,
    BlocksType,
    ConditionalBranch,
    ConditionalTest,
    Operator,
    ReadOnlyConditionalBranch,
    SelectOption,
    ShallowBlock,
} from "../../../../types"
import CloseButton from "../../../../components/CloseButton"
import Input from "../../../../components/Input/Input"
import chevronDown from "../../../../assets/icons/chevron-down.svg"
import add from "../../../../assets/icons/add.svg"
import { Editor } from "../../EditorScreen/Editor"
import { getEmptyBranch, getOperatorOptionsByVariableType } from "../../../../utils/utils"
import {
    addBlocks,
    addBranch,
    deleteBranch,
    findBlock,
    updateBranch,
} from "../../../../redux/blocks"
import { components, SingleValue } from "react-select"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import useOnClickOutside from "../../../../hooks/onClickOutside.hook"
import useVariableDefinitions, {
    VariableDefinitionWithBlockId,
} from "../../../../hooks/variables.hook"
import Select from "../../../../components/Select/Select"
import Checkbox from "../../../../components/Checkbox"
import { StyledBox, StyledH2, StyledSpan } from "../../../../styles/styledcomponents"
import {
    StyledAddBlockRow,
    StyledConditional,
    StyledConditionalInput,
    StyledKeywordPillsContainer,
} from "./styles"
import EditIcon from "@mui/icons-material/Edit"
import { useTheme } from "styled-components"
import { capitalize, last } from "lodash"
import CsvModal from "../../../../components/CsvModal"
import AddChunkMenu from "../../AddChunkMenu"
import { VariableType } from "../../../../apollo/generated/graphql"

export interface ConditionalProps {
    blockId: string
}

const Conditional = ({ blockId }: ConditionalProps) => {
    const dispatch = useDispatch()
    const blockIds = useSelector((state: RootState) => {
        return findBlock(blockId, state.blocksReducer.blocksIndex)!
    })
    const block = useSelector<RootState, ShallowBlock>(
        (state: RootState): ShallowBlock => state.blocksReducer.blocks[blockId],
        shallowEqual
    )
    const variables = useVariableDefinitions()
    const [focused, setFocused] = useState<boolean>(false)
    const [focusedBranchId, setFocusedBranchId] = useState<string>(
        block.branches ? block.branches[0].id : ""
    )
    const wrapperRef = useRef(null)
    const handler = useCallback(() => setFocused(false), [])
    useOnClickOutside(wrapperRef, handler)

    // Only the first branch's variable matters,
    // as all the subsequent ones will have the same one
    const variable = variables.find((variable) => variable.name === block.branches![0].test.var)
    const questionBlock = useSelector((state: RootState) => {
        return variable ? state.blocksReducer.blocks[variable.blockId!] : undefined
    }, shallowEqual)

    const addCondition = (isElse?: boolean) => {
        const newBranch = getEmptyBranch()
        dispatch(
            addBranch({
                blocksType: BlocksType.BLOCKS,
                blockId: block.id,
                branch: {
                    ...newBranch,
                    test: {
                        var: block.branches![0].test.var,
                        op: isElse ? Operator.ALWAYS_TRUE : block.branches![0].test.op,
                    },
                },
            })
        )
        setFocusedBranchId(newBranch.id)
    }

    const removeCondition = (branchId: string) => {
        dispatch(
            deleteBranch({
                blocksType: BlocksType.BLOCKS,
                blockId,
                branchId,
            })
        )
    }

    const handleVariableChange = (variable: string) => {
        block.branches?.forEach((branch) => {
            const test = { ...branch.test }
            if (
                variables.find((variableItem) => variableItem.name === branch.test.var)?.type !==
                variables.find((variableItem) => variableItem.name === variable)?.type
            ) {
                test.op = undefined
                test.val = undefined
            }
            test.var = variable
            dispatch(
                updateBranch({
                    blocksType: BlocksType.BLOCKS,
                    id: branch.id,
                    branch: { test },
                })
            )
        })
    }

    const handleOperatorChange = (operator: string) => {
        block.branches?.forEach((branch) => {
            const test = { ...branch.test }
            if (branch.test.op !== Operator.ALWAYS_TRUE) {
                test.op = operator as Operator
            }
            dispatch(
                updateBranch({ blocksType: BlocksType.BLOCKS, id: branch.id, branch: { test } })
            )
        })
    }

    const selectedChoices = block.branches!.reduce(
        (choices: string[], branch: ConditionalBranch) =>
            branch.test.val ? choices.concat(branch.test.val) : choices,
        []
    )

    const allCasesCovered = questionBlock?.choices?.every((choice) => {
        return selectedChoices.includes(choice.id)
    })
    return (
        <StyledConditional
            id={`block-${block.id}`}
            ref={wrapperRef}
            onClick={() => setFocused(true)}
        >
            {Object.keys(blockIds.branches!).map((_, branchIndex) => {
                const branch = block.branches!.filter(
                    (d) => d?.id === blockIds.branches![branchIndex].id
                )[0]
                return (
                    <Branch
                        key={branch.id}
                        branch={branch}
                        branchIds={blockIds.branches![branchIndex]}
                        onVariableChange={handleVariableChange}
                        onOperatorChange={handleOperatorChange}
                        onDelete={() => removeCondition(branch.id)}
                        deleteable={block!.branches && block!.branches.length > 1}
                        onFocus={(branchId: string) => setFocusedBranchId(branchId)}
                        focused={focused && branch.id === focusedBranchId}
                        isFirst={branchIndex === 0}
                        questionBlock={questionBlock}
                        variables={variables}
                    />
                )
            })}
            {focused &&
                !allCasesCovered &&
                !block.branches!.some((branch) => branch.test.op === Operator.ALWAYS_TRUE) && (
                    <div
                        className={"else-button"}
                        data-testid={"else-button"}
                        onClick={() => addCondition(true)}
                    >
                        <img alt={"chevron"} src={chevronDown} />
                        <span>Otherwise</span>
                    </div>
                )}
            {focused &&
                !allCasesCovered &&
                !block.branches!.some((branch) => branch.test.op === Operator.ALWAYS_TRUE) && (
                    <div className={"add-condition-container"} onClick={() => addCondition()}>
                        <div className={"line"} />
                        <div className={"add-condition"}>
                            <img alt={"add-condition"} src={add} />
                            <span>Condition</span>
                        </div>
                    </div>
                )}
        </StyledConditional>
    )
}

export interface BranchProps {
    branch: ConditionalBranch
    branchIds: ReadOnlyConditionalBranch
    onVariableChange: (variable: string) => void
    onOperatorChange: (operator: string) => void
    deleteable?: boolean
    onDelete: () => void
    onFocus: (branchId: string) => void
    focused: boolean
    isFirst: boolean
    questionBlock?: Block
    variables: VariableDefinitionWithBlockId[]
}

const Branch = React.memo(
    ({
        variables,
        branch,
        branchIds,
        onVariableChange,
        onOperatorChange,
        deleteable,
        onDelete,
        onFocus,
        focused,
        isFirst,
        questionBlock,
    }: BranchProps) => {
        const theme = useTheme()
        const dispatch = useDispatch()
        const [collapsed, setCollapsed] = useState<boolean>(false)
        const [conditionHovered, setConditionHovered] = useState(false)
        const [keywordsModalIsOpen, setKeywordModalIsOpen] = useState(false)

        const keywordsOperators = [Operator.ANY_OF, Operator.ALL]

        const handleValueChange = (value: string | string[]) => {
            dispatch(
                updateBranch({
                    blocksType: BlocksType.BLOCKS,
                    id: branch.id,
                    branch: { test: { ...branch.test, val: value } },
                })
            )
        }

        const handleMultiValueChange = (newValue: any) => {
            handleValueChange(
                newValue.map((value: { label: string; value: string }) => value.value)
            )
        }

        const handleAddChunk = useCallback(
            (chunk: Block) => {
                dispatch(
                    addBlocks({
                        blocksType: BlocksType.BLOCKS,
                        branchId: branch.id,
                        blocks: [chunk],
                    })
                )
            },
            [branch.id, dispatch]
        )

        const variable = variables.find((variableItem) => variableItem.name === branch.test.var)

        const testVal = Array.isArray(branch.test.val)
            ? questionBlock?.choices
                ? (branch.test.val as string[]).map((value) => ({
                      label:
                          questionBlock.choices!.find((choice) => choice.id === value)?.text || "",
                      value,
                  }))
                : variable?.possibleValues
                ? (branch.test.val as string[]).map((value) => ({
                      label:
                          questionBlock?.options?.find((option) => option.id === value)?.text || "",
                      value,
                  }))
                : []
            : []

        const renderValueInput = (variableType: VariableType, operatorType?: Operator) => {
            switch (variableType) {
                case VariableType.String:
                    // render the keywords input
                    if (operatorType && keywordsOperators.includes(operatorType)) {
                        const keywordValues =
                            branch.test.val && typeof branch.test.val !== "string"
                                ? branch.test.val
                                : []
                        return (
                            <StyledConditionalInput
                                tagStyleValues
                                onClick={() => setKeywordModalIsOpen(true)}
                            >
                                <StyledKeywordPillsContainer>
                                    {keywordValues.map((value, index) => (
                                        <StyledSpan
                                            key={`${index}-${value}`}
                                            css={{ fontWeight: "700", whiteSpace: "nowrap" }}
                                        >
                                            {value}
                                        </StyledSpan>
                                    ))}
                                </StyledKeywordPillsContainer>
                                <EditIcon sx={{ fontSize: "14px", marginLeft: "7px" }} />
                            </StyledConditionalInput>
                        )
                    }
                    // render the common string input
                    else {
                        return (
                            <Input
                                data-testid={"branch-value-input"}
                                className={"value-input"}
                                onChange={handleValueChange}
                                value={branch.test.val as string}
                                disabled={!branch.test.op}
                            />
                        )
                    }
                default:
                    /*
                     * If the variable comes from a block and that block has choices,
                     * we use those as options.
                     * If it doesn't, and it has possible values we use those instead
                     */
                    const options =
                        variable?.blockId && questionBlock?.choices
                            ? questionBlock.choices.map((choice) => ({
                                  label: choice.text || "",
                                  value: choice.id,
                              }))
                            : variable?.possibleValues
                            ? variable?.possibleValues.map((possibleValue) => ({
                                  label: capitalize(possibleValue?.text || ""),
                                  value: possibleValue.id,
                              }))
                            : []
                    return (
                        <ValueInput
                            options={options}
                            disabled={!branch.test.op}
                            onChange={handleMultiValueChange}
                            value={testVal}
                        />
                    )
            }
        }

        return (
            <div className={"condition"} onClick={() => onFocus(branch.id)}>
                <div
                    className={classNames("condition-row", { collapsed })}
                    onMouseEnter={() => setConditionHovered(true)}
                    onMouseLeave={() => setConditionHovered(false)}
                >
                    <img
                        alt={"dropdown-button"}
                        className={classNames("dropdown-button", { collapsed })}
                        src={chevronDown}
                        onClick={() => setCollapsed(!collapsed)}
                    />
                    {branch.test.op === Operator.ALWAYS_TRUE ? (
                        <div className={"else-container"}>
                            <div className={"line"} />
                            <div className={"else"}>
                                <span>Otherwise</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className={"condition-column"}>
                                <VariableInput
                                    variables={variables}
                                    onVariableChange={onVariableChange}
                                    test={branch.test}
                                    disabled={!isFirst}
                                />
                                <div className={classNames("collapsible", { collapsed })}>
                                    <div className={classNames("border", { focused })} />
                                </div>
                            </div>
                            <div className={"condition-column"}>
                                <OperatorInput
                                    disabled={!branch.test.var || !isFirst}
                                    onSelect={onOperatorChange}
                                    options={getOperatorOptionsByVariableType(variable?.type!)}
                                    value={branch.test.op}
                                />
                                <div className={classNames("collapsible", { collapsed })}>
                                    {testVal.slice(1).map(() => (
                                        <div className={"operator"}>
                                            {/* If variable is single choice, or multiple choice with CONTAINS_ANY operator, we show "OR" */}
                                            <span>
                                                {variable?.type === VariableType.Ref ||
                                                (variable?.type === VariableType.RefSet &&
                                                    branch.test.op === Operator.CONTAINS_ANY)
                                                    ? "OR"
                                                    : "AND"}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div
                                className={classNames("condition-column", {
                                    "hide-overflow":
                                        branch?.test?.op &&
                                        keywordsOperators.includes(branch.test.op),
                                })}
                            >
                                {renderValueInput(variable?.type!, branch.test.op)}
                                {testVal.length > 1 && (
                                    <div className={classNames("collapsible", { collapsed })}>
                                        {testVal.slice(1).map((val) => (
                                            <div className={"value"}>
                                                <span>{val.label}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {deleteable && conditionHovered && (
                        <CloseButton onClose={onDelete} css={{ top: "4px", right: 0 }} />
                    )}
                </div>
                <div className={classNames("objects-container", { collapsed, focused })}>
                    <Editor
                        blocksType={BlocksType.BLOCKS}
                        blockIds={branchIds.objects}
                        branchId={branch.id}
                        containsChunks
                    />
                    <StyledAddBlockRow>
                        <AddChunkMenu
                            handleAddChunk={handleAddChunk}
                            chunkParams={{
                                branchId: branch.id,
                                index: branch.objects.length || 0,
                                // inside a conditional, associate the "add" menu to the last object of the branch
                                blockGuid: last(branch.objects)?.id,
                            }}
                        />
                    </StyledAddBlockRow>
                </div>
                {keywordsModalIsOpen && (
                    <CsvModal
                        values={branch.test.val}
                        onSubmit={(values) => handleValueChange(values)}
                        closeModal={() => setKeywordModalIsOpen(false)}
                        title={
                            <StyledH2 css={{ color: theme.headlandsGray1 }}>
                                Keywords for&nbsp;
                                <StyledH2 css={{ color: theme.headlandsPrimaryColor }}>@</StyledH2>
                                {branch.test.var}
                            </StyledH2>
                        }
                    />
                )}
            </div>
        )
    },
    (prevProps, nextProps) =>
        prevProps.branch === nextProps.branch &&
        prevProps.focused === nextProps.focused &&
        prevProps.branchIds === nextProps.branchIds &&
        Object.keys(prevProps.variables).join("") === Object.keys(nextProps.variables).join("") &&
        prevProps.questionBlock?.choices?.length === nextProps.questionBlock?.choices?.length
)

export interface VariableInputProps {
    variables: VariableDefinitionWithBlockId[]
    test: ConditionalTest
    onVariableChange: (variable: any) => void
    disabled?: boolean
}

const VariableInput = ({
    variables,
    onVariableChange,
    test,
    disabled,
    ...props
}: VariableInputProps) => {
    const onChange = (newValue: any) => onVariableChange(newValue.value)

    return (
        <Select
            className={"react-select"}
            classNamePrefix={"react-select"}
            options={variables.map((variable) => ({ value: variable.name, label: variable.name }))}
            onChange={onChange}
            value={{ label: test.var, value: test.var }}
            isDisabled={disabled}
            components={{
                IndicatorSeparator: () => null,
                ValueContainer,
            }}
            isSearchable
            {...props}
        />
    )
}

export interface OperatorInputProps {
    onSelect: (variable: any) => void
    disabled?: boolean
    options: SelectOption[]
    value?: Operator
}

const OperatorInput = ({ onSelect, disabled, options, value }: OperatorInputProps) => {
    const optionSelected = useMemo(() => {
        const optionFound = options.filter((option) => option.value === value)[0]
        /*
         * if no option was found is probably because the conditional is using the old operators (glob or iglob)
         * in that case just create a new option with the old operator
         * Todo: in the future when the old operators are not longer used we should delete this extra step
         * */
        if (!optionFound) {
            return {
                value,
                label: value?.toUpperCase(),
            }
        }
        return optionFound
    }, [value])
    return (
        <Select
            className={"react-select"}
            classNamePrefix={"react-select"}
            options={options}
            onChange={(option) => onSelect((option as SingleValue<SelectOption>)?.value)}
            value={optionSelected}
            isDisabled={disabled}
            components={{ IndicatorSeparator: () => null }}
            hideSelectedOptions={false}
            isClearable={false}
            isSearchable
            placeholder={""}
            styles={{
                singleValue: () => ({
                    fontWeight: "bold",
                    textTransform: "uppercase",
                }),
                option: () => ({
                    fontWeight: "bold",
                    textTransform: "uppercase",
                }),
            }}
        />
    )
}

export interface ValueInputProps {
    options: { label: string; value: string }[]
    value: { label: string; value: string }[] | undefined
    onChange: (variable: any) => void
    disabled?: boolean
}

const ValueInput = ({ options, value, onChange, disabled }: ValueInputProps) => {
    return (
        <Select
            className={"react-select"}
            classNamePrefix={"react-select"}
            options={options}
            onChange={onChange}
            value={value}
            isDisabled={disabled}
            components={{
                IndicatorSeparator: () => null,
                Option,
            }}
            hideSelectedOptions={false}
            isClearable={false}
            isMulti
            isSearchable
            placeholder={""}
            closeMenuOnSelect={false}
        />
    )
}

const Option = (props: any) => (
    <div>
        <components.Option {...props}>
            <StyledBox css={{ display: "flex" }}>
                <Checkbox selected={props.isSelected} />
                <StyledSpan css={{ lineHeight: "18px" }}>{props.label}</StyledSpan>
            </StyledBox>
        </components.Option>
    </div>
)

const ValueContainer = (props: any) => {
    const theme = useTheme()
    return (
        <components.ValueContainer {...props}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <span style={{ color: theme.headlandsPrimaryColor, whiteSpace: "pre" }}>
                    <strong>IF</strong> @
                </span>
                {props.children}
            </div>
        </components.ValueContainer>
    )
}

export default Conditional
