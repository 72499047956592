import { StyledBox, StyledRow, StyledSpan, StyledH3 } from "../../../styles/styledcomponents"
import { StyledCard } from "../../styles"
import {
    StyledCopyLinkButton,
    StyledInputsContainer,
    StyledLastColumnContainer,
    StyledLinkIcon,
} from "./styles"
import Input from "../../../components/InputNew"
import { Formik } from "formik"
import ImageUpload from "../ImageUpload"
import { useSelectedDeliverProgram } from "../../../hooks/selectedProgram.hook"
import { useMutation } from "@apollo/client"
import { UPDATE_PROGRAM } from "../../../apollo/mutations"
import FormikAutoSave from "../../../utils/FormikAutoSave"
import { useToast } from "../../../hooks/useToast.hook"
import Checkbox from "../../../components/Checkbox"
import { ENROLLED_PROGRAMS } from "../../../apollo/queries"

enum StringFields {
    displayName = "displayName",
    name = "name",
    description = "description",
}

enum BooleanFields {
    live = "live",
    disableSocialSidebar = "disableSocialSidebar",
    disableLeaderboard = "disableLeaderboard",
}

const ProgramDetailsCard = () => {
    const { program } = useSelectedDeliverProgram()
    const { showToast } = useToast()

    const [updateProgramDetails] = useMutation(UPDATE_PROGRAM, {
        refetchQueries: [ENROLLED_PROGRAMS], // to keep the enrolled programs updated
    })

    const [updateProgramImageGuid, { loading: updateImageGuidLoading }] =
        useMutation(UPDATE_PROGRAM)

    const copyProgramLink = () => {
        const currentUrl = window.location.href
        const programUrl = currentUrl.replace("deliver", "program")
        if (programUrl) {
            navigator.clipboard.writeText(programUrl).catch((e) => console.log(e))
            showToast({
                icon: <StyledLinkIcon sx={{ color: "white" }} />,
                message: "Program link copied to clipboard",
            })
        }
    }

    const updateImageGuid = (guid: string) => {
        // TODO: delete the `name` field when the API support receiving only the `imageGUID`
        void updateProgramImageGuid({
            variables: { guid: program.guid, updates: { name: program.name, imageGUID: guid } },
        })
    }

    return (
        <Formik
            initialValues={{
                [StringFields.displayName]: program.displayName,
                [StringFields.name]: program.name,
                [StringFields.description]: program.description,
                [BooleanFields.live]: program.live,
                [BooleanFields.disableSocialSidebar]: !program.socialSidebar,
                [BooleanFields.disableLeaderboard]: !program.leaderboard,
            }}
            onSubmit={(values) => {
                const variables = {
                    guid: program.guid,
                    updates: {
                        name: values.name,
                        displayName: values.displayName,
                        description: values.description,
                        live: values.live,
                        socialSidebar: !values.disableSocialSidebar,
                        leaderboard: !values.disableLeaderboard,
                    },
                }
                void updateProgramDetails({ variables })
            }}
            // Reset the form if the `initialValues` has changed, this is necessary for the auto-save to work properly
            // otherwise the auto-save won't trigger if the result is equal to the initial values after a few changes.
        >
            {(formik) => (
                <StyledCard>
                    <FormikAutoSave wait={200} />
                    <StyledH3>Program Details</StyledH3>
                    <hr />
                    <StyledBox css={{ display: "flex", gap: "24px" }}>
                        <StyledBox css={{ display: "flex", alignItems: "center" }}>
                            <ImageUpload
                                imageUrl={program.imageURL || undefined}
                                onImageUpload={(attachment) => updateImageGuid(attachment.guid)}
                                width={160}
                                height={160}
                                loading={updateImageGuidLoading}
                            />
                        </StyledBox>
                        <StyledInputsContainer>
                            <StyledRow css={{ flex: 1, gap: "12px" }}>
                                <Input
                                    key={StringFields.displayName}
                                    id={StringFields.displayName}
                                    name={StringFields.displayName}
                                    type={"text"}
                                    onChange={formik.handleChange}
                                    value={formik.values[StringFields.displayName] || ""}
                                    label={"Display name"}
                                    placeholder={"New program"}
                                    containerStyles={{ flex: 1 }}
                                />
                                <Input
                                    key={StringFields.name}
                                    id={StringFields.name}
                                    name={StringFields.name}
                                    type={"text"}
                                    onChange={formik.handleChange}
                                    value={formik.values[StringFields.name] || ""}
                                    label={"Name"}
                                    placeholder={"New program"}
                                    containerStyles={{ flex: 1 }}
                                />
                            </StyledRow>
                            <Input
                                key={StringFields.description}
                                id={StringFields.description}
                                name={StringFields.description}
                                type={"textArea"}
                                onChange={formik.handleChange}
                                value={formik.values[StringFields.description] || ""}
                                label={"Description"}
                                placeholder={"Description"}
                                minRows={5}
                                maxRows={5}
                            />
                        </StyledInputsContainer>
                        <StyledLastColumnContainer>
                            <StyledBox
                                css={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    gap: "18px",
                                    padding: "12px 0",
                                }}
                            >
                                <Checkbox
                                    selected={formik.values.live}
                                    onChange={() =>
                                        formik.setFieldValue(
                                            BooleanFields.live,
                                            !formik.values.live
                                        )
                                    }
                                    label={"Live"}
                                />
                                <Checkbox
                                    selected={formik.values.disableSocialSidebar}
                                    onChange={() =>
                                        formik.setFieldValue(
                                            BooleanFields.disableSocialSidebar,
                                            !formik.values.disableSocialSidebar
                                        )
                                    }
                                    label={"Disable Social Sidebar"}
                                />
                                <Checkbox
                                    selected={formik.values.disableLeaderboard}
                                    onChange={() =>
                                        formik.setFieldValue(
                                            BooleanFields.disableLeaderboard,
                                            !formik.values.disableLeaderboard
                                        )
                                    }
                                    label={"Disable leaderboard"}
                                />
                            </StyledBox>
                            <StyledCopyLinkButton onClick={copyProgramLink}>
                                <StyledLinkIcon />
                                <StyledSpan>Copy link to program</StyledSpan>
                            </StyledCopyLinkButton>
                        </StyledLastColumnContainer>
                    </StyledBox>
                </StyledCard>
            )}
        </Formik>
    )
}

export default ProgramDetailsCard
