import Close from "@mui/icons-material/Close"
import styled, { css } from "styled-components"
import {
    StyledBox,
    VerticalScrollableContentWithHiddenScroll,
} from "../../../styles/styledcomponents"
import { StyledModal } from "../../../components/Modal/styles"

export const StyledPreviewModal = styled(StyledModal)<{ mobile: boolean }>`
    &-content {
        box-sizing: border-box;
        cursor: default !important;
        padding: 24px 12px !important;
        min-width: unset !important;
        min-height: unset !important;
        height: 90vh;
        width: 624px !important;
        transition: width 0.3s ease-in;
        max-height: 95vh;

        ${({ mobile }) =>
            mobile &&
            css`
                height: 844px;
                width: 392px !important;
            `}
    }
`

export const StyledCloseIcon = styled(Close)`
    color: ${({ theme }) => theme.headlandsGray10};
    cursor: pointer;
`
