import styled from "styled-components"
import { StyledBox } from "../../styles/styledcomponents"

export const StyledCloseButtonContainer = styled(StyledBox)`
    display: flex;
    position: absolute;
    top: 0;
    right: -8px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    border-radius: 50%;
    width: 20px;
    height: 20px;
`
