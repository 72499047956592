import { StyledArrowBackIcon, StyledButtonText, StyledText, StyledTitle } from "./styles"
import Input from "../components/InputNew"
import { Button } from "../components/Button/Button"
import { BaseContentProps } from "./index"
import useLayout from "../hooks/layout.hook"

interface FirstNameContentProps extends BaseContentProps {
    email: string
    changeEmail: () => void
    firstName?: string
}

const FirstNameContent = ({
    handleSubmit,
    handleChange,
    email,
    changeEmail,
    firstName,
}: FirstNameContentProps) => {
    const { isMobile } = useLayout()
    return (
        <>
            {isMobile && <StyledArrowBackIcon onClick={changeEmail} />}
            <StyledTitle>Welcome</StyledTitle>
            <StyledText css={{ marginBottom: "24px" }}>
                We’ll sign you in or create an account if you don’t have one yet with
                <strong>{` ${email}`}</strong> as your email
            </StyledText>
            <form onSubmit={handleSubmit} autoComplete={"off"}>
                <Input
                    id={"firstName"}
                    name={"firstName"}
                    label={"First name"}
                    value={firstName}
                    onChange={handleChange}
                />
                <Button label={"Continue"} disabled={!firstName} type={"submit"} />
            </form>
            {!isMobile && (
                <>
                    <StyledText css={{ marginTop: "24px" }} disabled>
                        Wrong email?
                    </StyledText>
                    <StyledButtonText onClick={changeEmail}>Change it</StyledButtonText>
                </>
            )}
        </>
    )
}

export default FirstNameContent
