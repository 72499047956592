import { SvgIconComponent } from "@mui/icons-material"
import { useTheme } from "styled-components"
import { Button, ButtonProps } from "../../../components/Button/Button"
import { StyledBox, StyledSpan } from "../../../styles/styledcomponents"
import { StyledIconContainer, StyledNoContentContainer } from "./styles"

interface NoContentProps {
    icon: SvgIconComponent
    text: string
    buttonLabel: string
    onAdd: () => void
    buttonProps?: Partial<ButtonProps>
}

const NoContent = ({ icon, text, buttonLabel, onAdd, buttonProps }: NoContentProps) => {
    const Icon = icon

    const theme = useTheme()

    return (
        <StyledNoContentContainer>
            <StyledBox
                css={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 6,
                }}
            >
                <StyledIconContainer>
                    <Icon sx={{ color: theme.headlandsGray10 }} />
                </StyledIconContainer>
                <StyledSpan css={{ fontWeight: "bold", color: theme.headlandsGray1 }}>
                    {text}
                </StyledSpan>
            </StyledBox>
            <Button label={buttonLabel} onClick={onAdd} width={104} {...buttonProps} />
        </StyledNoContentContainer>
    )
}

export default NoContent
