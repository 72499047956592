import { useQuery } from "@apollo/client"
import { useHistory } from "react-router"
import { useTheme } from "styled-components"
import { ABOUT_ME } from "../apollo/queries"
import {
    StyledEditLink,
    StyledMobileSettings,
    StyledSettingContainer,
    StyledDesktopSettings,
    StyledButtonLabelWithIcon,
    StyledProfileContent,
} from "./styles"
import TopBar from "../components/TopBar"
import { StyledBody1, StyledBox, StyledGrid, StyledH1, StyledH4 } from "../styles/styledcomponents"
import EditIcon from "../assets/icons/EditIcon"
import ProfileMainCard from "./components/ProfileMainCard"
import ActivityList from "./components/ActivityList"
import useLayout from "../hooks/layout.hook"
import { Button } from "../components/Button/Button"
import { NavLink } from "react-router-dom"

const Settings = () => {
    const theme = useTheme()
    const history = useHistory()
    const { isMobile } = useLayout()

    const {
        client: { resetStore },
    } = useQuery(ABOUT_ME)

    const handleLogout = () => {
        resetStore().then()
        localStorage.removeItem("token")
        history.push("/auth")
    }

    if (isMobile) {
        return (
            <StyledMobileSettings>
                <TopBar>
                    <StyledGrid
                        css={{ flex: 1, gridTemplateColumns: "1fr 1fr 1fr", alignItems: "center" }}
                    >
                        <StyledBody1
                            css={{ color: theme.colors.base.uiLabelSubtitle }}
                            onClick={handleLogout}
                        >
                            Sign Out
                        </StyledBody1>
                        <StyledH4 useNewDesign css={{ justifySelf: "center" }}>
                            Profile
                        </StyledH4>
                        <StyledEditLink to={"/settings/edit"}>
                            <EditIcon color={theme.colors.base.uiBgIcon} />
                        </StyledEditLink>
                    </StyledGrid>
                </TopBar>
                <ProfileMainCard />
                <StyledProfileContent>
                    <StyledBox css={{ padding: "0 12px" }}>
                        <ActivityList />
                    </StyledBox>
                </StyledProfileContent>
            </StyledMobileSettings>
        )
    }

    return (
        <StyledDesktopSettings>
            <TopBar styles={{ justifyContent: "space-between" }}>
                <StyledH1 useNewDesign>Profile</StyledH1>
                <NavLink to={"/settings/edit"}>
                    <Button
                        label={
                            <StyledButtonLabelWithIcon>
                                <EditIcon color={theme.colors.base.uiBgBase} />
                                <span>Edit</span>
                            </StyledButtonLabelWithIcon>
                        }
                    />
                </NavLink>
            </TopBar>
            <StyledSettingContainer css={{ maxWidth: "417px", gap: "18px", alignItems: "center" }}>
                <ProfileMainCard />
                <ActivityList />
            </StyledSettingContainer>
            <Button
                label={"Log Out"}
                buttonStyle={{ width: "fit-content", margin: "18px 0" }}
                variant={"outline"}
            />
        </StyledDesktopSettings>
    )
}

export default Settings
