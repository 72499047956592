import { IconProps } from "../../types"
import { useTheme } from "styled-components"

const HomeIcon = ({ width = 24, height = 24, color }: IconProps) => {
    const theme = useTheme()

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="home-icon">
                <path
                    id="home-icon-path"
                    d="M6 18.1714H9V13.6C9 13.341 9.096 13.1237 9.288 12.9481C9.48 12.7726 9.71733 12.6851 10 12.6857H14C14.2833 12.6857 14.521 12.7735 14.713 12.949C14.905 13.1246 15.0007 13.3416 15 13.6V18.1714H18V9.94286L12 5.82857L6 9.94286V18.1714ZM4 18.1714V9.94286C4 9.65333 4.071 9.37905 4.213 9.12C4.355 8.86095 4.55067 8.64762 4.8 8.48L10.8 4.36571C11.15 4.1219 11.55 4 12 4C12.45 4 12.85 4.1219 13.2 4.36571L19.2 8.48C19.45 8.64762 19.646 8.86095 19.788 9.12C19.93 9.37905 20.0007 9.65333 20 9.94286V18.1714C20 18.6743 19.804 19.1049 19.412 19.4633C19.02 19.8217 18.5493 20.0006 18 20H14C13.7167 20 13.479 19.9122 13.287 19.7367C13.095 19.5611 12.9993 19.3441 13 19.0857V14.5143H11V19.0857C11 19.3448 10.904 19.5621 10.712 19.7376C10.52 19.9131 10.2827 20.0006 10 20H6C5.45 20 4.979 19.8208 4.587 19.4624C4.195 19.104 3.99934 18.6737 4 18.1714Z"
                    fill={color ?? theme.colors.base.uiBgIcon}
                />
            </g>
        </svg>
    )
}

export default HomeIcon
