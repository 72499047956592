import { useCurrentResponseThreadField, useCurrentThreadField } from "./currentThread.hook"
import { ThreadStatus } from "../apollo/generated/graphql"
import { useSelectedEnrolledProgramAttribute } from "./enrolledProgram.hook"
import { useMemo } from "react"

export const useShouldShowScoreHeader = (): boolean => {
    const threadHasScore = useCurrentThreadField("hasScore")
    const threadStatus = useCurrentResponseThreadField("status")
    const { attribute: shouldShowSocialBar } = useSelectedEnrolledProgramAttribute("socialSidebar")

    const showScoreHeader = useMemo(
        () =>
            !!threadHasScore &&
            (threadStatus === ThreadStatus.Unlocked || threadStatus === ThreadStatus.InProgress) &&
            !!shouldShowSocialBar,
        [threadHasScore, threadStatus, shouldShowSocialBar]
    )

    return showScoreHeader
}
