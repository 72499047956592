import styled, { css } from "styled-components"
import {
    StyledBox,
    StyledH4,
    StyledSpan,
    VerticalScrollableContentWithHiddenScroll,
} from "../../../styles/styledcomponents"
import { StyledBubble } from "../../../components/ThreadPreview/styles"

export const AI_POPUP_WIDTH = 512
export const AI_POPUP_CONTENT_HEIGHT = 260
export const AI_POPUP_PADDING_TOP = 12

export const StyledAiPopup = styled(StyledBox)<{
    parentHeight: number
    parentWidth?: number
    position: "bottom" | "top"
    marginTop?: number
    marginBottom?: number
}>`
    position: absolute;
    right: 0;
    ${({ parentWidth }) =>
        parentWidth &&
        css`
            left: calc(
                0.5 * (${parentWidth}px - ${AI_POPUP_WIDTH}px) +
                    (0.5 * ${({ theme }) => theme.variables.creator.iconRowWidth})
            );
        `};
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: ${AI_POPUP_PADDING_TOP}px;
    width: ${AI_POPUP_WIDTH}px;
    gap: 12px;
    cursor: default;
    background-color: ${({ theme }) => theme.headlandsWhite};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: ${({ theme }) => theme.borderRadius1};

    /* leave an empty top space on absolute popup*/
    ${({ marginTop }) =>
        marginTop &&
        css<{ marginTop?: number }>`
            :after {
                position: absolute;
                content: "";
                top: -${({ marginTop }) => marginTop}px;
                height: ${({ marginTop }) => marginTop}px;
                width: 1px;
            }
        `}

    /* leave an empty bottom space on absolute popup*/
    ${({ marginBottom }) =>
        marginBottom &&
        css<{ marginBottom?: number }>`
            :after {
                position: absolute;
                content: "";
                bottom: -${({ marginBottom }) => marginBottom}px;
                height: ${({ marginBottom }) => marginBottom}px;
                width: 1px;
            }
        `}
    

    ${({ position, parentHeight }) =>
        position === "bottom"
            ? css`
                  /* We add 6px for separtion from the block */
                  top: ${parentHeight + 6}px;
              `
            : css`
                  bottom: ${parentHeight}px;
              `}
`

export const StyledTitle = styled(StyledH4)`
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.headlandsAiColor};
`

export const StyledContent = styled(StyledBox)<{ error?: boolean }>`
    ${VerticalScrollableContentWithHiddenScroll};
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: ${AI_POPUP_CONTENT_HEIGHT}px;
    max-height: ${AI_POPUP_CONTENT_HEIGHT}px;
    text-align: left;

    // this props will overwrite the default ones from thread preview
    min-width: unset;
    max-width: unset;
    padding: unset;

    ${StyledBubble} {
        animation: unset;
    }

    .left-animation {
        animation: unset !important;
    }

    .right-animation {
        animation: unset !important;
    }

    ${({ error }) =>
        error &&
        css`
            justify-content: center;
            align-items: center;
        `}
`

export const StyledErrorMessage = styled(StyledSpan)`
    color: ${({ theme }) => theme.headlandsError};
`
