import ReactSelect, { components, GroupBase, MultiValue, Props, SingleValue } from "react-select"
import { useTheme } from "styled-components"
import { StyledChevron } from "./styles"

const Select = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    props: Props<Option, IsMulti, Group>
) => {
    const theme = useTheme()

    const { styles, components, ...rest } = props

    return (
        <ReactSelect
            className={"react-select"}
            classNamePrefix={"react-select"}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
                ...components,
            }}
            styles={{
                container: (provided, state) => ({
                    ...provided,
                    flex: 1,
                    ...styles?.container?.(provided, state),
                }),
                control: (provided, state) => ({
                    ...provided,
                    padding: "4px 12px",
                    backgroundColor: theme.headlandsWhite,
                    borderRadius: state.menuIsOpen ? "16px 16px 0 0" : 16,
                    boxShadow: "inset 0px 2px 1px rgba(0, 0, 0, 0.05)",
                    border: `1px solid ${theme.headlandsGray3} !important`,
                    outline: "none",
                    transition: "border-radius 0.2s",
                    margin: "0 3px",
                    minHeight: 32,
                    opacity: state.isDisabled ? 0.3 : 1,
                    ...styles?.control?.(provided, state),
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "unset",
                    transition: "transform 0.3s",
                    padding: 0,
                    ...styles?.dropdownIndicator?.(provided, state),
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    padding: 0,
                    ...styles?.valueContainer?.(provided, state),
                }),
                singleValue: (provided, state) => ({
                    ...provided,
                    fontSize: 14,
                    color: theme.headlandsGray2,
                    ...styles?.singleValue?.(provided, state),
                }),
                multiValueLabel: (provided, state) => ({
                    ...provided,
                    fontSize: 14,
                    color: theme.headlandsGray2,
                    ...styles?.multiValueLabel?.(provided, state),
                }),
                input: (provided, state) => ({
                    ...provided,
                    padding: 0,
                    fontSize: 14,
                    color: theme.headlandsGray2,
                    ...styles?.input?.(provided, state),
                }),
                menu: (provided, state) => ({
                    ...provided,
                    margin: "-1px 0 0",
                    left: 3,
                    border: `1px solid ${theme.headlandsGray3}`,
                    borderRadius: `0 0 ${theme.borderRadius1} ${theme.borderRadius1}`,
                    boxShadow: "none",
                    padding: 6,
                    width: "calc(100% - 6px)",
                    ...styles?.menu?.(provided, state),
                }),
                menuList: (provided, state) => ({
                    ...provided,
                    padding: 0,
                    ...styles?.menuList?.(provided, state),
                }),
                option: (provided, state) => ({
                    ...provided,
                    padding: 6,
                    borderRadius: "8px",
                    color: theme.headlandsGray2,
                    fontSize: 14,
                    backgroundColor: state.isSelected
                        ? theme.headlandsWhite
                        : state.isFocused
                        ? theme.headlandsGray4
                        : provided.backgroundColor,
                    "&:hover": {
                        backgroundColor: theme.headlandsGray4,
                        color: theme.headlandsGray2,
                    },
                    ...styles?.option?.(provided, state),
                }),
            }}
            {...rest}
        />
    )
}

const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
        <StyledChevron />
    </components.DropdownIndicator>
)

/*
 * Useful function to check whether the selected option is a multiple one or not
 * */
export function isMultiValue<T>(arg: MultiValue<T> | SingleValue<T>): arg is MultiValue<T> {
    return Array.isArray(arg)
}

export default Select
