import { gql } from "./generated"

export const AI_GENERATE_QUESTION = gql(/* GraphQL */ `
    subscription aiGenerateQuestion($specs: AIGenerateQuestionInput!) {
        aiGenerateQuestion(specs: $specs) {
            guid
            object
            parentGUID
            index
            threadId
            threadGUID
            threadVersionId
        }
    }
`)

export const AI_CONTINUE_WRITING = gql(/* GraphQL */ `
    subscription aiContinueWriting($specs: AIContinueWritingInput!) {
        aiContinueWriting(specs: $specs) {
            guid
            object
            parentGUID
            index
            threadId
            threadGUID
            threadVersionId
        }
    }
`)

export const HANDLE_USER_INPUT = gql(/* GraphQL */ `
    subscription handleUserInput($input: UserInput) {
        handleUserInput(input: $input) {
            correct
            correctness
            content
            percentComplete
            score {
                basePoints
                bonusPoints
                points
            }
            choiceData {
                id
                count
                total
                percent
                correct
            }
        }
    }
`)

export const INDEX_CONTENT_SOURCE = gql(/* GraphQL */ `
    subscription indexContentSource($url: ContentSourceInput!) {
        indexContentSource(url: $url) {
            progress
            state
            error
        }
    }
`)
