import { ThreadStatus } from "../../types"
import {
    StyledBox,
    StyledChevron,
    StyledColumn,
    StyledH2,
    StyledH3,
    StyledHr,
    StyledSpan,
} from "../../styles/styledcomponents"
import {
    StyledLibraryBar,
    StyledLibraryBarContent,
    StyledLibraryBarHeader,
    StyledLibraryCollapsableSectionContent,
    StyledLibraryCollapsableSectionHeader,
    StyledLibraryTopBar,
    StyledScrollableContainer,
} from "./styles"
import SearchBar from "../../deliver/components/SearchBar"
import { useEffect, useMemo, useRef, useState } from "react"
import { debounce, first, isEmpty } from "lodash"
import LibraryRow, { LibraryRowSkeletonLoader, LibraryRowType } from "./LibraryRow"
import {
    useEnrolledPrograms,
    useEnrolledProgramsIdOnly,
    useSelectedProgramGuid,
} from "../../hooks/enrolledProgram.hook"
import PlaylistBar from "./PlaylistBar"
import useLayout from "../../hooks/layout.hook"
import { useTheme } from "styled-components"
import { EnrolledProgram, User } from "../../apollo/generated/graphql"
import { Maybe } from "graphql/jsutils/Maybe"
import { useHistory } from "react-router"
import TopBar from "../TopBar"

export interface LibraryItem {
    type: LibraryRowType
    title: string
    subtitle: string
    status: ThreadStatus | string
    progress?: number
    imageSrc?: Maybe<string>
    programGuid?: string
    threadGuid?: Maybe<string>
    isASingleThreadProgram?: boolean
    // note: again this is not optimal but the reset thread query needs the ids instead of the guids
    programId?: number
    threadId?: Maybe<number>
    instructor?: Pick<User, "profileImageURL" | "firstName" | "lastName">
    onClick?: () => void
}

const mapEnrolledProgram = (enrolledProgram: EnrolledProgram): LibraryItem => {
    return {
        isASingleThreadProgram: enrolledProgram.isSingleShareThread,
        programGuid: enrolledProgram.guid,
        threadGuid: enrolledProgram.firstThreadGUID,
        programId: enrolledProgram.id,
        threadId: enrolledProgram.firstThreadId,
        type: LibraryRowType.playlist,
        title: enrolledProgram.name,
        subtitle: enrolledProgram.description ?? "",
        status: enrolledProgram.progress.status,
        progress: Math.round(enrolledProgram.progress.percentComplete),
        imageSrc: enrolledProgram.imageURL,
    }
}
const LibraryBar = () => {
    const [selectedPlaylist, setSelectedPlaylist] = useState<LibraryItem | undefined>()
    const [filteredLibraryItems, setFilteredLibraryItems] = useState<LibraryItem[]>([])
    const { enrolledProgramsIds } = useEnrolledProgramsIdOnly()
    const { enrolledPrograms, loading: enrolledProgramsLoading } = useEnrolledPrograms({
        skip: !enrolledProgramsIds,
    })
    const currentProgramGuid = useSelectedProgramGuid()
    const { isMobile } = useLayout()

    const history = useHistory()

    const allLibraryItems = useMemo(
        () => enrolledPrograms?.map(mapEnrolledProgram),
        [enrolledPrograms]
    )
    const inProgressItems = useMemo(
        () => filteredLibraryItems.filter((item) => item.status === ThreadStatus.IN_PROGRESS),
        [filteredLibraryItems]
    )
    const completedItems = useMemo(
        () => filteredLibraryItems.filter((item) => item.status === ThreadStatus.COMPLETED),
        [filteredLibraryItems]
    )
    const savedItems: LibraryItem[] = []

    const navigateToProgram = (programGuid: string) => {
        if (isMobile) {
            history.push(`/program/${programGuid}`)
        } else {
            history.push(`/catalog/${programGuid}`)
        }
    }

    const navigateToProgramThread = (programGuid: string, threadGuid: string) => {
        if (isMobile) {
            history.push(`/program/${programGuid}/thread/${threadGuid}`)
        } else {
            history.push(`/catalog/${programGuid}/${threadGuid}`)
        }
    }

    useEffect(() => {
        const firstProgram = first(enrolledProgramsIds)
        if (enrolledProgramsIds?.length === 1 && !currentProgramGuid && firstProgram) {
            navigateToProgram(firstProgram.guid)
        }
    }, [enrolledProgramsIds, history, currentProgramGuid])

    useEffect(() => {
        if (allLibraryItems) {
            setFilteredLibraryItems(allLibraryItems)
        }
    }, [allLibraryItems, selectedPlaylist])

    /*
     * When the `currentProgramGuid` changes, select the correct playlist
     * */
    useEffect(() => {
        if (currentProgramGuid) {
            const playlist = allLibraryItems?.find(
                (libraryItem) => libraryItem.programGuid === currentProgramGuid
            )
            if (playlist) setSelectedPlaylist(playlist)
        }
    }, [currentProgramGuid, allLibraryItems])

    /*
     * When the `currentProgramGuid` is remove from the url and there is a selected playlist
     * delete the selected playlist
     * */
    useEffect(() => {
        if (!currentProgramGuid && selectedPlaylist) {
            setSelectedPlaylist(undefined)
        }
    }, [currentProgramGuid, selectedPlaylist])

    /*
     * Todo: integrate search with BE once ready
     * */
    const debouncedSearch = debounce((search: string) => {
        if (allLibraryItems) {
            if (search === "") setFilteredLibraryItems(allLibraryItems)
            else {
                setFilteredLibraryItems(
                    allLibraryItems.filter(
                        (programMapped) =>
                            programMapped.title.toLowerCase().includes(search.toLowerCase()) ||
                            programMapped.subtitle?.toLowerCase().includes(search.toLowerCase())
                    )
                )
            }
        }
    }, 300)

    const renderContent = (items?: LibraryItem[], emptyContentMessage?: string) => {
        if (enrolledProgramsLoading) {
            return (
                <StyledBox>
                    <LibraryRowSkeletonLoader type={LibraryRowType.thread} />
                    <LibraryRowSkeletonLoader type={LibraryRowType.playlist} />
                    <LibraryRowSkeletonLoader type={LibraryRowType.thread} />
                </StyledBox>
            )
        } else {
            return (
                <StyledColumn css={{ gap: "6px" }}>
                    {items?.map((item, index) => (
                        <LibraryRow
                            key={`library-item-${item.type}-${index}}`}
                            {...item}
                            onClick={() => {
                                if (
                                    (item.type === LibraryRowType.thread ||
                                        item.isASingleThreadProgram) &&
                                    item.programGuid &&
                                    item.threadGuid
                                ) {
                                    navigateToProgramThread(item.programGuid, item.threadGuid)
                                } else if (
                                    item.type === LibraryRowType.playlist &&
                                    item.programGuid
                                ) {
                                    navigateToProgram(item.programGuid)
                                }
                            }}
                        />
                    ))}
                    {isEmpty(items) && emptyContentMessage && (
                        <StyledSpan css={{ padding: "16px 0" }}>{emptyContentMessage}</StyledSpan>
                    )}
                </StyledColumn>
            )
        }
    }

    return (
        <StyledLibraryBar mobile={isMobile}>
            {/* If there's no programs data yet, dont render anything */}
            {enrolledPrograms?.length ? (
                // If there's a program selected render PlaylistBar
                !!currentProgramGuid && !selectedPlaylist?.isASingleThreadProgram ? (
                    <PlaylistBar
                        playlist={selectedPlaylist}
                        clearSelectedPlaylist={() => setSelectedPlaylist(undefined)}
                    />
                ) : (
                    // If there's no program selected render LibraryBar
                    <StyledBox>
                        <StyledLibraryBarContent>
                            {isMobile ? (
                                <TopBar />
                            ) : (
                                <>
                                    <StyledLibraryTopBar isMobile={false} />
                                    <StyledLibraryBarHeader>
                                        <StyledH2 useNewDesign>My Library</StyledH2>
                                        <SearchBar
                                            placeholder={"Threads, Playlists, Keywords, Topics"}
                                            onChange={debouncedSearch}
                                            availableFilters={[
                                                { id: "playlists", label: "Playlists" },
                                                { id: "threads", label: "Threads" },
                                            ]}
                                            // todo integrate once ready
                                            onFilterChange={(filters) => {}}
                                        />
                                    </StyledLibraryBarHeader>
                                </>
                            )}
                            <StyledScrollableContainer isMobile={isMobile}>
                                <LibraryCollapsableSection
                                    title={"In Progress"}
                                    content={renderContent(
                                        inProgressItems,
                                        "You haven’t started any threads or playlists yet"
                                    )}
                                />
                                <LibraryCollapsableSection
                                    title={"Completed"}
                                    content={renderContent(
                                        completedItems,
                                        "You haven’t completed any threads or playlists yet"
                                    )}
                                />
                                <LibraryCollapsableSection
                                    title={"Saved"}
                                    content={renderContent(
                                        savedItems,
                                        "You haven’t saved any threads or playlists yet"
                                    )}
                                />
                            </StyledScrollableContainer>
                        </StyledLibraryBarContent>
                    </StyledBox>
                )
            ) : null}
        </StyledLibraryBar>
    )
}

interface LibraryCollapsableSectionProps {
    title: string
    content: JSX.Element
}

const LibraryCollapsableSection = ({ title, content }: LibraryCollapsableSectionProps) => {
    const [open, setOpen] = useState(true)
    const [contentHeight, setContentHeight] = useState(0)
    const contentRef = useRef<HTMLDivElement>(null)

    const theme = useTheme()

    /*
     * We use this calculation to get content total height,
     * since it's needed to make the collapse transition work
     */
    useEffect(() => {
        if (contentRef.current && content) {
            const childHeight = Array.from(contentRef.current.children).reduce(
                (height, child) => height + child.clientHeight,
                0
            )
            setContentHeight(childHeight)
        }
    }, [content])

    return (
        <StyledColumn css={{ gap: "12px" }}>
            <StyledLibraryCollapsableSectionHeader>
                <StyledH3 useNewDesign>{title}</StyledH3>
                <StyledChevron open={open} onClick={() => setOpen(!open)} />
            </StyledLibraryCollapsableSectionHeader>
            <StyledLibraryCollapsableSectionContent
                open={open}
                ref={contentRef}
                height={contentHeight}
            >
                {content}
                <StyledHr css={{ borderBottom: `1px solid ${theme.colors.base.uiBgBorder}` }} />
            </StyledLibraryCollapsableSectionContent>
        </StyledColumn>
    )
}

export default LibraryBar
