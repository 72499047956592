import styled, { css } from "styled-components"
import { StyledBreakpointButton, StyledChevron } from "../../BreakpointButton/styles"
import { StyledRightBubble } from "../styles"

export const StyledBreakpointBubble = styled(StyledRightBubble)<{
    isMobile?: boolean
    hasText?: boolean
}>`
    min-height: 40px;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: unset;
    width: fill-available;
    position: relative;

    span {
        color: ${({ theme }) => theme.headlandsGray3};
        user-select: none;
    }

    ${({ isMobile }) =>
        isMobile &&
        css`
            ${StyledBreakpointButton} {
                width: 42px;
                height: 42px;
                box-sizing: border-box;

                ${StyledChevron} {
                    font-size: 40px;
                }
            }
        `}

    ${({ hasText }) =>
        hasText &&
        css`
            ${StyledBreakpointButton} {
                width: fit-content;
            }
        `}
`
