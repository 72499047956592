import styled, { css } from "styled-components"
import { SidebarToggleVariant } from "./index"

type StyledSidebarToggleProps = {
    variant: SidebarToggleVariant
    top: string
    left: string | null
    right: string | null
    size?: string
    isMobile?: boolean
    enabledTransition?: boolean
    dark?: boolean
    withBorder?: boolean
}
export const StyledSidebarToggle = styled.div<StyledSidebarToggleProps>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${({ size, theme }) => size ?? theme.variables.common.fabIconWidth};
    height: ${({ size, theme }) => size ?? theme.variables.common.fabIconHeight};

    border-radius: 50%;
    box-sizing: border-box;

    cursor: pointer;
    transition: ${({ enabledTransition }) =>
        enabledTransition && "z-index 0s linear 0.105s, left ease-out 0.3s, right ease-out 0.3s"};

    top: ${({ top }) => top};
    left: ${({ left }) => left ?? "unset"};
    right: ${({ right }) => right ?? "unset"};

    background-color: ${({ theme }) => theme.colors.base.uiBgBase};

    @media (hover: hover) {
        &:hover {
            transition: all 0.3s ease-out 0s !important;
            path {
                fill: ${({ theme }) => theme.colors.base.uiPrimary500};
            }
        }
    }

    ${({ isMobile }) =>
        isMobile &&
        css`
            position: fixed;
            background-color: transparent;
        `}

    ${({ variant }) =>
        variant === SidebarToggleVariant.outer &&
        css`
            z-index: 3;
        `}

    ${({ dark }) =>
        dark &&
        css`
            background-color: transparent;
            border-color: ${({ theme }) => theme.colors.base.uiBgBase};
            path {
                fill: ${({ theme }) => theme.colors.base.uiBgBase};
            }
        `}

        ${({ withBorder }) =>
        withBorder &&
        css`
            border: solid 1px ${({ theme }) => theme.headlandsGray7};
        `}
`
