import styled, { css } from "styled-components"
import {
    HorizontalScrollableContentWithHiddenScroll,
    StyledBox,
    StyledRow,
} from "../../styles/styledcomponents"

export const StyledCarouselContent = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    gap: 6px;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    ${HorizontalScrollableContentWithHiddenScroll}
`
export const StyledButtonArrow = styled.button<{ disabled: boolean }>`
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    opacity: ${(props) => (props.disabled ? ".5" : "1")};
    align-items: center;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    border-radius: ${({ theme }) => theme.borderRadius1};
    border-style: none;
    margin-left: 6px;

    svg {
        font-size: 32px !important;
        path {
            fill: ${({ theme }) => theme.colors.base.uiBgIcon};
        }
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.base.uiBgHover};
    }
`

export const StyledCarouselArrowsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 6px 0 3px 0;
`

export const StyledPaginationContainer = styled(StyledRow)`
    margin-top: 6px;
    gap: 2px;
    justify-content: flex-end;
`

export const StyledPaginationItem = styled(StyledBox)`
    border-radius: 50%;
    width: 8px;
    height: 8px;
    border: 1px solid ${({ theme }) => theme.headlandsGray10};
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const StyledPaginationIndicator = styled(StyledBox)`
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.headlandsGray10};
`
