import styled from "styled-components"
import {
    StyledBox,
    StyledColumn,
    StyledH4,
    VerticalScrollableContentWithHiddenScroll,
} from "../styles/styledcomponents"
import { NavLink } from "react-router-dom"
import { ArrowBack } from "@mui/icons-material"
import { StyledTopBar } from "../components/TopBar/styles"
import { StyledAvatar } from "../creator/components/UserAvatar/styles"

export const StyledMobileSettings = styled(StyledColumn)`
    flex: 1;
    margin-top: ${({ theme }) => theme.variables.common.topBarHeight};
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
    gap: 12px;

    input {
        background-color: transparent;
        color: ${({ theme }) => theme.headlandsGray2};

        &.disabled {
            background-color: ${({ theme }) => theme.headlandsGray4};
            opacity: unset;
        }
    }
`

export const StyledEditLink = styled(NavLink)`
    justify-self: flex-end;
`

export const StyledBackArrow = styled(ArrowBack)`
    position: absolute;
    left: 18px;
    color: ${({ theme }) => theme.colors.base.uiBgIcon};
`

export const StyledActivityListContainer = styled(StyledColumn)`
    width: 100%;
    gap: 6px;

    ${StyledH4} {
        color: ${({ theme }) => theme.colors.base.uiPrimary500};
    }
`

export const StyledProfileContent = styled(StyledColumn)`
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
    padding-bottom: calc(${({ theme }) => theme.variables.common.bottomNavbarHeight} + 12px);
    gap: 6px;
`

export const StyledMobileFormContainer = styled(StyledColumn)`
    padding: 12px;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border-radius: 16px;
    margin: 0 12px;
`

export const StyledSettingContainer = styled(StyledColumn)`
    ${VerticalScrollableContentWithHiddenScroll};
`

export const StyledDesktopSettings = styled(StyledBox)`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    height: inherit;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
    min-height: 0;
    overflow: hidden;

    ${StyledProfileContent} {
        padding-bottom: unset;
    }

    form {
        width: 100%;
    }

    ${StyledTopBar} {
        a {
            text-decoration: none;
        }
    }

    ${StyledAvatar} {
        &:hover {
            cursor: pointer;
        }
    }
`

export const StyledVerticalScrollableContainer = styled(StyledBox)`
    ${VerticalScrollableContentWithHiddenScroll};

    input {
        background-color: transparent;
        color: ${({ theme }) => theme.headlandsGray2};

        &.disabled {
            background-color: ${({ theme }) => theme.headlandsGray4};
            opacity: unset;
        }
    }
`

export const StyledButtonLabelWithIcon = styled(StyledBox)`
    display: flex;
    gap: 3px;
    align-items: center;

    svg {
        width: 18px;
        height: 18px;
    }

    span {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        font-style: normal;
        color: ${({ theme }) => theme.colors.base.uiBgBase};
    }
`
