import styled from "styled-components"
import { lighten } from "polished"

export const BlockCommentContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background-color: ${({ theme }) => lighten(0.25, theme.colors.base.uiWarning500)};
    border-radius: 0 8px 8px 0;
    transition: background-color 0.2s;
    border-left: 4px solid ${({ theme }) => theme.colors.base.uiWarning500};
`
