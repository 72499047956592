import styled, { css } from "styled-components"
import {
    StyledBox,
    StyledChevron,
    StyledColumn,
    StyledH2,
    StyledH3,
    StyledH4,
    StyledH4Styles,
    StyledImage,
    VerticalScrollableContentWithHiddenScroll,
} from "../../styles/styledcomponents"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { StyledEmptyTopBarHeight } from "../TopBar/styles"
import { StyledSearchBar } from "../../deliver/components/SearchBar/styles"

export const StyledArrowBackIcon = styled(ArrowBackIcon)`
    color: ${({ theme }) => theme.headlandsGray10};
    justify-self: flex-start;

    &:hover {
        cursor: pointer;
    }
`

export const StyledPlaylistHeader = styled(StyledColumn)`
    align-self: stretch;
    align-items: center;
    position: relative;
    border-radius: ${({ theme }) => theme.borderRadius1};
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    padding: 24px 12px 12px;
    gap: 12px;

    img {
        border-radius: ${({ theme }) => theme.borderRadius1};
        object-fit: cover;
    }

    ${StyledH4} {
        &:hover {
            cursor: pointer;
        }
    }
`

export const StyledLibraryBarContent = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(${({ theme }) => theme.sidebarWidths.library} * 1px);
    height: 100%;
    background-color: white;
    position: relative;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};

    ${StyledH3} {
        color: ${({ theme }) => theme.headlandsGray1};
    }
`

export const StyledLibraryBar = styled.div<{ mobile?: boolean }>`
    display: flex;
    height: 100%;
    width: calc(${({ theme }) => theme.sidebarWidths.library} * 1px);
    border-right: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};

    ${({ mobile }) =>
        mobile
            ? css`
                  width: 100vw;
                  border: none;
                  ${StyledLibraryBarContent} {
                      width: 100vw;
                  }
              `
            : css`
                  overflow-x: hidden;
              `}
`

export const StyledLibraryBarHeader = styled(StyledColumn)<{ isMobile?: boolean }>`
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border-bottom: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    gap: 18px;
    padding: ${({ theme }) => `18px
          ${theme.variables.program.libraryBarPadding.right}
          18px
          ${theme.variables.program.libraryBarPadding.left}
    `};
    z-index: 1;

    ${StyledH2} {
        color: ${({ theme }) => theme.colors.base.uiLabelTitle};
    }

    ${StyledSearchBar} {
        padding-right: 7px;
        margin: 0;
    }

    ${({ isMobile }) =>
        isMobile &&
        css`
            position: fixed;
            width: 100vw;
            box-sizing: border-box;
            top: 0;
            left: 0;
        `}
`

interface StyledLibraryRowProps {
    selected?: boolean
}

export const StyledLibraryRow = styled(StyledColumn)<StyledLibraryRowProps>`
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 12px 12px 6px;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    min-height: 112px;
    max-height: 112px;
    box-sizing: border-box;
    justify-content: space-between;
    transition: background-color 0.1s;
    gap: 16px;

    /* We don't want the hover state on mobile */
    @media (hover: hover) {
        &:hover {
            cursor: pointer;
            background-color: ${({ theme }) => theme.colors.base.uiBgThreadActive};
        }
    }

    // EllipsisText component uses a plain h4 tag so we apply our styles this way
    h4 {
        ${StyledH4Styles}
    }

    ${({ selected, theme }) =>
        selected &&
        css`
            background-color: ${({ theme }) => theme.colors.base.uiBgThreadActive};
        `}
`

export const StyledLibraryRowImage = styled(StyledImage)`
    height: 60px;
    width: 90px;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 8px;
`

export const StyledLibraryCollapsableSectionHeader = styled(StyledBox)`
    display: flex;
    justify-content: space-between;

    ${StyledChevron} {
        color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
        &:hover {
            cursor: pointer;
        }
    }

    ${StyledH3} {
        color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
    }
`

export const StyledLibraryCollapsableSectionContent = styled(StyledBox)<{
    open: boolean
    height?: number
}>`
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    transition: height 0.3s;

    ${({ height }) =>
        height &&
        css`
            height: ${height}px;
        `};

    ${({ open }) =>
        !open &&
        css`
            height: 0;
        `};
`

export const StyledMenuIcon = styled(MoreHorizIcon)`
    font-size: 18px !important;
    color: ${({ theme }) => theme.colors.base.uiBgIcon};

    &:hover {
        cursor: pointer;
    }
`

export const StyledScrollableContainer = styled(StyledColumn)<{
    isMobile?: boolean
}>`
    flex-grow: 1;
    gap: 18px;
    padding: ${({ theme }) => `12px ${theme.variables.program.libraryBarPadding.right}`};
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};

    ${({ isMobile }) =>
        !isMobile
            ? css`
                  ${VerticalScrollableContentWithHiddenScroll};
              `
            : css`
                  // This margin accounts for the height of the fixed-positioned mobile top-bar
                  padding-top: ${({ theme }) =>
                      `calc(${theme.variables.common.topBarHeight} + 12px)`};
                  // This margin accounts for the height of the fixed-positioned mobile bottom nav
                  padding-bottom: calc(12px + 60px);
              `}
`

export const StyledLibraryTopBar = styled(StyledEmptyTopBarHeight)<{ isMobile?: boolean }>`
    display: flex;
    align-items: center;
    padding: ${({ theme }) => `0 ${theme.variables.program.libraryBarPadding.right}`};
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    box-sizing: border-box;

    ${({ isMobile }) =>
        isMobile &&
        css`
            border-bottom: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
        `};
`

export const RightAnimatedColumn = styled.div<{ show?: boolean; disableAnimation?: boolean }>`
    transition: ${({ disableAnimation }) =>
        !disableAnimation ? "transform cubic-bezier(0.2, 0.8, 0.2, 1) 0.6s" : "unset"};
    transform: ${({ show }) => (show ? "translateX(-100%)" : "translateX(0)")};

    ${StyledArrowBackIcon} {
        opacity: ${({ show }) => (show ? 1 : 0)};
        transition: ${({ disableAnimation }) => (!disableAnimation ? "opacity 0.6s" : "unset")};
    }
`
