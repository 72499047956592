import styled from "styled-components"

const POPUP_PADDING = 36
const EDITOR_CONTENT_WIDTH = 1120
const EDITOR_CONTENT_HEIGHT = 596
// The space occupy by the cross icon and the done and cancel button
const BUTTONS_CONTAINER_HEIGHT = 44

const POPUP_MAX_WIDTH = EDITOR_CONTENT_WIDTH + 2 * POPUP_PADDING
const POPUP_MAX_HEIGHT = EDITOR_CONTENT_HEIGHT + 2 * POPUP_PADDING + BUTTONS_CONTAINER_HEIGHT

export const PopupContentStyle = {
    width: "90%",
    maxWidth: POPUP_MAX_WIDTH,
    height: "100%",
    maxHeight: POPUP_MAX_HEIGHT,
}

export const StyledButtonsContainer = styled.div`
    display: flex;
    align-self: flex-end;
    .close-icon {
        color: ${(props) => `${props.theme.headlandsGray10}`};
        :hover {
            cursor: pointer;
        }
    }
    button:first-of-type {
        margin-right: 6px;
    }
`
