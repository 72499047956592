import { StyledLibraryRow, StyledLibraryRowImage } from "./styles"
import { ThreadStatus } from "../../types"
import { StyledBody2, StyledBox, StyledColumn, StyledRow } from "../../styles/styledcomponents"
import { LockedIcon } from "../../assets/icons/LockedIcon"
import { ExpiredIcon } from "../../assets/icons/ExpiredIcon"
import ProgressCircle from "../../common/components/ProgressCircle/ProgressCircle"
import { CompletedIcon } from "../../assets/icons/CompletedIcon"
import { useTheme } from "styled-components"
import {
    SkeletonHeadlands,
    StyledRightContainerBox,
} from "../../common/components/LoaderSkeleton/styles"
import { StyledProgressPopup } from "../UserRow/styles"
import { useMemo, MouseEvent } from "react"
import { useUserPermissions } from "../../hooks/userPermissions"
import { StyledCacheIcon } from "../../common/components/ThreadRow/styles"
import EllipsisText from "../EllipsisText"
import { useHistory } from "react-router-dom"
import useLayout from "../../hooks/layout.hook"
import { useSidebars } from "../../hooks/sidebars.hook"
import { SidebarType } from "../../redux/layout"
import { useSelectedProgramGuid } from "../../hooks/enrolledProgram.hook"
import { useCurrentThreadGuid } from "../../hooks/currentThread.hook"
import { useMutation } from "@apollo/client"
import { RESET_THREAD } from "../../apollo/mutations"
import { LibraryItem } from "./LibraryBar"
import { getCustomImageUrl } from "../../utils/utils"
import { ArrowForward } from "@mui/icons-material"
import { useResizeDetector } from "react-resize-detector"

export enum LibraryRowType {
    playlist,
    thread,
}

const LibraryRow = ({
    type,
    imageSrc,
    title,
    subtitle,
    status,
    progress,
    programGuid,
    threadGuid,
    threadId,
    programId,
    isASingleThreadProgram,
    onClick,
}: LibraryItem) => {
    const theme = useTheme()
    const history = useHistory()
    const { isMobile } = useLayout()
    const { closeSidebars, setSidebarAnimation } = useSidebars()
    const roundedProgress = Math.round(progress || 0)
    const {
        permissions: { maySeeExperimentalFeatures },
    } = useUserPermissions()
    const currentProgramGuid = useSelectedProgramGuid()
    const currentThreadGuid = useCurrentThreadGuid()

    const { height: titleHeight, ref: titleContainerRef } = useResizeDetector()

    const titleIsMultiLine = titleHeight && titleHeight > 20

    const [resetThread] = useMutation(RESET_THREAD, {
        onCompleted: () => {
            window.location.reload()
        },
    })

    // This boolean helps to treat 'single thread program' as a thread instead of a program.
    const isAThread = useMemo(
        () => type === LibraryRowType.thread || isASingleThreadProgram,
        [type, isASingleThreadProgram]
    )

    const isSelected = useMemo(() => {
        if (isAThread) return !!threadGuid && currentThreadGuid === threadGuid
        else return !!programGuid && currentProgramGuid === programGuid
    }, [isAThread, currentThreadGuid, currentProgramGuid])

    const resizedImageSrc = useMemo(
        () =>
            imageSrc &&
            getCustomImageUrl(imageSrc, {
                width: 90,
                height: 60,
                webp: true,
                crop: "center",
            }),
        [imageSrc]
    )

    const renderIcon = () => {
        switch (status) {
            case ThreadStatus.LOCKED:
                return <LockedIcon color={theme.colors.base.uiBgIcon} />
            case ThreadStatus.EXPIRED:
                return <ExpiredIcon color={theme.colors.base.uiBgIcon} />
            case ThreadStatus.IN_PROGRESS:
                return (
                    <StyledProgressPopup
                        position={["right center"]}
                        on={"hover"}
                        arrow={false}
                        mouseEnterDelay={300}
                        mouseLeaveDelay={0}
                        trigger={
                            <div>
                                <ProgressCircle progress={roundedProgress} />
                            </div>
                        }
                    >
                        <span>{`${
                            isAThread ? "Thread" : "Playlist"
                        } Progress: ${roundedProgress}%`}</span>
                    </StyledProgressPopup>
                )
            case ThreadStatus.COMPLETED:
                return <CompletedIcon />
            case ThreadStatus.UNLOCKED:
                return <ProgressCircle />
        }
    }

    const handleRowClick = () => {
        /*
         * If `onClick` prop is present, call it and
         * override the built-in click behavior
         */
        if (!!onClick) {
            onClick()
            return
        }
        if (isAThread) {
            if (!programGuid || !threadGuid) return
            history.push(`/program/${programGuid}/thread/${threadGuid}`)
        } else if (programGuid) {
            /*
             * As the playlist is not an actual sidebar opening it will not change anything on
             * the `layout` reducer, so if the sidebar animations are disabled it won't animate
             * the sidebar. This is a problem when doing a refresh from a library view because
             * it will cause the animations not to work if the user click a playlist.
             * That's why we manually enable the sidebar animation here.
             * */
            setSidebarAnimation(true)
            history.push(`/program/${programGuid}`)
        }
    }

    const handleThreadReset = (event: MouseEvent<SVGElement>) => {
        if (programId && threadId) {
            event.preventDefault()
            event.stopPropagation()
            resetThread({
                variables: {
                    threadId,
                    programId,
                },
            }).catch((e) => console.error(e))
        }
    }

    const showResetThread =
        maySeeExperimentalFeatures &&
        isAThread &&
        (status === ThreadStatus.IN_PROGRESS || status === ThreadStatus.COMPLETED) &&
        !!handleThreadReset

    return (
        <>
            <StyledLibraryRow selected={isSelected} onClick={handleRowClick}>
                <StyledRow css={{ justifyContent: "space-between", gap: "6px" }}>
                    <StyledColumn>
                        <StyledBox ref={titleContainerRef}>
                            <EllipsisText
                                text={title}
                                variant={"h4"}
                                maxLines={2}
                                maxHeight={40}
                                disableTooltip={isMobile}
                            />
                        </StyledBox>
                        <EllipsisText
                            text={subtitle}
                            variant={"body2"}
                            maxLines={titleIsMultiLine ? 1 : 2}
                            maxHeight={titleIsMultiLine ? 18 : 36}
                            textStyles={
                                titleIsMultiLine
                                    ? {
                                          color: theme.colors.base.uiLabelDisabled,
                                          whiteSpace: "unset",
                                          WebkitLineClamp: 1,
                                          display: "-webkit-box",
                                      }
                                    : {
                                          color: theme.colors.base.uiLabelDisabled,
                                      }
                            }
                            disableTooltip={isMobile}
                        />
                    </StyledColumn>
                    {resizedImageSrc && <StyledLibraryRowImage src={resizedImageSrc} />}
                </StyledRow>
                <StyledRow css={{ justifyContent: "space-between" }}>
                    <StyledRow css={{ gap: "6px" }}>
                        {isAThread ? (
                            renderIcon()
                        ) : (
                            <StyledRow css={{ gap: "3px" }}>
                                <StyledBody2 css={{ color: theme.colors.base.uiPrimary500 }}>
                                    View More
                                </StyledBody2>
                                <ArrowForward
                                    sx={{ color: theme.colors.base.uiPrimary500, fontSize: "18px" }}
                                />
                            </StyledRow>
                        )}
                        {showResetThread && (
                            <StyledCacheIcon
                                sx={{ fontSize: "18px" }}
                                onClick={handleThreadReset}
                            />
                        )}
                    </StyledRow>
                </StyledRow>
            </StyledLibraryRow>
        </>
    )
}

interface LibraryRowSkeletonLoaderProps {
    type: LibraryRowType
}

export const LibraryRowSkeletonLoader = ({ type }: LibraryRowSkeletonLoaderProps) => {
    return (
        <StyledBox
            css={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "8px 0 6px 0",
            }}
        >
            <SkeletonHeadlands
                height={"46px"}
                width={"46px"}
                css={{
                    borderRadius: type === LibraryRowType.playlist ? "8px" : "50%",
                    minWidth: 44,
                }}
            />
            <StyledRightContainerBox>
                <SkeletonHeadlands
                    height={"14px"}
                    width={"90%"}
                    css={{
                        borderRadius: "7px",
                        marginBottom: "5px",
                    }}
                />
                <SkeletonHeadlands
                    width={"60%"}
                    height={"12px"}
                    css={{
                        borderRadius: "7px",
                    }}
                />
            </StyledRightContainerBox>
        </StyledBox>
    )
}

export default LibraryRow
