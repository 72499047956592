import { ImageOutlined } from "@mui/icons-material"
import styled from "styled-components"

export const StyledImageOutlined = styled(ImageOutlined)`
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px !important;
    background-color: ${({ theme }) => theme.colors.base.uiPrimary500};
    fill: ${({ theme }) => theme.colors.base.uiBgBase}!important;
    border-radius: 30px;
    padding: 6px;
`
