import styled from "styled-components"
import { ThreadTheme } from "../../../../types"
import { getThemeColors } from "../../../../utils/utils"

export const StyledInputRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
`

export const StyledThreadThemeCircle = styled.div<{ threadThemeKey: ThreadTheme }>`
    height: 24px;
    width: 24px;
    border-radius: 30px;
    border: solid 1px ${({ theme }) => theme.headlandsGray3};
    background: linear-gradient(
        90deg,
        ${({ threadThemeKey }) => getThemeColors(threadThemeKey).instructor?.bubble} 50%,
        ${({ threadThemeKey }) => getThemeColors(threadThemeKey).student?.bubble} 50%
    );
`
