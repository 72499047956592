import { StyledLeaderboardCard, StyledLeaderboardContainer } from "./styles"
import { StyledH2, StyledH3, StyledHr } from "../styles/styledcomponents"
import LeaderboardComponent from "../components/Leaderboard"
import { useQuery } from "@apollo/client"
import { ABOUT_ME } from "../apollo/queries"
import { useSidebars } from "../hooks/sidebars.hook"
import { useEnrolledProgramThreadsByGuid } from "../hooks/enrolledProgramThread.hook"
import { useSelectedEnrolledProgram } from "../hooks/enrolledProgram.hook"
import { ENROLLED_PROGRAM_PARTICIPANTS } from "../apollo/queries"
import TopBar from "../components/TopBar"

const Leaderboard = ({ mobile }: { mobile?: boolean }) => {
    const { libraryBarOpen } = useSidebars()
    const { selectedEnrolledProgram } = useSelectedEnrolledProgram()
    const { enrolledProgramThreads } = useEnrolledProgramThreadsByGuid(
        selectedEnrolledProgram?.guid
    )

    const { data: aboutMeData, loading: aboutMeLoading } = useQuery(ABOUT_ME)

    const { data: enrolledProgramParticipantsData, loading: enrolledProgramParticipantsLoading } =
        useQuery(ENROLLED_PROGRAM_PARTICIPANTS, {
            skip: !selectedEnrolledProgram?.guid,
            variables: {
                guid: selectedEnrolledProgram?.guid!,
            },
        })

    const loading = enrolledProgramParticipantsLoading || aboutMeLoading

    return (
        <StyledLeaderboardContainer>
            {mobile ? (
                <TopBar styles={{ borderBottom: "unset" }} />
            ) : (
                <TopBar title={selectedEnrolledProgram?.name} extraLeftPadding={!libraryBarOpen} />
            )}
            {mobile && (
                <StyledH2 css={{ margin: "12px" }}>{selectedEnrolledProgram?.name}</StyledH2>
            )}
            {
                // avoid rendering the card when there is content
                !loading && aboutMeData && enrolledProgramParticipantsData && (
                    <StyledLeaderboardCard mobile={mobile}>
                        {mobile ? (
                            <StyledH2>Team Progress</StyledH2>
                        ) : (
                            <StyledH3>Team Progress</StyledH3>
                        )}
                        <StyledHr />
                        {enrolledProgramThreads &&
                            enrolledProgramParticipantsData?.enrolledProgramParticipants && (
                                <LeaderboardComponent
                                    threads={enrolledProgramThreads.map((item) => ({
                                        guid: item.thread.guid,
                                        title: item.thread.title,
                                    }))}
                                    usersData={
                                        enrolledProgramParticipantsData.enrolledProgramParticipants
                                    }
                                    currentUserId={aboutMeData?.aboutMe?.id}
                                    mobile={mobile}
                                />
                            )}
                    </StyledLeaderboardCard>
                )
            }
        </StyledLeaderboardContainer>
    )
}

export default Leaderboard
