import { QueryHookOptions, useQuery } from "@apollo/client"
import { useParams } from "react-router"
import { ENROLLED_PROGRAMS, ENROLLED_PROGRAMS_ID_ONLY } from "../apollo/queries"
import { EnrolledProgram } from "../apollo/generated/graphql"

export const useSelectedProgramGuid = (): string | undefined => {
    const { programGuid } = useParams<{ programGuid: string }>()
    return programGuid
}

export const useEnrolledPrograms = (
    options?: QueryHookOptions
): {
    enrolledPrograms: EnrolledProgram[] | undefined
    loading: boolean
} => {
    const { data, loading } = useQuery<{
        enrolledPrograms: EnrolledProgram[]
    }>(ENROLLED_PROGRAMS, options)
    return {
        enrolledPrograms: data?.enrolledPrograms,
        loading,
    }
}

export const useEnrolledProgramsIdOnly = (): {
    enrolledProgramsIds: EnrolledProgram[] | undefined
    loading: boolean
} => {
    const { data, loading } = useQuery<{
        enrolledPrograms: EnrolledProgram[]
    }>(ENROLLED_PROGRAMS_ID_ONLY)
    return {
        enrolledProgramsIds: data?.enrolledPrograms,
        loading,
    }
}

export const useSelectedEnrolledProgram = (): {
    selectedEnrolledProgram: EnrolledProgram | undefined
    loading: boolean
} => {
    const programGuid = useSelectedProgramGuid()
    const { enrolledPrograms, loading } = useEnrolledPrograms()
    const selectedEnrolledProgram = enrolledPrograms?.find(
        (enrolledProgram) => enrolledProgram.guid === programGuid
    )
    return {
        selectedEnrolledProgram,
        loading,
    }
}

/*
 * This hook return a specific attribute of the selected enrolled program
 * */
export const useSelectedEnrolledProgramAttribute = <T extends keyof EnrolledProgram>(
    key: T
): {
    attribute: EnrolledProgram[T] | undefined
    loading: boolean
} => {
    const programGuid = useSelectedProgramGuid()
    const { enrolledPrograms, loading } = useEnrolledPrograms()
    const selectedEnrolledProgram = enrolledPrograms?.find(
        (enrolledProgram) => enrolledProgram.guid === programGuid
    )
    return {
        attribute: selectedEnrolledProgram ? selectedEnrolledProgram[key] : undefined,
        loading,
    }
}
