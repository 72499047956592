import styled from "styled-components"
import {
    StyledBox,
    VerticalScrollableContentWithHiddenScroll,
} from "../../../styles/styledcomponents"
import { StyledSearchInputContainer } from "../../../deliver/components/SearchBar/styles"

export const StyledThreadCreatorBar = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    background-color: white;
    z-index: 10;
    border-right: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    overflow-y: hidden;
    padding: ${({ theme }) => `
        ${theme.variables.creator.threadBarPadding.top}
        ${theme.variables.creator.threadBarPadding.right}
        ${theme.variables.creator.threadBarPadding.bottom}
        ${theme.variables.creator.threadBarPadding.left}
    `};
    width: ${({ theme }) => theme.sidebarWidths.creatorThread}px;

    .react-tabs {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        .react-tabs__tab-list {
            display: flex;
            justify-content: flex-start;
            margin: 0;
            padding: 0;
            .react-tabs__tab {
                background-color: transparent;
                width: fit-content;
                line-height: 23px;
                color: ${({ theme }) => theme.colors.base.uiLabelTitle};
            }
        }
        .react-tabs__tab-panel--selected {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
    }

    ${StyledSearchInputContainer} {
        border-radius: ${({ theme }) => theme.borderRadius1};
    }
`

export const StyledThreadsList = styled(StyledBox)`
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    margin-top: 18px;
    ${VerticalScrollableContentWithHiddenScroll};
`
