import { first } from "lodash"
import { Block } from "../../../types"
import RenderPreviewBlock from "../RenderPreviewBlock"
import { StyledGroupPreviewContainer } from "./styles"

interface GroupPreviewProps {
    block: Block
}

// NOTE: Even though the group block shouldn't be visible on thread preview
// we did this component to support group preview inside the `AiCreateBlockPopup`
const GroupPreview = ({ block }: GroupPreviewProps) => {
    return (
        <StyledGroupPreviewContainer>
            {first(block.branches)?.objects?.map((object, objectIndex) => (
                <RenderPreviewBlock
                    bubble={object}
                    index={objectIndex}
                    readonly={true}
                    key={`block-${object.id}`}
                />
            ))}
        </StyledGroupPreviewContainer>
    )
}

export default GroupPreview
