import { useCurrentThreadGuid } from "../../hooks/enrolledProgramThread.hook"
import { useSelectedProgramGuid } from "../../hooks/enrolledProgram.hook"
import { StyledRow } from "../../styles/styledcomponents"
import Thread from "../../thread"
import { StyledCatalogContainer } from "../styles"
import {
    StyledBackIcon,
    StyledCloseIcon,
    StyledIconsRow,
    StyledLeaderboardContainer,
    StyledLeaderboardIcon,
    StyledThreadContainer,
    StyledThreadContent,
} from "./styles"
import { useSidebars } from "../../hooks/sidebars.hook"
import { SidebarType } from "../../redux/layout"
import SocialBar from "../../components/SocialBar"
import { AnimatePresence, motion } from "framer-motion"
import { useHistory } from "react-router"

const ThreadScreen = () => {
    const history = useHistory()
    const programGuid = useSelectedProgramGuid()
    const threadGuid = useCurrentThreadGuid()

    const { socialBarOpen, toggleSidebar } = useSidebars()

    const goBack = () => history.replace(`/program/${programGuid}`)

    const toggleLeaderboard = () => toggleSidebar(SidebarType.social)

    return (
        <StyledCatalogContainer>
            <StyledThreadContent>
                <StyledIconsRow>
                    <StyledBackIcon onClick={goBack} />
                    {socialBarOpen ? (
                        <StyledCloseIcon onClick={toggleLeaderboard} />
                    ) : (
                        <StyledLeaderboardIcon onClick={toggleLeaderboard} width={32} height={32} />
                    )}
                </StyledIconsRow>
                <StyledRow>
                    <StyledThreadContainer>
                        {programGuid && threadGuid && (
                            <Thread programGuid={programGuid} threadGuid={threadGuid} />
                        )}
                    </StyledThreadContainer>
                    <AnimatePresence>
                        {socialBarOpen && (
                            <motion.div
                                initial={{ width: 0 }}
                                animate={{ width: "auto" }}
                                exit={{ width: 0 }}
                                style={{ overflow: "hidden" }}
                            >
                                <StyledLeaderboardContainer>
                                    <SocialBar title={"Leaderboard"} />
                                </StyledLeaderboardContainer>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </StyledRow>
            </StyledThreadContent>
        </StyledCatalogContainer>
    )
}

export default ThreadScreen
