import Input from "../../../../components/InputNew"
import { StyledBox, StyledColumn } from "../../../../styles/styledcomponents"
import { useEffect, useMemo } from "react"
import { useFormik } from "formik"
import { StyledAiPopupQuestionForm } from "./styles"
import { GenericSelectOption } from "../../../../types"
import { WritingVerbosity } from "../../../../apollo/generated/graphql"
import { capitalize, values } from "lodash"
import { useTheme } from "styled-components"
import { StyledInputLabel } from "../../../../components/InputNew/styles"
import Select, { isMultiValue } from "../../../../components/Select/Select"

enum TextFields {
    topic = "topic",
}

enum SelectFields {
    verbosity = "verbosity",
}

export type ContinueWritingFormValues = {
    [TextFields.topic]?: string
    [SelectFields.verbosity]?: GenericSelectOption<WritingVerbosity>
}

interface AiContinueWritingFormProps {
    setValues: (values: Required<ContinueWritingFormValues>) => void
    defaultTopic?: string | null
    onTopicFocused?: () => void
    onTopicBlur?: () => void
}

const AiPopupContinueWritingForm = ({
    setValues,
    defaultTopic,
    onTopicFocused,
    onTopicBlur,
}: AiContinueWritingFormProps) => {
    const theme = useTheme()
    const getOptionLabel = (value: string): string => capitalize(value.replaceAll("_", " "))

    const VerbosityOptions = useMemo(
        () =>
            values(WritingVerbosity).map((verbosity) => ({
                value: verbosity,
                label: getOptionLabel(verbosity),
            })),
        []
    )

    const formik = useFormik<ContinueWritingFormValues>({
        initialValues: {
            [SelectFields.verbosity]: {
                value: WritingVerbosity.Terse,
                label: getOptionLabel(WritingVerbosity.Terse),
            },
        },
        onSubmit: () => {},
    })

    useEffect(() => {
        if (defaultTopic) formik.setFieldValue(TextFields.topic, defaultTopic)
    }, [defaultTopic])

    useEffect(() => {
        if (formik.values[TextFields.topic]) {
            // safe `Required` cast above we check all values are present
            setValues(formik.values as Required<ContinueWritingFormValues>)
        }
    }, [formik.values])

    const selectStyles = {
        control: () => ({
            borderRadius: "8px",
            margin: 0,
            boxShadow: "unset",
        }),
        singleValue: () => ({ color: theme.colors.base.uiLabelBase }),
    }

    return (
        <StyledAiPopupQuestionForm>
            <StyledColumn css={{ gap: "6px" }}>
                <Input
                    id={TextFields.topic}
                    name={TextFields.topic}
                    value={formik.values.topic}
                    onChange={formik.handleChange}
                    onFocus={onTopicFocused}
                    onBlur={onTopicBlur}
                    label={"Topic"}
                    type={"textArea"}
                    minRows={2}
                    maxRows={2}
                    containerStyles={{ width: "100%" }}
                />
                <StyledBox>
                    <StyledInputLabel>Verbosity</StyledInputLabel>
                    <Select
                        id={SelectFields.verbosity}
                        name={SelectFields.verbosity}
                        value={formik.values[SelectFields.verbosity]}
                        onChange={(option) =>
                            option &&
                            !isMultiValue(option) &&
                            formik.setFieldValue(SelectFields.verbosity, option)
                        }
                        options={VerbosityOptions}
                        styles={selectStyles}
                    />
                </StyledBox>
            </StyledColumn>
        </StyledAiPopupQuestionForm>
    )
}

export default AiPopupContinueWritingForm
