import "./App.css"
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom"
import { Provider, useSelector } from "react-redux"
import { RootState, store } from "./redux/store"
import { RouteProps } from "react-router"
import NavBar from "./components/NavBar/NavBar"
// Screens
import ProgramScreen from "./program"
import HomeMobileScreen from "./program/HomeMobileScreen"
import CreatorScreen from "./creator"
import CreatorMobile from "./creator/mobile"
import CreatorThreadMobile from "./creator/mobile/Thread"
import useLayout from "./hooks/layout.hook"
import React from "react"
import { UserPermissions } from "./types"
import CatalogScreen from "./catalog"
import DeliverScreen from "./deliver"
import { useUserPermissions } from "./hooks/userPermissions"
import Link from "./link"
import Admin from "./admin"
import LoginAs from "./admin/login"
import Auth from "./auth"
import BottomNavigationBar from "./components/BottomNavigationBar"
import MobileLeaderboardScreen from "./leaderboard/MobileLeaderboardScreen"
import Settings from "./settings/"
import SettingsEdit from "./settings/SettingsEdit"
import { isEmpty } from "lodash"
import ThreadScreen from "./catalog/thread"

enum DeviceType {
    MOBILE = "MOBILE",
    DESKTOP = "DESKTOP",
}

interface PrivateRouteProps extends RouteProps {
    andPermissions?: UserPermissions[] // all permissions are necessary in order to render the component (`AND`)
    orPermissions?: UserPermissions[] // at least one permission is necessary in order to render the component (`OR`)
    allowedDevices?: DeviceType[]
}

const PrivateRoute = ({
    path,
    children,
    component,
    andPermissions,
    orPermissions,
    ...rest
}: PrivateRouteProps) => {
    const { permissions, error, loading } = useUserPermissions({ errorPolicy: "all" })
    const Component = component as React.ElementType

    if (error) {
        localStorage.setItem("redirectUrl", window.location.pathname)
        window.location.replace("/auth")
    }

    const renderContent = () => {
        const shouldRenderChildren = !loading && !error && children
        const shouldRenderComponent = !loading && !error && !children && component

        if (shouldRenderChildren) return children
        else if (shouldRenderComponent) return <Component />
        return null
    }

    // permission check
    if (
        !loading &&
        andPermissions &&
        andPermissions.some((permission) => !permissions[permission])
    ) {
        return <Redirect to={"/"} />
    }

    if (
        !loading &&
        orPermissions &&
        orPermissions.every((permission) => !permissions[permission])
    ) {
        return <Redirect to={"/"} />
    }

    return (
        <Route path={path} {...rest}>
            {!loading && renderContent()}
        </Route>
    )
}

const PrivateRouteWithNavBar = ({ component, ...props }: PrivateRouteProps) => {
    const { isMobile } = useLayout()

    const showMobileBottomBar = useSelector(
        (state: RootState) => state.layoutReducer.showMobileBottomBar
    )

    /*
     * If an `allowedDevices` is present, check if the current device
     * matches with the ones in the array, if it doesn't then redirect to home
     */
    if (!isEmpty(props.allowedDevices)) {
        if (isMobile && !props.allowedDevices?.includes(DeviceType.MOBILE))
            return <Redirect to={"/"} />
        else if (!isMobile && !props.allowedDevices?.includes(DeviceType.DESKTOP))
            return <Redirect to={"/"} />
    }

    const Component = component as React.ElementType
    return (
        <PrivateRoute {...props}>
            {isMobile ? <BottomNavigationBar hide={!showMobileBottomBar} /> : <NavBar />}
            <Component />
        </PrivateRoute>
    )
}

const ReverseAuthRoute = (props: any) => {
    const { permissions, error, loading } = useUserPermissions({ errorPolicy: "all" })

    return !loading && permissions && !error ? <Redirect to={"/"} /> : <Route {...props} />
}

const App = () => {
    const { isMobile } = useLayout()
    return (
        <Provider store={store}>
            <div className={"app"}>
                <BrowserRouter
                    getUserConfirmation={() => {
                        /* Empty callback to block the default browser prompt */
                    }}
                >
                    <Switch>
                        {/* Unauthenticated Routes */}
                        <ReverseAuthRoute exact path={"/auth"} component={Auth} />
                        <PrivateRouteWithNavBar
                            exact
                            path={["/program", "/program/:programGuid?"]}
                            component={isMobile ? HomeMobileScreen : CatalogScreen}
                        />
                        <PrivateRouteWithNavBar
                            path={["/program/:programGuid?/thread/:threadGuid?"]}
                            component={isMobile ? HomeMobileScreen : ThreadScreen}
                        />
                        <PrivateRouteWithNavBar
                            exact
                            path={[
                                "/catalog",
                                "/catalog/:programGuid?",
                                "/catalog/:programGuid?/:threadGuid?",
                            ]}
                            component={ProgramScreen}
                            andPermissions={[UserPermissions.maySeeExperimentalFeatures]}
                            allowedDevices={[DeviceType.DESKTOP]}
                        />
                        <PrivateRouteWithNavBar
                            path={"/leaderboard"}
                            component={MobileLeaderboardScreen}
                            allowedDevices={[DeviceType.MOBILE]}
                        />
                        {isMobile && (
                            <PrivateRouteWithNavBar
                                path={["/creator/:threadGuid"]}
                                component={CreatorThreadMobile}
                            />
                        )}
                        {isMobile ? (
                            <PrivateRouteWithNavBar path={["/creator"]} component={CreatorMobile} />
                        ) : (
                            <PrivateRouteWithNavBar
                                path={["/creator/:threadGuid", "/creator"]}
                                component={CreatorScreen}
                                orPermissions={[
                                    UserPermissions.mayCreate,
                                    UserPermissions.isAdministrator,
                                ]}
                            />
                        )}
                        <PrivateRouteWithNavBar
                            path={["/deliver/:programGuid", "/deliver"]}
                            component={DeliverScreen}
                            orPermissions={[
                                UserPermissions.mayCreate,
                                UserPermissions.isAdministrator,
                            ]}
                        />
                        <PrivateRouteWithNavBar exact path={"/settings"} component={Settings} />
                        <PrivateRouteWithNavBar path={"/settings/edit"} component={SettingsEdit} />
                        <PrivateRoute path={"/admin/login/:id"} component={LoginAs} />
                        <PrivateRouteWithNavBar path={"/admin"} component={Admin} />
                        <PrivateRoute path={"/link/:token"} component={Link} />
                        <Redirect exact from="/" to="/program" />
                    </Switch>
                </BrowserRouter>
            </div>
        </Provider>
    )
}

export default App
