import { Block } from "../../../../types"
import { StyledImageCarouselBlock, StyledImageItem } from "./styles"

const ImageCarouselBlock = ({ block }: { block: Block }) => {
    return (
        <StyledImageCarouselBlock>
            {block.choices?.map((choice, index) => (
                <StyledImageItem
                    src={choice.image_url}
                    key={`${block.id}-image-carousel-item-${index}`}
                />
            ))}
        </StyledImageCarouselBlock>
    )
}

export default ImageCarouselBlock
