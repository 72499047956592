import styled from "styled-components"
import { Button } from "../../../Button/Button"
import { StyledColumn, StyledH5, StyledRow } from "../../../../styles/styledcomponents"

export const StyledShareThreadButton = styled(Button)`
    align-self: flex-end;
    padding: 7px 12px;
    margin-top: auto;
    background-color: ${({ theme }) => theme.colors.base.uiChallenge};
`

export const StyledHeaderButton = styled(StyledColumn)`
    border-radius: 50%;
    border: 2px dashed ${({ theme }) => theme.colors.base.uiPrimary500};
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 2px;
`
export const StyledHeaderButtonContent = styled(StyledRow)`
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.base.uiPrimary500};
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`

interface StyledHeaderButtonContainerProps {
    dark?: boolean
}

export const StyledHeaderButtonContainer = styled(StyledRow)<StyledHeaderButtonContainerProps>`
    display: flex;
    justify-content: flex-end;
    text-align: end;
    align-items: center;
    cursor: pointer;
    gap: 6px;

    ${StyledH5} {
        font-weight: 500;
        color: ${({ theme, dark }) =>
            dark ? theme.colors.base.uiPrimary300 : theme.colors.base.uiPrimary500};
        width: 64px;
    }

    ${StyledHeaderButton} {
        border-color: ${({ theme, dark }) =>
            dark ? theme.colors.base.uiBgBase : theme.colors.base.uiPrimary500}!important;

        ${StyledHeaderButtonContent} {
            background-color: ${({ theme, dark }) =>
                dark ? theme.colors.base.uiBgBase : theme.colors.base.uiPrimary500};
        }
    }
`
