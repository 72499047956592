import { useTheme } from "styled-components"
import { Button } from "../../../components/Button/Button"
import { StyledBox, StyledH3, StyledSpan } from "../../../styles/styledcomponents"
import { StyledCloseIcon, StyledThreadsContainer } from "./styles"
import SearchBar from "../SearchBar"
import { CreatorThreadRow } from "../../../common/components/ThreadRow"
import { useLazyQuery } from "@apollo/client"
import LoaderSkeleton from "../../../common/components/LoaderSkeleton"
import { LoaderType, PartialRecursive } from "../../../types"
import { useEffect, useState } from "react"
import { debounce } from "lodash"
import Modal from "../../../components/Modal"
import { SEARCH_THREADS } from "../../../apollo/queries"
import { Thread } from "../../../apollo/generated/graphql"

interface AddThreadModalProps {
    onSubmit: (threads: PartialRecursive<Thread>[]) => void
    closeModal: () => void
    selectedThreadGuids: string[]
}

const AddThreadModal = ({ onSubmit, closeModal, selectedThreadGuids }: AddThreadModalProps) => {
    const [currentThreads, setCurrentThreads] = useState<PartialRecursive<Thread>[]>([])

    const theme = useTheme()

    const [searchThreads, { data: threadsData, loading: threadsLoading, error: threadsError }] =
        useLazyQuery(SEARCH_THREADS, {
            fetchPolicy: "network-only",
        })

    useEffect(() => {
        searchThreads({ variables: { input: { query: "", onlyPublished: true } } })
    }, [])

    const handleSearch = debounce((text: string) => {
        searchThreads({ variables: { input: { query: text, onlyPublished: true } } })
    }, 500)

    const handleAddThreads = () => {
        onSubmit(currentThreads)
        closeModal()
    }

    const handleThreadSelect = (selectedThread: PartialRecursive<Thread>) => {
        if (currentThreads.some((thread) => thread.guid === selectedThread.guid)) {
            setCurrentThreads(
                currentThreads.filter((thread) => thread.guid !== selectedThread.guid)
            )
        } else {
            setCurrentThreads(currentThreads.concat(selectedThread))
        }
    }

    const filteredThreads = threadsData?.searchThreads
        ?.map((searchResult) => searchResult.thread)
        .filter((thread) => !selectedThreadGuids.includes(thread.guid))

    return (
        <Modal open={true} onClose={closeModal} styles={{ width: "552px" }}>
            <StyledBox
                css={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <StyledH3 css={{ color: theme.headlandsGray1 }}>Add Thread</StyledH3>
                <StyledCloseIcon onClick={closeModal} />
            </StyledBox>
            <StyledBox css={{ width: "100%" }}>
                <SearchBar placeholder="Search for threads" onChange={handleSearch} />
                <StyledThreadsContainer>
                    {threadsLoading ? (
                        Array.from({ length: 5 }).map((_, index: number) => (
                            <LoaderSkeleton key={index} type={LoaderType.THREAD} />
                        ))
                    ) : filteredThreads?.length ? (
                        filteredThreads.map((thread) => (
                            <CreatorThreadRow
                                key={thread.id}
                                title={thread.title}
                                instructor={thread.instructor}
                                savedDate={thread.lastUpdateTime}
                                lastPublishedDate={thread.lastUpdateTime}
                                handleOnClick={() => handleThreadSelect(thread)}
                                selected={currentThreads.some((thr) => thr.guid === thread.guid)}
                                handleCheckboxSelect={() => handleThreadSelect(thread)}
                                lastVersionIsAPublishedOne
                                maxWidth={452}
                                titleToltipPosition={"top center"}
                            />
                        ))
                    ) : threadsError ? (
                        <StyledSpan css={{ alignSelf: "center" }}>En error has occurred</StyledSpan>
                    ) : (
                        <StyledSpan css={{ alignSelf: "center" }}>No threads found</StyledSpan>
                    )}
                </StyledThreadsContainer>
            </StyledBox>
            <StyledBox css={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                <Button
                    label={"Cancel"}
                    onClick={closeModal}
                    variant={"outline"}
                    buttonStyle={{ width: "fit-content" }}
                />
                <Button
                    label={"Add"}
                    onClick={handleAddThreads}
                    buttonStyle={{ marginLeft: "12px", width: "fit-content" }}
                />
            </StyledBox>
        </Modal>
    )
}

export default AddThreadModal
