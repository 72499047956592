export const InstructorAvatar = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_28251_94525)">
                <path
                    d="M9 4.425C9.87 4.425 10.575 5.13 10.575 6C10.575 6.87 9.87 7.575 9 7.575C8.13 7.575 7.425 6.87 7.425 6C7.425 5.13 8.13 4.425 9 4.425ZM9 11.175C11.2275 11.175 13.575 12.27 13.575 12.75V13.575H4.425V12.75C4.425 12.27 6.7725 11.175 9 11.175ZM9 3C7.3425 3 6 4.3425 6 6C6 7.6575 7.3425 9 9 9C10.6575 9 12 7.6575 12 6C12 4.3425 10.6575 3 9 3ZM9 9.75C6.9975 9.75 3 10.755 3 12.75V15H15V12.75C15 10.755 11.0025 9.75 9 9.75Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_28251_94525">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
