import { Block, BlockType } from "../../../../types"
import DesktopOnlyBlock from "../DesktopOnlyBlock"
import ImageBlock from "../ImageBlock"
import GroupBlock from "../GroupBlock"
import SectionBlock from "../SectionBlock"
import TextBlock from "../TextBlock"
import FreeTextBlock from "../FreeTextBlock"
import CommentBlock from "../CommentBlock"
import DividerBlock from "../DividerBlock"
import ImageCarouselBlock from "../ImageCarouselBlock"
import MultipleChoiceBlock from "../MultipleChoiceBlock"
import { Confetti } from "../../../../components/Confetti"
import InstructorAvatarBlock from "../InstructorAvatarBlock"

const CreatorBlockMobile = ({ block }: { block: Block }) => {
    switch (block.type) {
        case BlockType.DIVIDER:
            return <DividerBlock />
        case BlockType.IMAGE_CAROUSEL:
            return <ImageCarouselBlock block={block} />
        case BlockType.CHOICE_QUESTION:
            return <MultipleChoiceBlock block={block} />
        case BlockType.TEXT:
            return <TextBlock block={block} />
        case BlockType.COMMENT:
            return <CommentBlock block={block} />
        case BlockType.IMAGE:
            return <ImageBlock block={block} />
        case BlockType.SECTION:
            return <SectionBlock block={block} />
        case BlockType.GROUP:
            return <GroupBlock block={block} />
        case BlockType.FREE_TEXT_QUESTION:
            return <FreeTextBlock block={block} />
        case BlockType.CONFETTI:
            return <Confetti />
        case BlockType.INSTRUCTOR_AVATAR:
            return <InstructorAvatarBlock />
        // blocks we don't have support for mobile
        default:
            return <DesktopOnlyBlock />
    }
}

export default CreatorBlockMobile
