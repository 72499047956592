import styled from "styled-components"
import { StyledColumn, StyledHr, StyledRow } from "../../../../styles/styledcomponents"

export const StyledFreeTextBlock = styled(StyledColumn)`
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border-radius: 3px;
    gap: 6px;
    padding: 12px;
`

export const StyledResponseBubble = styled(StyledRow)`
    background-color: ${({ theme }) => theme.colors.base.uiPrimary300};
    border: 1px solid ${({ theme }) => theme.colors.base.uiPrimary500};
    border-radius: 16px 16px 2px 16px;
    gap: 6px;
    padding: 12px 18px;
    align-items: center;
    width: 90%;
    box-sizing: border-box;
    align-self: flex-end;
`

export const StyledDivider = styled(StyledHr)`
    border-style: dashed;
    border-color: ${({ theme }) => theme.colors.base.uiBgBorder};
    width: 100%;
    box-sizing: border-box;
    margin: 6px 0 0;
`
