import {
    StyledErrorMessageRow,
    StyledIconContainer,
    StyledInput,
    StyledInputContainer,
    StyledInputLabel,
} from "./styles"
import React, { ForwardedRef, forwardRef, ReactNode } from "react"
import { CSSObject } from "styled-components"
import TextareaAutosize from "react-textarea-autosize"
import { TextareaAutosizeProps } from "react-textarea-autosize/dist/declarations/src"
import { StyledBody2, StyledColumn } from "../../styles/styledcomponents"
import { ErrorIcon } from "../../assets/icons/ErrorIcon"

type InputProps = React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & CustomProps

type CustomInputProps = {
    label?: string
    rows?: number
    icon?: ReactNode
    containerStyles?: CSSObject
    error?: boolean
    errorMessage?: string
}

type CustomTextAreaProps = CustomInputProps & Pick<TextareaAutosizeProps, "minRows" | "maxRows">

type CustomProps = CustomInputProps | CustomTextAreaProps

const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
    (
        { label, type, icon, containerStyles, error, errorMessage, ...inputProps }: InputProps,
        ref
    ) => {
        const enhancedInputProps = {
            ...inputProps,
            autoComplete: type === "password" ? "new-password" : "",
        }

        const textareaAutosizeProps = {
            ...enhancedInputProps,
            // manually handling the disabled cause a crash bug on android
            disabled: false,
            // avoid cursor to be positioned on the input
            readOnly: inputProps.disabled,
            onChange: !inputProps.disabled ? inputProps.onChange : undefined,
        }

        const textAreaAutosizeStyle = inputProps.style as Omit<
            NonNullable<React.TextareaHTMLAttributes<HTMLTextAreaElement>["style"]>,
            "maxHeight" | "minHeight"
        > & {
            height?: number
        }

        return (
            <StyledInputContainer hasIcon={!!icon} css={containerStyles} error={error}>
                {label && <StyledInputLabel>{label}</StyledInputLabel>}
                <StyledColumn css={{ position: "relative" }}>
                    {!!icon && <StyledIconContainer>{icon}</StyledIconContainer>}
                    {type === "textArea" ? (
                        <TextareaAutosize
                            {...textareaAutosizeProps}
                            style={textAreaAutosizeStyle}
                            ref={ref as ForwardedRef<HTMLTextAreaElement>}
                        />
                    ) : (
                        <StyledInput
                            {...inputProps}
                            ref={ref as ForwardedRef<HTMLInputElement>}
                            type={type}
                        />
                    )}
                </StyledColumn>
                {error && !!errorMessage && <InputErrorMessage message={errorMessage} />}
            </StyledInputContainer>
        )
    }
)

interface InputErrorMessageProps {
    message: string
}

export const InputErrorMessage = ({ message }: InputErrorMessageProps) => (
    <StyledErrorMessageRow>
        <ErrorIcon />
        <StyledBody2>{message}</StyledBody2>
    </StyledErrorMessageRow>
)

export default Input
