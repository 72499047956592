import styled from "styled-components"
import { StyledBox } from "../../styles/styledcomponents"
import { Close } from "@mui/icons-material"

const BUTTON_SIZE = 20

export const StyledCloseIcon = styled(Close)`
    color: ${({ theme }) => theme.colors.base.uiBgIcon};
    font-size: 15px !important;
`

export const StyledCloseButton = styled.button`
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1;
    /* top right corner */
    top: calc(-${BUTTON_SIZE}px / 4);
    right: calc(-${BUTTON_SIZE}px / 2);
    border-radius: 50%;
    width: ${BUTTON_SIZE}px;
    height: ${BUTTON_SIZE}px;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    cursor: pointer;
`

export const StyledDeletableWrapper = styled(StyledBox)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: fit-content;
    height: fit-content;

    &:hover {
        ${StyledCloseButton} {
            display: flex;
        }
    }
`
