import { BlockType, Thread } from "../types"
import { HeadlandsFontColors, HeadlandsHighlightColors } from "./colors"

export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&\'()*+,\-.\/:;<=>?@[\]^_`{|}~\\]).{8,}$/

export const MIN_WIDTH = 991

export const SIDEBAR_BREAKPOINT = 900

export const SM_BREAKPOINT = 600
export const MD_BREAKPOINT = 1280
export const LG_BREAKPOINT = 1400

export const baseThread = {
    threadVersion: {
        title: "",
        published: false,
        synopsis: "",
        preview: "",
        blocks: [],
        displayDescription: "",
    },
    variables: {},
    userAnswers: [],
    userAnswersMap: {},
    globalVariables: {
        user: {},
        program: {},
        thread: {},
    },
} as Thread

export const wordsPerMinute = 250

export const timeToAnswer = 10000 // in ms

export const maxCharacterCount = 280

export const editorModules = {
    toolbar: [
        "ai",
        "bold",
        "italic",
        "underline",
        "formula",
        { header: [6, false, 4] },
        { color: HeadlandsFontColors },
        { background: HeadlandsHighlightColors },
        "link",
        "blockquote",
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        "clean",
    ],
    clipboard: {
        matchVisual: false,
    },
    keyboard: {
        bindings: {
            handleEnter: {
                key: 13,
                handler: () => {},
            },
            handleArrowLeft: {
                key: 37,
                handler: () => {},
            },
            handleArrowUp: {
                key: 38,
                handler: () => {},
            },
            handleArrowRight: {
                key: 39,
                handler: () => {},
            },
            handleArrowDown: {
                key: 40,
                handler: () => {},
            },
        },
    },
    history: {
        maxStack: 0,
    },
}

export const carouselBreakpoints = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
}

export const carouselBreakpointsMOCKMobile = {
    mockMobile: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
}
export const carouselBreakpointsMOCKDesktop = {
    mockDesktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
    },
}

export const questionTypes = [
    BlockType.IMAGE_UPLOAD,
    BlockType.PAUSE,
    BlockType.PREVIEW,
    BlockType.CHOICE_QUESTION,
    BlockType.FREE_TEXT_QUESTION,
]

export const THREAD_CONTENT_REF_ID = "thread-content-ref"

export const HEADLANDS_BLOCK_TYPE = "application/headlands-block"

// todo: fix default options
export const baseSortableOptions = {
    animation: 150,
    fallbackOnBody: true,
    swapThreshold: 0.65,
    ghostClass: "ghost",
    group: "GROUP",
    handle: ".drag-handle",
    dragClass: "dragging",
}
