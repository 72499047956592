import { useRef, useState, useCallback } from "react"
import { useReactiveVar } from "@apollo/client"
import { selectedBlockVar } from "../../../apollo/cache-store"
import { BlockType } from "../../../types"
import VideoModal from "./VideoModal"
import ImageModalContent, { ImageModalTab } from "./ImageModalContent"
import useOnClickOutside from "../../../hooks/onClickOutside.hook"
import { removeHTML } from "../../../utils/utils"
import { StyledMediaModal } from "./styles"
import { Attachment } from "../../../apollo/generated/graphql"

export type MediaModalOnChangeOptions = {
    value: string
    blockId?: string
    comment?: string
    attachmentGuid?: string
}
export interface MediaModalProps {
    blockId: string
    isVideo?: boolean
    imageComment?: string
    availableImageModalTabs?: ImageModalTab[]
    onChange: (options: MediaModalOnChangeOptions) => void
    setLoading?: (loading: boolean) => void
}

const MediaModal = ({
    blockId,
    isVideo,
    imageComment,
    availableImageModalTabs,
    onChange,
    setLoading,
}: MediaModalProps) => {
    // TODO: probably it will be better to use props instead of this reactive variable
    const selectedBlock = useReactiveVar(selectedBlockVar)
    const [mediaUrl, setMediaUrl] = useState((selectedBlock && selectedBlock.value) || "")

    const wrapperRef = useRef<HTMLDivElement>(null)

    const handler = useCallback(() => selectedBlockVar(null), [])
    useOnClickOutside(wrapperRef, handler)

    const setBlockMediaUrl = (url: string, comment?: string) => {
        onChange({ value: isVideo ? url : removeHTML(url), blockId, comment })
        selectedBlockVar(null)
    }

    const getMediaBlock = () => {
        if (selectedBlock) {
            switch (selectedBlock.type) {
                case BlockType.IMAGE:
                    return (
                        <ImageModalContent
                            mediaUrl={mediaUrl}
                            setMediaUrl={setMediaUrl}
                            onMediaChange={(options) => {
                                onChange(options)
                                selectedBlockVar(null)
                            }}
                            imageComment={imageComment}
                            availableTabs={availableImageModalTabs}
                        />
                    )
                case BlockType.VIDEO:
                    return (
                        <VideoModal
                            setBlockMediaUrl={setBlockMediaUrl}
                            mediaUrl={mediaUrl}
                            setMediaUrl={setMediaUrl}
                        />
                    )
                case BlockType.IMAGE_CAROUSEL:
                    return (
                        <ImageModalContent
                            setBlockMediaUrl={setBlockMediaUrl}
                            mediaUrl={mediaUrl}
                            setMediaUrl={setMediaUrl}
                            onMediaChange={(options) => {
                                onChange({ value: options.value, blockId })
                                selectedBlockVar(null)
                            }}
                            setLoading={setLoading}
                            imageComment={imageComment}
                        />
                    )
            }
        }
    }

    return (
        <StyledMediaModal ref={wrapperRef} data-testid={"media-modal"}>
            {getMediaBlock()}
        </StyledMediaModal>
    )
}

export interface SubModalProps {
    // TODO: we probably want to delete this `setBlockMediaUrl`
    setBlockMediaUrl?: (url: string, comment?: string) => void
    onMediaChange?: (options: MediaModalOnChangeOptions) => void
    mediaUrl: string
    setMediaUrl: (url: string) => void
    setLoading?: (loading: boolean) => void
    onAttachmentUpload?: (value: string, guid: string) => void
    onAttachment?: (attachment: Attachment) => void
    loading?: boolean
    imageComment?: string
}

export default MediaModal
