import { useState } from "react"
import { Block } from "../../../../types"
import { StyledDivider, StyledFreeTextBlock, StyledResponseBubble } from "./styles"
import { StyledBody1, StyledRow } from "../../../../styles/styledcomponents"
import { StyledCollapseIcon, StyledSendIcon } from "../../../components/QuestionBlock/styles"
import { AnimatePresence, motion } from "framer-motion"
import CreatorBlockMobile from "../CreatorBlockMobile"
import { useTheme } from "styled-components"
import ImageBlock from "../ImageBlock"
import TextBlock from "../TextBlock"

const FreeTextBlock = ({ block }: { block: Block }) => {
    const theme = useTheme()
    const [collapsed, setCollapsed] = useState(true)
    return (
        <StyledFreeTextBlock>
            {block.image && <ImageBlock block={block.image} />}
            <StyledRow css={{ alignItems: "center", gap: "6px" }}>
                {block.question && <TextBlock value={block.question} />}
                <StyledCollapseIcon
                    collapsed={collapsed}
                    onClick={() => setCollapsed(!collapsed)}
                />
            </StyledRow>
            <StyledResponseBubble>
                <StyledBody1 css={{ color: theme.colors.base.uiLabelBase }}>
                    {block.responses?.[0]?.input}
                </StyledBody1>
                <StyledSendIcon />
            </StyledResponseBubble>
            <AnimatePresence initial={false}>
                {!collapsed && (
                    <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                        }}
                    >
                        <StyledDivider />
                        {block.responses?.[0]?.objects?.map((innerBlock) => (
                            <CreatorBlockMobile block={innerBlock} />
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </StyledFreeTextBlock>
    )
}

export default FreeTextBlock
