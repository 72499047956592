import { useState } from "react"
import { StyledBox, StyledH5, StyledRow } from "../../../../styles/styledcomponents"
import { Block } from "../../../../types"
import { StyledCollapseIcon } from "../../../components/QuestionBlock/styles"
import CreatorBlockMobile from "../CreatorBlockMobile"
import { AnimatePresence, motion } from "framer-motion"

const SectionBlock = ({ block }: { block: Block }) => {
    const [collapsed, setCollapsed] = useState(true)
    return (
        <StyledBox>
            <StyledRow
                css={{ gap: "6px", marginBottom: "12px", alignItems: "center" }}
                onClick={() => setCollapsed(!collapsed)}
            >
                <StyledCollapseIcon collapsed={collapsed} />
                <StyledH5 useNewDesign>{block.title || ""}</StyledH5>
            </StyledRow>
            <AnimatePresence initial={false}>
                {!collapsed && (
                    <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            gap: "12px",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                        }}
                    >
                        {block.objects?.map((innerBlock) => (
                            <CreatorBlockMobile block={innerBlock} />
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </StyledBox>
    )
}

export default SectionBlock
