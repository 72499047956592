import styled, { css } from "styled-components"
import { StyledColumn, StyledImage } from "../../../styles/styledcomponents"

export const StyledEmptyHomePageContainer = styled(StyledColumn)<{ isMobile?: boolean }>`
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};

    ${({ isMobile }) =>
        isMobile
            ? css`
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
              `
            : css`
                  flex: 1;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: center;
              `}

    ${StyledImage} {
        opacity: 0.15;
        width: ${({ isMobile }) => (isMobile ? "42px" : "60px")};
    }
`
