import React from "react"
import useLayout from "../hooks/layout.hook"
import {
    StyleDesktopAuthScreen,
    StyledMainCard,
    StyledMainCardContent,
    StyledMobileBackgroundImage,
    StyleMobileAuthScreen,
} from "./styles"
import AuthLines from "../assets/images/auth-lines.png"

const withResponsiveAuthScreen =
    <T extends object>(AuthContentComponent: React.FC<T>) =>
    ({ ...props }: T) => {
        const { isMobile } = useLayout()

        if (!isMobile) {
            return (
                <StyleDesktopAuthScreen>
                    <StyledMainCard>
                        <StyledMainCardContent>
                            <AuthContentComponent {...props} />
                        </StyledMainCardContent>
                    </StyledMainCard>
                </StyleDesktopAuthScreen>
            )
        }
        return (
            <StyleMobileAuthScreen>
                <AuthContentComponent {...props} />
                <StyledMobileBackgroundImage src={AuthLines} />
            </StyleMobileAuthScreen>
        )
    }

export default withResponsiveAuthScreen
