import { ApolloError } from "@apollo/client"
import { capitalize } from "lodash"
import { useEffect, useRef } from "react"
import LoaderSkeleton from "../../../../common/components/LoaderSkeleton"
import RenderPreviewBlock from "../../../../components/ThreadPreview/RenderPreviewBlock"
import useIntersectionObserver from "../../../../hooks/intersection.hook"
import { StyledBox, StyledColumn } from "../../../../styles/styledcomponents"
import { Block, BlockLoaderSide, BlockLoaderType, LoaderType } from "../../../../types"
import { StyledContent, StyledErrorMessage } from "../styles"
import { CSSObject } from "styled-components"

interface AiAssistPopupContentProps {
    blocks: Block[]
    loading?: boolean
    error?: ApolloError
    subscriptionComplete?: boolean
    styles?: CSSObject
}

const AiAssistPopupContent = ({
    blocks,
    loading,
    error,
    subscriptionComplete,
    styles,
}: AiAssistPopupContentProps) => {
    const bottomRef = useRef<HTMLDivElement>(null)
    const { observe, unobserve } = useIntersectionObserver({
        observerOptions: {
            root: document.querySelector("#ai-content"),
        },
        fullyUnViewableCallback: () => bottomRef?.current?.scrollIntoView({ behavior: "smooth" }),
    })

    /*
     * This effect tries to add an observer for the bottom of the content,
     * that way we can handle auto scroll when new bubbles came.
     * */
    useEffect(() => {
        // if the content is loading the bottom content is not render
        if (loading) return
        const contentBottom = document.querySelector(`#ai-content-bottom`)
        contentBottom && observe(contentBottom)
        return () => void (contentBottom && unobserve(contentBottom))
    }, [loading])

    useEffect(() => {
        const contentBottom = document.querySelector(`#ai-content-bottom`)
        if (subscriptionComplete && contentBottom) unobserve(contentBottom)
    }, [subscriptionComplete])

    return (
        // this classname is not ideal but thread-preview is still on scss
        <StyledContent
            className={"thread-preview is-preview"}
            error={!!error}
            id={"ai-content"}
            style={styles}
        >
            {loading ? (
                <StyledBox css={{ margin: "0 12px" }}>
                    <LoaderSkeleton
                        key={"loader-skeleton-1"}
                        blockType={BlockLoaderType.SHORT_TEXT}
                        from={BlockLoaderSide.INSTRUCTOR}
                        type={LoaderType.BLOCK}
                    />
                    <LoaderSkeleton
                        key={"loader-skeleton-2"}
                        blockType={BlockLoaderType.MEDIUM_TEXT}
                        from={BlockLoaderSide.INSTRUCTOR}
                        type={LoaderType.BLOCK}
                    />
                </StyledBox>
            ) : error ? (
                <StyledErrorMessage>
                    {capitalize(error.graphQLErrors[0].message)}
                </StyledErrorMessage>
            ) : (
                <StyledColumn>
                    {blocks.map((block, index) => (
                        <RenderPreviewBlock
                            bubble={block}
                            index={index}
                            readonly={true}
                            key={`block-${block.id}`}
                        />
                    ))}
                    <div
                        id={"ai-content-bottom"}
                        ref={bottomRef}
                        style={{ width: "100%", height: "1px" }}
                    />
                </StyledColumn>
            )}
        </StyledContent>
    )
}

export default AiAssistPopupContent
