import { useEffect, useMemo, useState } from "react"
import { Block } from "../../../types"
import { getPresentVariableKeys, isHTML, removeHTML, replaceVariables } from "../../../utils/utils"
import { StyledTextBubble } from "./styles"
import parse from "html-react-parser"
import { useDynamicVariableValues, useStaticVariableValues } from "../../../hooks/variables.hook"
import { useResizeDetector } from "react-resize-detector"

export interface TextPreviewProps {
    block: Block
    // custom selector to be use when the text value is NOT on `block.value` (default one)
    customValueSelector?: (block: Block) => string | undefined
}

export const SINGLE_LINE_HEIGHT = 20

const TextPreview = ({ block, customValueSelector }: TextPreviewProps) => {
    const { getDynamicVariableValues } = useDynamicVariableValues()
    const { staticVariableValues } = useStaticVariableValues()
    const { height: textPreviewHeight, ref: textPreviewRef } = useResizeDetector()
    const [finalText, setFinalText] = useState("")
    const value = customValueSelector ? customValueSelector(block) : block?.value

    const replaceVariablesOnText = async () => {
        const variableKeys = getPresentVariableKeys(value)
        const dynamicVariableValues = await getDynamicVariableValues(variableKeys)
        const allVariableValues = [...dynamicVariableValues, ...staticVariableValues]
        setFinalText(replaceVariables(value || "", allVariableValues))
    }

    useEffect(() => {
        replaceVariablesOnText()
    }, [value, staticVariableValues])

    const isMultiLine = useMemo(() => {
        if (textPreviewHeight) return textPreviewHeight > SINGLE_LINE_HEIGHT
        return false
    }, [textPreviewHeight])

    return (
        <StyledTextBubble
            ref={textPreviewRef}
            hide={!removeHTML(value!)}
            noBackground={block.color}
            // we add quill classname to emulate the same styles from creator tool
            className={"ql-editor"}
            isMultiLine={isMultiLine}
        >
            {isHTML(value!) ? parse(finalText) : <p>{parse(finalText)}</p>}
        </StyledTextBubble>
    )
}

export default TextPreview
