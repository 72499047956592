import { BlockCommentContainer } from "./BlockCommentStyle"
import { Block } from "../../../../types"
import RichTextEditor from "../../../../components/Input/RichTextEditor"
import parse from "html-react-parser"

interface BlockCommentProps {
    block: Block
    blockIndex: number
    onChange: (value: string, blockId: string) => void
    onDelete: (blockId: string, blockIndex: number) => void
    onScreen?: boolean
}

const BlockComment = ({ block, blockIndex, onChange, onDelete, onScreen }: BlockCommentProps) => {
    const handleDelete = (event?: any) => {
        event?.stopPropagation()
        onDelete(block.id, blockIndex)
    }

    return (
        <BlockCommentContainer id={`block-${block.id}`}>
            {onScreen ? (
                <RichTextEditor
                    value={block.value}
                    onChange={(value: string) => onChange(value, block.id)}
                    enableEmojis
                    enableFormatting
                    noBorder
                    placeholder={"Add a comment"}
                    onDelete={handleDelete}
                />
            ) : (
                <div className={"rich-text-editor no-border"}>
                    <div className={"quill"}>
                        <div className={"ql-container ql-bubble"}>
                            <div className={"ql-editor"}>{parse(block.value || "")}</div>
                        </div>
                    </div>
                </div>
            )}
        </BlockCommentContainer>
    )
}

export default BlockComment
