import useLayout from "../../../hooks/layout.hook"
import { StyledImage } from "../../../styles/styledcomponents"
import thredsAiLogo from "../../../assets/images/threds-ai-logo.svg"
import { StyledEmptyHomePageContainer } from "./styles"
import TopBar from "../../../components/TopBar"

interface EmptyHomePageProps {
    topbarChildren?: JSX.Element
}

const EmptyHomePage = ({ topbarChildren }: EmptyHomePageProps) => {
    const { isMobile } = useLayout()

    return (
        <>
            <TopBar>{topbarChildren}</TopBar>
            <StyledEmptyHomePageContainer isMobile={isMobile}>
                <StyledImage src={thredsAiLogo} />
            </StyledEmptyHomePageContainer>
        </>
    )
}

export default EmptyHomePage
