import styled, { css } from "styled-components"
import { StyledBox, StyledHeadlandsPopup } from "../../../../../styles/styledcomponents"
import StyledButton from "../../../../../components/Button/styles"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

export const StyledButtonLabelContainer = styled(StyledBox)`
    display: flex;
    align-items: center;
    gap: 4px;
`

interface StyledCustomFormulaTooltipProps {
    top?: string
    left?: string
}

export const StyledCustomFormulaTooltip = styled(StyledBox)<StyledCustomFormulaTooltipProps>`
    display: flex;
    align-items: center;
    position: absolute;
    gap: 6px;
    z-index: 1;
    min-height: 42px;
    width: 320px;
    background-color: ${({ theme }) => theme.headlandsWhite};
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 6px 12px 6px 0;
    box-sizing: border-box;

    ${({ top }) =>
        !!top &&
        css`
            top: ${top};
        `}

    ${({ left }) =>
        !!left &&
        css`
            left: ${left};
        `}

    .rich-text-editor {
        flex: 1;
        .quill {
            margin: 0;
        }
    }

    ${StyledButton} {
        width: fit-content;
        padding: 5px 11px;
    }
`

export const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
    font-size: 15px !important;
    path {
        fill: ${({ theme }) => theme.colors.base.uiBgIcon}!important;
    }
`
export const StyledFormulaInfoPopup = styled(StyledHeadlandsPopup)`
    &-content {
        max-width: unset;
    }
`
