import { getEditorDefaults, PinturaDefaultImageWriterResult, processImage } from "pintura"
import { ImageEditionChanges } from "../types"
import { useCallback } from "react"
import { getPresentVariableKeys, replaceVariables } from "../utils/utils"
import { useDynamicVariableValues, useStaticVariableValues } from "./variables.hook"

const useImageEditor = () => {
    const { getDynamicVariableValues } = useDynamicVariableValues()
    const { staticVariableValues } = useStaticVariableValues()
    /*
     * This method generates an image URL applying all the changes given
     * */
    const generateImageUrl = useCallback(
        (src: string, imageState: { [key: string]: any }): Promise<string> => {
            const options = {
                ...getEditorDefaults(),
                imageState,
            }

            return processImage(src, options).then(({ dest }: PinturaDefaultImageWriterResult) =>
                URL.createObjectURL(dest)
            )
        },
        []
    )

    /*
     * This method generates an image File applying all the changes given
     * */
    const generateImageFile = (src: string, imageState: { [key: string]: any }): Promise<File> => {
        const options = {
            ...getEditorDefaults(),
            imageState,
        }

        return processImage(src, options).then(({ dest }: PinturaDefaultImageWriterResult) => dest)
    }

    /*
     * This method generates an image File and image URl applying all the changes given
     * */
    const generateImagUrlAndFile = (
        src: string,
        imageState: { [key: string]: any }
    ): Promise<{ url: string; file: File }> => {
        const options = {
            ...getEditorDefaults(),
            imageState,
        }

        return processImage(src, options).then(({ dest }: PinturaDefaultImageWriterResult) => ({
            file: dest,
            url: URL.createObjectURL(dest),
        }))
    }

    const _annotationIsDynamic = (annotation: { text: string }): boolean => {
        const words = annotation.text.split(" ")
        return words.some((word) => /@\w/.test(word))
    }

    /*
     * This method checks whether the image is dynamic (has at least one variable) or not
     * */
    const checkIfDynamic = (changes: ImageEditionChanges): boolean => {
        return changes?.annotation?.some(_annotationIsDynamic)
    }

    /*
     * This method replaces the variables found on the changes object and return that same object but with the
     * variables replaced
     * */
    const replaceImageVariables = (changes: ImageEditionChanges) => {
        return {
            ...changes,
            annotation: changes?.annotation.map(async (annotation: { text: string }) => {
                if (_annotationIsDynamic(annotation)) {
                    const variableKeys = getPresentVariableKeys(annotation.text)
                    const dynamicVariableValues = await getDynamicVariableValues(variableKeys)
                    const allVariableValues = [...dynamicVariableValues, ...staticVariableValues]
                    return {
                        ...annotation,
                        text: replaceVariables(annotation.text, allVariableValues),
                    }
                }
                return annotation
            }),
        }
    }

    return {
        generateImageUrl,
        generateImageFile,
        generateImagUrlAndFile,
        checkIfDynamic,
        replaceImageVariables,
    }
}

export default useImageEditor
