import { useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { Path } from "typescript"
import { GET_PATH_FROM_LINK_TOKEN } from "../apollo/mutations"

const Link = () => {
    const { token } = useParams<any>()
    const history = useHistory()
    const [status, setStatus] = useState<string>()

    const [getPathFromLinkToken] = useMutation(GET_PATH_FROM_LINK_TOKEN, {
        onCompleted: (res) => {
            history.push(res.getPathFromLinkToken as Path)
        },
        onError: (e) => {
            setStatus("Link has expired!")
            console.error(e)
        },
    })

    useEffect(() => {
        void getPathFromLinkToken({ variables: { token: token } })
    }, [])

    return <div>{status}</div>
}

export default Link
