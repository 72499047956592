import { useDispatch, useSelector } from "react-redux"
import { useTheme } from "styled-components"
import { DesktopIcon } from "../../../assets/icons/DesktopIcon"
import { MobileIcon } from "../../../assets/icons/MobileIcon"
import ThreadPreview from "../../../components/ThreadPreview/ThreadPreview"
import { useCurrentThreadGuid } from "../../../hooks/currentThread.hook"
import { RootState } from "../../../redux/store"
import { setPreviewDevice, setPreviewMode } from "../../../redux/threads"
import {
    StyledColumn,
    StyledHeadlandsPopup,
    StyledRow,
    StyledSpan,
} from "../../../styles/styledcomponents"
import { OptionPreviewMode, OptionWithIcon } from "../../../types"
import Switch from "../Switch/Switch"
import { StyledCloseIcon, StyledPreviewModal } from "./styles"
import { Button } from "../../../components/Button/Button"
import ShareThreadModal from "../../../components/ShareThreadModal"
import useThreadVersionHistory from "../../../hooks/threadVersionHistory.hook"

const PreviewModal = () => {
    const theme = useTheme()

    const dispatch = useDispatch()

    const threadGuid = useCurrentThreadGuid()

    const previewMode = useSelector((state: RootState) => state.threadsReducer.previewMode)
    const previewDevice = useSelector((state: RootState) => state.threadsReducer.previewDevice)

    const PreviewOptions: OptionWithIcon[] = [
        {
            label: OptionPreviewMode.DESKTOP,
            icon: <DesktopIcon color={theme.headlandsPrimaryColor} />,
        },
        {
            label: OptionPreviewMode.MOBILE,
            icon: <MobileIcon color={theme.headlandsPrimaryColor} />,
        },
    ]

    const updatePreviewDevice = (device: OptionPreviewMode) => {
        dispatch(setPreviewDevice({ previewDevice: device }))
    }

    const handleChangeSwitch = (option?: OptionPreviewMode) => {
        if (option !== undefined) {
            updatePreviewDevice(option)
        } else {
            previewDevice === OptionPreviewMode.MOBILE
                ? updatePreviewDevice(OptionPreviewMode.DESKTOP)
                : updatePreviewDevice(OptionPreviewMode.MOBILE)
        }
    }

    const handleClose = () =>
        dispatch(
            setPreviewMode({
                guid: threadGuid,
                previewMode: false,
            })
        )

    const { loading: threadVersionHistoryLoading, latestVersionIsPublished } =
        useThreadVersionHistory()

    const canBeShared = !threadVersionHistoryLoading && latestVersionIsPublished

    return (
        <StyledPreviewModal
            modal
            lockScroll
            open={previewMode}
            onClose={handleClose}
            mobile={previewDevice === OptionPreviewMode.MOBILE}
            overlayStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            nested
        >
            <StyledColumn css={{ width: "100%", height: "100%", gap: "18px" }}>
                <StyledRow
                    css={{
                        padding: "0 12px",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Switch
                        onChangeWithIcon={handleChangeSwitch}
                        options={PreviewOptions}
                        enabled={previewDevice === OptionPreviewMode.MOBILE}
                        style={{ marginRight: 12 }}
                    />
                    <StyledRow css={{ gap: "6px", alignItems: "center" }}>
                        <ShareThreadModal
                            disabled={!canBeShared}
                            on={"click"}
                            keepTooltipInside={".app"}
                            position={"bottom center"}
                            offsetY={10}
                            trigger={
                                <div>
                                    <StyledHeadlandsPopup
                                        disabled={canBeShared}
                                        on={"hover"}
                                        trigger={
                                            <div>
                                                <Button label={"Share"} disabled={!canBeShared} />
                                            </div>
                                        }
                                    >
                                        <StyledSpan>
                                            You must publish the thread first in order to share it
                                        </StyledSpan>
                                    </StyledHeadlandsPopup>
                                </div>
                            }
                        />
                        <StyledCloseIcon onClick={handleClose} />
                    </StyledRow>
                </StyledRow>
                <ThreadPreview isPreview />
            </StyledColumn>
        </StyledPreviewModal>
    )
}

export default PreviewModal
