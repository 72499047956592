import styled from "styled-components"
import { StyledH4Styles } from "../../../../styles/styledcomponents"
import { Link } from "react-router-dom"

export const StyledCreatorThreadItem = styled(Link)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px 12px 6px 12px;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border-radius: ${({ theme }) => theme.borderRadius1};
    text-decoration: none;

    h4 {
        ${StyledH4Styles};
        color: ${({ theme }) => theme.colors.base.uiLabelBase};
    }

    body2 {
        color: ${({ theme }) => theme.colors.base.uiLabelDisabled};
    }
`
