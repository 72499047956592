export const InsertPhotoIcon = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31.5 28.5V7.5C31.5 5.85 30.15 4.5 28.5 4.5H7.5C5.85 4.5 4.5 5.85 4.5 7.5V28.5C4.5 30.15 5.85 31.5 7.5 31.5H28.5C30.15 31.5 31.5 30.15 31.5 28.5ZM12.75 20.25L16.5 24.765L21.75 18L28.5 27H7.5L12.75 20.25Z"
                fill="#888888"
            />
        </svg>
    )
}
