import {
    Article,
    DragIndicator,
    ExpandMore,
    Lock,
    PlayArrow,
    ScheduleSend,
    Add,
    Error,
    Link,
} from "@mui/icons-material"
import Popup from "reactjs-popup"
import styled, { css } from "styled-components"
import { StyledRichTextEditorContainer } from "../../../components/RichTextEditorNew/styles"
import { StyledBox, StyledH6, StyledP } from "../../../styles/styledcomponents"

export const StyledCollapsibleRow = styled(StyledBox)`
    background-color: ${({ theme }) => theme.headlandsWhite};
    border-radius: 0 0 ${({ theme }) => theme.borderRadius1} ${({ theme }) => theme.borderRadius1};

    &:not(:first-child) {
        border-top: 1px solid ${({ theme }) => theme.headlandsGray3};
    }
`

export const StyledRowHeader = styled(StyledBox)`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 12px 6px;

    ${StyledH6} {
        color: ${({ theme }) => theme.colors.base.uiLabelBase};
    }
`

export const StyledDragIndicator = styled(DragIndicator)`
    color: ${({ theme }) => theme.headlandsGray3};
    font-size: 18px !important;
    cursor: grab;
`

const CircleIcon = css`
    color: ${({ theme }) => theme.headlandsGray10};
    padding: 4px;
    background-color: ${({ theme }) => theme.headlandsGray4};
    border-radius: 50%;
    font-size: 14px !important;
`

export const StyledArticleIcon = styled(Article)`
    ${CircleIcon}
`

export const StyledPlayIcon = styled(PlayArrow)`
    ${CircleIcon}
`

export const StyledLockIcon = styled(Lock)`
    ${CircleIcon}
`

export const StyledSendIcon = styled(ScheduleSend)`
    ${CircleIcon}
`

export const StyledAddIcon = styled(Add)`
    ${CircleIcon}
`

export const StyledLinkIconBase = styled(Link)`
    font-size: 14px !important;
    transform: rotate(-45deg);
`

export const StyledLinkIcon = styled(StyledLinkIconBase)`
    ${CircleIcon};
    cursor: pointer;
`

export const StyledChevronDown = styled(ExpandMore)<{ open: boolean }>`
    ${CircleIcon};
    background: none;
    cursor: pointer;
    transition: transform 0.2s !important;

    ${({ open }) =>
        open &&
        css`
            transform: rotate(180deg);
        `}
`

export const StyledCollapsibleContainer = styled(StyledBox)<{ open: boolean; height?: number }>`
    height: 0;
    transition: height 0.2s, padding 0.2s;
    overflow: hidden;
    padding-left: 24px;

    ${({ open, height }) =>
        open &&
        css`
            height: ${height ? `${height}px` : "auto"};
            padding-bottom: 12px;
        `}

    ${StyledP} {
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        color: ${({ theme }) => theme.headlandsGrayDark};
        margin-bottom: 6px;
    }

    ${StyledRichTextEditorContainer} {
        flex: 1;
    }
`

export const StyledAddMessageContainer = styled(StyledBox)`
    display: flex;
    align-self: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    margin-top: 12px;

    ${StyledAddIcon} {
        font-size: 16px !important;
        padding: 3px;
    }
`

export const StyledErrorIcon = styled(Error)`
    font-size: 12px;
`

export const StyledChannelPopup = styled(Popup)`
    &-content {
        background-color: ${(props) => props.theme.headlandsGrayDark};
        padding: 6px 12px;
        border-radius: ${(props) => props.theme.borderRadius1};

        span {
            color: ${(props) => props.theme.headlandsWhite};
            font-size: 14px;
        }
    }
    &-overlay {
        // note: this !important is necessary because on Modal.scss we overwrite the background-color
        background-color: transparent !important;
    }
`
