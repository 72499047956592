import { useTheme } from "styled-components"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import {
    Block,
    BlocksType,
    BlockType,
    ReadOnlyBlock,
    SortableOutlineGroupType,
} from "../../../../types"
import { compact, first, flatten, isNil, values } from "lodash"
import { getPlainTextFromHtml, isHTML } from "../../../../utils/utils"
import { StyledAddIcon, StyledMoreHorizIcon, StyledThreadOutlineBarBlockItem } from "../styles"
import { StyledImage } from "../../../../styles/styledcomponents"
import textIcon from "../../../../assets/images/chunk_icon_text.png"
import EllipsisText from "../../../../components/EllipsisText"
import { useState } from "react"
import { ThreadOutlineBarItemProps } from "./index"
import ThreadOutlineBarItemMenu from "./ThreadOutlineBarItemMenu"
import AddChunkMenu from "../../AddChunkMenu"

interface ThreadOutlineBarBlockItemProps extends ThreadOutlineBarItemProps {
    sectionId?: string
}

const ThreadOutlineBarBlockItem = ({
    blockId,
    navigateToItem,
    sectionId,
    blockIndex,
    ...commonHandlers
}: ThreadOutlineBarBlockItemProps) => {
    const theme = useTheme()
    const selected = useSelector(
        (state: RootState) => state.blocksReducer.selectedChunkId === blockId
    )
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [hovered, setHovered] = useState(false)

    const blockItemText = useSelector((state: RootState) => {
        const block = state.blocksReducer[BlocksType.BLOCKS][blockId]
        switch (block.type) {
            case BlockType.GROUP: {
                const findGroup = (
                    blockId: string,
                    blocksIndex: { [key: number]: ReadOnlyBlock }
                ): ReadOnlyBlock | undefined => {
                    return first(
                        compact(
                            flatten(
                                values(blocksIndex).map((value) => {
                                    // find group at the root level
                                    if (value.id === blockId) return value
                                    // find group inside sections
                                    if (value.objects) return findGroup(blockId, value.objects)
                                    return null
                                })
                            )
                        )
                    )
                }

                const blockIndex = findGroup(
                    blockId,
                    state.blocksReducer[`${BlocksType.BLOCKS}Index`]
                )

                const blockObjectIds = values(first(values(blockIndex?.branches))?.objects).map(
                    (readonlyBlock) => readonlyBlock.id
                )
                const firstTextBlock = values(state.blocksReducer[BlocksType.BLOCKS]).find(
                    (blockItem) =>
                        blockObjectIds.includes(blockItem.id) && blockItem.type === BlockType.TEXT
                )

                if (firstTextBlock?.value)
                    return !isHTML(firstTextBlock.value)
                        ? firstTextBlock.value
                        : getPlainTextFromHtml(firstTextBlock.value) || ""
                return ""
            }
            case BlockType.CHOICE_QUESTION:
            case BlockType.FREE_TEXT_QUESTION: {
                if (!block.question) return ""
                return !isHTML(block.question)
                    ? block.question
                    : getPlainTextFromHtml(block.question) || ""
            }
            case BlockType.INCLUDE: {
                return "Brick"
            }
            case BlockType.CONDITIONAL: {
                return "Conditional"
            }
            case BlockType.SET_STRING_VARIABLE: {
                return "Set Variable"
            }
            case BlockType.COMMENT: {
                const value = block.value || "Comment"
                return !isHTML(value) ? value : getPlainTextFromHtml(value) || ""
            }
            case BlockType.NOTIFICATION: {
                return "Message"
            }
            default: {
                return ""
            }
        }
    })

    return (
        <StyledThreadOutlineBarBlockItem
            id={blockId}
            selected={selected}
            className={SortableOutlineGroupType.CHUNK}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <StyledImage
                src={textIcon}
                css={{ width: "52px", marginRight: "6px" }}
                onClick={() => navigateToItem(blockId, true)}
            />
            <EllipsisText
                variant={"span"}
                text={blockItemText}
                textStyles={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: theme.colors.base.uiLabelBase,
                    fontWeight: 400,
                }}
                maxWidth={hovered ? 160 : 200}
                popupOptions={{ position: "right center", mouseEnterDelay: 1000 }}
                onClick={() => navigateToItem(blockId, true)}
            />
            <ThreadOutlineBarItemMenu
                blockId={blockId}
                blockIndex={blockIndex}
                sectionId={sectionId}
                onOpen={() => setMenuIsOpen(true)}
                onClose={() => setMenuIsOpen(false)}
                trigger={<StyledMoreHorizIcon fontSize={"small"} $show={menuIsOpen} />}
                {...commonHandlers}
            />
            <AddChunkMenu
                handleAddChunk={(chunk: Block) =>
                    commonHandlers.handleAddChunk(
                        chunk,
                        !isNil(blockIndex) ? blockIndex + 1 : undefined,
                        sectionId
                    )
                }
                trigger={<StyledAddIcon $show={menuIsOpen} />}
                onOpen={() => setMenuIsOpen(true)}
                onClose={() => setMenuIsOpen(false)}
                chunkParams={{
                    sectionId,
                    index: !isNil(blockIndex) ? blockIndex + 1 : undefined,
                    blockGuid: blockId,
                }}
            />
        </StyledThreadOutlineBarBlockItem>
    )
}

export default ThreadOutlineBarBlockItem
