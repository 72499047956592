import styled, { css } from "styled-components"
import { rgba } from "polished"
import { StyledBox, StyledH4, StyledRow } from "../styles/styledcomponents"

export const EditorContainerStyles = css`
    .react-sortable {
        min-height: 45px;
    }

    .block-container {
        display: flex;
        flex-direction: column;
        background-color: transparent;
        border-radius: ${(props) => props.theme.borderRadius1};
        width: ${({ theme }) => theme.variables.creator.canvasMaxWidth};

        // chunk blocks (E.g. brick and ai chat) should be full-width
        &.chunk:not(.isInPane) {
            width: ${({ theme }) =>
                `calc(${theme.variables.creator.canvasMaxWidth} + ${theme.variables.creator.groupPadding.right} + ${theme.variables.creator.groupPadding.left} + ${theme.variables.creator.iconRowWidth})`};
        }

        &.in-accordion {
            margin-left: -52px;

            .icons-row {
                margin-right: 18px;
            }

            .in-pane {
                margin-left: 12px !important;
            }
        }

        // Styles when dragging the block: hide all the icons of the block, add opacity
        &.dragging {
            .block-row {
                .block-column {
                    .close-icon-container {
                        display: none;
                    }

                    .block,
                    .conditional {
                        opacity: 0.3;
                    }
                }
            }
        }

        // Styles for the ghost block (where it will be after finishing dragging): small bar with blue color
        &.ghost {
            width: calc(100% - ${({ theme }) => theme.variables.creator.iconRowWidth});
            margin-left: ${({ theme }) => theme.variables.creator.iconRowWidth};
            align-self: flex-end;
            height: 6px;
            background-color: rgba(45, 170, 219, 0.3);

            * {
                display: none;
            }
        }

        /* if is a branch remove the max width restrictions */

        &.branch {
            max-width: unset;
            min-width: ${({ theme }) => theme.variables.creator.canvasMaxWidth};
        }

        .block-row {
            display: flex;
            align-items: flex-start;
            position: relative;

            &.in-pane {
                margin-left: -40px;

                .block:hover {
                    background-color: ${(props) => props.theme.headlandsGray6};
                    transition: background-color 0.2s;
                }

                .icons-row {
                    margin-left: -12px;
                    margin-right: 12px;
                }
            }

            .block {
                flex: 1;
            }

            // This handle the warning/advise glow style.
            .block-column {
                .glow-warning {
                    transition: background-color 0.3s ease-in-out;

                    &.on {
                        background-color: ${(props) => rgba(props.theme.headlandsError, 0.2)};
                    }
                }

                .glow-advice {
                    transition: background-color 0.3s ease-in-out;

                    &.on {
                        background-color: ${(props) => rgba(props.theme.headlandsWarning, 0.2)};
                    }
                }
            }

            .icons-row {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                pointer-events: none;
                align-items: center;
                opacity: 0;
                transition: opacity 0.2s;

                &.short-margin {
                    margin-top: 2px;
                }

                &.section {
                    margin-top: 6px;
                }

                &.focused {
                    opacity: 1;
                    pointer-events: initial;
                }
            }

            .block-column {
                flex: 1;
                width: ${({ theme }) =>
                    `calc(100% - ${theme.variables.creator.iconRowWidth} - ${theme.variables.creator.groupPadding.right})`};
                position: relative;

                .top-padding {
                    cursor: pointer;
                    height: 6px;
                    background-color: transparent;
                    transition: background-color 0.2s;
                    border-radius: ${(props) => props.theme.borderRadius1};

                    &:hover {
                        background-color: ${(props) => props.theme.headlandsGray6};
                        transition: background-color 0.2s;
                    }
                }
            }
        }

        &.isInPane {
            &.ghost {
                // as the icons-row is outside the pane there is no need to add margin or
                // a custom width
                width: 100%;
                margin-left: unset;
            }
        }

        &.in-accordion {
            &.ghost {
                // here we have an special situation because a 'block-container' inside
                // an accordion has some content that is actually outside the accordion
                // that's why we have some custom width and margin
                width: calc(100% - 36px);
                margin-left: 18px;
            }
        }
    }

    .bottom-padding {
        height: 12px;
    }
`

export const StyledCreatorScreen = styled(StyledBox)`
    height: 100%;
    display: flex;
    flex: 1;
    position: relative;

    .clickable {
        cursor: pointer;
    }

    .editor-content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100%;
        padding: 0 5vw;
        background-color: ${({ theme }) => theme.headlandsGray4};

        .lint-warnings-and-advices-container {
            align-self: flex-end;
            background-color: transparent;
            position: sticky;
            top: 10px;
            margin-right: 12px;
            z-index: 100;

            .warning-row,
            .advice-row {
                display: flex;
                background-color: ${(props) => props.theme.headlandsWhite};
                justify-content: center;
                align-items: center;
                padding: 4px;
                border-radius: 16px;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);

                .simple-horizontal-margin {
                    margin: 0 4px;
                }

                .double-margin-left {
                    margin-left: 8px;
                }

                &:nth-child(2) {
                    margin-top: 4px;
                }
            }

            .advice-row {
                span {
                    color: ${(props) => props.theme.headlandsWarning};
                    margin: 0 5px;
                }
            }

            .warning-row {
                span {
                    color: ${(props) => props.theme.headlandsError};
                    margin: 0 5px;
                }
            }
        }
    }

    .editor-container {
        min-width: 570px;
        padding: 24px 24px 24px 6px;
        align-items: center;

        ${EditorContainerStyles}
    }
`

export const StyledAddBlockRow = styled(StyledRow)`
    gap: 6px;
    margin: 12px 0 12px 40px;
`

export const StyledIconContainer = styled(StyledBox)`
    cursor: pointer;
    margin-right: 4px;
`
