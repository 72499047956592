import styled from "styled-components"
import {
    HorizontalScrollableContentWithHiddenScroll,
    StyledRow,
} from "../../../../styles/styledcomponents"

export const StyledImageCarouselBlock = styled(StyledRow)`
    min-height: 100px;
    gap: 6px;
    ${HorizontalScrollableContentWithHiddenScroll};
`

export const StyledImageItem = styled.img`
    height: 100px;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.borderRadius1};
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    object-fit: cover;
`
