import { useQuery } from "@apollo/client"
import { StyledInstructorAvatar } from "./styles"
import { GET_CREATOR_THREAD } from "../../../../apollo/queries"
import { useCurrentThreadGuid } from "../../../../hooks/currentThread.hook"
import { useMemo } from "react"
import { getCustomImageUrl } from "../../../../utils/utils"
import defaultAvatar from "../../../../assets/images/default-avatar.png"

const InstructorAvatarBlock = () => {
    const threadGUID = useCurrentThreadGuid()

    const { data } = useQuery(GET_CREATOR_THREAD, {
        variables: { threadGUID },
        fetchPolicy: "cache-only",
    })

    const instructorUrl = data?.getThreadByGuid.instructor?.profileImageURL

    const resizedImageUrl = useMemo(
        () =>
            instructorUrl
                ? getCustomImageUrl(instructorUrl, {
                      height: 30,
                      width: 30,
                      crop: "face",
                      webp: true,
                  })
                : defaultAvatar,
        [instructorUrl]
    )

    return <StyledInstructorAvatar src={resizedImageUrl} />
}

export default InstructorAvatarBlock
