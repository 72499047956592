import { IconProps } from "../../types"

export const ArrowBackIcon = ({
    width = 32,
    height = 32,
    color = "#888888",
    ...rest
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <g clip-path="url(#clip0_27550_3760)">
                <path
                    d="M27.1667 14.6667H10.94L18.3934 7.21337L16.5 5.33337L5.83337 16L16.5 26.6667L18.38 24.7867L10.94 17.3334H27.1667V14.6667Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_27550_3760">
                    <rect x="0.5" width="32" height="32" rx="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
