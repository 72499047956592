import { useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { Path } from "typescript"
import { LOGIN_AS } from "../../apollo/mutations"

const LoginAs = () => {
    const { id } = useParams<any>()
    const history = useHistory()
    const [status, setStatus] = useState<string>()

    const [loginAs] = useMutation(LOGIN_AS, {
        onCompleted: (res) => {
            if (res.loginAs.jwt) {
                localStorage.setItem("token", res.loginAs.jwt)
                setStatus("success")
                const redirectUrl = localStorage.getItem("redirectUrl")
                if (redirectUrl) {
                    history.push(redirectUrl as Path)
                    localStorage.removeItem("redirectUrl")
                } else {
                    history.push("/" as Path)
                }
            }
        },
        onError: (e) => {
            setStatus("error")
            console.error(e)
        },
    })

    useEffect(() => {
        void loginAs({ variables: { id: id } })
    }, [])

    return <div>{status}</div>
}

export default LoginAs
