import { useMemo } from "react"
import { LibraryItem } from "../../../components/LibraryBar/LibraryBar"
import { StyledBody1, StyledH4 } from "../../../styles/styledcomponents"
import { StyledActivityListContainer } from "../../styles"
import { useEnrolledProgramThreadsByGuid } from "../../../hooks/enrolledProgramThread.hook"
import { isEmpty, last, sortBy } from "lodash"
import { useEnrolledPrograms } from "../../../hooks/enrolledProgram.hook"
import { ThreadStatus } from "../../../apollo/generated/graphql"
import { mapEnrolledProgramThread } from "../../../components/LibraryBar/PlaylistBar"
import { useHistory } from "react-router"
import { StyledEmptyActivity } from "./styles"
import { Button } from "../../../components/Button/Button"
import LibraryRow from "../../../components/LibraryBar/LibraryRow"

const ActivityList = () => {
    const enrolledPrograms = useEnrolledPrograms()
    const programsWithLeaderboard = enrolledPrograms.enrolledPrograms?.filter(
        (program) => program.leaderboard
    )
    const latestProgram = last(sortBy(programsWithLeaderboard, "startTime"))
    const { enrolledProgramThreads } = useEnrolledProgramThreadsByGuid(latestProgram?.guid)

    const activityItems: LibraryItem[] | undefined = useMemo(
        () =>
            enrolledProgramThreads
                ?.filter((item) =>
                    [ThreadStatus.InProgress, ThreadStatus.Completed].includes(item.status)
                )
                .map((item) =>
                    mapEnrolledProgramThread(item, latestProgram?.guid, latestProgram?.id)
                ),
        [enrolledProgramThreads, latestProgram]
    )

    return (
        <StyledActivityListContainer>
            <StyledH4 useNewDesign>Activity</StyledH4>
            {activityItems &&
                (isEmpty(activityItems) ? (
                    <EmptyActivity />
                ) : (
                    activityItems.map((item, index) => (
                        <LibraryRow key={`library-item-${item.type}-${index}}`} {...item} />
                    ))
                ))}
        </StyledActivityListContainer>
    )
}

const EmptyActivity = () => {
    const history = useHistory()
    return (
        <StyledEmptyActivity>
            <StyledBody1>🫤 You haven’t played any threads yet.</StyledBody1>
            <Button
                label={"Play a Thread"}
                buttonStyle={{ width: "fit-content" }}
                onClick={() => history.push("/program")}
            />
        </StyledEmptyActivity>
    )
}

export default ActivityList
