import styled from "styled-components"
import { rgba } from "polished"
import CloseIcon from "@mui/icons-material/Close"
import { StyledRow } from "../../styles/styledcomponents"
import StyledButton from "../Button/styles"

export const StyledClosedIcon = styled(CloseIcon)`
    &:hover {
        cursor: pointer;
    }
`
export const StyledTag = styled.div<{ error?: boolean; preserveCase?: boolean }>`
    display: flex;
    align-items: center;
    background-color: ${({ theme, error }) =>
        error ? rgba(theme.headlandsError, 0.3) : rgba(theme.headlandsPrimaryColor, 0.2)};
    padding: 3px;
    border-radius: 3px;
    width: fit-content;
    height: fit-content;

    span {
        font-size: 10px;
        font-weight: 500;
        line-height: 10px;
        text-transform: ${({ preserveCase }) => (preserveCase ? "none" : "lowercase")};
        color: ${({ theme, error }) =>
            error ? theme.headlandsError : theme.headlandsPrimaryColor};
    }

    svg {
        width: 14px;
        height: 14px;
        fill: ${({ theme, error }) => (error ? theme.headlandsError : theme.headlandsPrimaryColor)};
    }

    &:hover {
        background-color: ${({ theme, error }) =>
            error ? theme.headlandsError : theme.headlandsPrimaryColor};
        transition: background-color 0.2s;

        span {
            color: ${({ theme }) => theme.headlandsWhite};
            transition: color 0.2s;
        }

        svg {
            fill: ${({ theme }) => theme.headlandsWhite};
            transition: fill 0.2s;
        }
    }
`

export const StyledTagsContainer = styled.div`
    display: flex;
    align-content: flex-start;
    gap: 3px;
    width: 100%;
    height: 166px;
    border-radius: ${({ theme }) => theme.borderRadius1};
    border: 1px solid ${({ theme }) => theme.headlandsGray3};
    margin: 18px 0;
    flex-wrap: wrap;
    padding: 6px;
    overflow-y: auto;

    input {
        flex: 1;
        border: none;

        &:focus {
            outline: none;
        }
    }
`

export const StyledModalFooter = styled(StyledRow)`
    width: 100%;
    justify-content: flex-end;

    ${StyledButton} {
        width: fit-content;
    }
`
