import ReactQuill, { ReactQuillProps } from "react-quill"
import { editorModules } from "../../utils/consts"
import { StyledRichTextEditorContainer } from "./styles"
import { StyledInputLabel } from "../InputNew/styles"

interface RichTextEditorProps extends ReactQuillProps {
    label?: string
}

const modules = {
    toolbar: editorModules.toolbar.filter((option: any) => !option.header && option !== "ai"),
}
const RichTextEditor = ({ label, ...reactQuillProps }: RichTextEditorProps) => {
    return (
        <StyledRichTextEditorContainer>
            {label && <StyledInputLabel>{label}</StyledInputLabel>}
            <ReactQuill modules={modules} {...reactQuillProps} />
        </StyledRichTextEditorContainer>
    )
}

export default RichTextEditor
