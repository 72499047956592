import { useMemo } from "react"
import LoaderSkeleton from "../index"
import { BlockLoaderSide, BlockLoaderType, LoaderType } from "../../../../types"
import { StyledBox } from "../../../../styles/styledcomponents"

export type ThreadItemSkeleton = {
    type: BlockLoaderType | undefined
    from: string | undefined
}

const defaultSkeletonList: ThreadItemSkeleton[] = [
    { type: BlockLoaderType.MEDIUM_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.MEDIUM_TEXT, from: BlockLoaderSide.STUDENT },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.IMAGE, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.SHORT_TEXT, from: BlockLoaderSide.STUDENT },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.MEDIUM_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.SHORT_TEXT, from: BlockLoaderSide.STUDENT },
    { type: BlockLoaderType.MEDIUM_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.SHORT_TEXT, from: BlockLoaderSide.STUDENT },
    { type: BlockLoaderType.MEDIUM_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.SHORT_TEXT, from: BlockLoaderSide.STUDENT },
]

const creatorSkeletonList: ThreadItemSkeleton[] = [
    { type: BlockLoaderType.PANE, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.IMAGE, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.SHORT_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.MEDIUM_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.SHORT_TEXT, from: BlockLoaderSide.STUDENT },
    { type: BlockLoaderType.MEDIUM_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.MEDIUM_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.SHORT_TEXT, from: BlockLoaderSide.STUDENT },
    { type: BlockLoaderType.MEDIUM_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.SHORT_TEXT, from: BlockLoaderSide.STUDENT },
    { type: BlockLoaderType.MEDIUM_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.LONG_TEXT, from: BlockLoaderSide.INSTRUCTOR },
    { type: BlockLoaderType.SHORT_TEXT, from: BlockLoaderSide.STUDENT },
]

interface ThreadSkeletonProps {
    creator?: boolean
}

export const ThreadSkeleton = ({ creator }: ThreadSkeletonProps) => {
    let canvasSpace = window.outerHeight - 80
    const maxHeightByDevice = window.outerWidth > 600 ? 250 : 150

    const skeletonsToRender = useMemo(() => {
        let listOfSkeleton: ThreadItemSkeleton[] = []
        const baseList = creator ? creatorSkeletonList : defaultSkeletonList
        baseList.forEach((skeletonItem) => {
            if (canvasSpace > maxHeightByDevice) {
                const quantityToRemove =
                    skeletonItem.type === BlockLoaderType.IMAGE
                        ? 190
                        : skeletonItem.type === BlockLoaderType.PANE
                        ? 366
                        : 42
                canvasSpace = canvasSpace - quantityToRemove
                listOfSkeleton = [...listOfSkeleton, skeletonItem]
            }
        })
        return listOfSkeleton
    }, [])

    return (
        <StyledBox css={{ height: "100%", padding: "0 18px" }}>
            {skeletonsToRender.map((item, index) => (
                <LoaderSkeleton
                    key={index}
                    blockType={item.type}
                    from={item.from}
                    type={LoaderType.BLOCK}
                />
            ))}
        </StyledBox>
    )
}
