import { StyledIconButton } from "./styles"

interface IconButtonProps {
    icon: JSX.Element
    onClick: (event?: any) => void
    rounded?: boolean
    disabled?: boolean
    transparent?: boolean
}
const IconButton = ({
    icon,
    rounded = true,
    disabled = false,
    transparent = false,
    ...rest
}: IconButtonProps) => {
    return (
        <StyledIconButton rounded={rounded} disabled={disabled} transparent={transparent} {...rest}>
            {icon}
        </StyledIconButton>
    )
}

export default IconButton
