import styled from "styled-components"
import { StyledBox } from "../styles/styledcomponents"

export const StyledAdminScreen = styled(StyledBox)`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${({ theme }) => theme.headlandsGray4};
    overflow: hidden;
`

export const StyledIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
`
