import { IconProps } from "../../types"

export const DesignerFundLogoIcon = ({ className }: IconProps) => {
    return (
        <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || ""}
        >
            <rect width="38" height="38" rx="10" fill="#37827A" />
            <path
                d="M11.3452 18.6142C11.8281 18.3695 12.5793 18.1249 13.0443 18.0308C14.189 17.7861 16.0133 17.7861 17.5514 17.7861C17.5514 18.5389 17.5872 19.4987 17.5514 20.2515C15.5304 20.2515 14.189 20.4585 12.7582 21.4559C11.0591 22.698 10.7014 24.6176 10.755 26.1796C10.8802 28.3815 12.2932 30 14.5109 30C18.0344 30 20.4131 27.8358 20.4131 23.225C22.2374 23.225 23.8471 23.1497 24.8844 22.5663C26.0291 21.9076 26.6551 20.4397 26.1901 19.1976C25.7072 19.4987 25.117 19.781 24.0975 20.0257C23.4357 20.195 22.2016 20.3644 20.4489 20.3644C20.4489 19.988 20.4131 18.2002 20.4489 17.8238C23.6325 17.8238 25.421 16.657 26.2616 15.3208C26.8876 14.361 27.299 13.2318 27.2453 11.5004C27.1201 9.78785 25.8681 8 23.4357 8C19.7335 8 17.5872 10.6159 17.5872 14.9444C15.6198 14.9444 13.7061 14.8503 12.5972 15.4902C11.4883 16.1488 11.0591 17.5415 11.3452 18.6142ZM20.431 14.9444C20.431 11.7827 21.3074 9.12917 23.5252 9.12917C25.0275 9.12917 25.9754 10.0513 25.9754 11.4628C25.9754 14.0411 23.4357 14.9444 20.431 14.9444ZM13.5451 23.7331C14.654 23.1121 15.9239 23.1497 17.5693 23.1497C17.5514 26.0667 16.9255 28.8896 14.4573 28.8896C13.0801 28.8896 11.9354 28.0051 11.9354 26.3114C11.9712 25.1069 12.6509 24.1848 13.5451 23.7331Z"
                fill="white"
            />
        </svg>
    )
}
