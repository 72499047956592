import { useFormikContext } from "formik"
import { debounce } from "lodash"
import { useCallback, useEffect } from "react"

interface FormikAutoSaveProps {
    wait?: number
}

const FormikAutoSave = ({ wait = 1000 }: FormikAutoSaveProps) => {
    const formik = useFormikContext()
    const { values } = formik

    // the useCallback is used to avoid regenerating the function each time the component props changes
    const debouncedAutoSave = useCallback(
        debounce(() => formik.submitForm(), wait),
        []
    )

    useEffect(() => {
        debouncedAutoSave()
    }, [values])

    return null
}

export default FormikAutoSave
