import { MoreHoriz } from "@mui/icons-material"
import styled from "styled-components"
import {
    StyledBody1,
    StyledBody2,
    StyledBox,
    StyledChevron,
} from "../../../styles/styledcomponents"

type StyledComponentSelectorProps = {
    left: number
    top?: number
    bottom?: number
}

export const StyledComponentSelector = styled(StyledBox)<StyledComponentSelectorProps>`
    left: ${({ left }) => `${left}px`};
    top: ${({ top }) => `${top}px` || "auto"};
    bottom: ${({ bottom }) => `${bottom}px` || "auto"};
    position: absolute;
    z-index: 999;
    background-color: ${({ theme }) => theme.headlandsWhite};
    width: 320px;
    max-height: 408px;
    border: 1px solid ${({ theme }) => theme.headlandsGray3};
    box-sizing: border-box;
    box-shadow: ${({ theme }) => theme.boxShadow1};
    border-radius: 4px;
    padding: 10px;
    overflow-y: scroll;
`

type StyledOptionProps = {
    selected?: boolean
}

export const StyledOption = styled(StyledBox)<StyledOptionProps>`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 6px;
    padding: 6px;
    border-radius: 4px;
    background-color: ${({ selected, theme }) => (selected ? theme.headlandsGray4 : "unset")};

    &:hover {
        background-color: ${({ theme }) => theme.headlandsGray4};
    }

    &:last-child {
        margin-bottom: 0;
    }

    ${StyledChevron} {
        transform: rotate(270deg);
        color: ${({ theme }) => theme.headlandsGray10};
        font-size: 18px;
    }
`

export const StyledTextOptionContainer = styled(StyledBox)`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    ${StyledBody1} {
        color: ${({ theme }) => theme.colors.base.uiLabelBase};
    }

    ${StyledBody2} {
        color: ${({ theme }) => theme.colors.base.uiLabelDisabled};
    }
`

export const StyledIconWrapper = styled(StyledBox)`
    height: 42px;
    width: 42px;
    border-radius: 50%;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.base.uiPrimary500};
`

export const StyledAiIconWrapper = styled(StyledBox)`
    height: 28px;
    width: 28px;
    border-radius: 50%;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.headlandsGray3};
    padding: 6px;
    box-sizing: border-box;

    svg {
        margin: 0;
    }
`

export const StyledMoreIcon = styled(MoreHoriz)`
    color: ${({ theme }) => theme.headlandsGray2};
    width: 12px !important;
    padding: 0 8px;
    margin-right: 12px;
`
