import styled, { css } from "styled-components"
import { StyledRightBubble } from "../styles"
import { rgba } from "polished"

interface StyledChoiceOptionProps {
    selected?: boolean
    active?: boolean
    disabled?: boolean
    percentage?: number
    multiple?: boolean
    readonly?: boolean
    noPoll?: boolean
}

export const StyledChoicePollOption = styled(StyledRightBubble)<StyledChoiceOptionProps>`
    border: solid 1px ${(props) => props.theme.colors.thread.student.bubble};
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    max-width: unset;
    padding: 11px 18px;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    margin: 3px 0;
    /*
    * This line was added to solve the safari issue with round corners
    * https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
    */
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    // this styles handles the percentage animation
    ::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        z-index: -1;
        transition: width 1s;
        border-radius: ${({ noPoll }) => (noPoll ? "unset" : "inherit")};
        box-sizing: border-box;
        background: ${({ selected, percentage, noPoll, theme }) => css`linear-gradient(
            // A gradient tilted 90 deg
            90deg,
            // Starting with the primary color and stops at the given percentage
            ${rgba(theme.colors.thread.student.bubble, noPoll ? 1 : 0.2)} ${percentage}%,
            // Continuing from that same percentage with the same color (but with 85 opacity)
            ${
                selected ? theme.colors.thread.student.font : theme.colors.thread.student.font
            }85 ${percentage}%
        )`};
    }

    span {
        line-height: 18px;
        font-weight: 600;
        color: ${({ theme, noPoll, selected }) =>
            noPoll && selected
                ? theme.colors.thread.student.font
                : theme.colors.thread.student.bubble};
        transition: color 1s;
    }

    // these styles are set to make the grow transition (when submitting a choice)
    // the choice option text
    span:first-of-type {
        flex-grow: 1;
        transition: flex 1s;
    }
    // and empty div to handle the space
    #choice-poll-space {
        flex: 0;
        transition: flex 1s;
        text-align: end;
    }

    // if selected add the border-radius transition if not avoid adding the right bubble border-radius
    ${({ selected }) =>
        selected
            ? css<{ noPoll?: boolean }>`
                  border: ${({ noPoll, theme }) =>
                      `solid ${noPoll ? 1 : 3}px ${theme.colors.thread.student.bubble}`};
                  transition: border 0.2s, border-radius 0.2s;
                  ::before {
                      border-radius: ${({ noPoll }) => (noPoll ? "unset" : "12px 12px 0 12px")};
                  }
              `
            : css<StyledChoiceOptionProps>`
                  border-radius: ${({ multiple }) => (multiple ? "6px" : "40px")};
              `}

    &:hover {
        cursor: pointer;
    }

    // choice style depending on whether is disabled or not
    ${(props) =>
        props.disabled &&
        css`
            user-select: none;
            cursor: default;
            &:hover {
                cursor: default;
            }
            // these styles are set to make the grow transition (when submitting a choice)
            span:first-of-type {
                flex-grow: 0;
            }
            #choice-poll-space {
                flex-grow: 1;
            }
        `}

    // choice opacity depending on whether is disabled and not selected
    ${(props) =>
        props.disabled &&
        !props.selected &&
        css`
            // the important is needed to overwrite the opacity of the StyledRightBubble
            opacity: 0.5 !important;
            &:hover {
                opacity: 0.8 !important;
            }
        `}

    // percentage style make the gradient visible
    ${(props) =>
        props.percentage !== undefined &&
        css`
            ::before {
                width: 100%;
                height: 100%;
            }
        `}

    // change the background and the span color when is an active choice
    ${({ active }) =>
        active &&
        css`
            background-color: ${({ theme }) => theme.colors.thread.student.bubble};
            span {
                color: ${({ theme }) => theme.colors.thread.student.font};
            }
        `}

    ${({ readonly }) =>
        readonly &&
        css`
            &:hover {
                cursor: default;
            }
        `}
`

export const StyledPollClarificationRow = styled.div<{
    answered?: boolean
}>`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 3px 12px;

    span {
        font-size: 12px;
        color: ${({ theme }) => theme.headlandsGray2};
        margin-right: ${({ answered }) => (answered ? 0 : "12px")};
    }
`
