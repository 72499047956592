import styled, { css } from "styled-components"
import { rgba } from "polished"
import {
    HorizontalScrollableContentWithHiddenScroll,
    StyledBox,
    StyledRow,
} from "../../../../styles/styledcomponents"
import { StyledCloseButtonContainer } from "../../../../components/CloseButton/styles"

export const StyledConditional = styled(StyledBox)`
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 6px 0;
    min-width: ${({ theme }) => theme.variables.creator.canvasMaxWidth};

    input {
        background-color: transparent;
    }

    .condition {
        display: flex;
        flex-direction: column;
        position: relative;

        .input {
            margin: 0;
        }

        .condition-row {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-top: 6px;
            padding-right: 24px;
            // todo improve this element width. Right now he had to set this max-width because the input of the of the
            //  keywords grows when having lot of keywords inside
            max-width: 600px;

            &:first-child {
                margin-top: 0;
            }

            .condition-column {
                display: flex;
                flex-direction: column;
                flex: 1;

                &.hide-overflow {
                    overflow: hidden;
                }

                .collapsible {
                    height: 100%;

                    &.collapsed {
                        height: 0;
                        overflow: hidden;
                    }

                    .operator,
                    .value {
                        margin: 6px 3px 0;
                    }
                }

                .border {
                    height: inherit;
                    border-left: 2px solid ${({ theme }) => theme.headlandsGray3};
                    margin-left: -12px;

                    &.focused {
                        border-left: 2px solid ${({ theme }) => theme.headlandsPrimaryColor};
                    }
                }

                .operator,
                .value {
                    padding: 5px 12px;
                    background-color: ${({ theme }) => theme.headlandsGray4};
                    border: 1px solid ${({ theme }) => theme.headlandsGray3};
                    margin: 0 3px;
                    border-radius: 16px;
                    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.05);
                }

                .operator {
                    text-align: center;
                    font-weight: bold;
                }
            }

            .dropdown-button {
                cursor: pointer;
                padding: 4px;
                transition: transform 0.3s;
                height: fit-content;
                margin-top: 8px;

                &.collapsed {
                    transform: rotate(-90deg);
                }
            }

            .else-container {
                display: flex;
                position: relative;
                flex: 1;
                padding: 12px 10px;

                .line {
                    border-bottom: 1px dashed ${({ theme }) => theme.headlandsGray10};
                    width: 100%;
                }

                .else {
                    display: flex;
                    position: absolute;
                    padding: 0 6px;
                    border-radius: ${({ theme }) => theme.borderRadius1};
                    background-color: ${({ theme }) => theme.headlandsWhite};
                    margin: auto;
                    left: 0;
                    right: 0;
                    bottom: 2px;
                    width: fit-content;

                    span {
                        font-size: 12px;
                        line-height: 20px;
                        color: ${({ theme }) => theme.headlandsGray10};
                        text-transform: uppercase;
                    }
                }
            }

            .value-input {
                flex: 1;
                margin: 0 3px;
                box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.05) !important;
                background-color: ${({ theme }) => theme.headlandsWhite};

                &:focus,
                &:focus-visible {
                    box-shadow: none;
                    border: 1px solid ${({ theme }) => theme.headlandsGray8};
                    color: ${({ theme }) => theme.headlandsBlack};
                }
            }

            .react-select__multi-value {
                display: none;
                margin: 0;

                // Only show the first selected item
                &:first-child {
                    display: flex;
                }

                .react-select__multi-value__label {
                    padding: 0;
                    background-color: ${({ theme }) => theme.headlandsWhite};
                    color: ${({ theme }) => theme.headlandsGray2};
                }

                .react-select__multi-value__remove {
                    display: none;
                }
            }

            &:hover {
                ${StyledCloseButtonContainer} {
                    display: flex;
                }
            }

            &.collapsed {
                margin-bottom: 12px;
            }
        }

        .objects-container {
            border-left: 2px solid ${({ theme }) => theme.headlandsGray8};
            padding: 6px;
            margin-left: 10px;
            margin-bottom: 6px;

            &.focused {
                border-left: 2px solid ${({ theme }) => theme.headlandsPrimaryColor};
            }

            &.collapsed {
                height: 0;
                padding: 0;
                margin: 0;
                overflow: hidden;
            }
        }
    }

    .else-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${({ theme }) => theme.headlandsGray10};

        border-radius: 16px;
        width: fit-content;
        padding: 0 6px;
        cursor: pointer;
        opacity: 0.7;

        img {
            transform: rotate(-90deg);
            width: 10px;
            margin-right: 6px;
        }

        span {
            text-transform: uppercase;
            font-size: 12px;
            line-height: 18px;
            color: ${({ theme }) => theme.headlandsGray10};
        }
    }

    .add-condition-container {
        display: flex;
        position: relative;
        flex: 1;
        padding: 12px 10px;
        opacity: 0;

        &:hover {
            opacity: 1;
        }

        .line {
            border-bottom: 1px dashed ${({ theme }) => theme.headlandsGray10};
            width: 100%;
        }

        .add-condition {
            display: flex;
            position: absolute;
            padding: 0 6px;
            border-radius: ${({ theme }) => theme.borderRadius1};
            border: 1px solid ${({ theme }) => theme.headlandsGray10};
            background-color: ${({ theme }) => theme.headlandsGray3};
            margin: auto;
            left: 0;
            right: 0;
            bottom: 2px;
            width: fit-content;
            cursor: pointer;

            img {
                margin-right: 6px;
            }

            span {
                font-size: 12px;
                line-height: 18px;
                color: ${({ theme }) => theme.headlandsGray10};
            }
        }
    }

    .variable-select {
        width: 186px;

        .chevron {
            transition: 0.3s;

            &.open {
                transform: rotate(180deg);
            }
        }
    }
`

export const StyledConditionalInput = styled.div<{ disabled?: boolean; tagStyleValues?: boolean }>`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.headlandsGray8};
    border-radius: ${({ theme }) => theme.borderRadius2};
    background-color: ${({ disabled, theme }) =>
        disabled ? theme.headlandsGray4 : theme.headlandsWhite};
    padding: 5px 11px;
    height: 32px;
    box-sizing: border-box;
    overflow-x: hidden;

    &:hover {
        cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    }

    svg {
        fill: ${({ theme }) => theme.headlandsGray10};
    }

    ${({ tagStyleValues }) =>
        tagStyleValues &&
        css`
            span {
                font-size: 10px;
                line-height: 10px;
                color: ${({ theme }) => theme.headlandsPrimaryColor};
                background-color: ${({ theme }) => rgba(theme.headlandsPrimaryColor, 0.2)};
                padding: 3px;
                border-radius: 3px;
                text-transform: lowercase;

                &:not(:first-of-type) {
                    margin-left: 3px;
                }
            }
        `}
`

export const StyledKeywordPillsContainer = styled(StyledBox)`
    display: flex;
    ${HorizontalScrollableContentWithHiddenScroll};
`

export const StyledAddBlockRow = styled(StyledRow)`
    gap: 6px;
    margin: 12px 0 0 40px;
`
