import styled, { css } from "styled-components"

export const StyledNavBar = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: ${({ theme }) => theme.headlandsGray5};
    width: calc(${({ theme }) => theme.sidebarWidths.nav} * 1px);
    color: ${({ theme }) => theme.headlandsGrayDark};
    padding: 42px 15px;
    box-sizing: border-box;
`

export const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
`

export const StyledBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const StyledIconContainer = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  margin: 3px 0;
  border-radius: ${({ theme }) => theme.borderRadius1};

  &:hover {
    background-color: ${({ theme }) => theme.headlandsPrimaryColorAlpha};
    transition: all 0.3s ease-in;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.headlandsPrimaryColor};
    }
  }

  ${({ active }) =>
      active &&
      css`
          background-color: ${({ theme }) => theme.headlandsPrimaryColorAlpha};
          cursor: pointer;

          path {
              fill: ${({ theme }) => theme.headlandsPrimaryColor};
          }
      `}
}
`
