import { IconProps } from "../../types"

export const BoltIcon = ({ width = 42, height = 43, color = "#444444" }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={"0 0 42 43"}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Offline bolt" clip-path="url(#bolt_clip_path">
                <path
                    id="Vector"
                    d="M26.5722 8.36544L13.479 22.4568L19.545 24.0822L16.698 34.7074L29.5208 20.5435L23.7252 18.9906L26.5722 8.36544Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="bolt_clip_path">
                    <rect width="42" height="42" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}
