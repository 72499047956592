import { CarouselContainer } from "./styles"
import { AddIcon } from "../../../assets/icons/AddIcon"
import CloseButton from "../../../components/CloseButton"
import { Block, BlocksType, QuestionChoice } from "../../../types"
import { InsertPhotoIcon } from "../../../assets/icons/InsertPhotoIcon"
import { generateUUID } from "../../../utils/utils"
import { updateBlock } from "../../../redux/blocks"
import { useDispatch } from "react-redux"
import { useReactiveVar } from "@apollo/client"
import { useEffect, useState } from "react"
import MediaModal from "../MediaModal/MediaModal"
import { selectedBlockVar } from "../../../apollo/cache-store"
import { OverlayLoader } from "../../../components/Loader"
import classNames from "classnames"
import { StyledBlockContent } from "../../../styles/styledcomponents"

interface CarouselImageProps {
    blocksType: BlocksType
    block: Block
}

const CarouselImage = ({ blocksType, block }: CarouselImageProps) => {
    const imageList = block.choices! || []
    const dispatch = useDispatch()
    const [uploadPending, setUploadPending] = useState(false)
    const selectedBlock = useReactiveVar(selectedBlockVar)
    const [modalOpen, setModalOpen] = useState(false)
    const [edit, setEdit] = useState<string | undefined>(undefined)

    const setOptions = (imageList: QuestionChoice[]) => {
        dispatch(updateBlock({ blocksType, id: block.id, block: { choices: imageList } }))
    }

    const handleBlockChange = (image: string, id: string) => {
        dispatch(
            updateBlock({
                blocksType,
                id,
                block: { choices: [...imageList, { id: generateUUID(), image_url: image }] },
            })
        )
    }

    const handleImageDelete = (event: any, id: string) => {
        event.stopPropagation()
        imageList.length >= 1 && setOptions(imageList.filter((image_url) => image_url.id !== id))
    }

    const handleClick = () => {
        setEdit(undefined)
        selectedBlockVar(block)
        setModalOpen(true)
    }

    useEffect(() => {
        if (!selectedBlock) setModalOpen(false)
    }, [selectedBlock])

    const handleEditImage = (id: string) => {
        setEdit(id)
        selectedBlockVar(block)
    }

    const handleBlockUpdate = (image: string, id: string) => {
        imageList.length >= 1 &&
            edit !== undefined &&
            setOptions(
                imageList.map((item) => (item.id === edit ? { id: id, image_url: image } : item))
            )
    }

    useEffect(() => {
        selectedBlock?.id !== block.id && uploadPending && setUploadPending(false)
    }, [selectedBlock])

    return (
        <StyledBlockContent>
            <CarouselContainer alignLeft={imageList?.length > 0}>
                {imageList?.length > 0 && (
                    <div className={"images-container"}>
                        {imageList?.map((option, key) => {
                            if (option.image_url && option.image_url.length > 0) {
                                return (
                                    <div
                                        className={classNames("item-container", {
                                            selected:
                                                edit === option.id &&
                                                selectedBlock?.id === block.id,
                                        })}
                                        key={key}
                                        onClick={() => handleEditImage(option.id)}
                                    >
                                        <img
                                            src={option.image_url}
                                            className={"item-image"}
                                            alt={"item-image"}
                                        />
                                        <CloseButton
                                            onClose={(event) => handleImageDelete(event, option.id)}
                                            css={{ display: "none", zIndex: 100 }}
                                        />
                                    </div>
                                )
                            } else {
                                return (
                                    <div className={"item-container"} key={key}>
                                        <InsertPhotoIcon />
                                    </div>
                                )
                            }
                        })}
                    </div>
                )}
                <div
                    className={classNames("add-container", { loading: uploadPending })}
                    onClick={() => !uploadPending && handleClick()}
                >
                    <AddIcon />
                    {uploadPending && <OverlayLoader />}
                </div>
            </CarouselContainer>
            {modalOpen &&
                (edit !== undefined ? (
                    <MediaModal
                        onChange={({ value, blockId }) => handleBlockUpdate(value, blockId!)}
                        blockId={edit}
                        setLoading={setUploadPending}
                    />
                ) : (
                    <MediaModal
                        onChange={({ value, blockId }) => handleBlockChange(value, blockId!)}
                        blockId={block.id}
                        setLoading={setUploadPending}
                    />
                ))}
        </StyledBlockContent>
    )
}

export default CarouselImage
