import styled, { css, keyframes } from "styled-components"
import {
    FontOverride,
    StyledBox,
    StyledColumn,
    VerticalScrollableContentWithHiddenScroll,
} from "../../styles/styledcomponents"

// TODO: check these keyframes because on the old scss sometimes we are using the right animation
//  for the left bubble and the left animation for the right bubble
export const expandLeftBubble = keyframes`
from {
    height: 75%;
    transform: translatey(15%);
    opacity: 0;
}
to {
    height: 100%;
    transform: translatey(0%);
    opacity: 1;
}
`

export const expandRightBubble = keyframes`
  from {
    transform-origin: right bottom;
    transform: scale(0);
    opacity: 0;
}
to {
    transform-origin: right bottom;
    transform: scale(1);
    opacity: 1;
}
`

export const StyledBubble = styled(StyledBox)`
    padding: 12px 18px;
    background-color: ${(props) => props.theme.headlandsGray4};
    color: ${(props) => props.theme.headlandsBlack};
    font-size: 14px;
    line-height: 1.4;
    max-width: calc(100% - 2 * 12px);
    width: fit-content;
    margin: 3px 12px;
    overflow: hidden;

    // TODO: check if we could remove most of this styles below
    p,
    li {
        color: black;
    }

    ul,
    ol {
        padding-left: 25px !important;
    }

    ul,
    ol {
        margin: 0;
    }

    // quill font-sizes
    // small
    h6 {
        font-weight: normal;
        font-size: 12px;
        color: ${(props) => props.theme.headlandsBlack};
    }
    // normal
    p {
        font-weight: normal;
        font-size: 14px;
        color: ${(props) => props.theme.headlandsBlack};
    }
    // large
    h4 {
        font-weight: normal;
        font-size: 18px;
        color: ${(props) => props.theme.headlandsBlack};
    }

    .ql-indent-3 {
        padding-left: 9em;
    }
    .ql-indent-2 {
        padding-left: 6em;
    }
    .ql-indent-1 {
        padding-left: 3em;
    }
    blockquote {
        border-left: 4px solid #ccc;
        margin-bottom: 5px;
        margin-top: 5px;
        padding-left: 16px;
        font-size: 14px;
        line-height: 18px;
        margin-inline-start: 0;
    }

    .variable-blot {
        background-color: unset;
        border: unset;
        border-radius: unset;
        padding: unset;
        span {
            color: ${(props) => props.theme.headlandsBlack};
        }
    }
`
// Instructor bubble
export const LeftBubbleStyles = css`
    margin: 0 12px 3px 12px;
    border-radius: 16px 16px 16px 2px;
    background-color: ${({ theme }) => theme.colors.thread?.instructor?.bubble};
    /*
        TODO: ideally here we only have to set 'color' at root but there are some weird
        tag selectors on 'StyledBubble' that changes the color
    */
    color: ${({ theme }) => theme.colors.thread?.instructor?.font};
    p,
    span,
    li,
    h4,
    h6 {
        color: ${({ theme }) => theme.colors.thread?.instructor?.font};
    }

    animation: ${expandLeftBubble} 0.75s forwards;
    transform-origin: 0 100%;
`

// Student bubble
export const RightBubbleStyles = css`
    margin: 3px 12px 6px 3px;
    align-self: flex-end;
    border-radius: 16px 16px 2px 16px;
    background-color: ${({ theme }) => theme.colors.thread?.student.bubble};
    /*
        TODO: ideally here we only have to set 'color' at root but there are some weird
        tag selectors on 'StyledBubble' that changes the color
    */
    color: ${({ theme }) => theme.colors.thread?.student?.font};
    p,
    li,
    h4,
    h6 {
        color: ${({ theme }) => theme.colors.thread?.student?.font};
    }
    white-space: pre-wrap;
    animation: ${expandRightBubble} 0.55s forwards;
    transform-origin: 100% 100%;
`

export const StyledLeftBubble = styled(StyledBubble)`
    ${LeftBubbleStyles}
`

export const StyledRightBubble = styled(StyledBubble)`
    ${RightBubbleStyles}
`

export const StyledThreadPreviewContainer = styled(StyledColumn)<{
    mobile?: boolean
    isPreview?: boolean
}>`
    flex: 1;
    background-color: ${({ theme }) => theme.colors.thread.background};

    ${({ theme }) => FontOverride(theme.fonts.thread)}

    ${({ mobile }) =>
        !mobile
            ? css`
                  overflow-x: hidden;
                  ${VerticalScrollableContentWithHiddenScroll};
              `
            : css`
                  .thread-preview {
                      background-color: ${({ theme }) => theme.colors.thread.background};
                  }
              `}

    ${({ isPreview }) =>
        isPreview &&
        css`
            border-radius: 8px;
            padding: 12px 0;
        `}
`

export const StyledThreadPreview = styled(StyledColumn)``
