import { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import classNames from "classnames"
import "./DragImageWrapper.scss"
import { useMutation } from "@apollo/client"
import { CREATE_ATTACHMENT } from "../../../apollo/mutations"
import { Loader } from "../../../components/Loader"
import { ErrorToastIcon } from "../../../assets/icons/ErrorToastIcon"
import { useToast } from "../../../hooks/useToast.hook"
import { AddBlockOptions } from "./Editor"
import { generateUUID } from "../../../utils/utils"
import { BlockType } from "../../../types"
import { useDispatch } from "react-redux"
import { useCurrentThreadGuid } from "../../../hooks/currentThread.hook"
import { addDynamicAttachment } from "../../../redux/threads"

export interface DragImageWrapperType {
    children: JSX.Element
    blockIndex: number
    handleAddBlock: (options: AddBlockOptions) => void
    top?: boolean
}

export const DragImageWrapper = ({
    children,
    blockIndex,
    handleAddBlock,
    top = false,
}: DragImageWrapperType) => {
    const [image, setImage] = useState<File>()
    const [uploadPending, setUploadPending] = useState(false)
    const { showErrorToast } = useToast()
    const threadGuid = useCurrentThreadGuid()
    const dispatch = useDispatch()

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (acceptedFiles.length) {
            setImage(acceptedFiles[0])
        }
        if (fileRejections.length) {
            showErrorToast({ icon: <ErrorToastIcon />, message: "File format not supported" })
        }
    }, [])

    const [createAttachment] = useMutation(CREATE_ATTACHMENT, {
        onCompleted: (res) => {
            const attachment = res.createAttachment
            // add the dynamic attachment to redux
            dispatch(
                addDynamicAttachment({
                    threadGuid,
                    dynamicAttachment: {
                        guid: attachment.guid,
                        url: attachment.url,
                        type: attachment.type,
                        changes: attachment.changes,
                    },
                })
            )
            handleAddBlock({
                index: blockIndex,
                block: {
                    id: generateUUID(),
                    type: BlockType.IMAGE,
                    value: attachment.url,
                    attachmentGuid: attachment.guid,
                },
            })
            setUploadPending(false)
        },
        onError: () => {
            setUploadPending(false)
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
        },
    })

    useEffect(() => {
        if (image?.name) {
            setUploadPending(true)
            image && createAttachment({ variables: { file: image, changes: "{}" } })
        }
    }, [image])

    const { getRootProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/jpeg, image/png, image/gif",
        maxFiles: 1,
        maxSize: 4000000,
    })

    return (
        <div
            className={classNames("dropzone", { "drag-active": isDragActive })}
            {...getRootProps()}
        >
            {uploadPending ? (
                <>
                    <div className={"drag-loader-container"}>
                        <div>
                            <h4>Uploading file</h4>
                            <p>{image?.name}</p>
                        </div>
                        <div>
                            <Loader />
                        </div>
                    </div>
                    {!top && children}
                </>
            ) : (
                children
            )}
        </div>
    )
}
