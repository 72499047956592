import { useEffect, useState } from "react"
import _, { compact } from "lodash"
import { format } from "date-fns"
import { getCustomImageUrl } from "../../../../utils/utils"
import {
    StyledHistoryAuthorAvatar,
    StyledHistorySpan,
    StyledTimeline,
    StyledTimelineItem,
} from "./styles"
import { StyledBox } from "../../../../styles/styledcomponents"
import defaultAvatar from "../../../../assets/images/default-avatar.png"
import retoolAvatar from "../../../../assets/images/retool-logo.png"
import { Person, ThreadVersionHistory } from "../../../../apollo/generated/graphql"
import { Maybe } from "graphql/jsutils/Maybe"
import useThreadVersionHistory from "../../../../hooks/threadVersionHistory.hook"

type HistoryItem = Partial<ThreadVersionHistory> & {
    editor?: Maybe<Partial<Person>>
}

const HistoryTab = () => {
    const { data } = useThreadVersionHistory()

    const [historyData, setHistoryData] = useState<HistoryItem[]>()
    const [lastPublishedIndex, setLastPublishedIndex] = useState<number>()

    useEffect(() => {
        if (data?.getThreadVersionHistoryByGuid) {
            const orderedByDate = compact(
                _.orderBy(data.getThreadVersionHistoryByGuid, "time", "desc")
            )
            if (orderedByDate) {
                setHistoryData(orderedByDate as HistoryItem[])
                setLastPublishedIndex(orderedByDate.findIndex((item) => item.published))
            }
        }
    }, [data])

    /*
     * This method tries to format a string date to the desire format
     * */
    const formatDate = (date: string) => {
        try {
            return format(new Date(date), "MMM d, hh:mm a")
        } catch (e) {
            console.error(e)
            return ""
        }
    }

    /*
     * This method return a custom url for user avatar
     * */
    const getAvatarUrl = (profileImageLink: string) => {
        return getCustomImageUrl(profileImageLink, {
            height: 24,
            width: 24,
            crop: "face",
            webp: true,
        })
    }

    return (
        <StyledTimeline id={"timeline"}>
            {/* if the last version is a non-published one, it adds the 'Current version label' */}
            {_.get(historyData, 0) && !_.get(historyData, 0)?.published && (
                <StyledTimelineItem key={`timeline-item-current}`}>
                    <StyledHistorySpan bold>Current version</StyledHistorySpan>
                </StyledTimelineItem>
            )}
            {historyData?.map((item, index) => {
                if (item.published) {
                    return (
                        <StyledTimelineItem
                            key={`timeline-item-${index}`}
                            colored
                            filled={index === lastPublishedIndex}
                        >
                            <StyledHistorySpan bold>{`Published - ${formatDate(
                                item.time
                            )}`}</StyledHistorySpan>
                        </StyledTimelineItem>
                    )
                } else {
                    return (
                        <StyledBox
                            css={{ margin: "6px 9px 6px 31px" }}
                            key={`editor-item-${index}`}
                        >
                            <StyledHistorySpan>{formatDate(item.time)}</StyledHistorySpan>
                            <StyledBox css={{ display: "flex", alignItems: "center" }}>
                                <StyledHistoryAuthorAvatar
                                    src={
                                        item.editor
                                            ? item.editor.profileImageURL
                                                ? getAvatarUrl(item.editor.profileImageURL)
                                                : defaultAvatar
                                            : retoolAvatar
                                    }
                                />
                                <StyledHistorySpan leftMargin>
                                    {item.editor
                                        ? `${item.editor.firstName} ${item.editor.lastName}`
                                        : "Retool"}
                                </StyledHistorySpan>
                            </StyledBox>
                        </StyledBox>
                    )
                }
            })}
        </StyledTimeline>
    )
}

export default HistoryTab
