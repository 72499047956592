import styled, { css } from "styled-components"
import { StyledBox, StyledH2 } from "../../styles/styledcomponents"

export const TopBarTitleStyles = css`
    font-size: 24px;
    font-weight: 700;
    line-height: 125%;
    color: ${({ theme }) => theme.colors.base.uiLabelTitle};
`

type StyledTopBarProps = {
    extraRightPadding?: boolean
    extraLeftPadding?: boolean
    isMobile?: boolean
}

export const StyledTopBar = styled.div<StyledTopBarProps>`
    display: flex;
    width: 100%;
    min-height: ${({ theme }) => theme.variables.common.topBarHeight};
    max-height: ${({ theme }) => theme.variables.common.topBarHeight};
    border-bottom: 1px solid ${({ theme }) => theme.headlandsGray3};
    background-color: ${({ theme }) => theme.headlandsWhite};
    box-sizing: border-box;
    transition: padding 0.3s ease-out;
    padding-left:  ${({ theme }) => theme.variables.common.topBarPadding.left};
    padding-right:  ${({ theme }) => theme.variables.common.topBarPadding.right};
    align-items: center;
    z-index: 1;

    ${({ extraRightPadding, theme }) =>
        extraRightPadding &&
        css`
            padding-right: calc(
                2 * ${theme.variables.common.topBarPadding.right} +
                    ${theme.variables.common.fabIconWidth}
            );
        `}}

    ${({ extraLeftPadding, theme }) =>
        extraLeftPadding &&
        css`
            padding-left: calc(
                2 * ${theme.variables.common.topBarPadding.left} +
                    ${theme.variables.common.fabIconWidth}
            );
        `}}

    ${StyledH2} {
        ${TopBarTitleStyles}
    }
    ${({ isMobile }) =>
        isMobile &&
        css`
            position: fixed;
            width: 100vw;
            box-sizing: border-box;
            top: 0;
            left: 0;
        `}
`
/*
 * This is a dummy component used to occupy the same height as the topbar, commonly used inside
 * sidebars to leave an empty space on the top
 * */
export const StyledEmptyTopBarHeight = styled(StyledBox)`
    width: 100%;
    min-height: ${({ theme }) => theme.variables.common.topBarHeight};
    max-height: ${({ theme }) => theme.variables.common.topBarHeight};
`
