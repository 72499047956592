import styled from "styled-components"
import {
    StyledBox,
    StyledH4,
    VerticalScrollableContentWithHiddenScroll,
} from "../../styles/styledcomponents"
import { StyledSearchBar } from "../../deliver/components/SearchBar/styles"

export const StyledCreatorMobileScreen = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
    ${StyledH4} {
        color: ${({ theme }) => theme.colors.base.uiLabelTitle};
    }
`
export const StyledCardContainer = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    margin-top: ${({ theme }) => theme.variables.common.topBarHeight};
    padding: 12px;
    padding-bottom: ${({ theme }) => theme.variables.common.bottomNavbarHeight};
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};

    ${StyledSearchBar} {
        margin-top: unset;
    }
`

export const StyledList = styled(StyledBox)`
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    margin-bottom: ${({ theme }) => theme.variables.common.bottomNavbarHeight};
    gap: 6px;
`
