import { ExpandMore } from "@mui/icons-material"
import styled, { css } from "styled-components"
import { StyledBox } from "../../styles/styledcomponents"

interface StyledBreakpointButtonProps {
    text?: boolean
    white?: boolean
    border?: boolean
    pointer?: boolean
    mobile?: boolean
}

export const StyledBreakpointButton = styled(StyledBox)<StyledBreakpointButtonProps>`
    align-self: flex-end;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(${({ theme }) => theme.colors.thread.student.bubble}, 0.2);
    border-radius: 50%;
    transition: background-color 0.5s;
    border: none;
    flex-shrink: 0;

    ${({ text }) =>
        text &&
        css`
            border-radius: 16px;
            color: ${({ theme }) => theme.colors.thread.student.bubble};
            width: auto;
            padding: 0em 1em 0em 1em;
        `}

    ${({ white }) =>
        white &&
        css`
            background-color: transparent;
        `}

    ${({ border }) =>
        border &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.thread.student.bubble};
        `}

    ${({ pointer }) =>
        pointer &&
        css`
            cursor: pointer;
        `}

    ${({ mobile }) =>
        mobile &&
        css`
            width: 24px;
            height: 24px;
        `}
`

export const StyledChevron = styled(ExpandMore)<{ primary?: boolean }>`
    color: ${({ theme, primary }) =>
        primary ? theme.colors.thread.student.bubble : theme.headlandsWhite};
`
