import { Block } from "../../../types"
import FreeTextPreview from "../FreeTextPreview"
import { ChoicePreviewProps } from "../ChoicePreview"
import TextPreview from "../TextPreview"
import { isNil } from "lodash"
import ImagePreview from "../ImagePreview"
import { StyledRow } from "../../../styles/styledcomponents"
import { CorrectnessCheck } from "../../../creator/components/QuestionBlock"
import withUserInput, { UserInputProps } from "../../../hocs/userInput.hoc"

interface FreeTextQuestionPreviewProps extends UserInputProps {
    block: Block
    isMobile?: boolean
    choiceProps: ChoicePreviewProps
}

const FreeTextQuestionPreview = ({
    block,
    isMobile,
    choiceProps,
    handleAnswer,
    correct,
    answer,
    score,
}: FreeTextQuestionPreviewProps) => {
    return (
        <>
            {block.image && (
                <ImagePreview
                    key={block.image.id}
                    block={block.image}
                    isMobile={isMobile}
                    scrollToBottom={choiceProps.scrollToBottom}
                />
            )}

            <TextPreview block={block} customValueSelector={({ question }) => question} />
            <StyledRow css={{ justifyContent: "flex-end", alignItems: "center" }}>
                {!isNil(correct) && (
                    <CorrectnessCheck
                        animated
                        size={28}
                        correct={correct}
                        score={correct && score ? score : undefined}
                    />
                )}

                <FreeTextPreview
                    {...choiceProps}
                    handleAnswer={handleAnswer}
                    answer={answer}
                    bubble={block}
                />
            </StyledRow>
        </>
    )
}

export default withUserInput(FreeTextQuestionPreview)
