import styled, { keyframes } from "styled-components"
import { StyledBox, StyledChevron } from "../../../styles/styledcomponents"

export const StyledRightContainerBox = styled(StyledBox)`
    width: 100%;
    height: 100%;
    margin-left: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-left: 6px;
`

const breatheAnimation = keyframes`
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.4
  }
  100% {
    opacity: 1
  }
`

interface SkeletonHeadlandsProps {
    height?: number | string
    width?: number | string
}

export const SkeletonChevron = styled(StyledChevron)`
    color: ${(props) => props.theme.headlandsGray4};
    animation-name: ${breatheAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`

export const SkeletonHeadlands = styled(StyledBox)<SkeletonHeadlandsProps>`
    width: ${(props) => (props.width ? props.width : "100%")};
    height: ${(props) => (props.height ? props.height : "100%")};
    background-color: ${(props) => props.theme.headlandsGray5};
    animation-name: ${breatheAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &.long-text {
        width: 100%;
        height: 42px;

        &.from-left {
            border-radius: 16px 16px 16px 2px;
        }
        &.from-right {
            border-radius: 16px 16px 2px 16px;
        }
    }

    &.medium-text {
        width: 60%;
        height: 42px;

        &.from-left {
            border-radius: 16px 16px 16px 2px;
        }
        &.from-right {
            border-radius: 16px 16px 2px 16px;
        }
    }

    &.short-text {
        width: 30%;
        height: 42px;

        &.from-left {
            border-radius: 16px 16px 16px 2px;
        }
        &.from-right {
            border-radius: 16px 16px 2px 16px;
        }
    }

    &.image {
        width: 60%;
        max-width: 384px;
        min-width: 300px;
        height: 190px;
        border-radius: 16px 16px 16px 2px;

        &.from-left {
            border-radius: 16px 16px 16px 2px;
        }
        &.from-right {
            border-radius: 16px 16px 2px 16px;
        }
    }

    &.pane {
        width: 100%;
        height: 366px;
        border-radius: 16px 16px 16px 2px;
    }
`
