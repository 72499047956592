import { useEffect, useRef } from "react"

export type IntersectionObserverOptions = IntersectionObserverInit & {
    minIntersectionRatio?: number
    maxIntersectionRation?: number
}

type IntersectionObserverProps = {
    partialViewableCallback?: (elementId: string) => void
    // the element is fully un-viewable
    fullyUnViewableCallback?: (elementId: string) => void
    // the element is fully viewable
    fullyViewableCallback?: (elementId: string) => void
    // the element is partial un-viewable
    partialUnViewableCallback?: (elementId: string) => void
    observerOptions?: IntersectionObserverOptions
}

const useIntersectionObserver = ({
    partialViewableCallback,
    fullyUnViewableCallback,
    fullyViewableCallback,
    partialUnViewableCallback,
    observerOptions,
}: IntersectionObserverProps) => {
    const observer = useRef<IntersectionObserver | null>(null)
    const options: IntersectionObserverInit = {
        root: observerOptions?.root || null,
        rootMargin: observerOptions?.rootMargin || "0px 0px 0px 0px",
        threshold: observerOptions?.threshold || [0, 1],
    }
    const minIntersectionRatio = observerOptions?.minIntersectionRatio || 0
    const maxIntersectionRatio = observerOptions?.maxIntersectionRation || 1

    useEffect(() => {
        observer.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const intersectionRatio = entry.intersectionRatio

                // the element is partial viewable or un-viewable (which is the same)
                if (
                    intersectionRatio > minIntersectionRatio &&
                    intersectionRatio < maxIntersectionRatio
                ) {
                    partialViewableCallback && partialViewableCallback(entry.target.id)
                    partialUnViewableCallback && partialUnViewableCallback(entry.target.id)
                }
                // the element fully un-viewable
                else if (intersectionRatio <= minIntersectionRatio) {
                    fullyUnViewableCallback && fullyUnViewableCallback(entry.target.id)
                }
                // the element fully viewable
                else if (intersectionRatio >= maxIntersectionRatio) {
                    fullyViewableCallback && fullyViewableCallback(entry.target.id)
                }
            })
        }, options)
    }, [])

    const observe = (element: Element) => {
        observer.current?.observe(element)
    }

    const unobserve = (element: Element) => {
        observer.current?.unobserve(element)
    }

    return { observe, unobserve }
}

export default useIntersectionObserver
