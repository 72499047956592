import styled from "styled-components"
import { StyledCloseButtonContainer } from "../../../components/CloseButton/styles"

interface CarouselContainerProps {
    alignLeft: boolean
}

export const CarouselContainer = styled("div")<CarouselContainerProps>`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: ${(props) => (props.alignLeft ? "start" : "center")};
    flex-wrap: nowrap;

    .images-container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        flex-direction: row;
        .item-container {
            flex: 0 0 100px;
            width: 100px;
            height: 100px;
            border: 1px solid ${(props) => props.theme.headlandsGray3};
            border-radius: 16px;
            margin: 0 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            &.selected {
                border: 1px solid ${(props) => props.theme.headlandsPrimaryColor};
            }
            &:hover {
                ${StyledCloseButtonContainer} {
                    display: flex;
                }
            }
            &:first-child {
                margin-left: 0;
            }
        }
        .item-image {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 16px;
            background-color: ${(props) => props.theme.headlandsWhite};
        }
    }
    .add-container {
        width: 100px;
        height: 100px;
        min-width: 100px;
        border: 1px dashed ${(props) => props.theme.headlandsGray3};
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 3px;
        position: relative;
        background-color: ${(props) => props.theme.headlandsWhite};
        cursor: pointer;
        svg {
            width: 36px;
            height: 36px;
        }
        &.loading {
            cursor: progress;
        }
        &.error {
            border: 1px dashed ${(props) => props.theme.headlandsError};
            background-color: ${(props) => `${props.theme.headlandsError}20`};
            svg {
                path {
                    fill: ${(props) => props.theme.headlandsError};
                }
            }
        }
    }
`
