import { useTheme } from "styled-components"
import { DesktopIcon } from "../../../../assets/icons/DesktopIcon"
import { StyledBody2, StyledH5, StyledRow } from "../../../../styles/styledcomponents"
import { StyledDesktopOnlyBlock } from "./styles"

const DesktopOnlyBlock = () => {
    const theme = useTheme()
    return (
        <StyledDesktopOnlyBlock>
            <StyledRow css={{ gap: "6px", alignItems: "center" }}>
                <DesktopIcon width={24} height={24} color={theme.colors.base.uiBgBase} />
                <StyledH5 useNewDesign>Desktop Only Block</StyledH5>
            </StyledRow>
            <StyledBody2>
                Please open this thread on your desktop browser to see this block
            </StyledBody2>
        </StyledDesktopOnlyBlock>
    )
}

export default DesktopOnlyBlock
