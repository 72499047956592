import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { OverlayLoader } from "../../Loader"
import { Button } from "../../Button/Button"
import Input from "../../Input/Input"
import ImageUploadDropzone from "../../ImageUploadDropzone"

interface ImageUploaderPreviewDesktopProps {
    uploadPending: boolean
    valid: boolean
    handleAddImage: (event: MouseEvent) => void
    handleAdd: (event: MouseEvent) => void
    mediaUrl: string
    setMediaUrl: any
    imageError: boolean
    setImageError: any
    image?: File
    setImage: any
}

const ImageUploaderPreviewDesktop = ({
    uploadPending,
    valid,
    handleAddImage,
    handleAdd,
    mediaUrl,
    setMediaUrl,
    imageError,
    setImageError,
    image,
    setImage,
}: ImageUploaderPreviewDesktopProps) => {
    return (
        <Tabs defaultIndex={mediaUrl ? 1 : 0}>
            <TabList>
                <Tab>Upload</Tab>
                <Tab>Url link</Tab>
            </TabList>
            <TabPanel>
                {uploadPending && <OverlayLoader size={48} />}
                <ImageUploadDropzone
                    image={image}
                    error={imageError}
                    onChange={setImage}
                    onError={setImageError}
                />
                <Button
                    label={"Add image"}
                    disabled={!image}
                    data-testid={"image-upload-submit"}
                    buttonStyle={{ maxWidth: 230, opacity: !image ? 0.5 : 1 }}
                    onClick={handleAddImage}
                />
            </TabPanel>
            <TabPanel>
                <Input
                    value={mediaUrl}
                    placeholder={"Paste the image link"}
                    inputStyle={{ width: "auto" }}
                    onChange={setMediaUrl}
                    secondary
                />
                <Button
                    label={"Add image"}
                    width={230}
                    disabled={!valid}
                    buttonStyle={{ opacity: !valid ? 0.5 : 1 }}
                    onClick={handleAdd}
                />
            </TabPanel>
        </Tabs>
    )
}

export default ImageUploaderPreviewDesktop
