import {
    StyledHoverOverlayOptions,
    StyledMenuOptionsContainer,
    StyledMenusContainer,
} from "./styles"
import { SvgIconComponent } from "@mui/icons-material"
import React, { useState } from "react"
import { CSSObject } from "styled-components"

type HoverOverlayOptionsProps = {
    children?: any
    menus?: Menu[]
    position?: TooltipPosition
    containerStyles?: CSSObject
}

export type Menu = {
    options: OptionItem[]
    enable?: boolean
}

export type TooltipPosition = "bottom-right" | "bottom-left" | "top-right" | "top-left"

export type OptionItem = {
    name: string
    icon?: SvgIconComponent
    text?: string
    onClick: Function
}

const HoverOverlayOptions = ({
    children,
    menus,
    position = "bottom-right",
    containerStyles,
}: HoverOverlayOptionsProps) => {
    const [showTooltip, setShowTooltip] = useState(false)

    const handleClick = (event: React.MouseEvent<any>, option: OptionItem) => {
        event.stopPropagation()
        event.preventDefault()
        option.onClick()
    }

    const renderOption = (option: OptionItem) => {
        const Icon = option.icon
        if (Icon)
            return (
                <Icon
                    key={option.name}
                    onClick={(event) => handleClick(event, option)}
                    fontSize={"inherit"}
                />
            )
        const text = option.text
        if (text)
            return (
                <span key={option.name} onClick={(event) => handleClick(event, option)}>
                    {text}
                </span>
            )
        return null
    }

    return (
        <StyledHoverOverlayOptions
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {children}
            {showTooltip && (
                <StyledMenusContainer position={position} styles={containerStyles}>
                    {menus?.map(
                        (menu, index) =>
                            menu.enable && (
                                <StyledMenuOptionsContainer key={`menu-${index}`}>
                                    {menu.options.map(renderOption)}
                                </StyledMenuOptionsContainer>
                            )
                    )}
                </StyledMenusContainer>
            )}
        </StyledHoverOverlayOptions>
    )
}

export default HoverOverlayOptions
