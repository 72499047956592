import { OverlayOpacityLoader } from "../../../../components/Loader"
import { Block, BlockType, BlocksType, ImageEditionChanges, MediaSize } from "../../../../types"
import { StyledEmptyImagePlaceholder, StyledImageContainer } from "./styles"
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera"
import { StyledBody1, StyledImage } from "../../../../styles/styledcomponents"
import HoverOverlayOptions, { Menu } from "../../../../components/HoverOverlayOptions"
import SettingsIcon from "@mui/icons-material/Settings"
import { useCallback, useEffect, useRef, useState } from "react"
import MediaModal, { MediaModalOnChangeOptions } from "../../MediaModal/MediaModal"
import ImageEditorModal from "../../ImageEditorModal"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { useCurrentThreadGuid } from "../../../../hooks/currentThread.hook"
import useImageEditor from "../../../../hooks/useImageEditor.hook"
import { useMutation, useReactiveVar } from "@apollo/client"
import { CREATE_ATTACHMENT, MODIFY_ATTACHMENT } from "../../../../apollo/mutations"
import { Attachment } from "../../../../apollo/generated/graphql"
import { addDynamicAttachment } from "../../../../redux/threads"
import { selectedBlockVar } from "../../../../apollo/cache-store"
import { ImageModalTab } from "../../MediaModal/ImageModalContent"

interface BlockImageProps {
    block: Block
    blocksType: BlocksType
    availableImageModalTabs?: ImageModalTab[]
    onUpdate: (block: Block) => void
}

/*
 * TODO:
 * 1. migrate the create attachment and modify attachment mutation to utils
 * 2. try to delete the `selectedBlock` logic
 * 3. try to refactor to separate the logic into a more "readable" component
 */

const BlockImage = ({ block, blocksType, availableImageModalTabs, onUpdate }: BlockImageProps) => {
    // layout states
    const [imageEditorModalOpen, setImageEditorModalOpen] = useState<boolean>()
    const [mediaModalOpen, setMediaModalOpen] = useState<boolean>(false)
    const [previewLoading, setPreviewLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const [dynamic, setDynamic] = useState<boolean>(false)

    const selectedBlock = useReactiveVar(selectedBlockVar)
    const dispatch = useDispatch()
    const { generateImageUrl, checkIfDynamic } = useImageEditor()

    // if the thread has attachments and the block has `attachmentGuid` then search the attachment
    const threadGuid = useCurrentThreadGuid()
    const attachment = useSelector((state: RootState) => {
        const attachments = state.threadsReducer.threads[threadGuid]?.thread?.attachments
        if (attachments && block.attachmentGuid)
            return attachments.find((attachment) => attachment.guid === block.attachmentGuid)
        return undefined
    })

    const previewUrl = useRef<string>()

    const handleBlockUpdate = useCallback(
        (options: {
            value?: string
            size?: MediaSize
            attachmentGuid?: string
            replace?: boolean
            comment?: string
        }) => {
            const { value, size, attachmentGuid, replace, comment } = options
            let updatedBlock: Block = { id: block.id, type: BlockType.IMAGE }
            if (value || replace) updatedBlock.value = value
            if (size || replace) updatedBlock.imageSize = size
            if (attachmentGuid || replace) updatedBlock.attachmentGuid = attachmentGuid
            if (comment) updatedBlock.comment = comment
            onUpdate(updatedBlock)
        },
        [block, blocksType, onUpdate]
    )

    /*
     * If there is an associated attachment then we try to generate an URL for the attachment with the attachment changes
     */
    useEffect(() => {
        if (attachment) {
            setPreviewLoading(true)
            if (attachment?.type?.includes("gif")) {
                previewUrl.current = block.value
                setPreviewLoading(false)
            } else {
                generateImageUrl(
                    attachment.url,
                    attachment?.changes ? JSON.parse(attachment?.changes) : {}
                ).then((urlGenerated) => {
                    previewUrl.current = urlGenerated
                    setPreviewLoading(false)
                })
            }
        }
    }, [attachment])

    /*
     * when the block is 'un-selected' close the modal
     */
    useEffect(() => {
        if (!selectedBlock) setMediaModalOpen(false)
    }, [selectedBlock])

    // the image is a static one, upload the edited image to s3
    const [createAttachment] = useMutation(CREATE_ATTACHMENT, {
        onCompleted: (res) => {
            const attachment = res.createAttachment
            // update the block value and guid
            handleBlockUpdate({ value: attachment.url, attachmentGuid: attachment.guid })

            const dynamicAttachmentAux: Attachment = {
                guid: attachment.guid,
                changes: attachment.changes || "{}",
                url: attachment.url,
                type: attachment.type,
            }

            // add the new dynamic attachment on redux
            if (threadGuid) {
                dispatch(
                    addDynamicAttachment({ threadGuid, dynamicAttachment: dynamicAttachmentAux })
                )
            }
        },
        onError: (e) => {
            setError(true)
            console.error(e)
        },
    })

    const [modifyAttachment] = useMutation(MODIFY_ATTACHMENT, {
        onCompleted: (res) => {
            const attachment = res.modifyAttachment
            const dynamicAttachmentAux: Attachment = {
                guid: attachment.guid,
                changes: attachment.changes || "{}",
                url: attachment.url,
                type: attachment.type,
            }

            // add the new dynamic attachment on redux
            if (threadGuid) {
                dispatch(
                    addDynamicAttachment({ threadGuid, dynamicAttachment: dynamicAttachmentAux })
                )

                // if the image is a dynamic one update the guid and set the value as empty string
                if (dynamic) {
                    handleBlockUpdate({
                        size: block.imageSize,
                        attachmentGuid: dynamicAttachmentAux.guid,
                        replace: true,
                    })
                }
            }
        },
        onError: (e) => {
            setError(true)
            console.error(e)
        },
    })

    const handleSizeChange = (size: MediaSize) => {
        handleBlockUpdate({ size })
    }

    const ImageMenus: Menu[] = [
        {
            options: [
                {
                    name: "size-extra-small",
                    text: "Xs",
                    onClick: () => handleSizeChange(MediaSize.extraSmall),
                },
                {
                    name: "size-small",
                    text: "Sm",
                    onClick: () => handleSizeChange(MediaSize.small),
                },
                {
                    name: "size-medium",
                    text: "Md",
                    onClick: () => handleSizeChange(MediaSize.medium),
                },
                {
                    name: "size-large",
                    text: "Lg",
                    onClick: () => handleSizeChange(MediaSize.large),
                },
            ],
            enable: true,
        },
        {
            options: [
                {
                    name: "advanceSetting",
                    icon: SettingsIcon,
                    onClick: () => setImageEditorModalOpen(true),
                },
            ],
            enable: !attachment?.type?.includes("gif") && !block?.value?.includes("hyperise"),
        },
    ]

    const handleMediaModalOnChange = ({
        value,
        comment,
        attachmentGuid,
    }: MediaModalOnChangeOptions) => {
        handleBlockUpdate({ value, comment, attachmentGuid })
        previewUrl.current = value
    }

    const handleImageEditionFinish = (changes: ImageEditionChanges, imageFile: File) => {
        const attachmentGuid = block.attachmentGuid
        if (!attachmentGuid || !changes) return

        setPreviewLoading(true)
        setDynamic(checkIfDynamic(changes))

        if (!checkIfDynamic(changes) && imageFile) {
            createAttachment({
                variables: { file: imageFile, changes: JSON.stringify(changes || {}) },
            })
        } else {
            modifyAttachment({
                variables: { guid: attachmentGuid, changes: JSON.stringify(changes || {}) },
            })
        }
    }

    const handleOnImageClick = () => {
        selectedBlockVar(block)
        setMediaModalOpen(true)
    }

    return (
        <StyledImageContainer
            id={`block-${block.id}`}
            onClick={handleOnImageClick}
            size={block?.imageSize}
        >
            <OverlayOpacityLoader loading={previewLoading}>
                {(!previewUrl.current && !block.value) || error ? (
                    <StyledEmptyImagePlaceholder error={error} selected={!!selectedBlock}>
                        {error ? <StyledBody1>Invalid Image</StyledBody1> : <PhotoCameraIcon />}
                    </StyledEmptyImagePlaceholder>
                ) : (
                    <HoverOverlayOptions
                        menus={ImageMenus}
                        containerStyles={
                            block.imageSize === MediaSize.extraSmall
                                ? {
                                      left: "-52px",
                                      bottom: "-36px",
                                      top: "unset",
                                      right: "unset",
                                  }
                                : undefined
                        }
                    >
                        <StyledImage
                            src={
                                !!attachment && previewUrl.current
                                    ? previewUrl.current
                                    : block.value
                            }
                            onError={() => setError(true)}
                            alt={"block-value"}
                        />
                    </HoverOverlayOptions>
                )}
            </OverlayOpacityLoader>
            {mediaModalOpen && (
                <MediaModal
                    onChange={handleMediaModalOnChange}
                    blockId={block.id}
                    imageComment={block.comment}
                    availableImageModalTabs={availableImageModalTabs}
                />
            )}
            <ImageEditorModal
                isOpen={!!imageEditorModalOpen}
                onClose={() => setImageEditorModalOpen(false)}
                src={attachment?.url}
                onSave={handleImageEditionFinish}
                state={attachment?.changes ? JSON.parse(attachment.changes) : {}}
            />
        </StyledImageContainer>
    )
}

export default BlockImage
