import { useMemo } from "react"
import { CSSObject, useTheme } from "styled-components"
import EllipsisText from "../../../../../components/EllipsisText"

interface ThreadTitleProps {
    title: string
    isMobile?: boolean
    width?: number
    dark?: boolean
}

const ThreadTitle = ({ title, isMobile, width = 0, dark }: ThreadTitleProps) => {
    const theme = useTheme()

    const maxWidth = useMemo(() => {
        return (
            width -
            // left and right padding
            parseInt(theme.variables.common.topBarPadding.left) -
            parseInt(theme.variables.common.topBarPadding.right) -
            // mobile has 2 icons inside the top bar, desktop only one
            (isMobile ? 2 : 1) * parseInt(theme.variables.common.fabIconWidth) -
            // we leave an extra 12px on each side
            2 * 12
        )
    }, [width, isMobile])

    const textStyles = useMemo(() => {
        const baseStyles: CSSObject = {
            color: dark ? theme.colors.base.uiBgBase : theme.colors.base.uiLabelTitle,
            fontWeight: 700,
        }
        if (isMobile) {
            baseStyles.fontSize = "16px"
            baseStyles.lineHeight = "20px"
        } else {
            baseStyles.fontSize = "24px"
            baseStyles.lineHeight = "30px"
        }
        return baseStyles
    }, [isMobile, dark])

    return (
        <EllipsisText
            variant={isMobile ? "h4" : "h2"}
            maxWidth={maxWidth}
            text={title}
            textStyles={textStyles}
            popupOptions={{ on: isMobile ? ["click"] : ["hover"], position: "bottom center" }}
        />
    )
}

export default ThreadTitle
