import { IconProps } from "../../types"

const AnalyzeIcon = ({ width = 24, height = 24, color = "#888888", ...rest }: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M5.66754 11.3223C4.56379 11.3223 4.00757 10.7661 4.00757 9.61888V5.73404C4.00757 4.58684 4.56379 4.03931 5.66754 4.03931H9.6306C10.7343 4.03931 11.2906 4.58684 11.2906 5.73404V9.61888C11.2906 10.7661 10.7343 11.3223 9.6306 11.3223H5.66754ZM14.3932 11.3223C13.2808 11.3223 12.7246 10.7661 12.7246 9.61888V5.73404C12.7246 4.58684 13.2808 4.03931 14.3932 4.03931H18.3476C19.4513 4.03931 20.0076 4.58684 20.0076 5.73404V9.61888C20.0076 10.7661 19.4513 11.3223 18.3476 11.3223H14.3932ZM5.68492 10.0969H9.60453C9.90871 10.0969 10.0651 9.94045 10.0651 9.61888V5.73404C10.0651 5.42116 9.90871 5.26473 9.60453 5.26473H5.68492C5.38074 5.26473 5.23299 5.42116 5.23299 5.73404V9.61888C5.23299 9.94045 5.38074 10.0969 5.68492 10.0969ZM14.4106 10.0969H18.3302C18.6344 10.0969 18.7821 9.94045 18.7821 9.61888V5.73404C18.7821 5.42116 18.6344 5.26473 18.3302 5.26473H14.4106C14.0977 5.26473 13.95 5.42116 13.95 5.73404V9.61888C13.95 9.94045 14.0977 10.0969 14.4106 10.0969ZM5.66754 20.0393C4.56379 20.0393 4.00757 19.4918 4.00757 18.3446V14.451C4.00757 13.3125 4.56379 12.7563 5.66754 12.7563H9.6306C10.7343 12.7563 11.2906 13.3125 11.2906 14.451V18.3446C11.2906 19.4918 10.7343 20.0393 9.6306 20.0393H5.66754ZM14.3932 20.0393C13.2808 20.0393 12.7246 19.4918 12.7246 18.3446V14.451C12.7246 13.3125 13.2808 12.7563 14.3932 12.7563H18.3476C19.4513 12.7563 20.0076 13.3125 20.0076 14.451V18.3446C20.0076 19.4918 19.4513 20.0393 18.3476 20.0393H14.3932ZM5.68492 18.8139H9.60453C9.90871 18.8139 10.0651 18.6574 10.0651 18.3446V14.4597C10.0651 14.1382 9.90871 13.9817 9.60453 13.9817H5.68492C5.38074 13.9817 5.23299 14.1382 5.23299 14.4597V18.3446C5.23299 18.6574 5.38074 18.8139 5.68492 18.8139ZM14.4106 18.8139H18.3302C18.6344 18.8139 18.7821 18.6574 18.7821 18.3446V14.4597C18.7821 14.1382 18.6344 13.9817 18.3302 13.9817H14.4106C14.0977 13.9817 13.95 14.1382 13.95 14.4597V18.3446C13.95 18.6574 14.0977 18.8139 14.4106 18.8139Z"
            fill={color}
        />
    </svg>
)

export default AnalyzeIcon
