import styled from "styled-components"
import {
    StyledBox,
    StyledH5,
    StyledHr,
    StyledSpan,
    VerticalScrollableContentWithHiddenScroll,
} from "../../../styles/styledcomponents"
import AddIcon from "@mui/icons-material/Add"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"

export const StyledThreadOutlineBar = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    width: ${({ theme }) => theme.sidebarWidths.creatorThreadOutline}px;
    height: 100%;
    border-right: solid 1px ${({ theme }) => theme.colors.base.uiBgBorder};
    box-sizing: border-box;
`

export const StyledThreadOutlineBarHeader = styled(StyledBox)`
    display: flex;
    align-items: center;
    height: 58px;
    width: 100%;
    border-bottom: solid 3px ${({ theme }) => theme.colors.base.uiPrimary500};
    padding: 24px 24px 12px;
    box-sizing: border-box;

    ${StyledH5} {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
    }
`

export const StyledThreadOutlineBarContent = styled(StyledBox)`
    flex-grow: 1;
    box-sizing: border-box;
    padding: 18px 24px;
    ${VerticalScrollableContentWithHiddenScroll};
`

export const StyledMoreHorizIcon = styled(MoreHorizIcon)<{ $show?: boolean }>`
    display: ${({ $show }) => ($show ? "inline-block" : "none")} !important;
    color: ${({ theme }) => theme.colors.base.uiLabelBase};
`

export const StyledAddIcon = styled(AddIcon)<{ $show?: boolean }>`
    display: ${({ $show }) => ($show ? "inline-block" : "none")} !important;
    width: 14px !important;
    height: 14px !important;
    color: ${({ theme }) => theme.colors.base.uiLabelBase};
`

export const StyledThreadOutlineBarBlockItem = styled(StyledBox)<{ selected?: boolean }>`
    display: grid;
    // 58px is the image width plus the right margin, 20px and 14px is the space we reserve for each icon
    grid-template-columns: 58px 1fr 20px 14px;
    align-items: center;
    padding: 3px 6px;
    margin: 3px 0;
    border-radius: ${({ theme }) => theme.borderRadius1};
    background-color: ${({ theme, selected }) => selected && theme.colors.base.uiBgHover};

    &:hover {
        background-color: ${({ theme }) => theme.colors.base.uiBgHover};
        cursor: pointer;

        ${StyledMoreHorizIcon}, ${StyledAddIcon} {
            display: inline-block !important;
        }
    }
`

export const StyledThreadOutlineBarFooter = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    padding: 6px 24px;
    border-top: solid 1px ${({ theme }) => theme.colors.base.uiBgBorder}; ;
`
export const StyledFooterButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius1};
    background-color: transparent;
    padding: 4px 10px;

    ${StyledSpan} {
        font-size: 14px;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.base.uiLabelBase};
        margin-left: 10px;
    }

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.base.uiBgHover};
    }
`

export const StyledThreadOutlineBarSectionItem = styled(StyledBox)`
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border-radius: 8px;
`

export const StyledThreadOutlineBarSectionHeader = styled(StyledBox)`
    display: grid;
    // 20px and 14px is the space we reserve for the icons
    grid-template-columns: 20px 1fr 20px 14px;
    align-items: center;

    &:hover {
        cursor: pointer;

        ${StyledMoreHorizIcon}, ${StyledAddIcon} {
            display: inline-block !important;
        }
    }
`

export const StyledThreadOutlineBarSectionContent = styled(StyledBox)<{ open?: boolean }>`
    height: ${({ open }) => (open ? "inherit" : 0)};
    overflow-y: ${({ open }) => (open ? "inherit" : "hidden")};
`

export const StyledSectionItemDivider = styled(StyledHr)`
    border-color: ${({ theme }) => theme.colors.base.uiBgBorder}; ;
`
