import { StyledConfetti } from "./styles"
import confettiBanner from "../../assets/images/confetti-banner.png"

export const Confetti = () => {
    return (
        <StyledConfetti>
            <img src={confettiBanner} alt={"confetti"} />
        </StyledConfetti>
    )
}
