import { StyledAccordion, StyledHeaderAccordion } from "./styles"
import { Block, BlocksType, BlockType } from "../../../types"
import RichTextEditor from "../../../components/Input/RichTextEditor"
import { StyledBox } from "../../../styles/styledcomponents"
import { generateUUID } from "../../../utils/utils"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Editor } from "../EditorScreen/Editor"
import { useCallback, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { addBlocks, findBlock } from "../../../redux/blocks"
import useOnClickOutside from "../../../hooks/onClickOutside.hook"
import { Divider } from "@mui/material"
import { useTheme } from "styled-components"

interface AccordionProps {
    blocksType: BlocksType
    block: Block
    blockIndex: number
    onChange: (value: string, id: string) => void
}

const Accordion = ({ blocksType, block, blockIndex, onChange }: AccordionProps) => {
    const [focused, setFocused] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(true)
    const wrapperRef = useRef(null)
    const branchId = block.branches![0].id
    const dispatch = useDispatch()
    const theme = useTheme()

    const blockIds = useSelector((state: RootState) => {
        return findBlock(block.id, state.blocksReducer[`${blocksType}Index`])!
    }, shallowEqual)

    const handler = useCallback(() => setFocused(false), [])

    useOnClickOutside(wrapperRef, handler)

    const handleAddBlock = () => {
        dispatch(
            addBlocks({
                blocksType,
                branchId: branchId,
                blocks: [
                    {
                        id: generateUUID(),
                        type: BlockType.TEXT,
                        value: "",
                    } as Block,
                ],
                index: undefined,
            })
        )
    }

    return (
        <StyledAccordion
            id={`block-${blockIndex}`}
            css={{
                backgroundColor: block.color ? "transparent" : theme.headlandsGray4,
                transition: "background-color 0.2s ease-out",
                borderRadius: "8px 8px 0 0",
            }}
            onClick={() => setFocused(true)}
        >
            <StyledHeaderAccordion>
                <RichTextEditor
                    value={block.value}
                    onChange={(value: string) => onChange(value, block.id)}
                    className={"richtext-title"}
                    placeholder={"Write accordion title"}
                    enableEmojis
                    enableFormatting
                    enableVariables
                />
                <KeyboardArrowDownIcon
                    sx={{
                        margin: "0 15px 0 10px",
                        cursor: "pointer",
                        transition: "transform 0.2s ease-out",
                        transform: open ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                    onClick={() => setOpen(!open)}
                />
            </StyledHeaderAccordion>
            <StyledBox
                css={{
                    height: open ? "inherit" : 0,
                    overflowY: open ? "inherit" : "hidden",
                    paddingTop: open ? "6px" : "0",
                    paddingBottom: open ? "9px" : "0",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Editor
                    blocksType={blocksType}
                    blockIds={blockIds.branches![0].objects}
                    branchId={branchId}
                    parentType={block.type}
                    disabledColorBubble
                />
                {focused && (
                    <StyledBox
                        css={{
                            height: "18px !important",
                        }}
                        onClick={() => handleAddBlock()}
                    />
                )}
            </StyledBox>
            <Divider />
        </StyledAccordion>
    )
}

export default Accordion
