import { IconProps } from "../../types"
import { useTheme } from "styled-components"

export const CreatorToolIcon = ({ width = 25, height = 24, color }: IconProps) => {
    const theme = useTheme()

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="creator-tool-icon">
                <path
                    id="creator-tool-icon-path"
                    d="M13.6929 19.9614C17.8752 19.9614 21.3333 16.3493 21.3333 11.9807C21.3333 7.61216 17.8752 4 13.6856 4C9.80625 4 6.54763 7.11819 6.10428 11.0622C6.56241 10.9542 7.17571 10.9156 7.67078 11.0005C8.11413 7.92861 10.6191 5.59768 13.6856 5.59768C17.0772 5.59768 19.8038 8.43801 19.8112 11.9807C19.8112 14.165 18.7767 16.0868 17.1806 17.2369L16.257 12.9532C16.1683 12.5519 15.8728 12.22 15.5107 12.2045H15.4516L14.2176 8.43801C14.0402 7.90545 13.3383 7.90545 13.1609 8.43801L11.9417 12.2045H11.89C11.5649 12.2123 11.2989 12.4747 11.2028 12.8066L11.1215 13.1925C11.4836 13.725 11.7496 14.3271 11.8826 14.9831L12.1856 13.3468H15.2003L16.0649 17.8775C15.3333 18.1939 14.5353 18.3714 13.6929 18.3714C12.954 18.3714 12.2447 18.2325 11.5944 17.9624C11.4097 18.4795 11.1289 18.9503 10.7816 19.3439C11.6757 19.7376 12.6659 19.9614 13.6929 19.9614ZM7.14615 20C9.20773 20 10.9516 18.1939 10.9516 16.0174C10.9516 13.8408 9.2299 12.0424 7.14615 12.0424C5.05502 12.0424 3.33334 13.8408 3.33334 16.0174C3.33334 18.2016 5.05502 20 7.14615 20ZM4.7299 16.0174C4.72251 15.7009 4.9294 15.4925 5.23236 15.4925H6.6363V14.0338C6.6363 13.7173 6.83581 13.5012 7.14615 13.5012C7.44911 13.5012 7.64861 13.7173 7.64861 14.0338V15.4925H9.05256C9.35551 15.4925 9.55502 15.7009 9.55502 16.0174C9.55502 16.3415 9.35551 16.5499 9.05256 16.5499H7.64861V18.0164C7.64861 18.3329 7.44911 18.549 7.14615 18.549C6.83581 18.549 6.6363 18.3329 6.6363 18.0164V16.5499H5.23236C4.93679 16.5499 4.7299 16.3415 4.7299 16.0174Z"
                    fill={color ?? theme.colors.base.uiBgIcon}
                />
            </g>
        </svg>
    )
}
