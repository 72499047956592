import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { isArray } from "lodash"

export enum SidebarType {
    nav = "navBarOpen",
    library = "libraryBarOpen",
    social = "socialBarOpen",
    creatorThread = "creatorThreadBarOpen",
    properties = "propertiesBarOpen",
    deliverProgram = "deliverProgramBarOpen",
    creatorThreadOutline = "creatorThreadOutlineOpen",
}

export enum ModalType {
    aiCreate = "aiCreateModalOpen",
}

export interface LayoutState {
    navBarOpen?: boolean
    libraryBarOpen?: boolean
    socialBarOpen?: boolean
    creatorThreadBarOpen?: boolean
    propertiesBarOpen?: boolean
    deliverProgramBarOpen?: boolean
    creatorThreadOutlineOpen?: boolean
    showConfetti?: boolean
    aiCreateModalOpen?: boolean
    enableSidebarAnimation?: boolean
    questionTimestamp?: number
    questionAvailableTime?: number
    showMobileBottomBar?: boolean
    showSwitchedDevicesModal?: boolean
    threadDoneScrolling?: boolean
    threadFoundLastBubble?: boolean
}

const initialState: LayoutState = {
    navBarOpen: false,
    libraryBarOpen: false,
    socialBarOpen: false,
    creatorThreadBarOpen: false,
    propertiesBarOpen: false,
    deliverProgramBarOpen: false,
    creatorThreadOutlineOpen: false,
    showConfetti: false,
    aiCreateModalOpen: false,
    enableSidebarAnimation: undefined,
    questionTimestamp: undefined,
    questionAvailableTime: undefined,
    showMobileBottomBar: true,
    showSwitchedDevicesModal: false,
    threadDoneScrolling: false,
    threadFoundLastBubble: false,
}

interface SetSideBarItem {
    sidebarType: SidebarType
    open: boolean
}

type SetModal = {
    modalType: ModalType
    open: boolean
}

interface SetSidebarStatePayload {
    sidebars: SetSideBarItem | SetSideBarItem[]
    enabledSidebarAnimation: boolean
}

export const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setSidebarState: (state: LayoutState, action: PayloadAction<SetSidebarStatePayload>) => {
            if (isArray(action.payload.sidebars)) {
                action.payload.sidebars.forEach((setting) => {
                    state[setting.sidebarType] = setting.open
                })
            } else {
                state[action.payload.sidebars.sidebarType] = action.payload.sidebars.open
            }
            state.enableSidebarAnimation = action.payload.enabledSidebarAnimation

            return state
        },
        setShowConfetti: (state: LayoutState, action: PayloadAction<boolean>) => {
            state.showConfetti = action.payload
            return state
        },
        setModalState: (state: LayoutState, action: PayloadAction<SetModal>) => {
            state[action.payload.modalType] = action.payload.open
            return state
        },
        setQuestionTimestampData: (
            state: LayoutState,
            action: PayloadAction<{ timestamp: number; availableTime: number }>
        ) => {
            state.questionTimestamp = action.payload.timestamp
            state.questionAvailableTime = action.payload.availableTime
            return state
        },
        resetQuestionTimestampData: (state: LayoutState) => {
            state.questionTimestamp = undefined
            state.questionAvailableTime = undefined
            return state
        },
        setShowMobileBottomBar: (state: LayoutState, action: PayloadAction<boolean>) => {
            state.showMobileBottomBar = action.payload
            return state
        },
        setShowSwitchedDevicesModal: (state: LayoutState, action: PayloadAction<boolean>) => {
            state.showSwitchedDevicesModal = action.payload
            return state
        },
        setThreadDoneScrolling: (state: LayoutState, action: PayloadAction<boolean>) => {
            state.threadDoneScrolling = action.payload
            return state
        },
        setThreadFoundLastBubble: (state: LayoutState, action: PayloadAction<boolean>) => {
            state.threadFoundLastBubble = action.payload
            return state
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setSidebarState,
    setShowConfetti,
    setModalState,
    setQuestionTimestampData,
    resetQuestionTimestampData,
    setShowMobileBottomBar,
    setShowSwitchedDevicesModal,
    setThreadDoneScrolling,
    setThreadFoundLastBubble,
} = layoutSlice.actions

export default layoutSlice.reducer
