import { Colors } from "../../utils/colors"
import { IconProps } from "../../types"

export const ArrowUpIcon = ({
    color = Colors.headlandsBlack,
    className = "",
    onClick = () => {},
}: IconProps) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <path d="M0 8L1.41 9.41L7 3.83V16H9V3.83L14.58 9.42L16 8L8 0L0 8Z" fill={color} />
        </svg>
    )
}
