import styled, { css } from "styled-components"
import { StyledH4, StyledP, StyledSpan } from "../../../styles/styledcomponents"

export const StyledUserRow = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 3px;

    ${StyledSpan} {
        font-weight: 700;
    }
`
export const BaseAnswerCardStyles = css`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.headlandsGray4};
    border-radius: ${({ theme }) => theme.borderRadius2};
    padding: 12px 18px;
    box-sizing: border-box;
`

export const StyledAnswerCard = styled.div`
    ${BaseAnswerCardStyles};
    min-width: 300px;
    max-width: 300px;
    max-height: 220px;
    height: min-content;

    ${StyledSpan}, ${StyledP} {
        color: ${({ theme }) => theme.headlandsGrayDark};
    }

    ${StyledP} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 9; // add ellipsis if p has more than 9 lines
        -webkit-box-orient: vertical;
    }
`

export const StyledMoreAnswerCard = styled.div`
    ${BaseAnswerCardStyles};
    min-height: 200px;
    min-width: 200px;
    justify-content: center;
    align-items: center;

    ${StyledH4} {
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.headlandsGray10};
    }

    &:hover {
        cursor: pointer;
    }
`

export const StyledNoAnswersCard = styled.div`
    ${BaseAnswerCardStyles};
    height: 200px;
    width: 200px;
    align-items: center;
    justify-content: center;

    ${StyledH4}, ${StyledP} {
        color: ${({ theme }) => theme.headlandsGray10};
        text-align: center;
    }

    ${StyledH4} {
        font-size: 14px;
        margin-bottom: 6px;
        margin-top: 3px;
    }

    ${StyledP} {
        font-size: 12px;
    }
`

export const StyledMoreItemsClarificationText = styled(StyledSpan)`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.thread.student.bubble};
    margin-right: 6px;

    &:hover {
        cursor: pointer;
    }
`
