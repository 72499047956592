import { useDispatch } from "react-redux"
import { addThreadVersionToHistoryList } from "../apollo/cacheHelper"
import { EDIT_THREAD } from "../apollo/mutations"
import { updateThread } from "../redux/threads"
import { useCurrentThreadField, useCurrentThreadGuid } from "./currentThread.hook"
import { MutationHookOptions, MutationResult, useLazyQuery, useMutation } from "@apollo/client"
import { GET_LINT_THREAD } from "../apollo/queries"
import { buildBlocksForSaving, updateLintWarningAndAdvices } from "../redux/blocks"
import { pick } from "lodash"
import { store } from "../redux/store"

const useSaveThread = (options?: MutationHookOptions): [Function, Partial<MutationResult>] => {
    const threadGuid = useCurrentThreadGuid()
    const threadId = useCurrentThreadField("id")
    const threadTitle = useCurrentThreadField("title")
    const threadDescription = useCurrentThreadField("description")
    const threadVersionID = useCurrentThreadField("threadVersionID")
    const threadFont = useCurrentThreadField("font")
    const threadTheme = useCurrentThreadField("theme")

    const dispatch = useDispatch()

    const [lintThreadById] = useLazyQuery(GET_LINT_THREAD, {
        onCompleted: (data) => {
            dispatch(updateLintWarningAndAdvices(data?.lintThread || []))
        },
        // Lint errors and warnings should be refetch everytime to check if something had changed
        fetchPolicy: "network-only",
    })

    const [editThread, { loading, error }] = useMutation(EDIT_THREAD, {
        ...(options || {}),
        onCompleted: (data) => {
            threadGuid &&
                data.editThread.threadVersionID &&
                data.editThread.version &&
                dispatch(
                    updateThread({
                        guid: threadGuid,
                        thread: {
                            threadVersionID: data.editThread.threadVersionID,
                            version: data.editThread.version, // necessary for the `GET_THREAD_DURATION` query
                        },
                    })
                )
            threadId && void lintThreadById({ variables: { id: threadId } })
            options?.onCompleted && options?.onCompleted(data)
        },
        update: (cache, result, updateOptions) => {
            if (!threadId) return
            const editedThread = result.data?.editThread
            const newVersionHistory = {
                id: editedThread?.threadVersionID,
                time: editedThread?.lastUpdateTime,
                editor: pick(editedThread?.instructor, [
                    "id",
                    "firstName",
                    "lastName",
                    "profileImageURL",
                ]),
                published: false,
            }
            addThreadVersionToHistoryList(cache, newVersionHistory, threadGuid)
            options?.update && options?.update(cache, result, updateOptions)
        },
    })
    const saveCurrentThread = (options?: Omit<MutationHookOptions, "context">) => {
        const {
            blocks: reducerBlocks,
            blocksIndex,
            previewBlocks,
            previewBlocksIndex,
            synopsisBlocks,
            synopsisBlocksIndex,
        } = store.getState().blocksReducer
        return editThread({
            ...options,
            variables: {
                threadId: threadId,
                threadVersionId: threadVersionID,
                edit: {
                    title: threadTitle,
                    blocks: JSON.stringify(buildBlocksForSaving(reducerBlocks, blocksIndex)),
                    displayDescription: threadDescription,
                    preview: JSON.stringify(
                        buildBlocksForSaving(previewBlocks, previewBlocksIndex)
                    ),
                    synopsis: JSON.stringify(
                        buildBlocksForSaving(synopsisBlocks, synopsisBlocksIndex)
                    ),
                    theme: threadTheme,
                    font: threadFont,
                },
            },
        })
    }

    return [saveCurrentThread, { loading, error }]
}

export default useSaveThread
