import styled from "styled-components"

export const StyledConfetti = styled.div<{ preview?: boolean }>`
    height: 36px;
    width: 100%;

    img {
        width: inherit;
        height: inherit;
        object-fit: contain;
    }
`
