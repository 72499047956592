/*
 * The Program screen
 * */
import { useParams } from "react-router"
import { useEffect } from "react"
import { useQuery } from "@apollo/client"
import _ from "lodash"
import Thread from "../thread"
import SocialBar from "../components/SocialBar"
import { SidebarType } from "../redux/layout"
import useLayout from "../hooks/layout.hook"
import Sidebar from "react-sidebar"
import { StyledProgramScreen } from "./styles"
import { useSidebars } from "../hooks/sidebars.hook"
import Leaderboard from "../leaderboard"
import LibraryBar from "../components/LibraryBar/LibraryBar"
import { useTheme } from "styled-components"
import SidebarToggle, { SidebarTogglePosition } from "../components/SidebarToggle"
import { useSelectedEnrolledProgram } from "../hooks/enrolledProgram.hook"
import useLockScroll from "../hooks/lockSroll.hook"
import EmptyHomePage from "./components/EmptyHomePage"
import { ENROLLED_PROGRAMS } from "../apollo/queries"
import { Close } from "@mui/icons-material"
import MenuIcon from "../assets/icons/MenuIcon"
import LeaderboardIcon from "../assets/icons/LeaderboardIcon"

const Program = () => {
    const { programGuid } = useParams<any>()
    const { isMobile } = useLayout()
    const { data: enrolledProgramsData } = useQuery(ENROLLED_PROGRAMS)

    const programs = enrolledProgramsData?.enrolledPrograms || []
    const {
        toggleSidebar,
        openSidebars,
        closeSidebars,
        canUseSidebar,
        socialBarOpen,
        libraryBarOpen,
        enableSidebarAnimation,
    } = useSidebars()
    const theme = useTheme()

    /*
     * When there is no program guid on the URL, and the social sidebar is open, close it
     * */
    useEffect(() => {
        if (!programGuid && socialBarOpen) closeSidebars([SidebarType.social])
        // `closeSidebars` dependency is not necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programGuid, socialBarOpen])

    /*
     * By default try to open the new library bar (desktop devices only) without animation
     * */
    useEffect(() => {
        !isMobile && openSidebars([SidebarType.library], false)
        // openSidebars dependency is not needed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile])

    /*
     * This effect selects `programGuid` on the url if there is no one present.
     * Only for mobile devices, other devices won't select a program by default.
     * */
    useEffect(() => {
        if (_.isEmpty(programs) || !isMobile) return
        if (!programGuid) openSidebars(SidebarType.library, false)
        // history dependency is not needed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programGuid, programs, isMobile])

    useLockScroll({ condition: isMobile && (!!libraryBarOpen || !!socialBarOpen) })

    return (
        <StyledProgramScreen isMobile={isMobile}>
            <SidebarToggle
                onClick={() => toggleSidebar(SidebarType.library)}
                position={SidebarTogglePosition.left}
                sidebarWidth={`${theme.sidebarWidths.library}px`}
                leftPadding={theme.variables.program.libraryBarPadding.left}
                sidebarOpen={!!libraryBarOpen}
                styles={{ zIndex: socialBarOpen ? 998 : 1000 }}
                enabledTransition={enableSidebarAnimation}
                withBorder
            />
            {programGuid && canUseSidebar(SidebarType.social) && (
                <SidebarToggle
                    onClick={() => toggleSidebar(SidebarType.social)}
                    position={SidebarTogglePosition.right}
                    sidebarWidth={`${theme.sidebarWidths.social}px`}
                    rightPadding={theme.variables.program.socialBarPadding.right}
                    icon={
                        socialBarOpen ? (
                            <Close sx={{ color: theme.colors.base.uiBgIcon }} />
                        ) : (
                            <LeaderboardIcon />
                        )
                    }
                    styles={{ zIndex: libraryBarOpen ? 998 : 1000 }}
                    enabledTransition={enableSidebarAnimation}
                />
            )}
            <Sidebar
                sidebar={<LibraryBar />}
                open={libraryBarOpen}
                onSetOpen={() => toggleSidebar(SidebarType.library)}
                overlayClassName={"sidebar-overlay"}
                shadow={false}
                touch={isMobile}
                docked={libraryBarOpen && !isMobile}
                transitions={enableSidebarAnimation}
                styles={{
                    root: {
                        height: "inherit",
                        width: isMobile && !libraryBarOpen ? "0" : "unset",
                    },
                    content: { overflowY: "auto" },
                    sidebar: {
                        overflowY: "hidden",
                        zIndex: "10",
                        position: isMobile ? "fixed" : "absolute",
                    },
                }}
            >
                <Sidebar
                    sidebar={<SocialBar />}
                    open={socialBarOpen}
                    onSetOpen={() => toggleSidebar(SidebarType.social)}
                    touch={isMobile}
                    pullRight
                    styles={{
                        content: {
                            overflowY: "hidden",
                            display: "flex",
                            flexDirection: "column",
                        },
                        sidebar: { zIndex: "10", position: isMobile ? "fixed" : "absolute" },
                    }}
                    overlayClassName={"sidebar-overlay"}
                    shadow={false}
                    docked={socialBarOpen && !isMobile}
                    transitions={enableSidebarAnimation}
                >
                    <ProgramContent isInsideSidebar />
                </Sidebar>
            </Sidebar>
            <ProgramContent />
        </StyledProgramScreen>
    )
}

export const ProgramContent = ({ isInsideSidebar }: { isInsideSidebar?: boolean }) => {
    const { isMobile } = useLayout()
    const { programGuid, threadGuid } = useParams<any>()
    const { selectedEnrolledProgram } = useSelectedEnrolledProgram()

    if ((isInsideSidebar && isMobile) || (!isInsideSidebar && !isMobile)) return null

    if (programGuid) {
        if (threadGuid) return <Thread programGuid={programGuid} threadGuid={threadGuid} />
        else if (selectedEnrolledProgram?.leaderboard && !isMobile) return <Leaderboard />
    }

    return <EmptyHomePage />
}

export default Program
