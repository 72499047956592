import styled, { css } from "styled-components"
import { FontOverride } from "../../../styles/styledcomponents"

/*
 * This method generate `ql-indent` css selectors with a custom padding-left for each one.
 * Quill uses these classnames when using indenting
 * */
export const generateQlIndents = (n: number) => {
    let result = ""
    for (let i = 1; i < n; i++) {
        result += `
            &.ql-indent-${i} {
              padding-left: calc(3px + 18px * ${i})!important;
            }
        `
    }
    return result
}
export const StyledTextBlock = styled.div<{ gray?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background-color: transparent;
    border-radius: ${({ theme }) => theme.borderRadius2} ${({ theme }) => theme.borderRadius2}
        ${({ theme }) => theme.borderRadius2} 2px;
    transition: background-color 0.2s;

    &:hover {
        background-color: ${({ theme }) => theme.headlandsGray6};
        transition: background-color 0.2s;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .quill {
        ${({ theme }) => FontOverride(theme.fonts.thread)}
    }

    .ql-editor {
        padding: 12px 18px !important;
    }

    ${({ gray }) =>
        gray &&
        css`
            background-color: ${({ theme }) => theme.headlandsGray4};
        `}
`
