import { UserPermissions } from "../types"
import { QueryHookOptions, useQuery } from "@apollo/client"
import { USER_PERMISSIONS } from "../apollo/queries"

type useUserPermissionsResponse = {
    permissions: { [keys in UserPermissions]: boolean }
    loading?: boolean
    error?: boolean
}

export const useUserPermissions = (
    options?: Pick<QueryHookOptions, "errorPolicy">
): useUserPermissionsResponse => {
    const { data, loading, error } = useQuery(USER_PERMISSIONS, {
        ...(options || {}),
    })
    return {
        permissions: {
            [UserPermissions.mayCreate]: data?.aboutMe?.mayCreate ?? false,
            [UserPermissions.distinguishedInstructor]:
                data?.aboutMe?.distinguishedInstructor ?? false,
            [UserPermissions.maySeeUnpublishedThreads]:
                data?.aboutMe?.maySeeUnpublishedThreads ?? false,
            [UserPermissions.maySeeExperimentalFeatures]:
                data?.aboutMe?.maySeeExperimentalFeatures ?? false,
            [UserPermissions.isAdministrator]: data?.aboutMe?.isAdministrator ?? false,
        },
        loading,
        error: !!error,
    }
}
