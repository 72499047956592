import { useEffect, useState } from "react"
import { Block, BlocksType, BlockType } from "../../../types"
import { useDispatch } from "react-redux"
import { updateBlock } from "../../../redux/blocks"
import useVariableDefinitions from "../../../hooks/variables.hook"
import { StyledSaveAnswer } from "./styles"
import { useTheme } from "styled-components"
import { getVariableType } from "../../../utils/utils"

interface SaveAnswerProps {
    block: Block
    inLineLabel?: boolean
    onAddBlock?: (index?: number, initialContent?: string) => void
}

const SaveAnswer = ({ block, inLineLabel = true, onAddBlock }: SaveAnswerProps) => {
    const dispatch = useDispatch()
    const variableName = block.save_to_variable || ""
    const [altVariableName, setAltVariableName] = useState<string>()
    const assignedVariables = useVariableDefinitions()
    const [error, setError] = useState<string>()

    const theme = useTheme()

    /*
     * This useEffect is used when the variable name is change from the setting bar
     * */
    useEffect(() => {
        if (variableName !== altVariableName) {
            const hideVariableValue = variableName === block.id
            setAltVariableName(hideVariableValue ? "" : variableName)
        }
    }, [variableName])

    /*
     * This function check whether the variable name is a valid one or not.
     * We consider a valid variable name if these items are accomplished:
     * - there is no other variable with the same name OR
     * - there is a variable with the same name and both variables types are the exact same OR
     * - there is a variable name with the same name but it belongs to the same block (it catches a bug related to the
     * last previous valid variable name)
     * */
    const isVariableNameValid = (variableNameToTest: string): boolean => {
        if (assignedVariables) {
            return !assignedVariables.find(
                (assignedVariable) =>
                    assignedVariable.name === variableNameToTest &&
                    assignedVariable.blockId !== block.id &&
                    assignedVariable.type !==
                        getVariableType(block.type, block?.properties?.may_select_multiple)
            )
        }
        return true
    }

    // This function add a new variable to a wanted thread
    const addVariableName = (variableNameToAdd: string): void => {
        let blockToUpdate = {
            ...block,
            save_to_variable: variableNameToAdd,
        }

        dispatch(
            updateBlock({
                blocksType: BlocksType.BLOCKS,
                id: block.id,
                block: blockToUpdate,
            })
        )
    }

    /*
     * This method handles the validation and the alternative name (in case of an error)
     * */
    const handleChange = (value: string) => {
        if (isVariableNameValid(value)) {
            setAltVariableName(value)
            setError(undefined)
        } else {
            setError("You can not reassign a variable name with a different type")
            setAltVariableName(value)
        }
    }

    /*
     * This method handles the blur, it only adds the variable if is a valid one.
     * Note: we decide to add it on blur to solve an issue where if variable name contains
     * another variable name, the first one get overwrite. This is a temporal solution,
     * the correct way to solve this is with the variable refactor.
     * */
    const handleBlur = (value: string) => {
        if (isVariableNameValid(value)) {
            addVariableName(value)
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (onAddBlock && event.key === "Enter") {
            setTimeout(onAddBlock, 0)
        }
    }

    return (
        <StyledSaveAnswer inlineLabel={inLineLabel}>
            <div className={"save-answer-input"}>
                <label>Save answer in</label>
                <div className={"variable-picker"}>
                    <span style={{ color: theme.headlandsPrimaryColor }}>@</span>
                    <input
                        placeholder={"variable_name"}
                        name={"variable-name-input"}
                        onChange={(e) => handleChange(e.target.value)}
                        onBlur={(e) => handleBlur(e.target.value)}
                        onKeyDown={handleKeyDown}
                        value={altVariableName}
                        autoComplete={"off"}
                    />
                </div>
            </div>
            {error && <span className={"save-answer-error"}>{error}</span>}
        </StyledSaveAnswer>
    )
}

export default SaveAnswer
