import { StyledBox, StyledSpan } from "../../../../styles/styledcomponents"
import { StyledSettingsBarSectionTitle } from "../styles"
import Select from "../../../../components/Select/Select"
import { StyledThreadThemeCircle } from "./styles"
import { components, MultiValue, SingleValue } from "react-select"
import { ThreadFont, ThreadTheme } from "../../../../types"
import { useCurrentThreadGuid } from "../../../../hooks/currentThread.hook"
import { useDispatch } from "react-redux"
import _ from "lodash"
import { updateStyle } from "../../../../redux/threads"
import useThreadStyles from "../../../../hooks/threadStyles.hook"
import { getThreadThemeFont } from "../../../../utils/utils"

const StyleTab = () => {
    const threadGuid = useCurrentThreadGuid()

    const { theme } = useThreadStyles()

    const dispatch = useDispatch()

    const handleThemeChange = (threadTheme: ThreadTheme) => {
        dispatch(
            updateStyle({
                guid: threadGuid,
                theme: threadTheme,
                font: getThreadThemeFont(threadTheme),
            })
        )
    }

    function isMultiValue<T>(arg: MultiValue<T> | SingleValue<T>): arg is MultiValue<T> {
        return Array.isArray(arg)
    }

    const ThemeSingleValue = (props: any) => (
        <components.SingleValue {...props}>
            <StyledBox css={{ display: "flex", alignItems: "center" }}>
                <StyledThreadThemeCircle threadThemeKey={props.data.value} />
                <StyledSpan css={{ marginLeft: "6px", textTransform: "capitalize" }}>
                    {props.data.label}
                </StyledSpan>
            </StyledBox>
        </components.SingleValue>
    )

    const ThemeOption = (props: any) => {
        return (
            <components.Option {...props} key={props.value}>
                <StyledBox css={{ display: "flex", alignItems: "center" }}>
                    <StyledThreadThemeCircle threadThemeKey={props.data.value} />
                    <StyledSpan css={{ marginLeft: "6px", textTransform: "capitalize" }}>
                        {props.data.label}
                    </StyledSpan>
                </StyledBox>
            </components.Option>
        )
    }

    const getLabel = (key: ThreadTheme | ThreadFont): string => key.replace("_", " ")

    return (
        <StyledBox>
            <StyledSettingsBarSectionTitle>Theme</StyledSettingsBarSectionTitle>
            <Select
                defaultValue={{
                    value: ThreadTheme.Default,
                    label: getLabel(ThreadTheme.Default),
                }}
                value={theme ? { value: theme, label: getLabel(theme as ThreadTheme) } : undefined}
                options={Object.values(ThreadTheme).map((value) => ({
                    value,
                    label: getLabel(value),
                }))}
                onChange={(themeOption) =>
                    themeOption &&
                    !isMultiValue(themeOption) &&
                    handleThemeChange(themeOption.value as ThreadTheme)
                }
                isSearchable={false}
                styles={{
                    container: () => ({
                        flex: "unset",
                    }),
                    control: () => ({
                        borderRadius: "8px",
                    }),
                }}
                components={{
                    Option: ThemeOption,
                    SingleValue: ThemeSingleValue,
                }}
            />
        </StyledBox>
    )
}

export default StyleTab
