import "./RichTextEditor.scss"
import classNames from "classnames"
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import useStateRef from "../../hooks/stateRef.hook"
import { ContextMenuType, MenuType, MENU_KEYS, RichtTextFormatOptions } from "../../types"
import ContextMenu from "../ContextMenu/ContextMenu"
import ReactQuill, { Quill } from "react-quill"
import { editorModules } from "../../utils/consts"
import EmojiPicker from "../EmojiPicker/EmojiPicker"
import { Sources, RangeStatic } from "quill"
import ReactDOM from "react-dom"
import { getLastSpecialCharacterAndIndex } from "../../utils/utils"
import { isNil } from "lodash"
import VariableBlot from "../../creator/components/TextBlock/customBlots/VariableBlot"

if (Quill) Quill.register(VariableBlot, true)

export interface RichTextEditorProps {
    id?: string
    value: string | undefined
    onChange: (value: string) => void
    onDelete?: () => void
    placeholder?: string
    enableVariables?: boolean
    enableEmojis?: boolean
    enableFormatting?: boolean
    noBorder?: boolean
    className?: string
    onBlur?: () => void
    readOnly?: boolean
    autoFocus?: boolean
    onEnterPress?: () => void
    reactQuillRef?: MutableRefObject<ReactQuill | null>
}

const RichTextEditor = ({
    id,
    value,
    onChange,
    placeholder,
    enableVariables,
    enableEmojis,
    enableFormatting,
    noBorder,
    onDelete,
    className,
    onBlur,
    readOnly,
    autoFocus = true,
    onEnterPress,
    reactQuillRef,
}: RichTextEditorProps) => {
    const [editorFocused, setEditorFocused] = useState<boolean>(false)
    const [editorReady, setEditorReady] = useState<boolean>(false)

    const [shouldDelete, setShouldDelete] = useState(true)

    const [activeMenu, setActiveMenu] = useState<MenuType | undefined>()

    const [selection, setSelection, selectionRef] = useStateRef({ index: 0, length: 0 })
    const [contextMenuPosition, setContextMenuPosition] = useState<{
        top: number
        left: number
        bottom?: number
    }>({ top: 0, left: 0 })
    const [contentSearch, setContentSearch, contentSearchRef] = useStateRef("")

    const closeActiveMenu = () => setActiveMenu(undefined)

    const containerRef = useRef<HTMLDivElement>(null)
    const editorRefAux = useRef<ReactQuill | null>(null)
    const editorRef = reactQuillRef || editorRefAux

    useEffect(() => {
        // This prevents react-quill's default behavior of autofocusing on mount
        !autoFocus && setTimeout(() => setEditorReady(true), 0)
    }, [])

    const modules = useMemo(() => {
        const { keyboard, ...otherModules } = editorModules
        const { handleEnter } = keyboard.bindings
        return {
            ...otherModules,
            keyboard: { bindings: { handleEnter } },
            toolbar: enableFormatting ? editorModules.toolbar : false,
        }
    }, [])

    const handleChange = (newText: string, delta: any, source: Sources) => {
        /*
         * Avoid triggering if the source is the 'api'.
         * This was done to prevent calling the onChange function when the content is render the first time.
         * Todo: we still don't know why ReactQuill is calling the onChange function when first render.
         * */
        if (source === "api") return

        const editor = editorRef?.current?.editor
        if (!editor) return
        const textBeforeCursor = editor.getText(0, selection.index)
        const specialCharacter = getLastSpecialCharacterAndIndex(textBeforeCursor)
        // We add 1 to specialCharacter.index to account for the special character itself
        const searchText =
            (specialCharacter && textBeforeCursor.slice(specialCharacter.index + 1)) || ""
        !isNil(searchText) && setContentSearch(searchText)
        if (specialCharacter) {
            const bounds = editor.getBounds(selection.index, selection.length)
            // {editor width} - {popup width} - {cursor position} - {cursor offset}
            const diffToEdge = 600 - 196 - (bounds.left + 12) - 16
            const container = containerRef.current
            const containerTop = container?.getBoundingClientRect().top || 0
            const containerBottom = container?.getBoundingClientRect().bottom || 0
            const containerLeft = container?.getBoundingClientRect().left || 0
            switch (specialCharacter.key) {
                case MENU_KEYS.VariableMenu:
                    if (enableVariables) {
                        setContextMenuPosition({
                            top: containerTop + bounds.bottom - 16,
                            bottom: containerBottom,
                            left:
                                diffToEdge > 0
                                    ? containerLeft + bounds.left
                                    : containerLeft + bounds.left + diffToEdge,
                        })
                        setActiveMenu(MenuType.VARIABLES)
                    }
                    break
                case MENU_KEYS.EmojiMenu:
                    if (enableEmojis && searchText.length > 1) {
                        setContextMenuPosition({
                            top: containerTop + bounds.bottom - 18,
                            bottom: containerBottom,
                            left:
                                diffToEdge > 0
                                    ? containerLeft + bounds.left
                                    : containerLeft + bounds.left + diffToEdge,
                        })
                        setActiveMenu(MenuType.EMOJIS)
                    }
                    break
            }
        } else {
            closeActiveMenu()
        }
        newText !== "" && setShouldDelete(false)
        onChange(newText)
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        const editor = editorRef?.current?.editor
        if (!editor) return
        const isEmpty = editor.getText() === "" || editor.getText() === "\n"

        if (event.code === "Backspace") {
            if (isEmpty && !shouldDelete && onDelete) {
                setShouldDelete(true)
            } else if (isEmpty && shouldDelete && onDelete) {
                onDelete()
            }
        } else if (onEnterPress && event.code === "Enter") {
            onEnterPress()
        }
    }

    const handleEmojiSelect = (emoji: string) => {
        const searchLength = contentSearchRef.current.length
        const index = selectionRef.current.index
        const searchIndex = index - searchLength
        // @ts-ignore
        editorRef.current?.editor.insertText(index, `${emoji} `, "user")
        editorRef.current &&
            setTimeout(() =>
                // @ts-ignore
                editorRef.current.getEditor().deleteText(searchIndex - 1, searchLength + 1, "user")
            )
        // @ts-ignore
        editorRef.current.focus()
        // @ts-ignore
        setTimeout(() => editorRef.current.editor.setSelection(searchIndex + 2, 0, "user"), 0)
        closeActiveMenu()
    }

    const handleSelectionChange = (newSelection: { index: number; length: number }) => {
        newSelection && setSelection(newSelection)
    }

    const handleVariableSelect = (variableText: string, error?: boolean) => {
        const variable = `@${variableText}`
        const searchLength = contentSearchRef.current.length
        const index = selectionRef.current.index - searchLength
        // deletes the text the user enter on the editor
        editorRef.current &&
            setTimeout(
                () =>
                    // @ts-ignore
                    editorRef.current?.getEditor().deleteText(index - 1, searchLength + 1, "user"),
                0
            )
        const newIndex = selectionRef.current.index
        // @ts-ignore
        editorRef.current?.editor.insertEmbed(
            newIndex,
            "VariableBlot",
            JSON.stringify({ name: variable, error: !!error }),
            "user"
        )
        // @ts-ignore
        editorRef.current.focus()

        /*
         * We place the cursor at the end of the inserted variable.
         * We add 2 to the current index, 1 for the length of the VariableBlot,
         * and another for it to be placed after it.
         */
        // @ts-ignore
        editorRef.current.editor.setSelection(newIndex + 2, 0, "user")
        closeActiveMenu()
    }

    const handleBlur = (previousRange: RangeStatic, source: Sources) => {
        if (!activeMenu && source !== "silent") {
            setEditorFocused(false)
            onBlur && onBlur()
        }
    }

    return (
        <div
            className={classNames("rich-text-editor", className, { "no-border": noBorder })}
            ref={containerRef}
        >
            <ReactQuill
                id={id}
                className={classNames({
                    focused: editorFocused,
                    "no-formatting": !enableFormatting,
                })}
                placeholder={placeholder}
                bounds={".editor-container"}
                ref={(el) => (editorRef.current = el)}
                theme={"bubble"}
                defaultValue={value}
                modules={modules}
                formats={
                    enableFormatting
                        ? [...Object.values(RichtTextFormatOptions), "VariableBlot"]
                        : ["VariableBlot"]
                }
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onChangeSelection={handleSelectionChange}
                onFocus={() => setEditorFocused(true)}
                onBlur={handleBlur}
                readOnly={readOnly || (!autoFocus && !editorReady)}
            />
            {activeMenu === MenuType.VARIABLES &&
                ReactDOM.createPortal(
                    <ContextMenu
                        position={contextMenuPosition}
                        handleOptionSelect={handleVariableSelect}
                        onRequestClose={closeActiveMenu}
                        type={ContextMenuType.VARIABLES}
                        search={contentSearch}
                    />,
                    document.body
                )}
            {activeMenu === MenuType.EMOJIS &&
                contentSearch.length > 1 &&
                ReactDOM.createPortal(
                    <EmojiPicker
                        handleEmoji={handleEmojiSelect}
                        onRequestClose={closeActiveMenu}
                        position={contextMenuPosition}
                        search={contentSearch}
                    />,
                    document.body
                )}
        </div>
    )
}

export default RichTextEditor
