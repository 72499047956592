import { IconProps } from "../../types"

export const LockedIcon = ({ className, color }: IconProps) => {
    return (
        <svg
            width="12"
            height="16"
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M2.49316 15.0098H9.50684C10.5527 15.0098 11.0586 14.4902 11.0586 13.3555V7.92773C11.0586 6.90918 10.6416 6.38281 9.78027 6.30078V4.74219C9.78027 2.07617 7.90723 0.777344 6 0.777344C4.09277 0.777344 2.21973 2.07617 2.21973 4.74219V6.30762C1.37891 6.40332 0.93457 6.92285 0.93457 7.92773V13.3555C0.93457 14.4902 1.4541 15.0098 2.49316 15.0098ZM4.22949 4.66699C4.22949 3.47754 4.96777 2.72559 6 2.72559C7.03223 2.72559 7.77051 3.47754 7.77051 4.66699V6.28711H4.22949V4.66699Z"
                fill={color ?? "#777777"}
            />
        </svg>
    )
}
