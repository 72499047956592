import styled from "styled-components"
import { StyledBox, StyledSpan } from "../../../styles/styledcomponents"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import FilterListIcon from "@mui/icons-material/FilterList"
import CancelIcon from "@mui/icons-material/Cancel"

export const StyledSearchBar = styled(StyledBox)`
    display: flex;
    align-items: center;
    margin-top: 18px;
`
export const StyledSearchInputContainer = styled(StyledBox)`
    display: flex;
    align-items: center;
    height: 32px;
    border: 1px solid ${({ theme }) => theme.headlandsGray8};
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 6px;
    box-sizing: border-box;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};

    input {
        width: 100%;
        border-style: none;
        font-size: 14px;

        &:hover,
        &:focus {
            outline: none !important;
            border-width: 0 !important;
            border: none !important;
        }

        &::placeholder {
            color: ${({ theme }) => theme.headlandsGray2};
        }
    }
`

export const StyledCloseIcon = styled(CloseIcon)`
    fill: ${({ theme }) => theme.utils.addOpacityToHex(theme.headlandsBlack, 0.6)} !important;
    margin-left: 6px;
    font-size: 10px !important;

    &:hover {
        cursor: pointer;
    }
`

export const StyledSearchIcon = styled(SearchIcon)`
    fill: ${({ theme }) => theme.headlandsGray10} !important;
    margin-right: 4px;
    font-size: 13px !important;
`

export const StyledClearIcon = styled(CancelIcon)`
    color: ${({ theme }) => theme.colors.base.uiBgIcon};
    font-size: 12px !important;
    cursor: pointer;
`

export const StyledFilterListIcon = styled(FilterListIcon)`
    fill: ${({ theme }) => theme.colors.base.uiBgIcon} !important;
    margin-left: 15px;
    font-size: 18px !important;

    &:hover {
        cursor: pointer;
    }
`

export const StyledPill = styled(StyledBox)`
    display: flex;
    align-items: center;
    padding: 4px 6px;
    background-color: ${({ theme }) => theme.headlandsPrimaryColorAlpha};
    border-radius: 30px;
    margin: 0 3px;

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }

    ${StyledSpan} {
        font-size: 10px;
        line-height: 10px;
        font-weight: 500;
        color: ${({ theme }) => theme.headlandsBlack};
    }
`
