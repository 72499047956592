import { useLazyQuery, useReactiveVar } from "@apollo/client"
import useLazySubscription from "../hooks/lazySubscription.hook"
import { HANDLE_USER_INPUT } from "../apollo/subscriptions"
import { Block, BlockType, ChoiceQuestionOption, GraphQLErrors } from "../types"
import { useEffect, useMemo, useState } from "react"
import {
    Choice,
    ChoiceData,
    InputMaybe,
    Maybe,
    ThreadStatus,
    UserAnswer,
    UserInput,
    VariableValueInput,
} from "../apollo/generated/graphql"
import { sessionDataVar } from "../apollo/cache-store"
import { blockIsScorable, generateUUID, getVariableObject } from "../utils/utils"
import { compact, concat, isEmpty, isNil, omit } from "lodash"
import { useDispatch } from "react-redux"
import { addThreadUserAnswer } from "../redux/threads"
import {
    useCurrentResponseThreadField,
    useCurrentThreadField,
    useCurrentThreadGuid,
} from "../hooks/currentThread.hook"
import { ChoicePreviewProps } from "../components/ThreadPreview/ChoicePreview"
import RenderPreviewBlock from "../components/ThreadPreview/RenderPreviewBlock"
import {
    useSelectedEnrolledProgramAttribute,
    useSelectedProgramGuid,
} from "../hooks/enrolledProgram.hook"
import useTimeToAnswer from "../hooks/timeToAnswer.hook"
import { GraphQLError } from "graphql/error/GraphQLError"
import {
    setShowSwitchedDevicesModal,
    setThreadDoneScrolling,
    setThreadFoundLastBubble,
} from "../redux/layout"
import useIntersectionObserver from "../hooks/intersection.hook"
import {
    CORRECTNESS_ANIMATION_DURATION_MS,
    SCORE_ANIMATION_DURATION_MS,
} from "../creator/components/QuestionBlock/styles"
import { GET_DYNAMIC_VARIABLES_VALUE } from "../apollo/queries"
import {
    updateEnrolledProgramByPath,
    updateEnrolledProgramThreadByPath,
} from "../apollo/cacheHelper"
import { useCurrentEnrolledProgramThreadAttribute } from "../hooks/enrolledProgramThread.hook"

// these props will be injected by the hoc
export interface UserInputProps {
    correct?: Maybe<boolean>
    answer?: Maybe<UserAnswer>
    score?: Maybe<number>
    choiceData?: Maybe<ChoiceData[]>
    handleAnswer: (choices: Choice[]) => void
}
// these props will be provided by the InteractiveComponent
export interface InteractiveComponentBaseProps {
    block: Block
    choiceProps: ChoicePreviewProps
}

const withUserInput =
    <T extends UserInputProps & InteractiveComponentBaseProps>(InteractiveComponent: React.FC<T>) =>
    (interactiveComponentProps: Omit<T, "handleAnswer" | "correct" | "answer" | "score">) => {
        const programGUID = useSelectedProgramGuid()
        const threadGUID = useCurrentThreadGuid()
        const sessionData = useReactiveVar(sessionDataVar)
        const threadHasScore = useCurrentThreadField("hasScore")
        const threadUserAnswers = useCurrentResponseThreadField("userAnswers")
        const threadCurrentObjectID = useCurrentResponseThreadField("currentObjectID")
        const threadId = useCurrentThreadField("id")
        const { attribute: programId } = useSelectedEnrolledProgramAttribute("id")
        const { attribute: isSingleShareThread } =
            useSelectedEnrolledProgramAttribute("isSingleShareThread")
        const { attribute: currentEnrolledProgramThreadStatus } =
            useCurrentEnrolledProgramThreadAttribute("status")

        const [renderedBlocks, setRenderedBlocks] = useState<Block[]>([])
        const [answers, setAnswers] = useState<UserAnswer[]>([])
        const [answer, setAnswer] = useState<UserAnswer>()
        const [subscriptionFinished, setSubscriptionFinished] = useState<boolean>()

        const [getDynamicVariableValues] = useLazyQuery(GET_DYNAMIC_VARIABLES_VALUE)

        const dispatch = useDispatch()

        const { getTimeToAnswer, startTimer } = useTimeToAnswer()

        const { observe } = useIntersectionObserver({
            partialViewableCallback: () => dispatch(setThreadDoneScrolling(true)),
            fullyViewableCallback: () => dispatch(setThreadDoneScrolling(true)),
        })

        const [
            handleUserInput,
            {
                data: handleUserInputData,
                loading: handleUserInputLoading,
                error: handleUserInputError,
            },
        ] = useLazySubscription(HANDLE_USER_INPUT)

        const responseBlocks: Block[] = useMemo(() => {
            if (handleUserInputData?.handleUserInput.content) {
                return JSON.parse(handleUserInputData?.handleUserInput.content)
            }
            return []
        }, [handleUserInputData?.handleUserInput.content])

        /*
         * We manually trigger the getDynamicVariableValues query for the "threadScore" and "threadChallengerScore"
         * so the ThreadScoreHeader (that has the same query) gets updated every time there is a user input
         */
        useEffect(() => {
            /*
             * Trigger the update of the score only if:
             * 1. the user input HAS correct answer
             * 2. the user input is NOT in preview mode (creator tool preview)
             */
            if (
                !isNil(handleUserInputData?.handleUserInput.correct) &&
                !interactiveComponentProps.choiceProps.isPreview
            ) {
                // TODO: check if is possible to NOT have `sessionData?.id`
                if (sessionData?.id || (programId && threadId)) {
                    getDynamicVariableValues({
                        variables: {
                            input: {
                                names: ["threadScore", "threadChallengerScore"],
                                telemetrySessionId: sessionData?.id,
                                programID: !sessionData?.id ? programId : undefined,
                                threadID: !sessionData?.id ? threadId : undefined,
                            },
                        },
                        fetchPolicy: "network-only", // important, we want to get the latest data from the server
                    })
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            handleUserInputData?.handleUserInput.correct,
            interactiveComponentProps.choiceProps.isPreview,
        ])

        /*
         * We manually update the thread percentage on the enrolled program threads query
         */
        useEffect(() => {
            const percent = handleUserInputData?.handleUserInput?.percentComplete
            if (!isNil(percent) && programGUID && threadGUID) {
                updateEnrolledProgramThreadByPath(programGUID, threadGUID, "progress", percent)
                // note: we need to update the 'status' of the thread the first time an answer is being answered.
                if (currentEnrolledProgramThreadStatus === ThreadStatus.Unlocked) {
                    updateEnrolledProgramThreadByPath(
                        programGUID,
                        threadGUID,
                        "status",
                        ThreadStatus.InProgress
                    )
                }
            }
            // TODO: check if single share thread is still used, if not delete the following code
            if (percent && isSingleShareThread && programGUID) {
                updateEnrolledProgramByPath(programGUID, "progress.percentComplete", percent)
            }
        }, [handleUserInputData?.handleUserInput?.percentComplete])

        // Scroll to bottom every time the `renderedBlocks`s length changes
        useEffect(
            () => {
                if (interactiveComponentProps.choiceProps.scrollToBottom && renderedBlocks.length)
                    interactiveComponentProps.choiceProps.scrollToBottom()
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [renderedBlocks.length]
        )

        // Look for the currentObjectID inside system responses (skeleton loader related)
        useEffect(() => {
            const foundCurrentObject = renderedBlocks.some(
                (renderBlock) => renderBlock.id === threadCurrentObjectID
            )

            if (foundCurrentObject) {
                dispatch(setThreadFoundLastBubble(true))
                const element = document.querySelector(`#bubble-${threadCurrentObjectID}`)
                element && observe(element)
            }
        }, [renderedBlocks, threadCurrentObjectID])

        // Start the timer under the right conditions
        useEffect(() => {
            if (
                threadHasScore &&
                blockIsScorable(interactiveComponentProps.block) &&
                !interactiveComponentProps.choiceProps.answer &&
                !answer
            ) {
                startTimer()
            }
        }, [
            interactiveComponentProps.block,
            interactiveComponentProps.choiceProps.answer,
            answer,
            startTimer,
            threadHasScore,
        ])

        useEffect(() => {
            if (interactiveComponentProps?.choiceProps?.answer) {
                setAnswer(interactiveComponentProps.choiceProps.answer)
            }
        }, [interactiveComponentProps?.choiceProps?.answer])

        /*
         * This effect is used to populate the rendered blocks when refreshing the tread
         */
        useEffect(() => {
            const content = interactiveComponentProps?.choiceProps?.answer?.systemResponse?.content
            if (content && isEmpty(responseBlocks)) {
                const contentParsed = JSON.parse(content)
                contentParsed && setRenderedBlocks(reduceResponseBlocks(contentParsed))
            }
        }, [
            interactiveComponentProps?.choiceProps?.answer?.systemResponse?.content,
            responseBlocks,
        ])

        /*
         * This effect is used to populate the rendered blocks when the subscription respond with content
         */
        useEffect(() => {
            if (responseBlocks && !isEmpty(responseBlocks)) {
                // wait until animation has finished to set the blocks
                if (!interactiveComponentProps.block.properties?.no_correct_answers) {
                    if (
                        !isNil(handleUserInputData?.handleUserInput?.correct) &&
                        !isNil(handleUserInputData?.handleUserInput?.score?.points)
                    ) {
                        setTimeout(
                            () => setRenderedBlocks(reduceResponseBlocks(responseBlocks)),
                            handleUserInputData?.handleUserInput?.correct
                                ? CORRECTNESS_ANIMATION_DURATION_MS + SCORE_ANIMATION_DURATION_MS
                                : CORRECTNESS_ANIMATION_DURATION_MS
                        )
                    }
                }
                // set the blocks right away
                else {
                    setRenderedBlocks(reduceResponseBlocks(responseBlocks))
                }
            }
        }, [
            responseBlocks,
            handleUserInputData?.handleUserInput?.correct,
            handleUserInputData?.handleUserInput?.score?.points,
            interactiveComponentProps.block,
        ])

        /*
         * In case the subscription has finished but didn't return any content to render we resume the thread reproduction
         */
        useEffect(() => {
            if (subscriptionFinished && isEmpty(responseBlocks)) {
                // wait until animation has finished to resume the thread
                if (!interactiveComponentProps.block.properties?.no_correct_answers) {
                    setTimeout(
                        () => resumeThreadReproduction(),
                        handleUserInputData?.handleUserInput?.correct
                            ? CORRECTNESS_ANIMATION_DURATION_MS + SCORE_ANIMATION_DURATION_MS
                            : CORRECTNESS_ANIMATION_DURATION_MS
                    )
                } else resumeThreadReproduction()
            }
        }, [subscriptionFinished, responseBlocks, interactiveComponentProps.block])

        useEffect(() => {
            if (handleUserInputError) {
                /*
                 * If there is an error on the subscription we assume the subscription has finished
                 */
                setSubscriptionFinished(true)

                /*
                 * Here we handle the case where the user has switched
                 * devices and is not using the latest session.
                 */
                if (
                    handleUserInputError.graphQLErrors &&
                    handleUserInputError.graphQLErrors.some(
                        (graphQLError: GraphQLError) =>
                            graphQLError?.extensions?.code ===
                            GraphQLErrors.TELEMETRY_SESSION_CLOSED_ERROR
                    )
                ) {
                    dispatch(setShowSwitchedDevicesModal(true))
                }
            }
        }, [handleUserInputError])

        /*
         * This effect is used to populate the answers blocks when resuming the thread reproduction
         */
        useEffect(() => {
            if (!isEmpty(threadUserAnswers)) setAnswers(compact(concat(answers, threadUserAnswers)))
        }, [threadUserAnswers])

        /*
         * Here we reduce the blocks (if there is more than 1 group) and we add pauses blocks
         */
        const reduceResponseBlocks = (blocks: Block[]): Block[] => {
            return blocks.reduce((accum: Block[], responseBlock: Block, index) => {
                const hasAnswer = answers.some((answer) => answer.object === responseBlock.id)
                const previousHasAnswer = answers.some(
                    (answer) => accum[index - 1] && answer.object === accum[index - 1].id
                )
                let groupObjects = responseBlock.branches![0].objects
                if (!responseBlock.skip_pause) {
                    groupObjects = groupObjects.concat({
                        id: generateUUID(),
                        type: BlockType.PAUSE,
                        value: responseBlock.pause_label,
                        // Since this is a built-in pause, we associate it to it's parent group
                        groupId: responseBlock.id,
                    })
                }
                // we add the group to be able to render it on the DOM and find it when searching for the currentObjectID
                if (responseBlock.type === BlockType.GROUP) {
                    groupObjects.push(responseBlock)
                }
                if (blocks.length === 1) return accum.concat(groupObjects)
                else if (hasAnswer || previousHasAnswer) return accum.concat(groupObjects)
                else return accum
            }, [])
        }

        const saveUserAnswer = (choices: Choice[] | ChoiceQuestionOption[]) => {
            const { block } = interactiveComponentProps
            const userAnswer = {
                // We check for the `groupId` field for the case of built-in pause components
                object: block.groupId || block.id,
                ...omit(
                    getVariableObject(
                        block.type,
                        choices,
                        block.save_to_variable,
                        block?.properties?.may_select_multiple
                    ),
                    "__typename"
                ),
            }

            setAnswer(userAnswer)

            dispatch(
                addThreadUserAnswer({
                    guid: threadGUID,
                    userAnswer,
                })
            )
        }

        const handleAnswer = (options: Choice[] | ChoiceQuestionOption[]) => {
            if (!sessionData?.id) return
            const { block } = interactiveComponentProps

            let input: InputMaybe<UserInput> = {
                telemetrySessionId: sessionData.id,
                // We check for the `groupId` field for the case of built-in pause components
                objectID: block.groupId || block.id,
            }

            if (!block.groupId) {
                const variableValueInput: VariableValueInput = omit(
                    getVariableObject(
                        block.type,
                        options,
                        block.save_to_variable,
                        block.properties?.may_select_multiple
                    ).value,
                    "selectedChoices"
                )
                input.data = {
                    timeToAnswer: getTimeToAnswer() ?? null,
                    value: variableValueInput,
                }
                saveUserAnswer(options)
            }

            setSubscriptionFinished(false)

            handleUserInput({
                variables: { input },
                onSubscriptionComplete: () => {
                    if (interactiveComponentProps.block.type !== BlockType.PAUSE) {
                        setSubscriptionFinished(true)
                    }
                },
            })

            // If the block is a pause component we immediately resume the thread reproduction
            if (interactiveComponentProps.block.type === BlockType.PAUSE) resumeThreadReproduction()
        }

        /*
         * Here we submit the answer for the block itself, therefore we resume the thread reproduction
         */
        const resumeThreadReproduction = () => {
            interactiveComponentProps?.choiceProps?.handleAnswer(
                answer?.value?.selectedChoices
                    ? answer?.value?.selectedChoices
                    : // here we assume that if the answer doesn't have `selectedChoices` then is a free text
                      // question so we have to create a choice for it
                      [
                          {
                              id: generateUUID(),
                              text: answer?.value?.string?.trim(),
                          },
                      ],
                undefined
            )
        }

        return (
            <>
                <InteractiveComponent
                    {...(interactiveComponentProps as T)}
                    handleAnswer={handleAnswer}
                    correct={handleUserInputData?.handleUserInput?.correct}
                    answer={answer}
                    score={handleUserInputData?.handleUserInput?.score?.points}
                    choiceData={handleUserInputData?.handleUserInput.choiceData}
                />
                {!isEmpty(renderedBlocks) &&
                    renderedBlocks.map((bubble, index) => (
                        <RenderPreviewBlock
                            key={bubble.id}
                            bubble={bubble}
                            index={index}
                            readonly={false}
                            isMobile={interactiveComponentProps.choiceProps.isMobile}
                            mode={interactiveComponentProps.choiceProps.mode}
                            scrollToBottom={
                                interactiveComponentProps.choiceProps.scrollToBottom ||
                                (() => undefined)
                            }
                            handleAnswer={(options) =>
                                interactiveComponentProps?.choiceProps?.handleAnswer(options)
                            }
                            isPreview={interactiveComponentProps.choiceProps.isPreview}
                            threadId={interactiveComponentProps.choiceProps.threadId}
                            programId={interactiveComponentProps.choiceProps.programId}
                            programGuid={programGUID}
                            answer={answers.find(
                                (answer) =>
                                    answer?.object === bubble?.id ||
                                    answer?.object === bubble?.groupId
                            )}
                        />
                    ))}
            </>
        )
    }

export default withUserInput
