import { AvatarSize, EnrolledProgramParticipantPerson } from "../../types"
import { StyledPosition, StyledUserInfoPopup, StyledUserRow } from "./styles"
import { StyledColumn, StyledH5, StyledMini, StyledRow } from "../../styles/styledcomponents"
import ProfileCard from "../ProfileCard"
import { useState } from "react"
import useLayout from "../../hooks/layout.hook"
import { UserAvatar } from "../../creator/components/UserAvatar"
import EllipsisText from "../EllipsisText"
import { isNil, pick } from "lodash"

export interface UserRowProps {
    person: EnrolledProgramParticipantPerson
    index: number
    avatarSize?: AvatarSize
    isCurrentUser?: boolean
    score?: number
}

const enum PopupType {
    userInfo,
    programProgress,
}

const UserRow = ({ person, index, avatarSize, isCurrentUser, score }: UserRowProps) => {
    const { isTouchable } = useLayout()
    const [userInfoOpen, setUserInfoOpen] = useState<boolean>()

    const handlePopupOpen = (popupType: PopupType) => {
        setUserInfoOpen(popupType === PopupType.userInfo)
    }

    const handlePopupClose = (popupType: PopupType) => {
        if (popupType === PopupType.userInfo) setUserInfoOpen(false)
    }

    // This function prevent to open the profile card when progress popup is opened :: MOBILE
    const handleMouseLeaveUserCard = () => {
        setUserInfoOpen(false)
    }

    // We use hover behavior in desktop to open the popup and click for Mobile
    return (
        <StyledUserInfoPopup
            open={userInfoOpen}
            onOpen={() => handlePopupOpen(PopupType.userInfo)}
            onClose={() => handlePopupClose(PopupType.userInfo)}
            position={isTouchable ? ["bottom center", "top center"] : "left center"}
            on={isTouchable ? "click" : "hover"}
            arrow={false}
            offsetX={isTouchable ? 0 : 30}
            keepTooltipInside={!isTouchable && ".app"}
            nested
            mouseLeaveDelay={0}
            mouseEnterDelay={300}
            trigger={
                <StyledUserRow onMouseOut={handleMouseLeaveUserCard} isCurrentUser={isCurrentUser}>
                    <StyledPosition position={index + 1}>
                        <StyledMini>{index + 1}</StyledMini>
                    </StyledPosition>
                    <UserAvatar
                        user={{ ...pick(person, ["profileImageURL", "firstName", "lastName"]) }}
                        size={avatarSize}
                    />
                    <StyledRow
                        css={{ flex: 1, alignItems: "center", justifyContent: "space-between" }}
                    >
                        <StyledColumn>
                            <StyledH5 useNewDesign>
                                {person.firstName} {person.lastName}
                            </StyledH5>
                            <EllipsisText
                                variant={"body2"}
                                text={person.role}
                                // TODO: When we don't display scoring, the width should be 215
                                // maxWidth={215}
                                maxWidth={166}
                                disableTooltip
                            />
                        </StyledColumn>
                        {!isNil(score) && <StyledH5 useNewDesign>{Math.round(score)}</StyledH5>}
                    </StyledRow>
                </StyledUserRow>
            }
        >
            <ProfileCard person={person} />
        </StyledUserInfoPopup>
    )
}

export default UserRow
