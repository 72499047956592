import { useCallback, useMemo, useState } from "react"
import { StyledImage } from "../../../styles/styledcomponents"
import { StyledAddChunkButton, StyledAddChunkMenu } from "./styles"
import { Block, BlockType, NotificationNature } from "../../../types"
import {
    generateUUID,
    getEmptyBranch,
    getGroupBlock,
    getImageGroupBlock,
} from "../../../utils/utils"
import {
    MenuItem,
    questionBlockOptions,
    contentBlockOptions,
    advanceOptions,
    aiAssistOptions,
    AiAssistMenuKey,
    ContentBlockMenuKey,
    AdvanceMenuKey,
    AiAssistMenuBlockKey,
    AiAssistMenuQuestionKey,
    QuestionBlockMenuKey,
} from "./constants"
import { PopupProps } from "reactjs-popup/dist/types"
import {
    PopupOptionType,
    SectionOption as PopupMenuSectionOption,
    TextAndIconOption as PopupMenuTextAndIconOption,
    WithChildrenOption,
} from "../../../components/PopupMenu"
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
import RuleIcon from "@mui/icons-material/Rule"
import { StyledAiIconWrapper } from "../ComponentSelector/styles"
import { SparkleIcon } from "../../../assets/icons/SparklesIcon"
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined"
import { useTheme } from "styled-components"
import { useDispatch } from "react-redux"
import { setAiCreateBlockData } from "../../../redux/ai"
import { compact, first, isEmpty, uniq } from "lodash"
import ContentPasteIcon from "@mui/icons-material/ContentPaste"
import { parsePastedBlocks } from "../../../thread/utils"

interface AddChunkProps extends Pick<PopupProps, "trigger" | "onOpen" | "onClose" | "position"> {
    handleAddChunk: (chunk: Block) => void
    chunkParams?: {
        index?: number
        sectionId?: string
        questionOptionId?: string
        freeTextResponseId?: string
        branchId?: string
        blockGuid?: string
    }
    hideAiAssistOptions?: boolean
    allowedChunks?: (
        | ContentBlockMenuKey
        | AdvanceMenuKey
        | AiAssistMenuKey
        | QuestionBlockMenuKey
    )[]
}

const AddChunkMenu = ({
    trigger,
    onOpen,
    onClose,
    position = ["right top", "right bottom"],
    handleAddChunk,
    chunkParams,
    hideAiAssistOptions,
    allowedChunks,
}: AddChunkProps) => {
    const theme = useTheme()
    const [menuOpen, setMenuOpen] = useState(false)
    const dispatch = useDispatch()

    const filteredAiOptions = useMemo(
        () =>
            aiAssistOptions.filter(
                (option) => !allowedChunks || allowedChunks.includes(option.key)
            ),
        [allowedChunks]
    )

    const filteredQuestionBlockOptions = useMemo(
        () =>
            questionBlockOptions.filter(
                (option) => !allowedChunks || allowedChunks.includes(option.key)
            ),
        [allowedChunks]
    )

    const filteredContentBlockOptions = useMemo(
        () =>
            contentBlockOptions.filter(
                (option) => !allowedChunks || allowedChunks.includes(option.key)
            ),
        [allowedChunks]
    )

    const filteredAdvanceOptions = useMemo(
        () =>
            advanceOptions.filter((option) => !allowedChunks || allowedChunks.includes(option.key)),
        [allowedChunks]
    )

    const handleAddAiBlock = useCallback(
        (type: AiAssistMenuKey) => {
            const navigateToItem = (blockId: string) => {
                const node = document.getElementById(`block-${blockId}`)
                node && node.scrollIntoView({ block: "start", behavior: "smooth" })
            }

            const title = aiAssistOptions.find((option) => option.key === type)?.label
            dispatch(setAiCreateBlockData({ ...chunkParams, title, type }))
            chunkParams?.blockGuid && navigateToItem(chunkParams?.blockGuid)
        },
        [chunkParams, dispatch]
    )

    const handleOptionClick = useCallback(
        (option: ContentBlockMenuKey | AdvanceMenuKey | AiAssistMenuKey | QuestionBlockMenuKey) => {
            let newBlock: Block = {
                id: generateUUID(),
                type: BlockType.GROUP,
            }
            switch (option) {
                case ContentBlockMenuKey.TEXT:
                    newBlock.branches = [getGroupBlock()]
                    handleAddChunk(newBlock)
                    break
                case ContentBlockMenuKey.IMAGE:
                    newBlock.branches = [getImageGroupBlock()]
                    handleAddChunk(newBlock)
                    break
                case ContentBlockMenuKey.CUSTOM:
                    newBlock.branches = [getGroupBlock([])]
                    handleAddChunk(newBlock)
                    break
                case QuestionBlockMenuKey.CHOICE:
                    newBlock.type = BlockType.CHOICE_QUESTION
                    newBlock.properties = {
                        display_poll: true,
                        no_correct_answers: false,
                        may_select_multiple: false,
                        hide_answer_correctness: false,
                    }
                    newBlock.question = ""
                    newBlock.options = [
                        {
                            id: generateUUID(),
                            text: "",
                            objects: [
                                {
                                    id: generateUUID(),
                                    type: BlockType.GROUP,
                                    branches: [getGroupBlock()],
                                },
                            ],
                        },
                    ]
                    newBlock.save_to_variable = ""
                    handleAddChunk(newBlock)
                    break
                case QuestionBlockMenuKey.FREE_TEXT:
                    newBlock.type = BlockType.FREE_TEXT_QUESTION
                    newBlock.properties = {
                        no_correct_answers: false,
                        hide_answer_correctness: false,
                    }
                    newBlock.question = ""
                    newBlock.responses = [
                        {
                            id: generateUUID(),
                            input: "",
                            objects: [
                                {
                                    id: generateUUID(),
                                    type: BlockType.GROUP,
                                    branches: [getGroupBlock()],
                                },
                            ],
                        },
                    ]
                    newBlock.save_to_variable = ""
                    handleAddChunk(newBlock)
                    break
                case AdvanceMenuKey.SET_VARIABLE:
                    newBlock.type = BlockType.SET_STRING_VARIABLE
                    newBlock.value = ""
                    handleAddChunk(newBlock)
                    break
                case AdvanceMenuKey.COMMENT:
                    newBlock.type = BlockType.COMMENT
                    newBlock.value = ""
                    handleAddChunk(newBlock)
                    break
                case AdvanceMenuKey.MESSAGE:
                    newBlock.type = BlockType.NOTIFICATION
                    newBlock.nature = NotificationNature.SOCIAL
                    newBlock.value = ""
                    handleAddChunk(newBlock)
                    break
                case AdvanceMenuKey.BRANCH:
                    newBlock.type = BlockType.CONDITIONAL
                    newBlock.branches = [getEmptyBranch()]
                    handleAddChunk(newBlock)
                    break
                case AdvanceMenuKey.INCLUDE:
                    newBlock.type = BlockType.INCLUDE
                    newBlock.objects = []
                    handleAddChunk(newBlock)
                    break
                case AiAssistMenuBlockKey.Writing:
                    handleAddAiBlock(AiAssistMenuBlockKey.Writing)
                    break
                case AiAssistMenuBlockKey.Question:
                    handleAddAiBlock(AiAssistMenuBlockKey.Question)
                    break
            }
            setMenuOpen(false)
        },
        [handleAddAiBlock, handleAddChunk]
    )

    const handlePaste = () => {
        navigator.clipboard.readText().then((clipboardText) => {
            const parsedBlocks: Block[] | undefined = parsePastedBlocks(clipboardText)
            // we check which blocks can be pasted given the add chunk menu options
            // only those blocks that can be added by the menu option can also be pasted.
            const allowedBlockTypes = uniq(
                compact(
                    [
                        ...filteredQuestionBlockOptions,
                        ...filteredContentBlockOptions,
                        ...filteredAdvanceOptions,
                    ].map((option) => option.mainBlockType)
                )
            )
            const validBlocks: Block[] | undefined = parsedBlocks?.filter(({ type }) =>
                allowedBlockTypes.includes(type)
            )

            // note: we only support a single block copy/paste
            const firstValidBlock = first(validBlocks)
            if (firstValidBlock) handleAddChunk(firstValidBlock)
        })
    }

    const pasteOption: PopupMenuTextAndIconOption = {
        label: "Paste",
        type: PopupOptionType.TEXT_AND_ICON,
        icon: ContentPasteIcon,
        onClick: handlePaste,
    }

    const aiAssistSection: PopupMenuSectionOption | null = useMemo(() => {
        if (hideAiAssistOptions || isEmpty(filteredAiOptions)) return null
        return {
            label: "AI Assist",
            image: () => (
                <SparkleIcon color={theme.colors.base.uiLabelSubtitle} width={16} height={16} />
            ),
            type: PopupOptionType.SECTION,
            options: filteredAiOptions.map((item) => {
                let baseAttributes = {
                    label: item.label,
                    noDivider: true,
                }
                if (item.options) {
                    return {
                        ...baseAttributes,
                        type: PopupOptionType.WITH_CHILDREN,
                        options: item.options?.map((itemChild) => ({
                            label: itemChild.label,
                            image: () => (
                                <StyledAiIconWrapper>
                                    <SparkleIcon />
                                </StyledAiIconWrapper>
                            ),
                            onClick: () => handleOptionClick(itemChild.key),
                            type: PopupOptionType.TEXT_AND_IMAGE,
                            noDivider: true,
                        })),
                    }
                } else {
                    return {
                        ...baseAttributes,
                        image: () => (
                            <StyledAiIconWrapper>
                                <SparkleIcon />
                            </StyledAiIconWrapper>
                        ),
                        onClick: () => handleOptionClick(item.key),
                        type: PopupOptionType.TEXT_AND_IMAGE,
                    }
                }
            }),
        }
    }, [
        hideAiAssistOptions,
        filteredAiOptions,
        theme.colors.base.uiLabelSubtitle,
        handleOptionClick,
    ])

    const questionSection: PopupMenuSectionOption | null = useMemo(() => {
        if (isEmpty(filteredQuestionBlockOptions)) return null
        return {
            label: "Questions",
            icon: RuleIcon,
            iconProps: {
                sx: {
                    fontSize: "16px",
                    fill: theme.colors.base.uiLabelSubtitle,
                },
            },
            type: PopupOptionType.SECTION,
            options: filteredQuestionBlockOptions.map((item: MenuItem) => ({
                label: item.label,
                noDivider: !item.showDivider,
                image: () => (
                    <StyledImage src={item.image} css={{ width: "42px", marginRight: "6px" }} />
                ),
                onClick: () => handleOptionClick(item.key),
                type: PopupOptionType.TEXT_AND_IMAGE,
            })),
        }
    }, [filteredQuestionBlockOptions, handleOptionClick, theme.colors.base.uiLabelSubtitle])

    const contentSection: PopupMenuSectionOption | null = useMemo(() => {
        if (isEmpty(filteredContentBlockOptions)) return null
        return {
            label: "Content",
            icon: ArticleOutlinedIcon,
            iconProps: {
                sx: {
                    fontSize: "16px",
                    fill: theme.colors.base.uiLabelSubtitle,
                },
            },
            type: PopupOptionType.SECTION,
            options: filteredContentBlockOptions.map((item: MenuItem) => ({
                label: item.label,
                noDivider: !item.showDivider,
                image: () => (
                    <StyledImage src={item.image} css={{ width: "42px", marginRight: "6px" }} />
                ),
                onClick: () => handleOptionClick(item.key),
                type: PopupOptionType.TEXT_AND_IMAGE,
            })),
        }
    }, [filteredContentBlockOptions, handleOptionClick, theme.colors.base.uiLabelSubtitle])

    const advanceOption: WithChildrenOption | null = useMemo(() => {
        if (isEmpty(filteredAdvanceOptions)) return null
        return {
            label: "Advanced",
            icon: BuildOutlinedIcon,
            iconProps: {
                sx: {
                    fontSize: "16px",
                    fill: theme.colors.base.uiLabelSubtitle,
                },
            },
            styles: { padding: "6px 0" },
            type: PopupOptionType.WITH_CHILDREN,
            options: filteredAdvanceOptions.map((item: MenuItem) => ({
                label: item.label,
                noDivider: !item.showDivider,
                image: () => (
                    <StyledImage src={item.image} css={{ width: "42px", marginRight: "6px" }} />
                ),
                onClick: () => handleOptionClick(item.key),
                type: PopupOptionType.TEXT_AND_IMAGE,
            })),
        }
    }, [filteredAdvanceOptions, handleOptionClick, theme.colors.base.uiLabelSubtitle])

    return (
        <StyledAddChunkMenu
            open={menuOpen}
            onOpen={() => {
                setMenuOpen(true)
                onOpen && onOpen()
            }}
            onClose={() => {
                setMenuOpen(false)
                onClose && onClose()
            }}
            on={"click"}
            position={position}
            arrow={false}
            overlayStyle={{ backgroundColor: "transparent" }}
            offsetX={-7}
            options={compact([
                pasteOption,
                aiAssistSection,
                questionSection,
                contentSection,
                advanceOption,
            ])}
            trigger={trigger ?? <StyledAddChunkButton open={menuOpen} />}
            nested
        />
    )
}

export default AddChunkMenu
