import { StyledCatalogContainer, StyledCatalogContent } from "./styles"
import { StyledColumn, StyledH1, StyledH3, StyledRow } from "../styles/styledcomponents"
import { EnrolledProgramsThreadsQuery, ThreadStatus } from "../apollo/generated/graphql"
import { useEffect, useMemo } from "react"
import { isEmpty } from "lodash"
import TopBar from "../components/TopBar"
import { ProgramSelect } from "../leaderboard/MobileLeaderboardScreen"
import {
    useEnrolledPrograms,
    useSelectedEnrolledProgram,
    useSelectedEnrolledProgramAttribute,
    useSelectedProgramGuid,
} from "../hooks/enrolledProgram.hook"
import { useEnrolledProgramThreadsByGuid } from "../hooks/enrolledProgramThread.hook"
import LibraryRow from "../components/LibraryBar/LibraryRow"
import { mapEnrolledProgramThread } from "../components/LibraryBar/PlaylistBar"
import { useTheme } from "styled-components"
import { useHistory } from "react-router"

const CatalogScreen = () => {
    const theme = useTheme()
    const history = useHistory()

    const { enrolledPrograms } = useEnrolledPrograms()

    const programGuid = useSelectedProgramGuid()

    const { selectedEnrolledProgram: selectedProgram } = useSelectedEnrolledProgram()

    const { enrolledProgramThreads } = useEnrolledProgramThreadsByGuid(programGuid)

    // Set the selected program for the first time
    useEffect(() => {
        if (!programGuid && enrolledPrograms && !isEmpty(enrolledPrograms))
            history.push(`/program/${enrolledPrograms[0].guid}`)
    }, [enrolledPrograms, history, programGuid, selectedProgram])

    const inProgressThreads = useMemo(
        () => enrolledProgramThreads?.filter((thread) => thread.status === ThreadStatus.InProgress),
        [enrolledProgramThreads]
    )

    const discoverThreads = useMemo(
        () =>
            enrolledProgramThreads?.filter((thread) =>
                [ThreadStatus.Unlocked, ThreadStatus.Locked, ThreadStatus.Expired].includes(
                    thread.status
                )
            ),
        [enrolledProgramThreads]
    )

    const completedThreads = useMemo(
        () => enrolledProgramThreads?.filter((thread) => thread.status === ThreadStatus.Completed),
        [enrolledProgramThreads]
    )

    return (
        <StyledCatalogContainer>
            <TopBar>
                <StyledRow css={{ flex: 1, alignItems: "center", justifyContent: "space-between" }}>
                    <StyledH1 useNewDesign css={{ color: theme.colors.base.uiLabelTitle }}>
                        Catalog
                    </StyledH1>
                    <ProgramSelect
                        value={selectedProgram}
                        programs={enrolledPrograms}
                        menuAlignment={"right"}
                        onChange={(program) => history.push(`/program/${program.guid}`)}
                    />
                </StyledRow>
            </TopBar>
            <StyledCatalogContent>
                <ThreadGroup title={"In Progress"} threads={inProgressThreads} />
                <ThreadGroup title={"Discover"} threads={discoverThreads} />
                <ThreadGroup title={"Completed"} threads={completedThreads} />
            </StyledCatalogContent>
        </StyledCatalogContainer>
    )
}

interface ThreadGroupProps {
    title: string
    threads?: NonNullable<EnrolledProgramsThreadsQuery["enrolledProgramThreads"]>[0][]
}

const ThreadGroup = ({ title, threads }: ThreadGroupProps) => {
    const theme = useTheme()

    const programGuid = useSelectedProgramGuid()
    const { attribute: programId } = useSelectedEnrolledProgramAttribute("id")

    if (!threads || isEmpty(threads)) return null

    const mappedThreads = threads.map((thread) =>
        mapEnrolledProgramThread(thread, programGuid, programId)
    )

    return (
        <StyledColumn css={{ gap: "12px" }}>
            <StyledH3 useNewDesign css={{ color: theme.colors.base.uiLabelSubtitle }}>
                {title}
            </StyledH3>
            <StyledRow css={{ gap: "12px", flexWrap: "wrap" }}>
                {mappedThreads.map((thread, index) => (
                    <LibraryRow key={`library-item-${thread.type}-${index}}`} {...thread} />
                ))}
            </StyledRow>
        </StyledColumn>
    )
}

export default CatalogScreen
