import styled, { css } from "styled-components"
import { StyledColumn, StyledH3Styles, StyledH4, StyledRow } from "../../styles/styledcomponents"
import { StyledThreadTopBar } from "../../thread/components/ThreadTopBar/styles"
import { ArrowBackIcon } from "../../assets/icons/ArrowBack"
import { CloseIcon } from "../../assets/icons/CloseIcon"
import LeaderboardIcon from "../../assets/icons/LeaderboardIcon"
import { StyledSocialBar, StyledSocialTopBar } from "../../components/SocialBar/styles"

const THREAD_CONTENT_GAP = 12
const THREAD_CONTENT_VERTICAL_PADDING = 30
const THREAD_CONTENT_HORIZONTAL_PADDING = 42
const ICONS_ROW_HEIGHT = 32

const CARDS_HEIGHT =
    window.innerHeight -
    (THREAD_CONTENT_GAP + THREAD_CONTENT_VERTICAL_PADDING * 2 + ICONS_ROW_HEIGHT)

export const StyledThreadContent = styled(StyledColumn)`
    align-self: center;
    padding: ${THREAD_CONTENT_VERTICAL_PADDING}px ${THREAD_CONTENT_HORIZONTAL_PADDING}px;
    width: fit-content;
    gap: ${THREAD_CONTENT_GAP}px;
`

export const StyledIconsRow = styled(StyledRow)`
    justify-content: space-between;
    height: ${ICONS_ROW_HEIGHT}px;
`

export const StyledThreadContainer = styled(StyledColumn)`
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border-radius: 8px;
    width: 679px;
    height: ${CARDS_HEIGHT}px;
    overflow: auto;
    position: relative;

    ${StyledThreadTopBar} {
        width: inherit;
        position: absolute;
        border-radius: 8px 8px 0 0;
    }
`

const IconStyle = css`
    color: ${({ theme }) => theme.colors.base.uiBgIcon};
    cursor: pointer;
`

export const StyledBackIcon = styled(ArrowBackIcon)`
    ${IconStyle}
`

export const StyledCloseIcon = styled(CloseIcon)`
    ${IconStyle}
`

export const StyledLeaderboardIcon = styled(LeaderboardIcon)`
    ${IconStyle}
`

export const StyledLeaderboardContainer = styled(StyledColumn)`
    height: ${CARDS_HEIGHT}px;
    margin-left: 12px;

    ${StyledSocialBar} {
        border: none;
        background-color: ${({ theme }) => theme.colors.base.uiBgBase};
        border-radius: 8px;

        ${StyledSocialTopBar} {
            border: none;
            border-radius: 8px;

            /*
             * We do this, making the H4 look like an H3 because the SocialBar is used
             * in other places and so this way we avoid having to modify it directly.
             */
            ${StyledH4} {
                ${StyledH3Styles}
            }
        }
    }
`
