import withUserInput, { UserInputProps } from "../../../hocs/userInput.hoc"
import { Block } from "../../../types"
import { ChoicePollPreview } from "../ChoicePollPreview"
import ChoicePreview, { ChoicePreviewProps } from "../ChoicePreview"
import ImagePreview from "../ImagePreview"
import TextPreview from "../TextPreview"

interface ChoiceQuestionPreviewProps extends UserInputProps {
    block: Block
    isMobile?: boolean
    choiceProps: ChoicePreviewProps
}

const ChoiceQuestionPreview = ({
    block,
    isMobile,
    choiceProps,
    handleAnswer,
    answer,
    score,
    choiceData,
}: ChoiceQuestionPreviewProps) => {
    return (
        <>
            {block.image && (
                <ImagePreview
                    key={block.image.id}
                    block={block.image}
                    isMobile={isMobile}
                    scrollToBottom={choiceProps.scrollToBottom}
                />
            )}
            <TextPreview block={block} customValueSelector={({ question }) => question} />
            {block?.properties?.display_poll ? (
                <ChoicePollPreview
                    {...choiceProps}
                    handleAnswer={handleAnswer}
                    customChoicesSelector={({ options }) => options}
                    choiceData={choiceData}
                    score={score}
                    answer={answer}
                    avoidTimer
                />
            ) : (
                <ChoicePreview
                    {...choiceProps}
                    handleAnswer={handleAnswer}
                    customChoicesSelector={({ options }) => options}
                    choiceData={choiceData}
                    score={score}
                    answer={answer}
                    avoidTimer
                />
            )}
        </>
    )
}

export default withUserInput(ChoiceQuestionPreview)
