import { StyledBox, StyledH4, StyledP, StyledSpan } from "../../../styles/styledcomponents"
import {
    StyledAnswerCard,
    StyledMoreAnswerCard,
    StyledNoAnswersCard,
    StyledUserRow,
    StyledMoreItemsClarificationText,
} from "./styles"
import { UserAvatar } from "../../../creator/components/UserAvatar"
import { AvatarSize, Block } from "../../../types"
import { FinishFlagIcon } from "../../../assets/icons/FinishFlagIcon"
import { useQuery } from "@apollo/client"
import { GET_LATEST_FREE_TEXT_RESPONSES } from "../../../apollo/queries"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { useEffect, useState } from "react"
import { GetLatestFreeTextResponsesQuery } from "../../../apollo/generated/graphql"
import Carousel from "../../Carousel"
import { isEmpty, pick } from "lodash"
import MoreResponsesModal from "./MoreResponsesModal"
import { SkeletonHeadlands } from "../../../common/components/LoaderSkeleton/styles"

interface QuestionsAndAnswersPreviewProps {
    block: Block
    programGuid?: string
    threadGuid?: string
}

enum CarrouselItemType {
    response,
    no_responses,
    more_responses,
}

type CarrouselItemWithData = NonNullable<
    GetLatestFreeTextResponsesQuery["latestFreetextResponses"]
>[0] & {
    type: CarrouselItemType.response
}

type CarrouselItemWithoutData = {
    type: CarrouselItemType.no_responses | CarrouselItemType.more_responses
}

type CarrouselItem = CarrouselItemWithData | CarrouselItemWithoutData

const MAX_RESPONSES_QUANTITY = 5

const QuestionsAndAnswersPreview = ({
    block,
    programGuid,
    threadGuid,
}: QuestionsAndAnswersPreviewProps) => {
    const [showMoreModalOpen, setShowMoreModalOpen] = useState<boolean>(false)
    const [carouselItems, setCarouselItems] = useState<CarrouselItem[]>([])
    const { data: freeTextResponsesData, loading: freeTextResponsesLoading } = useQuery(
        GET_LATEST_FREE_TEXT_RESPONSES,
        {
            skip: !block.variable_block_id || !programGuid || !threadGuid,
            variables: {
                programGUID: programGuid!,
                threadGUID: threadGuid!,
                objectID: block.variable_block_id!,
            },
        }
    )

    useEffect(() => {
        if (isEmpty(freeTextResponsesData?.latestFreetextResponses) && !freeTextResponsesLoading) {
            setCarouselItems([{ type: CarrouselItemType.no_responses }])
        } else if (
            freeTextResponsesData?.latestFreetextResponses &&
            freeTextResponsesData.latestFreetextResponses.length > MAX_RESPONSES_QUANTITY
        ) {
            setCarouselItems([
                ...freeTextResponsesData.latestFreetextResponses
                    .slice(0, MAX_RESPONSES_QUANTITY)
                    .map((response) => ({
                        type: CarrouselItemType.response,
                        ...response,
                    })),
                {
                    type: CarrouselItemType.more_responses,
                },
            ])
        } else if (freeTextResponsesData?.latestFreetextResponses) {
            setCarouselItems([
                ...freeTextResponsesData.latestFreetextResponses.map((response) => ({
                    type: CarrouselItemType.response,
                    ...response,
                })),
                {
                    type: CarrouselItemType.more_responses,
                },
            ])
        }
    }, [freeTextResponsesData])

    const renderItem = (item: CarrouselItem, carrouselItemId: string) => {
        switch (item.type) {
            case CarrouselItemType.response: {
                return (
                    <StyledAnswerCard id={carrouselItemId} key={carrouselItemId}>
                        <StyledUserRow>
                            <UserAvatar
                                size={AvatarSize.XS}
                                user={{
                                    ...pick(item.author, [
                                        "profileImageURL",
                                        "firstName",
                                        "lastName",
                                    ]),
                                }}
                            />
                            <StyledSpan css={{ marginLeft: 6, fontWeight: 700 }}>
                                {item.author.firstName}
                            </StyledSpan>
                        </StyledUserRow>
                        <StyledP>{item.response}</StyledP>
                    </StyledAnswerCard>
                )
            }
            case CarrouselItemType.no_responses: {
                return (
                    <StyledNoAnswersCard id={carrouselItemId} key={carrouselItemId}>
                        <FinishFlagIcon />
                        <StyledH4>Looks like you are first!</StyledH4>
                        <StyledP>Come back later to see what other people shared</StyledP>
                    </StyledNoAnswersCard>
                )
            }
            case CarrouselItemType.more_responses: {
                return (
                    <StyledMoreAnswerCard
                        id={carrouselItemId}
                        key={carrouselItemId}
                        onClick={() => setShowMoreModalOpen(true)}
                    >
                        <MoreHorizIcon fontSize={"large"} />
                        <StyledH4>View more responses</StyledH4>
                    </StyledMoreAnswerCard>
                )
            }
        }
    }

    return (
        <StyledBox css={{ margin: "3px 12px" }}>
            {freeTextResponsesLoading ? (
                <StyledBox css={{ display: "flex", gap: "6px" }}>
                    <SkeletonHeadlands
                        width={"200px"}
                        height={"100px"}
                        css={{ borderRadius: "16px" }}
                    />
                    <SkeletonHeadlands
                        width={"200px"}
                        height={"100px"}
                        css={{ borderRadius: "16px" }}
                    />
                </StyledBox>
            ) : (
                <Carousel
                    id={block.id}
                    items={carouselItems}
                    renderItem={renderItem}
                    bottomElement={
                        <StyledMoreItemsClarificationText
                            onClick={() => setShowMoreModalOpen(true)}
                        >
                            View more responses
                        </StyledMoreItemsClarificationText>
                    }
                />
            )}

            {showMoreModalOpen && freeTextResponsesData?.latestFreetextResponses && (
                <MoreResponsesModal
                    onClose={() => setShowMoreModalOpen(false)}
                    responses={freeTextResponsesData?.latestFreetextResponses}
                    question={block?.question_text || ""}
                />
            )}
        </StyledBox>
    )
}

export default QuestionsAndAnswersPreview
