import Modal from "../Modal"
import { StyledH3, StyledP, StyledRow } from "../../styles/styledcomponents"
import { Button } from "../Button/Button"
import withBlockNavigationPrompt from "../../hocs/blockNavigationPrompt.hoc"
import { useDispatch, useSelector } from "react-redux"
import useSaveThread from "../../hooks/saveThread.hook"
import { RootState } from "../../redux/store"
import { updateAutoSaveTimeout } from "../../redux/blocks"
import { useToast } from "../../hooks/useToast.hook"
import { ErrorToastIcon } from "../../assets/icons/ErrorToastIcon"

interface UnsavedChangesModalBaseProps {
    onCancel: () => void
    onConfirm: () => void
    confirmLoading?: boolean
}
const UnsavedChangesModalBase = withBlockNavigationPrompt(
    ({ onCancel, onConfirm, confirmLoading }: UnsavedChangesModalBaseProps) => {
        return (
            <Modal open closeOnDocumentClick={false} closeOnEscape={false}>
                <StyledH3 css={{ fontSize: "20px", fontWeight: 500, lineHeight: "24px" }}>
                    You have unsaved changes
                </StyledH3>
                <StyledP css={{ lineHeight: "20px" }}>Would you like to save them first?</StyledP>
                <StyledRow css={{ marginTop: "18px", gap: "12px" }}>
                    <Button
                        variant={"outline"}
                        label={"Discard changes"}
                        onClick={onCancel}
                        disabled={confirmLoading}
                        buttonStyle={{ width: "fit-content" }}
                    />
                    <Button
                        label={"Save changes"}
                        onClick={onConfirm}
                        loading={confirmLoading}
                        buttonStyle={{ width: "fit-content" }}
                    />
                </StyledRow>
            </Modal>
        )
    }
)

const UnsavedChangesModal = () => {
    const dispatch = useDispatch()

    const { showErrorToast } = useToast()

    const autoSaveEditing = useSelector(
        (state: RootState) => state.blocksReducer.autoSaveTimeout.editing
    )

    const [saveCurrentThread, { loading: editLoading }] = useSaveThread()

    return (
        <UnsavedChangesModalBase
            shouldBlockNavigation={!!autoSaveEditing}
            onConfirm={() => {
                return new Promise((resolve, reject) => {
                    saveCurrentThread({
                        onCompleted: () => resolve(true),
                        onError: () => reject(),
                    })
                })
            }}
            confirmLoading={editLoading}
            onPromptShowCallback={() => {
                // cancel auto-save
                dispatch(updateAutoSaveTimeout({ editing: undefined, timeoutID: undefined }))
            }}
            onErrorCallback={() => {
                // enable thread save
                dispatch(updateAutoSaveTimeout({ editing: true, timeoutID: undefined }))
                showErrorToast({
                    icon: <ErrorToastIcon />,
                    message: "An error occurred while saving",
                })
            }}
        />
    )
}

export default UnsavedChangesModal
