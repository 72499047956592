import styled, { css } from "styled-components"
import {
    StyledBox,
    StyledH4,
    StyledHr,
    StyledP,
    StyledSpan,
    VerticalScrollableContentWithHiddenScroll,
} from "../../../../styles/styledcomponents"
import Popup from "reactjs-popup"
import { StyledModal } from "../../../Modal/styles"

export const StyledCloseButtonRow = styled(StyledBox)`
    display: flex;
    width: 100%;
    justify-content: flex-end;

    svg {
        fill: ${({ theme }) => theme.headlandsGray10};
        font-size: 12px;

        &:hover {
            cursor: pointer;
        }
    }
`
export const StyledMoreResponsesDivider = styled(StyledBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 6px;
    height: 10px;

    ${StyledHr} {
        display: flex;
        flex: 1;
        color: ${({ theme }) => theme.colors.thread.student.bubble};
    }

    ${StyledSpan} {
        font-size: 10px;
        line-height: 10px;
        color: ${({ theme }) => theme.colors.thread.student.bubble};
    }
`

export const StyledMoreResponsesModal = styled(StyledModal)<{ mobile?: boolean }>`
    &-content {
        padding: 25px 25px 12px 25px !important;
        min-width: 400px !important;
        max-width: 400px !important;
        max-height: 728px;
        cursor: default;
        border-radius: ${({ theme }) => theme.borderRadius2} !important;
        box-sizing: border-box;
    }

    ${({ mobile }) =>
        mobile &&
        css`
            &-content {
                min-width: 100% !important;
                max-width: 100% !important;
                min-height: 100% !important;
                margin: 0 !important;
                justify-content: flex-start !important;
                z-index: 1001 !important; // to be greater than sidebar toggle button
                border-radius: 0 !important;
            }

            &-overlay {
                z-index: 1001 !important; // to be greater than sidebar toggle button
            }
        `}
`

export const StyledMoreResponsesModalContentContainer = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    min-height: 0; // so we can have a dynamic scrollable container
`

export const StyledScrollableContainer = styled(StyledBox)`
    flex-grow: 1;
    box-sizing: border-box;
    ${VerticalScrollableContentWithHiddenScroll};
`

export const StyledUserInfoPopup = styled(Popup)<{ mobile?: boolean }>`
    &-overlay {
        // note: this !important is necessary because on Modal.scss we overwrite the background-color
        background-color: transparent !important;
    }

    ${({ mobile }) =>
        mobile &&
        css`
            &-content {
                z-index: 1001 !important; // to be greater than sidebar toggle button
            }

            &-overlay {
                z-index: 1001 !important; // to be greater than sidebar toggle button
            }
        `}
`

export const StyledUserResponseItem = styled(StyledBox)`
    display: flex;
    padding: 12px 16px 12px 0;
    gap: 6px;

    ${StyledH4} {
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.headlandsGrayDark};
    }

    ${StyledP} {
        text-align: start;
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.headlandsGrayDark};
    }
`
