import styled, { css } from "styled-components"
import { StyledBody2, StyledSpan } from "../../styles/styledcomponents"
import { lighten } from "polished"

interface StyledButtonProps {
    isLoading?: boolean
    thread?: boolean
    disabledStyle?: boolean
    $colors: { background: string; color: string; border: string }
}

const StyledButton = styled.button<StyledButtonProps>`
    background-color: ${({ $colors }) => $colors.background};
    border: 1px solid ${({ $colors }) => $colors.border};
    cursor: pointer;
    border-radius: 8px;
    letter-spacing: 0.03em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${({ $colors }) => $colors.color};
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    padding: 6px 12px;
    text-align: center;
    outline: none;

    ${({ isLoading }) =>
        isLoading &&
        css`
            padding: 5px 20px;
        `}
    ${({ thread }) =>
        thread &&
        css`
            border-radius: 40px;
            border: 1px solid ${({ theme }) => theme.headlandsPrimaryColor};
            background-color: white;
            color: ${({ theme }) => theme.headlandsPrimaryColor};
            font-weight: 600;
        `}
  ${({ disabledStyle }) =>
        disabledStyle &&
        css<StyledButtonProps>`
            cursor: not-allowed;
            /* 
            * Here we manually set the colors to be lighten, we do this instead of an opacity because we don't
            * want transparency (in case there is something behind)
             */
            background-color: ${({ $colors }) => lighten(0.2, $colors.background)};
            border-color: ${({ $colors }) => lighten(0.5, $colors.border)};
            color: ${({ $colors }) => lighten(0.5, $colors.color)};
        `}
    // Used when a JSX.Element is pass as label
  ${StyledSpan}, ${StyledBody2} {
        font-weight: 500;
        color: ${({ $colors }) => $colors.color};
    }
`

export default StyledButton
