import { Block, BlocksType, NotificationNature } from "../../../../types"
import { DesignerFundLogoIcon } from "../../../../assets/icons/DesignerFundLogoIcon"
import { updateBlock } from "../../../../redux/blocks"
import RichTextEditor from "../../../../components/Input/RichTextEditor"
import { useDispatch } from "react-redux"
import {
    StyledMainSeparator,
    StyledMessageComponent,
    StyledMessageRow,
    StyledSecondarySeparator,
} from "./styles"
import Checkbox from "../../../../components/Checkbox"
import { StyledBox } from "../../../../styles/styledcomponents"

export interface MessageProps {
    block: Block
}

const Message = ({ block }: MessageProps) => {
    const dispatch = useDispatch()
    const groupReplies = !!block.group_replies
    const defaultAvatar = !!block.default_avatar
    const nature = block.nature

    const handleNatureToggle = () => {
        const newNature =
            nature === NotificationNature.FEEDBACK
                ? NotificationNature.SOCIAL
                : NotificationNature.FEEDBACK

        updateBlockProperty("nature", newNature)
    }

    const handleGroupRepliesToggle = () => {
        updateBlockProperty("group_replies", !groupReplies)
        // todo ask if we should reset the anchor properties when set to false or not
    }

    const handleDefaultAvatarToggle = () => {
        updateBlockProperty("default_avatar", !defaultAvatar)
    }

    const updateBlockProperty = (propertyName: string, propertyValue: string | boolean) => {
        dispatch(
            updateBlock({
                blocksType: BlocksType.BLOCKS,
                id: block.id,
                block: {
                    ...block,
                    [propertyName]: propertyValue,
                },
            })
        )
    }

    const renderMessageRow = (anchor?: boolean, secondary?: boolean) => {
        const onSenderChange = (sender: string) => {
            updateBlockProperty(anchor ? "anchor_sender" : "sender", sender)
        }

        const onMessageChange = (message: string) => {
            updateBlockProperty(anchor ? "anchor_message" : "value", message)
        }

        return (
            <StyledMessageRow secondary={secondary}>
                <DesignerFundLogoIcon className={"logo"} />
                <div className={"sender-and-message-container"}>
                    <RichTextEditor
                        placeholder={"Headlands Bot"}
                        value={anchor ? block.anchor_sender : block.sender}
                        onChange={onSenderChange}
                        enableVariables
                        className={"sender"}
                        autoFocus={false}
                    />
                    <RichTextEditor
                        placeholder={"Write your message here"}
                        value={anchor ? block.anchor_message : block.value}
                        onChange={onMessageChange}
                        enableFormatting
                        enableVariables
                        enableEmojis
                        autoFocus={false}
                    />
                </div>
            </StyledMessageRow>
        )
    }

    return (
        <StyledMessageComponent id={`block-${block.id}`} data-tes-id={"message-block"}>
            <div className={"header-container"}>
                <div className={"toggles-row"}>
                    <Checkbox
                        selected={nature === NotificationNature.SOCIAL}
                        onChange={handleNatureToggle}
                        label={"Share publicly"}
                        dataTestId={"message-toggle"}
                    />
                    <StyledBox css={{ display: "flex" }}>
                        {groupReplies && (
                            <StyledBox css={{ marginRight: 12 }}>
                                <Checkbox
                                    selected={!defaultAvatar}
                                    onChange={handleDefaultAvatarToggle}
                                    label={"Show user's avatar"}
                                    dataTestId={"default-avatar-toggle"}
                                />
                            </StyledBox>
                        )}

                        <Checkbox
                            selected={groupReplies}
                            onChange={handleGroupRepliesToggle}
                            label={"Group Replies"}
                            dataTestId={"group-replies-toggle"}
                        />
                    </StyledBox>
                </div>
                {groupReplies && <StyledMainSeparator>First Message</StyledMainSeparator>}
                {groupReplies && renderMessageRow(true)}
                {groupReplies && <StyledSecondarySeparator>Replies</StyledSecondarySeparator>}
                {groupReplies && renderMessageRow(false, true)}
                {!groupReplies && renderMessageRow(false)}
            </div>
        </StyledMessageComponent>
    )
}

export default Message
