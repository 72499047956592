import { useCurrentThreadGuid } from "../../hooks/currentThread.hook"
import { shallowEqual, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { useMemo } from "react"
import { getCustomImageUrl } from "../../utils/utils"
import defaultAvatar from "../../assets/images/default-avatar.png"
import { StyledInstructorAvatar } from "./styles"

interface InstructorAvatarProps {
    preview?: boolean
}

export const InstructorAvatar = ({ preview }: InstructorAvatarProps) => {
    const currentThreadGuid = useCurrentThreadGuid()
    const instructorProfileImageLink = useSelector(
        (state: RootState) =>
            state.threadsReducer.threads[currentThreadGuid]?.thread?.instructor?.profileImageURL,
        shallowEqual
    )
    const resizedImageUrl = useMemo(
        () =>
            instructorProfileImageLink
                ? getCustomImageUrl(instructorProfileImageLink, {
                      // NOTE: images intrinsic size should be larger than
                      // rendered size for perfect clarity on "retina" displays
                      // or any display with a "device pixel ratio" > 1
                      // Hacky but safe is to fetch images 2x larger than rendered
                      // size
                      height: 60,
                      width: 60,
                      crop: "face",
                      webp: true,
                  })
                : defaultAvatar,
        [instructorProfileImageLink]
    )

    return (
        <StyledInstructorAvatar preview={preview}>
            <img src={resizedImageUrl} alt={"instructor avatar"} />
        </StyledInstructorAvatar>
    )
}
