import { useTheme } from "styled-components"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { BlocksType, SortableOutlineGroupType } from "../../../../types"
import { findSection, moveBlock } from "../../../../redux/blocks"
import {
    StyledAddIcon,
    StyledMoreHorizIcon,
    StyledSectionItemDivider,
    StyledThreadOutlineBarSectionContent,
    StyledThreadOutlineBarSectionHeader,
    StyledThreadOutlineBarSectionItem,
} from "../styles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import EllipsisText from "../../../../components/EllipsisText"
import { ThreadOutlineBarItemProps } from "./index"
import ThreadOutlineBarBlockItem from "./ThreadOutlineBarBlockItem"
import ThreadOutlineBarItemMenu from "./ThreadOutlineBarItemMenu"
import SortableList, { MoveItemArguments } from "../../../../components/SortableList"
import { getEmptySectionBlock } from "../../../../utils/utils"
import { isNil } from "lodash"

const ThreadOutlineBarSectionItem = ({
    blockId,
    navigateToItem,
    blockIndex,
    ...commonHandlers
}: ThreadOutlineBarItemProps) => {
    const theme = useTheme()
    const [collapsed, setCollapsed] = useState(false)
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [hovered, setHovered] = useState(false)

    const dispatch = useDispatch()

    const sectionTitle = useSelector((state: RootState) => {
        return state.blocksReducer[BlocksType.BLOCKS][blockId].title
    })

    const sectionObjects = useSelector((state: RootState) => {
        return findSection(blockId, state.blocksReducer[`${BlocksType.BLOCKS}Index`]).objects
    })

    const moveItem = (args: MoveItemArguments) => {
        const { id, groupFrom, groupTo, indexFrom, indexTo } = args
        dispatch(
            moveBlock({
                blocksType: BlocksType.BLOCKS,
                blockId: id,
                branchFrom: groupFrom,
                branchTo: groupTo,
                indexFrom,
                /*
                 * The duplicated block adds 1 item to the list, but when we finish moving the block the
                 * duplicated one gets deleted that's why we have to subtract 1 to the 'indexTo'. This is
                 * only required when moving from a list to the same list, if we move from a group to a
                 * another group we don't have to do it.
                 * */
                indexTo: indexFrom < indexTo && groupFrom === groupTo ? indexTo - 1 : indexTo,
            })
        )
    }

    return (
        <StyledThreadOutlineBarSectionItem id={blockId}>
            <StyledThreadOutlineBarSectionHeader
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                {collapsed ? (
                    <ExpandMoreIcon fontSize={"small"} onClick={() => setCollapsed(false)} />
                ) : (
                    <ExpandLessIcon fontSize={"small"} onClick={() => setCollapsed(true)} />
                )}
                <EllipsisText
                    variant={"h4"}
                    text={sectionTitle ?? ""}
                    textStyles={{
                        fontSize: "14px",
                        lineHeight: "18px",
                        color: theme.colors.base.uiLabelBase,
                        fontWeight: "700",
                        marginLeft: "6px",
                    }}
                    maxWidth={hovered ? 210 : 250}
                    popupOptions={{ position: "right center", mouseEnterDelay: 1000 }}
                    onClick={() => navigateToItem(blockId)}
                    containerStyles={{ width: "100%" }}
                />
                <ThreadOutlineBarItemMenu
                    isSection
                    blockId={blockId}
                    blockIndex={blockIndex}
                    onOpen={() => setMenuIsOpen(true)}
                    onClose={() => setMenuIsOpen(false)}
                    trigger={<StyledMoreHorizIcon fontSize={"small"} $show={menuIsOpen} />}
                    {...commonHandlers}
                />
                <StyledAddIcon
                    $show={menuIsOpen}
                    onClick={() => {
                        commonHandlers.handleAddChunk(
                            getEmptySectionBlock(),
                            !isNil(blockIndex) ? blockIndex + 1 : undefined
                        )
                    }}
                />
            </StyledThreadOutlineBarSectionHeader>
            <StyledThreadOutlineBarSectionContent open={!collapsed}>
                {sectionObjects && (
                    <SortableList
                        id={blockId}
                        list={Object.values(sectionObjects)}
                        renderItem={(item, index) => (
                            <ThreadOutlineBarBlockItem
                                key={`inner-thread-outline-bar-block-item-${index}`}
                                blockId={item.id}
                                navigateToItem={navigateToItem}
                                sectionId={blockId}
                                blockIndex={index}
                                {...commonHandlers}
                            />
                        )}
                        moveItem={moveItem}
                        sortableProps={{
                            group: {
                                name: SortableOutlineGroupType.SECTION,
                                put: (to: any, from: any, element: any) => {
                                    /*
                                     * We only allow chunks inside sections, this prevents the
                                     * user from dragging a section inside another section
                                     * */
                                    const elementIsAChunk = element?.className.includes(
                                        SortableOutlineGroupType.CHUNK
                                    )
                                    return elementIsAChunk
                                },
                            },
                        }}
                    />
                )}
            </StyledThreadOutlineBarSectionContent>
            <StyledSectionItemDivider />
        </StyledThreadOutlineBarSectionItem>
    )
}

export default ThreadOutlineBarSectionItem
