import { StyledCardsContainer, StyledContentContainer, StyledDeliverScreen } from "./styles"
import Sidebar from "react-sidebar"
import { useEffect } from "react"
import useLayout from "../hooks/layout.hook"
import ProgramsBar from "./components/ProgramsBar"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { SidebarType } from "../redux/layout"
import { useSidebars } from "../hooks/sidebars.hook"
import ProgramDetailsCard from "./components/ProgramDetailsCard"
import ContentCard from "./components/ContentCard"
import ParticipantsCard from "./components/ParticipantsCard"
import { useSelectedDeliverProgram } from "../hooks/selectedProgram.hook"
import { Loader } from "../components/Loader"
import { useTheme } from "styled-components"
import SidebarToggle, { SidebarTogglePosition } from "../components/SidebarToggle"
import TopBar from "../components/TopBar"
import MenuIcon from "../assets/icons/MenuIcon"

const DeliverScreen = () => {
    const { isMobile } = useLayout()
    const { toggleSidebar, openSidebars, deliverProgramBarOpen, enableSidebarAnimation } =
        useSidebars()
    const theme = useTheme()

    const { program, loading } = useSelectedDeliverProgram()

    const programSidebarOpen = useSelector(
        (state: RootState) => state.layoutReducer.deliverProgramBarOpen
    )

    /*
     * On mount try to open the program sidebar
     * */
    useEffect(() => {
        openSidebars([SidebarType.deliverProgram], false)
    }, [])

    return (
        <StyledDeliverScreen id={"StyledDeliverScreen"}>
            <SidebarToggle
                position={SidebarTogglePosition.left}
                onClick={() => toggleSidebar(SidebarType.deliverProgram)}
                sidebarWidth={`${theme.sidebarWidths.deliver}px`}
                leftPadding={theme.variables.deliver.programBarPadding.left}
                sidebarOpen={!!deliverProgramBarOpen}
                enabledTransition={enableSidebarAnimation}
                styles={{ zIndex: 1000 }}
                icon={<MenuIcon />}
            />
            <Sidebar
                sidebar={<ProgramsBar />}
                open={programSidebarOpen}
                docked={programSidebarOpen}
                shadow={false}
                touch={isMobile}
                transitions={enableSidebarAnimation}
                styles={{
                    sidebar: {
                        overflow: "hidden",
                        display: "flex",
                    },
                    content: {
                        overflow: "hidden",
                    },
                }}
            >
                <StyledContentContainer id={"StyledContentContainer"}>
                    <TopBar title={program?.name} extraLeftPadding={!programSidebarOpen} />
                    {loading && <Loader size={72} />}
                    {program && !loading && (
                        <StyledCardsContainer>
                            <ProgramDetailsCard />
                            <ContentCard />
                            <ParticipantsCard />
                        </StyledCardsContainer>
                    )}
                </StyledContentContainer>
            </Sidebar>
        </StyledDeliverScreen>
    )
}

export default DeliverScreen
