import { gql } from "./generated"

export const ENROLLED_PROGRAM_THREADS = gql(`
    query EnrolledProgramsThreads($guid: String!) {
        enrolledProgramThreads(guid: $guid) {
            id
            status
            progress
            thread {
                id
                guid
                title
                description
                imageURL
                instructor {
                    id
                    firstName
                    lastName
                    profileImageURL
                }
            }
        }
    }
`)

export const GET_CREATOR_THREAD = gql(/* GraphQL */ `
    query getCreatorToolThreadByGuid($threadGUID: String!) {
        getThreadByGuid(threadGUID: $threadGUID) {
            id
            guid
            preview
            synopsis
            blocks
            lastUpdateTime
            title
            version
            threadVersionID
            theme
            font
            description
            attachments {
                guid
                changes
                type
                url
            }
            instructor {
                id
                email
                firstName
                lastName
                company
                role
                city
                state
                description
                emoji
                pronouns
                profileImageURL
            }
            aiParams {
                instructorProfile
                referenceMaterial
                prompt
            }
            aiEditing
            imageURL
            hasCustomCoverImage
        }
    }
`)

export const GET_CREATOR_THREADS = gql(`
    query getCreatorThreads {
        getAllCreatorToolThreads {
            id
            guid
            instructor {
                id
                firstName
                lastName
                profileImageURL
            }
            instructorId
            threadVersion {
                id
                title
                published
                threadId
                createdAt
            }
        }
    }
`)

export const GET_GLOBAL_VARIABLES = gql(`
    query getGlobalVariables {
        globalVariables {
            category
            name
            type
            documentation
            dynamic
            writable
            possibleValues {
                id
                text
                imageURL
            }
        }
    }
`)

export const GET_PROGRAM = gql(`
    query getProgram($guid: String) {
        program(guid: $guid) {
            id
            guid
            name
            live
            startTime
            endTime
            imageURL
            socialSidebar
            isSingleShareThread
            leaderboard
            displayName
            description
            socialFeatures
            threadsInOrder
            updatedAt
            defaultSender
        }
    }
`)

export const GET_PROGRAMS = gql(`
    query getPrograms($search: String, $guid: String) {
        program(searchTerm: $search, guid: $guid) {
            id
            guid
            name
            live
            startTime
            endTime
            imageURL
            socialSidebar
            leaderboard
        }
    }
`)

export const GET_PROGRAM_CONTENTS = gql(`
    query getProgramContents($guid: String!) {
        programContents(guid: $guid) {
            __typename
            ... on SlackMessage {
                title
                body
                deliveryTime
                link
                attachment {
                    guid
                    changes
                    url
                }
                clientData
            }
            ... on EmailMessage {
                title
                body
                deliveryTime
                link
                attachment {
                    guid
                    changes
                    url
                }
                clientData
            }
            ... on ProgramThread {
                thread {
                    id
                    guid
                    title
                }
                lockTime
                unlockTime
                clientData
                messages {
                    __typename
                    ... on SlackMessage {
                        title
                        body
                        deliveryTime
                        link
                        attachment {
                            guid
                            changes
                            url
                        }
                        clientData
                    }
                    ... on EmailMessage {
                        title
                        body
                        deliveryTime
                        link
                        attachment {
                            guid
                            changes
                            url
                        }
                        clientData
                    }
                }
            }
        }
    }
`)

export const GET_THREAD_VERSION_HISTORY_BY_GUID = gql(`
    query GetThreadVersionHistoryByGuid($threadGUID: String!) {
        getThreadVersionHistoryByGuid(threadGUID: $threadGUID) {
            id
            time
            editor {
                id
                firstName
                lastName
                profileImageURL
            }
            published
        }
    }
`)

export const GET_LINT_THREAD = gql(`
    query getLintThread($id: Int!) {
        lintThread(input: { id: $id }) {
            id
            objectID
            description
            severity
            category
        }
    }
`)

export const GET_ALL_INSTRUCTORS = gql(`
    query GetAllInstructors {
        allInstructors {
            id
            firstName
            lastName
            description
            profileImageURL
        }
    }
`)

export const ENROLLED_PROGRAM_PARTICIPANTS = gql(`
    query enrolledProgramParticipants($guid: String!) {
        enrolledProgramParticipants(guid: $guid) {
            person {
                id
                email
                firstName
                lastName
                profileImageURL
                company
                role
                city
                state
                emoji
                description
            }
            progress {
                total
                totalScore
                threadScore {
                    guid
                    totalBasePoints
                    totalBonusPoints
                    totalPoints
                }
                lastActivity
                threadProgress {
                    guid
                    progress
                    status
                }
            }
        }
    }
`)

export const ENROLLED_PROGRAMS = gql(`
    query EnrolledPrograms {
        enrolledPrograms {
            id
            name
            description
            isSingleShareThread
            firstThreadId
            firstThreadGUID
            imageURL
            guid
            socialSidebar
            leaderboard
            startTime
            progress {
                percentComplete
                status
            }
        }
    }
`)

export const ENROLLED_PROGRAMS_ID_ONLY = gql(`
    query EnrolledProgramsIdOnly {
        enrolledPrograms {
            id
            guid
        }
    }
`)

export const GET_PROGRAM_NOTIFICATION_CONFIG = gql(`
    query getProgramNotificationConfig($guid: String!) {
        programNotificationConfig(guid: $guid) {
            id
            name
            channels {
                id
                name
            }
        }
    }
`)

export const GET_SLACK_CONNECTIONS = gql(`
    query getSlackConnections {
        slackConnections {
            id
            name
            channels {
                id
                name
            }
        }
    }
`)

export const PREVIEW_THREAD = gql(`
    query previewThread($threadGUID: String!) {
        previewThreadByGuid(threadGUID: $threadGUID) {
            thread {
                id
                blocks
                preview
                synopsis
                title
                instructor {
                    firstName
                    lastName
                }
            }
            variables {
                name
                type
                value {
                    string
                    valueID
                    integer
                    boolean
                }
            }
            telemetrySessionId
        }
    }
`)

export const GENERATE_EDIT_CONTENT = gql(`
    query generateEditContent($content: String!, $type: EditType) {
        aiGenEditContent(content: $content, type: $type)
    }
`)

export const USER_PERMISSIONS = gql(/* GraphQL */ `
    query UserPermissions {
        aboutMe {
            id
            mayCreate
            distinguishedInstructor
            maySeeUnpublishedThreads
            maySeeExperimentalFeatures
            isAdministrator
        }
    }
`)
export const GET_LATEST_FREE_TEXT_RESPONSES = gql(/* GraphQL */ `
    query getLatestFreeTextResponses(
        $programGUID: String!
        $threadGUID: String!
        $objectID: String!
    ) {
        latestFreetextResponses(
            programGUID: $programGUID
            threadGUID: $threadGUID
            objectID: $objectID
        ) {
            author {
                id
                email
                firstName
                lastName
                profileImageURL
                company
                role
                city
                state
                description
                emoji
            }
            response
        }
    }
`)

export const GET_PROGRAM_PARTICIPANTS = gql(/* GraphQL */ `
    query getProgramParticipants($guid: String!) {
        programParticipants(guid: $guid) {
            id
            registered
            hidden
            firstName
            lastName
            email
            company
            imageURL
        }
    }
`)

export const ABOUT_ME = gql(/* GraphQL */ `
    query AboutMe {
        aboutMe {
            id
            email
            firstName
            lastName
            phoneNumber
            company
            profileImageURL
            role
            city
            state
            description
            emojis
        }
    }
`)

export const SEARCH_PLAYLISTS = gql(/* GraphQL */ `
    query searchPlaylists($searchTerm: String, $onlyPublished: Boolean) {
        searchPlaylists(searchTerm: $searchTerm, onlyPublished: $onlyPublished) {
            playlist {
                id
                guid
                title
                description
                imageURL
                length
            }
        }
    }
`)

export const SEARCH_THREADS = gql(/* GraphQL */ `
    query searchThreads($input: ThreadSearchQuery!) {
        searchThreads(input: $input) {
            thread {
                id
                guid
                title
                lastUpdateTime
                instructor {
                    id
                    firstName
                    lastName
                    profileImageURL
                }
            }
        }
    }
`)

export const SEARCH_CREATOR_TOOL_THREADS_MOBILE = gql(/* GraphQL */ `
    query searchCreatorTollThreadsMobile($input: ThreadSearchQuery!) {
        searchThreads(input: $input) {
            thread {
                id
                guid
                title
                description
                imageURL
                lastUpdateTime
                instructor {
                    id
                    firstName
                    lastName
                    profileImageURL
                }
            }
        }
    }
`)

export const CHECK_EMAIL = gql(/* GraphQL */ `
    query checkEmail($email: String!) {
        checkEmail(email: $email)
    }
`)

export const IMAGE_SEARCH = gql(/* GraphQL */ `
    query imageSearch($input: ImageSearchInput!) {
        imageSearch(input: $input) {
            value {
                handle
                imageURL
                thumbnailURL
            }
        }
    }
`)

export const GET_DYNAMIC_VARIABLES_VALUE = gql(/* GraphQL */ `
    query getDynamicVariablesValues($input: DynamicVariableQuery!) {
        dynamicVariable(input: $input) {
            name
            type
            value {
                string
                valueID
                integer
                boolean
            }
        }
    }
`)

export const THREAD_PROGRESS_FOR_PROGRAM = gql(/* GraphQL */ `
    query threadProgressForProgram($input: ThreadProgressForProgramInput!) {
        threadProgressForProgram(input: $input) {
            programGuid
            threadGuid
            person {
                id
                email
                firstName
                lastName
                profileImageURL
                company
                role
                city
                state
                emoji
                description
            }
            threadScore {
                guid
                totalBasePoints
                totalBonusPoints
                totalPoints
            }
        }
    }
`)

export const PROGRAM_PROGRESS = gql(/* GraphQL */ `
    query programProgress($input: ProgramProgressInput!) {
        programProgress(input: $input) {
            person {
                id
                email
                firstName
                lastName
                profileImageURL
                company
                role
                city
                state
                emoji
                description
            }
            totalScore
        }
    }
`)

export const GET_THREAD_DURATION = gql(/* GraphQL */ `
    query getThreadDuration($input: ThreadSpec) {
        fullThreadContent(input: $input) {
            timingData {
                expectedDurationMS
            }
        }
    }
`)

export const USER_PROFILE_METRICS = gql(/* GraphQL */ `
    query userProfileMetrics {
        aboutMe {
            id
            stats {
                matches
                points
                wins
                losses
            }
        }
    }
`)
