import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { Block, BlocksType, BlockType } from "../../../../types"
import { useCallback } from "react"
import { selectChunk } from "../../../../redux/blocks"
import ThreadOutlineBarSectionItem from "./ThreadOutlineBarSectionItem"
import ThreadOutlineBarBlockItem from "./ThreadOutlineBarBlockItem"

export interface ThreadOutlineBarItemHandlers {
    handleAddChunk: (chunk: Block, index?: number, sectionId?: string) => void
    handleDelete: (blockId: string, index: number, sectionId?: string) => void
    handleToggleVisibility: (blockId: string, isHidden?: boolean) => void
    handleDuplicate: (blockId: string, index: number, sectionId?: string) => void
    handlePreview: (previewMode: boolean, initialBlockId?: string) => void
}
export interface BaseThreadOutlineBarItemProps extends ThreadOutlineBarItemHandlers {
    blockId: string
    blockIndex: number
}

export interface ThreadOutlineBarItemProps extends BaseThreadOutlineBarItemProps {
    navigateToItem: (blockId: string, isAChunk?: boolean) => void
}
const ThreadOutlineBarItem = ({
    blockId,
    blockIndex,
    ...commonHandlers
}: BaseThreadOutlineBarItemProps) => {
    const dispatch = useDispatch()
    const type = useSelector(
        (state: RootState) => state.blocksReducer[BlocksType.BLOCKS][blockId].type
    )

    const navigateToItem = useCallback((blockId: string, isAChunk?: boolean) => {
        const node = document.getElementById(`block-${blockId}`)
        node && node.scrollIntoView({ block: "start", behavior: "smooth" })
        if (isAChunk) dispatch(selectChunk({ chunkId: blockId }))
    }, [])

    if (type === BlockType.SECTION) {
        return (
            <ThreadOutlineBarSectionItem
                blockId={blockId}
                navigateToItem={navigateToItem}
                blockIndex={blockIndex}
                {...commonHandlers}
            />
        )
    }
    return (
        <ThreadOutlineBarBlockItem
            blockId={blockId}
            navigateToItem={navigateToItem}
            blockIndex={blockIndex}
            {...commonHandlers}
        />
    )
}

export default ThreadOutlineBarItem
