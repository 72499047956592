import RichTextEditor from "../../../components/Input/RichTextEditor"
import { removeHTML } from "../../../utils/utils"
import { StyledBreakpoint, StyledBreakpointInput, StyledChevron } from "./styles"
import { useEffect, useRef, useState } from "react"
import BreakpointButton from "../../../components/BreakpointButton/BreakpointButton"
import ReactQuill from "react-quill"

export interface BreakpointProps {
    value?: string
    onChange?: (newValue: string) => void
}

const Breakpoint = ({ value, onChange }: BreakpointProps) => {
    const [active, setActive] = useState(false)
    const inputRef = useRef<ReactQuill>(null)

    useEffect(() => {
        if (active && inputRef.current) inputRef.current.focus()
    }, [active])

    return (
        <StyledBreakpoint disableHover>
            {/*
             * The component will only behave as the combination of Breakpoint and UserMessage if
             * an onChange function is received, if not, it will behave as the regular Breakpoint.
             */}
            {!!onChange ? (
                <StyledBreakpointInput
                    active={active || !!value}
                    onClick={() => setActive(true)}
                    data-testid={"pause-button"}
                >
                    {active || !!value ? (
                        <RichTextEditor
                            reactQuillRef={inputRef}
                            value={value}
                            onChange={(value: string) => onChange(removeHTML(value))}
                            enableVariables
                            enableEmojis
                            placeholder={"User message"}
                            onBlur={() => setActive(false)}
                        />
                    ) : (
                        <StyledChevron />
                    )}
                </StyledBreakpointInput>
            ) : (
                <BreakpointButton white border />
            )}
        </StyledBreakpoint>
    )
}

export default Breakpoint
