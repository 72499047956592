import { useToast } from "../../../hooks/useToast.hook"
import { useMutation, useQuery } from "@apollo/client"
import { ABOUT_ME } from "../../../apollo/queries"
import { UPDATE_USER_PROFILE } from "../../../apollo/mutations"
import { ErrorToastIcon } from "../../../assets/icons/ErrorToastIcon"
import { SaveToastIcon } from "../../../assets/icons/SaveToastIcon"
import Input from "../../../components/InputNew"
import { StyledColumn } from "../../../styles/styledcomponents"
import { Button } from "../../../components/Button/Button"
import { useFormik } from "formik"
import * as Yup from "yup"

export const PROFILE_USER_FORM_ID = "profile_user_form_id"

type FormValues = {
    email: string
    firstName: string
    lastName: string
    company: string
    role: string
    city: string
    state: string
    emojis: string
    description: string
}

type FormFieldName = keyof FormValues

type Field = {
    fieldName: FormFieldName
    label: string
}

const FormValidationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("This field is required"),
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    company: Yup.string().required("This field is required"),
    role: Yup.string().required("This field is required"),
})

type ProfileFormProps = {
    hideSubmitButton?: boolean
}

const ProfileForm = ({ hideSubmitButton }: ProfileFormProps) => {
    const { showToast, showErrorToast } = useToast()

    useQuery(ABOUT_ME, {
        fetchPolicy: "cache-and-network",
        onCompleted: ({ aboutMe }) => {
            formik.setFieldValue("email", aboutMe.email)
            formik.setFieldValue("firstName", aboutMe.firstName)
            formik.setFieldValue("lastName", aboutMe.lastName)
            formik.setFieldValue("company", aboutMe.company)
            formik.setFieldValue("role", aboutMe.role)
            formik.setFieldValue("description", aboutMe.description)
            formik.setFieldValue("emojis", aboutMe.emojis)
            formik.setFieldValue("city", aboutMe.city)
            formik.setFieldValue("state", aboutMe.state)
        },
    })

    const [updateUserProfile, { loading }] = useMutation(UPDATE_USER_PROFILE, {
        onError: () => {
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
        },
        onCompleted: () => {
            showToast({ icon: <SaveToastIcon />, message: "Saved successfully" })
        },
    })

    const initialValues: FormValues = {
        email: "",
        firstName: "",
        lastName: "",
        company: "",
        role: "",
        city: "",
        state: "",
        emojis: "",
        description: "",
    }

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            updateUserProfile({ variables: values })
        },
        validationSchema: FormValidationSchema,
    })

    const formFieldNames: Field[] = [
        { fieldName: "email", label: "Email Address" },
        { fieldName: "firstName", label: "First Name" },
        { fieldName: "lastName", label: "Last Name" },
        { fieldName: "company", label: "Company" },
        { fieldName: "role", label: "Role" },
        { fieldName: "city", label: "City" },
        { fieldName: "state", label: "State" },
        { fieldName: "emojis", label: "Emojis" },
        { fieldName: "description", label: "Description" },
    ]

    return (
        <form
            id={PROFILE_USER_FORM_ID}
            onSubmit={formik.handleSubmit}
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
        >
            {formFieldNames.map(({ fieldName, label }) => (
                <Input
                    key={`field-${fieldName}`}
                    name={fieldName}
                    label={label}
                    value={formik.values[fieldName]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched[fieldName] && !!formik.errors[fieldName]}
                    errorMessage={formik.errors[fieldName]}
                    disabled={fieldName === "email"}
                />
            ))}

            {!hideSubmitButton && (
                <StyledColumn css={{ alignItems: "center" }}>
                    <Button
                        width={120}
                        label={"Save"}
                        loading={loading}
                        type={"submit"}
                        disabled={!formik.isValid}
                        buttonStyle={{ marginBottom: 12, padding: 12 }}
                    />
                </StyledColumn>
            )}
        </form>
    )
}

export default ProfileForm
