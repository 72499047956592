import styled, { css } from "styled-components"
import { rgba } from "polished"
import { StyledLeftBubble } from "../styles"

interface StyledImageUploadBubbleProps {
    isMobile?: boolean
}

export const StyledImageUploadBubble = styled(StyledLeftBubble)<StyledImageUploadBubbleProps>`
    padding: 0;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    border: 1px solid ${({ theme }) => theme.headlandsGray3};
    box-sizing: border-box;
    box-shadow: ${({ theme }) => theme.boxShadow1};
    border-radius: ${({ theme }) => theme.borderRadius1};
    background-color: ${({ theme }) => theme.headlandsWhite};
    max-width: none;
    margin-top: 6px;
    overflow: visible;

    ${({ isMobile }) =>
        isMobile &&
        css`
            background-color: transparent;
            width: unset;
            display: flex;
            flex: 1;
            align-self: unset;
            padding: unset;
            margin: 6px 0 0 0;
            max-width: unset;
            box-shadow: unset;
            border: unset;
        `}

    img {
        border-radius: 16px 16px 16px 16px;
    }

    .react-tabs {
        .react-tabs__tab-list {
            display: block;
            padding: 10px 10px 0;
            border-bottom: 1px solid ${({ theme }) => theme.headlandsGray3};
            margin: 0;

            .react-tabs__tab {
                font-size: 14px;
                font-weight: 400;
                padding: 0 4px 4px;
                margin-bottom: -1px;
                display: inline-block;
                cursor: pointer;

                &:first-child {
                    margin-right: 12px;
                }

                &.react-tabs__tab--selected {
                    border-bottom: 2px solid ${({ theme }) => theme.colors.thread.student.bubble};
                    position: relative;
                }
            }
        }

        .react-tabs__tab-panel--selected {
            display: flex;
            flex-direction: column;
            padding: 24px 18px 18px;

            button {
                min-height: unset;
                height: 24px;
                padding: 0;
                margin: 18px auto 0;
            }
        }

        .quill {
            flex: 1;
            border: 1px solid #d0d0d0;
            border-radius: ${({ theme }) => theme.borderRadius1};
            margin: 0 6px;
            box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.05);
            background-color: ${({ theme }) => theme.headlandsWhite};

            .ql-tooltip {
                display: none;
            }

            .ql-editor {
                padding: 10px 16px;
                p,
                li {
                    color: ${({ theme }) => theme.headlandsGray2};
                }

                &.ql-blank::before {
                    color: ${({ theme }) => theme.headlandsGray3};
                }
            }
        }
    }
`

export const StyledMobileButtonWrapper = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 16px;
    border: 1px solid ${(props) => props.theme.headlandsGray3};
    background-color: ${(props) => props.theme.headlandsWhite};
    margin: 0 20px;
    width: 100px;
    height: 100px;
    text-align: center;
    box-shadow: ${(props) => props.theme.boxShadow1};

    span {
        padding-top: 10px;
    }

    &:hover {
        background-color: ${(props) => rgba(props.theme.colors.thread.student.bubble, 0.2)};
        border-color: ${(props) => props.theme.colors.thread.student.bubble};
        cursor: pointer;
        span {
            color: ${(props) => props.theme.colors.thread.student.bubble};
        }
    }
`

export const StyledMobilePreview = styled.div`
    display: flex;
    flex-direction: column;
    padding: 6px;
    border: 1px solid ${(props) => props.theme.headlandsGray3};
    border-radius: ${(props) => props.theme.borderRadius1};
    box-shadow: ${(props) => props.theme.boxShadow1};
    margin: ${(props) => props.theme.bubbleVerticalMargin}
        ${(props) => props.theme.bubbleHorizontalMargin};

    img {
        border-radius: 8px;
        width: 100%;
    }
`

export const StyledMobilePreviewConfirmationRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 6px;

    svg {
        padding: 0 4px;
        &:hover {
            cursor: pointer;
        }
    }
`
