import styled, { css } from "styled-components"
import { StyledBox } from "../../../styles/styledcomponents"
import { BlockType } from "../../../types"
import { StyledLeftBubble } from "../styles"

const calculatePaneHeight = (
    isMobile: boolean,
    hasNext: boolean,
    isPreview?: boolean,
    type?: BlockType.SYNOPSIS | BlockType.PREVIEW,
    isMobilePreview?: boolean,
    scoreHeaderIsShown?: boolean
) => {
    const topBarHeight = isMobile ? 69 : isPreview ? 67 : 70
    const scoreHeaderHeight = type == BlockType.SYNOPSIS && scoreHeaderIsShown ? 69 : 0
    const mobilePreviewDeviceHeight = window.innerHeight - topBarHeight - 80
    const height = isMobilePreview ? mobilePreviewDeviceHeight : window.innerHeight
    const nextThreadHeight = hasNext ? 66 : 0
    const paddings = isMobilePreview ? 45 : isPreview && type === BlockType.PREVIEW ? 101 : 86
    return height - topBarHeight - scoreHeaderHeight - nextThreadHeight - paddings + "px"
}

export const StyledPaneContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;

    .bubble {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        max-width: unset !important;
        &.image {
            &.small {
                width: calc((1 / 2) * 100% - 2 * 12px) !important;
            }
            &.medium {
                width: calc((3 / 4) * 100% - 2 * 12px) !important;
            }
            &.large {
                width: calc(100% - 2 * 12px) !important;
            }
        }
    }

    hr {
        margin-right: 0;
        margin-left: 0;
    }

    &.thread-preview {
        padding: 0 !important;
        margin: 0;
    }
`

export const StyledPaneBubble = styled(StyledBox)<{
    isMobile: boolean
    hasNext: boolean
    isPreview?: boolean
    type?: BlockType.SYNOPSIS | BlockType.PREVIEW
    isMobilePreview?: boolean
    scoreHeaderIsShown?: boolean
}>`
    display: flex;
    flex-direction: column;
    height: ${(props) =>
        calculatePaneHeight(
            props.isMobile,
            props.hasNext,
            props.isPreview,
            props.type,
            props.isMobilePreview,
            props.scoreHeaderIsShown
        )};
    background-color: ${({ theme }) => theme.colors.thread.instructor.bubble};
    line-height: 1.4;
    border-radius: 16px;
    margin: 30px 12px 12px;
    overflow: overlay;
    padding: 18px;
    ${(props) =>
        props.isPreview &&
        props.type === BlockType.PREVIEW &&
        css`
            margin-top: 0;
        `}

    ${StyledLeftBubble} {
        margin: 0;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
    }
`

export const StyledNextThreadContainer = styled.div<{ mobile: boolean; disabled?: boolean }>`
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin: 0 12px 28px;
    width: fit-content;

    ${(props) =>
        props.mobile &&
        css`
            margin: 0 20px 28px;
        `}

    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
            opacity: 0.3;
        `}

    &:hover {
        cursor: pointer;
    }
`
