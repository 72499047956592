import { Block, BlocksType, MediaSize } from "../../../../types"
import video from "../../../../assets/icons/video.svg"
import { useReactiveVar } from "@apollo/client"
import { selectedBlockVar } from "../../../../apollo/cache-store"
import MediaModal from "../../MediaModal/MediaModal"
import { getVideoUtils } from "../../../../utils/utils"
import HoverOverlayOptions, { Menu } from "../../../../components/HoverOverlayOptions"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateBlock } from "../../../../redux/blocks"
import {
    StyledBlockVideo,
    StyledEmptyBlockVideo,
    StyledResponsiveVideoContainer,
    StyledResponsiveVideo,
} from "./styles"
import { StyledBox } from "../../../../styles/styledcomponents"

export interface BlockImageProps {
    blocksType: BlocksType
    block: Block
    onChange: (value: string, blockId: string) => void
}

const BlockVideo = ({ blocksType, block, onChange }: BlockImageProps) => {
    const dispatch = useDispatch()
    const selectedBlock = useReactiveVar(selectedBlockVar)
    const [modalOpen, setModalOpen] = useState(false)
    const [size, setSize] = useState<MediaSize>(MediaSize.medium)

    /*
     * This effect handles video size
     * */
    useEffect(() => {
        if (block?.videoSize && block?.videoSize !== size) {
            setSize(block?.videoSize)
        }
    }, [block])

    const handleClick = () => {
        selectedBlockVar(block)
        setModalOpen(true)
    }

    useEffect(() => {
        if (!selectedBlock) setModalOpen(false)
    }, [selectedBlock])

    const { parsed, maxWidth, paddingBottom } = block.value
        ? getVideoUtils(block.value)
        : { parsed: undefined, maxWidth: undefined, paddingBottom: undefined }

    const handleSizeChange = (size: MediaSize) => {
        setSize(size)
        const updatedBlock: { id: string; block: { value?: string; videoSize?: MediaSize } } = {
            id: block.id,
            block: { videoSize: size },
        }
        dispatch(updateBlock({ blocksType, id: updatedBlock.id, block: updatedBlock.block }))
    }

    const VideoMenus: Menu[] = [
        {
            options: [
                {
                    name: "size-small",
                    text: "Sm",
                    onClick: () => handleSizeChange(MediaSize.small),
                },
                {
                    name: "size-medium",
                    text: "Md",
                    onClick: () => handleSizeChange(MediaSize.medium),
                },
                {
                    name: "size-large",
                    text: "Lg",
                    onClick: () => handleSizeChange(MediaSize.large),
                },
            ],
            enable: true,
        },
    ]

    const getVideoWidth = () => {
        // all sizes we subtract 40px (space for the action buttons) plus 14px (block horizontal margin)
        const actionButtonsWidth = 40
        const blockHorizontalMargin = 14

        switch (size) {
            case MediaSize.small:
                return `${300 - actionButtonsWidth - blockHorizontalMargin}px`
            case MediaSize.large:
                return `${600 - actionButtonsWidth - blockHorizontalMargin}px`
            case MediaSize.medium:
            default:
                return `${450 - actionButtonsWidth - blockHorizontalMargin}px`
        }
    }

    return (
        <StyledBlockVideo
            id={`block-${block.id}`}
            data-testid={"video-block"}
            onClick={handleClick}
        >
            {block.value === "" ? (
                <StyledEmptyBlockVideo selected={selectedBlock?.id === block.id}>
                    <img src={video} alt={"block"} />
                </StyledEmptyBlockVideo>
            ) : (
                <HoverOverlayOptions menus={VideoMenus}>
                    <StyledBox css={{ width: getVideoWidth(), transition: "0.2s width" }}>
                        <StyledResponsiveVideoContainer maxWidth={maxWidth}>
                            <StyledResponsiveVideo paddingBottom={paddingBottom}>
                                {parsed}
                            </StyledResponsiveVideo>
                        </StyledResponsiveVideoContainer>
                    </StyledBox>
                </HoverOverlayOptions>
            )}
            {modalOpen && (
                <MediaModal
                    onChange={({ value, blockId }) => onChange(value, blockId!)}
                    blockId={block.id}
                    isVideo
                />
            )}
        </StyledBlockVideo>
    )
}

export default BlockVideo
