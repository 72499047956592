import styled, { css } from "styled-components"
import { StyledBox, StyledColumn, StyledSpan } from "../../../styles/styledcomponents"
import { StyledLeftBubble } from "../styles"

interface StyledFreeTextBubbleProps {
    asBubble?: boolean
}

export const StyledFreeTextBubble = styled(StyledLeftBubble)<StyledFreeTextBubbleProps>`
    padding: 0;
    background-color: unset;
    border-radius: 0;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    textarea {
        min-height: 28px;
        resize: none;
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.colors.thread.student.bubble} !important;
        box-shadow: none !important;
        border-radius: 16px 16px 2px 16px;
        padding: 12px 54px 12px 12px;
        margin: 0;
        font-size: 14px;
        transition: background-color 0.2s, color 0.2s, border-radius 0.2s, padding 0.2s, width 0.3s;
        color: ${({ theme }) => theme.colors.thread.student.inputText} !important;
    }

    textarea[disabled] {
        -webkit-text-fill-color: ${({ theme }) => theme.colors.thread.student.font};
        opacity: 1 !important;
    }

    button {
        position: absolute;
        bottom: 6px;
        right: 6px;
    }

    ${({ asBubble }) =>
        asBubble &&
        css`
            textarea {
                opacity: 1 !important;
                width: fit-content;
                min-height: unset;
                background-color: ${({ theme }) => theme.colors.thread.student.bubble} !important;
                color: ${({ theme }) => theme.colors.thread.student.font} !important;
                border-radius: ${({ theme }) => theme.rightBubbleBorderRadius};
                padding: ${({ theme }) => theme.bubbleVerticalPadding}
                    ${({ theme }) => theme.bubbleHorizontalPadding};
            }
        `}
`

export const StyledGhostBubble = styled(StyledBox)<{
    isMobile?: boolean
    disabled?: boolean
    selected?: boolean
}>`
    padding: 12px 18px;
    border: 1px dashed ${({ theme }) => theme.headlandsGray10};
    border-radius: 16px 16px 2px 16px;
    cursor: pointer;
    box-sizing: border-box;
    transition: background-color 0.3s, border 0.3s;

    ${StyledSpan} {
        color: ${({ theme }) => theme.headlandsGray10};
        transition: color 0.3s;
    }

    ${({ isMobile }) =>
        isMobile &&
        css`
            width: 100%;
            text-align: center;
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.2;
            cursor: default;
        `}

    ${({ selected }) =>
        selected &&
        css`
            opacity: 1;
            cursor: default;
            background-color: ${({ theme }) => theme.colors.thread.student.bubble};
            border: 1px solid ${({ theme }) => theme.colors.thread.student.bubble};

            ${StyledSpan} {
                color: ${({ theme }) => theme.colors.thread.student.font};
            }
        `}
`

export const StyledGhostSection = styled(StyledColumn)`
    width: 100%;
    gap: 6px;
    flex: 0 0 auto;
    scroll-snap-align: start;
    align-items: flex-end;
    justify-content: flex-end;
`
