import { QueryHookOptions, useQuery } from "@apollo/client"
import { useParams } from "react-router"
import { EnrolledProgramsThreadsQuery } from "../apollo/generated/graphql"
import { ENROLLED_PROGRAM_THREADS } from "../apollo/queries"
import { useSelectedEnrolledProgramAttribute } from "./enrolledProgram.hook"

export const useEnrolledProgramThreadsByGuid = (
    guid?: string,
    options?: Pick<QueryHookOptions, "fetchPolicy">
): {
    enrolledProgramThreads: EnrolledProgramsThreadsQuery["enrolledProgramThreads"] | undefined
    loading: boolean
} => {
    const { data, loading } = useQuery(ENROLLED_PROGRAM_THREADS, {
        ...(options || {}),
        skip: !guid,
        variables: { guid: guid! },
    })

    return {
        enrolledProgramThreads: data?.enrolledProgramThreads,
        loading,
    }
}

export const useCurrentThreadGuid = (): string => {
    const { threadGuid } = useParams<any>()
    return threadGuid
}

export const useCurrentEnrolledProgramThreadAttribute = <
    T extends keyof NonNullable<
        NonNullable<EnrolledProgramsThreadsQuery["enrolledProgramThreads"]>[0]
    >
>(
    attribute: T
): {
    attribute: NonNullable<EnrolledProgramsThreadsQuery["enrolledProgramThreads"]>[0][T] | undefined
    loading: boolean | undefined
} => {
    const { attribute: selectedProgramGuid, loading: selectedEnrolledProgramLoading } =
        useSelectedEnrolledProgramAttribute("guid")
    const { enrolledProgramThreads, loading: selectedEnrolledProgramThreadsLoading } =
        useEnrolledProgramThreadsByGuid(selectedProgramGuid)
    const currentThreadGuid = useCurrentThreadGuid()
    const currentEnrolledProgramThread = enrolledProgramThreads?.find(
        (enrolledProgramThread) => enrolledProgramThread.thread.guid === currentThreadGuid
    )

    return {
        attribute: currentEnrolledProgramThread
            ? currentEnrolledProgramThread[attribute]
            : undefined,
        loading: selectedEnrolledProgramLoading || selectedEnrolledProgramThreadsLoading,
    }
}
