import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { SubModalProps } from "./MediaModal"
import Input from "../../../components/InputNew"
import { Button } from "../../../components/Button/Button"
import { StyledSpan } from "../../../styles/styledcomponents"
import { isHTML } from "../../../utils/utils"
import { ChangeEvent, useState } from "react"

const VideoModal = ({ setBlockMediaUrl, mediaUrl, setMediaUrl }: SubModalProps) => {
    const [errorValue, setErrorValue] = useState<string | undefined>()
    const handleSave = (event: MouseEvent) => {
        event.stopPropagation()
        setBlockMediaUrl && setBlockMediaUrl(mediaUrl)
    }

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value
        if (!isHTML(value)) {
            setErrorValue(value)
        } else {
            setErrorValue(undefined)
            setMediaUrl(value)
        }
    }

    return (
        <Tabs>
            <TabList>
                <Tab>
                    <StyledSpan>Embed Code</StyledSpan>
                </Tab>
            </TabList>
            <TabPanel>
                <Input
                    type={"textArea"}
                    value={errorValue ?? mediaUrl}
                    onChange={onChange}
                    minRows={5}
                    maxRows={5}
                    data-testid={"video-src-input"}
                    placeholder={"Paste the embed code"}
                    error={!!errorValue}
                />
                <Button
                    label={"Save"}
                    data-testid={"video-src-submit"}
                    width={125}
                    buttonStyle={{ margin: "auto", marginTop: 12 }}
                    onClick={handleSave}
                    disabled={!!errorValue}
                />
            </TabPanel>
        </Tabs>
    )
}

export default VideoModal
