import { useEffect, useRef, useState } from "react"
import { StyledBox, StyledHeadlandsPopup, StyledSpan } from "../../styles/styledcomponents"
import { StyledEllipsisText, StyledTextContainer } from "./styles"
import { PopupProps } from "reactjs-popup/dist/types"
import { CSSObject } from "styled-components"

interface BaseEllipsisTextProps {
    variant: "span" | "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body1" | "body2" | "mini"
    text: string
    textStyles?: CSSObject
    containerStyles?: CSSObject
    popupOptions?: Pick<
        PopupProps,
        "position" | "mouseLeaveDelay" | "mouseEnterDelay" | "contentStyle" | "on"
    >
    disableTooltip?: boolean
    onClick?: () => void
}

interface EllipsisTextMaxWidthProps extends BaseEllipsisTextProps {
    maxWidth: number
    // avoid receiving these props
    maxHeight?: never
    maxLines?: never
}

interface EllipsisTextMaxHeightProps extends BaseEllipsisTextProps {
    maxHeight: number
    maxLines: number
    // avoid receiving this prop
    maxWidth?: never
}

type EllipsisTextProps = EllipsisTextMaxWidthProps | EllipsisTextMaxHeightProps

/*
 * This component handles the overflow ellipsis flow, this includes the following:
 * - render a normal text if it fits on the available space
 * - render a text with ellipsis if it doesn't fit on the available space
 * - render a tooltip (on hover) when the text doesn't fit (if `disableTooltip` is not `true`)
 *
 * Note: is important to provide some constraints in order to work, that is why the maxWidth or the maxHeight is
 * required
 * */
const EllipsisText = ({
    variant,
    text,
    textStyles,
    containerStyles,
    maxWidth,
    maxHeight,
    maxLines,
    popupOptions,
    disableTooltip = false,
    onClick,
}: EllipsisTextProps) => {
    const textRef = useRef<HTMLDivElement>(null)
    const [textTooltipEnabled, setTextTooltipEnabled] = useState(false)

    useEffect(() => {
        if (textRef?.current) {
            const { current } = textRef
            if (maxWidth) {
                // note: scrollWidth is a measurement of the width of an element's content, including content not visible
                setTextTooltipEnabled(current.scrollWidth > maxWidth)
            } else if (maxHeight) {
                // note: scrollHeight is a measurement of the height of an element's content, including content not visible
                setTextTooltipEnabled(current.scrollHeight > maxHeight)
            }
        }
    }, [textRef.current])

    return (
        <StyledBox
            id={"ellipsis-text"}
            css={{ maxWidth, maxHeight, ...containerStyles }}
            onClick={onClick}
        >
            <StyledHeadlandsPopup
                position={popupOptions?.position ?? "right center"}
                on={popupOptions?.on ?? ["hover"]}
                mouseEnterDelay={popupOptions?.mouseEnterDelay ?? 200}
                mouseLeaveDelay={popupOptions?.mouseLeaveDelay ?? 200}
                arrow={false}
                disabled={disableTooltip || !textTooltipEnabled}
                contentStyle={popupOptions?.contentStyle}
                trigger={
                    <StyledTextContainer>
                        <StyledEllipsisText
                            as={variant}
                            style={textStyles}
                            maxLines={maxLines || 1}
                            ref={textRef}
                        >
                            {text}
                        </StyledEllipsisText>
                    </StyledTextContainer>
                }
            >
                <StyledSpan>{text}</StyledSpan>
            </StyledHeadlandsPopup>
        </StyledBox>
    )
}

export default EllipsisText
