import styled from "styled-components"
import { StyledBox } from "../../../styles/styledcomponents"
import { StyledTopBar } from "../../../components/TopBar/styles"

interface StyledThreadTopBarProps {
    isMobile?: boolean
    libraryBarOpen?: boolean
    socialBarOpen?: boolean
    dark?: boolean
}

export const StyledThreadTopBar = styled(StyledBox)<StyledThreadTopBarProps>`
    display: flex;
    flex-direction: column;
    /*
    * We have to set a fixed width because it's not possible
    * to get a "fixed" positioned div to take it's parent width
    */
    // TODO: check if we can delete this width logic
    width: ${({ theme, isMobile, libraryBarOpen, socialBarOpen }) =>
        isMobile
            ? "100%"
            : `calc(100vw - ${theme.sidebarWidths.nav}px -
                ${libraryBarOpen ? `${theme.sidebarWidths.library}px` : "0px"} -
                ${socialBarOpen ? `${theme.sidebarWidths.social}px` : "0px"})`};
    z-index: 4;
    position: fixed;
    transition: width 0.3s ease-out;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(0, 12, 45, 0.1);
    background-color: ${({ theme, dark }) =>
        dark ? theme.colors.base.uiBgDark : theme.colors.base.uiBgBase};

    ${StyledTopBar} {
        background-color: ${({ theme, dark }) =>
            dark ? theme.colors.base.uiBgDark : theme.colors.base.uiBgBase};
    }
`
