import styled, { css } from "styled-components"
import { StyledRow } from "../../styles/styledcomponents"
import { NavLink } from "react-router-dom"

export const StyledBottomNavigationBar = styled(StyledRow)<{ hide?: boolean }>`
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    padding: 6px 30px;
    background-color: ${({ theme }) => theme.colors.base.uiBgTertiary};
    justify-content: space-around;
    box-sizing: border-box;
    border-top: solid 1px ${({ theme }) => theme.colors.base.uiBgBorder};
    height: ${({ theme }) => theme.variables.common.bottomNavbarHeight};

    * {
        opacity: 1;
        transition: opacity 0.2s;
    }

    transition: height 0.2s, padding 0.2s;

    ${({ hide }) =>
        hide &&
        css`
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
            border: none;
            * {
                opacity: 0;
            }
        `}
`

export const StyledNavLink = styled(NavLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 12px;
    background-color: ${({ theme }) => theme.colors.base.uiBgTertiary};

    svg {
        width: 24px;
        height: 24px;
        font-size: 24px !important;
        fill: ${({ theme }) => theme.colors.base.uiBgIcon};
    }

    &.active {
        background-color: ${({ theme }) => theme.colors.base.uiPrimary300};

        svg,
        path {
            fill: ${({ theme }) => theme.colors.base.uiPrimary500};
        }
    }
`
