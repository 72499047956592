import { ApolloError } from "@apollo/client"
import { capitalize } from "lodash"
import { useTheme } from "styled-components"
import { Loader } from "../../../../components/Loader"
import { StyledBox, StyledP } from "../../../../styles/styledcomponents"
import { StyledContent, StyledErrorMessage } from "../styles"
import parse from "html-react-parser"

interface AiEditPopupContentProps {
    text: string
    loading?: boolean
    error?: ApolloError
}

const AiEditPopupContent = ({ text, loading, error }: AiEditPopupContentProps) => {
    const theme = useTheme()
    return (
        <StyledContent>
            {loading ? (
                <Loader size={36} color={theme.headlandsAiColor} />
            ) : error ? (
                <StyledErrorMessage>
                    {capitalize(error.graphQLErrors[0].message)}
                </StyledErrorMessage>
            ) : (
                <StyledBox>
                    <StyledP>{parse(text)}</StyledP>
                </StyledBox>
            )}
        </StyledContent>
    )
}

export default AiEditPopupContent
