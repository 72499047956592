import { Colors } from "../../utils/colors"
import { IconProps } from "../../types"

export const ArrowDownIcon = ({
    color = Colors.headlandsBlack,
    className = "",
    onClick = () => {},
}: IconProps) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <path d="M16 8L14.59 6.59L9 12.17V0H7V12.17L1.42 6.58L0 8L8 16L16 8Z" fill={color} />
        </svg>
    )
}
