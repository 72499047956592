import { useEffect, useMemo, useState } from "react"
import ImageCarousel from "../../Carousel/ImageCarousel"
import IconButton from "../../IconButton"
import { StyledChoiceContainer, StyledSubmitRow } from "./styles"
import { StyledBody2, StyledBox, StyledRow, StyledSpan } from "../../../styles/styledcomponents"
import _, { compact } from "lodash"
import { Choice, ChoiceData, VariableType } from "../../../apollo/generated/graphql"
import { QuestionPreviewProps } from "../ThreadPreview"
import { Block, OptionPreviewMode, QuestionChoice } from "../../../types"
import { CorrectnessCheck } from "../../../creator/components/QuestionBlock"
import { useCurrentThreadField } from "../../../hooks/currentThread.hook"
import useTimeToAnswer from "../../../hooks/timeToAnswer.hook"
import { blockIsScorable, getVariableType } from "../../../utils/utils"
import { Maybe } from "graphql/jsutils/Maybe"
import { StyledChoicePollOption } from "../ChoicePollPreview/styles"
import SendIcon from "@mui/icons-material/Send"

export interface ChoicePreviewProps extends QuestionPreviewProps {
    handleAnswer: (option: Choice[], showTypingIndicator?: boolean) => void
    mode: OptionPreviewMode
    choicesAreImages?: boolean
    isMultipleSelection?: boolean
    customChoicesSelector?: (block: Block) => Maybe<QuestionChoice[]>
    choiceData?: Maybe<ChoiceData[]>
    score?: Maybe<number>
    /*
     * We use this component for both (new choice question component and old question component), on the new question choice
     * the timer logic is done inside the hoc while on the older component the timer logic must be done inside ChoicePreview.
     * That's why we receive a prop wether we has to use the timer or not
     */
    avoidTimer?: boolean
}

/*
 * This component is a choice preview.
 * It handles the following options:
 * - Single Selection Choice
 * - Multiple Selection Choice (`isMultipleSelection` prop)
 * - Single Image Selection Choice (`choicesAreImages` prop)
 * - Multiple Image Selection Choice (`isMultipleSelection` and `choicesAreImages` prop)
 * */
const ChoicePreview = ({
    bubble,
    handleAnswer,
    answer,
    scrollToBottom,
    choicesAreImages,
    isMultipleSelection,
    readonly,
    customChoicesSelector,
    score,
    avoidTimer,
    ...rest
}: ChoicePreviewProps) => {
    const [selectedOptions, setSelectedOptions] = useState<Choice[]>([])
    /*
     * These choices are from the block data (we only use it for rendering the possible choices to choose).
     * To know which one is correct we will use `choiceData` instead because is coming from the back-end
     */
    const choices = customChoicesSelector ? customChoicesSelector(bubble) : bubble?.choices
    const choiceData = rest.choiceData ?? answer?.systemResponse?.choiceData

    const threadHasScore = useCurrentThreadField("hasScore")

    const { startTimer } = useTimeToAnswer()

    // Start the timer under the right conditions
    useEffect(() => {
        if (threadHasScore && blockIsScorable(bubble) && !answer && !avoidTimer) {
            startTimer()
        }
    }, [bubble, answer, startTimer, threadHasScore, avoidTimer])

    const handleOptionSelect = (option: Choice) => {
        if (readonly) return
        const optionIsAlreadySelected = selectedOptions.find(
            (selectedOption) => selectedOption.id === option.id
        )
        // multiple selection
        if (isMultipleSelection) {
            if (optionIsAlreadySelected) {
                setSelectedOptions(
                    selectedOptions.filter((selectedOption) => selectedOption.id !== option.id)
                )
            } else {
                setSelectedOptions([...selectedOptions, option])
            }
        }
        // single selection
        else {
            if (optionIsAlreadySelected) {
                setSelectedOptions([])
            } else {
                setSelectedOptions([option])
            }
            // text single selection choice doesn't require a submit action by the user
            if (!choicesAreImages) handleAnswer([option], true)
        }
    }

    const handleSubmit = () => {
        if (readonly) return
        handleAnswer(selectedOptions, true)
    }

    const showAnswerCorrectness = useMemo(
        () => !!answer && choiceData?.some((choice) => choice.correct),
        [answer, choiceData]
    )

    const selectedOptionIDs = useMemo(() => {
        if (answer) {
            const variableType = getVariableType(bubble.type, isMultipleSelection)
            if (variableType === VariableType.Ref) {
                return compact([answer?.value?.valueID])
            }
            return compact(answer?.value?.valueIDs) || []
        } else {
            return selectedOptions.map(({ id }) => id)
        }
    }, [answer, selectedOptions])

    /*
     * Check if the option is selected or not.
     * A selected option is the one that has already been answered.
     * */
    const isOptionSelected = (option: QuestionChoice): boolean => {
        if (!answer) return false
        return !!selectedOptionIDs.some((selectedOptionID) => selectedOptionID === option.id)
    }

    /*
     * Check if the option is active or not.
     * An active option is the one that has been selected but hasn't been answered yet (only for multiple-choice)
     * */
    const isOptionActive = (option: QuestionChoice): boolean => {
        if (!isMultipleSelection || isOptionSelected(option)) return false
        return selectedOptions.some((optionSelected) => optionSelected.id === option.id)
    }

    const getOptionPercentage = (option: QuestionChoice): number | undefined => {
        if (!answer || !isOptionSelected(option)) return undefined
        return 100
    }

    const isOptionCorrect = (option: QuestionChoice): boolean => {
        return !!choiceData?.find((choice) => choice.id === option.id)?.correct
    }

    const getOptionScore = (option: QuestionChoice): number | undefined => {
        if (isOptionSelected(option) && isOptionCorrect(option) && score) return score
        return undefined
    }

    return (
        <StyledChoiceContainer choicesAreImages={choicesAreImages}>
            {!choicesAreImages ? (
                <>
                    {choices?.map((option, index) => (
                        <StyledRow
                            css={{ alignItems: "center", gap: "12px" }}
                            key={`choice-preview-choice-${index}`}
                        >
                            {/*
                             * We use this Box for "reserving" the horizontal space for the
                             * CorrectnessCheck, so that all choices have the same width
                             * eventhough only one of them will display the check
                             * */}
                            <StyledRow css={{ width: "30px", justifyContent: "flex-end" }}>
                                {showAnswerCorrectness &&
                                    (isOptionSelected(option) || isOptionCorrect(option)) && (
                                        <CorrectnessCheck
                                            correct={isOptionCorrect(option)}
                                            size={28}
                                            animated={isOptionSelected(option)}
                                            score={getOptionScore(option)}
                                            css={{
                                                opacity: isOptionSelected(option) ? 1 : 0.4,
                                            }}
                                        />
                                    )}
                            </StyledRow>
                            <StyledChoicePollOption
                                key={option.id}
                                data-testid={"radio-group-option"}
                                onClick={() => handleOptionSelect(option)}
                                disabled={!!answer}
                                as={"button"}
                                multiple={isMultipleSelection}
                                percentage={getOptionPercentage(option)}
                                selected={isOptionSelected(option)}
                                active={isOptionActive(option)}
                                readonly={readonly}
                                noPoll
                            >
                                <StyledSpan>{option.text}</StyledSpan>
                                <StyledBox id={"choice-poll-space"} />
                            </StyledChoicePollOption>
                        </StyledRow>
                    ))}
                </>
            ) : (
                <ImageCarousel
                    id={bubble.id}
                    // Remove options with no image nor text
                    imagesList={choices?.filter((option: any) => option.image_url || option.text)}
                    handleOptionSelect={handleOptionSelect}
                    scrollToBottom={scrollToBottom}
                    selectedOptionIDs={selectedOptionIDs}
                    imagesAreSelectable={!readonly}
                    disabled={!!answer}
                    choiceData={choiceData}
                    score={score}
                />
            )}
            {(isMultipleSelection || choicesAreImages) && !readonly && (
                <StyledSubmitRow active={!answer}>
                    <StyledBody2>
                        {`Choose ${isMultipleSelection ? `up to ${choices?.length}` : "1"}`}
                    </StyledBody2>
                    <IconButton
                        icon={<SendIcon fontSize={"small"} />}
                        data-testid={"submit"}
                        onClick={handleSubmit}
                        disabled={_.isEmpty(selectedOptions) || !!answer}
                        transparent={!!answer}
                    />
                </StyledSubmitRow>
            )}
        </StyledChoiceContainer>
    )
}

export default ChoicePreview
