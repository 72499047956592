import { useState, useEffect, useRef, useCallback } from "react"
import { CREATE_ATTACHMENT } from "../../../apollo/mutations"
import { useMutation } from "@apollo/client"
import { QuestionPreviewProps } from "../ThreadPreview"
import { generateUUID } from "../../../utils/utils"
import ImageUploaderPreviewMobile from "./ImageUploaderPreviewMobile"
import ImageUploaderPreviewDesktop from "./ImageUploaderPreviewDesktop"
import ImagePreview from "../ImagePreview"
import { StyledImageUploadBubble } from "./styles"

export const ACCEPTED_IMAGE_FORMATS = "image/jpeg, image/png, image/gif"

const ImageUploaderPreview = ({
    bubble,
    handleAnswer,
    scrollToBottom,
    answer,
    isMobile,
}: QuestionPreviewProps) => {
    const [mediaUrl, setMediaUrl] = useState("")
    const [uploadPending, setUploadPending] = useState(false)
    const [valid, setValid] = useState<boolean>(false)
    const [imageError, setImageError] = useState(false)
    const [image, setImage] = useState<File>()
    const imageUploadPreviewRef = useRef<HTMLDivElement>(null)
    const clientHeight = imageUploadPreviewRef.current?.clientHeight || 0
    const [minHeight, setMinHeight] = useState<number>(0)

    useEffect(() => {
        if (clientHeight > minHeight) setMinHeight(clientHeight)
    }, [clientHeight])

    const [createAttachment] = useMutation(CREATE_ATTACHMENT, {
        onCompleted: (res) => {
            const attachment = res.createAttachment
            const handleAnswerOrError = () => {
                setUploadPending(false)
                handleAnswer([
                    {
                        id: attachment.guid,
                        text: attachment.url,
                    },
                ])
            }
            // we prefetch the image before rendering
            fetch(attachment.url, { mode: "no-cors" })
                .then(handleAnswerOrError)
                .catch(handleAnswerOrError)
        },
        onError: () => {
            setImageError(true)
            setUploadPending(false)
        },
    })

    const validatorLink = useCallback(() => {
        var image = new Image()
        image.onload = function () {
            if (image.complete) {
                setValid(true)
            } else {
                setValid(false)
            }
        }
        image.onerror = function () {
            setValid(false)
            setImageError(true)
        }
        image.src = mediaUrl
        setValid(false)
    }, [mediaUrl])

    useEffect(() => {
        if (mediaUrl.length === 0) {
            setValid(false)
        }
        if (mediaUrl) {
            validatorLink()
        }
    }, [mediaUrl, validatorLink])

    /*
     * This method handles the get upload url
     * */
    const handleAddImage = (event: MouseEvent) => {
        event.stopPropagation()
        setUploadPending(true)
        image && image && createAttachment({ variables: { file: image, changes: "{}" } })
    }

    /*
     * This method handles when the user answer
     * */
    const handleAdd = (event: MouseEvent) => {
        event.stopPropagation()
        handleAnswer([
            {
                id: generateUUID(),
                text: mediaUrl,
            },
        ])
    }

    return (
        <div
            ref={imageUploadPreviewRef}
            style={{
                minHeight: `${minHeight}px`,
                width: answer ? "fit-content" : "100%",
                alignSelf: "flex-end",
            }}
        >
            {answer ? (
                <ImagePreview
                    block={{
                        ...bubble,
                        value: answer.value?.selectedChoices
                            ? answer.value?.selectedChoices[0].text || ""
                            : "",
                    }}
                    isMobile={isMobile}
                    fromUser
                    scrollToBottom={scrollToBottom}
                />
            ) : (
                <StyledImageUploadBubble isMobile={isMobile}>
                    {isMobile ? (
                        <ImageUploaderPreviewMobile
                            uploadPending={uploadPending}
                            handleAddImage={handleAddImage}
                            image={image}
                            setImage={setImage}
                            scrollToBottom={scrollToBottom}
                        />
                    ) : (
                        <ImageUploaderPreviewDesktop
                            uploadPending={uploadPending}
                            valid={valid}
                            handleAddImage={handleAddImage}
                            handleAdd={handleAdd}
                            mediaUrl={mediaUrl}
                            setMediaUrl={setMediaUrl}
                            imageError={imageError}
                            setImageError={setImageError}
                            image={image}
                            setImage={setImage}
                        />
                    )}
                </StyledImageUploadBubble>
            )}
        </div>
    )
}

export default ImageUploaderPreview
