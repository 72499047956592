import styled from "styled-components"
import { StyledBox } from "../../../styles/styledcomponents"

export const StyledGroupContainer = styled(StyledBox)<{ width?: number }>`
    flex: 1;
    background-color: ${(props) => props.theme.headlandsWhite};
    display: flex;
    flex-direction: column;
    position: relative;
    padding: ${({ theme }) =>
        `${theme.variables.creator.groupPadding.top} ${theme.variables.creator.groupPadding.right} ${theme.variables.creator.groupPadding.bottom} ${theme.variables.creator.groupPadding.left}`};
    border-radius: 3px;
    min-height: 92px; // min-height to hold a single line text and a pause
    transition: min-height 0.3s;

    .group {
        border-top: 1px dashed ${({ theme }) => theme.headlandsGray3};
        border-bottom: 1px dashed ${({ theme }) => theme.headlandsGray3};
        padding-bottom: 9px;
    }
`
