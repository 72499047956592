export const ImageCarousel = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_28251_94494)">
                <path
                    d="M1.5 4.5H4.5V12.75H1.5V4.5ZM5.25 14.25H12.75V3H5.25V14.25ZM6.75 4.5H11.25V12.75H6.75V4.5ZM13.5 4.5H16.5V12.75H13.5V4.5Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_28251_94494">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
