import { useState, useEffect, useRef, useMemo } from "react"
import SendIcon from "@mui/icons-material/Send"
import { StyledColumn, StyledSpan } from "../../../styles/styledcomponents"
import IconButton from "../../IconButton"
import { StyledFreeTextBubble, StyledGhostBubble, StyledGhostSection } from "./styles"
import { QuestionPreviewProps } from "../ThreadPreview"
import { generateUUID } from "../../../utils/utils"
import Input from "../../Input/Input"
import Carousel from "../../Carousel"
import { isEmpty } from "lodash"

const splitArray = (array: string[], chunkSize: number): string[][] => {
    const numChunks = Math.ceil(array.length / chunkSize)
    return Array.from({ length: numChunks }, (_, index) =>
        array.slice(index * chunkSize, (index + 1) * chunkSize)
    )
}

const GHOST_SECTION_SIZE = 3

const FreeTextPreview = ({
    handleAnswer,
    answer,
    isMobile,
    bubble,
    readonly,
    scrollToBottom,
}: QuestionPreviewProps) => {
    const [text, setText] = useState<string>("")
    const bubbleRef = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState<number | undefined>()

    // Scroll to bottom on mount or when an answer is submitted
    useEffect(() => scrollToBottom && scrollToBottom(), [answer])

    const ghostSections = useMemo(
        () => (bubble.suggestions ? splitArray(bubble.suggestions, GHOST_SECTION_SIZE) : []),
        [bubble.suggestions]
    )

    /*
     * If there's an answer, we check if it matches with one of the suggestions
     * We do that in order to mark the ghost bubble as selected and avoid
     * generating a new bubble for the answer below.
     */
    const ghostAnswer = useMemo(
        () => bubble.suggestions?.find((suggestion) => suggestion === answer?.value?.string),
        [bubble.suggestions, answer]
    )

    const handleChange = (newText: string) => {
        if (readonly) return
        setText(newText)
    }

    useEffect(() => {
        if (bubbleRef.current?.parentElement) {
            const percentage = isMobile ? 1 : 0.6
            const margin = isMobile ? 24 : 0
            const textWidth = calculateWidth()
            // parent width * percetage - right padding - left padding - left and right borders - left and right margins
            const bubbleMaxWidth =
                bubbleRef.current.parentElement.getBoundingClientRect().width * percentage -
                54 -
                12 -
                2 -
                margin

            setWidth(answer && textWidth < bubbleMaxWidth ? textWidth : bubbleMaxWidth)
        }
    }, [bubbleRef, answer, isMobile])

    const calculateWidth = () => {
        let textWidth: number
        const auxiliarElement: any = document.createElement("p")
        auxiliarElement.innerText = answer?.value?.string ?? ""
        auxiliarElement.setAttribute("style", "opacity:0; position:absolute")
        auxiliarElement.setAttribute("id", "text-to-test")
        bubbleRef.current?.appendChild(auxiliarElement)
        textWidth = auxiliarElement.getBoundingClientRect().width
        //@ts-ignore
        document?.getElementById("text-to-test")?.remove()
        return textWidth
    }

    const handleSubmit = (textOverride?: string) => {
        const answer = textOverride || text
        if (readonly) return
        handleAnswer(
            [
                {
                    id: generateUUID(),
                    text: answer.trim(),
                },
            ],
            true
        )
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (readonly) return
        if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) handleSubmit()
    }

    const handleSuggestion = (text: string) => {
        handleChange(text)
        handleSubmit(text)
    }

    const renderGhostSection = (suggestions: string[], sectionId: string) => (
        <StyledGhostSection id={sectionId} key={sectionId}>
            {suggestions.map((suggestion, index) => (
                <GhostBubble
                    key={suggestion + index}
                    text={suggestion}
                    onClick={handleSuggestion}
                    isMobile={isMobile}
                    disabled={!!answer}
                    selected={ghostAnswer === suggestion}
                />
            ))}
        </StyledGhostSection>
    )

    return (
        <StyledFreeTextBubble ref={bubbleRef} asBubble={!!answer}>
            <StyledColumn css={{ alignItems: "flex-end", gap: "6px", width: "100%" }}>
                {!isEmpty(ghostSections) && (
                    <Carousel
                        id={bubble.id}
                        items={ghostSections}
                        renderItem={renderGhostSection}
                        hideNavigation
                        showPagination
                        intersectionObserverOptions={{
                            threshold: [0.1, 0.9],
                            minIntersectionRatio: 0.1,
                            maxIntersectionRation: 0.9,
                        }}
                    />
                )}
                {/* We avoid displaying the input if the answer is one of the suggestions */}
                {(!bubble.hide_free_text_input || !!answer) && !ghostAnswer && (
                    <Input
                        value={answer?.value?.string || text}
                        onChange={handleChange}
                        minRows={1}
                        data-testid={"free-text-preview-input"}
                        inputStyle={{ width }}
                        disabled={!!answer}
                        // @ts-ignore
                        onKeyDown={handleKeyDown}
                        readonly={readonly}
                    />
                )}
            </StyledColumn>
            {!answer && !readonly && !bubble.hide_free_text_input && (
                <IconButton
                    data-testid={"free-text-preview-submit"}
                    icon={<SendIcon fontSize={"small"} />}
                    disabled={text.length === 0}
                    rounded
                    onClick={() => handleSubmit()}
                />
            )}
        </StyledFreeTextBubble>
    )
}

interface GhostBubbleProps {
    text: string
    onClick: (text: string) => void
    isMobile?: boolean
    disabled?: boolean
    selected?: boolean
}

export const GhostBubble = ({ text, onClick, isMobile, disabled, selected }: GhostBubbleProps) => (
    <StyledGhostBubble
        onClick={() => !disabled && onClick(text)}
        isMobile={isMobile}
        disabled={disabled}
        selected={selected}
    >
        <StyledSpan>{text}</StyledSpan>
    </StyledGhostBubble>
)

export default FreeTextPreview
