import React, { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router"

interface WithBlockNavigationPromptProps {
    shouldBlockNavigation: boolean
    onConfirm: () => Promise<boolean>
    onPromptShowCallback?: () => void
    confirmLoading?: boolean
    onErrorCallback?: () => void
}

export interface BlockNavigationPromptComponentProps {
    onCancel: () => void
    onConfirm: () => void
    confirmLoading?: boolean
}
const withBlockNavigationPrompt =
    (PromptComponent: React.FC<BlockNavigationPromptComponentProps>) =>
    ({
        shouldBlockNavigation,
        onConfirm,
        onPromptShowCallback,
        confirmLoading,
        onErrorCallback,
    }: WithBlockNavigationPromptProps) => {
        const history = useHistory()
        const [desirePath, setDesirePath] = useState<string>()
        const [showPrompt, setShowPrompt] = useState<boolean>(false)

        useEffect(() => {
            const unblockNavigation = history.block((prompt) => {
                if (shouldBlockNavigation) {
                    setDesirePath(prompt.pathname)
                    setShowPrompt(true)
                    // returning false will block the navigation
                    return false
                } else {
                    setShowPrompt(false)
                    return
                }
            })
            return () => unblockNavigation()
        }, [history, shouldBlockNavigation])

        useEffect(() => {
            if (showPrompt && onPromptShowCallback) onPromptShowCallback()
        }, [showPrompt, onPromptShowCallback])

        /*
         * The user is aware of the alert but still want to navigate to the desire path
         * without doing any other action
         * */
        const handleCancel = useCallback(() => {
            history.block(() => {})
            desirePath && history.push(desirePath)
            setShowPrompt(false)
        }, [history, desirePath])

        /*
         * The user wants to execute the 'onConfirm' action before navigating to the
         * desire path
         * */
        const handleConfirm = useCallback(() => {
            onConfirm()
                .then((shouldNavigate) => {
                    if (shouldNavigate) {
                        history.block(() => {})
                        desirePath && history.push(desirePath)
                        setShowPrompt(false)
                    }
                })
                .catch(() => {
                    onErrorCallback && onErrorCallback()
                    setShowPrompt(false)
                })
        }, [history, desirePath, onConfirm, onErrorCallback])

        if (showPrompt)
            return (
                <PromptComponent
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                    confirmLoading={confirmLoading}
                />
            )
        return null
    }

export default withBlockNavigationPrompt
