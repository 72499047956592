import { StyledBox, StyledH2, StyledSpan } from "../../../styles/styledcomponents"
import {
    StyledMoreVerticalIcon,
    StyledProgramItem,
    StyledProgramItemDivider,
    StyledProgramsBar,
    StyledProgramsBarContentContainer,
    StyledProgramsBarHeader,
} from "./styles"
import { Button } from "../../../components/Button/Button"
import defaultProgramImage from "../../../assets/images/default-image.png"
import EllipsisText from "../../../components/EllipsisText"
import { format } from "date-fns"
import PopupMenu, { PopupOptionType } from "../../../components/PopupMenu"
import { useTheme } from "styled-components"
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate"
import DeleteIcon from "@mui/icons-material/Delete"
import SearchBar from "../SearchBar"
import { useLazyQuery, useMutation } from "@apollo/client"
import { CLONE_PROGRAM, CREATE_PROGRAM, DELETE_PROGRAM } from "../../../apollo/mutations"
import { useHistory } from "react-router"
import { GET_PROGRAMS } from "../../../apollo/queries"
import { useEffect, useState } from "react"
import { useWindowHeight } from "@react-hook/window-size"
import LoaderSkeleton from "../../../common/components/LoaderSkeleton"
import { LoaderType } from "../../../types"
import { debounce, times } from "lodash"
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal"
import { addProgramToSearchList, deleteItemFromCache } from "../../../apollo/cacheHelper"
import { StyledEmptyTopBarHeight } from "../../../components/TopBar/styles"
import { useSelectedProgramGuid } from "../../../hooks/selectedProgram.hook"
import { ENROLLED_PROGRAMS } from "../../../apollo/queries"

interface ProgramItemProps {
    name: string
    imageSrc: string
    startDate?: string | null
    endDate?: string | null
    guid: string
    showDeleteConfirmationModal: () => void
}

const ProgramItem = ({
    name,
    imageSrc,
    startDate,
    endDate,
    guid,
    showDeleteConfirmationModal,
}: ProgramItemProps) => {
    const theme = useTheme()
    const history = useHistory()
    const currentProgramGuid = useSelectedProgramGuid()

    const [cloneProgram] = useMutation(CLONE_PROGRAM, {
        onCompleted: (data) => {
            const programGuid = data?.cloneProgram?.guid
            if (programGuid) {
                history.push(`/deliver/${programGuid}`)
            }
        },
        update: (cache, { data }) => {
            addProgramToSearchList(cache, data?.cloneProgram)
        },
        variables: {
            guid,
        },
    })

    const renderAvailability = () => {
        if (startDate && endDate) {
            const formatDateTime = "MMM do hh a"
            const from = format(new Date(startDate), formatDateTime)
            const to = format(new Date(endDate), formatDateTime)
            return `${from} - ${to}`
        }
        return "Available"
    }

    const redirectToProgram = () => {
        guid && history.push(`/deliver/${guid}`)
    }

    return (
        <>
            <StyledProgramItem onClick={redirectToProgram} active={guid === currentProgramGuid}>
                <StyledBox css={{ position: "relative" }}>
                    <img alt={"program-image"} src={imageSrc ?? defaultProgramImage} />
                </StyledBox>
                <StyledBox css={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <StyledBox css={{ maxWidth: 239 }}>
                        <EllipsisText
                            variant={"h4"}
                            text={name}
                            maxWidth={239}
                            popupOptions={{ position: "right center" }}
                            textStyles={{ color: theme.colors.base.uiLabelBase }}
                        />
                    </StyledBox>
                    <StyledSpan css={{ color: `${theme.colors.base.uiLabelSubtitle}!important` }}>
                        {renderAvailability()}
                    </StyledSpan>
                </StyledBox>
                <PopupMenu
                    position={"top center"}
                    on={["click"]}
                    arrow={false}
                    overlayStyle={{ backgroundColor: "transparent" }}
                    options={[
                        {
                            label: "Duplicate",
                            icon: ControlPointDuplicateIcon,
                            onClick: cloneProgram,
                            type: PopupOptionType.TEXT_AND_ICON,
                        },
                        {
                            label: "Delete",
                            icon: DeleteIcon,
                            onClick: showDeleteConfirmationModal,
                            styles: { color: `${theme.headlandsError}!important` },
                            type: PopupOptionType.TEXT_AND_ICON,
                        },
                    ]}
                    trigger={
                        <StyledMoreVerticalIcon
                            fontSize={"small"}
                            sx={{ color: theme.colors.base.uiBgIcon }}
                        />
                    }
                />
            </StyledProgramItem>
            <StyledProgramItemDivider />
        </>
    )
}

const ProgramsBar = () => {
    const theme = useTheme()
    const history = useHistory()
    const [skeletonItems, setSkeletonItems] = useState<number>(0)
    const height = useWindowHeight()
    const [programToDelete, setProgramToDelete] = useState<{
        guid: string
        name: string
    } | null>(null)

    const [getPrograms, { data: getProgramsData, loading: getProgramsLoading }] =
        useLazyQuery(GET_PROGRAMS)

    // todo review if this calc is ok
    useEffect(() => {
        setSkeletonItems(Math.trunc((height - 299) / 81))
    }, [getProgramsLoading])

    // on mount get all the programs
    useEffect(() => {
        getPrograms({ variables: { search: "" } }).catch((e) => console.error(e))
    }, [])

    const [createProgram, { loading: createProgramLoading }] = useMutation(CREATE_PROGRAM, {
        onCompleted: (data) => {
            const programGuid = data?.createProgram?.guid
            if (programGuid) {
                history.push(`/deliver/${programGuid}`)
            }
        },
        update: (cache, { data }) => {
            addProgramToSearchList(cache, data?.createProgram)
        },
    })

    const [deleteProgram, { loading: deleteProgramLoading }] = useMutation(DELETE_PROGRAM, {
        onCompleted: () => setProgramToDelete(null),
        update: (cache, { data }) => {
            if (data?.deleteProgram) deleteItemFromCache(cache, "Program", data.deleteProgram)
            setProgramToDelete(null)
        },
        refetchQueries: [ENROLLED_PROGRAMS], // to keep the enrolled programs updated
    })

    const handleDeleteProgram = () => {
        deleteProgram({
            variables: {
                guid: programToDelete!.guid,
            },
        }).catch((e) => console.error(e))
    }

    const handleSearch = (search: string) => {
        getPrograms({
            variables: {
                search: search.trim(),
            },
        }).catch((e) => console.error(e))
    }

    const debouncedSearch = debounce((search: string) => handleSearch(search), 500)

    return (
        <StyledProgramsBar>
            <StyledEmptyTopBarHeight />
            <StyledProgramsBarHeader>
                <StyledBox css={{ display: "flex", justifyContent: "space-between" }}>
                    <StyledH2 css={{ color: theme.colors.base.uiLabelTitle }}>Programs</StyledH2>
                    <Button
                        label={"Create"}
                        onClick={createProgram}
                        data-testid={"create-program"}
                        loading={createProgramLoading}
                    />
                </StyledBox>
                <SearchBar placeholder={"Search for programs"} onChange={debouncedSearch} />
            </StyledProgramsBarHeader>
            <StyledProgramsBarContentContainer id={"StyledProgramsBarContentContainer"}>
                {getProgramsLoading &&
                    times(skeletonItems, (index) => (
                        <LoaderSkeleton type={LoaderType.THREADCREATOR} withDivider key={index} />
                    ))}
                {getProgramsData?.program?.map((program: any) => (
                    <ProgramItem
                        key={program.id}
                        name={program.name}
                        guid={program.guid}
                        imageSrc={program.imageURL}
                        startDate={program.startTime}
                        endDate={program.endTime}
                        showDeleteConfirmationModal={() =>
                            program.guid &&
                            setProgramToDelete({
                                guid: program.guid,
                                name: program.name,
                            })
                        }
                    />
                ))}
            </StyledProgramsBarContentContainer>
            {!!programToDelete && (
                <DeleteConfirmationModal
                    open={true}
                    deleteItemName={programToDelete.name}
                    onClose={() => setProgramToDelete(null)}
                    onConfirm={handleDeleteProgram}
                    loading={deleteProgramLoading}
                />
            )}
        </StyledProgramsBar>
    )
}

export default ProgramsBar
