import styled from "styled-components"
import {
    StyledBody1Styles,
    StyledBody2Styles,
    StyledBox,
    StyledMiniStyles,
} from "../../styles/styledcomponents"

export const StyledTextContainer = styled(StyledBox)`
    width: 100%;
    height: 100%;

    body1 {
        ${StyledBody1Styles};
    }

    body2 {
        ${StyledBody2Styles};
    }

    mini {
        ${StyledMiniStyles};
    }
`

export const StyledEllipsisText = styled.span<{ maxLines: number }>`
    text-overflow: ellipsis;
    overflow: hidden;
    // for single line
    white-space: ${({ maxLines }) => (maxLines === 1 ? "nowrap" : "unset")};
    // for multiple lines
    -webkit-line-clamp: ${({ maxLines }) => (maxLines > 1 ? maxLines : "unset")};
    display: ${({ maxLines }) => (maxLines > 1 ? "-webkit-box" : "block")};
    -webkit-box-orient: vertical;
`
