import { useEffect, useMemo } from "react"
import { useCurrentResponseThreadField, useCurrentThreadGuid } from "./currentThread.hook"
import { useCurrentUser } from "./currentUser"
import { compact } from "lodash"
import { useLazyQuery } from "@apollo/client"
import { THREAD_PROGRESS_FOR_PROGRAM } from "../apollo/queries"
import { useSelectedProgramGuid } from "./enrolledProgram.hook"

export enum MATCH_RESULT {
    NO_MATCH = "NO_MATCH",
    USER_WON = "USER_WON",
    USER_LOST = "USER_LOST",
    TIE = "TIE",
}

const useTriviaMatchData = () => {
    const { user } = useCurrentUser()
    const challenger = useCurrentResponseThreadField("challenger")
    const programGUID = useSelectedProgramGuid()
    const threadGUID = useCurrentThreadGuid()

    const [getThreadProgress, { data, loading, refetch, called }] = useLazyQuery(
        THREAD_PROGRESS_FOR_PROGRAM
    )

    useEffect(() => {
        if (user?.id && challenger?.id && programGUID && threadGUID) {
            void getThreadProgress({
                variables: {
                    input: { programGUID, threadGUID, userIDs: [user.id, challenger.id] },
                },
            })
        }
    }, [user?.id, challenger?.id, programGUID, threadGUID])

    const leaderboardItems = useMemo(
        () => compact(data?.threadProgressForProgram),
        [data?.threadProgressForProgram]
    )

    const userScores = leaderboardItems.find((item) => item.person.id === user?.id)?.threadScore

    const challengerScores = leaderboardItems.find(
        (item) => item.person.id === challenger?.id
    )?.threadScore

    const matchResult = useMemo(() => {
        if (!userScores || !challengerScores) return MATCH_RESULT.NO_MATCH
        else if (userScores.totalPoints > challengerScores.totalPoints) return MATCH_RESULT.USER_WON
        else if (userScores.totalPoints < challengerScores.totalPoints)
            return MATCH_RESULT.USER_LOST
        else return MATCH_RESULT.TIE
    }, [challengerScores, userScores])

    const refetchThreadProgressForProgram = () => {
        //  avoids calling refetch when the query was not being trigger yet, this causes empty variables to be sent
        if (!called) return
        refetch()
    }

    return {
        user,
        challenger,
        matchResult,
        userScores,
        challengerScores,
        loading,
        refetchThreadProgressForProgram,
    }
}

export default useTriviaMatchData
