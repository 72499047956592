import { AvatarSize, UserStatus } from "../../../types"
import defaultAvatar from "../../../assets/images/default-avatar.png"
import React, { useMemo } from "react"
import { getCustomImageUrl, hashString } from "../../../utils/utils"
import { StyledAvatar } from "./styles"
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar"
import { StyledBox, StyledSpan } from "../../../styles/styledcomponents"
import { CSSObject } from "styled-components"
import { CircularProgressbarProps } from "react-circular-progressbar/dist/types"
import { User } from "../../../apollo/generated/graphql"
import { SkeletonHeadlands } from "../../../common/components/LoaderSkeleton/styles"

export type AvatarColor = {
    background: string
    font: string
}

const Colors: AvatarColor[] = [
    { background: "#888888", font: "#545454" },
    { background: "#66C3B8", font: "#378B81" },
    { background: "#EC407A", font: "#B51249" },
    { background: "#28B6F6", font: "#077CB0" },
    { background: "#BA69C8", font: "#873795" },
    { background: "#FF8A66", font: "#FF3C00" },
    { background: "#F48FB1", font: "#EB3371" },
    { background: "#F7CB4E", font: "#D7A10A" },
    { background: "#7986CC", font: "#3E4EA2" },
    { background: "#AED582", font: "#7BB33C" },
    { background: "#26C7DB", font: "#167783" },
]

interface UserAvatarProps {
    user?: Pick<User, "profileImageURL" | "firstName" | "lastName">
    size?: AvatarSize
    status?: UserStatus
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    onMouseLeave?: () => void
    styles?: Omit<CSSObject, "width" | "height">
    loading?: boolean
    showSingleLetter?: boolean
}

export const UserAvatar = ({
    user,
    size = AvatarSize.M,
    status,
    onClick,
    styles,
    loading,
    showSingleLetter,
}: UserAvatarProps) => {
    const styledAvatarProps = { size, status, style: styles }

    const imageSrc = useMemo(
        () =>
            user?.profileImageURL
                ? getCustomImageUrl(user.profileImageURL, {
                      height: size,
                      width: size,
                      crop: "face",
                      webp: true,
                  })
                : null,
        [user?.profileImageURL, size]
    )

    const color = useMemo(() => {
        if (imageSrc) return { background: "unset", font: "unset" }
        const hash = hashString(`${user?.firstName ?? ""} ${user?.lastName ?? ""}`)
        return Colors[Math.abs(hash) % (Colors.length - 1)]
    }, [user?.firstName, user?.lastName, imageSrc])

    if (loading) {
        return (
            <SkeletonHeadlands
                width={`${size}px`}
                height={`${size}px`}
                css={{ borderRadius: "50%", flexShrink: 0 }}
            />
        )
    }

    return (
        <StyledAvatar {...styledAvatarProps} $color={color} onClick={onClick}>
            {imageSrc ? (
                <img
                    alt={"avatar"}
                    src={imageSrc}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = defaultAvatar
                    }}
                />
            ) : (
                <StyledSpan>
                    {showSingleLetter
                        ? `${(user?.firstName || user?.lastName || "").charAt(0)}`
                        : `${user?.firstName?.charAt(0) || ""}${user?.lastName?.charAt(0) || ""}`}
                </StyledSpan>
            )}
        </StyledAvatar>
    )
}

interface UserAvatarWithProgressProps extends UserAvatarProps {
    progress: number
    progressBarProps?: Partial<CircularProgressbarProps>
    progressBarStyles?: {
        pathColor?: string
        trailColor?: string
        rotation?: number
    }
}

export const UserAvatarWithProgress = ({
    progress,
    progressBarProps,
    progressBarStyles,
    ...userAvatarProps
}: UserAvatarWithProgressProps) => {
    const progressRingStyles = buildStyles({
        rotation: progressBarStyles?.rotation ?? 0.375,
        strokeLinecap: "butt",
        pathColor: progressBarStyles?.pathColor ?? "url(#progress-gradient)",
        // if undefined will use default trail color
        trailColor: progressBarStyles?.trailColor,
    })
    const cssStyle = {
        width: `${(userAvatarProps.size || AvatarSize.M) + 8}px`,
        height: `${(userAvatarProps.size || AvatarSize.M) + 8}px`,
    }
    return (
        <StyledBox css={cssStyle}>
            <CircularProgressbarWithChildren
                value={progress}
                strokeWidth={10}
                styles={progressRingStyles}
                {...progressBarProps}
            >
                <UserAvatar {...userAvatarProps} />
            </CircularProgressbarWithChildren>
        </StyledBox>
    )
}
