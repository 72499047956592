import styled from "styled-components"
import { StyledBox } from "../../styles/styledcomponents"

export const StyledLoaderContainer = styled(StyledBox)`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export const StyledOverlayLoader = styled(StyledBox)`
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`

export const StyledOverlaySpinnerContainer = styled(StyledBox)`
    svg {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`
