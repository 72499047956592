import { IconProps } from "../../types"

export const ErrorIcon = ({ width = 18, height = 18, color = "#D84545" }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.22313 14.2162C3.24985 14.2162 2.66016 13.5407 2.66016 12.6647C2.66016 12.3956 2.72886 12.1208 2.88344 11.8632L7.32619 4.12273C7.61817 3.60174 8.13344 3.33838 8.66016 3.33838C9.18687 3.33838 9.69642 3.60174 9.99413 4.12273L14.4369 11.8632C14.58 12.1151 14.6602 12.3956 14.6602 12.6647C14.6602 13.5407 14.0705 14.2162 13.0972 14.2162H4.22313ZM4.22886 13.3174H13.0857C13.4808 13.3174 13.7327 13.0082 13.7327 12.659C13.7327 12.5502 13.704 12.4185 13.6411 12.2983L9.20405 4.55784C9.08382 4.34601 8.87199 4.25441 8.66016 4.25441C8.44832 4.25441 8.23077 4.34601 8.11054 4.55784L3.67352 12.2926C3.61054 12.4128 3.58764 12.5502 3.58764 12.659C3.58764 13.0082 3.8281 13.3174 4.22886 13.3174ZM8.66016 10.3575C8.3739 10.3575 8.21359 10.1914 8.20787 9.89945L8.13344 6.87655C8.12771 6.58456 8.34527 6.37273 8.65443 6.37273C8.95787 6.37273 9.18687 6.59029 9.18115 6.88227L9.101 9.89945C9.09527 10.1972 8.93497 10.3575 8.66016 10.3575ZM8.66016 12.2182C8.3281 12.2182 8.04184 11.9491 8.04184 11.6227C8.04184 11.2907 8.32237 11.0273 8.66016 11.0273C8.99794 11.0273 9.27848 11.2849 9.27848 11.6227C9.27848 11.9548 8.99222 12.2182 8.66016 12.2182Z"
                fill={color}
            />
        </svg>
    )
}
