import { Block, BlockContext, BlocksType, BlockType } from "../../../types"
import { Editor } from "../EditorScreen/Editor"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { addBlocks, findBlock, updateBlock } from "../../../redux/blocks"
import { generateUUID } from "../../../utils/utils"
import { StyledGroupContainer } from "./styles"
import { useResizeDetector } from "react-resize-detector"
import { useMemo } from "react"
import { questionTypes } from "../../../utils/consts"
import Breakpoint from "../Breakpoint"

export interface GroupProps {
    block: Block
    context?: BlockContext
}

const Group = ({ block, context }: GroupProps) => {
    const dispatch = useDispatch()
    const { width: groupWidth, ref: groupRef } = useResizeDetector()

    // Groups always have only 1 branch
    const branchId = block.branches![0].id

    const blockIds = useSelector((state: RootState) => {
        return findBlock(block.id, state.blocksReducer.blocksIndex)!
    }, shallowEqual)

    const hasInteractiveChild = useSelector((state: RootState) => {
        const childrenIds = blockIds && blockIds.branches && blockIds.branches[0].objects
        if (childrenIds) {
            const children = Object.values(childrenIds).map(
                (childId) => state.blocksReducer.blocks[childId.id]
            )
            return children.some((child) => questionTypes.includes(child.type))
        }
    })

    const handlePauseLabelChange = (newValue: string) => {
        dispatch(
            updateBlock({
                blocksType: BlocksType.BLOCKS,
                id: block.id,
                block: { pause_label: newValue },
            })
        )
    }

    const handleAddBlock = () => {
        dispatch(
            addBlocks({
                blocksType: BlocksType.BLOCKS,
                branchId: branchId,
                blocks: [
                    {
                        id: generateUUID(),
                        type: BlockType.TEXT,
                        value: "",
                    } as Block,
                ],
                index: undefined,
            })
        )
    }

    return useMemo(
        () => (
            <StyledGroupContainer id={`block-${block.id}`} ref={groupRef} width={groupWidth}>
                <Editor
                    blocksType={BlocksType.BLOCKS}
                    blockIds={blockIds && blockIds.branches ? blockIds.branches![0].objects : []}
                    branchId={branchId}
                    parentType={block.type}
                    context={context}
                />
                <div
                    className={"bottom-padding"}
                    data-testid={"bottom-padding"}
                    onClick={() => handleAddBlock()}
                />
                {!hasInteractiveChild && (
                    <Breakpoint value={block.pause_label} onChange={handlePauseLabelChange} />
                )}
            </StyledGroupContainer>
        ),
        [block, blockIds, branchId, context, handleAddBlock, groupRef, groupWidth]
    )
}

export default Group
