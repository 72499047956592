import {
    StyledAiEditContent,
    StyledAiEditItem,
    StyledAiEditItemIconContainer,
    StyledAiEditMenu,
} from "./styles"
import { StyledRow, StyledSpan } from "../../../styles/styledcomponents"
import { useTheme } from "styled-components"
import { useRef } from "react"
import { SparkleIcon } from "../../../assets/icons/SparklesIcon"
import useOnClickOutside from "../../../hooks/onClickOutside.hook"
import AutoAwesome from "@mui/icons-material/AutoAwesome"
import { AiEditMenuItem, EditType } from "../../../apollo/generated/graphql"
import { aiEditMenuOptions } from "./consts"

interface AiEditMenuProps {
    onCancel: () => void
    onSelect: (type: EditType) => void
    position?: { top: number; bottom: number }
}

const AiEditMenu = ({ onCancel, onSelect, position }: AiEditMenuProps) => {
    const theme = useTheme()
    const popupRef = useRef<HTMLDivElement>(null)

    const toolbar: HTMLDivElement | null = document.querySelector(".ql-tooltip")

    useOnClickOutside(popupRef, onCancel)

    const handleOptionSelect = (type: EditType) => {
        onSelect(type)
        onCancel()
    }

    return (
        <StyledAiEditMenu
            ref={popupRef}
            top={position?.bottom ? `${position.bottom + 6}px` : undefined}
            left={toolbar?.style?.left || "unset"}
            /* We stop propagation because if we don't, when clicking something
             * inside this Popup we loose the text highlight on the text editor
             */
            onClick={(e) => e.stopPropagation()}
        >
            <StyledRow css={{ padding: "0px 12px", alignItems: "center", gap: "6px" }}>
                <SparkleIcon color={theme.headlandsGray2} />
                <StyledSpan>AI Assist</StyledSpan>
            </StyledRow>
            <StyledAiEditContent>
                {aiEditMenuOptions.map((aiMenu) => (
                    <AiEditItem key={aiMenu.type} item={aiMenu} onSelect={handleOptionSelect} />
                ))}
            </StyledAiEditContent>
        </StyledAiEditMenu>
    )
}

interface AiEditItemProps {
    item: AiEditMenuItem
    onSelect: (type: EditType) => void
}

const AiEditItem = ({ item, onSelect }: AiEditItemProps) => {
    const theme = useTheme()
    return (
        <StyledAiEditItem onClick={() => item.type && onSelect(item.type)}>
            <StyledAiEditItemIconContainer>
                <AutoAwesome sx={{ color: theme.headlandsAiColor, fontSize: "14px" }} />
            </StyledAiEditItemIconContainer>
            <StyledSpan css={{ color: theme.headlandsGrayDark }}>{item.title}</StyledSpan>
        </StyledAiEditItem>
    )
}

export default AiEditMenu
