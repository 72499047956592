import { formatDistanceToNowStrict } from "date-fns"
import { Thread, User } from "../../../../apollo/generated/graphql"
import EllipsisText from "../../../../components/EllipsisText"
import { StyledLibraryRowImage } from "../../../../components/LibraryBar/styles"
import { StyledBody2, StyledColumn, StyledRow } from "../../../../styles/styledcomponents"
import { AvatarSize } from "../../../../types"
import { UserAvatar } from "../../../components/UserAvatar"
import { StyledCreatorThreadItem } from "./styles"

type CreatorThreadItemProps = {
    thread: Pick<Thread, "guid" | "title" | "description" | "imageURL" | "lastUpdateTime">
    instructor: Pick<User, "profileImageURL" | "firstName" | "lastName">
}

const CreatorThreadItem = ({ thread, instructor }: CreatorThreadItemProps) => {
    return (
        <StyledCreatorThreadItem to={`/creator/${thread.guid}`}>
            <StyledRow>
                <StyledColumn css={{ flex: 1 }}>
                    <EllipsisText variant="h4" text={thread?.title} maxWidth={215} />
                    <EllipsisText
                        variant="body2"
                        text={thread.description || ""}
                        maxLines={2}
                        maxHeight={37}
                    />
                </StyledColumn>
                <StyledColumn>
                    <StyledLibraryRowImage src={thread.imageURL || ""} />
                </StyledColumn>
            </StyledRow>
            <StyledRow css={{ gap: "6px" }}>
                <UserAvatar size={AvatarSize.XXXS} user={instructor} />
                <StyledBody2>{`with ${instructor?.firstName || ""} ${
                    instructor?.lastName || ""
                }`}</StyledBody2>
                <StyledBody2>·</StyledBody2>
                <StyledBody2>{`Draft saved ${formatDistanceToNowStrict(
                    new Date(thread.lastUpdateTime),
                    { addSuffix: true }
                )}`}</StyledBody2>
            </StyledRow>
        </StyledCreatorThreadItem>
    )
}

export default CreatorThreadItem
