/*
 * Here you should find all the common styled components used across the app,
 * those that are likely to be extended
 * */
import styled, {
    createGlobalStyle,
    css,
    css as styledComponentCss,
    CSSObject,
} from "styled-components"
import Popup from "reactjs-popup"
import { ExpandMore } from "@mui/icons-material"
import { getFontName } from "../../utils/utils"
import { ThreadFont } from "../../types"

export interface CustomCssProps {
    css?: CSSObject
}

export const FontOverride = (fontFamily: string) => css`
    html,
    body,
    div,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    input,
    textarea,
    button,
    blockquote,
    a,
    small,
    strike,
    strong,
    b,
    u,
    i,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    table,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td {
        font-family: ${fontFamily};
    }
`

export const GlobalStyles = createGlobalStyle<{ isMobile?: boolean }>`
    ${({ theme }) =>
        css`
            ${FontOverride(theme.fonts.thread)}
        `}

`

/*
 * Box container to add style
 * */
export const StyledBox = styled.div<CustomCssProps>`
    && {
        ${(propsTest) => styledComponentCss`${propsTest.css}`}
    }
`

export const StyledGrid = styled(StyledBox)`
    display: grid;
`

export const StyledRow = styled(StyledBox)`
    display: flex;
    flex-direction: row;
`

export const StyledColumn = styled(StyledBox)`
    display: flex;
    flex-direction: column;
`
interface TypographyProps extends CustomCssProps {
    useNewDesign?: boolean
}

/*
 * Typographies
 * (some of them had default styles according to new design system)
 * */

export const StyledH1Styles = css`
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
`
export const StyledH2Styles = css`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
`
export const StyledH3Styles = css`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
`
export const StyledH4Styles = css`
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
`
export const StyledH5Styles = css`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
`
export const StyledBody1Styles = css`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`
export const StyledBody2Styles = css`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
`

export const StyledMiniStyles = css`
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
`
export const StyledH1 = styled.h1<TypographyProps>`
    ${({ useNewDesign }) =>
        useNewDesign &&
        css`
            && {
                ${StyledH1Styles};
            }
        `}
    ${(props) => styledComponentCss`${props.css}`}
`
export const StyledH2 = styled.h2<TypographyProps>`
    ${({ useNewDesign }) =>
        useNewDesign &&
        css`
            && {
                ${StyledH2Styles};
            }
        `}

    ${(props) => styledComponentCss`${props.css}`}
`
export const StyledH3 = styled.h3<TypographyProps>`
    ${({ useNewDesign }) =>
        useNewDesign &&
        css`
            && {
                ${StyledH3Styles};
            }
        `}

    ${(props) => styledComponentCss`${props.css}`}
`
export const StyledH4 = styled.h4<TypographyProps>`
    ${({ useNewDesign }) =>
        useNewDesign &&
        css`
            && {
                ${StyledH4Styles};
            }
        `}

    ${(props) => styledComponentCss`${props.css}`}
`

export const StyledH5 = styled.h5<TypographyProps>`
    ${({ useNewDesign }) =>
        useNewDesign &&
        css`
            && {
                ${StyledH5Styles};
            }
        `}
    ${(props) => styledComponentCss`${props.css}`}
`
export const StyledH6 = styled.h6<TypographyProps>`
    ${(props) => styledComponentCss`${props.css}`}
`

export const StyledBody1 = styled.p<TypographyProps>`
    ${StyledBody1Styles};
    ${(props) => styledComponentCss`${props.css}`}
`

export const StyledBody2 = styled.p<TypographyProps>`
    ${StyledBody2Styles};
    ${(props) => styledComponentCss`${props.css}`}
`
export const StyledMini = styled.span<TypographyProps>`
    ${StyledMiniStyles};
    ${(props) => styledComponentCss`${props.css}`}
`

export const StyledP = styled.p<CustomCssProps>`
    ${(props) => styledComponentCss`${props.css}`}
`

export const StyledSpan = styled.span<CustomCssProps>`
    ${(props) => styledComponentCss`${props.css}`}
`

export const StyledImage = styled.img<CustomCssProps>`
    ${(props) => styledComponentCss`${props.css}`}
`

export const StyledHr = styled.hr<CustomCssProps>`
    display: block;
    border: 0;
    border-top: solid 1px;
    height: 0;
    ${(props) => styledComponentCss`${props.css}`}
`

export const StyledHeadlandsPopup = styled(Popup)<{ light?: boolean }>`
    &-content {
        max-height: 60vh;
        overflow-y: auto;
        max-width: 210px;
        box-shadow: ${({ theme }) => theme.boxShadow1};
        background-color: ${({ theme, light }) =>
            light ? theme.headlandsWhite : theme.headlandsGrayDark};
        padding: 6px 12px;
        border-radius: 8px;

        span,
        p {
            color: ${({ theme, light }) =>
                light ? theme.headlandsGrayDark : theme.headlandsWhite};
            font-size: 14px;
        }
        .bold {
            // todo check if these props are necessary
            color: ${({ theme, light }) =>
                light ? theme.headlandsGrayDark : theme.headlandsWhite};
            font-size: 14px;
            font-weight: bold;
        }
    }
`

export const StyledBlockContent = styled.div<{ disableHover?: boolean }>`
    flex: 1;
    padding: 12px;
    border-radius: 16px;
    background-color: ${(props) => props.theme.headlandsWhite};
    display: flex;
    flex-direction: column;
    position: relative;

    ${({ disableHover }) =>
        !disableHover &&
        styledComponentCss`
        &:hover {
            background-color: ${(props) => props.theme.headlandsGray6};
        }
    `}
`

export const StyledChunkContent = styled(StyledBox)`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.headlandsWhite};
    padding: ${({ theme }) =>
        `${theme.variables.creator.groupPadding.top} ${theme.variables.creator.groupPadding.right} ${theme.variables.creator.groupPadding.bottom} ${theme.variables.creator.groupPadding.left}`};
    border-radius: 3px;
    padding: 12px;
`

/*
 * This styled component is meant to be use when having a scrollable content, but we don't want to show the scroll.
 * This component SHOULD NOT be exported, we should use `HorizontalScrollableContentWithHiddenScroll` or
 * `VerticalScrollableContentWithHiddenScroll` instead.
 * */
const ScrollableContentWithHiddenScroll = css`
    overflow: hidden;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`

/*
 * An scrollable content on the X axis without the scroll bar
 * */
export const HorizontalScrollableContentWithHiddenScroll = css`
    ${ScrollableContentWithHiddenScroll};
    overflow-x: auto;
    overflow-y: hidden;
`

/*
 * An scrollable content on the Y axis without the scroll bar
 * */
export const VerticalScrollableContentWithHiddenScroll = css`
    ${ScrollableContentWithHiddenScroll};
    overflow-x: hidden;
    overflow-y: auto;
`

export const DisableTextSelection = css`
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`

export const StyledChevron = styled(ExpandMore)<{ open?: boolean }>`
    font-size: 24px;
    transition: transform 0.2s !important;

    ${({ open }) =>
        open &&
        css`
            transform: rotate(-180deg);
        `}
`
