import { StyledSpan } from "../../../styles/styledcomponents"
import { StyledImageContainer, StyledImageHelpTextContainer, StyledImageIcon } from "./styles"
import defaultImage from "../../../assets/images/program-image-default.png"
import { ChangeEvent, useRef } from "react"
import { DeliverAttachment } from "../../../types"
import { useMutation } from "@apollo/client"
import { CREATE_ATTACHMENT } from "../../../apollo/mutations"
import { OverlayOpacityLoader } from "../../../components/Loader"

interface ImageUploadProps {
    imageUrl?: string
    // todo delete this image change once ContentCard is integrated
    onImageChange?: (newImageUrl: string) => void
    width?: number
    height?: number
    onImageUpload?: (attachment: DeliverAttachment) => void
    loading?: boolean
}

const ImageUpload = ({
    imageUrl,
    onImageChange,
    width,
    height,
    onImageUpload,
    loading,
}: ImageUploadProps) => {
    const imageInputRef = useRef<HTMLInputElement>(null)

    const [createAttachment, { loading: createAttachmentLoading }] = useMutation(
        CREATE_ATTACHMENT,
        {
            onCompleted: (data) => {
                onImageUpload && onImageUpload(data.createAttachment)
            },
        }
    )

    const onImageClick = () => imageInputRef?.current?.click()

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files ? event?.target?.files[0] : null
        if (file) {
            if (createAttachment) void createAttachment({ variables: { file } })
            else if (onImageChange) onImageChange(URL.createObjectURL(file))
        }
    }

    return (
        <OverlayOpacityLoader loading={createAttachmentLoading || !!loading}>
            <StyledImageContainer onClick={onImageClick} width={width} height={height}>
                <img alt={"image"} src={imageUrl ?? defaultImage} />
                <StyledImageHelpTextContainer>
                    <StyledImageIcon />
                    <StyledSpan>
                        Upload an <strong>Image</strong>
                    </StyledSpan>
                </StyledImageHelpTextContainer>
                <input
                    id={"upload-image-input"}
                    type={"file"}
                    accept={"image/png, image/jpeg"}
                    ref={imageInputRef}
                    onChange={handleImageUpload}
                    hidden
                />
            </StyledImageContainer>
        </OverlayOpacityLoader>
    )
}

export default ImageUpload
