import { Slide, ToastOptions, toast } from "react-toastify"
import Toast, { ToastComponentProps } from "../components/Toast"
import { SM_BREAKPOINT } from "../utils/consts"
import { useTheme } from "styled-components"
import { ReactText } from "react"
import { omit } from "lodash"

const width = window.window.outerWidth

const defaultOptions = (options: ToastOptions): ToastOptions => ({
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    ...options,
    style: {
        color: "white",
        borderRadius: width < SM_BREAKPOINT ? "0 0 8px 8px" : "8px",
        width: width < SM_BREAKPOINT ? "100%" : "fit-content",
        maxWidth: width < SM_BREAKPOINT ? "none" : "600px",
        ...options.style,
    },
})

interface ShowToastBaseProps {
    options?: ToastOptions
}

interface ShowIconAndMessageToastProps extends ShowToastBaseProps {
    icon?: JSX.Element
    message: string
    content?: never
}

interface ShowRawToastProps extends ShowToastBaseProps {
    content: JSX.Element
    icon?: never
    message?: never
}

export const useToast = () => {
    const theme = useTheme()

    // The ReactText return type is for the toast id that the function returns
    const showDefaultToast = (
        toastComponentProps: ToastComponentProps,
        options: ToastOptions
    ): ReactText =>
        toast(
            Toast({
                ...toastComponentProps,
            }),
            defaultOptions(options)
        )
    const showToast = ({ options, ...rest }: ShowIconAndMessageToastProps | ShowRawToastProps) =>
        showDefaultToast(rest, {
            style: { backgroundColor: theme.headlandsPrimaryColor, ...options?.style },
            ...omit(options, "style"),
        })

    const showErrorToast = ({
        options,
        ...rest
    }: ShowIconAndMessageToastProps | ShowRawToastProps) =>
        showDefaultToast(rest, {
            style: { backgroundColor: theme.headlandsError },
            ...options,
        })

    return { showToast, showErrorToast }
}
