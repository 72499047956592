import { CSSObject, useTheme } from "styled-components"
import { StyledSidebarToggle } from "./styles"
import { CloseSidebarIcon } from "../../assets/icons/CloseSidebarIcon"
import { useMemo } from "react"
import useLayout from "../../hooks/layout.hook"

export enum SidebarTogglePosition {
    right,
    left,
}

export enum SidebarToggleVariant {
    inner,
    outer,
}

interface SidebarToggleBaseProps {
    onClick: () => void
    sidebarWidth: string
    position: SidebarTogglePosition
    sidebarOpen?: boolean
    variant?: SidebarToggleVariant
    icon?: JSX.Element
    size?: string
    enabledTransition?: boolean
    // should be use when position need to account another sidebar width (multiple sidebars)
    initialHorizontalPosition?: string
    styles?: Omit<CSSObject, "width" | "height" | "top" | "bottom" | "right" | "left">
    withBorder?: boolean
}

interface SidebarToggleRightProps extends SidebarToggleBaseProps {
    position: SidebarTogglePosition.right
    rightPadding: string
    leftPadding?: never
}

interface SidebarToggleLeftProps extends SidebarToggleBaseProps {
    position: SidebarTogglePosition.left
    leftPadding: string
    rightPadding?: never
}

const SidebarToggle = ({
    onClick,
    sidebarWidth,
    sidebarOpen,
    position,
    variant = SidebarToggleVariant.inner,
    icon,
    enabledTransition = true,
    size,
    initialHorizontalPosition = "0px",
    styles,
    leftPadding,
    rightPadding,
    withBorder,
}: SidebarToggleRightProps | SidebarToggleLeftProps) => {
    const theme = useTheme()
    const { isMobile } = useLayout()

    const leftPosition = useMemo(() => {
        if (position === SidebarTogglePosition.left) {
            if (sidebarOpen) {
                // inner variant
                if (variant === SidebarToggleVariant.inner) {
                    return `calc(${sidebarWidth} - ${leftPadding} - ${theme.variables.common.fabIconWidth})`
                }
                // outer variant
                return `calc(${sidebarWidth} + ${initialHorizontalPosition} + ${leftPadding})`
            }
            return `calc(${leftPadding} + ${initialHorizontalPosition})`
        }
        return null
        // theme.variables.common.fabIconWidth dependency is not necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isMobile,
        position,
        sidebarOpen,
        sidebarWidth,
        leftPadding,
        variant,
        initialHorizontalPosition,
    ])

    const rightPosition = useMemo(() => {
        if (position === SidebarTogglePosition.right) {
            if (isMobile) return "18px"
            if (sidebarOpen) {
                // inner variant
                if (variant === SidebarToggleVariant.inner) {
                    return `calc(${sidebarWidth} - ${rightPadding} - ${theme.variables.common.fabIconWidth})`
                }
                // outer variant
                return `calc(${sidebarWidth} + ${initialHorizontalPosition} + ${rightPadding})`
            }
            return `calc(${rightPadding} + ${initialHorizontalPosition})`
        }
        return null
        // theme.variables.common.fabIconWidth dependency is not necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isMobile,
        position,
        sidebarOpen,
        sidebarWidth,
        rightPadding,
        variant,
        initialHorizontalPosition,
    ])

    const topPosition = useMemo(() => {
        // inner variant
        if (variant === SidebarToggleVariant.inner) {
            return `calc(
                ${theme.variables.common.topBarHeight} * 0.5 -
                ${theme.variables.common.fabIconHeight} * 0.5
            )`
        }
        // outer variant
        return `calc(${theme.variables.common.topBarHeight} + 16px)`

        // theme.variables.common.fabIconWidth dependency is not necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variant])

    return (
        <StyledSidebarToggle
            variant={variant}
            enabledTransition={enabledTransition}
            size={size}
            style={styles}
            left={leftPosition}
            right={rightPosition}
            top={topPosition}
            isMobile={isMobile}
            onClick={onClick}
            withBorder={withBorder}
        >
            {icon ? icon : <CloseSidebarIcon />}
        </StyledSidebarToggle>
    )
}

export default SidebarToggle
