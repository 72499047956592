import "./EmojiPicker.scss"
import { useEffect, useMemo, useRef, useState } from "react"
import useOnClickOutside from "../../hooks/onClickOutside.hook"
import { Emoji, emojiIndex, BaseEmoji } from "emoji-mart"
import classNames from "classnames"
import useStateRef from "../../hooks/stateRef.hook"
import { StyledBox } from "../../styles/styledcomponents"
import { useTheme } from "styled-components"

interface EmojiPickerProps {
    handleEmoji: (emoji: string) => void
    onRequestClose: () => void
    position: { top: number; left: number; bottom?: number }
    search?: string
}

const EMOJIS_PER_ROW = 7

const EmojiPicker = ({ handleEmoji, onRequestClose, position, search }: EmojiPickerProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [positionModal, setPositionModal] = useState<{ vertical: string; position: number }>({
        vertical: "top",
        position: position.top,
    })

    const containsSpaces = useMemo(() => search?.includes(" ") || search?.includes("\n"), [search])

    const [cursor, setCursor, cursorRef] = useStateRef(0)

    const theme = useTheme()

    useOnClickOutside(wrapperRef, onRequestClose)

    const handleEmojiClick = (emoji: BaseEmoji) => {
        handleEmoji(emoji.native)
    }

    const emojis = search
        ? emojiIndex.search(search)
        : // @ts-ignore
          Object.values(emojiIndex.emojis).map((emoji) => (emoji.id ? emoji : emoji["1"]))

    useEffect(() => {
        if (wrapperRef.current) {
            const wrapperTop = wrapperRef.current?.getBoundingClientRect().top
            const wrapperHeight = wrapperRef.current?.getBoundingClientRect().height
            if (wrapperTop - wrapperHeight - 100 > 0) {
                // Should appear on top
                setPositionModal({
                    vertical: "bottom",
                    position: position.bottom ? window.innerHeight - position.bottom + 30 : 30,
                })
            } else {
                // Should appear on bottom
                setPositionModal({ vertical: "top", position: position.top + 20 })
            }
        }
    }, [wrapperRef])

    useEffect(() => {
        const currentEmoji = emojis && emojis[cursor]
        const emojiElement = currentEmoji && document.getElementById(`emoji-${currentEmoji.id}`)
        if (emojiElement) emojiElement.scrollIntoView({ behaviour: "smooth", block: "nearest" })
    }, [cursor])

    const onKeyDown = (event: any) => {
        switch (event.key) {
            case "Escape":
                onRequestClose()
                break
            case "Enter":
                event.preventDefault()
                emojis && handleEmojiClick(emojis[cursorRef.current])
                break
            case "ArrowDown":
                event.preventDefault()
                if (emojis && cursorRef.current < emojis.length - 1) {
                    const newCursor = cursorRef.current + EMOJIS_PER_ROW
                    if (newCursor < emojis.length - 1) {
                        setCursor(newCursor)
                    } else {
                        setCursor(emojis.length - 1)
                    }
                }
                break
            case "ArrowUp":
                event.preventDefault()
                if (emojis && cursorRef.current > 0) {
                    const newCursor = cursorRef.current - EMOJIS_PER_ROW
                    if (newCursor > 0) {
                        setCursor(newCursor)
                    } else {
                        setCursor(0)
                    }
                }
                break
            case "ArrowLeft":
                event.preventDefault()
                if (cursorRef.current > 0) {
                    setCursor(cursorRef.current - 1)
                }
                break
            case "ArrowRight":
                event.preventDefault()
                if (emojis && cursorRef.current < emojis.length - 1) {
                    setCursor(cursorRef.current + 1)
                }
                break
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown)
        return () => document.removeEventListener("keydown", onKeyDown)
    }, [search])

    useEffect(() => {
        if (!emojis?.length || containsSpaces) onRequestClose()
    }, [emojis, containsSpaces])

    if (!emojis?.length || containsSpaces) return null

    return (
        <div
            className={classNames("emoji-picker", { bottom: positionModal.vertical === "top" })}
            ref={wrapperRef}
            style={{
                left: position.left,
                top: positionModal.vertical === "top" ? positionModal.position : "auto",
                bottom: positionModal.vertical === "bottom" ? positionModal.position : "auto",
            }}
        >
            {emojis.map((emoji: any, index: number) => (
                <StyledBox
                    id={`emoji-${emoji.id}`}
                    css={{
                        borderRadius: theme.borderRadius1,
                        backgroundColor: cursor === index ? theme.headlandsGray4 : "unset",
                    }}
                >
                    <Emoji
                        key={emoji.id}
                        emoji={emoji}
                        size={24}
                        onClick={() => handleEmojiClick(emoji)}
                    />
                </StyledBox>
            ))}
        </div>
    )
}

export default EmojiPicker
