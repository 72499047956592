import { useEffect, useState } from "react"
import FingerprintJS from "@fingerprintjs/fingerprintjs"

const useDeviceId = () => {
    const [loading, setLoading] = useState(true)
    const [deviceId, setDeviceId] = useState<string | undefined>()

    useEffect(() => {
        try {
            FingerprintJS.load()
                .then((fp) => fp.get())
                .then((result) => {
                    setLoading(false)
                    setDeviceId(result.visitorId)
                })
        } catch {
            setLoading(false)
            setDeviceId("")
        }
    }, [])

    return { loading, deviceId }
}

export default useDeviceId
