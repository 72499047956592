import { StyledTopBar } from "./styles"
import React, { MutableRefObject } from "react"
import { StyledH2, StyledH4 } from "../../styles/styledcomponents"
import { CSSObject } from "styled-components"
import useLayout from "../../hooks/layout.hook"

type TopBarProps = {
    title?: string
    extraRightPadding?: boolean
    extraLeftPadding?: boolean
    styles?: CSSObject
    ref?: MutableRefObject<HTMLDivElement>
}

const TopBar: React.FC<TopBarProps> = ({
    title,
    extraRightPadding,
    extraLeftPadding,
    children,
    styles,
    ref,
}) => {
    const { isMobile } = useLayout()
    return (
        <StyledTopBar
            extraRightPadding={extraRightPadding}
            extraLeftPadding={extraLeftPadding}
            style={styles}
            ref={ref}
            isMobile={isMobile}
        >
            {children && children}
            {title ? (
                isMobile ? (
                    <StyledH4 useNewDesign css={{ marginLeft: "auto", marginRight: "auto" }}>
                        {title}
                    </StyledH4>
                ) : (
                    <StyledH2>{title}</StyledH2>
                )
            ) : null}
        </StyledTopBar>
    )
}
export default TopBar
