import { TypedDocumentNode, useLazyQuery } from "@apollo/client"
import { debounce, isEmpty } from "lodash"
import { useCallback, useEffect, useState } from "react"
import useInfiniteScroll from "react-infinite-scroll-hook"

const DEFAULT_PAGE_SIZE = 20
const DEFAULT_SEARCH_WAIT = 400

type UserPaginationProps<TData, TVariables, TMapItem> = {
    query: TypedDocumentNode<TData, TVariables>
    pageSize?: number
    searchWait?: number
    mapData: (data: TData) => TMapItem[]
    mapVariables: (params: MapVariablesParams) => TVariables
}

type MapVariablesParams = {
    pageSize: number
    pageOffset: number
    search: string
}

const usePagination = <TData, TVariables, TMapItem>({
    query,
    pageSize = DEFAULT_PAGE_SIZE,
    searchWait = DEFAULT_SEARCH_WAIT,
    mapData,
    mapVariables,
}: UserPaginationProps<TData, TVariables, TMapItem>) => {
    const [endReached, setEndReached] = useState(false)
    const [items, setItems] = useState<TMapItem[]>([])
    const [pageOffset, setPageOffset] = useState(0)
    const [search, setSearch] = useState("")

    const [loadItems, { loading }] = useLazyQuery(query, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            const mappedData = mapData(data)
            if (isEmpty(mappedData)) setEndReached(true)
            else setItems(items.concat(mappedData))
        },
    })

    const getNextPage = () => {
        loadItems({
            variables: mapVariables({
                pageSize,
                pageOffset: pageOffset + pageSize,
                search: search.trim(),
            }),
        })
        setPageOffset(pageOffset + pageSize)
    }

    const delaySearch = useCallback(
        debounce(() => {
            loadItems({
                variables: mapVariables({
                    pageSize,
                    pageOffset: 0,
                    search: search.trim(),
                }),
                onCompleted: (data) => {
                    const mappedData = mapData(data)
                    if (isEmpty(mappedData)) setEndReached(true)
                    else setItems(mappedData)
                },
            })
            setPageOffset(pageSize)
        }, searchWait),
        [search]
    )

    useEffect(() => {
        delaySearch()
        return delaySearch.cancel
    }, [search, delaySearch])

    return {
        items,
        loading,
        endReached,
        getNextPage,
        setSearch,
    }
}

export const useInfinitiveScrollPagination = <TData, TVariables, TMapItem>({
    query,
    pageSize = DEFAULT_PAGE_SIZE,
    searchWait = DEFAULT_SEARCH_WAIT,
    mapData,
    mapVariables,
}: UserPaginationProps<TData, TVariables, TMapItem>) => {
    const { items, loading, endReached, getNextPage, setSearch } = usePagination({
        query,
        pageSize,
        searchWait,
        mapData,
        mapVariables,
    })

    const [endRef] = useInfiniteScroll({
        loading,
        hasNextPage: !endReached,
        onLoadMore: getNextPage,
    })

    return {
        items,
        loading,
        endReached,
        endRef,
        setSearch,
    }
}

export default usePagination
