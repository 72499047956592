import { Colors } from "../../utils/colors"
import { IconProps } from "../../types"

export const AdviceIcon = ({
    color = Colors.headlandsBlack,
    className = "",
    onClick = () => {},
}: IconProps) => {
    return (
        <svg
            width="18"
            height="15"
            viewBox="0 0 18 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <path
                d="M0.75 14.75H17.25L9 0.5L0.75 14.75ZM9.75 12.5H8.25V11H9.75V12.5ZM9.75 9.5H8.25V6.5H9.75V9.5Z"
                fill={color}
            />
        </svg>
    )
}
