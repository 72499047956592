import {
    StyledHidableBlockWrapper,
    StyledHidableBlockOverlay,
    StyledVisibilityOffIcon,
} from "./styles"

export const withHidableWrapper = (component: JSX.Element) => (hidden?: boolean) => {
    return (
        <StyledHidableBlockWrapper className={"StyledHidableBlockWrapper"} hidden={hidden}>
            {component}
            {hidden && (
                <StyledHidableBlockOverlay>
                    <StyledVisibilityOffIcon />
                </StyledHidableBlockOverlay>
            )}
        </StyledHidableBlockWrapper>
    )
}
