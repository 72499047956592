import React, { useCallback, useRef, useState } from "react"
import {
    StyledFullscreen,
    StyledFullScreenImage,
    StyledCircularButton,
    StyledCloseIcon,
    StyledNormalSizeImage,
    StyledFullScreenImageContainer,
    StyledMaximizeButton,
    StyledFullscreenIcon,
} from "./styles"
import ReactDOM from "react-dom"
import { useWindowWidth } from "@react-hook/window-size"
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom"

export enum ImageZoomType {
    BUTTON = "BUTTON",
    IMAGE = "IMAGE",
    NONE = "NONE",
}

interface FullscreenImagesProps {
    src: string
    scrollToBottom?: () => void
    onImageLoad?: () => void
    styles?: React.CSSProperties
    id?: string
    zoomType?: ImageZoomType
    onClick?: () => void
}

const FullscreenImages = ({
    src,
    scrollToBottom,
    onImageLoad,
    styles,
    id,
    zoomType = ImageZoomType.IMAGE,
    onClick,
}: FullscreenImagesProps) => {
    const [openFullScreen, setOpenFullScreen] = useState<boolean>(false)
    const width = useWindowWidth()
    const imgRef = useRef<HTMLImageElement>(null)

    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef
        if (img) {
            const value = make3dTransformValue({ x, y, scale })

            img.style.setProperty("transform", value)
        }
    }, [])

    const onImageClick = () => {
        if (zoomType === ImageZoomType.IMAGE) setOpenFullScreen(true)
        else if (onClick) onClick()
    }

    const onLoad = () => {
        if (onImageLoad) onImageLoad()
        else if (scrollToBottom) scrollToBottom()
    }

    return (
        <StyledFullScreenImageContainer>
            <StyledNormalSizeImage
                style={styles}
                src={src}
                onLoad={onLoad}
                id={id}
                alt={"image-preview"}
                onClick={onImageClick}
                zoomType={zoomType}
            />
            {zoomType === ImageZoomType.BUTTON && (
                <StyledMaximizeButton onClick={() => setOpenFullScreen(true)}>
                    <StyledFullscreenIcon />
                </StyledMaximizeButton>
            )}
            {ReactDOM.createPortal(
                openFullScreen && (
                    <StyledFullscreen>
                        <StyledCircularButton onClick={() => setOpenFullScreen(!openFullScreen)}>
                            <StyledCloseIcon />
                        </StyledCircularButton>
                        <QuickPinchZoom
                            onUpdate={onUpdate}
                            minZoom={0.9}
                            lockDragAxis={width < 600}
                        >
                            <StyledFullScreenImage
                                src={src}
                                ref={imgRef}
                                alt={"full-screen-image"}
                            />
                        </QuickPinchZoom>
                    </StyledFullscreen>
                ),
                document.body
            )}
        </StyledFullScreenImageContainer>
    )
}

export default FullscreenImages
