import styled, { css } from "styled-components"
import {
    StyledBody1Styles,
    StyledBody2,
    StyledBox,
    StyledColumn,
    StyledRow,
    StyledSpan,
} from "../../styles/styledcomponents"

export const StyledInputLabel = styled(StyledSpan)`
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.base.uiLabelDisabled};
`

export const StyledInputContainer = styled(StyledBox)<{ hasIcon?: boolean; error?: boolean }>`
    display: flex;
    flex-direction: column;

    input,
    textarea {
        ${StyledBody1Styles};

        box-sizing: border-box;
        background-color: ${({ theme, error }) =>
            error
                ? theme.utils.addOpacityToHex(theme.colors.base.uiError500, 0.2)
                : theme.colors.base.uiBgBase};
        border-radius: ${({ theme }) => theme.borderRadius1};
        border: solid 1px
            ${({ theme, error }) => (error ? theme.colors.base.uiError500 : theme.headlandsGray8)};
        padding: 6px 12px;

        &:focus,
        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 4px
                ${({ theme, error }) =>
                    error
                        ? theme.utils.addOpacityToHex(theme.colors.base.uiError500, 0.25)
                        : theme.utils.addOpacityToHex(theme.colors.base.uiPrimary500, 0.25)};
            border: 1px solid
                ${({ theme, error }) =>
                    error ? theme.colors.base.uiError500 : theme.colors.base.uiPrimary500};
        }

        &:disabled,
        &:read-only {
            cursor: not-allowed;
            pointer-events: unset;
            background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
            color: ${({ theme }) => theme.colors.base.uiLabelDisabled};
        }

        &::placeholder {
            color: ${({ theme, error }) =>
                error ? theme.colors.base.uiError400 : theme.colors.base.uiLabelPlaceholder};
        }

        ${({ error }) =>
            error &&
            css`
                color: ${({ theme }) => theme.colors.base.uiError500};
            `}
    }

    input {
        height: 32px;
    }

    textarea {
        resize: none;
    }

    ${({ hasIcon }) =>
        hasIcon &&
        css`
            position: relative;

            input,
            textarea {
                padding-left: 25px;
            }
        `}

    ${({ error }) =>
        error &&
        css`
            ${StyledIconContainer} {
                color: ${({ theme }) => theme.colors.base.uiError500};
            }
        `}
`
// Right now the styled input has no styles, just added for selection purposes (selectors inside other styled components)
export const StyledInput = styled.input``

export const StyledIconContainer = styled(StyledColumn)`
    position: absolute;
    left: 6px;
    color: ${({ theme }) => theme.colors.base.uiBgIcon};

    /* Vertically center absolutely positioned element */
    top: 0;
    bottom: 0;
    margin-bottom: auto;
    margin-top: auto;
    justify-content: center;
`

export const StyledErrorMessageRow = styled(StyledRow)`
    gap: 3px;
    align-items: center;

    ${StyledBody2} {
        color: ${({ theme }) => theme.colors.base.uiError500};
    }
`
