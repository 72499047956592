export const Confetti = () => {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4.875 1.625C6.92371 1.91078 7.51792 2.6972 7.125 5.75" stroke="white" />
            <path
                d="M14.9902 12.5051C14.8399 10.4421 14.0942 9.79739 11.0222 9.98863"
                stroke="white"
            />
            <path
                d="M9.375 7.625L10.875 6.125H13.125L12.75 3.875L14.625 4.25V2.375L15.375 1.25"
                stroke="white"
            />
            <path
                d="M10.125 12.125L4.875 6.875L1.5 15.125L10.125 12.125Z"
                stroke="white"
                stroke-width="2"
            />
            <rect x="9.375" y="1.625" width="0.75" height="0.75" fill="white" />
            <rect x="2.625" y="3.875" width="0.75" height="0.75" fill="white" />
            <rect x="16.125" y="8.375" width="0.75" height="0.75" fill="white" />
            <rect x="10.875" y="14.375" width="0.75" height="0.75" fill="white" />
        </svg>
    )
}
