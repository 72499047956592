import styled from "styled-components"
import { StyledBox } from "../../styles/styledcomponents"

export const StyledRichTextEditorContainer = styled(StyledBox)`
    display: flex;
    flex-direction: column;

    .quill {
        flex: 1;
        display: flex;
        flex-direction: column;

        .ql-toolbar {
            border-radius: 3px 3px 0 0;
            padding: 2px;
        }

        .ql-container {
            border-radius: 0 0 8px 8px;
        }
    }
`
