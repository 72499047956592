import { ChangeEvent, useRef, useState } from "react"
import {
    StyledMobileButtonWrapper,
    StyledMobilePreview,
    StyledMobilePreviewConfirmationRow,
} from "./styles"
import { OverlayOpacityLoader } from "../../Loader"
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto"
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera"
import SendIcon from "@mui/icons-material/Send"
import { ACCEPTED_IMAGE_FORMATS } from "./index"
import IconButton from "../../IconButton"
import { useTheme } from "styled-components"

interface ImageUploaderPreviewMobileProps {
    uploadPending: boolean
    handleAddImage: any
    image?: File
    setImage: any
    scrollToBottom?: () => void
}

const ImageUploaderPreviewMobile = ({
    uploadPending,
    handleAddImage,
    image,
    setImage,
    scrollToBottom,
}: ImageUploaderPreviewMobileProps) => {
    const inputFileRef = useRef<HTMLInputElement>(null)
    const inputCameraRef = useRef<HTMLInputElement>(null)
    const theme = useTheme()
    const [pictureIconColor, setPictureIconColor] = useState(theme.headlandsGray10)
    const [cameraIconColor, setCameraIconColor] = useState(theme.headlandsGray10)

    const handleImageSelection = (event: ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.files && event?.target?.files[0]) {
            setImage(event.target.files[0])
        }
    }

    const handlePhotoTaken = (event: ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.files && event?.target?.files[0]) {
            setImage(event.target.files[0])
        }
    }

    if (image)
        return (
            <StyledMobilePreview>
                <OverlayOpacityLoader size={48} loading={uploadPending}>
                    <img
                        alt={"image-selected"}
                        src={URL.createObjectURL(image)}
                        onLoad={scrollToBottom}
                    />
                </OverlayOpacityLoader>
                <StyledMobilePreviewConfirmationRow>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <InsertPhotoIcon
                            sx={{ color: theme.headlandsGray10 }}
                            onClick={() => inputFileRef?.current?.click()}
                        />
                        <PhotoCameraIcon
                            sx={{ color: theme.headlandsGray10 }}
                            onClick={() => inputCameraRef?.current?.click()}
                        />
                    </div>
                    <IconButton
                        icon={<SendIcon fontSize={"small"} />}
                        onClick={(event) => !uploadPending && handleAddImage(event)}
                        disabled={uploadPending}
                    />
                </StyledMobilePreviewConfirmationRow>
                <input
                    type="file"
                    id="file"
                    ref={inputFileRef}
                    style={{ display: "none" }}
                    onChange={handleImageSelection}
                />
                <input
                    type={"file"}
                    accept={ACCEPTED_IMAGE_FORMATS}
                    ref={inputCameraRef}
                    style={{ display: "none" }}
                    capture={"environment"}
                    onChange={handlePhotoTaken}
                />
            </StyledMobilePreview>
        )

    return (
        <div style={{ display: "flex", flex: 1, padding: "6px", justifyContent: "center" }}>
            <StyledMobileButtonWrapper
                onMouseEnter={() => setPictureIconColor(theme.colors.thread.student.bubble)}
                onMouseLeave={() => setPictureIconColor(theme.headlandsGray10)}
                onClick={() => inputFileRef?.current?.click()}
            >
                <InsertPhotoIcon sx={{ color: pictureIconColor }} fontSize={"large"} />
                <span>
                    Upload <strong>Image</strong>
                </span>
            </StyledMobileButtonWrapper>
            <StyledMobileButtonWrapper
                onMouseEnter={() => setCameraIconColor(theme.colors.thread.student.bubble)}
                onMouseLeave={() => setCameraIconColor(theme.headlandsGray10)}
                onClick={() => inputCameraRef?.current?.click()}
            >
                <PhotoCameraIcon sx={{ color: cameraIconColor }} fontSize={"large"} />
                <span>
                    Take <strong>Photo</strong>
                </span>
            </StyledMobileButtonWrapper>
            <input
                type={"file"}
                accept={ACCEPTED_IMAGE_FORMATS}
                ref={inputFileRef}
                style={{ display: "none" }}
                onChange={handleImageSelection}
            />
            <input
                type={"file"}
                accept={ACCEPTED_IMAGE_FORMATS}
                ref={inputCameraRef}
                style={{ display: "none" }}
                capture={"environment"}
                onChange={handlePhotoTaken}
            />
        </div>
    )
}

export default ImageUploaderPreviewMobile
