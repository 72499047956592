import styled, { css } from "styled-components"
import { StyledBody2, StyledColumn, StyledRow } from "../../../styles/styledcomponents"
import StyledButton from "../../../components/Button/styles"
import { StyledChevron } from "../../../components/Select/styles"

export const StyledHeader = styled(StyledRow)<{ langSelectOpen: boolean }>`
    display: none;
    top: 0;
    left: 0;
    position: absolute;
    padding: 6px 12px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    z-index: 1;

    ${({ langSelectOpen }) =>
        langSelectOpen &&
        css`
            display: flex;

            /* Language dropdown */
            .react-select {
                .react-select__control {
                    .react-select__single-value,
                    .react-select__input-container,
                    ${StyledChevron} {
                        color: white;
                    }
                }
            }
        `}

    /* Language dropdown */
    .react-select {
        ${StyledChevron} {
            font-size: 12px;
        }

        .react-select__control {
            &:hover {
                .react-select__single-value,
                .react-select__input-container,
                ${StyledChevron} {
                    color: white;
                }
            }
        }
    }

    ${StyledButton} {
        padding: 3px 6px;
        border-radius: 4px;
        width: auto;

        ${StyledBody2} {
            font-weight: 400;
        }
    }
`

export const StyledCodeBlock = styled(StyledColumn)<{ readOnly?: boolean }>`
    position: relative;
    padding: 36px 12px 18px;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    border-radius: 8px;

    ${({ readOnly }) =>
        readOnly &&
        css`
            padding: 12px;
            margin: 3px 12px;

            ${StyledHeader} {
                justify-content: flex-end;
            }
        `}

    &:hover {
        ${StyledHeader} {
            display: flex;
        }
    }

    ${StyledButton} {
        width: fit-content;
    }
`

export const StyledCodeContent = styled(StyledColumn)`
    /* Override some of Codemirrors styles */
    .cm-focused {
        outline: none;
    }
`
