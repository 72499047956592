import styled, { css } from "styled-components"
import { MediaSize } from "../../../types"
import { LeftBubbleStyles, RightBubbleStyles, StyledBubble } from "../styles"

export enum Device {
    mobile,
    desktop,
}

export const IMAGE_SIZE_RATIO = {
    [Device.mobile]: {
        [MediaSize.large]: 1,
        [MediaSize.medium]: 0.7,
        [MediaSize.small]: 0.5,
    },
    [Device.desktop]: {
        [MediaSize.large]: 0.7,
        [MediaSize.medium]: 0.6,
        [MediaSize.small]: 0.5,
    },
}

interface StyledImageBubbleProps {
    size: MediaSize
    isMobile?: boolean
    fromUser?: boolean
    open?: boolean
    error?: boolean
}

const ImageWidth = (size: MediaSize, isMobile?: boolean) => {
    const device = isMobile ? Device.mobile : Device.desktop
    switch (size) {
        case MediaSize.extraSmall:
            return css`
                width: 46px;
            `
        case MediaSize.small:
            return css`
                width: calc(
                    (${IMAGE_SIZE_RATIO[device][MediaSize.small]}) *
                        ${({ theme }) => (isMobile ? "100%" : theme.threadPreviewMaxWidth)} - 2 *
                        ${({ theme }) => (isMobile ? "12px" : theme.bubbleHorizontalMargin)}
                );
            `
        case MediaSize.medium:
            return css`
                width: calc(
                    (${IMAGE_SIZE_RATIO[device][MediaSize.medium]}) *
                        ${({ theme }) => (isMobile ? "100%" : theme.threadPreviewMaxWidth)} - 2 *
                        ${({ theme }) => (isMobile ? "12px" : theme.bubbleHorizontalMargin)}
                );
            `
        case MediaSize.large:
            return css`
                width: ${isMobile
                    ? "fit-content"
                    : `calc(${IMAGE_SIZE_RATIO[device][MediaSize.large]} * 100%)`};
            `
    }
}

export const StyledImageBubble = styled(StyledBubble)<StyledImageBubbleProps>`
    ${({ size, isMobile }) => ImageWidth(size, isMobile)}

    ${({ fromUser, isMobile }) =>
        fromUser
            ? css`
                  ${RightBubbleStyles}
                  img {
                      border-radius: ${({ theme }) => theme.rightBubbleBorderRadius};
                  }
                  ${isMobile &&
                  css`
                      width: fit-content !important;
                      height: fit-content;
                  `}
              `
            : css`
                  ${LeftBubbleStyles}
                  flex: 1;
              `}
    padding: 0;
    background-color: transparent;
    line-height: 0;
    max-width: unset;
    max-height: 80vh;
    height: auto;

    img {
        width: 100%;
        object-fit: contain;
        max-height: 80vh;
        transition: all 0.3s ease-out !important;
    }

    ${({ open }) =>
        open &&
        css`
            img {
                transition: all 0.3s ease-out !important;
                max-height: none;
                width: auto;
            }
        `}

    ${({ error }) =>
        error &&
        css`
            width: 400px;
            height: 250px;
            border: 1px solid ${({ theme }) => theme.headlandsError};
            background-color: rgb(${({ theme }) => theme.headlandsError}, 0.2);
            display: flex;
            justify-content: center;
            align-items: center;
            path {
                fill: ${({ theme }) => theme.headlandsError};
            }
        `}
`
