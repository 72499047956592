import { EditType } from "../../../apollo/generated/graphql"

export const aiEditMenuOptions: {
    title: string
    type: EditType
}[] = [
    {
        title: "Bulletize",
        type: EditType.Bulletize,
    },
    {
        title: "Fix grammar",
        type: EditType.FixGrammar,
    },
    {
        title: "Fix spelling",
        type: EditType.FixSpelling,
    },
    {
        title: "Make longer",
        type: EditType.MakeLonger,
    },
    {
        title: "Make shorter",
        type: EditType.MakeShorter,
    },
]
