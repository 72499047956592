import styled from "styled-components"

export const StyledCustomLinkTooltip = styled.div`
    position: absolute;
    min-height: 38px;
    min-width: 530px;
    background-color: ${({ theme }) => theme.headlandsWhite};
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border: 0.5px solid ${({ theme }) => theme.headlandsGray3};
    z-index: 1;
`
