import Modal from "../Modal"
import { WeirdEmojiIcon } from "../../assets/icons/WeirdEmojiIcon"
import { Button } from "../Button/Button"
import React from "react"
import { StyledH3, StyledP, StyledRow } from "../../styles/styledcomponents"

interface UnableToDeleteThreadModalProps {
    closeModal: () => void
}
const UnableToDeleteThreadModal = ({ closeModal }: UnableToDeleteThreadModalProps) => {
    return (
        <Modal open>
            <StyledRow css={{ alignItems: "center" }}>
                <WeirdEmojiIcon />
                <StyledH3 css={{ marginLeft: "4px" }}>Oops...</StyledH3>
            </StyledRow>
            <StyledP>You can only delete your own threads</StyledP>
            <Button label={"Close"} onClick={() => closeModal()} />
        </Modal>
    )
}

export default UnableToDeleteThreadModal
