import { ReactText, useCallback, useEffect, useState } from "react"
import Topbar from "./components/Topbar/Topbar"
import Sidebar from "react-sidebar"
import { useLazyQuery, useMutation } from "@apollo/client"
import { Block, BlocksType, CursorMovement, ThreadFont, ThreadTheme } from "../types"
import {
    addBlocks,
    clearTimeouts,
    moveWarningOrAdviseCursor,
    redoBlockVersion,
    setBlocks,
    undoBlockVersion,
    updateAutoSaveTimeout,
    updateLintWarningAndAdvices,
} from "../redux/blocks"
import { RootState } from "../redux/store"
import SettingsBar from "./components/SettingsBar/SettingsBar"
import classNames from "classnames"
import { setThreadTheme } from "../utils/utils"
import { useDispatch, useSelector } from "react-redux"
import usePrevious from "../hooks/previous.hook"
import ThreadCreatorBar from "./components/ThreadBar/ThreadCreatorBar"
import { WarningIcon } from "../assets/icons/Warning"
import { AdviceIcon } from "../assets/icons/Advice"
import { compact, isEmpty, isEqual, last, throttle } from "lodash"
import { ArrowUpIcon } from "../assets/icons/ArrowUp"
import { ArrowDownIcon } from "../assets/icons/ArrowDown"
import { setPreviewMode, updateThread } from "../redux/threads"
import { useCurrentThreadField, useCurrentThreadGuid } from "../hooks/currentThread.hook"
import { ModalType, SidebarType, setModalState } from "../redux/layout"
import { StyledAddBlockRow, StyledCreatorScreen } from "./styles"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import { ThreadSkeleton } from "../common/components/LoaderSkeleton/ThreadSkeleton"
import useLayout from "../hooks/layout.hook"
import { useSidebars } from "../hooks/sidebars.hook"
import EditorSection from "./components/EditorScreen/components/EditorSection"
import { parseBlocksString, preFetchImages } from "../thread/utils"
import { DefaultTheme, ThemeProvider, useTheme } from "styled-components"
import { StyledBox } from "../styles/styledcomponents"
import { ABOUT_ME, GET_CREATOR_THREAD, GET_LINT_THREAD, PREVIEW_THREAD } from "../apollo/queries"
import {
    CreateThreadInput,
    GenerateThreadInput,
    GetLintThreadQuery,
    Severity,
    VariableWithValue,
} from "../apollo/generated/graphql"
import AddChunkMenu from "./components/AddChunkMenu"
import { useUserPermissions } from "../hooks/userPermissions"
import SidebarToggle, {
    SidebarTogglePosition,
    SidebarToggleVariant,
} from "../components/SidebarToggle"
import TopBar from "../components/TopBar"
import useThreadStyles from "../hooks/threadStyles.hook"
import PreviewModal from "./components/PreviewModal"
import UnsavedChangesModal from "../components/UnsavedChangesModal"
import { ErrorToastIcon } from "../assets/icons/ErrorToastIcon"
import { useToast } from "../hooks/useToast.hook"
import AICreateModal from "./components/AICreateModal"
import ThreadOutlineBar from "./components/ThreadOutlineBar"
import TimelineIcon from "@mui/icons-material/Timeline"
import { useHistory } from "react-router"
import { CREATE_THREAD, CANCEL_AI_EDITING, GENERATE_THREAD } from "../apollo/mutations"
import { addThreadCreatorToSearchList } from "../apollo/cacheHelper"
import { Button } from "../components/Button/Button"
import { Loader } from "../components/Loader"
import { toast } from "react-toastify"
import CreateContent from "./components/CreateContent"
import MenuIcon from "../assets/icons/MenuIcon"
import { sessionDataVar } from "../apollo/cache-store"

const CreatorScreen = () => {
    const { showToast, showErrorToast } = useToast()
    const { isMobile } = useLayout()

    const history = useHistory()

    const [threadReady, setThreadReady] = useState(false)
    const [aiBlocksReady, setAiBlocksReady] = useState(false)
    const [aiToastId, setAiToastId] = useState<ReactText>()

    const propertiesBarOpen = useSelector(
        (state: RootState) => state.layoutReducer.propertiesBarOpen
    )

    const [warningGlowTimeoutId, setWarningGlowTimeoutId] = useState<NodeJS.Timeout | undefined>()
    const [adviceGlowTimeoutId, setAdviseGlowTimeoutId] = useState<NodeJS.Timeout | undefined>()

    const currentThreadGuid = useCurrentThreadGuid()

    const threadSetInStore = useSelector(
        (state: RootState) => !!state.threadsReducer.threads[currentThreadGuid]
    )

    const aiCreateRunning = useCurrentThreadField("aiEditing")

    const { font: threadFont, theme: threadTheme } = useThreadStyles()

    const lintErrorsAndWarnings = useSelector(
        (state: RootState) => state.blocksReducer.lintWarningAndAdvices.issues,
        isEqual
    )
    const warningIndex = useSelector(
        (state: RootState) => state.blocksReducer.lintWarningAndAdvices.indexes[Severity.Warning]
    )
    const adviceIndex = useSelector(
        (state: RootState) => state.blocksReducer.lintWarningAndAdvices.indexes[Severity.Advice]
    )

    const aiCreateModalOpen = useSelector(
        (state: RootState) => state.layoutReducer.aiCreateModalOpen
    )

    const blocksIndexLength = useSelector(
        (state: RootState) => Object.keys(state.blocksReducer.blocksIndex).length
    )

    const lastBlockGuid = useSelector(
        (state: RootState) => last(Object.values(state.blocksReducer.blocksIndex))?.id
    )

    const dispatch = useDispatch()
    const prevGuid = usePrevious(currentThreadGuid)

    const {
        toggleSidebar,
        openSidebars,
        closeSidebars,
        creatorThreadBarOpen,
        creatorThreadOutlineOpen,
        enableSidebarAnimation,
    } = useSidebars()

    const {
        permissions: { maySeeExperimentalFeatures },
    } = useUserPermissions()

    const theme = useTheme()

    const [lintThreadById] = useLazyQuery(GET_LINT_THREAD, {
        onCompleted: (data) => {
            dispatch(updateLintWarningAndAdvices(data?.lintThread || []))
        },
        // Lint errors and warnings should be refetch everytime to check if something had changed
        fetchPolicy: "network-only",
    })

    const [
        getThread,
        { data: aiCreateData, loading: getThreadLoading, startPolling, stopPolling },
    ] = useLazyQuery(GET_CREATOR_THREAD, {
        onCompleted: (data) => {
            const thread = data.getThreadByGuid
            if (thread) {
                const parsedBlocks: Block[] = parseBlocksString(thread.blocks?.toString())
                const parsedPreviewBlocks: Block[] = parseBlocksString(thread.preview?.toString())
                const parsedSynopsisBlocks: Block[] = parseBlocksString(thread.synopsis?.toString())
                const compactedBlocks = compact(parsedBlocks)
                const compactedPreviewBlocks = compact(parsedPreviewBlocks)
                const compactedSynopsisBlocks = compact(parsedSynopsisBlocks)
                throttledSetBlocks(compactedBlocks)
                throttledSetPreviewBlocks(compactedPreviewBlocks)
                throttledSetSynopsisBlocks(compactedSynopsisBlocks)
                dispatch(updateThread({ guid: thread.guid, thread }))
                setThreadReady(!thread.aiEditing)
                // if the thread has id, query for warnings and errors
                if (thread.id) {
                    void lintThreadById({ variables: { id: thread.id } })
                }
                setAiBlocksReady(true)
            }
        },
        onError: (error) => {
            console.log("error", error)
        },
        fetchPolicy: "no-cache",
    })

    const [previewThread] = useLazyQuery(PREVIEW_THREAD, {
        fetchPolicy: "network-only",
    })

    const [getAboutMe] = useLazyQuery(ABOUT_ME)

    const [cancelAIEditing] = useMutation(CANCEL_AI_EDITING, {
        onError: () => {
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
        },
        onCompleted: (data) => setThreadReady(!data.aiCancelEditing?.aiEditing),
    })

    const [createThread] = useMutation(CREATE_THREAD, {
        onCompleted(data) {
            if (data.createThread) {
                history.push(`/creator/${data.createThread.guid}`)
            }
        },
        update: (cache, { data }) => {
            data?.createThread && addThreadCreatorToSearchList(cache, data.createThread)
        },
        onError: () => {
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
        },
    })

    const [generateThread] = useMutation(GENERATE_THREAD, {
        onCompleted(data) {
            if (data.generateThread) {
                history.push(`/creator/${data.generateThread.guid}`)
            }
        },
        update: (cache, { data }) => {
            data?.generateThread && addThreadCreatorToSearchList(cache, data.generateThread)
        },
        onError: () => {
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
        },
    })

    const baseSetBlocks = useCallback((blocksType: BlocksType, blocks: Block[]) => {
        if (!isEmpty(blocks)) {
            dispatch(setBlocks({ blocksType, blocks }))
        }
        // `dispatch` dependency is not necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /*
     * Note we need separate `throttled` functions otherwise the throttle could avoid
     * adding some blocks of another `BlocksType`
     * */
    const throttledSetPreviewBlocks = useCallback(
        throttle((blocks: Block[]) => baseSetBlocks(BlocksType.PREVIEW, blocks), 1000),
        []
    )
    const throttledSetSynopsisBlocks = useCallback(
        throttle((blocks: Block[]) => baseSetBlocks(BlocksType.SYNOPSIS, blocks), 1000),
        []
    )
    const throttledSetBlocks = useCallback(
        throttle((blocks: Block[]) => baseSetBlocks(BlocksType.BLOCKS, blocks), 1000),
        []
    )

    const handleCreate = ($input: CreateThreadInput) => {
        createThread({ variables: { input: $input } })
    }

    // This should at some point replace `createThread`
    const handleGenerate = ($input: GenerateThreadInput) => {
        generateThread({ variables: { input: $input } })
    }

    // Remove the toast once generation has finished or been cancelled by the user
    useEffect(() => {
        if (aiCreateRunning) {
            startPolling(3000)
            const toastId = showToast({
                icon: <Loader color="white" size={12} />,
                message: "Generating your thread",
                options: {
                    autoClose: false,
                    closeOnClick: false,
                    draggable: false,
                    closeButton: false,
                    style: {
                        backgroundColor: theme.colors.base.uiAi500,
                    },
                },
            })
            setAiToastId(toastId)
        } else {
            stopPolling()
            toast.dismiss(aiToastId)
        }
    }, [aiCreateRunning])

    const handleAiCreateCancel = () => {
        cancelAIEditing({ variables: { threadGUID: currentThreadGuid } })
        dispatch(updateThread({ guid: currentThreadGuid, thread: { aiEditing: false } }))
    }

    /*
     * on mount:
     * - add listener for undo/redo
     * - open the creator thread sidebar
     *
     * on unmount:
     * - reset the current thread
     * - remove listener for undo/red
     * */
    useEffect(() => {
        const handleUndoRedo = (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey) && event.key === "z") {
                if (!event.shiftKey) dispatch(undoBlockVersion())
                else dispatch(redoBlockVersion())
            }
        }

        openSidebars(SidebarType.creatorThread, false)

        document.addEventListener("keydown", handleUndoRedo)

        return () => {
            dispatch(setBlocks({ blocksType: BlocksType.PREVIEW, blocks: [] }))
            dispatch(setBlocks({ blocksType: BlocksType.BLOCKS, blocks: [] }))
            dispatch(setBlocks({ blocksType: BlocksType.SYNOPSIS, blocks: [] }))
            dispatch(updateAutoSaveTimeout({ editing: undefined, timeoutID: undefined }))
            handlePreview(false)
            document.removeEventListener("keydown", handleUndoRedo)
        }
        // `dispatch`, `handlePreview` and `openSidebars` dependencies are not necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(clearTimeouts())
        if (currentThreadGuid) {
            void getAboutMe()
            // before calling the get thread we clear the blocks reducer and cancel the timeouts
            dispatch(setBlocks({ blocksType: BlocksType.PREVIEW, blocks: [] }))
            dispatch(setBlocks({ blocksType: BlocksType.BLOCKS, blocks: [] }))
            dispatch(setBlocks({ blocksType: BlocksType.SYNOPSIS, blocks: [] }))
            dispatch(updateAutoSaveTimeout({ editing: undefined, timeoutID: undefined }))
            // then we call the get thread
            void getThread({ variables: { threadGUID: currentThreadGuid } })
        }
        if (currentThreadGuid && prevGuid && prevGuid !== currentThreadGuid) {
            handlePreview(false)
        }
        if (!currentThreadGuid) {
            setThreadReady(false)
            closeSidebars([SidebarType.creatorThreadOutline, SidebarType.properties], false)
        }
    }, [currentThreadGuid])

    /*
     * This function handle the:
     * - scroll into view of the correspondent block
     * - the glow style of the correspondent block
     * when the warning/advice index changes
     * */
    const handleIssueIndexChange = (severity: Severity) => {
        let index
        let timeoutID
        let className: string

        if (severity === Severity.Warning) {
            index = warningIndex
            timeoutID = warningGlowTimeoutId
            className = "glow-warning"
        } else {
            index = adviceIndex
            timeoutID = adviceGlowTimeoutId
            className = "glow-advice"
        }

        if (index !== undefined) {
            const filterIssues = lintErrorsAndWarnings?.filter(
                (issue) => issue.severity === severity
            )
            const blockId = filterIssues[index]?.objectID

            if (timeoutID) {
                clearTimeout(timeoutID)
                if (severity === Severity.Warning) setWarningGlowTimeoutId(undefined)
                else setAdviseGlowTimeoutId(undefined)
            }

            // remove all previous 'glow' classNames (to only have one active at a time)
            document
                .getElementsByClassName("glow-warning")
                .item(0)
                ?.classList?.remove("glow-warning", "on")
            document
                .getElementsByClassName("glow-advice")
                .item(0)
                ?.classList?.remove("glow-advice", "on")

            // scroll the block into view
            document.getElementById(`block-${blockId}`)?.scrollIntoView({ behavior: "smooth" })

            // add the right className
            document.getElementById(`block-${blockId}`)?.classList.add(className, "on")

            // after 2 seconds remove the className
            const newTimeoutId = setTimeout(() => {
                document.getElementsByClassName(className).item(0)?.classList?.remove("on")
            }, 2000)

            if (severity === Severity.Warning) setWarningGlowTimeoutId(newTimeoutId)
            else setAdviseGlowTimeoutId(newTimeoutId)
        }
    }

    // when the warning cursor changes scroll to show the block with the warning
    useEffect(() => {
        handleIssueIndexChange(Severity.Warning)
        // `handleIssueIndexChange` dependency is not necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warningIndex])

    // when the advice cursor changes scroll to show the block with the advice
    useEffect(() => {
        handleIssueIndexChange(Severity.Advice)
        // `handleIssueIndexChange` dependency is not necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adviceIndex])

    const handlePreview = useCallback(
        (
            previewMode: boolean,
            initialBlockId?: string,
            blocksToPreview?: {
                blocks?: Block[]
                previewBlocks?: Block[]
                synopsisBlocks?: Block[]
            },
            variablesToAdd?: VariableWithValue[]
        ) => {
            if (currentThreadGuid && threadSetInStore) {
                if (blocksToPreview) {
                    /*
                     * This is the case where the Topbar component has already
                     * called the previewThreadByGuid query and has prefetched
                     * the images.
                     */
                    dispatch(
                        setPreviewMode({
                            guid: currentThreadGuid,
                            previewMode,
                            initialBlockId,
                            blocksToPreview,
                            variablesToAdd,
                        })
                    )
                } else {
                    previewThread({ variables: { threadGUID: currentThreadGuid } }).then((res) => {
                        const parsedBlocks = parseBlocksString(
                            res.data?.previewThreadByGuid.thread.blocks
                        )
                        const parsedPreviewBlocks = parseBlocksString(
                            res.data?.previewThreadByGuid.thread.preview
                        )
                        const parsedSynopsisBlocks = parseBlocksString(
                            res.data?.previewThreadByGuid.thread.synopsis
                        )

                        const sessionId = res?.data?.previewThreadByGuid?.telemetrySessionId
                        if (sessionId) {
                            sessionDataVar({
                                id: sessionId,
                                timestamp: Date.now(),
                            })
                        }

                        preFetchImages(parsedBlocks)
                        dispatch(
                            setPreviewMode({
                                guid: currentThreadGuid,
                                previewMode,
                                initialBlockId,
                                blocksToPreview: {
                                    blocks: parsedBlocks,
                                    previewBlocks: parsedPreviewBlocks,
                                    synopsisBlocks: parsedSynopsisBlocks,
                                },
                                variablesToAdd: compact(res.data?.previewThreadByGuid.variables),
                            })
                        )
                    })
                }
            }
        },
        // `dispatch` and `previewThread` dependencies are not necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentThreadGuid, threadSetInStore]
    )

    const handleAddChunk = useCallback(
        (chunk: Block) => {
            dispatch(
                addBlocks({
                    blocksType: BlocksType.BLOCKS,
                    blocks: [chunk],
                })
            )
        },
        [dispatch]
    )

    return (
        <StyledCreatorScreen data-testid={"editor-screen"}>
            <div className={classNames("playlist-program creator")}>
                <SidebarToggle
                    position={SidebarTogglePosition.left}
                    onClick={() => toggleSidebar(SidebarType.creatorThread)}
                    sidebarWidth={`${theme.sidebarWidths.creatorThread}px`}
                    leftPadding={theme.variables.creator.threadBarPadding.left}
                    sidebarOpen={!!creatorThreadBarOpen}
                    enabledTransition={enableSidebarAnimation}
                    styles={{ zIndex: 200 }}
                    icon={<MenuIcon />}
                />
            </div>
            {threadReady && (
                <SidebarToggle
                    variant={SidebarToggleVariant.outer}
                    size={"30px"}
                    initialHorizontalPosition={
                        !!creatorThreadBarOpen
                            ? `${theme.sidebarWidths.creatorThread}px`
                            : undefined
                    }
                    position={SidebarTogglePosition.left}
                    leftPadding={"18px"}
                    onClick={() => toggleSidebar(SidebarType.creatorThreadOutline)}
                    sidebarWidth={`${theme.sidebarWidths.creatorThreadOutline}px`}
                    sidebarOpen={!!creatorThreadOutlineOpen}
                    enabledTransition={enableSidebarAnimation}
                    icon={<TimelineIcon sx={{ color: theme.headlandsGray10, fontSize: 18 }} />}
                />
            )}
            {threadReady && (
                <SidebarToggle
                    variant={SidebarToggleVariant.outer}
                    size={"30px"}
                    position={SidebarTogglePosition.right}
                    rightPadding={"18px"}
                    onClick={() => toggleSidebar(SidebarType.properties)}
                    sidebarWidth={`${theme.sidebarWidths.properties}px`}
                    sidebarOpen={!!propertiesBarOpen}
                    enabledTransition={enableSidebarAnimation}
                    icon={
                        <SettingsOutlinedIcon sx={{ color: theme.headlandsGray10, fontSize: 18 }} />
                    }
                />
            )}
            <Sidebar
                sidebar={<ThreadCreatorBar />}
                open={creatorThreadBarOpen}
                docked={creatorThreadBarOpen}
                shadow={false}
                touch={isMobile}
                transitions={enableSidebarAnimation}
                styles={{
                    sidebar: {
                        display: "flex",
                        overflow: "hidden",
                    },
                }}
            >
                {aiCreateRunning ? (
                    <TopBar
                        extraLeftPadding={!creatorThreadBarOpen}
                        title={aiCreateData?.getThreadByGuid?.title}
                        styles={{
                            flexDirection: "row-reverse",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button label={"CANCEL"} onClick={handleAiCreateCancel} width={84} />
                    </TopBar>
                ) : /* render and empty topbar if there is no thread or is loading */
                !threadSetInStore ? (
                    <TopBar />
                ) : (
                    <Topbar handlePreview={handlePreview} />
                )}
                <Sidebar
                    sidebar={<ThreadOutlineBar handlePreview={handlePreview} />}
                    open={creatorThreadOutlineOpen}
                    docked={creatorThreadOutlineOpen}
                    shadow={false}
                    touch={isMobile}
                    transitions={enableSidebarAnimation}
                    styles={{
                        root: {
                            height: `calc(100% - ${theme.variables.common.topBarHeight})`,
                            marginTop: theme.variables.common.topBarHeight,
                            transition: "0.2s",
                        },
                        content: { overflowX: "hidden" },
                    }}
                >
                    <Sidebar
                        pullRight
                        sidebar={<SettingsBar />}
                        open={propertiesBarOpen}
                        docked={propertiesBarOpen}
                        touch={isMobile}
                        transitions={enableSidebarAnimation}
                        styles={{
                            root: { height: "inherit" },
                            content: {
                                overflowX: "hidden",
                                overflowY: !currentThreadGuid ? "hidden" : "auto",
                                backgroundColor: theme.headlandsGray4,
                            },
                        }}
                        shadow={false}
                    >
                        {/* If there is no thread selected render the `CreateContent` component */}
                        {!currentThreadGuid ? (
                            <CreateContent />
                        ) : (
                            <div
                                className={"editor-content-container"}
                                style={{
                                    pointerEvents: aiCreateRunning ? "none" : "auto",
                                }}
                            >
                                {threadReady && (
                                    <LintWarningAndAdvises issues={lintErrorsAndWarnings} />
                                )}
                                {getThreadLoading || (aiCreateData && !aiBlocksReady) ? (
                                    <StyledBox css={{ width: "100%", maxWidth: "580px" }}>
                                        <ThreadSkeleton creator />
                                    </StyledBox>
                                ) : (
                                    (threadSetInStore || aiCreateRunning) && (
                                        <ThemeProvider
                                            theme={(baseTheme: DefaultTheme) =>
                                                setThreadTheme(
                                                    baseTheme,
                                                    threadTheme
                                                        ? (threadTheme as ThreadTheme)
                                                        : undefined,
                                                    threadFont
                                                        ? (threadFont as ThreadFont)
                                                        : undefined
                                                )
                                            }
                                        >
                                            <div className={"editor-container"}>
                                                <EditorSection
                                                    blocksType={BlocksType.PREVIEW}
                                                    handlePreview={handlePreview}
                                                />
                                                <EditorSection
                                                    blocksType={BlocksType.BLOCKS}
                                                    handlePreview={handlePreview}
                                                />
                                                <StyledAddBlockRow>
                                                    <AddChunkMenu
                                                        handleAddChunk={handleAddChunk}
                                                        hideAiAssistOptions={!lastBlockGuid}
                                                        chunkParams={{
                                                            index: blocksIndexLength || 0,
                                                            blockGuid: lastBlockGuid,
                                                        }}
                                                    />
                                                </StyledAddBlockRow>
                                                <EditorSection
                                                    blocksType={BlocksType.SYNOPSIS}
                                                    handlePreview={handlePreview}
                                                />
                                            </div>
                                        </ThemeProvider>
                                    )
                                )}
                            </div>
                        )}
                    </Sidebar>
                </Sidebar>
            </Sidebar>
            {aiCreateModalOpen && (
                <AICreateModal
                    onCreate={handleCreate}
                    onGenerate={handleGenerate}
                    onClose={() =>
                        dispatch(setModalState({ modalType: ModalType.aiCreate, open: false }))
                    }
                />
            )}
            <PreviewModal />
            <UnsavedChangesModal />
        </StyledCreatorScreen>
    )
}

interface LintWarningAndAdvisesProps {
    issues: NonNullable<GetLintThreadQuery["lintThread"]>
}

const LintWarningAndAdvises = ({ issues }: LintWarningAndAdvisesProps) => {
    const warnings = issues?.filter((issue) => issue.severity === Severity.Warning)
    const advices = issues?.filter((issue) => issue.severity === Severity.Advice)
    const theme = useTheme()

    const dispatch = useDispatch()

    const nextWarning = () =>
        dispatch(
            moveWarningOrAdviseCursor({
                severity: Severity.Warning,
                cursorMovement: CursorMovement.next,
            })
        )
    const previousWarning = () =>
        dispatch(
            moveWarningOrAdviseCursor({
                severity: Severity.Warning,
                cursorMovement: CursorMovement.previous,
            })
        )
    const nextAdvise = () =>
        dispatch(
            moveWarningOrAdviseCursor({
                severity: Severity.Advice,
                cursorMovement: CursorMovement.next,
            })
        )
    const previousAdvise = () =>
        dispatch(
            moveWarningOrAdviseCursor({
                severity: Severity.Advice,
                cursorMovement: CursorMovement.previous,
            })
        )

    if (isEmpty(warnings) && isEmpty(advices)) return null

    return (
        <div className={"lint-warnings-and-advices-container"}>
            {!isEmpty(warnings) && (
                <div className={"warning-row"}>
                    <ArrowDownIcon
                        color={theme.headlandsError}
                        className={"clickable simple-horizontal-margin"}
                        onClick={nextWarning}
                    />
                    <ArrowUpIcon
                        color={theme.headlandsError}
                        className={"clickable simple-horizontal-margin"}
                        onClick={previousWarning}
                    />
                    <WarningIcon color={theme.headlandsError} className={"double-margin-left"} />
                    <span>{warnings.length}</span>
                </div>
            )}
            {!isEmpty(advices) && (
                <div className={"advice-row"}>
                    <ArrowDownIcon
                        color={theme.headlandsWarning}
                        className={"clickable simple-horizontal-margin"}
                        onClick={nextAdvise}
                    />
                    <ArrowUpIcon
                        color={theme.headlandsWarning}
                        className={"clickable simple-horizontal-margin"}
                        onClick={previousAdvise}
                    />
                    <AdviceIcon color={theme.headlandsWarning} className={"double-margin-left"} />
                    <span>{advices.length}</span>
                </div>
            )}
        </div>
    )
}

export default CreatorScreen
