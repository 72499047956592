import { StyledBox, StyledHr } from "../../styles/styledcomponents"
import { useTheme } from "styled-components"
import { Block } from "../../types"

interface DividerProps {
    bubble: Block
}

const Divider = ({ bubble }: DividerProps) => {
    const theme = useTheme()
    return (
        <StyledBox
            className={"left-animation"}
            css={{ margin: bubble.isInsideGroup ? "3px 0 20px" : "3px 0" }}
        >
            <StyledHr
                css={{
                    marginLeft: "12px",
                    marginRight: "12px",
                    borderColor: theme.headlandsGray3,
                }}
            />
        </StyledBox>
    )
}

export default Divider
