import { CSSObject, useTheme } from "styled-components"
import { useShareThread } from "../../../../hooks/shareThread.hook"
import { StyledBox, StyledH5 } from "../../../../styles/styledcomponents"
import { Loader } from "../../../Loader"
import {
    StyledHeaderButton,
    StyledHeaderButtonContainer,
    StyledHeaderButtonContent,
    StyledShareThreadButton,
} from "./styles"
import { Add } from "@mui/icons-material"
import { Maybe } from "graphql/jsutils/Maybe"
import { BoltIcon } from "../../../../assets/icons/Bolt"
import { ShareIcon } from "../../../../assets/icons/ShareIcon"
import { useToast } from "../../../../hooks/useToast.hook"
import { StyledLinkIcon } from "../../../ShareThreadModal/styles"
import { useMutation } from "@apollo/client"
import { ADD_EVENT } from "../../../../apollo/mutations"
import { useLocation } from "react-router"

export enum ShareThreadButtonVariant {
    RECTANGLE,
    CIRCLE, // share button used on the H2H
}

export enum CopyThreadButtonVariant {
    SHARE,
    CHALLENGE,
}

interface ShareThreadButtonProps {
    variant?: ShareThreadButtonVariant
    copyVariant?: CopyThreadButtonVariant
    dark?: boolean
    styles?: CSSObject
    programID?: Maybe<number>
    threadID?: Maybe<number>
    additionalText?: string
    isMobile?: boolean
}

const ShareThreadButton = ({
    variant = ShareThreadButtonVariant.RECTANGLE,
    copyVariant = CopyThreadButtonVariant.CHALLENGE,
    dark,
    styles,
    threadID,
    programID,
    additionalText,
    isMobile,
}: ShareThreadButtonProps) => {
    const theme = useTheme()
    const { linkLoading, generateLink } = useShareThread({ threadID, programID })
    const { showToast } = useToast()
    const [addEvent] = useMutation(ADD_EVENT)
    const location = useLocation()

    const handleClick = () => {
        // send telemetry to record which button was clicked
        if (!isMobile && variant === ShareThreadButtonVariant.RECTANGLE) {
            addEvent({
                variables: {
                    input: {
                        url: location.pathname,
                        clientTime: new Date(),
                        data: JSON.stringify({
                            button:
                                copyVariant === CopyThreadButtonVariant.SHARE
                                    ? "share"
                                    : "challenge",
                        }),
                        type: "SHARE",
                    },
                },
            })
        }
        if (isMobile) {
            generateLink().then(({ data }) => {
                const linkURL = data?.createLink?.linkURL || ""
                navigator.share({ text: additionalText, url: linkURL })
            })
        } else {
            const clipboardItem = new ClipboardItem({
                "text/plain": generateLink().then(({ data }) => {
                    const linkURL = data?.createLink?.linkURL || ""
                    const text = additionalText ? linkURL + "\n" + additionalText : linkURL
                    return new Blob([text], { type: "text/plain" })
                }),
            })

            navigator.clipboard.write([clipboardItem]).then(() =>
                showToast({
                    icon: <StyledLinkIcon sx={{ color: "white" }} />,
                    message: "Challenge link copied to clipboard",
                })
            )
        }
    }

    if (variant === ShareThreadButtonVariant.CIRCLE)
        return <HeaderShareThreadButton onClick={handleClick} loading={linkLoading} dark={dark} />
    else {
        let text
        let Icon
        let buttonStyle = { ...styles }
        if (copyVariant === CopyThreadButtonVariant.CHALLENGE) {
            text = "Challenge Friends"
            Icon = BoltIcon
        } else {
            text = "Share"
            Icon = ShareIcon
            buttonStyle = { ...buttonStyle, backgroundColor: theme.colors.base.uiPrimary500 }
        }
        return (
            <StyledShareThreadButton
                loading={linkLoading}
                onClick={handleClick}
                buttonStyle={buttonStyle}
                loaderHeight={24}
                label={
                    <StyledBox
                        css={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "4px",
                        }}
                    >
                        <StyledH5 useNewDesign>{text}</StyledH5>
                        <Icon width={24} height={24} color={theme.colors.base.uiBgBase} />
                    </StyledBox>
                }
            />
        )
    }
}

interface HeaderShareThreadButtonProps extends ShareThreadButtonProps {
    loading?: boolean
    onClick?: () => void
}

const HeaderShareThreadButton = ({ onClick, loading, dark }: HeaderShareThreadButtonProps) => {
    const theme = useTheme()
    const color = dark ? theme.colors.base.uiPrimary500 : theme.colors.base.uiBgBase

    return (
        <StyledHeaderButtonContainer onClick={onClick} dark={dark}>
            <StyledH5 useNewDesign css={{ alignSelf: "flex-start" }}>
                Challenge Friends
            </StyledH5>
            <StyledHeaderButton>
                <StyledHeaderButtonContent>
                    {loading ? (
                        <Loader color={color} size={20} />
                    ) : (
                        <Add sx={{ fontSize: "20px", color }} />
                    )}
                </StyledHeaderButtonContent>
            </StyledHeaderButton>
        </StyledHeaderButtonContainer>
    )
}

export default ShareThreadButton
