import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
    Block,
    BlocksType,
    BlockType,
    ChoiceQuestionOption,
    ConditionalBranch,
    CursorMovement,
    FreeTextResponse,
    PartialBlock,
    PartialConditionalBranch,
    ReadOnlyBlock,
    ReadOnlyConditionalBranch,
    ReadOnlyQuestionOption,
    ShallowBlock,
} from "../types"
import _, { isNil } from "lodash"
import { GetLintThreadQuery, Severity } from "../apollo/generated/graphql"
import { addIdsToFreeTextResponses } from "../thread/utils"

export interface BlocksReducer {
    [key: string]: ShallowBlock
}

export interface BlocksIndexReducer {
    [key: number]: ReadOnlyBlock
}

export interface BlocksState {
    blocks: BlocksReducer
    blocksIndex: BlocksIndexReducer
    previewBlocks: BlocksReducer
    previewBlocksIndex: BlocksIndexReducer
    synopsisBlocks: BlocksReducer
    synopsisBlocksIndex: BlocksIndexReducer
    previewMode: boolean
    initialIndex: number
    blockVersions: BlocksVersion[]
    blockVersionIndex?: number
    autoSaveTimeout: BlockTimeout
    lintWarningAndAdvices: {
        issues: NonNullable<GetLintThreadQuery["lintThread"]>
        indexes: {
            [Severity.Advice]?: number
            [Severity.Warning]?: number
        }
    }
    selectedBlockId?: string
    selectedChunkId?: string
}

const MAX_BLOCK_VERSIONS = 100

interface BlocksVersion {
    blocks: { [key: string]: Block }
    blocksIndex: { [key: number]: ReadOnlyBlock }
    previewBlocks: { [key: string]: Block }
    previewBlocksIndex: { [key: number]: ReadOnlyBlock }
    synopsisBlocks: { [key: string]: Block }
    synopsisBlocksIndex: { [key: number]: ReadOnlyBlock }
}

type BlockTimeout = Partial<{
    editing: boolean
    timeoutID: ReturnType<typeof setTimeout>
}>

const initialState: BlocksState = {
    blocks: {},
    blocksIndex: {},
    previewBlocks: {},
    previewBlocksIndex: {},
    synopsisBlocks: {},
    synopsisBlocksIndex: {},
    previewMode: false,
    initialIndex: 0,
    blockVersions: [],
    blockVersionIndex: undefined,
    autoSaveTimeout: {
        editing: undefined,
        timeoutID: undefined,
    },
    lintWarningAndAdvices: {
        issues: [],
        indexes: {
            [Severity.Warning]: undefined,
            [Severity.Advice]: undefined,
        },
    },
    selectedBlockId: undefined,
    selectedChunkId: undefined,
}

export const blocksSlice = createSlice({
    name: "blocks",
    initialState,
    reducers: {
        clearTimeouts: (state: BlocksState) => {
            // clear the auto-save timeout reset the auto-save
            if (state.autoSaveTimeout.timeoutID) clearTimeout(state.autoSaveTimeout.timeoutID)
            state.autoSaveTimeout = {
                editing: undefined,
                timeoutID: undefined,
            }
            return state
        },
        setBlocks: (
            state: BlocksState,
            action: PayloadAction<{ blocksType: BlocksType; blocks: Block[] }>
        ) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            const initialValue = {}
            const blocksIndex: { [key: number]: any } = {}

            /*
             * I'm sorry but I couldn't come up with a shorter name that's still descriptive enough 🤷🏻‍♂️.
             *
             * What we're doing here is adding an `id` to the free text block's `responses`,
             * because we need them on the front end in order for the `ManageResponsesModal` to work
             * correctly, but the backend filters that field since it's not valid per the thread format.
             *
             * Note: This is for the new Free Text block `free_text_question`
             */
            const blocksWithIdsForFreeTextResponses = addIdsToFreeTextResponses(
                action.payload.blocks
            )
            state[action.payload.blocksType] = blocksWithIdsForFreeTextResponses.reduce(
                (a: { [key: string]: Block }, block: Block) => {
                    const mapBlock = (
                        a: { [key: string]: Block },
                        block: Block,
                        parentObject: { [key: number]: any }
                    ): { [key: string]: Block } => {
                        const index = Object.keys(parentObject).length
                        parentObject[index] = { id: block.id }
                        a[block.id] = _.cloneDeep(block) as ShallowBlock

                        if (block.branches?.length) {
                            parentObject[index] = {
                                id: block.id,
                                branches: {},
                            }
                            block.branches.forEach((branch, branchIndex) => {
                                parentObject[index].branches[branchIndex] = {
                                    ...branch,
                                    objects: {},
                                }
                                branch.objects
                                    .filter((d) => d)
                                    .forEach((innerBlock: Block, i) => {
                                        if (innerBlock.branches?.length)
                                            mapBlock(
                                                a,
                                                innerBlock,
                                                parentObject[index].branches[branchIndex].objects
                                            )
                                        else if (innerBlock.options?.length)
                                            mapBlock(
                                                a,
                                                innerBlock,
                                                parentObject[index].branches[branchIndex].objects
                                            )
                                        else if (innerBlock.responses?.length)
                                            mapBlock(
                                                a,
                                                innerBlock,
                                                parentObject[index].branches[branchIndex].objects
                                            )
                                        else {
                                            a[innerBlock.id] = innerBlock
                                            parentObject[index].branches[branchIndex].objects[i] = {
                                                id: innerBlock.id,
                                            }
                                        }
                                    })
                            })
                        } else if (block.objects?.length) {
                            parentObject[index] = {
                                id: block.id,
                                objects: {},
                            }

                            block.objects.forEach((obj) => {
                                mapBlock(a, obj, parentObject[index].objects)
                            })
                        } else if (block.options?.length) {
                            parentObject[index] = {
                                id: block.id,
                                options: {},
                            }
                            block.options.forEach((option, optionIndex) => {
                                parentObject[index].options[optionIndex] = {
                                    ...option,
                                    objects: {},
                                }
                                option.objects
                                    ?.filter((d) => d)
                                    .forEach((innerBlock: Block, i) => {
                                        if (innerBlock.options?.length) {
                                            mapBlock(
                                                a,
                                                innerBlock,
                                                parentObject[index].options[optionIndex].objects
                                            )
                                        } else if (innerBlock.branches?.length) {
                                            mapBlock(
                                                a,
                                                innerBlock,
                                                parentObject[index].options[optionIndex].objects
                                            )
                                        } else {
                                            a[innerBlock.id] = innerBlock
                                            parentObject[index].options[optionIndex].objects[i] = {
                                                id: innerBlock.id,
                                            }
                                        }
                                    })
                            })
                        } else if (block.responses?.length) {
                            parentObject[index] = {
                                id: block.id,
                                responses: {},
                            }
                            block.responses.forEach((response, optionIndex) => {
                                parentObject[index].responses[optionIndex] = {
                                    ...response,
                                    objects: {},
                                }
                                response.objects
                                    ?.filter((d) => d)
                                    .forEach((innerBlock: Block, i) => {
                                        if (innerBlock.responses?.length) {
                                            mapBlock(
                                                a,
                                                innerBlock,
                                                parentObject[index].responses[optionIndex].objects
                                            )
                                        } else if (innerBlock.branches?.length) {
                                            mapBlock(
                                                a,
                                                innerBlock,
                                                parentObject[index].responses[optionIndex].objects
                                            )
                                        } else {
                                            a[innerBlock.id] = innerBlock
                                            parentObject[index].responses[optionIndex].objects[i] =
                                                {
                                                    id: innerBlock.id,
                                                }
                                        }
                                    })
                            })
                        }
                        return a
                    }
                    return mapBlock(a, block, blocksIndex)
                },
                initialValue
            ) as { [key: string]: Block }
            state[`${action.payload.blocksType}Index`] = blocksIndex

            // reset the block versions data
            state.blockVersions = [
                {
                    blocks: state.blocks,
                    blocksIndex: state.blocksIndex,
                    previewBlocks: state.previewBlocks,
                    previewBlocksIndex: state.previewBlocksIndex,
                    synopsisBlocks: state.synopsisBlocks,
                    synopsisBlocksIndex: state.synopsisBlocksIndex,
                },
            ]

            // reset the lint warnings and advices
            state.lintWarningAndAdvices = {
                issues: [],
                indexes: {
                    [Severity.Warning]: undefined,
                    [Severity.Advice]: undefined,
                },
            }

            state.blockVersionIndex = 0

            return state
        },
        // The `replace` prop is useful when trying to delete an attribute from the block, but you MUST provide the entire
        // `block` to the function
        updateBlock: (
            state: BlocksState,
            action: PayloadAction<{
                blocksType: BlocksType
                id: string
                block: PartialBlock
                replace?: boolean
                blockIndex?: number
                branchId?: string
            }>
        ) => {
            const { id, block, blockIndex, branchId, replace } = action.payload
            const parent = branchId
                ? findBranch(branchId, state[`${action.payload.blocksType}Index`]).objects
                : state[`${action.payload.blocksType}Index`]
            let updatedBlock: Block
            updatedBlock = { ...(block as Block), id }
            if (updatedBlock.branches?.length && blockIndex !== undefined && replace) {
                parent[blockIndex].branches = {}
                updatedBlock.branches.forEach((branch, index) => {
                    const newObjects = branch.objects.reduce((accum: any, obj, index) => {
                        state[action.payload.blocksType][obj.id] =
                            state[action.payload.blocksType][obj.id] || obj
                        // obj would have branches if it's an Accordion, for example
                        if (obj.branches) {
                            const subBranch = obj.branches[0]
                            subBranch.objects.forEach((subBranchObj) => {
                                state[action.payload.blocksType][subBranchObj.id] = subBranchObj
                            })
                            accum[index] = {
                                id: obj.id,
                                branches: [{ id: subBranch.id, objects: subBranch.objects }],
                            }
                        } else {
                            accum[index] = { id: obj.id }
                        }
                        return accum
                    }, {})
                    parent[blockIndex].branches![index] = {
                        id: branch.id,
                        objects: newObjects,
                    }
                })
            } else {
                updatedBlock = {
                    ...state[action.payload.blocksType][id],
                    ...block,
                    id,
                }
            }
            const stateBlock = state[action.payload.blocksType][action.payload.id]
            if (
                stateBlock.save_to_variable &&
                stateBlock.save_to_variable !== updatedBlock.save_to_variable
            ) {
                findBranchesByVarName(
                    state[action.payload.blocksType],
                    stateBlock.save_to_variable
                ).map((branch) => {
                    branch!.test.var = updatedBlock.save_to_variable!
                })
            }
            state[action.payload.blocksType][action.payload.id] = updatedBlock

            // delete all the version after the block version index
            if (
                state.blockVersionIndex &&
                state.blockVersionIndex < state.blockVersions.length - 1
            ) {
                state.blockVersions.splice(state.blockVersionIndex + 1)
            }

            return state
        },
        updateBranch: (
            state: BlocksState,
            action: PayloadAction<{
                blocksType: BlocksType
                id: string
                branch: PartialConditionalBranch
            }>
        ) => {
            let stateBranch = findBranchInBlocks(
                action.payload.id,
                state[action.payload.blocksType]
            )
            stateBranch.test = action.payload.branch.test!
            return state
        },
        addBlocks: (
            state: BlocksState,
            action: PayloadAction<{
                blocksType: BlocksType
                blocks: Block[]
                branchId?: string
                index?: number
                sectionId?: string
                questionOptionId?: string
                freeTextResponseId?: string
            }>
        ) => {
            const {
                blocksType,
                branchId,
                index,
                blocks,
                sectionId,
                questionOptionId,
                freeTextResponseId,
            } = action.payload
            /*
             * What we're doing here is adding an `id` to the free text block's `responses`,
             * because we need them on the front end in order for the `ManageResponsesModal` to work
             * correctly, but the backend filters that field since it's not valid per the thread format.
             *
             * Note: This is for the new Free Text block `free_text_question`
             */
            addIdsToFreeTextResponses(blocks).forEach((block, currentIndex) =>
                addSingleBlock({
                    state,
                    blocksType,
                    block,
                    branchId,
                    index: !isNil(index) ? index + currentIndex : undefined,
                    sectionId,
                    questionOptionId,
                    freeTextResponseId,
                })
            )
            return state
        },
        deleteBlock: (
            state: BlocksState,
            action: PayloadAction<{
                blocksType: BlocksType
                blockId: string
                index: number
                branchId?: string
                sectionId?: string
                questionOptionId?: string
                freeTextResponseId?: string
            }>
        ) => {
            const {
                blocksType,
                branchId,
                blockId,
                index,
                sectionId,
                questionOptionId,
                freeTextResponseId,
            } = action.payload
            const { selectedBlockId } = deleteBlockById({
                id: blockId,
                blocks: state[blocksType],
                blockIndexes: state[`${blocksType}Index`],
                index,
                branchId,
                sectionId,
                questionOptionId,
                freeTextResponseId,
            })
            state.selectedBlockId = selectedBlockId
            return state
        },
        updateQuestionOptions: (
            state: BlocksState,
            action: PayloadAction<{
                blocksType: BlocksType
                blockId: string
                options: ChoiceQuestionOption[]
            }>
        ) => {
            const { blocksType, blockId, options } = action.payload
            const parent = state[`${blocksType}Index`]
            const oldBlock: Block = state[blocksType][blockId]

            // only support choice question and free text blocks
            if (![BlockType.CHOICE_QUESTION, BlockType.FREE_TEXT_QUESTION].includes(oldBlock.type))
                return state

            if (oldBlock.type === BlockType.CHOICE_QUESTION) {
                // update on blocks
                state[blocksType][blockId] = { ...oldBlock, options }
                // update on blocks index
                findBlock(blockId, parent).options = options
            } else if (oldBlock.type === BlockType.FREE_TEXT_QUESTION) {
                const responses = options.map((option) => ({
                    id: option.id,
                    input: option.text,
                    objects: option.objects,
                    correct: option.correct,
                }))
                // update on blocks
                state[blocksType][blockId] = {
                    ...oldBlock,
                    responses,
                }
                // update on blocks index
                findBlock(blockId, parent).responses = responses
            }

            return state
        },
        moveBlock: (
            state: BlocksState,
            action: PayloadAction<{
                blocksType: BlocksType
                blockId: string
                branchFrom: string
                branchTo: string
                indexFrom: number
                indexTo: number
            }>
        ) => {
            const { blockId, branchFrom, branchTo, indexFrom, indexTo, blocksType } = action.payload

            const fromBlock = !!branchFrom && state.blocks[branchFrom]
            const toBlock = !!branchTo && state.blocks[branchTo]

            const oldParent =
                fromBlock && fromBlock.type === BlockType.SECTION
                    ? findSection(fromBlock.id, state[`${blocksType}Index`]).objects!
                    : findQuestionOption(branchFrom, state[`${blocksType}Index`])?.objects ??
                      findBranch(branchFrom, state[`${blocksType}Index`])?.objects ??
                      state[`${blocksType}Index`]

            const newParent =
                toBlock && toBlock.type === BlockType.SECTION
                    ? findSection(toBlock.id, state[`${blocksType}Index`]).objects!
                    : findQuestionOption(branchTo, state[`${blocksType}Index`])?.objects ??
                      findBranch(branchTo, state[`${blocksType}Index`])?.objects ??
                      state[`${blocksType}Index`]

            if (branchFrom === branchTo) {
                const parent = oldParent
                const branches = parent[indexFrom].branches
                const objects = parent[indexFrom].objects
                const options = parent[indexFrom].options
                const responses = parent[indexFrom].responses
                if (indexFrom < indexTo) {
                    for (let i = indexFrom; i < indexTo; i++) {
                        parent[i] = parent[i + 1]
                    }
                } else {
                    for (let i = indexFrom; i > indexTo; i--) {
                        parent[i] = parent[i - 1]
                    }
                }
                parent[indexTo] = { id: blockId, branches, objects, options, responses }
            } else {
                const oldParentLength = Object.keys(oldParent).length
                const newParentLength = Object.keys(newParent).length
                const branches = _.cloneDeep(oldParent[indexFrom].branches)
                const objects = _.cloneDeep(oldParent[indexFrom].objects)
                const options = _.cloneDeep(oldParent[indexFrom].options)
                const responses = _.cloneDeep(oldParent[indexFrom].responses)
                for (let i = indexFrom; i < oldParentLength; i++) {
                    oldParent[i] = oldParent[i + 1]
                }
                delete oldParent[oldParentLength - 1]
                for (let i = newParentLength; i > indexTo; i--) {
                    newParent[i] = newParent[i - 1]
                }
                newParent[indexTo] = { id: blockId, branches, objects, options, responses }
            }
            return state
        },
        addBranch: (
            state: BlocksState,
            action: PayloadAction<{
                blocksType: BlocksType
                branch: ConditionalBranch
                blockId: string
            }>
        ) => {
            const { branch, blockId, blocksType } = action.payload
            const block = findBlock(blockId, state[`${blocksType}Index`])
            // We only support adding branches with 1 child
            const branchChild = branch.objects[0]
            if (block.branches && branchChild.branches) {
                block.branches[Object.keys(block.branches).length] = {
                    // todo fix - do branches always get added at the end?
                    id: branch.id,
                    objects: {
                        0: {
                            id: branchChild.id,
                            branches: [
                                {
                                    id: branchChild.branches[0].id,
                                    objects: branchChild.branches[0].objects.reduce(
                                        (accum, obj, index) => ({
                                            ...accum,
                                            [index]: { id: obj.id },
                                        }),
                                        {}
                                    ),
                                },
                            ],
                        },
                    },
                }
            } else
                block.branches = {
                    [branch.id]: {
                        id: branch.id,
                        objects: {
                            0: { id: branchChild.id },
                        },
                    },
                }
            state[blocksType][blockId].branches!.push(branch)
            state[blocksType][branchChild.id] = branch.objects[0]
            /*
             * Since now branches contain groups inside, we need to add that
             * group's children to the state[blocksType] map
             * */
            if (branchChild.branches) {
                branchChild.branches[0].objects.forEach((childObject) => {
                    state[blocksType][childObject.id] = childObject
                })
            }
            return state
        },
        deleteBranch: (
            state: BlocksState,
            action: PayloadAction<{ blocksType: BlocksType; branchId: string; blockId: string }>
        ) => {
            const { branchId, blockId } = action.payload
            const block = findBlock(blockId, state[`${action.payload.blocksType}Index`])
            const index = parseInt(
                Object.keys(block.branches!).find(
                    (d) => block.branches![parseInt(d)].id === branchId
                )!
            )
            if (block.branches) delete block.branches[index]
            for (let i = index; i < Object.keys(block.branches!).length; i++) {
                if (!block.branches![i + 1]) delete block.branches![i]
                else block.branches![i] = block.branches![i + 1]
            }
            state[action.payload.blocksType][blockId].branches!.splice(index, 1)
            return state
        },
        addQuestionOption: (
            state: BlocksState,
            action: PayloadAction<{
                blocksType: BlocksType
                option: ChoiceQuestionOption
                blockId: string
            }>
        ) => {
            const { option, blockId, blocksType } = action.payload
            const block = findBlock(blockId, state[`${blocksType}Index`])
            // We only support adding options with 1 child
            const optionChild = option.objects[0]
            if (optionChild.branches) {
                const optionToAdd = {
                    // todo fix - do branches always get added at the end?
                    id: option.id,
                    objects: {
                        0: {
                            id: optionChild.id,
                            branches: [
                                {
                                    id: optionChild.branches[0].id,
                                    objects: optionChild.branches[0].objects.reduce(
                                        (accum, obj, index) => ({
                                            ...accum,
                                            [index]: { id: obj.id },
                                        }),
                                        {}
                                    ),
                                },
                            ],
                        },
                    },
                }
                if (block.options) {
                    block.options[Object.keys(block.options).length] = optionToAdd
                } else {
                    block.options = { [0]: optionToAdd }
                }
            }
            if (isNil(state[blocksType][blockId].options)) {
                state[blocksType][blockId].options = [option]
            } else {
                // is safe to add `!` because the previous condition is checking for nullish
                state[blocksType][blockId].options!.push(option)
            }
            state[blocksType][optionChild.id] = option.objects[0]
            /*
             * Since options contain groups inside, we need to add that
             * group's children to the state[blocksType] map
             * */
            if (optionChild.branches) {
                optionChild.branches[0].objects.forEach((childObject) => {
                    state[blocksType][childObject.id] = childObject
                })
            }
            return state
        },
        addFreeTextResponse: (
            state: BlocksState,
            action: PayloadAction<{
                blocksType: BlocksType
                response: FreeTextResponse
                blockId: string
            }>
        ) => {
            const { response, blockId, blocksType } = action.payload
            const block = findBlock(blockId, state[`${blocksType}Index`])
            // We only support adding responses with 1 child
            const responseChild = response.objects[0]
            if (responseChild.branches) {
                const responseToAdd = {
                    // todo fix - do branches always get added at the end?
                    id: response.id,
                    objects: {
                        0: {
                            id: responseChild.id,
                            branches: [
                                {
                                    id: responseChild.branches[0].id,
                                    objects: responseChild.branches[0].objects.reduce(
                                        (accum, obj, index) => ({
                                            ...accum,
                                            [index]: { id: obj.id },
                                        }),
                                        {}
                                    ),
                                },
                            ],
                        },
                    },
                }
                if (block.responses) {
                    block.responses[Object.keys(block.responses).length] = responseToAdd
                } else {
                    block.responses = { [0]: responseToAdd }
                }
            }

            if (isNil(state[blocksType][blockId].responses)) {
                state[blocksType][blockId].responses = [response]
            } else {
                // is safe to add `!` because the previous condition is checking for nullish
                state[blocksType][blockId].responses!.push(response)
            }
            state[blocksType][responseChild.id] = response.objects[0]
            /*
             * Since responses contain groups inside, we need to add that
             * group's children to the state[blocksType] map
             * */
            if (responseChild.branches) {
                responseChild.branches[0].objects.forEach((childObject) => {
                    state[blocksType][childObject.id] = childObject
                })
            }
            return state
        },
        deleteQuestionOption: (
            state: BlocksState,
            action: PayloadAction<{ blocksType: BlocksType; optionId: string; blockId: string }>
        ) => {
            const { optionId, blockId } = action.payload
            const block = findBlock(blockId, state[`${action.payload.blocksType}Index`])
            const index = parseInt(
                Object.keys(block.options!).find(
                    (d) => block.options![parseInt(d)].id === optionId
                )!
            )
            if (block.options) delete block.options[index]
            for (let i = index; i < Object.keys(block.options!).length; i++) {
                if (!block.options![i + 1]) delete block.options![i]
                else block.options![i] = block.options![i + 1]
            }
            state[action.payload.blocksType][blockId].options!.splice(index, 1)
            return state
        },
        deleteFreeTextResponse: (
            state: BlocksState,
            action: PayloadAction<{ blocksType: BlocksType; responseId: string; blockId: string }>
        ) => {
            const { responseId, blockId } = action.payload
            const block = findBlock(blockId, state[`${action.payload.blocksType}Index`])
            const index = parseInt(
                Object.keys(block.responses!).find(
                    (d) => block.responses![parseInt(d)].id === responseId
                )!
            )
            if (block.responses) delete block.responses[index]
            for (let i = index; i < Object.keys(block.responses!).length; i++) {
                if (!block.responses![i + 1]) delete block.responses![i]
                else block.responses![i] = block.responses![i + 1]
            }
            state[action.payload.blocksType][blockId].responses!.splice(index, 1)
            return state
        },
        updateAutoSaveTimeout: (
            state: BlocksState,
            action: PayloadAction<
                Partial<{ editing: boolean; timeoutID: ReturnType<typeof setTimeout> }>
            >
        ) => {
            state.autoSaveTimeout = { ...state.autoSaveTimeout, ...action.payload }
            return state
        },
        addBlockVersion: (state: BlocksState) => {
            // delete the first element if the number of block versions reaches its max
            if (state.blockVersions.length + 1 > MAX_BLOCK_VERSIONS) state.blockVersions.shift()

            // delete all the version after the block version index
            if (
                state.blockVersionIndex &&
                state.blockVersionIndex < state.blockVersions.length - 1
            ) {
                state.blockVersions.splice(state.blockVersionIndex + 1)
            }

            state.blockVersions?.push({
                blocks: state.blocks,
                blocksIndex: state.blocksIndex,
                previewBlocks: state.previewBlocks,
                previewBlocksIndex: state.previewBlocksIndex,
                synopsisBlocks: state.synopsisBlocks,
                synopsisBlocksIndex: state.synopsisBlocksIndex,
            })

            state.blockVersionIndex = state.blockVersions.length - 1
            return state
        },
        undoBlockVersion: (state: BlocksState) => {
            const index = state.blockVersionIndex === undefined ? -1 : state.blockVersionIndex - 1
            if (index >= 0 && state.blockVersions[index]) {
                state.blocks = state.blockVersions[index].blocks
                state.blocksIndex = state.blockVersions[index].blocksIndex
                state.previewBlocks = state.blockVersions[index].previewBlocks
                state.previewBlocksIndex = state.blockVersions[index].previewBlocksIndex
                state.synopsisBlocks = state.blockVersions[index].synopsisBlocks
                state.synopsisBlocksIndex = state.blockVersions[index].synopsisBlocksIndex
                state.blockVersionIndex = index
            }
            return state
        },
        redoBlockVersion: (state: BlocksState) => {
            const index = state.blockVersionIndex === undefined ? -1 : state.blockVersionIndex + 1
            if (index >= 0 && state.blockVersions[index]) {
                state.blocks = state.blockVersions[index].blocks
                state.blocksIndex = state.blockVersions[index].blocksIndex
                state.previewBlocks = state.blockVersions[index].previewBlocks
                state.previewBlocksIndex = state.blockVersions[index].previewBlocksIndex
                state.synopsisBlocks = state.blockVersions[index].synopsisBlocks
                state.synopsisBlocksIndex = state.blockVersions[index].synopsisBlocksIndex
                state.blockVersionIndex = index
            }
            return state
        },
        updateLintWarningAndAdvices: (
            state: BlocksState,
            action: PayloadAction<NonNullable<GetLintThreadQuery["lintThread"]>>
        ) => {
            state.lintWarningAndAdvices.issues = action.payload
            return state
        },
        moveWarningOrAdviseCursor: (
            state: BlocksState,
            action: PayloadAction<{ severity: Severity; cursorMovement: CursorMovement }>
        ) => {
            const filterIssues = state.lintWarningAndAdvices.issues?.filter(
                (issue) => issue.severity === action.payload.severity
            )

            // if there are no issues that match the severity reset the correspondent index and return
            if (_.isEmpty(filterIssues)) {
                state.lintWarningAndAdvices.indexes[action.payload.severity] = undefined
                return state
            }

            const currentIndex = state.lintWarningAndAdvices.indexes[action.payload.severity]
            let temporalIndex

            if (action.payload.cursorMovement === CursorMovement.next) {
                // if there is no current index or we are already at the very last index, go back to index 0
                if (currentIndex === undefined || currentIndex === filterIssues.length - 1)
                    temporalIndex = 0
                else temporalIndex = currentIndex + 1
            } else {
                // if there is no current index or we are already at the very first index, go back to last index
                if (currentIndex === undefined || currentIndex === 0)
                    temporalIndex = filterIssues.length - 1
                else temporalIndex = currentIndex - 1
            }

            // this check is done to avoid getting stuck if there is only one issue, because otherwise the index won't change
            // todo find a better way to do this
            if (currentIndex === 0 && filterIssues.length === 1) temporalIndex = undefined

            state.lintWarningAndAdvices.indexes[action.payload.severity] = temporalIndex

            return state
        },
        selectBlock: (
            state: BlocksState,
            action: PayloadAction<{ blockId: string | undefined }>
        ) => {
            state.selectedBlockId = action.payload.blockId
            return state
        },
        selectChunk: (
            state: BlocksState,
            action: PayloadAction<{ chunkId: string | undefined }>
        ) => {
            state.selectedChunkId = action.payload.chunkId
            return state
        },
        duplicateBlock: (
            state: BlocksState,
            action: PayloadAction<{
                blockId: string
                blocksType: BlocksType
                blockIndex: number
                branchId?: string
                sectionId?: string
                questionOptionId?: string
                freeTextResponseId?: string
                callback?: () => void
            }>
        ) => {
            // this action does nothing on the reducer, all the handling is done on the middleware
            return state
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setBlocks,
    updateBlock,
    addBlocks,
    deleteBlock,
    updateQuestionOptions,
    moveBlock,
    updateBranch,
    addBranch,
    deleteBranch,
    addQuestionOption,
    deleteQuestionOption,
    addFreeTextResponse,
    deleteFreeTextResponse,
    updateAutoSaveTimeout,
    addBlockVersion,
    undoBlockVersion,
    redoBlockVersion,
    updateLintWarningAndAdvices,
    moveWarningOrAdviseCursor,
    clearTimeouts,
    selectBlock,
    selectChunk,
    duplicateBlock,
} = blocksSlice.actions

export const findBranchesByVarName = (blocks: BlocksReducer, variable: string) => {
    return Object.values(blocks)
        .map((block) => block.branches?.filter((branch) => branch.test.var === variable))
        .filter((d) => d)
        .flat(1)
}

export const findBlock = (
    blockId: string,
    blocksIndex: { [key: number]: ReadOnlyBlock }
): ReadOnlyBlock => {
    const findObjects = (
        blockId: string,
        objects: { [key: number]: ReadOnlyBlock }
    ): ReadOnlyBlock => {
        return Object.entries(objects)
            .map(([id, block]) => {
                if (block.id === blockId) return block
                if (block.branches) {
                    return Object.entries(block.branches).map(([id, branch]) => {
                        return findObjects(blockId, branch.objects)
                    })
                }
                if (block.objects) {
                    return findObjects(blockId, block.objects)
                }
                if (block.options) {
                    return Object.entries(block.options).map(([id, option]) => {
                        return findObjects(blockId, option.objects)
                    })
                }
                if (block.responses) {
                    return Object.entries(block.responses).map(([id, response]) => {
                        return findObjects(blockId, response.objects)
                    })
                }
                return undefined
            })
            .flat(1000)
            .filter((d) => d)[0]! // todo fix flat
    }
    return findObjects(blockId, blocksIndex)
}

export const findBranch = (branchId: string, blocksIndex: BlocksIndexReducer) => {
    const findObjects = (
        branchId: string,
        objects: { [key: number]: ReadOnlyBlock }
    ): ReadOnlyConditionalBranch => {
        return Object.entries(objects)
            .map(([id, block]) => {
                if (block.branches) {
                    return Object.entries(block.branches).map(([_, branch]) => {
                        if (branch.id === branchId) return branch
                        return findObjects(branchId, branch.objects)
                    })
                }
                if (block.objects) {
                    return findObjects(branchId, block.objects)
                }
                if (block.options) {
                    return Object.entries(block.options).map(([_, option]) => {
                        return findObjects(branchId, option.objects)
                    })
                }
                if (block.responses) {
                    return Object.entries(block.responses).map(([_, response]) => {
                        return findObjects(branchId, response.objects)
                    })
                }
                return undefined
            })
            .flat(1000)
            .filter((d) => d)[0]! // todo fix flat
    }
    return findObjects(branchId, blocksIndex)
}

export const findSection = (
    sectionId: string,
    objects: { [key: number]: ReadOnlyBlock }
): ReadOnlyBlock => {
    // we assume that the section will always being found, is the same assumption we did on `findBranch`
    return Object.entries(objects).find(([_, block]) => block.id === sectionId)![1]
}

export const findQuestionOption = (
    questionOptionId: string,
    blocksIndex: BlocksIndexReducer
): ReadOnlyBlock => {
    const findObjects = (
        questionOptionId: string,
        objects: { [key: number]: ReadOnlyBlock }
    ): ReadOnlyQuestionOption => {
        return Object.entries(objects)
            .map(([id, block]) => {
                if (block.branches) {
                    return Object.entries(block.branches).map(([_, branch]) => {
                        return findObjects(questionOptionId, branch.objects)
                    })
                }
                if (block.objects) {
                    return findObjects(questionOptionId, block.objects)
                }
                if (block.options) {
                    return Object.entries(block.options).map(([_, option]) => {
                        if (option.id === questionOptionId) return option
                        return findObjects(questionOptionId, option.objects)
                    })
                }
                if (block.responses) {
                    return Object.entries(block.responses).map(([_, response]) => {
                        return findObjects(questionOptionId, response.objects)
                    })
                }
                return undefined
            })
            .flat(1000)
            .filter((d) => d)[0]! // todo fix flat
    }
    return findObjects(questionOptionId, blocksIndex)
}

export const findFreeTextResponse = (
    freeTextResponseId: string,
    blocksIndex: BlocksIndexReducer
): ReadOnlyBlock => {
    const findObjects = (
        freeTextResponseId: string,
        objects: { [key: number]: ReadOnlyBlock }
    ): ReadOnlyQuestionOption => {
        return Object.entries(objects)
            .map(([id, block]) => {
                if (block.branches) {
                    return Object.entries(block.branches).map(([_, branch]) => {
                        return findObjects(freeTextResponseId, branch.objects)
                    })
                }
                if (block.objects) {
                    return findObjects(freeTextResponseId, block.objects)
                }
                if (block.options) {
                    return Object.entries(block.options).map(([_, option]) => {
                        return findObjects(freeTextResponseId, option.objects)
                    })
                }
                if (block.responses) {
                    return Object.entries(block.responses).map(([_, response]) => {
                        if (response.id === freeTextResponseId) return response
                        return findObjects(freeTextResponseId, response.objects)
                    })
                }
                return undefined
            })
            .flat(1000)
            .filter((d) => d)[0]! // todo fix flat
    }
    return findObjects(freeTextResponseId, blocksIndex)
}

export const findBranchInBlocks = (branchId: string, blocks: BlocksReducer) => {
    const findObjects = (branchId: string, objects: Block[]): ConditionalBranch => {
        return Object.entries(objects)
            .map(([id, block]) => {
                if (block.branches?.length) {
                    return block.branches.map((branch) => {
                        if (branch.id === branchId) return branch
                        return findObjects(branchId, branch.objects)
                    })
                }
                return undefined
            })
            .flat(1000)
            .filter((d) => d)[0]! // todo fix flat
    }
    return findObjects(branchId, Object.values(blocks))
}

export const buildBlocksForSaving = (
    blocks: { [key: string]: Block },
    blocksIndex: { [key: number]: ReadOnlyBlock }
): Block[] => {
    const resultBlocks: Block[] = []
    const buildObjects = (objects: { [key: number]: ReadOnlyBlock }, resultBlocksAcc: Block[]) => {
        Object.entries(objects).map(([id, block], index) => {
            let stateBlock = blocks[block.id]
            if (
                stateBlock &&
                resultBlocksAcc.filter((d) => d && d.id === stateBlock.id).length === 0
            ) {
                resultBlocksAcc.push(_.cloneDeep(stateBlock))
                if (block.branches) {
                    return Object.entries(block.branches).map(([id, branch], i) => {
                        resultBlocksAcc[index].branches![i].objects = []
                        return buildObjects(
                            branch.objects,
                            resultBlocksAcc[index].branches![i].objects
                        )
                    })
                }
                if (block.options) {
                    return Object.entries(block.options).map(([id, option], i) => {
                        resultBlocksAcc[index].options![i].objects = []
                        return buildObjects(
                            option.objects,
                            resultBlocksAcc[index].options![i].objects
                        )
                    })
                }
                if (block.responses) {
                    return Object.entries(block.responses).map(([id, response], i) => {
                        resultBlocksAcc[index].responses![i].objects = []
                        return buildObjects(
                            response.objects,
                            resultBlocksAcc[index].responses![i].objects
                        )
                    })
                }
                if (block.objects) {
                    resultBlocksAcc[index].objects = []
                    return buildObjects(block.objects, resultBlocksAcc[index].objects!)
                }
            }
            return undefined
        })
    }
    buildObjects(blocksIndex, resultBlocks)
    return resultBlocks
}

export const getFullBlockById = (
    blockId: string,
    blocks: { [key: string]: Block },
    blocksIndex: { [key: number]: ReadOnlyBlock }
): Block => {
    const readOnlyBlock = findBlock(blockId, blocksIndex)
    const fields = ["branches", "options", "responses"] as const
    type Field = typeof fields[number]

    const mapField = (fieldName: Field) => {
        return readOnlyBlock[fieldName]
            ? Object.values(readOnlyBlock[fieldName]!).map((branch, index: number) => ({
                  ...blocks[blockId][fieldName]![index],
                  objects: Object.values(branch.objects).map((childBlock: ReadOnlyBlock) =>
                      getFullBlockById(childBlock.id, blocks, blocksIndex)
                  ),
              }))
            : undefined
    }

    const map = new Map()
    fields.map((field) => map.set(field, mapField(field)))

    const result = {
        ...blocks[blockId],
        ...Object.fromEntries(map),
    }

    fields.forEach((field) => {
        if (!result[field]) delete result[field]
    })

    return result
}

// This method receives blocks and blockIndexes directly from the reducer's state and modifies it
export const deleteBlockById = ({
    id,
    blocks,
    blockIndexes,
    index,
    branchId,
    sectionId,
    questionOptionId,
    freeTextResponseId,
}: {
    id: string
    blocks: BlocksReducer
    blockIndexes: BlocksIndexReducer
    index: number
    branchId?: string
    sectionId?: string
    questionOptionId?: string
    freeTextResponseId?: string
}) => {
    // delete the block by id
    delete blocks[id]
    // get the block parent
    const parent = branchId
        ? findBranch(branchId, blockIndexes).objects
        : sectionId
        ? findSection(sectionId, blockIndexes).objects!
        : questionOptionId
        ? findQuestionOption(questionOptionId, blockIndexes).objects!
        : freeTextResponseId
        ? findFreeTextResponse(freeTextResponseId, blockIndexes).objects!
        : blockIndexes
    const previousBlockId = parent[index - 1]?.id
    // shift the empty space left
    for (let i = index; i < Object.keys(parent).length; i++) {
        parent[i] = parent[i + 1]
    }
    // delete the last element (will be empty because of the shifting done)
    delete parent[Object.keys(parent).length - 1]

    return { selectedBlockId: previousBlockId }
}

export const addSingleBlock = ({
    state,
    blocksType,
    block,
    branchId,
    index,
    sectionId,
    questionOptionId,
    freeTextResponseId,
}: {
    state: BlocksState
    blocksType: BlocksType
    block: Block
    branchId?: string
    index?: number
    sectionId?: string
    questionOptionId?: string
    freeTextResponseId?: string
}) => {
    const parent = branchId
        ? findBranch(branchId, state[`${blocksType}Index`]).objects
        : sectionId
        ? findSection(sectionId, state[`${blocksType}Index`]).objects!
        : questionOptionId
        ? findQuestionOption(questionOptionId, state[`${blocksType}Index`]).objects!
        : freeTextResponseId
        ? findFreeTextResponse(freeTextResponseId, state[`${blocksType}Index`]).objects!
        : state[`${blocksType}Index`]
    const blockIndex = isNil(index) ? Object.keys(parent).length : index
    const nextBlockId = parent[blockIndex]?.id
    const nextBlock = state[blocksType][nextBlockId]

    if (nextBlock) {
        for (let i = Object.keys(parent).length; i > blockIndex; i--) {
            parent[i] = parent[i - 1]
        }
    }
    parent[blockIndex] = { id: block.id }
    state[blocksType][block.id] = block
    if (block.branches?.length) {
        const reduceBranches = (
            branches: ConditionalBranch[]
        ): { [index: number]: ConditionalBranch } => {
            return branches.reduce(
                (accum, branch, index) => ({
                    ...accum,
                    [index]: {
                        id: branch.id,
                        objects: branch.objects.reduce((accum, childBlock, index) => {
                            /*
                             * In this line we update the map that
                             * contains all blocks in a linear way
                             */
                            state[blocksType][childBlock.id] = childBlock
                            const result: { [index: number]: Block } = {
                                ...accum,
                                [index]: {
                                    id: childBlock.id,
                                    branches: childBlock.branches
                                        ? reduceBranches(childBlock.branches)
                                        : undefined,
                                },
                            }
                            !result[index].branches && delete result[index].branches
                            return result
                        }, {}),
                    },
                }),
                {}
            )
        }
        parent[blockIndex].branches = reduceBranches(block.branches)
    } else if (block.objects?.length && block.type === BlockType.SECTION) {
        parent[blockIndex].objects = {}
        block.objects.forEach((object, index) =>
            addSingleBlock({ state, blocksType, block: object, sectionId: block.id, index })
        )
    } else if (block.options?.length && block.type === BlockType.CHOICE_QUESTION) {
        const reduceOptions = (
            options: ChoiceQuestionOption[]
        ): { [index: number]: ChoiceQuestionOption } => {
            return options.reduce(
                (accum, option, index) => ({
                    ...accum,
                    [index]: {
                        id: option.id,
                        objects: option.objects.reduce((accum, childBlock, index) => {
                            /*
                             * In this line we update the map that
                             * contains all blocks in a linear way
                             */
                            state[blocksType][childBlock.id] = childBlock
                            const result: { [index: number]: Block } = {
                                ...accum,
                                [index]: {
                                    id: childBlock.id,
                                    options: childBlock.options
                                        ? reduceOptions(childBlock.options)
                                        : undefined,
                                    branches: childBlock.branches
                                        ? reduceOptions(childBlock.branches)
                                        : undefined,
                                    responses: childBlock.responses
                                        ? reduceOptions(childBlock.responses)
                                        : undefined,
                                },
                            }
                            !result[index].options && delete result[index].options
                            !result[index].branches && delete result[index].branches
                            !result[index].responses && delete result[index].responses
                            return result
                        }, {}),
                    },
                }),
                {}
            )
        }
        parent[blockIndex].options = reduceOptions(block.options)
    } else if (block.responses?.length && block.type === BlockType.FREE_TEXT_QUESTION) {
        const reduceResponses = (
            responses: FreeTextResponse[]
        ): { [index: number]: FreeTextResponse } => {
            return responses.reduce(
                (accum, response, index) => ({
                    ...accum,
                    [index]: {
                        id: response.id,
                        objects: response.objects?.reduce((accum, childBlock, index) => {
                            /*
                             * In this line we update the map that
                             * contains all blocks in a linear way
                             */
                            state[blocksType][childBlock.id] = childBlock
                            const result: { [index: number]: Block } = {
                                ...accum,
                                [index]: {
                                    id: childBlock.id,
                                    options: childBlock.options
                                        ? reduceResponses(childBlock.options)
                                        : undefined,
                                    branches: childBlock.branches
                                        ? reduceResponses(childBlock.branches)
                                        : undefined,
                                    responses: childBlock.responses
                                        ? reduceResponses(childBlock.responses)
                                        : undefined,
                                },
                            }
                            !result[index].options && delete result[index].options
                            !result[index].branches && delete result[index].branches
                            !result[index].responses && delete result[index].responses
                            return result
                        }, {}),
                    },
                }),
                {}
            )
        }
        parent[blockIndex].responses = reduceResponses(block.responses)
    }
    state.selectedBlockId = block.id
}

export default blocksSlice.reducer
