import ReactDOM from "react-dom"
import App from "./App"
import "react-quill/dist/quill.bubble.css"
import "react-quill/dist/quill.snow.css"
import "react-tabs/style/react-tabs.css"
import "emoji-mart/css/emoji-mart.css"
import "react-multi-carousel/lib/styles.css"
import "./index.css"
import "./styles/main.scss"
import "./styles/variables.module.scss"
import "react-toastify/dist/ReactToastify.css"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "@szhsin/react-menu/dist/index.css"
import "react-multi-carousel/lib/styles.css"
import { ApolloClient, ApolloLink, ApolloProvider, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import { ThemeProvider, useTheme } from "styled-components"
import { theme } from "./styles/styledcomponents/theme"
import smoothscroll from "smoothscroll-polyfill"
import useLayout from "./hooks/layout.hook"
import { asyncErrorVar, cacheStore } from "./apollo/cache-store"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material"
import type {} from "@mui/x-date-pickers/themeAugmentation"
import { createUploadLink } from "apollo-upload-client"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { getMainDefinition } from "@apollo/client/utilities"
import { StyledToastContainer } from "./components/Toast/styles"
import { rgba } from "polished"
import ErrorReporter, { AsyncErrorCatcher } from "./components/ErrorReporter"
import { GlobalStyles } from "./styles/styledcomponents"

// force the usage of a smoothscroll polyfill on ALL browsers for
// consistent behavior and to address issues in browser implementations
// of "smooth" behavior that don't do the right thing when two smooth
// scrolls are requested around the same time.
window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill({ scrollTime: 468 })

window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault()
    // Stash the event so it can be triggered later.
    // const deferredPrompt = e
    // Update UI notify the user they can install the PWA
    // Optionally, send analytics event that PWA install promo was shown.
    console.log(`The 'beforeinstallprompt' event was fired!`)
})

const localLink = createUploadLink({
    uri: "http://localhost:8080/graphql/query",
    credentials: "include",
})

const utilsLink = createUploadLink({
    uri: process.env.REACT_APP_UTILS_URL,
    credentials: "include",
})

const authLink = setContext((_, { headers }) => {
    // token must be inside this function, so it can get updated if changes
    const token = localStorage.getItem("token")
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    }
})

const wsLink = () => {
    // token must be inside this function, so it can get updated if changes
    return new GraphQLWsLink(
        createClient({
            url: process.env.REACT_APP_UTILS_WS_URL || "",
            connectionParams: () => {
                const token = localStorage.getItem("token")
                return {
                    authorization: token ? `Bearer ${token}` : "",
                }
            },
        })
    )
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
    const errors = graphQLErrors ?? [networkError]
    let fatal = false
    errors.forEach((error) => {
        // if all reported errors are INFORMATIONAL then don't intercept
        if (!error || !("extensions" in error) || (error as any).extensions.severity !== "INFO") {
            fatal = true
        }
    })
    if (fatal) asyncErrorVar(errors)
})

export const apolloClient = new ApolloClient({
    cache: cacheStore,
    link: from([
        errorLink,
        ApolloLink.split(
            ({ query }) => {
                const definition = getMainDefinition(query)
                return (
                    definition.kind === "OperationDefinition" &&
                    definition.operation === "subscription"
                )
            },
            wsLink(),
            ApolloLink.split(
                (operation) => operation.getContext().clientName === "local",
                // @ts-ignore
                authLink.concat(localLink),
                // @ts-ignore
                authLink.concat(utilsLink)
            )
        ),
    ]),
    credentials: "include",
})

const CustomThemeProvider: React.FC = ({ children }) => {
    const { isMobile } = useLayout()

    return <ThemeProvider theme={theme(isMobile)}>{children}</ThemeProvider>
}

const CustomMaterialThemeProvider: React.FC = ({ children }) => {
    const styledComponentsTheme = useTheme()
    const { isMobile } = useLayout()

    const theme = createTheme({
        palette: {
            primary: {
                main: styledComponentsTheme.headlandsPrimaryColor,
            },
        },
        components: {
            MuiPickersPopper: {
                styleOverrides: {
                    root: {
                        margin: "12px 0 !important",
                    },
                    paper: {
                        borderRadius: styledComponentsTheme.borderRadius1,
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    today: {
                        backgroundColor: rgba(styledComponentsTheme.headlandsPrimaryColor, 0.2),
                        border: "1px solid transparent !important",
                        ":hover": {
                            backgroundColor: rgba(styledComponentsTheme.headlandsPrimaryColor, 0.2),
                        },
                    },
                },
            },
        },
    })

    return (
        <MuiThemeProvider theme={theme}>
            <GlobalStyles isMobile={isMobile} />
            {children}
        </MuiThemeProvider>
    )
}

ReactDOM.render(
    <ApolloProvider client={apolloClient}>
        <CustomThemeProvider>
            <ErrorReporter>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CustomMaterialThemeProvider>
                        <App />
                        <AsyncErrorCatcher />
                        <StyledToastContainer />
                    </CustomMaterialThemeProvider>
                </LocalizationProvider>
            </ErrorReporter>
        </CustomThemeProvider>
    </ApolloProvider>,
    document.getElementById("root")
)
