import { useTheme } from "styled-components"
import { IconProps } from "../../types"

export const CompletedIcon = ({ color }: IconProps) => {
    const theme = useTheme()
    const defaultColor = theme?.colors.base.uiPrimary500
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.8916 14.2305H5.2998C5.40234 14.2305 5.46387 14.2578 5.5459 14.333L6.53711 15.3242C7.51465 16.3018 8.47852 16.3018 9.45605 15.3242L10.4541 14.333C10.5293 14.2578 10.5908 14.2305 10.6934 14.2305H12.1016C13.4824 14.2305 14.166 13.5469 14.166 12.166V10.7578C14.166 10.6621 14.1934 10.5869 14.2686 10.5186L15.2598 9.52051C16.2373 8.54297 16.2373 7.5791 15.2598 6.60156L14.2686 5.61035C14.1865 5.52832 14.166 5.4668 14.166 5.37109V3.96289C14.166 2.5752 13.4756 1.8916 12.1016 1.8916H10.6934C10.5908 1.8916 10.5225 1.86426 10.4541 1.7959L9.45605 0.804688C8.47168 -0.186523 7.51465 -0.186523 6.53711 0.811523L5.5459 1.7959C5.4707 1.86426 5.40234 1.8916 5.2998 1.8916H3.8916C2.51074 1.8916 1.82715 2.56836 1.82715 3.96289V5.37109C1.82715 5.4668 1.7998 5.53516 1.73145 5.61035L0.733398 6.60156C-0.244141 7.5791 -0.244141 8.54297 0.733398 9.52051L1.73145 10.5186C1.7998 10.5869 1.82715 10.6621 1.82715 10.7578V12.166C1.82715 13.5469 2.51758 14.2305 3.8916 14.2305ZM7.25488 11.5576C6.89941 11.5576 6.61914 11.3867 6.38672 11.1064L4.84863 9.31543C4.65723 9.08984 4.58887 8.90527 4.58887 8.67285C4.58887 8.18066 4.99902 7.79102 5.49121 7.79102C5.77148 7.79102 5.9834 7.90723 6.1748 8.12598L7.24805 9.37012L9.74316 5.43945C9.96191 5.09766 10.208 4.92676 10.5361 4.92676C11.0215 4.92676 11.459 5.28906 11.459 5.78125C11.459 5.96582 11.3975 6.1709 11.2539 6.36914L8.0957 11.0859C7.89746 11.373 7.58984 11.5576 7.25488 11.5576Z"
                fill={color ?? defaultColor}
            />
        </svg>
    )
}
