import { ChangeEvent, useState } from "react"
import "./Input.scss"
import classNames from "classnames"
import TextareaAutosize from "react-textarea-autosize"

export interface BaseInputProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>, "onChange"> {
    value: string
    placeholder?: string
    type?: string
    name?: string
    error?: string
    disabled?: boolean
    readonly?: boolean
    tabIndex?: number
    inputStyle?: React.CSSProperties
    minRows?: number
    maxRows?: number
    secondary?: boolean
    className?: string
    label?: string
    showErrorLabel?: boolean
}

export interface onChangeInputProps extends BaseInputProps {
    onChange: (value: string) => any
    onChangeEvent?: never
}

export interface onChangeEventInputProps extends BaseInputProps {
    onChangeEvent: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onChange?: never
}

export type InputProps = onChangeInputProps | onChangeEventInputProps

const numbersOnly = (value: string) => value.replaceAll(/[^0-9]+/g, "")

const Input = ({
    value,
    placeholder,
    onChange,
    onChangeEvent,
    type,
    name,
    error,
    disabled,
    readonly,
    tabIndex,
    inputStyle,
    secondary,
    className,
    showErrorLabel,
    ...props
}: InputProps) => {
    const [touched, setTouched] = useState(false)

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeEvent
            ? onChangeEvent(e)
            : type === "numeric"
            ? onChange(numbersOnly(e.target.value))
            : onChange(e.target.value)

    const inputProps = {
        className: classNames("input", className, {
            error: error && (value || touched),
            disabled,
            secondary,
            readonly,
        }),
        onChange: handleChange,
        value: value,
        placeholder: placeholder,
        type: type,
        name: name,
        autoComplete: type === "password" ? "new-password" : "",
        onBlur: () => setTouched(true),
        disabled,
        tabIndex: tabIndex,
        style: inputStyle,
        ...props,
    }

    const textareaAutosizeProps = {
        ...inputProps,
        // manually handling the disabled cause a crash bug on android
        disabled: false,
        // avoid cursor to be positioned on the input
        readOnly: disabled,
        onChange: !disabled ? handleChange : undefined,
    }

    const textAreaAutosizeStyle = inputProps.style as Omit<
        NonNullable<React.TextareaHTMLAttributes<HTMLTextAreaElement>["style"]>,
        "maxHeight" | "minHeight"
    > & {
        height?: number
    }

    return (
        <>
            {props.minRows || props.maxRows ? (
                /*
                 * styles is undefined because the library isn't
                 * compatible with React.CSSProperties type
                 */
                <TextareaAutosize {...textareaAutosizeProps} style={textAreaAutosizeStyle} />
            ) : (
                <input {...inputProps} type={type} />
            )}
            {showErrorLabel && error && (value || touched) && (
                <div className="error-label">
                    <span className={"error-icon"}>!</span>
                    {error}
                </div>
            )}
        </>
    )
}

export default Input
