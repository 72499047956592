import ControlPointDuplicateOutlinedIcon from "@mui/icons-material/ControlPointDuplicateOutlined"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined"
import DeleteIcon from "@mui/icons-material/Delete"
import PopupMenu, { PopupOptionType } from "../../../../components/PopupMenu"
import { ThreadOutlineBarItemHandlers } from "./index"
import { useTheme } from "styled-components"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { PopupProps } from "reactjs-popup/dist/types"

interface ThreadOutlineBarItemMenuProps
    extends Pick<PopupProps, "trigger" | "position">,
        ThreadOutlineBarItemHandlers {
    blockId: string
    blockIndex: number
    sectionId?: string
    isSection?: boolean
    onOpen?: () => void
    onClose?: () => void
}
const ThreadOutlineBarItemMenu = ({
    trigger,
    position = "right center",
    blockId,
    blockIndex,
    sectionId,
    isSection,
    onOpen,
    onClose,
    ...commonHandlers
}: ThreadOutlineBarItemMenuProps) => {
    const theme = useTheme()
    const isHidden = useSelector((state: RootState) => state.blocksReducer.blocks[blockId]?.hidden)

    return (
        <PopupMenu
            position={position}
            on={["click"]}
            onOpen={onOpen}
            onClose={onClose}
            arrow={false}
            overlayStyle={{ backgroundColor: "transparent" }}
            options={[
                {
                    label: "Preview",
                    onClick: () => commonHandlers.handlePreview(true, blockId),
                    icon: PlayArrowOutlinedIcon,
                    type: PopupOptionType.TEXT_AND_ICON,
                },
                {
                    label: "Duplicate",
                    onClick: () => commonHandlers.handleDuplicate(blockId, blockIndex, sectionId),
                    noDivider: true,
                    icon: ControlPointDuplicateOutlinedIcon,
                    type: PopupOptionType.TEXT_AND_ICON,
                },
                {
                    label: isHidden
                        ? `Show ${isSection ? "Section" : "Block"}`
                        : `Hide ${isSection ? "Section" : "Block"}`,
                    onClick: () => commonHandlers.handleToggleVisibility(blockId, isHidden),
                    noDivider: isSection,
                    icon: isHidden ? VisibilityOutlinedIcon : VisibilityOffOutlinedIcon,
                    type: PopupOptionType.TEXT_AND_ICON,
                },
                {
                    label: "Delete",
                    onClick: () => commonHandlers.handleDelete(blockId, blockIndex, sectionId),
                    styles: { color: `${theme.colors.base.uiError500}!important` },
                    noDivider: true,
                    icon: DeleteIcon,
                    type: PopupOptionType.TEXT_AND_ICON,
                },
            ]}
            trigger={trigger}
        />
    )
}

export default ThreadOutlineBarItemMenu
