import styled, { css } from "styled-components"
import { StyledBody2, StyledColumn } from "../../../../styles/styledcomponents"
import { ArrowForward } from "@mui/icons-material"

export const StyledTemplate = styled(StyledColumn)<{ disabled?: boolean }>`
    width: 300px;
    height: 180px;
    gap: 12px;
    padding: 18px 12px;
    background-color: ${({ theme }) => theme.colors.base.uiBgTertiary};
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    border-radius: 8px;
    transition: all 0.2s;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.base.uiBgBase};
        box-shadow: ${({ theme }) => theme.shadows.small};
        transform: translateY(-6px);
    }

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
            transform: none !important;
            box-shadow: none !important;
            background-color: ${({ theme }) => theme.colors.base.uiBgTertiary} !important;
        `}
`

export const StyledIconContainer = styled(StyledColumn)`
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.base.uiPrimary300};
    border-radius: 50%;
`

export const StyledArrowRight = styled(ArrowForward)`
    color: ${({ theme }) => theme.colors.base.uiPrimary500};
    font-size: 14px !important;
`

export const StyledComingSoon = styled(StyledBody2)`
    width: fit-content;
    padding: 0 6px;
    color: ${({ theme }) => theme.colors.base.uiPrimary300};
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.base.uiPrimary500};
    background: ${({ theme }) => theme.colors.base.uiPrimary400};
`
