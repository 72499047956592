import {
    StyledAddIcon,
    StyledFooterButton,
    StyledThreadOutlineBar,
    StyledThreadOutlineBarContent,
    StyledThreadOutlineBarFooter,
    StyledThreadOutlineBarHeader,
} from "./styles"
import { StyledH5, StyledSpan } from "../../../styles/styledcomponents"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { Block, BlocksType, SortableOutlineGroupType } from "../../../types"
import {
    addBlocks,
    deleteBlock,
    duplicateBlock,
    moveBlock,
    updateBlock,
} from "../../../redux/blocks"
import ThreadOutlineBarItem from "./ThreadOutlineBarItem"
import SortableList, { MoveItemArguments } from "../../../components/SortableList"
import { getEmptySectionBlock } from "../../../utils/utils"
import { useCallback } from "react"

interface ThreadOutlineBarProps {
    handlePreview: (previewMode: boolean, initialBlockId?: string) => void
}
const ThreadOutlineBar = ({ handlePreview }: ThreadOutlineBarProps) => {
    const dispatch = useDispatch()
    const blocksIndex = useSelector(
        (state: RootState) => state.blocksReducer[`${BlocksType.BLOCKS}Index`]
    )

    /*
     * This function handles the addition of a chunk
     * */
    const handleAddChunk = useCallback(
        (chunk: Block, index?: number, sectionId?: string) => {
            dispatch(
                addBlocks({
                    blocksType: BlocksType.BLOCKS,
                    blocks: [chunk],
                    index,
                    sectionId,
                })
            )
        },
        [dispatch]
    )

    /*
     * This function handles the deletion of an item (a chunk or a section)
     * */
    const handleDelete = (blockId: string, index: number, sectionId?: string) => {
        dispatch(deleteBlock({ blocksType: BlocksType.BLOCKS, blockId, index, sectionId }))
    }

    /*
     * This function handles the toggle of the 'hidden' bit of an item (a chunk or a section)
     * */
    const handleToggleVisibility = (blockId: string, isHidden?: boolean) => {
        dispatch(
            updateBlock({
                blocksType: BlocksType.BLOCKS,
                id: blockId,
                block: { hidden: !isHidden },
            })
        )
    }
    /*
     * This function handles the duplication of an item (a chunk or a section)
     * */
    const handleDuplicate = (blockId: string, index: number, sectionId?: string) => {
        dispatch(
            duplicateBlock({
                blocksType: BlocksType.BLOCKS,
                blockId,
                blockIndex: index,
                sectionId,
            })
        )
    }

    const commonHandlers = {
        handleAddChunk,
        handleDelete,
        handleToggleVisibility,
        handleDuplicate,
        handlePreview,
    }

    const moveItem = (args: MoveItemArguments) => {
        const { id, groupFrom, groupTo, indexFrom, indexTo } = args
        dispatch(
            moveBlock({
                blocksType: BlocksType.BLOCKS,
                blockId: id,
                branchFrom: groupFrom,
                branchTo: groupTo,
                indexFrom,
                /*
                 * The duplicated block adds 1 item to the list, but when we finish moving the block the
                 * duplicated one gets deleted that's why we have to subtract 1 to the 'indexTo'. This is
                 * only required when moving from a list to the same list, if we move from a group to a
                 * another group we don't have to do it.
                 * */
                indexTo: indexFrom < indexTo && groupFrom === groupTo ? indexTo - 1 : indexTo,
            })
        )
    }

    return (
        <StyledThreadOutlineBar>
            <StyledThreadOutlineBarHeader>
                <StyledH5>Thread Outline</StyledH5>
            </StyledThreadOutlineBarHeader>
            <StyledThreadOutlineBarContent>
                <SortableList
                    list={Object.values(blocksIndex)}
                    renderItem={(item, index) => (
                        <ThreadOutlineBarItem
                            key={`thread-outline-item-${index}`}
                            blockId={item.id}
                            blockIndex={index}
                            {...commonHandlers}
                        />
                    )}
                    moveItem={moveItem}
                    sortableProps={{
                        group: {
                            name: SortableOutlineGroupType.ROOT,
                            put: [SortableOutlineGroupType.ROOT, SortableOutlineGroupType.SECTION],
                        },
                    }}
                />
            </StyledThreadOutlineBarContent>
            <StyledThreadOutlineBarFooter>
                <StyledFooterButton onClick={() => handleAddChunk(getEmptySectionBlock())}>
                    <StyledAddIcon $show />
                    <StyledSpan>Add a Section</StyledSpan>
                </StyledFooterButton>
            </StyledThreadOutlineBarFooter>
        </StyledThreadOutlineBar>
    )
}

export default ThreadOutlineBar
