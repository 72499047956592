import { IconProps } from "../../types"
import { useTheme } from "styled-components"

const MenuIcon = ({ width = 36, height = 36, color }: IconProps) => {
    const theme = useTheme()
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_25685_1555)">
                <path
                    d="M30.6425 11.7861C31.4107 11.7861 31.9991 11.167 31.9991 10.4012C31.9991 9.63542 31.4107 9 30.6425 9H6.37194C5.60378 9 4.99905 9.63542 4.99905 10.4012C4.99905 11.167 5.60378 11.7861 6.37194 11.7861H30.6425Z"
                    fill={color ?? theme.colors.base.uiBgIcon}
                />
                <path
                    d="M30.6425 19.6345C31.4107 19.6345 31.9991 19.0154 31.9991 18.2496C31.9991 17.4838 31.4107 16.8484 30.6425 16.8484H6.37194C5.60378 16.8484 4.99905 17.4838 4.99905 18.2496C4.99905 19.0154 5.60378 19.6345 6.37194 19.6345H30.6425Z"
                    fill={color ?? theme.colors.base.uiBgIcon}
                />
                <path
                    d="M30.6425 27.4831C31.4107 27.4831 31.9991 26.864 31.9991 26.0982C31.9991 25.3324 31.4107 24.697 30.6425 24.697H6.37194C5.60378 24.697 4.99905 25.3324 4.99905 26.0982C4.99905 26.864 5.60378 27.4831 6.37194 27.4831H30.6425Z"
                    fill={color ?? theme.colors.base.uiBgIcon}
                />
            </g>
            <defs>
                <clipPath id="clip0_25685_1555">
                    <rect width="36" height="36" fill="white" transform="translate(-0.000946045)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default MenuIcon
