import sms from "../../../assets/images/sms.png"
import email from "../../../assets/images/email.png"
import slack from "../../../assets/images/slack.png"
import teams from "../../../assets/images/teams.png"
import linkedin from "../../../assets/images/linkedin.png"
import twitter from "../../../assets/images/twitter.png"
import discord from "../../../assets/images/discord.png"
import whatsapp from "../../../assets/images/whatsapp.png"
import { MessagingProviders } from "../../../types"
import { useMemo } from "react"
import { StyledMessageProviderImage } from "./styles"

interface MessageProviderImageProps {
    provider: MessagingProviders
    disabled?: boolean
    size?: number
    onClick?: () => void
}

const MessageProviderImage = ({
    provider,
    disabled,
    size = 30,
    onClick,
}: MessageProviderImageProps) => {
    const getProviderImage = useMemo(() => {
        switch (provider) {
            case MessagingProviders.SMS:
                return sms
            case MessagingProviders.EMAIL:
                return email
            case MessagingProviders.SLACK:
                return slack
            case MessagingProviders.TEAMS:
                return teams
            case MessagingProviders.LINKEDIN:
                return linkedin
            case MessagingProviders.TWITTER:
                return twitter
            case MessagingProviders.DISCORD:
                return discord
            case MessagingProviders.WHATSAPP:
                return whatsapp
        }
    }, [provider])

    return (
        <StyledMessageProviderImage
            onClick={() => !disabled && !!onClick && onClick()}
            src={getProviderImage}
            size={size}
            disabled={disabled}
            clickable={!!onClick && !disabled}
        />
    )
}

export default MessageProviderImage
