import { Close } from "@mui/icons-material"
import { Colors } from "../../utils/colors"
import { CSSObject } from "styled-components"
import { StyledCloseButtonContainer } from "./styles"

interface CloseButtonProps {
    onClose: (event: any) => void
    css?: CSSObject
}

/*
 * TODO: migrate all uses of this component to use the new `DeletableWrapper` instead.
 * Please avoid using the current component on new implementations
 */
const CloseButton = ({ onClose, css }: CloseButtonProps) => (
    <StyledCloseButtonContainer onClick={onClose} css={css}>
        <Close sx={{ color: Colors.headlandsGray10, fontSize: 15 }} />
    </StyledCloseButtonContainer>
)

export default CloseButton
