export const DuplicateIcon = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.6667 5.33333H9.33333V7.33333H7.33333V8.66667H9.33333V10.6667H10.6667V8.66667H12.6667V7.33333H10.6667V5.33333ZM1.33333 8C1.33333 6.14 2.42667 4.53333 4.00667 3.78667V2.34667C1.68 3.17333 0 5.39333 0 8C0 10.6067 1.68 12.8267 4.00667 13.6533V12.2133C2.42667 11.4667 1.33333 9.86 1.33333 8ZM10 2C6.69333 2 4 4.69333 4 8C4 11.3067 6.69333 14 10 14C13.3067 14 16 11.3067 16 8C16 4.69333 13.3067 2 10 2ZM10 12.6667C7.42667 12.6667 5.33333 10.5733 5.33333 8C5.33333 5.42667 7.42667 3.33333 10 3.33333C12.5733 3.33333 14.6667 5.42667 14.6667 8C14.6667 10.5733 12.5733 12.6667 10 12.6667Z"
                fill="#888888"
            />
        </svg>
    )
}
