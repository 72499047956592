import styled, { css } from "styled-components"
import { StyledBox, StyledImage } from "../../../styles/styledcomponents"
import { lighten, transparentize } from "polished"
import CloseIcon from "@mui/icons-material/Close"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import { ImageZoomType } from "."

export const StyledFullScreenImageContainer = styled(StyledBox)`
    position: relative;
`

export const StyledFullscreen = styled(StyledBox)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => transparentize(0.5, theme.colors.base.uiBgDark)};
    -webkit-backdrop-filter: ${(props) => props.theme.blur1};
    backdrop-filter: ${(props) => props.theme.blur1};
    z-index: 1000;
    min-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: 100vw;
        height: 100vh;
    }
`

export const StyledNormalSizeImage = styled(StyledImage)<{
    zoomType?: ImageZoomType
}>`
    z-index: 1001;
    height: 100%;

    ${({ zoomType }) =>
        zoomType === ImageZoomType.IMAGE &&
        css`
            cursor: zoom-in;
        `}
`

export const StyledFullScreenImage = styled(StyledImage)`
    max-height: 90vh;
    max-width: 90vw;
    cursor: zoom-in;

    @media only screen and (min-width: 600px) {
        padding: 50px;
        box-sizing: border-box;
    }
`

export const StyledCircularButton = styled.button`
    border: none;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    width: 32px;
    height: 32px;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 50%;
    z-index: 10000;
    background-color: ${({ theme }) => lighten(0.3, theme.colors.base.uiBgDark)};
`

export const StyledCloseIcon = styled(CloseIcon)`
    font-size: 24px;
    color: ${({ theme }) => theme.colors.base.uiBgBase};
`

export const StyledMaximizeButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 6px;
    right: 6px;
    width: 24px;
    height: 24px;
    border: none;
    background-color: ${({ theme }) => lighten(0.3, theme.colors.base.uiBgDark)};
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 0;
    opacity: 0.6;

    &:hover {
        cursor: pointer;
    }
`

export const StyledFullscreenIcon = styled(FullscreenIcon)`
    font-size: 20px !important;
    color: ${({ theme }) => theme.colors.base.uiBgBase};
`
