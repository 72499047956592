export const Code = () => {
    return (
        <svg
            width="22"
            height="10"
            viewBox="0 0 22 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.9425 9.5L6 8.4425L2.565 5L6 1.5575L4.9425 0.5L0.4425 5L4.9425 9.5Z"
                fill="white"
            />
            <path
                d="M17.0575 9.5L16 8.4425L19.435 5L16 1.5575L17.0575 0.5L21.5575 5L17.0575 9.5Z"
                fill="white"
            />
        </svg>
    )
}
