import styled from "styled-components"
import {
    StyledColumn,
    StyledH4,
    VerticalScrollableContentWithHiddenScroll,
} from "../../styles/styledcomponents"
import { StyledEmptyTopBarHeight } from "../TopBar/styles"

export const StyledSocialBar = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    width: ${({ theme }) => theme.sidebarWidths.social}px;
    border-left: 1px solid rgba(0, 12, 45, 0.1);
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
`

export const StyledSocialTopBar = styled(StyledEmptyTopBarHeight)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border-bottom: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    box-sizing: border-box;

    ${StyledH4} {
        color: ${({ theme }) => theme.colors.base.uiLabelTitle};
    }
`

export const StyledSocialBarScrollableContainer = styled(StyledColumn)`
    ${VerticalScrollableContentWithHiddenScroll};
    padding: 12px;
    gap: 3px;
`
