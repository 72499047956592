import styled, { css } from "styled-components"
import { StyledBox, StyledSpan } from "../../../styles/styledcomponents"
import ImageIcon from "@mui/icons-material/Image"

export const StyledImageHelpTextContainer = styled(StyledBox)`
    display: none;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.headlandsGray3};
    position: absolute;
    height: 44px;
    right: 0;
    left: 0;
    bottom: 0;
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius1};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius1};

    ${StyledSpan} {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin-left: 12px;
    }
`

export const StyledImageIcon = styled(ImageIcon)`
    color: ${({ theme }) => theme.headlandsGray10};
`

export const StyledImageContainer = styled(StyledBox)<{ width?: number; height?: number }>`
    position: relative;
    display: flex;
    border-radius: ${({ theme }) => theme.borderRadius1};

    ${({ height }) =>
        !!height &&
        css`
            height: ${height}px;
        `}

    ${({ width }) =>
        !!width &&
        css`
            width: ${width}px;
        `}


    img {
        width: 100%;
        height: 100%;
        border-radius: ${({ theme }) => theme.borderRadius1};
        object-fit: cover; // todo ask how we should render the program image
    }

    &:hover {
        cursor: pointer;

        ${StyledImageHelpTextContainer} {
            display: flex;
        }
    }
`
