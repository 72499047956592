import styled from "styled-components"
import { StyledBox } from "../../../styles/styledcomponents"

export const StyledNoContentContainer = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.headlandsGray6};
    padding: 18px;
    border-radius: ${({ theme }) => theme.borderRadius1};
`

export const StyledIconContainer = styled.div`
    border-radius: 50%;
    height: 36px;
    width: 36px;
    border: 1px solid ${({ theme }) => theme.headlandsGray10};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
`
