import { useEffect } from "react"

interface MetaOverrideProps {
    name: string
    content: string
}
/*
 * This component updates a given meta tag with the given content.
 * After unmounting the component the meta tag is restore to the previous value.
 */
const MetaOverride = ({ name, content }: MetaOverrideProps) => {
    useEffect(() => {
        const prevMetaTag = document.querySelector(`meta[name="${name}"]`)
        const prevContent = prevMetaTag?.getAttribute("content")
        // update an existing meta tag
        if (prevMetaTag) prevMetaTag?.setAttribute("content", content)
        // create a new meta tag
        else {
            let newMeta = document.createElement("meta")
            newMeta.name = name
            newMeta.content = content
            document.head.appendChild(newMeta)
        }

        return () => {
            // restore the meta tag to the previous value
            if (prevContent) {
                document.querySelector(`meta[name="${name}"]`)?.setAttribute("content", prevContent)
            }
            // remove the meta tag
            else document.querySelector(`meta[name="${name}"]`)?.remove()
        }
    }, [])
    return null
}

export default MetaOverride
