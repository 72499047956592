import { StyledMessage, StyledSpanIcon, StyledToast } from "./styles"

type ToastWithIconAndIconProps = {
    icon?: JSX.Element
    message: string
    content?: never
}

type RawToastProps = {
    content: JSX.Element
    icon?: never
    message?: never
}

export type ToastComponentProps = ToastWithIconAndIconProps | RawToastProps

const Toast = ({ icon, message, content }: ToastComponentProps) => {
    if (content) {
        return <StyledToast>{content}</StyledToast>
    }
    return (
        <StyledToast>
            {icon && <StyledSpanIcon>{icon}</StyledSpanIcon>}
            <StyledMessage>{message}</StyledMessage>
        </StyledToast>
    )
}

export default Toast
