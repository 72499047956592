import styled from "styled-components"
import {
    StyledBox,
    StyledH2Styles,
    VerticalScrollableContentWithHiddenScroll,
} from "../../../styles/styledcomponents"

export const StyledCreateContent = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
    padding: 42px 0 30px;
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
    overflow: hidden;

    .react-tabs {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        .react-tabs__tab-list {
            display: flex;
            justify-content: flex-start;
            border-bottom: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
            margin: 0;
            padding: 0;
            gap: 12px;
            .react-tabs__tab {
                ${StyledH2Styles};
                flex: none;
                padding: 6px 8px;
                background-color: transparent;
                width: fit-content;
                color: ${({ theme }) => theme.colors.base.uiLabelTitle};
                cursor: default;

                &.react-tabs__tab--selected {
                    border-bottom: 2px solid ${({ theme }) => theme.colors.base.uiLabelTitle};
                }
            }
        }
        .react-tabs__tab-panel--selected {
            display: flex;
            flex-direction: column;
            margin-top: 18px;
            margin-bottom: 18px;
            gap: 30px;
            ${VerticalScrollableContentWithHiddenScroll};
        }
    }
`

export const StyledItemSetContainer = styled(StyledBox)`
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    padding: 0 6px;
`
