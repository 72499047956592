import {
    Block,
    BlockContext,
    BlocksType,
    BlockType,
    ConditionalBranch,
    ConditionalTest,
    DeliverProgramContent,
    DeliverProgramContentType,
    DeliverThread,
    ItemWithChildren,
    MediaSize,
    MENU_KEYS,
    MessagingProviders,
    ObjectTiming,
    Operator,
    ResponseThread,
    SelectOption,
    ThemeColors,
    ThreadFont,
    ThreadTheme,
} from "../types"
import {
    chain,
    compact,
    intersection,
    intersectionWith,
    isEmpty,
    isEqual,
    isNil,
    isString,
    merge,
    omit,
    pick,
} from "lodash"
import queryString from "query-string"
import parse from "html-react-parser"
import { DefaultTheme } from "styled-components"
import { emailRegex } from "./consts"
import { Maybe } from "graphql/jsutils/Maybe"
import {
    Choice,
    UserAnswer,
    VariableType,
    VariableValue,
    VariableWithValue,
} from "../apollo/generated/graphql"
import {
    ComponentSelectorItem,
    SectionItemOption,
} from "../creator/components/ComponentSelector/constants"

export const generateUUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = (Math.random() * 16) | 0
        let v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

export const isUUID = (string: string) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
    return uuidRegex.test(string)
}

export const isHTML = (text: string): boolean => /<\/?[a-z][\s\S]*>/i.test(text)

export const removeHTML = (text: string): string =>
    text
        ?.replaceAll("</p>", " ")
        ?.replace(/(<([^>]+)>)/gi, "")
        ?.trim()

export const removeWhiteSpaces = (text: string): string => text.replace(/\s+/g, "")

export const capitalize = (text: string) =>
    text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()

export const removeInvisibleCharacters = (text: string) =>
    text.replaceAll(/[^a-zA-Z0-9_@./#&+-]/g, "")

const baseGlob = (
    stringToMatch: string | number,
    likeCondition: string,
    matchPartial?: boolean,
    caseSensitive?: boolean
): boolean => {
    //Set default values
    if (stringToMatch === undefined) {
        stringToMatch = ""
    }
    if (likeCondition === undefined) {
        likeCondition = ""
    }
    if (matchPartial === undefined) {
        matchPartial = false
    }
    if (caseSensitive === undefined) {
        caseSensitive = true
    }
    if (typeof stringToMatch != "string") {
        stringToMatch = stringToMatch.toString()
    }

    //Escape regex characters from likeCondition
    likeCondition = likeCondition.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")

    if (!caseSensitive) {
        stringToMatch = stringToMatch.toUpperCase()
        likeCondition = likeCondition.toUpperCase()
    }

    let likeRegexString: string = likeCondition.replace(/%/g, ".*")

    if (!matchPartial) {
        likeRegexString = "^" + likeRegexString + "$"
    }
    let likeRegexPattern: RegExp = new RegExp(likeRegexString)

    if (likeRegexPattern.test(stringToMatch)) {
        return true
    }
    return false
}

export const glob = (stringToMatch: string | number, likeCondition: string) => {
    return baseGlob(stringToMatch, likeCondition, undefined, true)
}

export const iglob = (stringToMatch: string | number, likeCondition: string) => {
    return baseGlob(stringToMatch, likeCondition, undefined, false)
}

// TODO: check if we need to update the places where is used
export const getVariableObject = (
    blockType: BlockType,
    options: Choice[],
    variableName?: string,
    maySelectMultiple?: boolean
): VariableWithValue => {
    /*
     * TODO: APIs Migration
     *  add text
     *  add category
     * */
    switch (blockType) {
        case BlockType.CHOICE_QUESTION:
            let variableObject: VariableWithValue = {
                type: VariableType.Ref,
                name: variableName || "",
                value: {
                    valueID: options[0].id,
                    selectedChoices: options,
                },
            }
            if (maySelectMultiple) {
                variableObject.type = VariableType.RefSet
                delete variableObject.value.valueID
                variableObject.value.valueIDs = options.map((option) => option.id)
            }
            return variableObject
        case BlockType.FREE_TEXT_QUESTION:
        case BlockType.IMAGE_UPLOAD:
        case BlockType.SET_STRING_VARIABLE:
        default:
            return {
                type: VariableType.String,
                name: variableName || "",
                value: {
                    selectedChoices: options,
                    string: options[0].text,
                },
            }
    }
}

export const getVariableType = (blockType: BlockType, maySelectMultiple?: boolean) => {
    switch (blockType) {
        case BlockType.IMAGE_UPLOAD:
        case BlockType.SET_STRING_VARIABLE:
        case BlockType.CHOICE_QUESTION:
            if (maySelectMultiple) return VariableType.RefSet
            return VariableType.Ref
        default:
            return VariableType.String
    }
}

export const getVariableChoices = (block: Block): Choice[] => {
    switch (block.type) {
        case BlockType.CHOICE_QUESTION:
            return block?.options?.map((option) => pick(option, "id", "text")) || []
        default:
            return []
    }
}

export const getOperatorLabel = (operator: Operator) => {
    switch (operator) {
        case Operator.AND:
            return "And"
        case Operator.OR:
            return "Or"
        case Operator.ALWAYS_TRUE:
            return "Always true"
        // string
        case Operator.GLOB:
            return "GLOB"
        case Operator.IGLOB:
            return "IGLOB"
        case Operator.CONTAINS_STRING:
            return "Contains"
        case Operator.EQUALS_STRING:
            return "Equals"
        // ref
        case Operator.IN:
            return "Is"
        //ref-set
        case Operator.CONTAINS:
            return "Includes All"
        case Operator.CONTAINS_ANY:
            return "Includes Any of"
        case Operator.CONTAINS_EXACTLY:
            return "Includes Exactly"
        // free text
        case Operator.ANY_OF:
            return "Includes Any Of"
        case Operator.ALL:
            return "Includes All"
        default:
            return ""
    }
}

export const getOperatorOptionsByVariableType = (variableType: VariableType): SelectOption[] => {
    switch (variableType) {
        case VariableType.String:
            return [
                {
                    value: Operator.CONTAINS_STRING,
                    label: getOperatorLabel(Operator.CONTAINS_STRING),
                },
                { value: Operator.EQUALS_STRING, label: getOperatorLabel(Operator.EQUALS_STRING) },
                { value: Operator.ANY_OF, label: getOperatorLabel(Operator.ANY_OF) },
                { value: Operator.ALL, label: getOperatorLabel(Operator.ALL) },
            ]
        case VariableType.Ref:
            return [{ value: Operator.IN, label: getOperatorLabel(Operator.IN) }]
        case VariableType.RefSet:
            return [
                { value: Operator.CONTAINS, label: getOperatorLabel(Operator.CONTAINS) },
                { value: Operator.CONTAINS_ANY, label: getOperatorLabel(Operator.CONTAINS_ANY) },
                {
                    value: Operator.CONTAINS_EXACTLY,
                    label: getOperatorLabel(Operator.CONTAINS_EXACTLY),
                },
            ]
        default:
            return []
    }
}

const evaluateBooleanArray = (operator: Operator, a: boolean, b: boolean | undefined) => {
    return operator === Operator.OR ? a || b : a && b
}

const reduceBooleanArray = (booleans: boolean[], operator: Operator) => {
    return booleans.reduce((accum: any, d: any, i: number) => {
        if (i === booleans.length - 1) return accum
        if (accum === undefined) {
            return evaluateBooleanArray(operator, d, booleans[i + 1])
        }
        return evaluateBooleanArray(operator, accum, booleans[i + 1])
    }, undefined)
}

export const evaluateCondition = (
    test: ConditionalTest,
    variables: Maybe<VariableWithValue[]>,
    blockId: string
): boolean => {
    const variableItem = variables?.find((variable) => variable.name === test.var)
    const varValue = variableItem?.value
    const testValAsStr = test.val ? test.val.toString() : ""

    switch (test.op) {
        // string operators
        case Operator.IGLOB:
            return iglob(varValue?.string || "", testValAsStr)
        case Operator.GLOB:
            return glob(varValue?.string || "", testValAsStr)
        case Operator.CONTAINS_STRING:
            return iglob(varValue?.string || "", `%${testValAsStr}%`)
        case Operator.EQUALS_STRING:
            return iglob(varValue?.string || "", testValAsStr)
        // ref operators
        case Operator.IN:
            /*
             * Yes, this is not nice :(
             * but we have to do this because boolean variables are of type REF
             * whose values are arrays of string, so we have to check for the case
             * they are booleans
             */
            const isBoolean =
                Array.isArray(test.val) &&
                test.val.every((val) => val === "true" || val === "false")
            if (isBoolean) {
                const booleanVarValue = varValue?.valueID === "true"
                return Array.isArray(test.val) && test.val.includes(booleanVarValue.toString())
            } else {
                return !!test?.val?.includes(varValue?.valueID || "")
            }
        // ref-set operators
        case Operator.CONTAINS:
            return (
                Array.isArray(test.val) &&
                Array.isArray(varValue?.valueIDs) &&
                test.val.every((id) => varValue?.valueIDs?.includes(id))
            )
        case Operator.CONTAINS_ANY:
            return (
                Array.isArray(test.val) &&
                Array.isArray(varValue?.valueIDs) &&
                test.val.some((id) => varValue?.valueIDs?.includes(id))
            )
        case Operator.CONTAINS_EXACTLY:
            return (
                Array.isArray(test.val) &&
                Array.isArray(varValue?.valueIDs) &&
                isEqual([...test.val].sort(), [...(varValue?.valueIDs || [])].sort())
            )
        case (Operator.OR, Operator.AND):
            const booleans = test.tests!.map((t) => evaluateCondition(t, variables, blockId))
            return reduceBooleanArray(booleans, test.op)

        case Operator.ALWAYS_TRUE:
            return true
        // text area operators | string operator
        case Operator.ANY_OF:
            return (
                Array.isArray(test.val) &&
                test.val.some((value) =>
                    varValue?.string
                        ?.trim()
                        .toLocaleLowerCase()
                        .includes(value.trim().toLocaleLowerCase())
                )
            )
        case Operator.ALL:
            return (
                Array.isArray(test.val) &&
                test.val.every((value) =>
                    varValue?.string?.toLocaleLowerCase().includes(value.trim().toLocaleLowerCase())
                )
            )
        default:
            console.log("unhandled operator", test)
    }
    return false
}

export const addAutoPlayOff = (iframeCode: string) => {
    const match = iframeCode.match(/<iframe.+src=(?:"|')(.+?)(?:"|')(?:.+?)>/)!
    const iframeUrl = match && match[1]
    return iframeCode.replace(iframeUrl, `${iframeUrl}&autoPlay=false`)
}

// This must be called from inside immer's produce method
export const findBranch = (
    blocks: Block[],
    branchId: string,
    callback: (branch: ConditionalBranch) => void
) => {
    blocks.forEach((block) => {
        if (block.branches) {
            block.branches.forEach((branch: ConditionalBranch) => {
                if (branch.id === branchId) {
                    callback(branch)
                } else if (branch.objects) {
                    findBranch(branch.objects, branchId, callback)
                }
            })
        }
        // to support blocks with objects inside (like sections)
        else if (block.objects) {
            findBranch(block.objects, branchId, callback)
        }
    })
}

export const findSection = (blocks: Block[], sectionId: string): Block | undefined => {
    return blocks.find((block) => block.type === BlockType.SECTION && block.id === sectionId)
}

export const getEmptyBlock = () => ({
    id: generateUUID(),
    type: BlockType.TEXT,
    value: "",
})

export const getGroupBlock = (initialObjects?: Block[]) => ({
    id: generateUUID(),
    objects: initialObjects ?? [
        {
            id: generateUUID(),
            type: BlockType.TEXT,
            value: "",
        },
    ],
    test: { var: "" },
})

export const getQuestionOption = () => ({
    id: generateUUID(),
    text: "",
    objects: [
        {
            id: generateUUID(),
            type: BlockType.GROUP,
            branches: [getGroupBlock()],
        },
    ],
})

export const getFreeTextResponse = () => ({
    id: generateUUID(),
    input: "",
    objects: [
        {
            id: generateUUID(),
            type: BlockType.GROUP,
            branches: [getGroupBlock()],
        },
    ],
})

export const getEmptySectionBlock = () => ({
    id: generateUUID(),
    type: BlockType.SECTION,
    title: "Section",
    objects: [
        {
            id: generateUUID(),
            type: BlockType.GROUP,
            branches: [getGroupBlock()],
        },
    ],
})

export const getImageGroupBlock = () => ({
    id: generateUUID(),
    objects: [
        {
            id: generateUUID(),
            type: BlockType.IMAGE,
            value: "",
        },
    ],
    test: { var: "" },
})

export const getAccordionContent = (): ConditionalBranch => ({
    id: generateUUID(),
    objects: [
        {
            id: generateUUID(),
            type: BlockType.TEXT,
            value: "",
        },
    ],
    test: { var: "" },
})

export const getEmptyBranch = (options?: {
    test?: ConditionalTest
    objectBranches?: ConditionalBranch[]
}): ConditionalBranch => ({
    id: generateUUID(),
    objects: [
        {
            id: generateUUID(),
            type: BlockType.GROUP,
            branches: options?.objectBranches ?? [getGroupBlock()],
        },
    ],
    test: options?.test ?? { var: "" },
})

export const buildTimingsMap = (timingsArray: Array<ObjectTiming>) => {
    if (timingsArray) {
        return timingsArray.reduce((result: any, current: any) => {
            result[current.id] = {
                percentComplete: current.percentComplete,
            }
            return result
        }, {})
    } else return {}
}

export const buildAnswersMap = (answersArray: Array<{ [key: string]: string }>) => {
    if (answersArray) {
        return answersArray.reduce((result: any, current: any) => {
            if (current.object) {
                try {
                    result[current.object] = JSON.parse(current.json)
                } catch (error) {
                    return result
                }
            }
            return result
        }, {})
    } else {
        return {}
    }
}

export const buildVariablesMapFromAnswers = (answersArray: Array<{ [key: string]: string }>) => {
    if (answersArray) {
        return answersArray.reduce((result: any, current: { [key: string]: string }) => {
            if (!current.objectType) return result
            result[current.variable] = getVariableObject(
                current.objectType as BlockType,
                JSON.parse(current.json)
            )
            return result
        }, {})
    } else {
        return {}
    }
}

export const buildProgramVariablesMap = (variablesArray: Array<{ [key: string]: string }>) => {
    if (variablesArray && Array.isArray(variablesArray)) {
        return variablesArray.reduce((result: any, current: { [key: string]: string }) => {
            result[Object.keys(current)[0]] = {
                type: VariableType.String,
                value: Object.values(current)[0],
            }
            return result
        }, {})
    } else {
        return {}
    }
}

export const getAnswerValue = (
    blockType: BlockType,
    answer: Choice[],
    maySelectMultiple?: boolean
) => {
    switch (blockType) {
        case BlockType.CHOICE_QUESTION:
            if (maySelectMultiple) return answer.map((ans) => ans.id)
            return answer[0].id

        case BlockType.FREE_TEXT_QUESTION:
        case BlockType.IMAGE_UPLOAD:
        case BlockType.SET_STRING_VARIABLE:
            return answer[0].text
    }
}

/*
 * This method return an array with all responses of the given urls
 * */
export const fetchUrls = (urls: string[], init: RequestInit = {}): Promise<any> => {
    const promises = urls.map((url) => fetch(url, init))
    return Promise.all(promises)
}

/*
 * This method return all the blocks of a certain type (or multiple types) from an array of blocks.
 * Supports:
 * - section blocks (meaning that it would search inside sections)
 * - conditionals and groups blocks (meaning it would search inside them)
 * - multiple type search, it will return all the blocks that match any given block type
 * */
export const getAllBlockOfType = (blocks: Block[], blockType: BlockType | BlockType[]): Block[] => {
    let result: Block[] = []
    const blockTypes = Array.isArray(blockType) ? blockType : [blockType]
    /*
     * Note: `blocks` is optional, because on some recursive calls, it might be null,
     * for example for an `appear_together` block that has no objects inside.
     * Marking it as optional prevents a crash.
     */
    blocks?.forEach((block) => {
        if (!block) return
        if (blockTypes.includes(block.type)) result.push(block)
        else if (block.branches) {
            block.branches.forEach((branch) => {
                result = [...result, ...getAllBlockOfType(branch.objects, blockType)]
            })
        } else if (block.objects) {
            result = [...result, ...getAllBlockOfType(block.objects, blockType)]
        } else if (block.options) {
            block.options.forEach((option) => {
                result = [...result, ...getAllBlockOfType(option.objects, blockType)]
            })
        } else if (block.responses) {
            block.responses.forEach((response) => {
                result = [...result, ...getAllBlockOfType(response.objects, blockType)]
            })
        }
    })
    return compact(result)
}

export const findBlockIdInBlocks = (blocklist: Block[], blockId: string): boolean => {
    return blocklist?.some(
        (block) =>
            block.id === blockId ||
            (block.branches && findBlockIdInBranch(block.branches, blockId)) ||
            (block.objects && findBlockIdInBlocks(block.objects, blockId))
    )
}

const findBlockIdInBranch = (block: ConditionalBranch[], blockId: string) => {
    return !!block.find((branch) => findBlockIdInBlocks(branch.objects, blockId))
}

export const findBlockIdInBlock = (block: Block, blockId: string): boolean => {
    if (!block) return false
    if (block.id === blockId) {
        return true
    } else if (block.branches) {
        return findBlockIdInBranch(block.branches, blockId)
    } else if (block.objects) {
        return findBlockIdInBlocks(block.objects, blockId)
    } else {
        return false
    }
}

export interface CustomImageOptions {
    height?: number
    width?: number
    crop?: "center" | "smart" | "pad" | "face"
    webp?: boolean
}

export const getCustomImageUrl = (baseUrl: string, options?: CustomImageOptions): string => {
    // note: with the `Math.ceil` we ensure that the `dpr` is always an integer number (rounded up),
    // so the calculated `width` and `height` will also be integers
    const dpr = Math.ceil(window.devicePixelRatio)
    let altOptions = { ...options }
    if (altOptions.width) altOptions.width = altOptions.width * dpr
    if (altOptions.height) altOptions.height = altOptions.height * dpr
    // We check if the url is from our CDN
    // TODO: in the future we will add a build variable that contains the cdn hostname so we
    //  can do a more exhaustive check whether the url belong to our cdn or not
    if (baseUrl?.includes("cdn")) {
        const urlObject = new URL(baseUrl)
        // We prepend `/image` so that the request is resolved by the image utils server
        urlObject.pathname = `/image${urlObject.pathname}`
        if (altOptions && !isEmpty(altOptions)) {
            urlObject.search = queryString.stringify(altOptions)
        }
        return urlObject.toString()
    } else {
        return baseUrl
    }
}

export const isInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect()
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

export const isBlockEmpty = (block: Block): boolean =>
    block.value === "" || block.value === "<p><br></p>"

export const isBlockValueEmpty = (value?: string): boolean =>
    value === undefined || value === "" || value === "<p><br></p>"

/*
 * This function calculates some video utils stuff like maxWidth and padding
 * Given a video bubble value (string html) it returns:
 * - the parsed code (without vendor responsive stuff)
 * - the max width the video should have
 * - padding bottom to make is responsive
 * */
export const getVideoUtils = (
    bubbleValue: string
): { parsed: string | JSX.Element | JSX.Element[]; maxWidth: number; paddingBottom: string } => {
    const isAWistiaResponsiveVideo = bubbleValue.includes("wistia_responsive_padding")
    let div = document.createElement("div")
    div.innerHTML = bubbleValue.trim()

    let parsed

    const width = parseInt(div.getElementsByTagName("iframe")[0].getAttribute("width") || "16")
    const height = parseInt(div.getElementsByTagName("iframe")[0].getAttribute("height") || "9")

    const magicPercentage = !isAWistiaResponsiveVideo
        ? (height * 100) / width
        : // @ts-ignore
          div.getElementsByClassName("wistia_responsive_padding")[0].style.padding.split("%")[0]

    const maxHeight = window.innerHeight * 0.8 // 80 view height
    const magicMaxWidth = maxHeight / (magicPercentage / 100)

    if (isAWistiaResponsiveVideo) parsed = parse(div.getElementsByTagName("iframe")[0].outerHTML)
    else parsed = parse(bubbleValue)

    return { parsed, maxWidth: magicMaxWidth, paddingBottom: `${magicPercentage}%` }
}

export const flattenBlocks = (blocks: Block[]): Block[] => {
    return blocks.reduce((flatBlocks: Block[], block: Block) => {
        let blocksToAdd: Block[]
        if (block.branches) {
            blocksToAdd = [
                block,
                ...block.branches.map((branch) => flattenBlocks(branch.objects)).flat(),
            ]
        } else if (block.objects) {
            blocksToAdd = [block, ...flattenBlocks(block.objects)]
        } else {
            blocksToAdd = [block]
        }
        return flatBlocks.concat(blocksToAdd)
    }, [])
}

export const getThemeColors = (themeKey?: ThreadTheme): ThemeColors => {
    const theme = themeKey || ThreadTheme.Default
    switch (theme) {
        case ThreadTheme.AppleMessages: {
            return {
                instructor: {
                    bubble: "#E8E8EB",
                    font: "#323232",
                },
                student: {
                    bubble: "#037CFE",
                    font: "#FFFFFF",
                    inputText: "#000000",
                },
                background: "#FFFFFF",
            }
        }
        case ThreadTheme.GoogleMessages: {
            return {
                instructor: {
                    bubble: "#EDEDED",
                    font: "#323232",
                },
                student: {
                    bubble: "#00A291",
                    font: "#FFFFFF",
                    inputText: "#000000",
                },
                background: "#FFFFFF",
            }
        }
        case ThreadTheme.WhatsApp: {
            return {
                instructor: {
                    bubble: "#F6F6F6",
                    font: "#323232",
                },
                student: {
                    bubble: "#CAF0B0",
                    font: "#020500",
                    inputText: "#000000",
                },
                background: "#FFFFFF",
            }
        }
        case ThreadTheme.MSTeams: {
            return {
                instructor: {
                    bubble: "#FFFFFF",
                    font: "#323232",
                },
                student: {
                    bubble: "#C3CCF4",
                    font: "#323232",
                    inputText: "#000000",
                },
                background: "#F5F5F5",
            }
        }
        case ThreadTheme.Slack: {
            return {
                instructor: {
                    bubble: "#FCFFFB",
                    font: "#323232",
                },
                student: {
                    bubble: "#4E1A52",
                    font: "#FFFFFF",
                    inputText: "#000000",
                },
                background: "#F5F5F5",
            }
        }
        case ThreadTheme.Discord: {
            return {
                instructor: {
                    bubble: "#2F3136",
                    font: "#FFFFFF",
                },
                student: {
                    bubble: "#5662F6",
                    font: "#FFFFFF",
                    inputText: "#FFFFFF",
                },
                background: "#35393E",
            }
        }
        case ThreadTheme.Purple: {
            return {
                instructor: {
                    bubble: "#E2DBEC",
                    font: "#323232",
                },
                student: {
                    bubble: "#572CEA",
                    font: "#FFFFFF",
                    inputText: "#000000",
                },
                background: "#FFFFFF",
            }
        }
        case ThreadTheme.Orange: {
            return {
                instructor: {
                    bubble: "#F5DED3",
                    font: "#323232",
                },
                student: {
                    bubble: "#E89632",
                    font: "#FFFFFF",
                    inputText: "#000000",
                },
                background: "#FFFFFF",
            }
        }
        case ThreadTheme.Olive: {
            return {
                instructor: {
                    bubble: "#D8E1D6",
                    font: "#323232",
                },
                student: {
                    bubble: "#195127",
                    font: "#FFFFFF",
                    inputText: "#000000",
                },
                background: "#FFFFFF",
            }
        }
        case ThreadTheme.Blue: {
            return {
                instructor: {
                    bubble: "#D2D7E3",
                    font: "#323232",
                },
                student: {
                    bubble: "#2C5497",
                    font: "#FFFFFF",
                    inputText: "#000000",
                },
                background: "#FFFFFF",
            }
        }
        case ThreadTheme.Green: {
            return {
                instructor: {
                    bubble: "#D1E3E4",
                    font: "#323232",
                },
                student: {
                    bubble: "#248283",
                    font: "#FFFFFF",
                    inputText: "#000000",
                },
                background: "#FFFFFF",
            }
        }
        case ThreadTheme.Ocean: {
            return {
                instructor: {
                    bubble: "#D8E0F0",
                    font: "#323232",
                },
                student: {
                    bubble: "#2E5D84",
                    font: "#FFFFFF",
                    inputText: "#000000",
                },
                background: "#FFFFFF",
            }
        }
        case ThreadTheme.Cherry: {
            return {
                instructor: {
                    bubble: "#F5D2DB",
                    font: "#323232",
                },
                student: {
                    bubble: "#9E183F",
                    font: "#FFFFFF",
                    inputText: "#000000",
                },
                background: "#FFFFFF",
            }
        }
        case ThreadTheme.Default:
        default: {
            return {
                instructor: {
                    font: "#323232",
                    bubble: "#F1F1F1",
                },
                student: {
                    bubble: "#444444",
                    font: "#FFFFFF",
                    inputText: "#000000",
                },
                background: "#FFFFFF",
            }
        }
    }
}

export const getFontName = (fontKey?: ThreadFont) => {
    switch (fontKey) {
        case ThreadFont.Cambay:
            return "Cambay"
        case ThreadFont.Dosis:
            return "Dosis"
        case ThreadFont.Inconsolata:
            return "Inconsolata"
        case ThreadFont.Karma:
            return "Karma"
        case ThreadFont.Lato:
            return "Lato"
        case ThreadFont.MarkerFelt:
            return "Marker Felt"
        case ThreadFont.Montserrat:
            return "Montserrat"
        case ThreadFont.NotoSans:
            return "Noto Sans"
        case ThreadFont.Nunito:
            return "Nunito"
        case ThreadFont.OpenSans:
            return "Open Sans"
        case ThreadFont.Oswald:
            return "Oswald"
        case ThreadFont.Raleway:
            return "Raleway"
        case ThreadFont.Ubuntu:
            return "Ubuntu"
        case ThreadFont.System:
            return "system-ui"
        case ThreadFont.Arial:
            return "Arial"
        case ThreadFont.HelveticaNeue:
            return "Helvetica Neue"
        case ThreadFont.Courier:
            return "Courier"
        case ThreadFont.Georgia:
            return "Georgia"
        case ThreadFont.ComicSans:
            return "Comic Sans MS"
        case ThreadFont.GGSans:
            return "GG Sans"
        case ThreadFont.SegoeUI:
            return "Segoe UI"
        case ThreadFont.Circular:
            return "Circular"
        case ThreadFont.Roboto:
        default:
            return "Roboto"
    }
}

export const getThreadThemeFont = (theme: ThreadTheme): ThreadFont => {
    switch (theme) {
        case ThreadTheme.AppleMessages:
        case ThreadTheme.WhatsApp: {
            return ThreadFont.HelveticaNeue
        }
        case ThreadTheme.GoogleMessages: {
            return ThreadFont.Roboto
        }
        case ThreadTheme.MSTeams: {
            return ThreadFont.SegoeUI
        }
        case ThreadTheme.Slack: {
            return ThreadFont.Circular
        }
        case ThreadTheme.Discord: {
            return ThreadFont.GGSans
        }
        default:
            return ThreadFont.Roboto
    }
}

export const setThreadTheme = (
    baseTheme: DefaultTheme,
    colorTheme?: ThreadTheme,
    font?: ThreadFont
): DefaultTheme => ({
    ...baseTheme,
    fonts: {
        ...baseTheme.fonts,
        thread: getFontName(font),
    },
    colors: {
        ...baseTheme.colors,
        thread: merge(baseTheme.colors.thread, getThemeColors(colorTheme)),
    },
})

export const isAiBlockAllowedInContext = (
    option: SectionItemOption,
    context: BlockContext
): boolean => {
    const isInPane = intersection(context, [BlocksType.PREVIEW, BlocksType.SYNOPSIS]).length > 0
    // TODO: We don't allow AI in Panes for now because the BE doesn't support it
    if (isInPane) return false
    const allowInteractiveElement = intersection(context, [BlockType.ACCORDION]).length === 0
    if (!allowInteractiveElement && option.containsInteractiveElement) return false
    else return true
}

export const isBlockTypeAllowedInContext = (
    blockType: ComponentSelectorItem,
    context: BlockContext
): boolean => {
    switch (blockType) {
        case BlockType.TEXT:
        case BlockType.IMAGE:
        case BlockType.IMAGE_CAROUSEL:
        case BlockType.VIDEO:
        case BlockType.INSTRUCTOR_AVATAR:
        case BlockType.DIVIDER:
        case BlockType.COMMENT:
        case BlockType.QUESTIONS_AND_ANSWERS:
        case BlockType.CODE:
            return true
        case BlockType.NOTIFICATION:
        case BlockType.IMAGE_UPLOAD:
        case BlockType.CONDITIONAL:
        case BlockType.SET_STRING_VARIABLE:
        case BlockType.CONFETTI:
            return (
                intersection(context, [
                    BlockType.ACCORDION,
                    BlockType.CHOICE_QUESTION,
                    BlockType.FREE_TEXT_QUESTION,
                    BlocksType.PREVIEW,
                    BlocksType.SYNOPSIS,
                ]).length === 0
            )
        case BlockType.CHOICE_QUESTION:
        case BlockType.FREE_TEXT_QUESTION:
            // these components are only available at root level
            return context === undefined
        case BlockType.GROUP:
        case BlockType.INCLUDE:
            return (
                intersection(context, [
                    BlockType.GROUP,
                    BlockType.ACCORDION,
                    BlocksType.PREVIEW,
                    BlocksType.SYNOPSIS,
                ]).length === 0
            )
        case BlockType.ACCORDION:
            return !context.some((cont) => cont === BlockType.ACCORDION)
        default:
            return false
    }
}

export const validateEmail = (email: string): boolean => emailRegex.test(email)

export const getLastSpecialCharacterAndIndex = (
    text: string
): { key: MENU_KEYS; index: number } | undefined => {
    let highest: { key: MENU_KEYS; index: number } | undefined
    Object.values(MENU_KEYS).forEach((key) => {
        const lastSpecialIndex = text.lastIndexOf(key)
        if (
            (highest && lastSpecialIndex > highest.index) ||
            (!highest && lastSpecialIndex !== -1)
        ) {
            highest = { key, index: lastSpecialIndex }
        }
    })
    return highest
}

type StyleProperty = string | { key: string; allowedValues?: string[] }

export const getDocumentElementFromString = (html: string): Document => {
    const parser = new DOMParser()
    return parser.parseFromString(html, "text/html")
}

export const removeStylesFromElement = (element: HTMLElement, styleProperties: StyleProperty[]) => {
    styleProperties.forEach((styleProperty) => {
        // remove the property
        if (isString(styleProperty)) {
            element.style.removeProperty(styleProperty)
        }
        // if the value is not allow remove the property
        else {
            const normalizeString = (stringToNormalize: string): string => {
                return stringToNormalize.replaceAll(" ", "").toLowerCase()
            }

            const value = element.style.getPropertyValue(styleProperty.key)
            const normalizeValue = normalizeString(value)
            const shouldKeepAttribute = styleProperty.allowedValues?.some(
                (allowValue) => normalizeString(allowValue) === normalizeValue
            )
            if (shouldKeepAttribute) return
            else element.style.removeProperty(styleProperty.key)
        }
    })
}

export const removeStylesFromHtml = (html: string, stylesToRemove: StyleProperty[]): string => {
    const documentFromString = getDocumentElementFromString(html)
    const allElements = documentFromString.getElementsByTagName("*")

    for (let i = 0; i < allElements.length; i++) {
        const element = allElements[i] as HTMLElement
        removeStylesFromElement(element, stylesToRemove)
    }

    return documentFromString.documentElement.innerHTML
}

export const hexToRGB = (h: string) => {
    let r = "",
        g = "",
        b = ""

    // 3 digits
    if (h.length === 4) {
        r = "0x" + h[1] + h[1]
        g = "0x" + h[2] + h[2]
        b = "0x" + h[3] + h[3]

        // 6 digits
    } else if (h.length === 7) {
        r = "0x" + h[1] + h[2]
        g = "0x" + h[3] + h[4]
        b = "0x" + h[5] + h[6]
    }

    return "rgb(" + +r + "," + +g + "," + +b + ")"
}

export const mapProgramContentForSaving = (contents: DeliverProgramContent[]): any => {
    return contents.map((content: DeliverProgramContent) => {
        switch (content.__typename) {
            case DeliverProgramContentType.ProgramThread:
                const thread = content as DeliverThread
                return {
                    thread: chain({ ...thread, guid: content.clientData })
                        .omit("thread", "id", "selected", "chosen", "__typename")
                        .omitBy(isNil)
                        .set("messages", mapProgramContentForSaving(thread.messages || [])),
                }
            case DeliverProgramContentType.SlackMessage:
                return {
                    slack: chain(content)
                        .omit("id", "selected", "chosen", "attachment", "__typename")
                        .omitBy(isNil),
                }
            case DeliverProgramContentType.EmailMessage:
                return {
                    email: chain(content)
                        .omit("id", "selected", "chosen", "attachment", "__typename")
                        .omitBy(isNil),
                }
            default:
                return []
        }
    })
}

export const removeReactSortableFieldsFromObject = <T extends Object>(object: T): T => {
    return omit(object, "id", "selected", "chosen") as T
}

export const getMessagingProviderByTypename = (
    __typename: string
): MessagingProviders | undefined => {
    switch (__typename) {
        case "SlackMessage":
            return MessagingProviders.SLACK
        case "EmailMessage":
            return MessagingProviders.EMAIL
    }
}

/*
 * This function returns all `VariableWithValue` items for a given thread response.
 * It searches inside thread variables and also inside user answers.
 *
 * note: as the dynamic variables are present on the thread response this method will also return `VariableWithValue`
 * items for dynamic variables BUT the value won't be defined (a separate query would be needed)
 */
export const getVariableValues = (threadResponse: ResponseThread): VariableWithValue[] => {
    const threadVariables = threadResponse?.variables || []
    const userAnswersAsVariables: VariableWithValue[] = (threadResponse?.userAnswers || [])
        .filter((userAnswers) => userAnswers.value)
        .map((answer: UserAnswer) => ({
            __typename: "VariableWithValue",
            value: answer.value!,
            ...pick(answer, ["name", "type"]),
        }))
    const duplicatedVariablesNames = intersectionWith(
        threadVariables,
        userAnswersAsVariables,
        (variable1, variable2) => variable1.name === variable2.name
    ).map((variable) => variable.name)
    // Here we remove the duplicate variables from the `threadVariables` this use case is used when
    // a thread variable is overwritten by a user answer
    const threadVariablesDuplicateFree = threadVariables.filter(
        (variable) => !duplicatedVariablesNames.includes(variable.name)
    )
    return [...threadVariablesDuplicateFree, ...userAnswersAsVariables]
}
/*
 * This function returns all the variable keys present on a given text.
 */
export const getPresentVariableKeys = (text: string | undefined): string[] => {
    let variableKeys: string[] = []
    if (!text) return variableKeys

    // new variables format
    const variableBlots = new DOMParser()
        .parseFromString(text, "text/html")
        .getElementsByClassName("variable-blot")

    for (let i = 0; i < variableBlots.length; i++) {
        const variableName = removeInvisibleCharacters(
            (variableBlots.item(i) as HTMLElement)?.outerText
        )
        const variableKey = removeInvisibleCharacters(variableName.replace("@", ""))
        variableKeys.push(variableKey)
    }

    // TODO: do we need support for old variables format?

    return variableKeys
}

export const replaceVariables = (text: string, variables: VariableWithValue[]): string => {
    const variableBlots = new DOMParser()
        .parseFromString(text, "text/html")
        .getElementsByClassName("variable-blot")
    let textAux

    const stringifyValue = (value: VariableValue, type: VariableType) => {
        switch (type) {
            case VariableType.Bool: {
                return value.boolean?.toString() || ""
            }
            case VariableType.Int: {
                return value.integer?.toString() || ""
            }
            case VariableType.String: {
                return value.string || ""
            }
            case VariableType.Ref: {
                return value.selectedChoices![0].text || ""
            }
            case VariableType.RefSet: {
                return value.selectedChoices?.map((item) => item.text).join(", ") || ""
            }
            default:
                return ""
        }
    }

    for (let i = 0; i < variableBlots.length; i++) {
        if (!textAux) textAux = text
        const variableName = removeInvisibleCharacters(
            (variableBlots.item(i) as HTMLElement)?.outerText
        )
        const variableKey = removeInvisibleCharacters(variableName.replace("@", ""))
        const variableItem = variables?.find((variable) => variable.name === variableKey)

        if (variableItem?.value && variableItem.type) {
            const variableValue = stringifyValue(variableItem?.value, variableItem.type)
            textAux = textAux.replace(variableName, variableValue)
        }
    }

    // this is done to continue the variable parsing (enables support for old AND new variables on the same text)
    if (!textAux) textAux = text

    /*
     * Old way to parse the variables
     * Note: this is still used for Pintura images with variables also AI generates this kind of variables
     * */
    // split the textAux chunks with @ in it, we don't care about the rest
    const foundVars = textAux.split(" ").filter((d) => removeHTML(d).indexOf("@") > -1)
    return foundVars.reduce((accum, variableText) => {
        // one chunk of text may have more than one variable
        const vars = variableText.split("@").filter((d) => d)
        // mutate the returning string
        let textAccum = accum
        vars.forEach((variable) => {
            // clean the html inside the text
            const cleanVariable = removeHTML(variable).split("")
            // variables can have trailing symbols, like @firstName's, so we go letter by letter until we find the variable
            for (let i = 0; i < cleanVariable.length; i++) {
                const searchText = cleanVariable.slice(0, i + 1).join("")
                const foundVar = variables.find((e) => e.name === searchText)
                // const foundVar = variables[searchText]
                if (foundVar && !cleanVariable[i + 1]?.match("^[a-zA-Z0-9]*$")) {
                    textAccum = textAccum.replace(
                        "@" + searchText,
                        stringifyValue(foundVar?.value, foundVar?.type)
                    )
                    break
                }
                if (i === cleanVariable.length - 1) {
                    textAccum = textAccum.replace("@" + searchText, "")
                }
            }
        })
        return textAccum
    }, textAux)
}

export const findItemRecursively = <T extends ItemWithChildren<T>>(
    items: Maybe<T[]>,
    comparator: (item: T) => boolean
): T | undefined => {
    if (!items) return undefined
    for (let item of items) {
        if (comparator(item)) {
            return item
        } else if (item.children) {
            const found = findItemRecursively(item.children, comparator)
            if (found) return found
        }
    }
    return undefined
}
/*
 * This function filters all the hidden blocks from an array in a recursive way.
 * Note: only supports hiding the following blocks: `appear_together`, `conditional`,
 * `include` and `ai_chat`
 * */
export const filterHiddenBlocks = (blocks: Block[]): Block[] => {
    let result: Block[] = []
    blocks.forEach((block) => {
        if (!block.hidden) {
            if (block.type === BlockType.CONDITIONAL) {
                // add the conditional block
                result.push({
                    ...block,
                    // add each branch of the conditional (branches can't be hidden)
                    branches: block.branches?.map((branch) => ({
                        ...branch,
                        // add the non-hidden objects of each branch (keep in mind that objects can
                        // be another conditional)
                        objects: filterHiddenBlocks(branch.objects),
                    })),
                })
            } else if (block.type === BlockType.INCLUDE || block.type === BlockType.SECTION) {
                result.push({ ...block, objects: filterHiddenBlocks(block.objects || []) })
            } else {
                result.push(block)
            }
        }
    })
    return result
}

export const filterSectionsBlocks = (blocks: Block[]): Block[] => {
    let result: Block[] = []
    blocks.forEach((block) => {
        if (block.type === BlockType.SECTION) {
            const sectionObjects = block.objects
            if (sectionObjects) result = [...result, ...sectionObjects]
        } else {
            result.push(block)
        }
    })
    return result
}

export const getAdminUrl = () => {
    return process.env.REACT_APP_ADMIN_URL
}

export const getPlainTextFromHtml = (html: string): string | null => {
    return new DOMParser().parseFromString(html, "text/html").documentElement.textContent
}

export const blockIsScorable = (block: Block) =>
    block?.options?.some((option) => option.correct) ||
    block?.responses?.some((response) => response.correct) ||
    block?.choices?.some((choice) => choice.correct)

export const hashString = (text: string): number => {
    return text.split("").reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0)
        return a & a
    }, 0)
}
