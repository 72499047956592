import { configureStore } from "@reduxjs/toolkit"
import blocksReducer from "./blocks"
import threadsReducer from "./threads"
import layoutReducer from "./layout"
import aiReducer from "./ai"
import { blocksMiddleware } from "./blocks.middleware"
import logger from "redux-logger"

const middlewares =
    process.env.NODE_ENV === "development" ? [blocksMiddleware, logger] : [blocksMiddleware]

export const store = configureStore({
    reducer: {
        blocksReducer: blocksReducer,
        threadsReducer: threadsReducer,
        layoutReducer: layoutReducer,
        aiReducer: aiReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["blocks/duplicateBlock"],
            },
        }).concat(middlewares),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
