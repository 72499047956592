import { CSSProperties, forwardRef, useEffect } from "react"
import { Loader } from "../Loader"
import StyledButton from "./styles"
import { useTheme } from "styled-components"

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean
    disabled?: boolean
    thread?: boolean
    label: string | JSX.Element
    onClick?: (e: any) => void
    className?: string
    type?: "button" | "submit"
    width?: number
    buttonStyle?: CSSProperties
    color?: string
    variant?: "filled" | "outline"
    keyBinding?: string
    loaderHeight?: number
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            loading,
            disabled,
            thread = false,
            label,
            type,
            width,
            buttonStyle,
            onClick,
            color,
            variant = "filled",
            keyBinding,
            loaderHeight,
            ...props
        },
        ref
    ) => {
        const theme = useTheme()

        /*
         * Mount a listener if a `keyBinding` is passes
         * */
        useEffect(() => {
            if (!keyBinding) return
            const onKeyDown = (event: any) => {
                if (loading || disabled) return
                switch (event.key) {
                    case keyBinding:
                        event.preventDefault()
                        onClick && onClick(event)
                        break
                }
            }

            document.addEventListener("keydown", onKeyDown)
            return () => document.removeEventListener("keydown", onKeyDown)
        }, [keyBinding])

        const calculateButtonColors = (): { background: string; color: string; border: string } => {
            if (variant === "outline") {
                return {
                    background: theme.headlandsWhite,
                    color: color ?? theme.headlandsPrimaryColor,
                    border: color ?? theme.headlandsPrimaryColor,
                }
            } else {
                return {
                    background: color ?? theme.headlandsPrimaryColor,
                    color: theme.headlandsWhite,
                    border: "rgba(0, 0, 0, 0.15)",
                }
            }
        }

        const buttonColors = calculateButtonColors()

        return (
            <StyledButton
                ref={ref}
                type={type || "button"}
                isLoading={loading}
                thread={thread}
                disabledStyle={disabled}
                disabled={!onClick ? disabled : false}
                style={width ? { width: width, ...buttonStyle } : buttonStyle}
                onClick={!disabled ? onClick : undefined}
                $colors={buttonColors}
                {...props}
            >
                {/* loader height should be equal to the line height*/}
                {loading ? <Loader color={buttonColors.color} size={loaderHeight ?? 18} /> : label}
            </StyledButton>
        )
    }
)
