import { useState, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { ImagePreviewIcon } from "../../../assets/icons/ImagePreviewIcon"
import SendIcon from "@mui/icons-material/Send"
import FullscreenImages, { ImageZoomType } from "../../../common/components/FullscreenImages"
import { useCurrentThreadGuid } from "../../../hooks/currentThread.hook"
import useImageEditor from "../../../hooks/useImageEditor.hook"
import { RootState } from "../../../redux/store"
import { StyledBox } from "../../../styles/styledcomponents"
import { Block, MediaSize, OptionPreviewMode } from "../../../types"
import IconButton from "../../IconButton"
import { StyledImageBubble } from "./styles"
import { isEmpty } from "lodash"
import { usePreviewMode } from "../../../hooks/previewMode.hook"
import { StyledImageLabel } from "../../Carousel/ImageCarousel/styles"

export interface ImagePreviewProps {
    block: Block
    fromUser?: boolean
    scrollToBottom?: () => void
    imageStyles?: React.CSSProperties
    label?: string
    isMobile?: boolean
}

export const ImagePreview = ({
    block,
    fromUser,
    scrollToBottom,
    imageStyles,
    label,
    isMobile,
}: ImagePreviewProps) => {
    const finalUrl = block.value || ""
    const [error, setError] = useState(false)
    const [url, setUrl] = useState<string>()
    const [imageLoaded, setImageLoaded] = useState<boolean>(false)
    const threadGuid = useCurrentThreadGuid()
    const attachments = useSelector(
        (state: RootState) => state.threadsReducer.threads[threadGuid]?.thread?.attachments
    )
    const { generateImageUrl, replaceImageVariables } = useImageEditor()
    const { previewDevice, previewMode } = usePreviewMode()
    const isMobilePreview = previewMode && previewDevice === OptionPreviewMode.MOBILE

    useEffect(() => {
        // is a static image
        if (block.value && block.value !== "") {
            setUrl(finalUrl)
        }
        // is a dynamic image
        else if (block.attachmentGuid && attachments && !isEmpty(attachments)) {
            const attachment = attachments.find(
                (attachment) => attachment.guid === block.attachmentGuid
            )
            if (attachment) {
                const changesWithVariablesReplaced = replaceImageVariables(
                    attachment?.changes ? JSON.parse(attachment?.changes) : {}
                )
                generateImageUrl(attachment.url, changesWithVariablesReplaced).then(
                    (urlGenerated) => setUrl(urlGenerated)
                )
            }
        }
    }, [block, attachments])

    const onImageLoad = () => {
        if (fromUser) {
            setImageLoaded(true)
            scrollToBottom && scrollToBottom()
        } else scrollToBottom && scrollToBottom()
    }

    /*
     * This method calculates the image size.
     * */
    const imageSize: MediaSize = useMemo(() => {
        return block.imageSize || MediaSize.medium
    }, [block.imageSize, isMobile])

    return (
        <StyledImageBubble
            size={imageSize}
            isMobile={isMobile || isMobilePreview}
            fromUser={fromUser}
            error={error}
        >
            <div>
                {error ? (
                    <ImagePreviewIcon />
                ) : url ? (
                    <FullscreenImages
                        src={url}
                        onImageLoad={onImageLoad}
                        styles={imageStyles}
                        zoomType={ImageZoomType.IMAGE}
                    />
                ) : null}
            </div>
            {label && <StyledImageLabel css={{ paddingTop: "6px" }}>{label}</StyledImageLabel>}
            {fromUser && imageLoaded && (
                <StyledBox css={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                    <IconButton
                        icon={<SendIcon fontSize={"small"} />}
                        onClick={() => {}}
                        disabled
                        transparent
                    />
                </StyledBox>
            )}
        </StyledImageBubble>
    )
}

export default ImagePreview
