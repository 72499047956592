import Popup from "reactjs-popup"
import styled, { css } from "styled-components"
import { StyledColumn, StyledSpan } from "../../styles/styledcomponents"

const FocusedStyle = css`
    background-color: ${({ theme }) => theme.headlandsGray6};
    border-bottom: 2px solid ${({ theme }) => theme.headlandsPrimaryColor};
`

export const StyledInput = styled.input<{ open?: boolean; error?: boolean }>`
    box-sizing: border-box;
    height: 26px;
    border: none;
    padding: 3px;
    border-radius: 4px 4px 3px 3px;
    outline: none;
    border-bottom: 2px solid transparent;
    color: ${({ theme }) => theme.colors.base.uiLabelDisabled};
    font-size: 14px;
    line-height: 20px;
    transition: all 0.2s;

    &:focus {
        ${FocusedStyle}
    }

    ${({ open }) =>
        open &&
        css`
            ${FocusedStyle}
        `}

    ${({ error }) =>
        error &&
        css`
            border-bottom: 2px solid ${({ theme }) => theme.headlandsError} !important;
        `}
`

export const StyledPopup = styled(Popup)`
    &-overlay {
        // note: this !important is necessary because on Modal.scss we overwrite the background-color
        background-color: transparent !important;
    }
`

export const StyledDropdownContainer = styled(StyledColumn)`
    background-color: ${({ theme }) => theme.headlandsWhite};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border: 1px solid ${({ theme }) => theme.headlandsGray3};
    border-radius: ${({ theme }) => theme.borderRadius1};
    width: 126px;
    max-height: 132px;
    box-sizing: border-box;
    overflow: auto;
    padding: 6px;
`

export const StyledDropdownItem = styled(StyledSpan)`
    padding: 3px 6px;
    color: ${({ theme }) => theme.headlandsGray2};
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.headlandsGray6};
    }
`
