import { AvatarSize } from "../../../types"
import { useMemo } from "react"
import { useTheme } from "styled-components"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import { format } from "date-fns"
import {
    StyledMoreVerticalIcon,
    StyledPublishIcon,
    StyledThreadRow,
    StyledThreadRowHr,
    StyledThreadRowMiddleContainer,
    StyledTitleContainer,
} from "./styles"
import { StyledBox, StyledHeadlandsPopup, StyledSpan } from "../../../styles/styledcomponents"
import PopupMenu, { PopupOptionType } from "../../../components/PopupMenu"
import { UserAvatar } from "../../../creator/components/UserAvatar"
import Checkbox from "../../../components/Checkbox"
import { PopupPosition } from "reactjs-popup/dist/types"
import EllipsisText from "../../../components/EllipsisText"
import { Maybe } from "graphql/jsutils/Maybe"
import { Person } from "../../../apollo/generated/graphql"
import { pick } from "lodash"

interface CreatorThreadRowProps {
    threadId?: number
    title?: string
    instructor?: Maybe<Partial<Person>>
    savedDate?: string
    lastPublishedDate?: string
    lastVersionIsAPublishedOne?: boolean
    isTheOwner?: boolean
    selected?: boolean
    handleOnClick?: () => void
    handleDeleteThread?: (e: any) => void
    handleDuplicateThread?: () => void
    handleCheckboxSelect?: () => void
    maxWidth?: number
    titleToltipPosition?: PopupPosition
    fullWidth?: boolean
    active?: boolean
}

const CreatorThreadRow = ({
    threadId,
    title,
    instructor,
    savedDate,
    lastPublishedDate,
    lastVersionIsAPublishedOne,
    isTheOwner,
    selected,
    handleOnClick,
    handleDeleteThread,
    handleDuplicateThread,
    handleCheckboxSelect,
    maxWidth = 182,
    titleToltipPosition = "right center",
    fullWidth,
    active,
}: CreatorThreadRowProps) => {
    const theme = useTheme()

    const savedLabel: string = useMemo(() => {
        if (!savedDate) return ""
        const distance = formatDistanceToNowStrict(new Date(savedDate), { addSuffix: true })
        if (lastVersionIsAPublishedOne) return `Published ${distance}`
        return `Draft saved ${distance}`
    }, [savedDate, lastVersionIsAPublishedOne])

    const lastPublishedLabel: string = useMemo(() => {
        if (!lastPublishedDate) return ""
        const dateFormatted = format(new Date(lastPublishedDate), "LLLL do yyyy")
        return `Latest published at ${dateFormatted}`
    }, [lastPublishedDate])

    return (
        <>
            <StyledThreadRow
                onClick={handleOnClick}
                data-testid={"creator-thread-row-" + threadId}
                data-testname={title}
                fullWidth={fullWidth}
                active={active}
            >
                {!!handleCheckboxSelect && (
                    <Checkbox selected={!!selected} onChange={handleCheckboxSelect} />
                )}
                <UserAvatar
                    size={AvatarSize.XXL}
                    user={
                        instructor
                            ? { ...pick(instructor, ["profileImageURL", "firstName", "lastName"]) }
                            : undefined
                    }
                />

                <StyledThreadRowMiddleContainer maxWidth={maxWidth}>
                    <StyledTitleContainer>
                        {lastPublishedDate && (
                            <StyledHeadlandsPopup
                                position={"top center"}
                                on={["hover"]}
                                mouseLeaveDelay={200}
                                arrow={false}
                                trigger={
                                    <StyledBox css={{ marginRight: "3px" }}>
                                        <StyledPublishIcon />
                                    </StyledBox>
                                }
                            >
                                <StyledSpan>{lastPublishedLabel}</StyledSpan>
                            </StyledHeadlandsPopup>
                        )}
                        <StyledBox css={{ maxWidth }}>
                            <EllipsisText
                                variant={"h4"}
                                text={title || ""}
                                maxWidth={maxWidth}
                                popupOptions={{ position: titleToltipPosition }}
                                textStyles={{ color: theme.colors.base.uiLabelBase }}
                            />
                        </StyledBox>
                    </StyledTitleContainer>
                    <StyledSpan
                        css={{
                            fontSize: 12,
                            lineHeight: "18px",
                            color: theme.colors.base.uiLabelDisabled,
                        }}
                    >{`with ${instructor?.firstName} ${instructor?.lastName}`}</StyledSpan>
                    <StyledSpan
                        css={{
                            fontSize: 10,
                            lineHeight: "18px",
                            color: theme.colors.base.uiLabelDisabled,
                        }}
                    >
                        {savedLabel}
                    </StyledSpan>
                </StyledThreadRowMiddleContainer>
                {(handleDuplicateThread || handleDeleteThread) && (
                    <PopupMenu
                        position={"top center"}
                        on={["click"]}
                        data-testid={"tooltip-thread" + threadId}
                        arrow={false}
                        overlayStyle={{ backgroundColor: "transparent" }}
                        options={[
                            {
                                label: "Duplicate",
                                onClick: () => handleDuplicateThread && handleDuplicateThread(),
                                type: PopupOptionType.TEXT,
                            },
                            {
                                label: "Delete",
                                onClick: (e) => handleDeleteThread && handleDeleteThread(e),
                                disabled: !isTheOwner,
                                styles: { color: `${theme.headlandsError}!important` },
                                dataTestId: `delete-thread-${threadId}`,
                                type: PopupOptionType.TEXT,
                            },
                        ]}
                        trigger={
                            <StyledMoreVerticalIcon
                                data-testid={"options-thread-" + threadId}
                                fontSize={"small"}
                                sx={{ color: theme.headlandsGray10 }}
                            />
                        }
                    />
                )}
            </StyledThreadRow>
            <StyledThreadRowHr />
        </>
    )
}

export default CreatorThreadRow
