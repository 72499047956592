import { PinturaEditor } from "react-pintura"
import { CropPresetOption, getEditorDefaults, PinturaDefaultImageWriterResult } from "pintura"
import "pintura/pintura.css"
import "./ImageEditorModal.scss"
import { ImageEditionChanges } from "../../../types"
import { PopupContentStyle, StyledButtonsContainer } from "./styles"
import { Button } from "../../../components/Button/Button"
import { useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import Modal from "../../../components/Modal"

interface ImageEditorModalProps {
    isOpen: boolean
    onClose: () => void
    src?: string
    onSave: (changes: ImageEditionChanges, imageFile: File) => void // the function to save the changes
    state?: { [key: string]: any } // The object with all the changes made
}

const ImageEditorModal = ({ isOpen, onClose, src, state, onSave }: ImageEditorModalProps) => {
    const editorRef = useRef<any>(null)
    const [previewLoaded, setPreviewLoaded] = useState<boolean>(false)
    const [processingLoading, setProcessingLoading] = useState<boolean>(false)
    if (!isOpen || !src) return null

    const editorConfig = {
        ...getEditorDefaults(),
        onHide: onClose,
        className: "custom-editor-modal",
        enableButtonExport: false,
        cropSelectPresetOptions: [
            [3 / 4, "Portrait"],
            [4 / 3, "Landscape 4:3"],
            [16 / 9, "Landscape 16:9"],
            [1, "Square"],
        ] as CropPresetOption[],
    }

    const handleClose = () => {
        onClose()
        setPreviewLoaded(false)
        setProcessingLoading(false)
    }

    /*
     * This method handles the 'save' functionality. When the user finish editing the image, we process that image
     * and then save it
     * */
    const handleProcess = () => {
        setProcessingLoading(true)
        editorRef?.current?.editor
            ?.processImage()
            .then(({ dest, imageState }: PinturaDefaultImageWriterResult) => {
                setProcessingLoading(false)
                onSave(imageState, dest)
                handleClose()
            })
            .catch(() => setProcessingLoading(false))
    }

    return (
        <Modal
            position={"center center"}
            open={true}
            onClose={handleClose}
            contentStyle={PopupContentStyle}
        >
            {previewLoaded && (
                <StyledButtonsContainer>
                    <CloseIcon onClick={handleClose} className={"close-icon"} />
                </StyledButtonsContainer>
            )}
            <PinturaEditor
                {...editorConfig}
                src={src}
                imageState={state}
                ref={editorRef}
                onLoadpreview={() => setPreviewLoaded(true)}
            />
            {previewLoaded && (
                <StyledButtonsContainer>
                    <Button
                        label={"Cancel"}
                        onClick={handleClose}
                        disabled={processingLoading}
                        variant={"outline"}
                    />
                    <Button label={"Done"} onClick={handleProcess} disabled={processingLoading} />
                </StyledButtonsContainer>
            )}
        </Modal>
    )
}

export default ImageEditorModal
