import { IconProps } from "../../types"
import { useTheme } from "styled-components"

const LeaderboardIcon = ({ width = 24, height = 24, color, ...rest }: IconProps) => {
    const theme = useTheme()

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M15.3542 17.7847V6.21528C15.3542 4.75644 14.3421 4 12.932 4H11.068C9.65789 4 8.49998 4.75644 8.49998 6.21528V17.7847C8.49998 19.2436 8.12175 20 9.53845 20H14.4615C15.8716 20 15.3542 19.2436 15.3542 17.7847ZM13.7794 17.7037C13.7794 18.3318 13.4616 18.6762 12.8194 18.6762H11.1805C10.5384 18.6762 10.1538 18.3318 10.1538 17.7037V6.29633C10.1538 5.67497 10.5384 5.32377 11.1805 5.32377H12.8194C13.4616 5.32377 13.7794 5.67497 13.7794 6.29633V17.7037Z"
                fill={color ?? theme.colors.base.uiBgIcon}
            />
            <path
                d="M10.1538 18.5759V11.1384C10.1538 10.2006 9.419 9.71429 8.00892 9.71429H6.1449C4.73482 9.71429 3.99998 10.2006 3.99998 11.1384V18.5759C3.99998 19.5137 4.7282 20 6.1449 20H9.53845C10.9485 20 10.1538 19.5137 10.1538 18.5759ZM8.49998 18.2123C8.49998 18.6161 8.53853 18.8375 7.89638 18.8375H6.25744C5.61529 18.8375 5.62297 18.6161 5.62297 18.2123V11.4971C5.62297 11.0977 5.61529 11.1384 6.25744 11.1384H7.89638C8.53853 11.1384 8.49998 11.0977 8.49998 11.4971V18.2123Z"
                fill={color ?? theme.colors.base.uiBgIcon}
            />
            <path
                d="M20 19.0506V14.0923C20 13.467 19.2652 13.1429 17.8551 13.1429H15.9911C14.581 13.1429 13.8461 13.467 13.8461 14.0923V19.0506C13.8461 19.6758 12.4294 20 13.8461 20H17.8551C19.2652 20 20 19.6758 20 19.0506ZM18.5 18.4403C18.5 18.7095 18.3847 18.8571 17.7425 18.8571H16.1036C15.4614 18.8571 15.3542 18.7095 15.3542 18.4403V14.7025C15.3542 14.4362 15.4614 14.5 16.1036 14.5H17.7425C18.3847 14.5 18.5 14.4362 18.5 14.7025C18.5 14.9688 18.5 18.4403 18.5 18.4403Z"
                fill={color ?? theme.colors.base.uiBgIcon}
            />
        </svg>
    )
}

export default LeaderboardIcon
