import { Block } from "../../../types"
import { StyledBox, StyledHr } from "../../../styles/styledcomponents"
import classNames from "classnames"
import { selectBlock } from "../../../redux/blocks"
import { useDispatch } from "react-redux"
import { useIsBlockSelected } from "../../../hooks/isBlockSelected.hook"
import { useTheme } from "styled-components"

export interface DividerProps {
    block: Block
}

const DividerComponent = ({ block }: DividerProps) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const focused = useIsBlockSelected(block.id)

    const handleFocus = () => !focused && dispatch(selectBlock({ blockId: block.id }))

    return (
        <StyledBox className={classNames("block")} id={`block-${block.id}`} onClick={handleFocus}>
            <StyledHr
                css={{
                    marginLeft: "6px",
                    marginRight: "6px",
                    borderColor: theme.headlandsGray3,
                }}
            />
        </StyledBox>
    )
}

export default DividerComponent
