import { addBlocks, addBlockVersion, getFullBlockById, updateAutoSaveTimeout } from "./blocks"
import { PayloadAction, Dispatch } from "@reduxjs/toolkit"
import { duplicateBlock, generateBlockClipboardData } from "../thread/utils"
const AUTO_SAVE_TIMEOUT = 5000

const blocksMiddleware = (store: any) => (next: Dispatch) => (action: PayloadAction<any>) => {
    /*
     * Important:
     * we get the timeout id BEFORE calling `next(action)` because the timeout id
     * will be deleted on the reducer and requesting it after calling `next(action)`
     * will end up with an undefined timeout id.
     *
     * Short note: don't move this const unless you want to break the auto save 🤗
     * */
    const timeoutIdToClear = store?.getState()?.blocksReducer.autoSaveTimeout.timeoutID

    next(action)

    const blocksReducer = store?.getState()?.blocksReducer
    switch (action.type) {
        case "blocks/updateBlock":
        case "blocks/updateBranch":
        case "blocks/addBlocks":
        case "blocks/addBranch":
        case "blocks/deleteBlock":
        case "blocks/deleteBranch":
        case "blocks/addBranchIndex":
        case "blocks/undoBlockVersion":
        case "blocks/redoBlockVersion":
        case "blocks/duplicateBlock":
        case "blocks/moveBlock":
        case "blocks/updateQuestionOptions":
        case "thread/updateThread":
        case "threads/updateStyle":
            if (action.type === "blocks/duplicateBlock") {
                const fullBlock = getFullBlockById(
                    action.payload.blockId,
                    blocksReducer[action.payload.blocksType],
                    blocksReducer[`${action.payload.blocksType}Index`]
                )
                const duplicatedBlock = duplicateBlock(fullBlock)
                store.dispatch(
                    addBlocks({
                        blocksType: action.payload.blocksType,
                        branchId: action.payload.branchId,
                        index: action.payload.blockIndex + 1,
                        blocks: [duplicatedBlock],
                        sectionId: action.payload.sectionId,
                        questionOptionId: action.payload.questionOptionId,
                        freeTextResponseId: action.payload.freeTextResponseId,
                    })
                )
                action.payload.callback && action.payload.callback(duplicatedBlock)
            }

            const autoSaveTimeout = blocksReducer?.autoSaveTimeout

            const clearTimeoutAndAddNewOne = (
                timeout: typeof autoSaveTimeout,
                functionToDispatch: Function,
                milliseconds: number
            ) => {
                const previousTimeoutID = timeout?.timeoutID
                if (previousTimeoutID) clearTimeout(previousTimeoutID)
                const newTimeoutID = setTimeout(() => {
                    store.dispatch(functionToDispatch({ editing: false, timeoutID: undefined }))
                }, milliseconds)
                store.dispatch(functionToDispatch({ timeoutID: newTimeoutID }))
            }

            const clearAutoSaveTimeoutAndAddANewOne = () =>
                clearTimeoutAndAddNewOne(autoSaveTimeout, updateAutoSaveTimeout, AUTO_SAVE_TIMEOUT)

            if (!autoSaveTimeout?.editing) {
                store.dispatch(updateAutoSaveTimeout({ editing: true }))
                clearAutoSaveTimeoutAndAddANewOne()
            } else clearAutoSaveTimeoutAndAddANewOne()

            // avoid adding block version if the action is from undo or redo
            const shouldAddBlockVersion = ![
                "blocks/undoBlockVersion",
                "blocks/redoBlockVersion",
            ].includes(action.type)

            if (shouldAddBlockVersion) store.dispatch(addBlockVersion())

            break
        case "blocks/updateAutoSaveTimeout":
            // If the action was dispatched with an undefined timeoutID,
            // then we want to clear the previous timeout too.
            if (action.payload.timeoutID === undefined && timeoutIdToClear) {
                clearTimeout(timeoutIdToClear)
            }
            break
        case "blocks/copyBlocks":
            // TODO: For now we only support copying 1 block at a time
            const copyBlock = getFullBlockById(
                action.payload.blocks[0].id,
                blocksReducer[action.payload.blocksType],
                blocksReducer[`${action.payload.blocksType}Index`]
            )
            navigator.clipboard.writeText(generateBlockClipboardData([copyBlock]))
            break
    }
}

export { blocksMiddleware }
