import LoaderSpinner from "react-loader-spinner"
import { StyledLoaderContainer, StyledOverlayLoader, StyledOverlaySpinnerContainer } from "./styles"
import { CSSObject, useTheme } from "styled-components"
import { StyledBox } from "../../styles/styledcomponents"

export interface LoaderProps {
    color?: string
    size?: number
    css?: CSSObject
}

export const Loader = ({ color, size = 30, css }: LoaderProps) => {
    const theme = useTheme()

    return (
        <StyledLoaderContainer css={css}>
            <LoaderSpinner
                type={"TailSpin"}
                color={color || theme.headlandsPrimaryColor}
                height={size}
                width={size}
            />
        </StyledLoaderContainer>
    )
}

export interface OverlayLoaderProps {
    color?: string
    size?: number
}

export const OverlayLoader = ({ color, size = 72 }: OverlayLoaderProps) => (
    <StyledOverlayLoader>
        <Loader color={color} size={size} />
    </StyledOverlayLoader>
)

export interface OverlayOpacityLoaderProps {
    color?: string
    size?: number
    children: any
    loading: boolean
}

export const OverlayOpacityLoader = ({
    color,
    size = 72,
    children,
    loading,
}: OverlayOpacityLoaderProps) => {
    const theme = useTheme()

    return (
        <StyledBox css={{ position: "relative" }}>
            {loading && (
                <StyledOverlaySpinnerContainer>
                    <LoaderSpinner
                        type={"TailSpin"}
                        color={color || theme.headlandsPrimaryColor}
                        height={size}
                        width={size}
                    />
                </StyledOverlaySpinnerContainer>
            )}
            <StyledBox css={{ opacity: loading ? 0.5 : 1 }}>{children}</StyledBox>
        </StyledBox>
    )
}
