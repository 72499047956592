import Link from "@mui/icons-material/Link"
import { rgba } from "polished"
import styled from "styled-components"
import { StyledHr, StyledImage, StyledRow, StyledSpan } from "../../styles/styledcomponents"
import { StyledLoaderContainer } from "../Loader/styles"
import { ExpandMore } from "@mui/icons-material"
import { StyledModal } from "../Modal/styles"

export const StyledShareModal = styled(StyledModal)`
    &-content {
        cursor: default;
        display: flex;
        flex-direction: column;
        background-color: ${({ theme }) => theme.headlandsWhite};
        padding: 12px !important;
        gap: 12px;
        box-shadow: 0 6px 52px 1px rgba(0, 0, 0, 0.15);
        border-radius: 16px;

        ${StyledHr} {
            margin: 0;
        }

        /* we update the disabled styles only for this use case */
        input {
            &:disabled,
            &:read-only {
                background-color: unset;
                opacity: 0.3;
            }
        }
    }
`

export const StyledEmailsContainer = styled(StyledRow)`
    flex: 1;
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    border-radius: 8px;
    padding: 6px 6px 6px 12px;
    align-items: center;
    justify-content: space-between;
`

export const StyledLinkIcon = styled(Link)`
    transform: rotate(-45deg);
    font-size: 18px !important;
    color: ${({ theme }) => theme.headlandsGray10};
`

export const StyledChevronDown = styled(ExpandMore)``

export const StyledCopyLinkButton = styled(StyledRow)`
    height: 30px;
    box-sizing: border-box;
    align-items: center;
    gap: 6px;
    background-color: ${({ theme }) => rgba(theme.headlandsPrimaryColor, 0.25)};
    border-radius: 16px;
    padding: 6px 12px;
    cursor: pointer;

    ${StyledLoaderContainer} {
        width: auto;
    }

    ${StyledSpan} {
        color: ${({ theme }) => theme.headlandsPrimaryColor};
        font-weight: 500;
    }
`

export const StyledSocialMediaIcon = styled(StyledImage)<{ disabled?: boolean }>`
    opacity: ${({ disabled }) => (disabled ? 0.3 : "unset")};
`

StyledSocialMediaIcon.defaultProps = { width: 24, height: 24 }
