import { Button } from "../../../components/Button/Button"
import { StyledBody2 } from "../../../styles/styledcomponents"
import { Block, BlocksType, SelectOption } from "../../../types"
import { StyledCodeBlock, StyledCodeContent, StyledHeader } from "./styles"
import Select from "../../../components/Select/Select"
import { useTheme } from "styled-components"
import { useDispatch } from "react-redux"
import { updateBlock } from "../../../redux/blocks"
import CodeMirror from "@uiw/react-codemirror"
import { langs } from "@uiw/codemirror-extensions-langs"
import { githubDark } from "@uiw/codemirror-theme-github"
import { useMemo, useState } from "react"
import { capitalize } from "lodash"
import { useToast } from "../../../hooks/useToast.hook"
import CodeIcon from "@mui/icons-material/Code"

interface BaseCodeBlockProps {
    block: Block
}

interface CodeBlockProps extends BaseCodeBlockProps {
    blocksType: BlocksType
    readOnly?: boolean
}

interface ReadOnlyCodeBlockProps extends BaseCodeBlockProps {
    readOnly: true
    blocksType?: undefined
}

const CodeBlock = ({ block, blocksType, readOnly }: CodeBlockProps | ReadOnlyCodeBlockProps) => {
    const [langSelectOpen, setLangSelectOpen] = useState(false)

    const dispatch = useDispatch()

    const { showToast } = useToast()

    const languages = useMemo(
        () =>
            Object.keys(langs)
                .sort()
                .map((lang) => ({ value: lang, label: capitalize(lang) })),
        [langs]
    )

    const extensions = useMemo(
        () => (block.language ? [langs[block.language as keyof typeof langs]()] : []),
        [block.language, langs]
    )

    const handleValueChange = (newValue: string) => {
        blocksType &&
            dispatch(
                updateBlock({
                    blocksType,
                    id: block.id,
                    block: { ...block, value: newValue },
                })
            )
    }

    const handleLanguageChange = (newLanguage: SelectOption) => {
        blocksType &&
            dispatch(
                updateBlock({
                    blocksType,
                    id: block.id,
                    block: { ...block, language: newLanguage.value },
                })
            )
    }

    const handleCopyCode = () => {
        if (block.value) {
            navigator.clipboard.writeText(block.value).catch((e) => console.log(e))
            showToast({
                icon: <CodeIcon sx={{ fontSize: "14px", color: "white" }} />,
                message: "Code copied to clipboard",
            })
        }
    }

    return (
        <StyledCodeBlock readOnly={readOnly}>
            <StyledHeader langSelectOpen={langSelectOpen}>
                {!readOnly && (
                    <LanguageDropdown
                        value={languages.find((lang) => lang.value === block.language)}
                        options={languages}
                        onChange={handleLanguageChange}
                        open={langSelectOpen}
                        onOpen={() => setLangSelectOpen(true)}
                        onClose={() => setLangSelectOpen(false)}
                    />
                )}
                <Button onClick={handleCopyCode} label={<StyledBody2>Copy</StyledBody2>} />
            </StyledHeader>
            <StyledCodeContent>
                <CodeMirror
                    value={block.value}
                    placeholder={"Enter code here"}
                    onChange={handleValueChange}
                    extensions={extensions}
                    theme={githubDark}
                    basicSetup={{
                        autocompletion: false,
                        lineNumbers: false,
                        foldGutter: false,
                        highlightActiveLineGutter: false,
                        highlightActiveLine: false,
                    }}
                    readOnly={readOnly}
                />
            </StyledCodeContent>
        </StyledCodeBlock>
    )
}

type LanguageDropdownProps = {
    value?: SelectOption
    options: SelectOption[]
    open: boolean
    onChange: (newValue: SelectOption) => void
    onOpen: () => void
    onClose: () => void
}

const LanguageDropdown = ({
    value,
    options,
    open,
    onChange,
    onOpen,
    onClose,
}: LanguageDropdownProps) => {
    const theme = useTheme()
    return (
        <Select
            value={value}
            options={options}
            onChange={(newValue) => newValue && onChange(newValue)}
            isMulti={false}
            placeholder={"Language"}
            menuIsOpen={open}
            onMenuOpen={onOpen}
            onMenuClose={onClose}
            styles={{
                container: () => ({
                    width: "auto",
                    flex: "unset",
                }),
                control: (_, state) => ({
                    height: "24px",
                    minHeight: "unset",
                    padding: "3px 6px",
                    margin: 0,
                    gap: "3px",
                    border: "none",
                    boxShadow: "none",
                    borderRadius: "4px",
                    transition: "unset",
                    cursor: "pointer",
                    width: "fit-content",
                    fontSize: "12px",
                    backgroundColor: state.menuIsOpen ? "rgba(0, 0, 0, 0.6)" : "initial",
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                    },
                }),
                singleValue: () => ({
                    color: theme.colors.base.uiLabelSubtitle,
                    fontSize: "12px",
                }),
                menu: () => ({
                    width: "212px",
                    left: "unset",
                    margin: "2px 0 0",
                    border: "none",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)",
                }),
                menuList: () => ({
                    maxHeight: "262px",
                }),
                option: () => ({
                    cursor: "pointer",
                    fontSize: "12px",
                }),
                dropdownIndicator: () => ({
                    color: theme.colors.base.uiLabelSubtitle,
                    "&:hover": {
                        color: theme.colors.base.uiLabelSubtitle,
                    },
                }),
                input: () => ({
                    margin: 0,
                }),
            }}
        />
    )
}

export default CodeBlock
