import styled, { css } from "styled-components"
import { StyledBox, StyledSpan } from "../../../styles/styledcomponents"
import { TopBarTitleStyles } from "../../../components/TopBar/styles"
import IosShare from "@mui/icons-material/IosShare"

export const StyledIconTopBar = styled.div<{ $loading?: boolean; disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 5px;
    border: 1px solid ${({ theme }) => theme.headlandsGray3};
    margin-right: 12px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    path {
        fill: ${({ theme }) => theme.headlandsGray10};
    }
    &:hover {
        transition: all 0.2s ease-out;
        border-color: ${({ theme, disabled }) => !disabled && theme.headlandsPrimaryColor};
        path {
            fill: ${({ theme }) => theme.headlandsPrimaryColor};
        }
    }

    ${({ $loading }) =>
        $loading &&
        css`
            path {
                fill: transparent !important;
            }
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
            border: 1px solid ${({ theme }) => theme.headlandsGray3};
            path {
                fill: ${({ theme }) => theme.headlandsGray3} !important;
            }
        `}
`

export const StyledThreadTitleContainer = styled(StyledBox)<{ rightContainerWidth?: number }>`
    max-width: ${({ theme, rightContainerWidth }) =>
        `calc(
            100% -
            ${rightContainerWidth || 0}px -
            ${theme.variables.common.topBarPadding.left} -
            ${theme.variables.common.topBarPadding.right}
        )`};

    input {
        border-style: none;
        max-width: inherit;
        text-overflow: ellipsis;

        &:hover,
        &:focus-visible {
            outline-color: ${({ theme }) => theme.utils.addOpacityToHex(theme.headlandsGray7, 0.2)};
            outline-width: 1px;
        }
        ${TopBarTitleStyles}
    }
`

export const StyledSavedLabel = styled(StyledSpan)<{ error?: boolean }>`
    margin-right: 12px;
    white-space: nowrap;
    color: ${({ theme, error }) => (error ? theme.headlandsError : theme.headlandsGray2)};
`

export const StyledShareIcon = styled(IosShare)`
    font-size: 18px !important;
`
