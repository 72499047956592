import { useTheme } from "styled-components"
import { IconProps } from "../../types"

export const ShareIcon = ({ width = 24, height = 24, color }: IconProps) => {
    const theme = useTheme()

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9966 14.4493C11.7059 14.4493 11.4625 14.1941 11.4625 13.8963V6.61586L11.5031 5.5525L11.0434 6.05583L10.0225 7.20425C9.92789 7.31768 9.77915 7.37439 9.6507 7.37439C9.36676 7.37439 9.16394 7.16172 9.16394 6.87107C9.16394 6.7222 9.21803 6.60877 9.31944 6.50244L11.6045 4.1914C11.7465 4.04962 11.8614 4 11.9966 4C12.1386 4 12.2535 4.04962 12.3887 4.1914L14.6738 6.50244C14.7752 6.60877 14.8361 6.7222 14.8361 6.87107C14.8361 7.16172 14.6197 7.37439 14.3425 7.37439C14.2073 7.37439 14.0721 7.31768 13.9775 7.20425L12.9499 6.05583L12.4969 5.5525L12.5375 6.61586V13.8963C12.5375 14.1941 12.2873 14.4493 11.9966 14.4493ZM8.12282 20C6.70986 20 6 19.2627 6 17.8024V10.7275C6 9.26717 6.70986 8.52991 8.12282 8.52991H10.009V9.67125H8.13634C7.46028 9.67125 7.08845 10.0541 7.08845 10.7913V17.7386C7.08845 18.4759 7.46028 18.8587 8.13634 18.8587H15.8569C16.5262 18.8587 16.9115 18.4759 16.9115 17.7386V10.7913C16.9115 10.0541 16.5262 9.67125 15.8569 9.67125H13.991V8.52991H15.8772C17.2901 8.52991 18 9.26717 18 10.7275V17.8024C18 19.2627 17.2901 20 15.8772 20H8.12282Z"
                fill={color ?? theme.colors.base.uiBgBase}
            />
        </svg>
    )
}
