import styled, { css } from "styled-components"
import { StyledBox, StyledH6 } from "../../../styles/styledcomponents"

export const StyledSettingsBar = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: calc(${({ theme }) => theme.sidebarWidths.properties} * 1px);
    height: 100%;
    border-left: 1px solid ${({ theme }) => theme.headlandsGray3};
    padding: 18px 24px;
    box-sizing: border-box;
`

export const StyledSettingsBarTabs = styled.div`
    display: flex;
`

export const StyledSettingsBarTab = styled.h4<{ selected?: boolean }>`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid transparent;
    color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
    font-size: 20px;
    line-height: 23px;
    padding-bottom: 12px;

    &:hover {
        cursor: pointer;
    }

    ${({ selected }) =>
        selected &&
        css`
            border-bottom-color: ${({ theme }) => theme.colors.base.uiPrimary500};
        `}
`

const SettingsBarSectionTitleStyles = css`
    padding-bottom: 6px;
    color: ${({ theme }) => theme.colors.base.uiLabelDisabled};
    border-bottom: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    margin: 24px 0 12px 0;
`
export const StyledSettingsBarSectionTitle = styled(StyledH6)`
    ${SettingsBarSectionTitleStyles};
`

export const StyledSettingsBarSectionTitleContainer = styled(StyledBox)`
    ${SettingsBarSectionTitleStyles};
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
        flex: 1;
        padding: 2px 0;
        border: none;
        outline: none;
        color: ${({ theme }) => theme.headlandsGray1};
    }
`

export const StyledSettingsBarContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    height: 100%;

    textarea {
        box-shadow: none !important;
        padding: 6px 12px;
        border-color: ${({ theme }) => theme.colors.base.uiBgBorder} !important;
    }
`
