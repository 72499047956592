export const Accordion = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_28251_94517)">
                <path
                    d="M4.7207 7.27875L5.7782 6.22125L9.2207 9.65625L12.6632 6.22125L13.7207 7.27875L9.2207 11.7788L4.7207 7.27875Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_28251_94517">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
