import BreakpointButton from "../../BreakpointButton/BreakpointButton"
import { generateUUID } from "../../../utils/utils"
import { useEffect, useRef, useState, useCallback } from "react"
import { BlockType } from "../../../types"
import { useThreadContentRef } from "../../../hooks/threadContentRef.hook"
import useIntersectionObserver from "../../../hooks/intersection.hook"
import { StyledBreakpointBubble } from "./styles"
import { StyledBox } from "../../../styles/styledcomponents"
import withUserInput, {
    InteractiveComponentBaseProps,
    UserInputProps,
} from "../../../hocs/userInput.hoc"

interface BreakpointPreviewProps extends UserInputProps, InteractiveComponentBaseProps {
    isMobile?: boolean
}

const BreakpointPreview = ({
    block,
    handleAnswer,
    answer,
    isMobile,
    choiceProps,
}: BreakpointPreviewProps) => {
    const pauseRef = useRef<HTMLDivElement>(null)
    const [pauseIsVisible, setPauseIsVisible] = useState<boolean>()
    const renderTime = useRef<number>(new Date().valueOf())

    // y represents the total number of reported pixels the viewport has been scrolled
    // down beneath its content.
    const [y, setY] = useState<number>(0)

    const buttonRef = useRef<HTMLDivElement>(null)
    // when touchscreen events are in play, this is the Y coordinate
    // the touch began at
    const touchStartY = useRef<number | undefined>()
    // the delta Y of the last mouse event
    const lastDelta = useRef<number>(0)
    // whether the breakpoint has been triggered
    const [triggered, setTriggered] = useState<boolean>(!!answer)

    const threadContentRef = useThreadContentRef()

    // distance returns a normalized number that represents how hard the user has
    // tried to "pull" the viewport up to reveal more content.
    //
    // The number is calculated using a logarithm to produce a "tension" effect where
    // a little tug has a big impact, but pulling more is harder
    //
    // the maximum value of this number tends to be between 70-150 depending on the type of
    // input device in play (trackpad/mousewheel/touch screen)
    const distance = function () {
        return Math.log2(1 + y) * 10
    }
    const onContinue = () => {
        if (!triggered) setTriggered(true)
        setTimeout(() => {
            handleAnswer([
                {
                    id: generateUUID(),
                    text: "",
                },
            ])
        })
    }

    const handleScrollTrigger = () => {
        buttonRef.current?.click()
    }

    const { observe: observeElement, unobserve: unobserveElement } = useIntersectionObserver({
        partialViewableCallback: () => setPauseIsVisible(true),
        fullyUnViewableCallback: () => setPauseIsVisible(false),
        fullyViewableCallback: () => setPauseIsVisible(true),
    })

    /*
     * Observe the pause component if the pause was not trigger
     * */
    useEffect(() => {
        if (!triggered) {
            pauseRef?.current && observeElement(pauseRef?.current)
        }
    }, [pauseRef, triggered])

    /*
     * Unobserve the pause component if the pause was trigger
     * */
    useEffect(() => {
        if (triggered) {
            pauseRef?.current && unobserveElement(pauseRef?.current)
        }
    }, [triggered])

    /*
     * Whenever we get an answer for the breakpoint we set the trigger state to true (if was not set yet)
     * */
    useEffect(() => {
        if (!triggered && answer) {
            setTriggered(true)
        }
    }, [answer, triggered])

    const handleScroll = useCallback(
        (event: any) => {
            // A single hearty track pad flick (on osx) may take a couple
            // seconds to dole out all of the scroll events.  This means when
            // one breakpoint is advanced, the next breakpoint will get some
            // number of events if it is displayed quickly (i.e. in a group)
            //
            // Similarly, when the user is not at the bottom and heartily
            // swipes down to scroll the viewport all the way to the bottom,
            // more scroll events will be sent once we get to the bottom - and
            // it feels uncomfortable if the breakpoint advances when we get
            // to the bottom in this case.
            //
            // The single trick that addresses BOTH cases is to determine if
            // the mouse is *accelerating* once we are at the bottom of the screen.
            //
            // For the first case, by the time the bottom is reached, usually
            // the synthetic scroll events are decreasing in magnitude.
            // For the second case, same
            //
            // conversely, if you are *close* to the bottom and swipe, the first
            // couple events will get you to the bottom, and you'll still be accelerating
            // so you can trigger the breakpoint.  which feels right!
            const accelerating = Math.round(event.deltaY) > lastDelta.current
            lastDelta.current = Math.round(event.deltaY)

            if (!pauseIsVisible) {
                // ignore scroll event when the pause is not visible
                return
            }

            if (!accelerating) {
                // ignore events where the mouse movement is not increasing in magnitude
                return
            }

            // finally, let's ignore events that occur within 800ms of render.
            // this is another safeguard on the issue where a swipe for a breakpoint
            // triggeres the next (trackpad specific fix)
            let sinceRender = new Date().valueOf() - renderTime.current
            if (sinceRender < 800) {
                return
            }

            const scrollingDown = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail)) <= 0
            if (scrollingDown) {
                const current = Math.round(event.deltaY)
                if (current > 1) {
                    setY((y) => y + current)
                }
            }
        },
        [renderTime, pauseIsVisible]
    )

    const handleTouchStart = useCallback(
        (event: any) => {
            touchStartY.current = event.changedTouches[0].clientY
        },
        [touchStartY]
    )

    const handleTouchMove = useCallback(
        (event: any) => {
            if (!pauseIsVisible) {
                // ignore scroll event when the pause is not visible
                return
            }
            const delta = (touchStartY.current || 0) - event.changedTouches[0].clientY
            setY(delta)
        },
        [touchStartY, pauseIsVisible]
    )

    const handleTouchEnd = useCallback(() => {
        touchStartY.current = undefined
    }, [touchStartY])

    // once distance hits a threshold, we trigger the breakpoint
    if (distance() > 65.0 && !triggered) {
        setTriggered(true)
        setTimeout(handleScrollTrigger, 0)
    }

    useEffect(() => {
        if (block.type === BlockType.PAUSE && !triggered) {
            threadContentRef?.addEventListener("wheel", handleScroll)
            threadContentRef?.addEventListener("touchstart", handleTouchStart)
            threadContentRef?.addEventListener("touchmove", handleTouchMove)
            threadContentRef?.addEventListener("touchend", handleTouchEnd)
            return () => {
                threadContentRef?.removeEventListener("wheel", handleScroll)
                threadContentRef?.removeEventListener("touchstart", handleTouchStart)
                threadContentRef?.removeEventListener("touchmove", handleTouchMove)
                threadContentRef?.removeEventListener("touchend", handleTouchEnd)
            }
        }
    }, [triggered, handleScroll, handleTouchStart, handleTouchMove, handleTouchEnd, block.type])

    // if the user doesn't pull hard enough to trigger the breakpoint, then
    // after a short delay we reset the tension applied back to zero
    useEffect(() => {
        var x = setTimeout(() => y !== 0 && setY(0), 300)
        return () => {
            clearTimeout(x)
        }
    }, [y])

    // the opacity of the button is the only visible indication to a user of
    // the "tension" they're applying to trigger a breakpoint.  We want opacity
    // to be in the .1 -> 1.0 range and uniformly increase as tension is applied
    let opacity = Math.min(1, Math.max(0.1, 1 - distance() / 90)).toFixed(2)
    if (triggered) {
        opacity = ".2"
    }

    return (
        <StyledBreakpointBubble ref={pauseRef} isMobile={isMobile} hasText={!!block.value}>
            <StyledBox css={{ opacity: opacity, display: "flex", justifyContent: "flex-end" }}>
                <BreakpointButton
                    ref={buttonRef}
                    onClick={!answer ? onContinue : undefined}
                    white
                    border={!triggered}
                    value={block.value}
                />
            </StyledBox>
        </StyledBreakpointBubble>
    )
}

export default withUserInput(BreakpointPreview)
