import { DocumentNode } from "graphql"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import { SubscriptionHookOptions, SubscriptionResult } from "@apollo/client/react/types/types"
import { OperationVariables } from "@apollo/client/core"
import { useSubscription } from "@apollo/client"
import { useState } from "react"

type LazySubscriptionHookOptions<TData, TVariables> = Omit<
    SubscriptionHookOptions<TData, TVariables>,
    "skip"
>

/*
 * Note: right now the exec function does return void, modify it if necessary
 * */
type LazySubscriptionExecFunction<TData, TVariables> = (
    options: LazySubscriptionHookOptions<TData, TVariables>
) => void

type LazySubscriptionResultTuple<TData, TVariables> = [
    LazySubscriptionExecFunction<TData, TVariables>,
    SubscriptionResult<TData, any> & {
        cancel: () => void
    }
]
/*
 * Note: we avoid receiving `options` on the hook itself (useLazySubscription) for several reasons:
 * 1. We avoid unwanted calls to the subscription when the options change
 * 2. We can do a strong typing on the exec function
 * */
const useLazySubscription = <TData = any, TVariables = OperationVariables>(
    subscription: DocumentNode | TypedDocumentNode<TData, TVariables>
): LazySubscriptionResultTuple<TData, TVariables> => {
    const [subscriptionOptions, setSubscriptionOptions] = useState<
        SubscriptionHookOptions<TData, TVariables> | undefined
    >({ skip: true })

    const subscriptionResult = useSubscription(subscription, {
        ...subscriptionOptions,
    })

    const lazySubscriptionExecFunction: LazySubscriptionExecFunction<TData, TVariables> = (
        options
    ) => {
        setSubscriptionOptions({ ...options, skip: false })
    }

    const cancelSubscription = () => setSubscriptionOptions({ ...subscriptionOptions, skip: true })

    return [lazySubscriptionExecFunction, { ...subscriptionResult, cancel: cancelSubscription }]
}

export const useLazySubscriptionWithOptions = <TData = any, TVariables = OperationVariables>(
    subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options: Omit<SubscriptionHookOptions<TData, TVariables>, "skip">
): LazySubscriptionResultTuple<TData, TVariables> => {
    const [aditionalOptions, setAditionalOptions] = useState<
        SubscriptionHookOptions<TData, TVariables> | undefined
    >({ skip: true })

    const cancelSubscription = () => setAditionalOptions({ ...aditionalOptions, skip: true })

    const subscriptionResult = useSubscription(subscription, {
        ...options,
        ...aditionalOptions,
        onSubscriptionComplete: () => {
            options.onSubscriptionComplete && options.onSubscriptionComplete()
            aditionalOptions?.onSubscriptionComplete && aditionalOptions.onSubscriptionComplete()
            cancelSubscription()
        },
    })

    const lazySubscriptionExecFunction: LazySubscriptionExecFunction<TData, TVariables> = (opt) => {
        setAditionalOptions({ ...opt, skip: false })
    }

    return [lazySubscriptionExecFunction, { ...subscriptionResult, cancel: cancelSubscription }]
}

export default useLazySubscription
