import styled, { css } from "styled-components"
import { StyledBox } from "../../../styles/styledcomponents"

export const StyledEditorWrapper = styled(StyledBox)<{ readOnly?: boolean }>`
    ${({ readOnly }) =>
        readOnly &&
        css`
            pointer-events: none;
        `}
`
