import { IconProps } from "../../types"

export const DesktopIcon = ({ color, width, height }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 1.33337H1.99996C1.26663 1.33337 0.666626 1.93337 0.666626 2.66671V10.6667C0.666626 11.4 1.26663 12 1.99996 12H6.66663L5.33329 14V14.6667H10.6666V14L9.33329 12H14C14.7333 12 15.3333 11.4 15.3333 10.6667V2.66671C15.3333 1.93337 14.7333 1.33337 14 1.33337ZM14 9.33337H1.99996V2.66671H14V9.33337Z"
                fill={color}
            />
        </svg>
    )
}
