import styled, { css } from "styled-components"
import { StyledBox, StyledH5Styles, StyledSpan } from "../../../styles/styledcomponents"
import {
    StyledFullScreenImageContainer,
    StyledNormalSizeImage,
} from "../../../common/components/FullscreenImages/styles"
import { transparentize } from "polished"

// this styled component with almost no-styles is mostly used to be referenced on the StyledImageCarouselContent component
export const StyledImageCarouselItem = styled.div<{ light?: boolean }>`
    opacity: ${({ light }) => (light ? 0.3 : "none")};
    transition: opacity 0.3s;
`

export const StyledCarouselImageContainer = styled(StyledBox)`
    flex-shrink: 0;
    width: fit-content;
    height: 200px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;

    ${StyledFullScreenImageContainer} {
        height: inherit;
        width: inherit;

        ${StyledNormalSizeImage} {
            border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
            border-radius: 8px;
            height: 200px;
            object-fit: cover;
            box-sizing: border-box;
        }
    }
`

interface StyledCorrectnessCheckProps {
    selected?: boolean
    correct?: boolean
    incorrect?: boolean
}

export const StyledCorrectnessCheck = styled(StyledBox)<StyledCorrectnessCheckProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    padding: 7px;
    border: 1px solid ${({ theme }) => theme.colors.base.uiBgBorder};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    transition: border-color 0.3s, background-color 0.3s;
    cursor: pointer;

    ${({ selected }) =>
        selected &&
        css`
            border-color: ${({ theme }) => theme.colors.base.uiPrimary500};
            background-color: ${({ theme }) => theme.colors.base.uiPrimary500};
            path {
                fill: ${({ theme }) => theme.colors.base.uiBgBase};
            }
        `}

    ${({ correct }) =>
        correct &&
        css`
            border-color: ${({ theme }) => theme.colors.base.uiCorrect};
            background-color: ${({ theme }) => transparentize(0.8, theme.colors.base.uiCorrect)};
            path {
                fill: ${({ theme }) => theme.colors.base.uiCorrect};
            }
        `}

    ${({ incorrect }) =>
        incorrect &&
        css`
            border-color: ${({ theme }) => theme.colors.base.uiIncorrect};
            background-color: ${({ theme }) => transparentize(0.8, theme.colors.base.uiIncorrect)};
            path {
                fill: ${({ theme }) => theme.colors.base.uiIncorrect};
            }
        `}
`

export const StyledImageLabel = styled(StyledSpan)`
    /* we use an span instead of an H4 because the 'ThreadPreview.scss' overrides h4 styles */
    ${StyledH5Styles}
    color: ${({ theme }) => theme.colors.base.uiLabelBase};
`
