import styled, { DefaultTheme } from "styled-components"
import {
    StyledBody2,
    StyledBox,
    StyledColumn,
    StyledH5,
    StyledHr,
} from "../../../styles/styledcomponents"
import { MetricVariant } from "."
import { rgba } from "polished"

export const StyledHeaderCard = styled(StyledColumn)`
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    padding: 18px 24px;
    border-radius: 0 0 16px 16px;
    align-items: center;
    justify-content: center;
    gap: 6px;

    ${StyledHr} {
        width: 100%;
        margin: 12px 0;
        color: ${({ theme }) => theme.colors.base.uiBgBorder};
    }

    ${StyledBody2} {
        white-space: nowrap;
    }
`

const getColorsByVariant = (variant: MetricVariant, theme: DefaultTheme) => {
    switch (variant) {
        case MetricVariant.black:
            return {
                primaryColor: theme.colors.base.uiPrimary500,
                secondaryColor: theme.colors.base.uiPrimary300,
            }
        case MetricVariant.blue:
            return {
                primaryColor: theme.colors.base.uiChallenge,
                secondaryColor: rgba(theme.colors.base.uiChallenge, 0.15),
            }
        case MetricVariant.green:
            return {
                primaryColor: theme.colors.base.uiCorrect,
                secondaryColor: rgba(theme.colors.base.uiCorrect, 0.15),
            }
        case MetricVariant.red:
            return {
                primaryColor: theme.colors.base.uiIncorrect,
                secondaryColor: rgba(theme.colors.base.uiIncorrect, 0.15),
            }
    }
}

export const StyledMetric = styled(StyledBox)`
    width: 42px;
    height: 23px;
    padding: 6px 12px;
    border: solid 2px;
    border-radius: ${({ theme }) => theme.borderRadius1};
    text-align: center;

    // font-styles
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
`

export const StyledMetricContainer = styled(StyledColumn)<{ variant: MetricVariant }>`
    align-items: center;
    gap: 3px;
    color: ${({ variant, theme }) => getColorsByVariant(variant, theme).primaryColor};

    ${StyledMetric} {
        border-color: ${({ variant, theme }) => getColorsByVariant(variant, theme).primaryColor};
        background-color: ${({ variant, theme }) =>
            getColorsByVariant(variant, theme).secondaryColor};
    }

    ${StyledH5} {
        color: ${({ variant, theme }) => getColorsByVariant(variant, theme).primaryColor};
    }
`
