import Modal from "../../../components/Modal"
import { MessagingProviders } from "../../../types"
import SlackForm from "./Forms/SlackForm"

export type IntegrationItem = {
    provider: MessagingProviders
    name: string
}

const INTEGRATIONS: IntegrationItem[] = [
    {
        provider: MessagingProviders.SLACK,
        name: "Slack",
    },
    {
        provider: MessagingProviders.LINKEDIN,
        name: "Linkedin",
    },
    {
        provider: MessagingProviders.DISCORD,
        name: "Discord",
    },
    {
        provider: MessagingProviders.TEAMS,
        name: "Teams",
    },
    {
        provider: MessagingProviders.TWITTER,
        name: "Twitter",
    },
    {
        provider: MessagingProviders.WHATSAPP,
        name: "WhatsApp",
    },
]

interface ChannelsModalProps {
    provider: MessagingProviders
    onClose: () => void
}

const ChannelsModal = ({ provider, onClose }: ChannelsModalProps) => {
    const getIntegrationByProvider = (provider: MessagingProviders | null) => {
        return INTEGRATIONS.find((channel) => channel.provider === provider)
    }

    const getNameByProvider = (provider: MessagingProviders | null) => {
        return getIntegrationByProvider(provider)?.name || ""
    }

    const getFormByIntegration = (integration: MessagingProviders) => {
        const integrationItem = getIntegrationByProvider(integration)
        if (!integrationItem) return null
        switch (integration) {
            case MessagingProviders.SLACK:
            default:
                return <SlackForm integration={integrationItem} closeModal={onClose} />
        }
    }
    return (
        <Modal
            open={true}
            onClose={onClose}
            title={`${getNameByProvider(provider)} Configuration`}
            styles={{ width: "500px" }}
            showCloseIcon
        >
            {provider && getFormByIntegration(provider)}
        </Modal>
    )
}

export default ChannelsModal
