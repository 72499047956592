import { Quill } from "react-quill"
import "./VariableBlot.scss"
import classNames from "classnames"

const BlockEmbed = Quill.import("blots/embed")

type VariableBlotValue = {
    name: string
    error?: boolean
}

class VariableBlot extends BlockEmbed {
    static create = (variable: string) => {
        let node = super.create()
        try {
            const variableParsed: VariableBlotValue = JSON.parse(variable)
            node.textContent = variableParsed.name
            const className = classNames("variable-blot", { error: !!variableParsed.error })
            node.setAttribute("class", className)
        } catch (e) {
            node.textContent = variable
        }
        return node
    }
    static value = (node: any): string => {
        return JSON.stringify({
            name: node.textContent,
            error: node.className.includes("error"),
        })
    }
}
VariableBlot.blotName = "VariableBlot"
VariableBlot.className = "variable-blot"
VariableBlot.tagName = "span"

export default VariableBlot
