import styled, { css } from "styled-components"
import { StyledBox, StyledH2, StyledImage, StyledP, StyledRow } from "../styles/styledcomponents"
import StyledButton from "../components/Button/styles"
import { StyledInput, StyledInputContainer, StyledInputLabel } from "../components/InputNew/styles"
import { StyledLoaderContainer } from "../components/Loader/styles"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

export const AuthScreenBaseStyles = css`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    position: relative;

    ${StyledButton} {
        padding: 11px;
    }

    ${StyledInputContainer} {
        margin-bottom: 24px;

        ${StyledInputLabel} {
            font-size: 14px;
            line-height: 20px;
            color: ${({ theme }) => theme.colors.base.uiLabelActive};
            margin-bottom: 3px;
        }
    }

    ${StyledLoaderContainer} {
        height: unset;
    }

    form {
        width: 100%;
        z-index: 1;
    }
`

export const StyledText = styled(StyledP)<{ disabled?: boolean; error?: boolean }>`
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme, disabled, error }) =>
        disabled
            ? theme.colors.base.uiLabelDisabled
            : error
            ? theme.colors.base.uiError500
            : theme.colors.base.uiLabelSubtitle};
    text-align: center;
`

export const StyledButtonText = styled.button`
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.base.uiPrimary500};
    border: none;
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }
`

export const StyleDesktopAuthScreen = styled(StyledBox)`
    ${AuthScreenBaseStyles};
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
`

export const StyleMobileAuthScreen = styled(StyledBox)`
    ${AuthScreenBaseStyles};
    position: relative;
    justify-content: flex-start;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    padding: 120px 45px;
    ${StyledText}, ${StyledButtonText} {
        font-size: 16px;
    }

    ${StyledInput} {
        padding: 6px;
        font-size: 14px;
        line-height: 20px;
        height: 42px;
    }
`

export const StyledMainCard = styled(StyledBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 800px;
    height: 530px;
    background: url("/auth-background.png") center no-repeat;
    background-size: cover;
    box-shadow: 0 6px 52px 1px rgba(0, 0, 0, 0.15);
    border-radius: ${({ theme }) => theme.borderRadius2};
`

export const StyledMainCardContent = styled(StyledBox)`
    width: 300px;
    flex: 1;
    padding-top: 60px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
`

export const StyledTitle = styled(StyledH2)`
    font-size: 24px;
    line-height: 30px;
    align-self: center;
    color: ${({ theme }) => theme.colors.base.uiLabelActive};
    margin-bottom: 6px;
`

export const StyledCodeInputsContainer = styled(StyledRow)`
    gap: 6px;
    justify-content: center;
    margin: 24px 0;

    ${StyledInputContainer} {
        margin-bottom: 0;

        ${StyledInput} {
            width: 42px;
            height: 42px;
            border-radius: ${({ theme }) => theme.borderRadius1};
            padding: 11px 16px;
        }
    }
`

export const StyledArrowBackIcon = styled(ArrowBackIcon)`
    position: absolute;
    top: 28px;
    left: 28px;
    color: ${({ theme }) => theme.colors.base.uiBgIcon};
    justify-self: flex-start;

    &:hover {
        cursor: pointer;
    }
`

export const StyledMobileBackgroundImage = styled(StyledImage)`
    position: absolute;
    bottom: 0;
    width: 100%;
    pointer-events: none;
`
