import { StyledCustomLinkTooltip } from "./styles"
import RichTextEditor from "../../../../../components/Input/RichTextEditor"
import { useCallback, useEffect, useRef, useState } from "react"
import ReactQuill from "react-quill"
import useOnClickOutside from "../../../../../hooks/onClickOutside.hook"

interface CustomLinkTooltipProps {
    onSubmit: (link: string) => void
    onOutsideClick?: () => void
}

const CustomLinkTooltip = ({ onSubmit, onOutsideClick }: CustomLinkTooltipProps) => {
    const [value, setValue] = useState("")
    const reactQuillRef = useRef<ReactQuill | null>(null)

    // on outside click, close the input
    const customLinkInputRef = useRef<HTMLDivElement>(null)
    const outsideClickHandler = useCallback(() => {
        onOutsideClick && onOutsideClick()
        // there is no need to add `closeLinkInput` as a dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useOnClickOutside(customLinkInputRef, outsideClickHandler)

    useEffect(() => {
        if (reactQuillRef.current) {
            reactQuillRef.current?.editor?.focus()
        }
    }, [reactQuillRef])

    return (
        <StyledCustomLinkTooltip ref={customLinkInputRef}>
            <RichTextEditor
                placeholder={"Please enter the URL"}
                value={value}
                noBorder
                onChange={(newValue: string) => setValue(newValue)}
                enableVariables
                onEnterPress={() => onSubmit(value)}
                reactQuillRef={reactQuillRef}
            />
        </StyledCustomLinkTooltip>
    )
}

export default CustomLinkTooltip
