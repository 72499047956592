import styled, { css, DefaultTheme } from "styled-components"
import CheckIcon from "@mui/icons-material/Check"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import {
    StyledSpan,
    VerticalScrollableContentWithHiddenScroll,
} from "../../styles/styledcomponents"
import { ThreadStatus } from "../../apollo/generated/graphql"

export const StyledCheckIcon = styled(CheckIcon)`
    font-size: 12px !important;
    color: ${({ theme }) => theme.headlandsWhite};
`

export const StyledPlayIcon = styled(PlayArrowIcon)`
    font-size: 12px !important;
    color: ${({ theme }) => theme.headlandsPrimaryColor};
`

export const StyledLeaderboardContentCell = styled.div<{ status: ThreadStatus }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20px;
    background-color: ${({ status, theme }) => getColorByStatus(status, theme)};
`

export const StyledLeaderboardRow = styled.div<{ mobile?: boolean }>`
  display: grid;
  width: 100%;
  align-items: center;
  gap: 3px;
  height: ${({ mobile }) => (mobile ? "57px" : "48px")};
  grid-template-columns: ${({ mobile }) => (mobile ? "unset" : "250px 1fr")};
  grid-template-rows: ${({ mobile }) => (mobile ? "1fr 1fr" : "unset")};
  min-width: ${({ mobile }) => (mobile ? "unset" : "calc(250px + 615px)")};

  ${({ mobile }) =>
      mobile &&
      css`
          :not(:first-of-type) {
              margin-top: 12px;
          }

          ${StyledCheckIcon} {
              font-size: 16px !important;
          }

          ${StyledPlayIcon} {
              font-size: 16px !important;
          }

          ${StyledLeaderboardContentCell} {
              height: 30px;
          }
      `}
}
`

const getColorByStatus = (status: ThreadStatus, theme: DefaultTheme) => {
    switch (status) {
        case ThreadStatus.Completed:
            return theme.headlandsPrimaryColor
        case ThreadStatus.InProgress:
            return theme.headlandsPrimaryColorAlpha
        default:
            return theme.headlandsGray4
    }
}

export const StyledThreadTitleCell = styled.div`
    width: 100%;
    height: 24px;
`

export const StyledLeaderboardContentRow = styled.div<{ quantity: number; mobile?: boolean }>`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(
        ${({ quantity }) => quantity},
        ${({ mobile }) => (mobile ? "1fr" : "100px")}
    );
    column-gap: 3px;

    ${StyledLeaderboardContentCell}:first-of-type {
        border-radius: ${({ theme }) => `${theme.borderRadius1} 0 0 ${theme.borderRadius1}`};
    }

    ${StyledLeaderboardContentCell}:last-of-type {
        border-radius: ${({ theme }) => `0 ${theme.borderRadius1} ${theme.borderRadius1} 0`};
    }
`

const getMedalColor = (place: 1 | 2 | 3) => {
    switch (place) {
        case 1:
            return {
                border: "#F8DC68",
                background: "#EBBC2B",
            }
        case 2:
            return {
                border: "#CBC8C6",
                background: "#A2A2A2",
            }
        case 3:
            return {
                border: "#EA8D3F",
                background: "#C7621E",
            }
    }
}

export const StyledMedal = styled.div<{ place: 1 | 2 | 3 }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 30px;
    border: solid 1px ${({ place }) => getMedalColor(place).border};
    background-color: ${({ place }) => getMedalColor(place).background};

    ${StyledSpan} {
        font-size: 10px;
        line-height: 10px;
        font-weight: 700;
        color: ${({ place }) => getMedalColor(place).border};
    }
`

export const LeaderboardContainer = styled.div<{ mobile?: boolean }>`
    ${VerticalScrollableContentWithHiddenScroll};
    overflow-x: auto;
    height: 100%;
    width: 100%;

    ${({ mobile }) =>
        mobile
            ? css`
                  // mobile top-bar, program title, card padding, card title, hr separator, margin bottom, bottom navigation bar
                  max-height: calc(
                      ${window.innerHeight}px - 65px - 54px - 24px - 18px - 33px - 36px - 60px
                  );
              `
            : css`
                  // desktop top-bar, card margin, card padding, card title, hr separator, margin bottom
                  max-height: calc(100vh - 67px - 18px - 12px - 24px - 34px - 34px);
              `}
`
