import { StyledBox, StyledSpan } from "../../../styles/styledcomponents"
import { QuestionsAndAnswers as QuestionsAndAnswersIcon } from "../../../assets/icons/BlockIcon/QuestionsAndAnswers"
import Input from "../../../components/Input/Input"
import Select, { isMultiValue } from "../../../components/Select/Select"
import { useEffect, useState } from "react"
import { components } from "react-select"
import useVariableDefinitions from "../../../hooks/variables.hook"
import { Block, BlocksType } from "../../../types"
import { updateBlock } from "../../../redux/blocks"
import { useDispatch } from "react-redux"
import { VariableType } from "../../../apollo/generated/graphql"
import { debounce } from "lodash"

interface QuestionsAndAnswersProps {
    block: Block
    blocksType: BlocksType
}

const QuestionsAndAnswers = ({ block, blocksType }: QuestionsAndAnswersProps) => {
    const dispatch = useDispatch()
    const [question, setQuestion] = useState<string>(block?.question_text || "")
    const [variableBlockIdSelected, setVariableBlockIdSelected] = useState<string>(
        block?.variable_block_id || ""
    )
    /*
     * here we filter the variables, we only render variables:
     * - string type variables
     * - that has block id (this avoids us using the program variables)
     * */
    const stringVariables = useVariableDefinitions().filter(
        (variable) => variable.type === VariableType.String && variable.blockId
    )
    const variableOptions = stringVariables.map((variableKey) => ({
        value: variableKey.blockId!,
        label: variableKey.name,
    }))

    const onChange = debounce(() => {
        dispatch(
            updateBlock({
                blocksType,
                id: block.id,
                block: { question_text: question, variable_block_id: variableBlockIdSelected },
            })
        )
    }, 500)

    useEffect(() => {
        // Only call the `onChange` if any of the input values has changed
        if (
            block.question_text !== question ||
            block.variable_block_id !== variableBlockIdSelected
        ) {
            onChange()
        }
    }, [question, variableBlockIdSelected])

    return (
        <StyledBox css={{ display: "flex", flex: 1, alignItems: "center", padding: "12px 0" }}>
            <QuestionsAndAnswersIcon />
            <StyledBox css={{ display: "flex", flex: 1, flexDirection: "column", marginLeft: 12 }}>
                <Input
                    value={question}
                    onChange={setQuestion}
                    placeholder={"Question"}
                    inputStyle={{ backgroundColor: "white" }}
                />
                <StyledBox css={{ display: "flex", alignItems: "center" }}>
                    <StyledSpan css={{ marginRight: 6 }}>For response in</StyledSpan>
                    <Select
                        className={"react-select"}
                        classNamePrefix={"react-select"}
                        options={variableOptions}
                        onChange={(option) => {
                            if (!option || isMultiValue(option)) return
                            setVariableBlockIdSelected(option.value)
                        }}
                        value={variableOptions?.filter(
                            (variableOption) => variableOption.value === block.variable_block_id
                        )}
                        components={{
                            IndicatorSeparator: () => null,
                            ValueContainer,
                        }}
                        isSearchable
                        placeholder={""}
                        styles={{ container: () => ({ maxWidth: 250 }) }}
                    />
                </StyledBox>
            </StyledBox>
        </StyledBox>
    )
}

const ValueContainer = (props: any) => (
    <components.ValueContainer {...props}>
        <StyledBox css={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <StyledSpan css={{ whiteSpace: "pre" }}>@</StyledSpan>
            {props.children}
        </StyledBox>
    </components.ValueContainer>
)

export default QuestionsAndAnswers
