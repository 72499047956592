import React, { useCallback, useEffect, useState } from "react"
import { CreatorThreadRow } from "../../../common/components/ThreadRow"
import { LoaderType, PartialRecursive } from "../../../types"
import { useLazyQuery, useMutation } from "@apollo/client"
import { DELETE_THREAD } from "../../../apollo/mutations"
import { useHistory } from "react-router"
import { ErrorToastIcon } from "../../../assets/icons/ErrorToastIcon"
import { debounce } from "lodash"
import { ConfettiIcon } from "../../../assets/icons/ConfettiIcon"
import _ from "lodash"
import { useCurrentThreadGuid, useCurrentThreadId } from "../../../hooks/currentThread.hook"
import { addThreadCreatorToSearchList, deleteItemFromCache } from "../../../apollo/cacheHelper"
import LoaderSkeleton from "../../../common/components/LoaderSkeleton"
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal"
import { DUPLICATE_THREAD } from "../../../apollo/mutations"
import { useToast } from "../../../hooks/useToast.hook"
import { StyledEmptyTopBarHeight } from "../../../components/TopBar/styles"
import UnableToDeleteThreadModal from "../../../components/UnableToDeleteThreadModal"
import { StyledThreadCreatorBar, StyledThreadsList } from "./styles"
import SearchBar from "../../../deliver/components/SearchBar"
import { StyledBox, StyledColumn, StyledRow, StyledSpan } from "../../../styles/styledcomponents"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { useUserPermissions } from "../../../hooks/userPermissions"
import { useCurrentUser } from "../../../hooks/currentUser"
import { SEARCH_THREADS } from "../../../apollo/queries"
import { Thread } from "../../../apollo/generated/graphql"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { Loader } from "../../../components/Loader"

const THREAD_PAGE_SIZE = 20

const ThreadCreatorBar = () => {
    const [search, setSearch] = useState("")

    return (
        <StyledThreadCreatorBar>
            <StyledEmptyTopBarHeight />
            <Tabs>
                <TabList>
                    <Tab key={"teamThreads"}>Team Threads</Tab>
                    <Tab key={"myThreads"}>My Threads</Tab>
                </TabList>
                <TabPanel key={"teamThreads"}>
                    <ThreadsPanel
                        type={ThreadPanelType.TEAM_THREADS}
                        search={search}
                        setSearch={setSearch}
                    />
                </TabPanel>
                <TabPanel key={"myThreads"}>
                    <ThreadsPanel
                        type={ThreadPanelType.MY_THREADS}
                        search={search}
                        setSearch={setSearch}
                    />
                </TabPanel>
            </Tabs>
        </StyledThreadCreatorBar>
    )
}

enum ThreadPanelType {
    MY_THREADS,
    TEAM_THREADS,
}
interface ThreadsPanelProps {
    type: ThreadPanelType
    search: string
    setSearch: (newValue: string) => void
}
export const ThreadsPanel = ({ type, search, setSearch }: ThreadsPanelProps) => {
    const {
        permissions: { isAdministrator },
    } = useUserPermissions()
    const { user } = useCurrentUser()
    const threadId = useCurrentThreadId()
    const threadGuid = useCurrentThreadGuid()
    const history = useHistory()
    const { showToast, showErrorToast } = useToast()

    const [threads, setThreads] = useState<PartialRecursive<Thread>[]>([])
    const [threadsOffset, setThreadsOffset] = useState(0)
    const [endReached, setEndReached] = useState(false)
    const [showUnableToDeleteThreadModal, setShowUnableToDeleteThreadModal] =
        useState<boolean>(false)
    const [threadToDelete, setThreadToDelete] = useState<{ id: number; name: string } | null>(null)

    const [searchThreads, { loading: searchLoading, called: searchCalled }] = useLazyQuery(
        SEARCH_THREADS,
        {
            fetchPolicy: "no-cache",
            onError: (_) => {
                showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
            },
            onCompleted: ({ searchThreads }) => {
                const threads = searchThreads?.map((searchResult) => searchResult.thread)
                const sortedThreads = _.sortBy(threads, ["lastUpdateTime"]).reverse()
                setThreads(sortedThreads)
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    const searchQuery = () => {
        setThreads([])
        const searchClean = search.trim()
        void searchThreads({
            variables: {
                input: {
                    query: searchClean,
                    count: THREAD_PAGE_SIZE,
                    offset: 0,
                    userID: type === ThreadPanelType.MY_THREADS ? user?.id : undefined,
                },
            },
        })
    }

    const delayedQuery = useCallback(debounce(searchQuery, 100), [search, user?.id])

    useEffect(() => {
        delayedQuery()
        return delayedQuery.cancel
    }, [search, delayedQuery])

    const [loadMoreThreads, { loading: moreThreadsLoading }] = useLazyQuery(SEARCH_THREADS, {
        fetchPolicy: "no-cache",
        onCompleted: ({ searchThreads }) => {
            const mappedThreads = searchThreads?.map((searchResult) => searchResult.thread)
            if (mappedThreads?.length) {
                const sortedThreads = _.sortBy(mappedThreads, ["lastUpdateTime"]).reverse()
                setThreads(threads.concat(sortedThreads))
            } else {
                setEndReached(true)
            }
        },
    })

    useEffect(() => {
        if (threadsOffset > 0) {
            void loadMoreThreads({
                variables: {
                    input: {
                        query: search,
                        count: THREAD_PAGE_SIZE,
                        offset: threadsOffset,
                        userID: type === ThreadPanelType.MY_THREADS ? user?.id : undefined,
                    },
                },
            })
        }
    }, [threadsOffset])

    const [sentryRef] = useInfiniteScroll({
        loading: moreThreadsLoading,
        // We asume there's always a next page
        hasNextPage: !endReached,
        onLoadMore: () => setThreadsOffset(threadsOffset + THREAD_PAGE_SIZE),
    })

    const [deleteThread, { loading: deleteLoading }] = useMutation(DELETE_THREAD, {
        onCompleted: (data) => {
            setThreadToDelete(null)
            if (threadId === data.deleteThread.threadId) {
                history.push("/creator")
            }
            showToast({ icon: <ConfettiIcon />, message: "Thread deleted successfully" })
        },
        onError: () => {
            setShowUnableToDeleteThreadModal(false)
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
        },
        update: (cache, { data }) => {
            deleteItemFromCache(cache, "Thread", data!.deleteThread.threadId)
        },
    })

    const [duplicateThread] = useMutation(DUPLICATE_THREAD, {
        onCompleted: (data) => {
            showToast({ icon: <ConfettiIcon />, message: "Thread duplicated successfully" })
            history.push(`/creator/${data?.cloneThread.guid}`)
        },
        onError: () => {
            showErrorToast({ icon: <ErrorToastIcon />, message: "An error has occurred" })
        },
        update: (cache, { data }) => {
            data?.cloneThread && addThreadCreatorToSearchList(cache, data.cloneThread)
        },
    })

    const onThreadRowClick = (selectedThreadGuid?: string) => {
        if (selectedThreadGuid !== threadGuid) {
            history.push(`/creator/${selectedThreadGuid}`)
        }
    }

    const handleDeleteThread = (event: any, thread: PartialRecursive<Thread>) => {
        event.stopPropagation()
        if (
            (isAdministrator || (user?.id && thread?.instructor?.id === user?.id && thread.id)) &&
            thread.id &&
            thread.title
        ) {
            setThreadToDelete({ id: thread.id, name: thread.title })
        } else {
            setShowUnableToDeleteThreadModal(true)
        }
    }

    const handleDuplicateThread = (id?: number) => {
        if (id) {
            duplicateThread({
                variables: {
                    threadID: id,
                },
            }).catch((e) => console.error(e))
        }
    }

    return (
        <StyledColumn css={{ height: "100%" }}>
            <SearchBar
                placeholder={"Search for threads"}
                onChange={(newValue) => setSearch(newValue)}
                value={search}
            />
            <StyledThreadsList>
                {(!searchCalled || searchLoading) && (
                    <StyledBox>
                        {Array.from(Array(6).keys()).map((item, index) => (
                            <LoaderSkeleton
                                type={LoaderType.THREADCREATOR}
                                withDivider
                                key={index}
                            />
                        ))}
                    </StyledBox>
                )}
                {!searchLoading &&
                    threads?.length > 0 &&
                    user?.id &&
                    threads?.map((item, index) => (
                        <CreatorThreadRow
                            key={index}
                            title={item.title}
                            threadId={item.id}
                            instructor={item.instructor}
                            savedDate={item.lastUpdateTime}
                            isTheOwner={
                                isAdministrator || (!!user?.id && item?.instructor?.id === user?.id)
                            }
                            handleOnClick={() => onThreadRowClick(item.guid)}
                            handleDeleteThread={(e) => handleDeleteThread(e, item)}
                            handleDuplicateThread={() => handleDuplicateThread(item?.id)}
                            maxWidth={227}
                            active={item.guid === threadGuid}
                            fullWidth
                        />
                    ))}
                {searchCalled && !searchLoading && threads?.length === 0 && (
                    <StyledRow css={{ justifyContent: "center", alignItems: "center" }}>
                        <ErrorToastIcon />
                        <StyledSpan css={{ marginLeft: "4px" }}>Thread not found</StyledSpan>
                    </StyledRow>
                )}
                <StyledBox ref={sentryRef} style={{ minHeight: "30px", padding: "3px" }}>
                    {searchCalled && !searchLoading && moreThreadsLoading && <Loader size={24} />}
                </StyledBox>
            </StyledThreadsList>
            {/* Modals */}
            {showUnableToDeleteThreadModal && !threadToDelete && (
                <UnableToDeleteThreadModal
                    closeModal={() => setShowUnableToDeleteThreadModal(false)}
                />
            )}
            {threadToDelete && !showUnableToDeleteThreadModal && (
                <DeleteConfirmationModal
                    open={true}
                    deleteItemName={threadToDelete.name}
                    onClose={() => setThreadToDelete(null)}
                    onConfirm={() => deleteThread({ variables: { threadId: threadToDelete.id } })}
                    loading={deleteLoading}
                />
            )}
        </StyledColumn>
    )
}
export default React.memo(ThreadCreatorBar)
