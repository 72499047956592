import styled from "styled-components"
import { StyledBox, StyledH3, StyledH5 } from "../styles/styledcomponents"

export const StyledDeliverScreen = styled(StyledBox)`
    height: 100%;
    display: flex;
    flex: 1;
    position: relative;
`

export const StyledContentContainer = styled(StyledBox)`
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.headlandsGray4};
`
export const StyledCard = styled(StyledBox)`
    background-color: ${({ theme }) => theme.headlandsWhite};
    border-radius: ${({ theme }) => theme.borderRadius2};
    padding: 18px;
    margin-bottom: 12px;

    ${StyledH3} {
        font-size: 20px;
        line-height: 23px;
        color: ${({ theme }) => theme.colors.base.uiLabelTitle};
    }

    ${StyledH5} {
        color: ${({ theme }) => theme.colors.base.uiLabelSubtitle};
    }

    hr {
        width: 100%;
        height: 1px;
        border: 0;
        border-top: 1px solid;
        margin-top: 6px;
        margin-bottom: 12px;
        color: #d9d9d9;
    }
`

export const StyledCardsContainer = styled(StyledBox)`
    height: calc(100% - 72px);
    padding: 18px;
    box-sizing: border-box;
    overflow-y: auto;
`
