import { useTheme } from "styled-components"
import TopBar from "../../../../components/TopBar"
import { StyledBox } from "../../../../styles/styledcomponents"
import { ArrowBackIcon } from "../../../../assets/icons/ArrowBack"
import { StyledCreateButton } from "./styles"
import AddRoundedIcon from "../../../../assets/icons/AddRoundedIcon"

type CreatorMobileTopBarProps = {
    onBack?: () => void
    showCreateButton?: boolean
    children?: JSX.Element
}

const CreatorMobileTopBar = ({ onBack, showCreateButton, children }: CreatorMobileTopBarProps) => {
    const theme = useTheme()

    return (
        <TopBar>
            <StyledBox
                css={{
                    display: "grid",
                    flex: 1,
                    gridTemplateColumns: "30px 1fr 30px",
                    alignItems: "center",
                }}
            >
                {onBack ? <ArrowBackIcon width={23} height={23} onClick={onBack} /> : <div />}
                {children}
                {showCreateButton ? (
                    <StyledCreateButton disabled>
                        <AddRoundedIcon color={theme.colors.base.uiBgBase} />
                    </StyledCreateButton>
                ) : (
                    <div />
                )}
            </StyledBox>
        </TopBar>
    )
}

export default CreatorMobileTopBar
