import Carousel from "../index"
import { Choice, ChoiceData } from "../../../apollo/generated/graphql"
import { StyledRow } from "../../../styles/styledcomponents"
import { useCallback, useMemo } from "react"
import { OptionPreviewMode, QuestionChoice } from "../../../types"
import {
    StyledCarouselImageContainer,
    StyledCorrectnessCheck,
    StyledImageCarouselItem,
    StyledImageLabel,
} from "./styles"
import FullscreenImages, { ImageZoomType } from "../../../common/components/FullscreenImages"
import useLayout from "../../../hooks/layout.hook"
import { usePreviewMode } from "../../../hooks/previewMode.hook"
import WrongIcon from "../../../assets/icons/WrongIcon"
import CorrectIcon from "../../../assets/icons/CorrectIcon"
import { StyledAnimatedScore } from "../../../creator/components/QuestionBlock/styles"
import { Maybe } from "graphql/jsutils/Maybe"

interface CommonImageCarouselProps {
    id: string
    // `imagesList` should be use ONLY for rendering the possible options
    imagesList?: QuestionChoice[]
    scrollToBottom?: (() => void) | undefined
}

interface NonSelectableImageCarouselProps extends CommonImageCarouselProps {
    imagesAreSelectable?: false
}

interface SelectableImageCarouselProps extends CommonImageCarouselProps {
    imagesAreSelectable: true
    handleOptionSelect: (option: Choice) => void
    selectedOptionIDs: string[]
    disabled: boolean
    // `choiceData` should be use to determine which one is correct this data will came from the back-end
    choiceData?: Maybe<ChoiceData[]>
    score?: Maybe<number>
}

type ImageCarouselProps = NonSelectableImageCarouselProps | SelectableImageCarouselProps
const ImageCarousel = ({ id, imagesList, scrollToBottom, ...props }: ImageCarouselProps) => {
    const { isMobile } = useLayout()
    const { previewDevice, previewMode } = usePreviewMode()
    /*
     * Right now the url on the blocks are saved in the `image_url` property, this is a problem because
     * the `Choice` type has the url on the `imageURL` instead.
     * */
    const imagesToRender = useMemo(
        () => (imagesList ? imagesList.filter((option) => option?.image_url) : []),
        [imagesList]
    )

    const imagesAreSelectable = props.imagesAreSelectable
    const disabled = imagesAreSelectable && props.disabled
    const selectedOptionIDs = imagesAreSelectable ? props.selectedOptionIDs : []
    const choiceData = imagesAreSelectable ? props.choiceData : []

    const isChoiceSelected = useCallback(
        (choice: QuestionChoice) =>
            !!selectedOptionIDs?.find((selectedOptionID) => selectedOptionID === choice?.id),
        [selectedOptionIDs]
    )

    const isChoiceCorrect = useCallback(
        (choice: QuestionChoice) =>
            !!choiceData &&
            !!choiceData?.find((choiceImage) => choiceImage.id === choice?.id)?.correct,
        [choiceData]
    )

    const isChoiceIncorrect = useCallback(
        (choice: QuestionChoice) =>
            isChoiceSelected(choice) &&
            !!choiceData &&
            !choiceData?.find((choiceImage) => choiceImage.id === choice?.id)?.correct,
        [choiceData, selectedOptionIDs]
    )

    const onImageSelect = (image: Choice) =>
        !disabled &&
        imagesAreSelectable &&
        props.handleOptionSelect &&
        props.handleOptionSelect(image)

    return (
        <Carousel
            id={id}
            items={imagesToRender}
            hideNavigation={isMobile || (previewMode && previewDevice === OptionPreviewMode.MOBILE)}
            renderItem={(item, carrouselItemId) => (
                <StyledImageCarouselItem
                    key={carrouselItemId}
                    id={carrouselItemId}
                    light={imagesAreSelectable && disabled && !isChoiceSelected(item)}
                >
                    <StyledCarouselImageContainer>
                        {/* the image itself */}
                        <FullscreenImages
                            src={item?.image_url || ""}
                            scrollToBottom={scrollToBottom}
                            zoomType={disabled ? ImageZoomType.NONE : ImageZoomType.BUTTON}
                            onClick={() => onImageSelect(item)}
                        />
                    </StyledCarouselImageContainer>
                    <StyledRow css={{ alignItems: "center", gap: "12px", marginTop: "6px" }}>
                        {imagesAreSelectable && (
                            <>
                                {isChoiceSelected(item) && isChoiceCorrect(item) && props.score && (
                                    <StyledAnimatedScore>{`+${props.score} pts`}</StyledAnimatedScore>
                                )}
                                <Correctness
                                    selected={isChoiceSelected(item)}
                                    correct={isChoiceCorrect(item)}
                                    incorrect={isChoiceIncorrect(item)}
                                    onClick={() => onImageSelect(item)}
                                />
                            </>
                        )}
                        {/* the image label text */}
                        {item?.text && <StyledImageLabel>{item?.text}</StyledImageLabel>}
                    </StyledRow>
                </StyledImageCarouselItem>
            )}
            intersectionObserverOptions={{
                threshold: [0.1, 0.9],
                minIntersectionRatio: 0.1,
                maxIntersectionRation: 0.9,
            }}
        />
    )
}

interface CorrectnessProps {
    selected?: boolean
    correct?: boolean
    incorrect?: boolean
    onClick?: () => void
}

const Correctness = (props: CorrectnessProps) => {
    return (
        <StyledCorrectnessCheck {...props}>
            {props.incorrect ? (
                <WrongIcon />
            ) : props.correct || props.selected ? (
                <CorrectIcon />
            ) : null}
        </StyledCorrectnessCheck>
    )
}

export default ImageCarousel
