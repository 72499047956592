import styled, { css, keyframes } from "styled-components"
import {
    StyledBody1,
    StyledBody1Styles,
    StyledBox,
    StyledColumn,
    StyledHr,
    StyledImage,
    StyledRow,
} from "../../../styles/styledcomponents"
import RichTextEditor from "../../../components/Input/RichTextEditor"
import { CorrectnessCheckShape } from "."
import { rgba } from "polished"
import {
    Check,
    Close,
    Rule,
    Send,
    SettingsOutlined,
    InsertPhotoOutlined,
    VideocamOutlined,
    ExpandLess,
} from "@mui/icons-material"

// divider semicircle
export const SEMICIRCLE_HEIGHT = "30px"

export const StyledQuestionBlock = styled(StyledColumn)`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    padding: 18px 0 12px 0;
    border-radius: 3px;
`

export const StyledRuleIcon = styled(Rule)`
    font-size: 18px !important;
`

export const StyledInsertPhotoOutlineIcon = styled(InsertPhotoOutlined)`
    font-size: 18px !important;
`

export const StyledVideocamOutlinedIcon = styled(VideocamOutlined)`
    font-size: 18px !important;
`

export const StyledSettingsOutlinedIcon = styled(SettingsOutlined)`
    font-size: 18px !important;
`

export const StyledButton = styled.button`
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius1};
    outline: none;

    color: ${({ theme }) => theme.colors.base.uiBgIcon};
    ${StyledBody1Styles};

    &:hover {
        background-color: ${({ theme }) => theme.colors.base.uiBgHover};
        cursor: pointer;
    }
`

export const StyledManageResponseButton = styled.button`
    background-color: transparent;
    border: none;

    color: ${({ theme }) => theme.colors.base.uiPrimary500};
    ${StyledBody1Styles};
    text-decoration: underline;
`
export const StyledSemiCircle = css`
    content: "";
    display: inline-block;
    position: absolute;

    width: calc(${SEMICIRCLE_HEIGHT} / 2);
    height: ${SEMICIRCLE_HEIGHT};

    background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
`

export const StyledQuestionBlockDivider = styled(StyledBox)`
    display: flex;
    align-items: center;
    width: 100%;
    height: ${SEMICIRCLE_HEIGHT};
    position: relative;

    ::before {
        ${StyledSemiCircle};
        -moz-border-radius: 0 30px 30px 0;
        -webkit-border-radius: 0 30px 30px 0;
        border-radius: 0 30px 30px 0;
        position: absolute;
        right: calc(100% - (${SEMICIRCLE_HEIGHT} / 2));
    }

    ::after {
        ${StyledSemiCircle};
        -moz-border-radius: 30px 0 0 30px;
        -webkit-border-radius: 30px 0 0 30px;
        border-radius: 30px 0 0 30px;
        position: absolute;
        left: calc(100% - (${SEMICIRCLE_HEIGHT} / 2));
    }

    ${StyledHr} {
        border-top: dashed 2px ${({ theme }) => theme.colors.base.uiBgSecondary};
        width: 100%;
    }
`

export const StyledQuestionBlockRow = styled(StyledRow)`
    flex: 1;
    padding: 0 12px 0 35px;

    .block-container {
        width: unset !important;

        &.branch {
            min-width: 572px !important;
        }

        .icons-row {
            display: none !important;
        }
    }
`
export const StyledQuestionBlockColumn = styled(StyledColumn)`
    flex: 1;
    padding: 0 12px 0 35px;
`

export const StyledRichTextEditor = styled(RichTextEditor)`
    width: 100%;
    .quill {
        border: none;
        background-color: ${({ theme }) => theme.colors.base.uiBgSecondary};
        border-radius: ${({ theme }) => theme.leftBubbleBorderRadius};
        /* width: 100%; */

        &.focused {
            border: none !important;
            box-shadow: none !important;
            border-radius: ${({ theme }) => theme.leftBubbleBorderRadius};
        }
    }
`

export const StyledAnswersContainer = styled(StyledColumn)`
    align-items: flex-end;
    align-self: flex-end;
    gap: 6px;
    min-width: 200px;
    max-width: 358px;
    padding: 6px;
`

export const StyledFreeTextAnswer = styled(StyledRow)`
    padding: 12px 18px;
    border-radius: 16px 16px 2px 16px;
    background-color: ${({ theme }) => theme.colors.base.uiPrimary300};
    border: 1px solid ${({ theme }) => theme.colors.base.uiPrimary500};
    gap: 8px;
    align-items: center;

    ${StyledBody1} {
        color: ${({ theme }) => theme.colors.base.uiLabelBase};
    }
`

export const StyledSendIcon = styled(Send)`
    color: ${({ theme }) => theme.colors.base.uiPrimary400};
    font-size: 20px !important;
`

export const StyledCorrectIcon = styled(Check)`
    color: ${({ theme }) => theme.colors.base.uiCorrect};
`

export const StyledIncorrectIcon = styled(Close)`
    color: ${({ theme }) => theme.colors.base.uiIncorrect};
`

const CorrectnessAnimation = keyframes`
  0% {
    opacity: 0;
    scale: 0.5;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
`

export const CORRECTNESS_ANIMATION_DURATION_MS = 500

export const StyledCorrectnessCheck = styled(StyledColumn)<{
    correct?: boolean
    size: number
    shape: CorrectnessCheckShape
    animated?: boolean
}>`
    justify-content: center;
    align-items: center;
    border-radius: ${({ shape }) => (shape === CorrectnessCheckShape.circular ? "50%" : "2px")};
    transition: all 0.3s;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    flex: 0 0 auto;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;

    ${({ animated }) =>
        animated &&
        css`
            animation-name: ${CorrectnessAnimation};
            animation-duration: ${CORRECTNESS_ANIMATION_DURATION_MS}ms;
            animation-timing-function: ease-out;
        `}

    ${({ correct }) =>
        !correct &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.base.uiIncorrect};
            background-color: ${({ theme }) => rgba(theme.colors.base.uiIncorrect, 0.15)};
        `}

    ${({ correct }) =>
        correct &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.base.uiCorrect};
            background-color: ${({ theme }) => rgba(theme.colors.base.uiCorrect, 0.15)};
        `}
`

const ScoreAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(3px);
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
`

export const SCORE_ANIMATION_DURATION_MS = 800

export const StyledAnimatedScore = styled(StyledBody1)`
    pointer-events: none;
    position: absolute;
    color: ${({ theme }) => theme.colors.base.uiCorrect};
    white-space: nowrap;
    font-weight: 500;
    z-index: 1;
    opacity: 0;

    animation-name: ${ScoreAnimation};
    animation-duration: ${SCORE_ANIMATION_DURATION_MS}ms;
    animation-timing-function: ease-out;
    animation-delay: 0.5s;
`

export const StyledChoiceOption = styled(StyledBox)<{ correct?: boolean }>`
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;
    min-height: 34px;
    padding: 6px 12px;
    background-color: ${({ theme }) => theme.colors.base.uiBgBase};
    border: solid 1px ${({ theme }) => theme.colors.thread.student.bubble};
    border-radius: 30px;
    opacity: 0.5;
    box-sizing: border-box;
    text-align: center;
    color: ${({ theme }) => theme.colors.thread.student.bubble};

    ${StyledBody1Styles};

    ${({ correct }) =>
        correct &&
        css`
            background-color: ${({ theme }) => theme.colors.thread.student.bubble};
            border: none;
            opacity: 1;
            color: ${({ theme }) => theme.colors.thread.student.font};

            ${StyledBody1} {
                color: ${({ theme }) => theme.colors.thread.student.font};
            }

            ${StyledInsertPhotoOutlineIcon} {
                path {
                    fill: ${({ theme }) => theme.colors.base.uiBgBase};
                }
            }
        `}

    ${StyledInsertPhotoOutlineIcon} {
        &:hover {
            cursor: pointer;
        }
    }
`

export const StyledChoiceOptionImage = styled(StyledImage)`
    height: 24px;
    width: 24px;
    border-radius: ${({ theme }) => theme.borderRadius1};
    object-fit: cover;

    &:hover {
        cursor: pointer;
    }
`

export const StyledCollapseIcon = styled(ExpandLess)<{ collapsed?: boolean }>`
    color: ${({ theme }) => theme.colors.base.uiBgIcon};
    transition: transform 0.3s !important;
    &:hover {
        cursor: pointer;
    }

    ${({ collapsed }) =>
        collapsed &&
        css`
            transform: rotate(-180deg);
        `}
`
