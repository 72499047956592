import { Block, BlockContext, BlocksType, BlockType } from "../../../types"
import { withHidableWrapper } from "../HidableBlockWrapper"
import Group from "../Group"
import Conditional from "../BlockContent/Conditional/Conditional"
import Brick from "../Brick/Brick"
import QuestionBlock from "../QuestionBlock"

interface RenderChunkProps {
    block: Block
    blocksType: BlocksType
    context?: BlockContext
    handlePreview?: (previewMode: boolean, initialBlockId?: string) => void
}

const RenderChunk = (props: RenderChunkProps) => {
    const { block, blocksType, context } = props
    switch (block.type) {
        case BlockType.CONDITIONAL:
            return withHidableWrapper(<Conditional key={`block-${block.id}`} blockId={block.id} />)(
                block.hidden
            )
        case BlockType.GROUP: {
            return withHidableWrapper(
                <Group key={`block-${block.id}`} block={block} context={context} />
            )(block.hidden)
        }
        case BlockType.INCLUDE:
            return withHidableWrapper(<Brick key={`block-${block.id}`} block={block} />)(
                block.hidden
            )
        case BlockType.CHOICE_QUESTION:
        case BlockType.FREE_TEXT_QUESTION:
            return withHidableWrapper(
                <QuestionBlock
                    key={`block-${block.id}`}
                    block={block}
                    blocksType={blocksType}
                    context={context}
                />
            )(block.hidden)
    }
    return null
}

export default RenderChunk
