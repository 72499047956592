import { InMemoryCache, ReactiveVar } from "@apollo/client"
import { Block, TelemetrySessionData } from "../types"

export const cacheStore: InMemoryCache = new InMemoryCache({
    possibleTypes: {
        ProgramContent: ["ProgramThread", "SlackMessage", "EmailMessage"],
    },
    typePolicies: {
        Query: {
            fields: {
                selectedBlock() {
                    return selectedBlockVar()
                },
                sessionData() {
                    return sessionDataVar()
                },
                asyncError() {
                    return asyncErrorVar()
                },
            },
        },
        UserThread: {
            // to avoid cache collision between the same thread on different programs
            keyFields: ["id", "status", "threadVersion", ["id"]],
        },
        Participant: {
            /*
             * Participants don't have an id when they aren't registered,
             * so to avoid collisions we also use email as key
             */
            keyFields: ["id", "email"],
        },
        ProgramThread: {
            /*
             * The way to distinguish between different `ProgramThreads` is by the `clientData` field
             * */
            keyFields: ["clientData"],
        },
        SlackMessage: {
            /*
             * The way to distinguish between different `SlackMessage` is by the `clientData` field
             * */
            keyFields: ["clientData"],
        },
        EmailMessage: {
            /*
             * The way to distinguish between different `EmailMessage` is by the `clientData` field
             * */
            keyFields: ["clientData"],
        },
        Issue: {
            /*
             * The way to distinguish between different `Issue` is by the `objectID` field
             * */
            keyFields: ["objectID"],
        },
        ThreadProgressForProgram: {
            keyFields: ["programGuid", "threadGuid", "person", ["id"]],
        },
    },
})

export const selectedBlockVar: ReactiveVar<Block | null> = cacheStore.makeVar<Block | null>(null)

export const sessionDataVar: ReactiveVar<TelemetrySessionData | undefined> = cacheStore.makeVar<
    TelemetrySessionData | undefined
>(undefined)

export const asyncErrorVar: ReactiveVar<unknown> = cacheStore.makeVar<unknown>(undefined)
