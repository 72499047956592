import useLayout from "./layout.hook"
import { setSidebarState, SidebarType } from "../redux/layout"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { useLocation } from "react-router-dom"
import { isEmpty } from "lodash"
import { useSelectedEnrolledProgramAttribute } from "./enrolledProgram.hook"
import { useTheme } from "styled-components"

export enum Screen {
    Catalog = "CATALOG",
    Creator = "CREATOR",
    Deliver = "DELIVER",
}

// all the sidebars that a screen has
// IMPORTANT: ordered by priority (from less priority to high priority)
const ScreenSidebars: { [key in Screen]: SidebarType[] } = {
    [Screen.Catalog]: [SidebarType.nav, SidebarType.social, SidebarType.library],
    [Screen.Creator]: [
        SidebarType.nav,
        SidebarType.properties,
        SidebarType.creatorThread,
        SidebarType.creatorThreadOutline,
    ],
    [Screen.Deliver]: [SidebarType.nav, SidebarType.deliverProgram],
}

export const useSidebars = () => {
    const theme = useTheme()
    const { width } = useLayout()
    const dispatch = useDispatch()
    const location = useLocation()
    const layout = useSelector((state: RootState) => state.layoutReducer)
    const { attribute: selectedProgramSocialSidebar } =
        useSelectedEnrolledProgramAttribute("socialSidebar")

    const SidebarsWidth: { [key: string]: number } = {
        [SidebarType.nav]: theme.sidebarWidths.nav,
        [SidebarType.library]: theme.sidebarWidths.library,
        [SidebarType.social]: theme.sidebarWidths.social,
        [SidebarType.creatorThread]: theme.sidebarWidths.creatorThread,
        [SidebarType.properties]: theme.sidebarWidths.properties,
        [SidebarType.deliverProgram]: theme.sidebarWidths.deliver,
        [SidebarType.creatorThreadOutline]: theme.sidebarWidths.creatorThreadOutline,
    }

    const NAV_BAR_WIDTH = theme.sidebarWidths.nav

    // this constant represents the space we left for the content of each screen
    // note: keep in mind that the nav bar occupies some space even when is closed
    const ScreenContentMinWidth = {
        [Screen.Catalog]: 600 + NAV_BAR_WIDTH,
        [Screen.Creator]: 600 + NAV_BAR_WIDTH,
        [Screen.Deliver]: 600 + NAV_BAR_WIDTH,
    }

    /*
     * Returns whether the sidebar can be use or not.
     * */
    const canUseSidebar = (sidebar: SidebarType): boolean => {
        switch (sidebar) {
            case SidebarType.social:
                return !!selectedProgramSocialSidebar
            default:
                return true
        }
    }

    const getCurrentScreen = (): Screen | null => {
        const findTerm = (term: string) => location.pathname.includes(term)
        if (findTerm("catalog")) return Screen.Catalog
        else if (findTerm("creator")) return Screen.Creator
        else if (findTerm("deliver")) return Screen.Deliver
        else return null
    }

    /*
     * Returns the whole available space of a given screen, it doesn't take in account the open sidebars,
     * just the width of the screen less the minimum content width
     * */
    const getScreenAvailableSpace = (screen: Screen): number => {
        return width - ScreenContentMinWidth[screen]
    }

    /*
     * Return all the sidebars of a certain screen that are open ordered by priority
     * */
    const getSidebarsOpenOfScreen = (screen: Screen): SidebarType[] => {
        return ScreenSidebars[screen].filter((sidebar) => layout[sidebar])
    }

    /*
     * Calculates and return the total width of the given sidebars
     * */
    const calculateSidebarsWidth = (sidebars: SidebarType[]): number => {
        let calculatedWidth = 0
        sidebars.forEach((sidebar) => (calculatedWidth += SidebarsWidth[sidebar]))
        return calculatedWidth
    }

    /*
     * Calculates and return an array of sidebars to close in order to make room for the new sidebar to be opened
     * */
    const calculateSidebarsToBeClosed = (
        screen: Screen,
        openSidebars: SidebarType[],
        sidebarToOpen: SidebarType
    ): SidebarType[] => {
        const openSidebarsWidth = calculateSidebarsWidth(openSidebars)
        const thereIsEnoughRoom =
            openSidebarsWidth + SidebarsWidth[sidebarToOpen] <= getScreenAvailableSpace(screen)
        if (thereIsEnoughRoom || isEmpty(openSidebars)) return []
        // if there is not enough room, remove the less priority sidebar and calculate again
        return [
            openSidebars[0],
            ...calculateSidebarsToBeClosed(screen, openSidebars.slice(1), sidebarToOpen),
        ]
    }

    /*
     * Handles all the logic to toggle a certain sidebar, here is where the magic happens!
     * */
    const toggleSidebar = (sidebar: SidebarType, enableAnimation: boolean = true) => {
        if (!canUseSidebar(sidebar)) return
        const sidebarIsOpen = layout[sidebar]
        const screen = getCurrentScreen()

        // if the screen is not supported print an error and just toggle the requested sidebar
        if (!screen) {
            console.error("*** Smart Sidebar is not supported on the current screen ***")
            dispatch(
                setSidebarState({
                    sidebars: { sidebarType: sidebar, open: !sidebarIsOpen },
                    enabledSidebarAnimation: enableAnimation,
                })
            )
        }
        // if the sidebar is open we don't care about the other sidebars, just close it
        else if (sidebarIsOpen) {
            dispatch(
                setSidebarState({
                    sidebars: { sidebarType: sidebar, open: false },
                    enabledSidebarAnimation: enableAnimation,
                })
            )
        }
        // check if the width of the screen is larger enough to contain all previous opened sidebars and the new one,
        // if not, close one or more sidebars in order to make room
        else {
            const openSidebars = getSidebarsOpenOfScreen(screen)
            const sidebarsToBeClosed = calculateSidebarsToBeClosed(screen, openSidebars, sidebar)
            dispatch(
                setSidebarState({
                    sidebars: [
                        ...sidebarsToBeClosed.map((sidebarToBeClosed) => ({
                            sidebarType: sidebarToBeClosed,
                            open: false,
                        })),
                        { sidebarType: sidebar, open: true },
                    ],
                    enabledSidebarAnimation: enableAnimation,
                })
            )
        }
    }

    /*
     * Tries to open the requested sidebar (or sidebars) given the available space
     * */
    const openSidebars = (
        sidebar: SidebarType | SidebarType[],
        enableAnimation: boolean = true
    ) => {
        if (Array.isArray(sidebar)) openMultipleSidebars(sidebar, enableAnimation)
        else openSingleSidebar(sidebar, enableAnimation)
    }

    /*
     * Opens the given sidebar, if is already open is doesn't do anything
     * */
    const openSingleSidebar = (sidebar: SidebarType, enableAnimation: boolean = true) => {
        const sidebarIsOpen = layout[sidebar]
        if (sidebarIsOpen || !canUseSidebar(sidebar)) return
        toggleSidebar(sidebar, enableAnimation)
    }

    /*
     * Tries to open as many sidebar as it could, given the available space.
     * Important: the array of sidebars must be in order from less priority to high priority
     * */
    const openMultipleSidebars = (sidebars: SidebarType[], enableAnimation: boolean = true) => {
        const availableSidebars = sidebars.filter((sidebar) => canUseSidebar(sidebar))
        const screen = getCurrentScreen()
        // if the screen is not supported print an error and just open all the requested sidebars
        if (!screen) {
            console.error("*** Smart Sidebar is not supported on the current screen ***")
            dispatch(
                setSidebarState({
                    sidebars: availableSidebars.map((sidebar) => ({
                        sidebarType: sidebar,
                        open: true,
                    })),
                    enabledSidebarAnimation: enableAnimation,
                })
            )
            return
        }
        if (isEmpty(availableSidebars)) return
        const allSidebarsWidth = calculateSidebarsWidth(availableSidebars)
        const availableSpace = getScreenAvailableSpace(screen)
        // if there is enough space, open all the requested sidebars
        if (availableSpace >= allSidebarsWidth) {
            dispatch(
                setSidebarState({
                    sidebars: availableSidebars.map((sidebar) => ({
                        sidebarType: sidebar,
                        open: true,
                    })),
                    enabledSidebarAnimation: enableAnimation,
                })
            )
        }
        // otherwise remove the less priority sidebar and try again
        else openMultipleSidebars(availableSidebars.slice(1), enableAnimation)
    }

    /*
     * Close all given sidebars
     * */
    const closeSidebars = (sidebars: SidebarType[], enableAnimation: boolean = true) => {
        /*
         * note:
         * we shouldn't filter the sidebars with the `canUseSidebar` function, this can introduce
         * a bug where the social sidebar can't be closed if the url doesn't contain a program guid
         * */
        dispatch(
            setSidebarState({
                sidebars: sidebars.map((sidebar) => ({ sidebarType: sidebar, open: false })),
                enabledSidebarAnimation: enableAnimation,
            })
        )
    }

    /*
     * Manually changing the sidebar animation boolean on the reducer.
     * Only useful for very specific situations.
     * */
    const setSidebarAnimation = (enabled: boolean) => {
        dispatch(
            setSidebarState({
                sidebars: [],
                enabledSidebarAnimation: enabled,
            })
        )
    }

    return {
        toggleSidebar,
        openSidebars,
        closeSidebars,
        canUseSidebar,
        setSidebarAnimation,
        // sidebars open state
        libraryBarOpen: layout.libraryBarOpen,
        socialBarOpen: layout.socialBarOpen,
        creatorThreadBarOpen: layout.creatorThreadBarOpen,
        propertiesBarOpen: layout.propertiesBarOpen,
        deliverProgramBarOpen: layout.deliverProgramBarOpen,
        creatorThreadOutlineOpen: layout.creatorThreadOutlineOpen,
        enableSidebarAnimation: layout.enableSidebarAnimation,
    }
}
