import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { AiAssistMenuKey } from "../creator/components/AddChunkMenu/constants"
import { ThreadTemplate } from "../apollo/generated/graphql"

export type CreateBlockData = {
    index?: number
    sectionId?: string
    questionOptionId?: string
    freeTextResponseId?: string
    branchId?: string
    blockGuid?: string
    title?: string
    type: AiAssistMenuKey
}

export interface AIState {
    createBlockData?: CreateBlockData
    createThreadTemplate?: ThreadTemplate
}

const initialState: AIState = {
    createBlockData: undefined,
    createThreadTemplate: undefined,
}

export const aiSlice = createSlice({
    name: "ai",
    initialState,
    reducers: {
        setAiCreateBlockData: (
            state: AIState,
            action: PayloadAction<CreateBlockData | undefined>
        ) => {
            state.createBlockData = action.payload
            return state
        },
        setAiCreateThreadTemplate: (
            state: AIState,
            action: PayloadAction<ThreadTemplate | undefined>
        ) => {
            state.createThreadTemplate = action.payload
            return state
        },
    },
})

export const { setAiCreateBlockData, setAiCreateThreadTemplate } = aiSlice.actions

export default aiSlice.reducer
