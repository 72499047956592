import { useCallback, useEffect } from "react"

interface useLockScrollProps {
    condition: boolean
    element?: HTMLElement
}
const useLockScroll = ({
    condition,
    // by default will apply to the body
    element = document.getElementsByTagName("body")[0],
}: useLockScrollProps) => {
    const lockScroll = useCallback(() => {
        element.style.overflow = "hidden"
    }, [element])

    const unlockScroll = useCallback(() => {
        element.style.overflow = "auto"
    }, [element])

    /*
     * On unmount unlock the scroll
     * */
    useEffect(() => {
        return () => unlockScroll()
    }, [unlockScroll])

    /*
     * Whenever the condition is true, lock the scroll, otherwise unlock it
     * */
    useEffect(() => {
        if (condition) lockScroll()
        else unlockScroll()
    }, [condition, lockScroll, unlockScroll])

    return null
}

export default useLockScroll
